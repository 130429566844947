import React from 'react'
import { TextInput } from 'grommet'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import moment from 'moment'
import MomentUtils from '@date-io/moment'

const DateInput = ({ value, onChange, ...dateInputProps }) => {
  const format = 'D MMM YYYY'
  const dateValue = moment(value)

  const onTimeChange = date => {
    onChange(date.toDate())
  }
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <DatePicker
        value={dateValue}
        onChange={onTimeChange}
        format={format}
        TextFieldComponent={TextInput}
        {...dateInputProps}
      />
    </MuiPickersUtilsProvider>
  )
}

export default DateInput
