import { Constants } from 'galarm-config'
import { TaskManager } from 'galarm-shared'

const NotificationManager = (function () {
  return {
    scheduleNotifications: () => {},
    cancelNotifications: () => {},
    requestPermissions: () => {},
    sendRemoteNotification: (uid, notificationKey, info) => {
      let category = ''
      if (
        info.type === Constants.NotificationTypes.BACKUP_ALARM_ADDED &&
        info.alarmCreationMode !== Constants.AlarmCreationModes.INSTANT_ALARM
      ) {
        // iOS
        category = 'accept_reject'
        // Android
        // Note that this is a string that gets converted to JSON array in RNPushNotificationHelper.sendNotification().
        info.actionKeys = JSON.stringify([
          Constants.ACCEPT_ALARM,
          Constants.REJECT_ALARM
        ])
      }

      TaskManager.addHttpsCloudTask('sendPushNotification', {
        uid: uid,
        notification: {
          notificationKey,
          userInfo: info,
          category: category
        }
      })
    },
    checkPermissions: () => {},
    scheduleSnoozeNotification: () => {},
    cancelAlarmNotifications: () => {},
    cancelSnoozeNotification: () => {},
    cancelAllNotifications: () => {},
    incrementApplicationBadgeNumber: () => {},
    decrementApplicationBadgeNumbe: () => {},
    getNotificationCategoryForIos: () => {},
    checkCriticalAlertsPermissionForIos: () => {}
  }
})()

export default NotificationManager
