import React, { useCallback } from 'react'
import ContactAvatar from './ContactAvatar'
import { useSelector } from 'react-redux'
import { I18n } from 'galarm-config'
import { contactListSelector } from 'galarm-shared/src/store/selectors'
import { Utils } from 'galarm-shared'
import { Box, Heading, List, Text } from 'grommet'

const AlarmFollowerSummary = ({ name, images }) => {
  const avatarSource = images?.avatarThumbnailUrl
    ? {
        uri: images.avatarThumbnailUrl
      }
    : undefined

  return (
    <Box direction="row" gap="small" align="center">
      <ContactAvatar avatarSource={avatarSource} size="medium" name={name} />
      <Box pad={{ vertical: 'small' }} border="bottom" flex>
        <Text border={false}>{name}</Text>
      </Box>
    </Box>
  )
}

const AlarmFollowers = ({ subscribers }) => {
  const contacts = useSelector(contactListSelector)

  let otherSubscribersCount = 0

  const subscriberUids = Object.keys(subscribers)
  const subscriberContacts = []
  subscriberUids.forEach(uid => {
    const contact = Utils.getObjectWithId(contacts, uid)
    if (contact) {
      subscriberContacts.push(contact)
    } else {
      otherSubscribersCount++
    }
  })

  subscriberContacts.sort((a, b) => {
    return a.name.localeCompare(b.name)
  })

  // const tempSubscribers = []
  // for (let i = 0; i < 5; i++) {
  //   tempSubscribers.push({
  //     name: 'Test ' + i,
  //     mobileNumber: '1234567890',
  //     avatarSource: null
  //   })
  // }

  const renderOtherSubscribers = useCallback(() => {
    if (otherSubscribersCount > 0) {
      return (
        <Text margin="xsmall">
          {I18n.t('otherSubscribersCount', { count: otherSubscribersCount })}
        </Text>
      )
    } else {
      return null
    }
  }, [otherSubscribersCount])

  return (
    <Box>
      <Heading level={4} alignSelf="center" margin="none">
        {I18n.t('alarmSubscribers')}
      </Heading>
      <Box overflow="auto" margin={{ top: 'small' }}>
        <List
          border={false}
          margin={{ vertical: 'small' }}
          pad={{ vertical: 'xsmall' }}
          data={subscriberContacts}
          // eslint-disable-next-line react/no-children-prop
          children={AlarmFollowerSummary}
        />
        {renderOtherSubscribers()}
      </Box>
    </Box>
  )
}

export default AlarmFollowers
