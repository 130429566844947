module.exports = {
  alarms: '알람',
  contacts: '연락처',
  groups: '그룹',
  settings: '설정',
  notificationsTab: '알림',
  specifyTimeInFutureForAlarm: '알람은 과거에 있을 수 없습니다.',
  specifyTimeInFutureForPreReminder: '알람 사전 알림은 이제 과거입니다.',
  specifyNameForGroup: '그룹 이름은 필수 입력 사항입니다.',
  updatingGroupName: '그룹 정보 업데이트하기.',
  unableToEditGroupName:
    '그룹 정보를 업데이트할 수 없습니다. 잠시 후 다시 시도해 주세요.',
  selectSmallerImage: '이미지가 10MB보다 큽니다. 더 작은 이미지를 선택하세요.',
  sendingVerificationCode:
    '인증 코드를 {{formattedMobileNumber}} 으로 전송합니다.',
  verifyingCode: '코드를 확인합니다.',
  creatingUserProfile: '설정...',
  unabeToCreateUserProfile:
    '지금은 계정을 설정할 수 없습니다. 잠시 후 다시 시도해 주세요.',
  unableToSendVerificationCode: '인증 코드를 보낼 수 없습니다. {{error}}',
  unableToVerifyCode: '코드를 확인할 수 없습니다. {{error}}',
  unableToRegisterUserWithoutMobileNumber:
    '설정을 수행할 수 없습니다. {{error}}',
  permissionDeniedToAccessCameraIos:
    'Galarm은 카메라에 액세스할 수 있는 권한이 없습니다. \n\n설정 > Galarm으로 이동하여 카메라에 대한 액세스를 허용합니다.',
  permissionDeniedToAccessCameraAndroid:
    'Galarm은 카메라에 액세스할 수 있는 권한이 없습니다.\n\n설정 > 앱 > Galarm > 권한으로 이동하여 카메라에 대한 액세스를 허용합니다.',
  permissionDeniedToAccessPhotosIos:
    'Galarm에는 사진에 액세스할 수 있는 권한이 없습니다.\n\n설정 > Galarm으로 이동하여 사진에 대한 액세스를 허용합니다.',
  permissionDeniedToAccessPhotosAndroid:
    'Galarm에는 사진에 액세스할 수 있는 권한이 없습니다.\n\n설정 > 앱 > Galarm > 권한으로 이동하여 저장소에 대한 액세스를 허용합니다.',
  permissionDeniedToAccessCalendarIos:
    'Galarm에 캘린더에 액세스할 수 있는 권한이 없습니다.\n설정 > 캘린더로 이동하여 Galarm에 대한 액세스를 허용합니다.',
  permissionDeniedToAccessCalendarAndroid:
    'Galarm에 캘린더에 액세스할 수 있는 권한이 없습니다.\n설정 > 앱 > Galarm > 권한으로 이동하여 캘린더에 대한 액세스를 허용합니다.',
  verificationCodeSent:
    '인증 코드가 {{formattedMobileNumber}} 으로 전송되었습니다.',
  rateTheApp:
    'Galarm이 유용하게 사용되셨기를 바랍니다. 잠시 시간을 내어 Play 스토어에서 평가해 주세요. 여러분의 성원에 감사드립니다.',
  backgroundAppRefreshDisabledPermission:
    'Galarm에 백그라운드 앱 새로 고침을 수행할 수 있는 권한이 없기 때문에 알람이 울리지 않을 수 있습니다.\n\n"사용"을 탭하고 "백그라운드 앱 새로 고침"을 활성화합니다.',
  selectedGroupUnselected:
    '그룹 또는 연락처 집합을 참가자로 선택할 수 있습니다. 이전에 선택한 그룹은 선택 해제됩니다. 계속 진행하시겠습니까?',
  selectedContactsUnselected:
    '그룹 또는 연락처 집합을 참가자로 선택할 수 있습니다. 이전에 선택한 연락처는 선택 해제됩니다. 계속 진행하시겠습니까?',
  notificationsDisabledIos:
    "Galarm에 알림 표시 권한이 없기 때문에 알람이 울리지 않습니다.\n\n설정 > Galarm > 알림으로 이동하여 '알림', '소리' 및 '배너'를 사용 설정합니다.",
  notificationsDisabledAndroid:
    "알람 알림이 화면에 표시되지 않습니다.\n\n'사용'을 탭하고 '알림'을 선택한 다음 알림을 허용합니다.",
  alarmNotificationChannelDisabled:
    "알람 알림이 화면에 팝업되지 않습니다. \n\n'사용'을 탭하고 알람 알림이 화면에 표시되도록 허용합니다.",
  alarmLockScreenNotificationlDisabled:
    "잠금 화면에는 알람 알림이 표시되지 않습니다. \n\n'사용'을 탭하고 잠금 화면에 알람 알림이 표시되도록 허용합니다.",
  alarmWillNotRingDueToNotificationsDisabledIos:
    "알림이 비활성화되어 있으므로 알람이 울리지 않습니다.\n\n설정 > 알림 > 알림으로 이동하여 '알림', '소리' 및 '배너'를 사용 설정합니다.",
  alarmWillNotRingDueToCriticalAlertsDisabledIos:
    "중요 알림이 비활성화되어 있으므로 휴대폰이 음소거된 상태에서는 알람이 울리지 않습니다.\n\n설정 > 알림 > 알림으로 이동하여 '중요 알림'을 사용 설정합니다.",
  alarmWillNotRingDueToNotificationsDisabledAndroid:
    "알림이 비활성화되어 있으므로 화면에 알람이 표시되지 않습니다.\n\n'사용'을 탭하고 '알림'을 선택한 다음 알림을 허용합니다.",
  noNotificationsScheduledAlarmInPast:
    '알람이 과거에 발생했습니다. 예약된 알림이 없습니다.',
  alarmOverAllowedLimit: {
    zero: '\n알람 한도인 {{limit}} 알람에 도달했습니다.\n\n기존 알람을 삭제하거나 재사용하세요.',
    one: '알람 한도인 {{limit}} 알람에 도달했습니다.\n\n삭제하거나 재사용할 수 있는 비활성 알람이 1개 있습니다.',
    other:
      '알람 한도인 {{limit}} 알람에 도달했습니다.\n\n삭제하거나 재사용할 수 있는 {{count}} 비활성 알람이 있습니다.'
  },
  alarmAlreadyConfirmed: '이미 확인하셨습니다.',
  alarmAlreadyDeclined: '이미 거절하셨습니다.',
  participantMovedToPosition: '{{name}} 위치로 이동 {{count}}.',
  memberIsFirstParticpant: '{{name}} 가 첫 번째 참가자입니다.',
  memberIsLastParticpant: '{{name}} 가 마지막 참가자입니다.',
  cantContinueWithRegistrationWhenOffline:
    '장치가 오프라인 상태이므로 등록을 계속할 수 없습니다.',
  cantContinueWithRegistrationWhenOfflineWithTryAgain:
    '장치가 오프라인 상태이므로 등록을 계속할 수 없습니다. 인터넷에 연결되어 있는지 확인하세요.',
  confirmBlockContact:
    '더 이상 {{name}} 에서 알람을 받지 않게 됩니다. 계속하시겠습니까?',
  confirmUnblockContact:
    '{{name}} 차단을 해제하면 차단된 알람도 복원됩니다. 계속 진행하시겠습니까?',
  unblockingContact: '{{name}} 차단 해제 및 알람 복원하기.',
  unableToUnblockContact: '연락처 차단을 해제할 수 없습니다. {{error}}',
  blockingContact: '차단 중 {{name}}.',
  unableToBlockContact: '연락처를 차단할 수 없습니다. {{error}}',
  cantAddContactToAlarmBecauseBlocked:
    '{{name}} 을 차단했습니다. 알람 참여자로 추가하려면 차단을 해제하세요.',
  cantAddContactToGroupBecauseBlocked:
    '{{name}} 님을 차단했습니다. 그룹 멤버로 추가하려면 차단을 해제하세요.',
  sendMessageNotAllowedForPastAlarms:
    '비활성 알람에 대해서는 메시지를 보낼 수 없습니다.',
  nameIsRequired: '이름은 필수 입력 사항입니다.',
  confirmDisableGroupAlarm:
    '그룹 알람을 비활성화하면 다른 참가자에 대한 알람도 비활성화됩니다. 계속하시겠습니까?',
  removeMemberFromGroup: '이 그룹에서 {{name}} 을(를) 제거하시겠습니까?',
  leaveGroupConfirm: '이 그룹에서 탈퇴하시겠습니까?',
  deviceOffline:
    '장치가 오프라인 상태입니다. 인터넷에 연결되어 있는지 확인한 후 다시 시도하세요.',
  cantUpdateGroupDefaulConfigWhenOffline:
    '장치가 오프라인 상태이므로 수정된 그룹 구성은 향후 알람에 적용되지 않습니다.',
  specifyFeedback: '피드백이 필요합니다.',
  specifyEmailId: '이메일 주소는 필수 입력 사항입니다.',
  specifyValidEmailId: '이메일 주소가 유효하지 않습니다.',
  specifyProblem: '문제 설명이 필요합니다.',
  appInstalledUsingSameNumberOnDifferentPhone:
    '동일한 번호를 사용하여 다른 장치에 Galarm을 설치했습니다. 이 디바이스의 앱이 재설정되었습니다.',
  invalidUid:
    '이 사용자 계정은 더 이상 존재하지 않습니다. 이 디바이스의 Galarm 앱이 재설정되었습니다.',
  accountDeleted: '계정이 삭제되었습니다.',
  deleteAccount: '내 계정 삭제',
  deletingAccount: '계정 삭제하기',
  howCanWeImprove: '유감입니다. 어떻게 개선할 수 있을까요?',
  noMusicAlert: '음악 파일을 찾을 수 없습니다.',
  noLongerAvailableMusic:
    '{{fileName}} 은 더 이상 사용할 수 없습니다. 벨소리가 다음과 같이 변경되었습니다. {{defaultRingtoneName}}',
  userInactiveTitle: '돌아온 것을 환영합니다!',
  userInactiveMessage: '중요한 일에 대한 알람을 설정하고 놓치지 마세요.',
  createAlarm: '알람 만들기',
  updatedAppAvailable: '새 버전의 앱을 사용할 수 있습니다.',
  alarmOccurrenceDisabled: '이 발생에 대해 비활성화됨',
  ownAlarmOccurrenceDone: {
    zero: '완료됨으로 표시 {{timeString}}',
    one: '{{timeString}} 에서 완료됨으로 표시 {{dateString}}'
  },
  ownAlarmOccurrenceSkipped: {
    zero: '에서 건너뛰기 {{timeString}}',
    one: '건너뛰기 {{timeString}} 에서 {{dateString}}'
  },
  enabledAlarmsCount: {
    one: '1개의 알람이 활성화되었습니다.',
    other: '{{count}} 알람을 활성화합니다.'
  },
  enabledAndNotEnabledAlarmsCount: {
    one: '알람 1개가 활성화됩니다. 향후 발생하지 않는 알람은 비활성화된 상태로 유지됩니다.',
    other:
      '{{count}} 알람을 활성화합니다. 향후 발생하지 않는 알람은 비활성화된 상태로 유지됩니다.'
  },
  disabledAlarmsCount: {
    one: '알람 1개를 비활성화했습니다.',
    other: '{{count}} 알람을 비활성화합니다.'
  },
  ownAlarmOccurrenceUnacknowledged: '응답하지 않음',
  participantAlarmOccurrenceDone: {
    zero: '{{participantName}} 에서 완료 표시 {{timeString}}',
    one: '작성자: {{participantName}} 에 {{timeString}} {{dateString}}'
  },
  participantAlarmOccurrenceSkipped: {
    zero: '{{participantName}} 에서 건너뛰기 {{timeString}}',
    one: '건너뛰기 {{participantName}} 에서 {{timeString}} {{dateString}}'
  },
  participantAlarmOccurrenceUnacknowledged: '{{participantName}} 응답하지 않음',
  groupAlarmOccurrenceConfirmed: {
    zero: '다음에서 확인되었습니다. {{timeString}}',
    one: '{{timeString}} 에서 확인 {{dateString}}'
  },
  groupAlarmOccurrenceDeclined: {
    zero: '다음에서 거부됨 {{timeString}}',
    one: '{{timeString}} 에서 거부됨 {{dateString}}'
  },
  warning: '경고',
  info: '정보',
  confirm: '확인',
  error: '오류',
  cantUploadImage: '이미지를 업로드할 수 없습니다.',
  cantDeleteImage: '이미지를 삭제할 수 없습니다.',
  cantEditGroup: '그룹을 편집할 수 없습니다.',
  cantAddNewContact: '새 연락처를 추가할 수 없습니다.',
  cantDeleteContact: '연락처를 삭제할 수 없습니다.',
  cantEditContact: '연락처를 편집할 수 없습니다.',
  cantSaveAlarm: '알람을 저장할 수 없음',
  cantSaveGroup: '그룹을 저장할 수 없음',
  cantReloadContacts: '연락처를 다시 로드할 수 없음',
  cantTakePhoto: '사진 촬영 불가',
  cantExportToCalendar: '캘린더로 내보낼 수 없음',
  cantOpenPhotoLibrary: '사진 보관함을 열 수 없음',
  cantDeleteAlarmHistory: '알람 기록을 삭제할 수 없음',
  cannotDuplicateAlarm: '알람을 복제할 수 없음',
  upgradeToPremiumToDuplicateAlarm:
    '프리미엄으로 업그레이드하면 더 많은 그룹 및 친구 알람을 만들 수 있습니다.',
  confirmAcceptAlarm: '알람 수락?',
  confirmRejectAlarm: '거절 알람?',
  deleteAlarm: '알람을 삭제하시겠습니까?',
  restoreAlarmConfirmation: '알람 복원?',
  respondToRepeatingAlarm: {
    zero: '{{alarmCreatorName}} 에 의해 {{alarmName}} 알람 수신자로 추가되었습니다. {{alarmTimeString}} {{alarmRepetitionString}} 에서 알림이 시작됩니다. {{alarmDateString}}',
    one: '{{alarmCreatorName}} 에 의해 {{alarmName}} 알람 참가자로 추가됩니다. {{alarmTimeString}} {{alarmRepetitionString}} 에서 알림을 받게 됩니다. {{alarmDateString}}'
  },
  respondToOneTimeAlarm: {
    zero: '{{alarmCreatorName}} 에 의해 {{alarmName}} 알람 수신자로 추가되었습니다. {{alarmTimeString}} 으로 알림이 전송됩니다. {{alarmDateString}}',
    one: '{{alarmCreatorName}} 에 의해 {{alarmName}} 알람 참가자로 추가됩니다. {{alarmTimeString}} 에서 알림을 받게 됩니다. {{alarmDateString}}'
  },
  cantMoveMemberUp: '{{name}} 위로 이동할 수 없음',
  cantMoveMemberDown: '{{name}} 아래로 이동할 수 없음',
  decline: '거절',
  cantEditProfile: '프로필을 수정할 수 없습니다.',
  cantSelectContact: '연락처를 선택할 수 없습니다.',
  cantSendMessage: '메시지를 보낼 수 없습니다.',
  cantSendVerificationCode: '인증 코드를 보낼 수 없습니다.',
  confirmBlockContactTitle: '차단 연락처 확인',
  confirmUnblockContactTitle: '연락처 차단 해제 확인',
  cantSaveProfile: '프로필을 저장할 수 없습니다.',
  cantBlockContact: '연락처 차단 불가',
  cantUnblockContact: '연락처 차단을 해제할 수 없음',
  cantLoadEarlierMessages: '이전 메시지를 로드할 수 없음',
  cantRestoreAlarm: '알람을 복원할 수 없음',
  cantSendFeedback: '피드백을 제출할 수 없습니다.',
  cantSubmitProblem: '문제를 제출할 수 없습니다.',
  cantDeletePastAlarmsInBulk: '비활성 알람을 일괄 삭제할 수 없음',
  cantAddNewGroup: '새 그룹을 추가할 수 없습니다.',
  alreadySnoozing: '이미 잠자기 중',
  markAlarmUndone:
    "{{durationString}} 전에 이 알람을 '완료'로 표시하셨습니다. 취소하시겠습니까?",
  markAlarmUnskipped:
    '{{durationString}} 전에 이 알람을 건너뛰었습니다. 취소하시겠습니까?',
  cantUndo: '실행 취소할 수 없음',
  fetchMusicFromPhone: '사용 가능한 음악 찾기.',
  enableAllAlarmsInCategory: '이 카테고리의 모든 알람을 사용 설정하시겠습니까?',
  disableAllAlarmsInCategory: '이 카테고리의 모든 알람을 비활성화하시겠습니까?',
  allAlarmsAlreadyEnabled:
    '이 카테고리의 모든 알람은 이미 사용 설정되어 있습니다.',
  allAlarmsAlreadyDisabled:
    '이 카테고리의 모든 알람은 이미 비활성화되어 있습니다.',
  time: '시간',
  timeForBuddy: '버디를 위한 시간',
  timeForYou: '다음 주소로 알림',
  name: '이름',
  title: '제목',
  notes: '참고',
  repeat: '반복',
  preReminder: '사전 알림',
  remove: '제거',
  preReminderShortAlert: '{{duration}} 사전 알림',
  preReminderTitle: '{{alarmName}} 에서 {{date}}',
  preReminderGroupHelperText:
    '이 사전 알림은 나만을 위한 것입니다. 다른 알람 참여자는 알람을 받은 후 자신만의 사전 알림을 설정할 수 있습니다.',
  addPreReminder: '사전 알림 추가',
  helperTextForPreReminder:
    '사전 알림을 제거하려면 상단의 재설정 버튼을 탭합니다.',
  edit: '편집',
  delete: '삭제',
  creator: '크리에이터',
  useAsDefaultGroupConfiguration: '향후 알람에 적용',
  done: '완료',
  skipped: '건너뛰기',
  save: '저장',
  members: '회원',
  mobileNumber: '전화번호',
  enter: '입력',
  addMembers: '멤버 추가',
  respond: '응답',
  yes: '예',
  no: '아니요',
  cancel: '취소',
  participants: '참가자',
  takePhoto: '사진 찍기',
  chooseFromLibrary: '라이브러리에서 선택',
  resendCode: '코드 다시 보내기',
  register: '등록하기',
  inviteFriends: '이 앱 공유',
  invite: '초대',
  incorrectNumber: '번호가 잘못되었나요?',
  date: '날짜',
  cascadingAlarmInterval: '참가자 알림 사이의 시간',
  ringParticipantAlarmsByDefault: '수락하지 않고 알람 울리기',
  alarmRingtone: '알람 벨소리',
  pickASong: '휴대폰에서 음악 선택',
  privacyPolicy: '개인정보 보호정책',
  sendFeedback: '피드백',
  galarmWeb: 'Galarm 웹',
  galarmEnterprise: 'Galarm Pro',
  galarmAdmin: 'Galarm 관리자',
  troubleshooting: '문제 해결',
  alarmDidntRingHelp: '도와주세요! 알람이 울리지 않습니다.',
  autoStartSettingHelperText:
    '휴대폰에 자동 시작 설정이 있는 경우 Galarm에 대해 자동 시작이 활성화되어 있는지 확인하세요.',
  batteryOptimizationHelperText: 'Galarm에 대한 배터리 최적화 비활성화',
  troubleshootingHelperText:
    '다음 설정으로 인해 알람이 예상대로 울리지 않을 수 있습니다. 이러한 설정을 검토해 보세요.',
  getMoreHelpText: '아직도 문제가 있으신가요?',
  autoStartSetting: '자동 시작',
  batteryOptimization: '배터리 최적화',
  battery: '배터리',
  rateTheAppSettings: '이 앱 평가하기',
  about: '정보 및 도움말',
  leaveGroup: '그룹 탈퇴',
  removeGroup: '그룹 제거',
  viewMember: '보기 {{memberName}}',
  removeMember: '제거 {{memberName}}',
  changeMemberState: '알림 대상 변경 {{memberName}}',
  changeYourGroupState: '알림을 받을 수 있는 사용자 변경',
  numMembersInGroup: '회원: {{count}}',
  notifications: '앱 알림이 비활성화됨',
  backgroundAppRefresh: '백그라운드 앱 새로 고침이 비활성화됨',
  groupsInCommon: '공통 그룹',
  alarmsInCommon: '공통 알람',
  close: '닫기',
  responsePending: '당신의 반응은?',
  later: '나중에',
  install: '설치',
  all: '모두',
  numYears: { one: '1 년', other: '{{count}} 년' },
  numMonths: { one: '1개월', other: '{{count}} 개월' },
  numDays: { one: '1일', other: '{{count}} 일수' },
  hours: { one: '1시간', other: '{{count}} 시간' },
  minutes: { one: '1분', other: '{{count}} 분' },
  seconds: { one: '1초', other: '{{count}} 초' },
  minutesSmall: '{{count}} 분',
  secondsSmall: '{{count}} 초',
  hoursAfter: { one: '1시간 후', other: '{{count}} 시간 후' },
  minutesAfter: { one: '1분 후', other: '{{count}} 몇 분 후' },
  hoursAfterBuddy: {
    one: '친구로부터 1시간 후',
    other: '{{count}} 버디 후 몇 시간'
  },
  minutesAfterBuddy: { one: '버디 후 1분', other: '{{count}} 버디 후 몇 분' },
  longerThanAnHour: '> 1시간 이상',
  licenses: '타사 라이선스',
  version: '버전',
  termsOfService: '서비스 약관',
  showPrivacyPolicy: '> 개인정보 보호정책',
  showTermsOfService: '> 서비스 약관',
  showLicenses: '타사 라이선스 > 타사 라이선스',
  change: '변경',
  snooze: '스누즈',
  ringOnVibrate: '무음으로 벨 울리기',
  vibrate: '진동',
  ringOnEarphoneOnly: '이어폰에서만 울림 알람',
  ringOnSpeakerAndEarphone: '스피커 및 이어폰의 벨소리 알람',
  whenEarphoneIsConnected: '이어폰이 연결된 경우',
  tapGesture: '탭',
  slideGesture: '슬라이드',
  silent: 'Silent',
  volume: '볼륨',
  soundAndVibration: '소리 및 진동',
  advancedSettings: '고급 설정',
  gestureToDismissAnAlarm: '알람을 해제하는 제스처',
  someNotificationsAreMuted: '일부 알림이 음소거됩니다.',
  allNotificationsEnabled: '모든 알림이 활성화됨',
  notificationSettings: '알림 설정',
  galarmWebNotifications: 'Galarm 웹 알림',
  alarmsfromOthersNotifications: '참가자 알람 알림',
  sharedAlarmNotifications: '공유 알람 알림',
  alarmChatNotifictions: '알람 채팅 알림',
  groupNotifications: '그룹 알림',
  alarmAcknowledgementNotifications: '알람 확인 알림',
  send: '보내기',
  prev: '이전',
  snoozing: '{{timeString}}',
  specifySnoozeDuartion: '스누즈 기간 지정',
  chooseDifferentDuration: '다른 기간 선택',
  noCalendarsFound: '장치에서 캘린더를 찾을 수 없습니다.',
  eventWillEndAt: '이벤트는 {{timeString}} 에서 종료됩니다.',
  selectDurationWithinTheSameDay: '이벤트는 하루의 경계를 넘을 수 없습니다.',
  cannotMapRepeatTypeInTheDeviceCalendar:
    "알람의 '반복' 설정이 캘린더에서 지원되지 않기 때문에 '캘린더로 내보내기'를 할 수 없습니다.",
  alarmSavedToCalendar: '알람이 캘린더에 성공적으로 저장되었습니다.',
  faq: '자주 묻는 질문',
  openSettings: '설정 열기',
  openAppSettings: '앱 설정 열기',
  alreadyDone: '완료',
  enableAutoStart: '자동 시작 사용',
  restrictedApps: '제한된 앱',
  unmonitoredApps: '모니터링되지 않는 앱',
  enableProtected: '보호된 앱',
  manageAppLaunch: '앱 실행 관리',
  unblockContact: '연락처 차단 해제',
  blockContact: '연락처 차단',
  call: '통화 {{name}}',
  text: '텍스트 {{name}}',
  chat: '채팅',
  ok: '확인',
  stopSnooze: '스누즈 중지',
  emailId: '이메일 주소',
  feedback: '피드백',
  submit: '제출하기',
  reportAProblem: '문제 신고',
  enableAlarm: '알람 사용',
  restoreAlarm: '알람 복원',
  deletePastAlarms: '비활성 알람 삭제',
  accept: '수락',
  defaultPersonalAlarmName: '개인 알림',
  defaultGroupAlarmName: '그룹 이벤트',
  defaultRecipientAlarmName: '버디 알람',
  search: '검색',
  recent: '최근',
  upcoming: '예정',
  missed: '누락됨',
  expired: '만료됨',
  past: '과거',
  active: '활성',
  inactive: '비활성',
  allAlarms: '모든 알람',
  myAlarms: '내 알람',
  participantAlarms: '참가자 알람',
  pendingParticipantAlarms: '다른 사람의 새로운 알람',
  alarmsWithUnreadChatMessages: '새 채팅을 통한 알람',
  systemNotifications: '시스템 메시지',
  missedAlarms: '부재중 알람',
  recentlyDeletedAlarms: '최근 삭제된 알람',
  searchAlarms: '알람 검색',
  participant: '참가자',
  you: '당신',
  none: '없음',
  onceOnly: '한 번만',
  weekdays: '평일',
  weekends: '주말',
  everyDayString: '매일',
  everySunday: '매주 일요일',
  everyMonday: '매주 월요일',
  everyTuesday: '매주 화요일',
  everyWednesday: '매주 수요일',
  everyThursday: '매주 목요일',
  everyFriday: '매주 금요일',
  everySaturday: '매주 토요일',
  sunday: '일요일',
  monday: '월요일',
  tuesday: '화요일',
  wednesday: '수요일',
  thursday: '목요일',
  friday: '금요일',
  saturday: '토요일',
  january: '1월',
  february: '2월',
  march: '3월',
  april: '4월',
  may: '5월',
  june: '6월',
  july: '7월',
  august: '8월',
  september: '9월',
  october: '10월',
  november: '11월',
  december: '12월',
  jan: '1월',
  feb: '2월',
  mar: '3월',
  apr: '4월',
  shortMay: '5월',
  jun: '6월',
  jul: '7월',
  aug: '8월',
  sep: '9월',
  oct: '10월',
  nov: '11월',
  dec: '12월',
  sun: 'Sun',
  mon: '월',
  tue: '화',
  wed: '수',
  thu: '목',
  fri: '금',
  sat: '토',
  first: '1번째',
  second: '2번째',
  third: '3번째',
  fourth: '4번째',
  fifth: '5번째',
  every: '모든',
  oddNumberedDays: '홀수 날짜',
  evenNumberedDays: '짝수 날짜',
  noParticipants: '참가자 없음',
  typeYourMessage: '메시지 입력',
  cantPostMessageToPastAlarm: '비활성 알람에 메시지를 게시할 수 없습니다.',
  groupName: '그룹: {{name}}',
  alarm: '알람',
  confirmed: '확인됨',
  declined: '거부됨',
  Done: '완료',
  Skip: '건너뛰기',
  Confirm: '확인',
  Decline: '거절',
  Accept: '수락',
  today: '오늘',
  on: 'on',
  recipientAlarm: '버디 알람',
  recipient: '수신자',
  remindMeAfter: '알림',
  remindMeIn: '알림 설정',
  newRecipientAlarm: '새로운 버디 알람',
  editRecipientAlarm: '버디 알람 편집',
  newInstantAlarm: '새로운 인스턴트 알람',
  instantAlarms: '즉시 알람',
  alarmDone: '알람 완료로 표시됨',
  alarmSkipped: '알람 건너뛰기',
  undo: '실행 취소',
  enterVerificationCode: '아래에 4자리 코드를 입력하세요:',
  initializing: '초기화',
  tryAgain: '다시 시도',
  nMore: '+ {{count}} 더보기',
  enableNotification: '사용',
  enableAlarmNotificationChannel: '사용',
  enableBackgroundAppRefresh: '사용',
  viewImpactedAlarms: '알람',
  viewImpactedGroups: '그룹',
  dismiss: '해지',
  minsInterval: '+ {{count}}m',
  hoursInterval: '+ {{count}}h',
  daysInterval: '+ {{count}}d',
  snoozeUnitHours: '시간',
  snoozeUnitMinutes: '분',
  snoozeUnitDays: '일수',
  selectMinutes: '회의록 선택',
  selectHours: '시간 선택',
  selectDays: '날짜 선택',
  selectHoursAndMinutes: '시간 및 분 선택',
  enterDuration: '기간',
  snoozeUnit: '단위',
  specifySnoozeUnit: '스누즈 단위 지정',
  specifySnoozeDuration: '스누즈 기간 지정',
  duration: '기간',
  quickAlarmName: {
    zero: '{{minutes}} 최소 알림',
    one: '{{hours}} 시간 알림',
    other: '{{hours}} 시간 {{minutes}} 분 알림'
  },
  alarmTitleForGoogleVoiceTimerCommand: '{{durationString}} 알림',
  addedAlarmFromVoiceCommand:
    '{{alarmName}} 에 알람을 추가했습니다. {{dateString}}',
  addedTimerFromVoiceCommand:
    '다음에 대한 타이머가 추가되었습니다. {{duration}}',
  clear: '지우기',
  ringtoneDuration: '벨소리 지속 시간',
  chooseDuration: '기간을 탭합니다. 여러 번 탭할 수 있습니다.',
  newAlarm: '새 알람',
  quickAlarm: '빠른 알림',
  quickAlarmWillRingAt: '{{timeString}} 에서 알람이 울립니다.',
  preReminderWillRingAt:
    '이 알람에 대한 사전 알림이 울립니다. \n {{timeString}} 에서 사전 알림이 울립니다.',
  random: '랜덤',
  enableAllAlarms: '모든 알람 사용',
  disableAllAlarms: '모든 알람 비활성화',
  deleteAllAlarms: '모든 알람 삭제',
  hideCategory: '카테고리 숨기기',
  unhideCategory: '카테고리 숨기기 해제',
  dontShowHiddenCategories: '숨겨진 카테고리 표시 안 함',
  showHiddenCategories: '숨겨진 카테고리 표시',
  welcomeAlarmsTitle: '시작해보자!',
  welcomeAlarmsMessage: '다음은 일반적으로 사용되는 몇 가지 알람입니다:',
  onboardingAlarms: '온보딩 알람',
  hiddenAlarmCategories: '숨겨진 카테고리',
  restorePurchases: '구매 복원',
  noAvailablePurchasesFound:
    "계정에 사용 가능한 구매를 찾을 수 없습니다.\n\n복원할 수 없는 유효한 구매가 있는 경우 '설정 > 피드백'을 통해 피드백을 보내주세요.",
  restoringPurchases: '구매 복원',
  fetchingContacts: '어떤 연락처가 Galarm을 사용하고 있는지 확인',
  enterNameAndUploadPhotoForUser:
    '아래에 이름을 입력하고 선택적으로 프로필 사진을 업로드합니다.',
  enterNameAndUploadPhotoForGroup:
    '아래에 이름을 입력하고 선택적으로 단체 사진을 업로드합니다.',
  noContactsWithAppInstalled: '연락처에서 아무도 Galarm을 사용하지 않습니다.',
  galarmContacts: { one: '갈람 연락처 1개', other: '{{count}} Galarm 연락처' },
  contactsPendingInvitation: {
    one: '대기 중인 초대 1건',
    other: '{{count}} 보류 중인 초대'
  },
  otherContacts: { one: '다른 연락처 1명', other: '{{count}} 기타 연락처' },
  over100Contacts: '100개 이상의 다른 연락처',
  noAlarms: '알람이 없습니다. 를 탭하여 새 알람을 추가합니다.',
  noContactsWeb: {
    one: 'Galarm을 사용하는 연락처를 찾을 수 없습니다. 연락처에 액세스할 수 있는 권한이 없거나 연락처에 Galarm을 사용하는 사람이 없는 경우입니다. 모바일 앱에서 연락처를 동기화할 수 있습니다.\n\n다양한 방법으로 Galarm을 사용하는 연락처를 알람에 추가하여 앱의 이점을 활용할 수 있습니다.',
    other: '알람에 연락처를 추가하려면 모바일 앱에서 등록하세요.'
  },
  viewingAlarmsInCommon: '다음과 공통되는 알람 {{name}}',
  viewingGroupsInCommon: '공통 그룹 {{contactName}}',
  changeGroupMemberConfigTextForCascadingAlarm:
    '활성 참가자에게는 표시된 순서대로 알림이 전송됩니다.',
  changeGroupMemberConfigTextForSimultaneousAlarm:
    '활동 중인 참가자에게만 알림이 전송됩니다.',
  changeGroupMemberStateText: {
    zero: '"{{groupName}}" 그룹에 대한 알람이 생성될 때 알림을 받을 수 있는 구성원을 변경합니다.\n\n허용된 구성원이 알람을 만든 경우에만 알림을 받게 됩니다.',
    one: '"{{groupName}}" 그룹에 대한 알람이 생성되면 {{memberName}} 에 알릴 수 있는 멤버를 변경합니다.\n\n{{memberName}} 알람이 허용된 구성원에 의해 생성된 경우에만 알림이 전송됩니다.'
  },
  allowed: '허용됨',
  notAllowed: '허용되지 않음',
  changeStatus: '허용된 구성원',
  noUpcomingAlarms: '예정된 알람 없음',
  showMore: '자세히 보기',
  nMorePastAlarms: {
    one: '비활성 알람 1개 추가',
    other: '{{count}} 더 많은 비활성 알람'
  },
  shareAppMessage:
    '저는 Galarm 앱을 사용해 미리 알림을 만들어 모든 일을 적시에 완료합니다. 이 앱을 통해 서로를 미리 알림에 포함시킬 수도 있습니다. 이 혁신적인 알람 및 리마인더 앱을 다운로드하는 것을 추천합니다. {{downloadLink}}',
  addingMembers: '멤버 추가하기.',
  unableToAddMembers: '멤버를 추가할 수 없습니다. {{error}}',
  removingMember: '회원 제거하기.',
  unableToRemoveMember: '회원을 삭제할 수 없습니다. {{error}}',
  leavingGroup: '그룹에서 나가기.',
  unableToLeaveGroup: '그룹을 탈퇴할 수 없습니다. {{error}}',
  expiredPersonalAlarmForCreator: {
    zero: '이 알람은 {{durationString}} 전에 만료되었습니다. "완료"를 탭하여 알람을 완료합니다.',
    one: "이 알람은 {{durationString}} 전에 만료되었습니다. '완료'를 탭하여 알람을 완료하고 참가자에게 알립니다.",
    other:
      "이 알람은 {{durationString}} 전에 만료되었습니다. '완료'를 탭하여 알람을 완료하고 참가자에게 알립니다."
  },
  donePersonalAlarmForCreator:
    '이 알람은 {{durationString}} 전에 만료되었습니다. 알람이 완료됨으로 표시되어 있습니다.',
  skippedPersonalAlarmForCreator:
    '이 알람은 {{durationString}} 전에 만료되었습니다. 알람을 건너뛰었습니다.',
  expiredGroupAlarm:
    '이 알람은 {{durationString}} 전에 만료되었습니다. 참여를 확인해 주세요.',
  expiredPersonalAlarmForParticipant:
    '이 알람은 {{durationString}} 전에 만료되었습니다. {{creatorName}} 으로 알려주세요.',
  alarmDurationAndroid: '지금부터 {{durationString}} 으로 알람을 설정합니다.',
  alarmDurationIos: '지금부터 {{durationString}} 으로 알람을 설정합니다.',
  alarmFeedbackHeader:
    '디버깅 목적으로 이 알람의 정보 일부를 전송하게 됩니다. 알람 이름, 메모, 참가자 등의 개인 데이터는 공유되지 않습니다.',
  privacyPolicyHeader:
    '개인 정보 보호에 대한 존중은 저희의 시계에서 끊임없이 움직입니다. 사용자 여러분과 마찬가지로 저희도 개인정보를 소중히 여기기 때문에 애플리케이션 설치에 필요하거나 법에서 요구하는 경우를 제외하고는 사용자의 개인정보를 누구와도 공유하지 않는다는 점을 알아주시기 바랍니다. 어떤 경우에도 사용자의 정보를 절대 판매하지 않습니다. 간단합니다!\n \n사용자가 만든 알람은 사용자의 디바이스에서만 볼 수 있으며, 알람에 다른 참가자가 포함된 경우 해당 참가자의 디바이스에서만 볼 수 있습니다.\n \n당사는 Galarm의 연락처 탭을 채우기 위해서만 휴대폰의 주소록/연락처 목록에 액세스합니다. 당사는 다른 목적으로 해당 목록을 저장하지 않습니다. 당사는 귀하의 주소록을 존중합니다!\n \n당사는 사용자가 좋은 프로필 사진을 설정할 수 있도록 하기 위해서만 사용자의 카메라와 사진에 액세스합니다. 이 사진은 내 아바타 또는 내가 편집한 그룹의 프로필 사진으로 표시됩니다. 설정한 알람의 구성원인 다른 Galarm 사용자는 이 사진을 볼 수 있습니다.\n\n당사는 등록 과정에서 코드를 요청하고 확인하는 등 모든 요청과 함께 기기의 공인 IP와 고유 ID를 전송합니다. 이 정보는 계정과 연결되지 않으며 잠재적인 악의적인 활동을 확인하는 데만 사용됩니다.\n \n수집하는 정보 및 사용 방법에 대한 자세한 내용은 전체 개인정보 처리방침을 참조하시기 바랍니다.',
  termsOfServiceHeader:
    'Galarm 앱을 사용함으로써 귀하는 이 모바일 앱에 대한 귀하의 액세스 및 사용과 이 앱이 제공하는 기능 및 서비스에 적용되는 당사의 서비스 약관에 동의하게 됩니다. \n\nGalarm의 모토는 간단합니다. 어떤 이벤트도 놓치지 않도록 도와드리는 것입니다!  기상 알림을 위한 개인 알람 설정, 약속 시간 지키기, 제때 약 복용과 같은 활동에 대한 알림 설정, 영화 나들이, 스포츠 이벤트, 파티와 같은 그룹 활동을 조직하기 위한 그룹 알람 생성 등 Galarm을 사용하면 소셜 알람 시계를 통해 서로를 상기시키고 서로의 상태를 추적하고 소통할 수 있습니다.\n\n본 약관에 동의하는 경우에만 Galarm을 사용하세요. 아래는 약관 링크입니다.',
  acknowledgedPersonalAlarm:
    '{{durationString}} 전에 알람을 "완료"로 표시했습니다.',
  skippedPersonalAlarm: '{{durationString}} 전에 알람을 건너뛰었습니다.',
  authenticatingWithServer: '연결 중...',
  selectParticipantsForPersonalAlarm:
    '개인 알람의 백업 역할을 할 그룹 또는 사람들을 선택합니다.',
  selectParticipantsForGroupAlarm:
    '그룹 활동을 위해 그룹 또는 사용자 집합을 선택합니다.',
  acknowledgedGroupAlarm:
    '이 알람을 {{acknowledgedAsString}} 전에 {{durationString}}.',
  acknowledgedExpiredGroupAlarm:
    '이 알람은 {{durationString}} 전에 만료되었습니다. 이 알람은 {{acknowledgedAsString}}.',
  acknowledgedPersonalAlarmForParticipant:
    '{{creatorName}} {{durationString}} 전에 알람을 "완료"로 표시했습니다.',
  acknowledgedExpiredPersonalAlarmForParticipant:
    '이 알람은 {{durationString}} 전에 만료되었습니다. {{creatorName}} 알람이 "완료"로 표시되었습니다.',
  entityNotFound:
    '죄송합니다! 선택한 {{entityType}} 을 찾을 수 없습니다. 아직 검색 중이거나 삭제되었을 수 있습니다.',
  goBack: '뒤로 가기',
  uploadingImageToServer: '서버에 이미지 업로드하기.',
  deletingImageFromServer: '서버에서 이미지 삭제하기',
  errorUploadingImageToServer:
    '서버에 이미지를 업로드하는 동안 오류가 발생했습니다. 나중에 다시 시도하세요.',
  unableToRemoveGroup: '그룹을 제거할 수 없습니다. {{error}}',
  addingGroup: '그룹 추가하기.',
  editingGroup: '편집 그룹.',
  unableToAddGroup: '그룹을 추가할 수 없습니다. {{error}}',
  unableToEditGroup: '그룹을 수정할 수 없습니다. {{error}}',
  thanksForFeedback: '개선에 도움을 주셔서 감사합니다.',
  licensesHeader:
    'Galarm에는 타사에서 라이선스를 취득한 소프트웨어가 포함되어 있습니다. 해당 저작자 표시 및 라이선스 정보는 타사 라이선스 페이지를 참조하세요.',
  alarmAlreadyInSnooze: '{{durationString}} 에서 알림을 받게 됩니다.',
  carrierChargesMayApply: '통신사 요금이 부과될 수 있습니다.',
  verficationCodeInfo: '4자리 코드가 포함된 문자 메시지를 받게 됩니다.',
  selectCascadingInterval:
    '알람을 완료로 표시하지 않으면 첫 번째 참가자에게는 알람 시간 후 {{firstParticipantDurationString}}, 두 번째 참가자에게는 알람 시간 후 {{secondParticipantDurationString}} 등의 알림이 전송됩니다.',
  reorderParticipantsScreenDescription:
    '참가자에게는 표시된 순서대로 알림이 전송됩니다.',
  noMembers: '회원 없음',
  selectedGroupDescription: {
    zero: '{{numActiveMembers}} 참가자',
    one: '{{numActiveMembers}} 활성 및 {{numInactiveMembers}} 비활성 참가자',
    other: '{{numActiveMembers}} 활성 및 {{numInactiveMembers}} 비활성 참가자'
  },
  groupAlarmWillRingAtSameTime:
    '모든 참가자에게는 {{timeString}} 으로 알림이 전송됩니다.',
  welcomeToGalarm: 'Galarm에 오신 것을 환영합니다.',
  checkingConnection: '연결 확인',
  deletePastAlarmsTitle: '다음보다 오래된 비활성 알람을 삭제합니다.',
  deletePastAlarmsOption: {
    zero: '{{option}} (알람 0건)',
    one: '{{option}} (알람 1개)',
    other: '{{option}} ({{count}} 알람)'
  },
  deletePastAlarmsNotificationMessage: '비활성 알람을 삭제하여 재사용하세요.',
  chooseSnoozeInterval: '에서 다시 알림',
  personalAlarmDescription:
    '기상, 약 복용 또는 기타 작업을 위해 개인용 알람을 사용하세요. 선택적으로 알람을 놓친 경우 알림을 받을 참가자를 추가하여 알림을 받을 수 있도록 할 수 있습니다. 알람을 완료로 표시하면 참여자에게도 알림이 전송됩니다.',
  groupAlarmDescription:
    '나들이, 파티 또는 기타 이벤트와 같은 그룹 활동에 그룹 알람을 사용하세요. 모든 참가자에게 동시에 알람이 울리며, 참가자들은 알람을 통해 자신의 참여를 확인할 수 있습니다.',
  recipientAlarmDescription:
    '다른 사람("친구")에게 알람을 만들어 해야 할 일을 상기시켜 줍니다. 버디가 알람을 놓칠 경우 알림을 받을 수 있습니다. 버디가 알람을 완료로 표시하면 본인에게도 알림이 전송됩니다.\n  \n알람은 내 시간대에 맞춰 생성된다는 점에 유의하세요.',
  whatsThis: '이게 뭐죠?',
  alarmCurrentlyDisabled: '이 알람은 현재 비활성화되어 있습니다.',
  alarmCurrentlyRejected: '이 알람은 현재 거부되었습니다.',
  currentlyTypingUsers: {
    one: '{{currentlyTypingUsers}} 입력 중입니다...',
    other: '{{currentlyTypingUsers}} 입력 중입니다...'
  },
  noSearchResultsFound: '{{type}} 찾을 수 없음',
  noGroupsCreated: '그룹을 만들지 않았습니다.',
  noGroupsWeb: {
    one: '그룹을 만들지 않았습니다.',
    other: '그룹을 만들고 알람에 추가하려면 모바일 앱에서 등록하세요.'
  },
  internalErrorDuringRegistration:
    '사용자 등록 중에 내부 오류가 발생했습니다. 몇 분 후에 다시 시도해 주세요.',
  selectRecipientAlarmInterval: {
    zero: '수신자와 동시에 알림을 받게 됩니다.',
    one: "수신자가 알람을 '완료'로 표시하지 않으면 알람 시간이 지난 후 {{durationString}} 으로 알림이 전송됩니다.",
    other:
      "수신자가 알람을 '완료'로 표시하지 않으면 알람 시간이 지난 후 {{durationString}} 으로 알림이 전송됩니다."
  },
  selectRecipient: '수신자 선택',
  acknowledgedExpiredRecipientAlarm:
    '이 알람은 {{durationString}} 전에 만료되었습니다. 이 알람을 "완료"로 표시했습니다.',
  skippedExpiredRecipientAlarm:
    '이 알람은 {{durationString}} 전에 만료되었습니다. 이 알람을 건너뛰었습니다.',
  expiredRecipientAlarm:
    '이 알람은 {{durationString}} 전에 만료되었습니다. "완료"를 탭하여 알람을 완료하고 {{creatorName}} 으로 알립니다.',
  acknowledgedRecipientAlarm:
    '{{durationString}} 전에 이 알람을 "완료"로 표시했습니다.',
  skippedRecipientAlarm: '{{durationString}} 전에 이 알람을 건너뛰셨습니다.',
  acknowledgedExpiredRecipientAlarmForCreator:
    '이 알람은 {{durationString}} 전에 만료되었습니다. {{recipientName}} 알람이 "완료"로 표시되었습니다.',
  skippedExpiredRecipientAlarmForCreator:
    '이 알람은 {{durationString}} 전에 만료되었습니다. {{recipientName}} 알람을 건너뛰었습니다.',
  expiredRecipientAlarmForCreator:
    '이 알람은 {{durationString}} 전에 만료되었습니다. {{recipientName}} 으로 알려주세요.',
  acknowledgedRecipientAlarmForCreator:
    '{{recipientName}} {{durationString}} 전에 알람을 "완료"로 표시했습니다.',
  selectRecipientForRecipientAlarm: '알람 수신자 선택',
  selectAtLeastOneParticipantForGroupAlarm: '알람에 참가자 1명 이상 추가하기',
  addAtLeastOneMemberToGroup: '그룹에 구성원 1명 이상 추가',
  atTheSameTime: '동시에',
  myself: '내 자신',
  group: '그룹',
  someoneElse: '다른 사람',
  participantsAreNotified: '참가자에게 알림이 전송됩니다.',
  creatorIsNotified: '{{creatorName}} 알림을 받습니다.',
  membersAddedToGroup: {
    one: '{{memberNames}} 그룹에 추가됩니다 {{groupName}}.',
    other: '{{memberNames}} 그룹에 추가됩니다 {{groupName}}.'
  },
  memberRemovedFromGroup:
    '{{memberName}} 그룹( {{groupName}} )에서 제거되어 일부 알람에 영향을 미쳤을 수 있습니다.',
  youRemovedFromGroup:
    '일부 알람에 영향을 줄 수 있는 {{groupName}} 그룹에서 삭제되었습니다.',
  memberLeftGroup:
    '{{memberName}} 왼쪽 그룹 {{groupName}} 일부 알람에 영향을 미쳤을 수 있습니다.',
  youLeftGroup:
    '{{groupName}} 그룹을 탈퇴하여 일부 알람에 영향을 미쳤을 수 있습니다.',
  groupLeft: '그룹을 떠났습니다 {{groupName}}.',
  memberMovedToADifferentTimezone:
    '{{memberName}} 다른 시간대로 이동하여 일부 알람에 영향을 미쳤을 수 있습니다.',
  memberDstChanged: {
    zero: '{{memberName}} 서머타임 준수를 중단했습니다. 관련 알람이 업데이트되었습니다.',
    one: '{{memberName}} 서머타임을 준수하기 시작했습니다. 관련 알람이 업데이트되었습니다.'
  },
  impactedAlarms: '영향을 받은 알람:',
  alwaysRingParticipantAlarmsOn: '다른 사람의 알람은 수락하지 않으면 울립니다.',
  alwaysRingParticipantAlarmsOff:
    '다른 사람이 보낸 알람은 사용자가 수락하지 않으면 울리지 않습니다.',
  agreeToTermsOfServiceAndPrivacyPolicy:
    '계속 진행하면 Galarm의 서비스 약관 및 개인정보 취급방침에 동의하는 것으로 간주됩니다.',
  deleteAccountWarningHeader:
    '경고: 이 작업은 되돌릴 수 없습니다. 계정을 삭제하면 삭제됩니다:',
  deleteAccountDeleteAlarms: '- 생성한 모든 알람을 삭제합니다.',
  deleteAccountDeleteRecipientAlarms:
    '- 내가 수신자인 모든 친구 알람을 삭제합니다.',
  deleteAccountRemoveFromGroups: '- 모든 그룹에서 사용자 삭제',
  deleteAccountWarningFooter: {
    zero: '휴대폰을 변경하는 경우 새 휴대폰에 앱을 설치하고 동일한 번호로 등록하면 됩니다. 이 휴대폰에서 앱을 삭제할 수 있습니다.\n\n계정을 삭제하면 계정을 복원할 수 없습니다.',
    one: '휴대폰을 변경하는 경우 새 휴대폰에 앱을 설치하고 동일한 번호로 등록하면 됩니다. 이 휴대폰에서 앱을 삭제할 수 있습니다.\n\n계정을 삭제하면 복원할 수 없습니다. Play 스토어에서 활성화된 Galarm 구독을 관리할 수 있습니다.',
    other:
      '휴대폰을 변경하는 경우 새 휴대폰에 앱을 설치하고 이 휴대폰에서 앱을 삭제하면 됩니다.\n\n계정을 삭제하면 계정을 복원할 수 없습니다. App Store에서 활성 Galarm 구독을 관리할 수 있습니다.'
  },
  typeConfirmationStringToDeleteAccount:
    '{{confirmationString}} 입력하여 계정을 삭제합니다.',
  unableToDeleteAccount: '계정을 삭제할 수 없습니다. {{error}}',
  impactedGroups: '영향을 받는 그룹:',
  memberDeletedAccount:
    '{{memberName}} 계정을 삭제하여 일부 알람 및 그룹에 영향을 미쳤을 수 있습니다.',
  confirmCountryCodeAndEnterNumber:
    '국가 코드를 확인하고 휴대폰 번호를 입력하세요.',
  confirmCountryCodeAndEnterNumberForExistingUser:
    '국가 코드를 확인하고 이전에 등록할 때 사용한 전화번호를 입력하세요.',
  requiredPermissionsDisabledWarningIos:
    '알림이 비활성화됩니다. 알람이 울리지 않습니다. 탭하여 수정합니다.',
  requiredPermissionsDisabledWarningAndroid:
    'Galarm에 알람을 울리는 데 필요한 권한이 없습니다. 탭하여 수정하세요.',
  notificationChannelPopupDisabledWarningAndroid:
    '알람 알림이 화면에 팝업되지 않습니다. 탭하여 수정합니다.',
  alarmLockScreenNotificationDisabledWarningAndroid:
    '잠금 화면에 알람 알림이 표시되지 않습니다. 탭하여 수정합니다.',
  why: '왜',
  how: '방법',
  registrationRequired: '등록 필요',
  phoneNumberRequired: '전화번호 필수',
  fewSeconds: '몇 초',
  lessThanMinute: '1분 미만',
  updateUnsupportedVersion:
    'Galarm을 계속 사용하려면 업데이트된 버전의 앱이 필요합니다.',
  update: '업데이트',
  back: '뒤로',
  unableToMarkAlarmDone:
    '지금은 알람을 완료된 것으로 표시할 수 없습니다. 잠시 후 다시 시도해 주세요.',
  unableToMarkAlarmUndone:
    '지금은 알람을 취소된 것으로 표시할 수 없습니다. 잠시 후 다시 시도해 주세요.',
  unableToMarkAlarmUnskipped:
    '지금은 알람을 건너뛰지 않은 것으로 표시할 수 없습니다. 잠시 후 다시 시도해 주세요.',
  unableToSkipAlarm:
    '지금은 알람을 건너뛸 수 없습니다. 잠시 후 다시 시도해 주세요.',
  unableToSetGroupAlarmResponse:
    '지금은 그룹 알람을 {{response}} 할 수 없습니다. 잠시 후 다시 시도해 주세요.',
  unableToDeleteAlarm:
    '지금은 알람을 삭제할 수 없습니다. 잠시 후 다시 시도해 주세요.',
  unableToSetAlarmResponse:
    '지금은 응답을 설정할 수 없습니다. 잠시 후 다시 시도해 주세요.',
  unableToSnoozeAlarm: '알람을 일시 중지할 수 없습니다.',
  unableToStopSnoozeForAlarm: '알람 스누즈를 중지할 수 없습니다.',
  unableToUpdateParticipantAlarmRingerSettings:
    '지금은 벨소리 설정을 업데이트할 수 없습니다. 잠시 후 다시 시도하세요.',
  updateParticipantPreReminderDuration:
    '지금은 사전 알림 기간을 업데이트할 수 없습니다. 잠시 후 다시 시도해 주세요.',
  unableToUpdateAlarmRingerSettings:
    '지금은 벨소리 설정을 업데이트할 수 없습니다. 잠시 후 다시 시도하세요.',
  unableToUpdateAlarmPreReminderDuration:
    '지금은 사전 알림 기간을 업데이트할 수 없습니다. 잠시 후 다시 시도해 주세요.',
  retrievingAlarmsFromServer: '서버에서 알람 검색하기...',
  startApp: '시작 >',
  phoneNumberRequiredGroupListHeaderText:
    '그룹은 Galarm 연락처의 모음으로, 이 연락처로 알람을 빠르게 만들 수 있습니다.\n\n휴대폰 번호로 등록하여 Galarm을 사용 중인 연락처를 찾아보세요. 그룹에 추가할 수 있습니다.',
  phoneNumberRequiredBuddyAlarmHeaderText:
    '버디 알람을 사용하여 친구와 가족에게 알람을 설정하여 해야 할 일을 상기시킬 수 있습니다.\n\n이 기능을 비롯한 여러 가지 멋진 앱 기능을 사용하려면 휴대폰 번호로 등록하세요.',
  phoneNumberRequiredContactListHeaderText:
    '연락처는 알람의 참여자로 추가할 수 있는 사람들을 말합니다.\n\n휴대폰 번호로 등록하면 Galarm을 사용하는 연락처를 확인할 수 있습니다. 이들을 알람의 참가자로 추가할 수 있습니다.',
  phoneNumberRequiredGroupAlarmHeaderText:
    '그룹 알람은 여러 사람에게 미리 알림을 설정하는 데 사용할 수 있습니다.\n\n이 기능을 비롯한 여러 가지 멋진 앱 기능을 사용하려면 휴대폰 번호로 등록하세요.',
  phoneNumberRequiredParticipantsHeaderText:
    '참가자는 이 알람의 백업 역할을 합니다. 참가자는 알람을 놓친 경우 알림을 받습니다.\n\n이 기능 및 기타 여러 가지 멋진 앱 기능을 사용하려면 휴대폰 번호로 등록하세요.',
  youHaveNotRegistered:
    '현재 알람이 백업되지 않았습니다.\n\n휴대폰 번호를 사용하여 등록하세요. 등록하면 알람이 클라우드에 백업됩니다. 이렇게 하면 휴대폰을 변경할 때 알람 및 기타 설정을 복원할 수 있습니다.\n',
  registerNow: '지금 등록하기',
  hoursAndMinutes: '시간 및 분',
  days: '일수',
  weeks: '주',
  months: '개월',
  years: '년',
  daysOfWeek: '주간(선택 요일)',
  monthly: '월간',
  yearly: '연간',
  everyDay: '매일',
  selectDaysOfWeek: '요일 선택',
  alarmRingMessageForSpecificDates:
    '이 알람은 최소 {{dayOfMonth}} 일수가 있는 달에만 울립니다. 계속하시겠습니까?',
  alarmRingMessageForSpecificYear:
    '이 알람은 윤년에만 울립니다. 계속하시겠습니까?',
  alarmRingMessageForSpecificDayOfWeekInMonth:
    '이 알람은 {{weekNumberOfDayInMonth}} {{dayName}} 가 있는 달에만 울립니다. 계속하시겠습니까? ',
  alarmRepetitionAdjustedBasedOnNewDate:
    '알람 반복이 새 날짜를 기준으로 업데이트되었습니다.',
  inviteFriendsToInstallGalarm:
    '연락처는 내 알람에 참여자로 추가할 수 있는 사람들을 말합니다. 연락처를 초대할 수 있습니다:\n\n1. 주소록에서\n\n2. 이름과 번호를 직접 입력합니다.\n\n왼쪽 상단의 새로 고침을 탭하여 Galarm 연락처를 등록한 후 다시 로드합니다.',
  whyCreateNewGroup:
    '그룹은 알람에 참여자로 추가할 수 있는 연락처 모음입니다.\n  \n그룹을 만들지 않았습니다.',
  noNotifications: '새 알림이 없습니다.',
  createGroup: '그룹 만들기',
  galarmRequiresAccessToContactsPermission:
    'Galarm은 연락처에 액세스할 수 있는 권한을 요청합니다. 이 정보는 Galarm을 사용하는 연락처를 식별하기 위해 서버로 전송됩니다. 이러한 사용자를 알람의 참가자로 추가할 수 있습니다.',
  galarmRequiresAccessToAudioFilesPermission:
    '일부 알람은 휴대폰 음악을 벨소리로 사용하고 있습니다. 선택한 벨소리로 알람을 울리려면 장치의 오디오 파일에 액세스할 수 있는 권한을 제공하세요.',
  galarmRequiresAccessToContactsSelectScreen:
    'Galarm에는 연락처에 액세스할 수 있는 권한이 없습니다. 이 기능을 사용하려면 연락처에 대한 액세스 권한을 허용하세요.',
  allow: '허용',
  deny: '거부',
  galarmRequiresAccessToContacts:
    '연락처는 내 알람에 참여자로 추가할 수 있는 사람들을 말합니다. 연락처를 추가할 수 있는 기준은 다음과 같습니다:\n\n1. 휴대폰 연락처에서 자동으로 가져오기. "액세스 허용"을 탭하고 "연락처"를 활성화합니다.\n\n2. 2. 이름과 번호를 수동으로 추가합니다. "연락처 추가"를 탭하여 시작합니다.',
  galarmRequiresAccessToAlarmAndRemindersPermission:
    'Galarm이 지정된 시간에 정확히 알람을 울리려면 "알람 및 알림" 권한이 필요합니다. 다음 화면에서 활성화하세요.',
  accessToContactsPermissionRequiredAndroid:
    'Galarm은 휴대폰 연락처에 액세스할 수 있는 권한이 없습니다.\n\n액세스를 허용하면 휴대폰 연락처에서 Galarm을 사용 중인 사람들이 Galarm 연락처로 표시됩니다. 이러한 사람들을 알람 참여자로 추가할 수 있습니다. "액세스 허용"을 탭하고 "권한"을 선택한 다음 "연락처"를 활성화합니다.\n\nGalarm에 연락처를 수동으로 추가할 수도 있습니다.',
  accessToContactsPermissionRequiredIos:
    'Galarm은 휴대폰 연락처에 액세스할 수 있는 권한이 없습니다.\n\n액세스를 허용하면 휴대폰 연락처에서 Galarm을 사용 중인 사람들이 Galarm 연락처로 표시됩니다. 이러한 사람들을 알람 참여자로 추가할 수 있습니다. "액세스 허용"을 탭하고 "연락처"를 활성화합니다.\n\nGalarm에 연락처를 수동으로 추가할 수도 있습니다.',
  giveAccess: '액세스 허용',
  enterValidMobileNumber: '등록하려면 유효한 전화번호를 입력하세요.',
  proceedAnyway: '어쨌든 계속 진행',
  checkInvalidPhoneNumber:
    '{{mobileNumber}} 으로 잘못된 번호를 입력하신 것 같습니다.',
  checkInvalidCountryCode:
    '{{countryCode}} 으로 지정된 국가 코드가 잘못되었습니다. 국가 코드가 두 개 이상 있는 경우 해당 국가 코드를 유지하도록 필드를 편집합니다.',
  editNumber: '번호 수정',
  editCountryCode: '국가 코드 수정',
  sendingCodeOnCall: '코드를 가지고 전화합니다.',
  sendCodeOnCall: '코드와 함께 전화하기',
  registrationLandingScreenRegisterText:
    '계속을 탭하여 휴대폰 번호를 사용하여 등록합니다.\n\n등록하면\n1) 알람에 참가자를 추가하고 그들과 채팅할 수 있습니다.\n2) 알람이 클라우드에 백업되어 휴대폰을 변경하면 새 휴대폰에 알람이 표시됩니다.',
  continue: '계속하기',
  registerLater: '등록 건너뛰기',
  cancelRegistration: '등록 취소',
  welcome: '환영',
  haventReceivedCodeYet: {
    one: '아직 코드를 받지 못하셨나요? 1초 후에 코드를 다시 요청할 수 있습니다.',
    other:
      '아직 코드를 받지 못하셨나요? {{count}} 초 후에 코드를 다시 요청할 수 있습니다.'
  },
  storagePermissionDenied:
    '저장소 권한이 거부되어 음악 파일에 액세스할 수 없습니다.',
  havingTroubleRegisteringMessage:
    '여러 번 시도해도 Galarm에서 코드를 확인할 수 없습니다.\n\n등록을 건너뛰고 앱 사용을 시작하시겠습니까?',
  autoDetectVerificationCode:
    '등록을 간소화하기 위해 Galarm이 SMS 메시지를 볼 수 있도록 허용하면 인증 코드를 자동으로 감지할 수 있습니다.',
  notNow: '지금은 아닙니다',
  verifyNumberText:
    '다음 주소로 인증 코드가 포함된 SMS를 보내드립니다:\n\n{{formattedMobileNumber}}',
  addToContacts: '연락처에 추가',
  todayString: '오늘',
  tomorrowString: '내일',
  backups: '백업',
  acintyoTrademark:
    '© 2024 Acintyo, Inc. 모든 권리 보유.\nGalarm은 Acintyo, Inc의 등록 상표입니다.\n미국 특허에 의해 보호됨 10382613, 10867286.\n기타 특허 출원 중.',
  acintyoTrademarkWeb:
    '© 2024 Acintyo, Inc. 모든 권리 보유. Galarm은 Acintyo, Inc의 등록 상표입니다.\n미국 특허에 의해 보호됨 10382613, 10867286. 기타 특허 출원 중.',
  acintyoTrademarkEnterprise: '© 2024 Acintyo, Inc.\n모든 권리 보유.',
  markAlarmDone: '알람을 완료로 표시',
  skipAlarm: '알람 건너뛰기',
  confirmGroupAlarm: '참여 확인',
  declineGroupAlarm: '참여 거부',
  snoozeAlarm: '스누즈 알람',
  deleteAlarmQuickAction: '알람 삭제',
  disableAlarm: '알람 비활성화',
  confirmDisableAlarm: '알람을 비활성화하시겠습니까?',
  saveToCalendar: '캘린더로 내보내기',
  stillRingGroupAlarmAfterConfirmingInAdvance:
    '그룹 알람을 미리 확인하고 있습니다.  알람 시간에 알림을 계속 받으시겠습니까?',
  updateNow: '지금 업데이트',
  nParticipants: { one: '다른 참가자 1명', other: '{{count}} 다른 참가자' },
  details: '세부 정보',
  changeParanthesis: '(변경)',
  loadMoreHistory: '자세히 보기',
  alarmSummaryForHistory:
    '에 대한 지난 응답 {{alarmName}}.\n반복 {{alarmRepetitionString}}\n{{previousOccurrencesString}}',
  alarmSummaryForHistoryWeb: '다음에 대한 과거 응답 {{alarmName}}',
  alarmSummaryForHistoryRepetitionWeb: '반복 {{alarmRepetitionString}}',
  dontSeeCompleteHistory: '전체 기록이 보이지 않나요?',
  dontSeeCompleteHistoryExplanation:
    '알람 기록은 알람이 생성된 시점부터 기록됩니다. 알람 반복에 따라 각 알람에 대해 제한된 기록만 유지됩니다. 예를 들어 알람이 매일 반복되는 경우 30일의 기록만 유지됩니다.\n\n알람 시간이나 반복 횟수를 변경하면 알람 이력이 초기화됩니다.',
  noActionAvailableForAlarmOccurrence:
    '이 발생에 대해 사용할 수 있는 조치가 없습니다.',
  enterEmailIdForFeedback: '응답 및 업데이트 공유에 사용',
  noPreviousOccurrences: '아직 알람 기록이 없습니다.',
  nMissedOccurrencesOfAlarmInLastNDays: {
    zero: '아직 알람 기록이 없습니다.',
    one: '{{missedAlarmOccurrences}} 마지막에 놓친 {{count}}',
    other: '{{missedAlarmOccurrences}} 마지막에 놓친 {{count}}'
  },
  soundAndVibrationSummaryString: {
    zero: '{{volume}}볼륨, {{ringtoneName}}',
    one: '{{volume}}% 볼륨, {{ringtoneName}}, 제목 발표'
  },
  soundAndVibrationSummaryStringIos: {
    zero: '{{ringtoneName}}',
    one: '중요, {{volume}}% 볼륨, {{ringtoneName}}'
  },
  previousOccurrences: '알람 기록',
  retrieving: '검색 중...',
  startTimeOfDay: '다음에서 시작',
  endTimeOfDay: '다음에서 종료',
  ringEveryHours: '모든 링',
  everyNHours: '시간별',
  everyMHoursNMinutes: '시간 및 분',
  selectHourlyRepetition: '시간별 반복 선택',
  everyNHoursRepetitionString: {
    one: '매시간 {{startTimeString}} 에서 {{endTimeString}} 으로 이동 {{daysString}}',
    other:
      '{{startTimeString}} ~ {{endTimeString}} 매시간 {{count}} {{daysString}}'
  },
  everyNHoursAndMinutesRepetitionString:
    '모든 {{hoursAndMinutes}} {{startTimeString}} 에서 {{endTimeString}} 까지 {{daysString}}',
  everyMHoursAndNMinutesRepetitionString: '모든 {{repeatString}}',
  everyNHoursRepetitionStringForCalendar: {
    one: '매시간 {{startTimeString}} 에서 {{endTimeString}}',
    other: '{{startTimeString}} 에서 {{count}} 시간마다 {{endTimeString}}'
  },
  everyNHoursAndMinutesRepetitionStringForCalendar:
    '모든 {{hoursAndMinutes}} {{startTimeString}} 에서 {{endTimeString}}',
  everyNHoursAndMinutesRepetitionStringForAlarmSummary:
    '모든 {{hoursAndMinutes}} 까지 {{endTimeString}}',
  everyNHoursRepetitionStringForAlarmSummary: {
    one: '매시간 {{endTimeString}}',
    other: '매 {{count}} 시간까지 {{endTimeString}}'
  },
  daysRepetitionOddString: {
    other: '모든 홀수 날짜',
    one: '홀수 날짜 ({{daysString}})'
  },
  daysRepetitionEvenString: {
    other: '모든 짝수 날짜',
    one: '짝수 날짜 ({{daysString}})'
  },
  oddNumberedRepetitionPreviewString: {
    one: '지정된 시간에 {{daysString}} 에 해당하는 홀수 날짜에 알람이 울립니다.',
    other: '지정된 시간에 모든 홀수 날짜에 알람이 울립니다.'
  },
  evenNumberedRepetitionPreviewString: {
    one: '지정된 시간에 {{daysString}} 에 해당하는 짝수 날짜에 알람이 울립니다.',
    other: '지정된 시간에 모든 짝수 날짜에 알람이 울립니다.'
  },
  hourlyRepetitionPreviewString: {
    one: '이 알람은 {{daysString}} 에 한 번씩 울립니다:',
    other: '이 알람은 {{daysString}} 에서 {{count}} 번 울립니다:'
  },
  daysRepetitionPreviewString: {
    one: '이 알람은 매일 울립니다.',
    other: '이 알람은 {{count}} 일마다 울립니다.'
  },
  weeksRepetitionPreviewString: {
    one: '이 알람은 매주 울립니다.',
    other: '이 알람은 {{count}} 주마다 울립니다.'
  },
  everyNDays: { one: '매일', other: '매일 {{count}} 일' },
  everyNWeeks: { one: '매주', other: '매주 {{count}} ' },
  everyNMonths: { one: '매월', other: '매 {{count}} 개월마다' },
  everyNYears: { one: '매년', other: '매년 {{count}} ' },
  cantSetEndTime: '종료 시간을 설정할 수 없습니다.',
  endTimeShouldBeGreater: '종료 시간은 시작 시간보다 커야 합니다.',
  cantSetStartTime: '시작 시간을 설정할 수 없습니다.',
  startTimeShouldBeLess: '시작 시간은 종료 시간보다 짧아야 합니다.',
  everyDaySmall: '일',
  everyGivenDay: '{{day}}',
  everyGivenDays: '{{days}}',
  adjustingAlarmsAfterTimezoneChange:
    '다른 시간대로 이동한 것을 감지했습니다. 알람을 조정하고 있습니다.',
  ringOnDays: '링 켜짐',
  yourAlarmsMayNotRing: '알람이 울리지 않을 수 있습니다.',
  yourAlarmsMayNotRingTapToFix:
    '알람이 울리지 않을 수 있습니다. 탭하여 수정하세요.',
  xiaomiSetupInstructions:
    "다음 지침에 따라 항상 알람이 울리도록 설정하세요:\n\n - '자동 시작 사용'을 탭하고 '알람'을 허용하여 알람이 항상 울리도록 설정합니다. \n\n자세한 내용은 www.galarmapp.com/setup-mi 참조하세요.",
  oppoSetupInstructions:
    '다음 지침에 따라 항상 알람이 울리도록 설정하세요:\n\n - \'자동 시작 사용\'을 탭하고 \'자동 시작 허용\'을 활성화합니다.\n\n - 휴대폰에서 "최근 앱" 버튼을 탭하고 Galarm 앱 아이콘을 아래로 스와이프한 다음 "잠금"을 탭합니다.  \n\n자세한 내용은 www.galarmapp.com/setup-oppo 참조.',
  oppo8AndLowerSetupInstructions:
    '다음 지침에 따라 항상 알람이 울리도록 설정하세요:\n\n - \'자동 시작 사용\'을 탭하고 \'자동 시작 허용\'을 활성화합니다.\n\n - 휴대폰에서 "최근 앱" 버튼을 탭하고 Galarm 앱 아이콘을 아래로 스와이프한 다음 "잠금"을 탭합니다.\n\n자세한 내용은 www.galarmapp.com/setup-oppo-8-and-lower 참조.',
  vivo9And10SetupInstructions:
    '다음 지침에 따라 항상 알람이 울리도록 설정하세요:\n\n - "자동 시작 사용"을 탭하고 "알람"을 허용합니다.\n\n - "배터리 사용량"을 탭하고 "높은 백그라운드 전력 소비"를 선택한 다음 "알람"을 허용합니다.\n\n자세히 알아보려면 www.galarmapp.com/setup-vivo',
  vivo11SetupInstructions:
    '다음 지침에 따라 항상 알람이 울리도록 설정하세요:\n\n- "자동 시작 사용"을 탭하고 "알람"을 허용합니다.\n\n- "배터리 사용량"을 탭하고 "높은 백그라운드 전력 소비"를 선택한 다음 "알람"을 허용합니다.\n\n자세히 알아보려면 www.galarmapp.com/setup-vivo-11',
  vivoSetupInstructions:
    '다음 지침에 따라 항상 알람이 울리도록 설정하세요:\n\n- "휴대폰 설정 > 앱 > 특수 앱 액세스 > 자동 시작"을 열고 Galarm에 대해 자동 시작을 활성화합니다.\n\n- "휴대폰 설정 > 배터리 > 백그라운드 전력 소비 관리 > Galarm"을 열고 "백그라운드 전력 사용 제한 안 함"을 선택합니다.\n\n자세히 알아보려면 www.galarmapp.com/setup-vivo-12-and-above',
  vivo8AndLowerSetupInstructions:
    '다음 지침에 따라 항상 알람이 울리도록 설정하세요:\n\n - "자동 시작 사용"을 탭하고 "알람"을 허용합니다.\n\n - "배터리 사용량"을 탭하고 "높은 백그라운드 전력 소비"를 선택한 다음 "알람"을 허용합니다.\n\n자세히 알아보려면 www.galarmapp.com/setup-vivo-8-and-lower',
  huawei7AndLowerAdditionalSetupInstructions:
    "다음 지침에 따라 항상 알람이 울리도록 설정하세요:\n\n - '보호된 앱'을 탭하고 'Galarm'을 허용합니다.\n\n자세한 내용은 www.galarmapp.com/setup-huawei-7-and-lower 참조하세요.",
  huaweiAdditionalSetupInstructions:
    '다음 지침에 따라 항상 알람이 울리도록 설정하세요:\n\n - "앱 실행 관리"를 탭하고 "알람"을 끄고 "수동으로 관리"로 전환합니다. 이어지는 프롬프트에서 "자동 실행" 및 "백그라운드에서 실행"이 활성화되어 있는지 확인합니다.\n\n자세히 알아보려면 www.galarmapp.com/setup-huawei',
  meizuSetupInstructions:
    '아래 링크의 지침에 따라 항상 알람이 울리도록 설정하세요: https://dontkillmyapp.com/meizu',
  asusSetupInstructions:
    '다음 지침에 따라 항상 알람이 울리도록 설정하세요:\n\n - "자동 시작 사용"을 탭하고 "자동 시작 관리자"를 클릭합니다. "자동 시작 관리자"에서 "알람"을 활성화합니다.\n\n자세히 알아보려면 www.galarmapp.com/setup-asus',
  nokia8AndLowerAdditionalSetupInstructions:
    '다음 지침에 따라 항상 알람이 울리도록 설정하세요:\n\n - "자동 시작 사용"을 탭하고 "알람"을 허용합니다.\n\n자세한 내용은 www.galarmapp.com/setup-nokia-8-and-lower 참조하세요.',
  nokiaAdditionalSetupInstructions:
    '다음 지침에 따라 항상 알람이 울리도록 설정하세요:\n\n - "제한된 앱"을 탭하고 "Galarm"을 비활성화합니다.\n\n자세한 내용은 www.galarmapp.com/setup-nokia 참조하세요.',
  lenovoSetupInstructions:
    "다음 지침에 따라 항상 알람이 울리도록 설정하세요:\n\n - 휴대폰에서 '최근 앱' 버튼을 탭하고 Galarm 앱을 찾은 다음 오른쪽 상단의 '잠금' 아이콘을 탭합니다.\n  \n자세한 내용은 https://dontkillmyapp.com/lenovo 참조",
  samsungAdditionalSetupInstructions:
    '다음 지침에 따라 항상 알람이 울리도록 설정하세요:\n\n - "모니터링하지 않는 앱"을 탭하고 "Galarm"을 허용합니다.\n\n자세한 내용은 www.galarmapp.com/setup-samsung 참조하세요.',
  tecnoSetupInstructions:
    '아래 링크의 지침에 따라 항상 알람이 울리도록 설정하세요: https://www.galarmapp.com/setup-tecno',
  infinixSetupInstructions:
    '아래 링크의 지침에 따라 항상 알람이 울리도록 설정하세요: https://www.galarmapp.com/setup-infinix',
  samsungAdditionalSetupInstructionsAndroid11AndAbove:
    '기본적으로 삼성은 빠르게 사라지는 "간략" 알림을 표시합니다. "상세" 알람 알림을 보려면 다음 지침을 따르세요.\n\n - 휴대폰 설정을 열고 "알림"을 선택합니다.\n - "상세" 알림 스타일을 선택합니다.\n  \n자세한 내용은 www.galarmapp.com/setup-samsung-android11-and-above 참조하세요.',
  iosSetupInstructions:
    '기본적으로 iOS의 알림 배너 스타일은 알림이 빠르게 사라지는 "임시"로 설정되어 있습니다. 배너 스타일을 "영구"로 설정하려면 다음 지침을 따르세요.\n\n- 휴대폰 설정을 열고 Galarm으로 스크롤합니다.\n- 알림을 선택하고 배너 스타일을 영구로 설정합니다.\n\n자세히 알아보려면 www.galarmapp.com/setup-ios',
  iosSetupInstructions15AndBelow:
    '기본적으로 iOS의 알림 배너 스타일은 알림이 빠르게 사라지는 "임시"로 설정되어 있습니다. 배너 스타일을 "영구"로 설정하려면 다음 지침을 따르세요.\n\n- 휴대폰 설정을 열고 Galarm으로 스크롤합니다.\n- 알림을 선택하고 배너 스타일을 영구로 설정합니다.\n\n자세히 알아보려면 www.galarmapp.com/setup-ios-15-below',
  setNotificationsStyle: '알림 스타일 설정',
  setNotificationsStyleToDetailed: '알림 스타일을 "상세"로 설정',
  setBannerStyle: '배너 스타일 설정',
  setBannerStyleToPersistent: '배너 스타일을 "영구"로 설정',
  selectAtleastOneDay: '이 알람이 울릴 요일을 선택합니다.',
  selectAtLeastOneHourOrMinute: '시간 또는 분 단위는 0이 아닌 값이어야 합니다.',
  hourlyAlarmDefaultStartTimeSetAlert:
    '알람 시작 시간이 오전 8시로 설정되었습니다.',
  batteryUsage: '배터리 사용량',
  dontShowAgain: '다시 표시하지 않기',
  alarmWillRingEveryHourTillSeen:
    '알람은 사용자가 조치를 취할 때까지 1시간마다 일시 중지됩니다.',
  unknown: '알 수 없음',
  alarmMarkedDone: '{{name}} 알람을 "완료"로 표시했습니다.',
  alarmMarkedSkipped: '{{name}} 알람을 건너뛰었습니다.',
  alarmIsMarkedDone: '{{alarmName}} 완료로 표시됩니다.',
  alarmIsSkipped: '{{alarmName}} 는 건너뜁니다.',
  alarmIsConfirmed: '{{alarmName}} 가 확인되었습니다.',
  alarmIsDeclined: '{{alarmName}} 가 거부됩니다.',
  alarmIsSnoozed: '{{alarmName}} 가 일시 중지되었습니다.',
  alarmIsAccepted: '{{alarmName}} 가 허용됩니다.',
  alarmIsRejected: '{{alarmName}} 가 거부됩니다.',
  newContactInfo:
    "연락처의 이름과 전화번호를 입력하세요.\n\n등록된 사용자만 연락처로 추가할 수 있습니다. 사용자가 등록된 Galarm 사용자가 아닌 경우 '설정 > 이 앱 공유'를 사용하여 초대할 수 있습니다. 해당 사용자가 전화번호를 사용하여 등록한 후에 연락처로 추가할 수 있습니다. ",
  cantEditContactMobileNumber:
    '전화번호는 수정할 수 없습니다. 전화번호를 변경하려면 연락처를 삭제하고 다시 추가하세요.',
  phoneContactsNotSyncedPermissionDenied:
    'Galarm에 전화 연락처에 액세스할 수 있는 권한이 없습니다. 전화 연락처가 동기화되지 않습니다.',
  inviteContactMessage:
    '안녕하세요 {{contactName}}, Galarm을 사용하여 내 알람 및 미리 알림에 사용자를 포함시키고 싶습니다. {{downloadLink}} 에서 앱을 다운로드하세요. 이 번호를 사용하여 등록하고 알람 공유를 시작하세요.',
  noRepeat: '반복하지 않음',
  deepLinkNoLongerValidTitle: '웁스!',
  deepLinkNoLongerValidMessage:
    '알람이 생성되지 않았습니다. 오래된 링크이거나 잘못된 링크일 수 있습니다.',
  inviteContact: '연락처 초대',
  fromPhoneBook: '주소록에서',
  addFromPhoneBook: '주소록에서 초대',
  manually: '수동으로',
  addManualContact: '수동으로 초대',
  noOtherContactsFound: '주소록의 모든 연락처가 Galarm을 사용하고 있습니다.',
  cantAddPhoneContactPermissionDenied:
    'Galarm에 전화 연락처에 액세스할 수 있는 권한이 없습니다. 연락처에 액세스할 수 있는 권한을 허용하세요.',
  alarmWillRingWithRandomRingtone: '알람 벨소리는 무작위 벨소리로 선택됩니다.',
  alarmWillRingWithRandomRingtoneGlobal:
    '알람 벨소리는 무작위 벨소리가 선택됩니다.',
  createdAlarmFromDeepLinkTitle: '모든 준비가 완료되었습니다!',
  createdAlarmFromDeepLinkMessage:
    '{{alarmTime}} 에서 "{{alarmName}}"로 이동합니다. {{alarmDate}}',
  createdRepeatingAlarmFromDeepLinkMessage: {
    zero: '"{{alarmName}}" {{repetitionString}}',
    one: '"{{alarmName}}" {{repetitionString}} {{alarmTime}} 에서 시작됩니다. {{alarmDate}}'
  },
  deleteCategoryAlertTitle: '카테고리 삭제?',
  deleteCategoryAlertMessage: {
    zero: '',
    one: '알람 1개가 이 카테고리에 속합니다. 이 카테고리를 삭제하면 해당 알람의 카테고리가 초기화됩니다.',
    other:
      '{{count}} 알람이 이 카테고리에 속합니다. 이 카테고리를 삭제하면 해당 알람의 카테고리가 초기화됩니다.'
  },
  updateReadyToInstallAlertTitle: '업데이트를 설치하시겠습니까?',
  updateReadyToInstallAlertMessage:
    '업데이트를 설치할 준비가 되었습니다. 지금 업데이트를 설치하시겠습니까? ',
  hideCategoryAlertTitle: '카테고리 숨기기?',
  hideCategoryAlertMessage: {
    zero: '앞으로는 이 카테고리를 사용할 수 없습니다.',
    one: '1개의 알람이 이 카테고리에 속합니다. 이 카테고리를 숨기면 해당 알람의 카테고리가 초기화됩니다.\n  \n앞으로는 이 카테고리를 사용할 수 없습니다.',
    other:
      '{{count}} 알람이 이 카테고리에 속합니다. 이 카테고리를 숨기면 해당 알람의 카테고리가 초기화됩니다.\n  \n앞으로는 이 카테고리를 사용할 수 없습니다.'
  },
  alarmCategoryDeleteAllAlarmsPrompt: {
    one: '알람 1개가 이 카테고리에 속합니다. 삭제하시겠습니까?\n\n참가자 알람은 삭제되지 않는다는 점에 유의하세요.',
    other:
      '{{count}} 알람이 이 카테고리에 속합니다. 삭제하시겠습니까?\n\n참가자 알람은 삭제되지 않는다는 점에 유의하세요.'
  },
  enterNameForGroup: '그룹 이름을 입력합니다.',
  enterMobileNumber: '휴대폰 번호 입력',
  enterNotesForAlarm: '알람을 위한 메모 입력',
  enterUserName: '이름 입력',
  enterEmailId: '선택 사항',
  enterFeedback: '어떻게 개선할 수 있을까요?',
  describeProblem: '문제 설명하기',
  invited: '초대됨',
  resend: '다시 보내기',
  addContact: '연락처 추가',
  inviteAgain: '다시 초대',
  contactNotRegistered:
    '{{contactName}} 아직 Galarm을 설치하지 않았습니다. 전화번호를 사용하여 설치하고 등록하면 알람에 추가할 수 있습니다.',
  registration: '등록',
  verify: '인증',
  profile: '프로필',
  profileImage: '프로필 이미지',
  addProfileImage: '프로필 이미지 추가',
  groupDetails: '그룹 세부 정보',
  newGroup: '새 그룹',
  uploadImage: '이미지 변경',
  removeImage: '이미지 제거',
  selectParticipants: '참가자 선택',
  selectMembers: '구성원 선택',
  selectTimezone: '시간대 선택',
  searchForATimezone: '시간대 검색',
  timezone: '시간대',
  deviceTimezone: '장치 시간대',
  specifyTimezoneForAlarm: '알람 시간대 지정',
  editName: '이름 수정',
  groupImage: '그룹 이미지',
  addGroupImage: '그룹 이미지 추가',
  selectAlarmType: '다음에 대한 알람을 만듭니다.',
  contactDetails: '연락처 세부 정보',
  commonAlarms: '알람',
  commonGroups: '그룹',
  groupAlarmsTitle: '알람',
  selectRingtone: '벨소리 선택',
  selectMusic: '음악 선택',
  select: '선택',
  personalAlarm: '개인 알람',
  groupAlarm: '그룹 알람',
  newPersonalAlarm: '새로운 개인 알람',
  newGroupAlarm: '새로운 그룹 알람',
  editPersonalAlarm: '개인 알람 편집',
  editGroupAlarm: '그룹 알람 편집',
  rearrangeParticipants: '참가자 재정렬',
  alarmConversation: '알람 채팅',
  timezoneMismatchDetected:
    '마지막으로 Galarm을 사용했을 때와 다른 시간대에 있는 것으로 보입니다.\n\n새 시간대에 적응할 수 있도록 Galarm을 다시 시작해 주세요.',
  deviceRestartRequired: '앱 재시작 필요',
  disabledAlarm: '비활성화 알람 {{name}}',
  newContact: '새 연락처',
  contactName: '연락처 이름',
  contactMobileNumber: '연락처 전화번호',
  enterNameForContact: '연락처 이름 입력',
  pickCountryForContact: '연락처 국가',
  enterMobileNumberForContact: '연락처의 전화번호 입력',
  specifyNameForContact: '연락처 이름은 필수 입력 사항입니다.',
  specifyValidPhoneNumberForContact: '유효한 전화번호가 필요합니다.',
  addingContact: '연락처 추가하기',
  deletingContact: '연락처 제거하기',
  editingContact: '연락처 수정하기',
  pending: '보류 중',
  deleteContact: '연락처 제거',
  galarmContact: '수동으로 추가',
  phoneContact: '전화 연락처에서 추가',
  confirmDeleteContactTitle: '{{name}} 제거 ?',
  confirmDeleteContact: '알람에 추가할 수 없습니다.',
  contactAdded: '잘됐네요! 이제 {{name}} 을 알람 참여자로 추가할 수 있습니다.',
  contactInvited:
    'Galarm을 사용하도록 초대하는 문자 메시지가 {{name}} 으로 전송되었습니다.',
  changeSnoozeDuration: '변경 기간',
  durationOfEvent: '이벤트 기간',
  upgradeToPremium: '프리미엄으로 업그레이드',
  premium: '프리미엄',
  alarmLimitReached: '알람 한도 도달',
  unableToConnectToGooglePlay:
    'Google Play에 연결할 수 없습니다. Google 계정에 로그인했는지 확인하세요.',
  cantLoadVideo: '비디오를 로드할 수 없습니다.',
  cantUpgradeToPremium: '프리미엄으로 업그레이드할 수 없음',
  unableToConnectToIapAndroid:
    'Play 스토어에 연결할 수 없습니다. Play 스토어 앱을 열고 로그인했는지 확인하세요.',
  unableToConnectToIapIos:
    'App Store에 연결할 수 없습니다. App Store 앱을 열고 로그인했는지 확인하세요.',
  premiumFeaturesIos:
    '프리미엄 사용자에게는 {{maxLimit}} 동시 알람 한도가 증가합니다.\n\n또한 구매를 통해 더 많은 기능을 개발할 수 있습니다.',
  premiumFeaturesAndroid:
    '프리미엄으로 업그레이드하여 무제한 알람을 생성하세요.\n\n프리미엄을 구매하면 더 많은 기능을 개발하는 데 도움이 됩니다.',
  upgrade: '업그레이드',
  rvInternalError:
    '내부 오류로 인해 동영상을 로드할 수 없습니다. 하지만 알람 한도를 1로 늘렸습니다.',
  rvNetworkError: '인터넷 연결 상태가 양호한지 확인한 후 다시 시도하세요.',
  rvNotFoundError:
    '현재 적합한 동영상을 찾을 수 없습니다. 하지만 알람 한도를 1만큼 늘렸습니다.',
  dontRemindMe: '상기시키지 마세요',
  sameTimeAsBuddy: '버디와 같은 시간',
  noAlertForBuddyAlarm: '이 알람에 대한 알림을 받지 않기로 선택했습니다.',
  noAlertRecipientIntervalDescription: '알림을 받지 않습니다.',
  chooseHours: '시간 선택',
  chooseMins: '분 선택',
  changeHours: '시간 변경',
  moreOptions: '더 많은 옵션...',
  set: '설정',
  chooseNumberOfDays: '일수 입력',
  chooseNumberOfWeeks: '주 수 입력',
  numberOfDaysShouldBeAtLeastOne: '일수는 최소 1일 이상이어야 합니다.',
  numberOfWeeksShouldBeAtLeastOne: '주 수는 최소 1주 이상이어야 합니다.',
  numberOfMonthsShouldBeAtLeastOne: '개월 수는 1개월 이상이어야 합니다.',
  numberOfYearsShouldBeAtLeastOne: '연수는 최소 1이어야 합니다.',
  frequency: '빈도',
  configuration: '구성',
  endDate: '알람 종료 날짜',
  noEndDate: '종료일 없음',
  alarmEndDateSetOnAlarm:
    '알람 종료 날짜가 {{endDateString}} 로 설정되어 있습니다.',
  noAlarmDateSetForAlarm: '이 알람에는 종료 날짜가 설정되어 있지 않습니다.',
  noOccurrenceForThisAlarmAsPerEndDate:
    '종료 날짜가 알람 발생을 허용하지 않습니다.',
  alarmDateShouldBeBiggerThanAlarmStartDate:
    '알람 종료일은 알람 시작일 이후여야 합니다.',
  aWeek: '일주일',
  aMonth: '한 달',
  allInactiveAlarms: '모든 비활성 알람',
  chooseAlarmEndDate: '알람 종료 날짜 선택',
  next: '다음',
  next7Days: '오늘부터 7일간',
  next30Days: '오늘부터 30일',
  next12Months: '오늘부터 12개월',
  next10Years: '오늘로부터 10년 후',
  chooseDate: '날짜 선택...',
  learnMore: '자세히 알아보기',
  repeatEvery: '매번 반복',
  repeatEveryDays: '일(일)',
  repeatEveryWeeks: '주(주)',
  repeatEveryMonths: '개월(들)',
  repeatEveryYears: '연도',
  autoSnooze: '알람 자동 스누즈',
  autoSnoozePerAlarm: '알람 자동 스누즈',
  unableToUploadGroupAvatar:
    '지금 그룹 이미지를 업로드할 수 없습니다. 나중에 다시 시도하세요.',
  addNewMembersToExistingAlarms:
    '이 그룹에는 다음과 같은 알람이 있습니다:\n\n{{alarmNames}}\n    \n이 알람에 새 멤버를 추가하시겠습니까?',
  removeGroupMemberAlert:
    '멤버를 제거하면 다음 알람에 영향을 미칩니다:\n\n{{alarmNames}}\n\n그래도 멤버를 제거하시겠습니까?',
  leaveGroupAlert:
    '그룹에서 탈퇴하면 다음 알람에 영향을 미칩니다:\n\n{{alarmNames}}\n\n그래도 그룹에서 탈퇴하시겠습니까?',
  updateAppAfterFeedback:
    '이전 버전의 앱을 실행 중인 것으로 확인되었습니다. 더 나은 환경을 위해 업데이트하세요.',
  changesAreLost:
    '저장되지 않은 변경 사항이 있습니다.\n\n떠나기 전에 저장하시겠습니까?',
  recipientAlarmIntervalGreaterThanRepetitionInterval:
    "'알림' 간격은 반복 간격보다 작아야 합니다.",
  cascadingAlarmIntervalGreaterThanRepetitionInterval:
    "'참가자 알림 사이의 시간' 간격은 반복 간격보다 작아야 합니다.",
  subscriptionCanceled:
    '프리미엄 구독이 해지되었습니다. 구독을 갱신할 때까지 프리미엄 기능을 사용할 수 없습니다.',
  unableToRenewSubscription:
    '프리미엄 구독을 갱신하는 동안 문제가 발생했습니다. 결제 정보가 최신 상태인지 확인하세요.',
  notice: '공지 사항',
  ringerSettingsWillApplyToNewAlarms:
    '소리 및 진동 설정에 대한 변경 사항은 새 알람에만 적용됩니다.\n\n기존 알람의 경우 알람을 편집하여 소리 및 진동 설정을 변경할 수 있습니다.',
  renew: '갱신',
  galarmPremium: '갈람 프리미엄',
  payMonthly: '매월 {{price}} 에서 월별 결제',
  payYearly: '매년 {{price}} 에서 연간 결제',
  free: '무료',
  unlimited: '무제한',
  personalAlarms: '개인 알람',
  groupAlarms: '그룹 알람',
  buddyAlarms: '버디 알람',
  alarmRepetitions: '반복',
  ringtones: '벨소리',
  alarmHistory: '알람 기록',
  base: '기본',
  advanced: '고급',
  complete: '전체',
  alarmHistoryFree: '제한적',
  phoneMusicAsRingtone: '벨소리로 전화 음악 듣기',
  startFreeTrial: '14일 무료 체험 시작',
  chargedAtEndOfFreeTrial:
    '14일 무료 체험이 종료된 후에는 구독을 취소하지 않는 한 요금이 청구됩니다.',
  monthlySubscription: '월간',
  yearlySubscription: '연간',
  premiumRingtoneRequiresUpgrade: '이 벨소리는 프리미엄 구독이 필요합니다.',
  defaultPremiumFeaturesDescription:
    '프리미엄으로 업그레이드하면 이러한 멋진 기능을 사용할 수 있습니다.',
  groupAlarmsPremiumFeaturesDescription:
    '그룹 알람을 1개 이상 만들려면 프리미엄 구독이 필요합니다.',
  buddyAlarmsPremiumFeaturesDescription:
    '다른 사람("친구")을 위해 알람을 1개 이상 만들려면 프리미엄 구독이 필요합니다.',
  shareableAlarmLinksPremiumFeaturesDescription:
    '공유 가능한 알람 링크를 더 많이 만들려면 프리미엄 구독이 필요합니다.',
  viewPremiumFeaturesDescription:
    '프리미엄 사용자가 되어 주셔서 감사합니다. 프리미엄 구독자는 다음과 같은 멋진 기능을 이용할 수 있습니다.',
  accessToPremiumFeatures: '구독을 통해 이러한 기능에 액세스할 수 있습니다:',
  alarmCategoryPremiumFeaturesDescription:
    '새 카테고리를 만들려면 프리미엄 구독이 필요합니다.',
  galarmWebPremiumFeaturesDescription:
    '웹 브라우저에서 Galarm을 사용하려면 프리미엄 구독이 필요합니다.',
  instantAlarmPremiumFeaturesDescription:
    '더 많은 즉시 알람을 만들려면 프리미엄 구독이 필요합니다.',
  personalAlarmsFeatureDescription:
    '기상, 약 복용 또는 기타 작업을 위해 개인용 알람을 사용하세요. 선택적으로 알람을 놓친 경우 알림을 받을 참가자를 추가하여 알림을 받을 수 있도록 할 수 있습니다. 알람을 완료로 표시하면 참여자에게도 알림이 전송됩니다.',
  groupAlarmsFeatureDescription:
    '나들이, 파티 또는 기타 이벤트와 같은 그룹 활동에 그룹 알람을 사용하세요. 모든 참가자에게 동시에 알람이 울리며, 참가자들은 알람을 통해 자신의 참여를 확인할 수 있습니다.',
  buddyAlarmsFeatureDescription:
    '다른 사람("친구")에게 알람을 만들어 해야 할 일을 상기시켜 줍니다. 버디가 알람을 놓칠 경우 알림을 받을 수 있습니다. 버디가 알람을 완료로 표시하면 본인에게도 알림이 전송됩니다.',
  unlimitedAlarmsFeatureDescription:
    '더 많은 그룹, 친구, 즉시 알람을 만들 수 있습니다.',
  alarmRepetitionsFeatureDescription:
    '30분마다, 2시간 45분마다, 격일, 격주 등의 고급 반복으로 알람을 설정할 수 있습니다.',
  ringtonesFeatureDescription:
    '다양한 프리미엄 벨소리 중에서 알람에 사용할 벨소리를 선택하세요. 프리미엄 벨소리는 지속적으로 추가되고 있습니다.',
  phoneMusicAsRingtoneFeatureDescription:
    '휴대폰에 있는 노래/음악을 알람 벨소리로 선택하세요.',
  shareableAlarmLinksFeatureDescription:
    '링크를 사용하여 다른 사람들과 알람을 공유할 수 있습니다.\n\n예를 들어, 교사는 이메일, WhatsApp 또는 메시징 앱을 사용하여 수업 알람 링크를 학생들과 공유할 수 있습니다. 학생이 링크를 탭하면 휴대폰에 수업 알람 사본이 생성됩니다. 모든 학생은 지정된 시간에 수업에 참여하라는 알림을 받게 됩니다.',
  instantAlarmFeatureDescription:
    '버튼 클릭 한 번으로 여러 사람에게 즉시 알림을 보낼 수 있습니다.\n\n이 알람을 사용하여 비상 훈련, 게임 시작, 긴급한 작업/회의 및 기타 즉각적인 주의가 필요한 이벤트에 대해 알려주세요.',
  alarmHistoryFeatureDescription:
    '반복되는 각 알람에 대한 과거 응답을 모두 볼 수 있습니다. 이를 통해 체육관 수업에 빠졌거나 어머니가 약을 놓친 경우 등을 쉽게 확인할 수 있습니다.',
  announceAlarmTitleFeatureDescription: '알람이 울리면 알람 제목을 알려줍니다.',
  exportToCalendarPremiumFeaturesDescription:
    '더 많은 알람을 캘린더로 내보내려면 프리미엄 구독이 필요합니다.',
  exportToCalendarFeatureDescription:
    '알람을 캘린더로 내보낼 수 있습니다. 이렇게 하면 장치 달력에서 알람을 쉽게 확인하고 가용성을 더 잘 반영할 수 있습니다.',
  galarmWebFeatureDescription:
    '웹 브라우저에서 Galarm을 사용하여 알람을 생성, 편집 및 삭제할 수 있습니다.',
  preReminderFeatureDescription:
    '예정된 알람에 대한 알림을 받으려면 사전 알림을 설정하세요. 30분 전, 1일 전 등과 같이 실제 알람 시간 전에 알림을 받도록 상대적인 기간을 설정할 수 있습니다.',
  calendarViewFeatureDescription:
    '캘린더에서 알람을 확인하여 원하는 날짜의 알람을 쉽게 확인할 수 있습니다.',
  updatePhoneNumberFeatureDescription:
    '이 계정에 사용된 휴대폰 번호를 변경할 수 있습니다. 모든 알람, 연락처, 그룹 및 기타 설정이 새 휴대폰 번호로 마이그레이션됩니다.',
  alarmPhotoFeatureDescription:
    '알람에 사진을 추가하여 약, 작업 지침, 물품 등의 사진을 빠르게 시각적 단서로 활용할 수 있습니다. 알람이 울릴 때 사진이 표시됩니다.',
  taskListFeatureDescription: '작업 목록을 만들어 관련 작업을 함께 관리하세요.',
  calendarViewPremiumFeature: '캘린더 보기',
  updatePhoneNumberPremiumFeature: '전화 번호 변경',
  alarmNotFound: '알람을 찾을 수 없음',
  alarmNotFoundMessage:
    "이 알람이 최근에 삭제되었을 수 있습니다. 이 알람에 대한 추가 알림을 받지 않으려면 '삭제'를 탭하세요.",
  alarmExistsWithDeepLinkTitle: '중복 알람',
  alarmExistsWithDeepLinkMessage:
    '이 링크를 사용하여 만든 알람이 이미 휴대폰에 존재합니다.',
  alarmDoesntHaveNextDate: '알람에 유효한 다음 날짜가 없습니다.',
  sharePersonalAlarmDeepLinkDescription:
    '이 이벤트에 대한 알람을 만들려면 이 링크를 탭하세요( {{dateString}}).',
  joinGroupAlarmDeepLinkDescription:
    '이 그룹 이벤트에 참여하려면 이 링크를 탭하세요. {{dateString}}',
  subscribedAlarmEdited:
    '{{alarmCreatorName}} 가 {{alarmName}} 을 업데이트했습니다. 알람을 업데이트하시겠습니까?',
  subscribedAlarmDeleted:
    '{{alarmCreatorName}} {{alarmName}} 을 삭제했습니다. 알람을 삭제하시겠습니까?',
  responseReceivedForTicket:
    '귀하의 티켓 #{{ticketId}} 에 답변했습니다: {{email}}. 받은 편지함에 없는 경우 스팸 폴더에서 support@galarm.zendesk.com 에서 보낸 이메일이 있는지 확인하세요.',
  newTicketReceived:
    '문의해 주셔서 감사합니다. 요청을 추적하기 위해 티켓 번호{{ticketId}} 을 생성했습니다.\n\n제공하신 이메일 주소( {{email}})로 답변해 드리겠습니다. 이메일 주소가 올바르지 않은 경우 올바른 이메일 주소로 다시 피드백을 제출해 주세요.\n\n또한 응답이 제대로 전달될 수 있도록 이메일 주소록에 support@galarm.zendesk.com 을 추가해 주세요. 또한 요청에 대한 응답이 완료되면 여기로 알림을 보내드립니다.',
  sentAccountBackup:
    '귀하의 알람 데이터를 {{email}} 으로 보냈습니다. 받은 편지함에 없는 경우 스팸 폴더에서 noreply@acintyo.com 으로부터 온 이메일을 확인하세요.',
  alarmDoesntExist: '알람이 존재하지 않습니다.',
  shareAlarm: '알람 링크 공유',
  shareAlarmDescription:
    '링크를 사용하여 누구와도 이 알람을 공유할 수 있습니다. 예를 들어 교사는 링크를 공유하여 학생들에게 수업에 참여하도록 상기시킬 수 있습니다. 누구든지 링크를 탭하면 휴대전화에 알람 사본이 생성됩니다.',
  shareLink: '공유 링크',
  joinedGroupAlarmFromDeepLinkMessage:
    '{{alarmTime}} 에서 "{{alarmName}}"로 이동합니다. {{alarmDate}}',
  joinedRepeatingGroupAlarmFromDeepLinkMessage: {
    zero: '"{{alarmName}}" {{repetitionString}}',
    one: '"{{alarmName}}" {{repetitionString}} {{alarmTime}} 에서 시작됩니다. {{alarmDate}}'
  },
  youHaveSubscribedToThisAlarm:
    '{{creatorName}} 에서 이 알람을 팔로우하고 있습니다.',
  alreadySharedAlarmDescription: {
    zero: '이 알람에 대한 팔로워가 없습니다.\n\n링크를 사용하여 더 많은 사람들과 이 알람을 계속 공유할 수 있습니다. 링크를 탭하면 이 알람의 사본이 휴대폰에 생성됩니다.',
    one: '{{count}} 사람이 이 알람을 팔로우하고 있습니다.\n\n링크를 사용하여 이 알람을 더 많은 사람들과 계속 공유할 수 있습니다. 링크를 탭하면 해당 알람의 사본이 휴대폰에 생성됩니다.',
    other:
      '{{count}} 사람들이 이 알람을 따르고 있습니다.\n\n링크를 사용하여 더 많은 사람들과 이 알람을 계속 공유할 수 있습니다. 링크를 탭하면 휴대폰에 이 알람의 사본이 생성됩니다.'
  },
  shareParentAlarmDescription:
    '{{creatorName}} 에서 이 알람을 팔로우하고 있습니다.\n\n링크를 사용하여 이 알람을 다른 사람들과 공유할 수도 있습니다. 링크를 탭하면 휴대폰에 이 알람의 사본이 생성되고 다른 사람들도 이 알람을 팔로우할 수 있습니다.',
  shareableAlarmLinks: '공유 가능한 링크',
  nPeopleFollowingAlarm: {
    zero: '이 알람에 대한 팔로워가 없습니다.',
    one: '{{count}} 사람이 이 알람을 팔로우하고 있습니다.',
    other: '{{count}} 사람들이 이 알람을 따르고 있습니다.'
  },
  likeUsOnFacebook: 'Facebook',
  followUsOnTwitter: '트위터',
  followUsOnInstagram: '인스타그램',
  youtube: 'YouTube',
  mediumBlog: 'Medium의 블로그',
  howtoVideos: '사용 방법 동영상',
  followGalarmOnSocialMedia: '소셜 미디어에서 Galarm 팔로우하기',
  galarmOnSocialMedia: '소셜 미디어의 갈람',
  followGalarmOnSocialMediaDescription:
    '앱 업데이트, 기능 사용, 출시 예정 기능에 대한 흥미로운 소식과 주요 앱 리뷰를 확인할 수 있습니다. Galarm 팀과도 소통할 수 있습니다.',
  sharedAlarm: '공유 알람 편집',
  editingPublishedAlarm: {
    one: '링크를 사용하여 이전에 공유한 알람을 수정하고 있습니다. {{count}} 다른 사람이 현재 이 알람을 팔로우하고 있습니다.\n\n이 알람을 수정하면 이 사람에게 변경 사항이 알림으로 전송됩니다.',
    other:
      '링크를 사용하여 이전에 공유한 알람을 수정하고 있습니다. {{count}} 사람들이 현재 이 알람을 팔로우하고 있습니다.\n\n이 알람을 수정하면 이들에게 변경 사항이 알림으로 전송됩니다.'
  },
  editingSubscribedAlarm:
    '{{creatorName}} 에서 이 알람을 팔로우하고 있습니다. 알람을 수정한 후에는 이 알람에 대한 업데이트를 받지 못합니다.',
  viewAlarm: '알람 보기',
  view: '보기',
  editAlarm: '알람 편집',
  thanksForTryingPremiumTitle: '모든 준비가 완료되었습니다!',
  thanksForTryingPremiumMessage:
    "프리미엄 기능을 즐겨보시기 바랍니다.\n\n궁금한 점이 있으면 '설정 > 피드백'을 통해 지원팀에 문의하거나 이메일(galarm@acintyo.com)로 문의해 주세요.",
  subscribedAlarmEditedInAlarmDetails:
    '{{creatorName}} 알람을 변경했습니다.\n알람을 업데이트하시나요?',
  subscribedAlarmDeletedInAlarmDetails:
    '{{creatorName}} 가 알람을 삭제했습니다.\n알람을 삭제하시겠습니까?',
  updateSubscribedAlarm: '예, 업데이트',
  deleteSubscribedAlarm: '예, 삭제',
  ignoreSubscribedAlarm: '아니요, 무시',
  customRepetitionPremiumScreenDescription:
    '사용자 지정 반복은 프리미엄 기능입니다.\n\n이 기능을 사용하여 고급 반복으로 알람을 만들 수 있습니다. 예를 들면 다음과 같습니다:\n',
  repeatAlternateDate: '✅ 다른 날에 반복',
  repeatEveryNDay: '✅ 3일마다 반복',
  repeatEveryNHoursAndMMinutes: '✅ 2시간 30분마다 반복',
  repeatEveryNMinutes: '✅ 45분마다 반복',
  repeatBiweekly: '✅ 2주마다 반복',
  repeatEveryNWeeks: '✅ 6주마다 반복',
  repeatEveryNMonths: '✅ 3개월마다 반복(분기별)',
  repeatEveryLastDayOfMonth: '✅ 매월 마지막 날 반복',
  repeatDayOfWeekOfMonth: '✅ 매월 둘째 주 토요일에 반복합니다.',
  repeatLastDayOfWeekOfMonth:
    '매월 마지막 금요일에 반복 ✅ 매월 마지막 금요일에 반복',
  customRepetition: '사용자 지정 반복',
  lastDayOfMonth: '매월 마지막 날',
  dayOfWeekInMonth: '{{weekNumberOfDayInMonth}} {{dayName}} 매월',
  lastDayOfWeekInMonth: '매월 마지막 {{dayName}} ',
  dayOfWeekInMonthInYearSummary:
    '{{weekNumberOfDayInMonth}} {{dayName}} 의 {{monthName}}',
  lastDayOfWeekInMonthInYearSummary: '마지막 {{dayName}} 의 {{monthName}}',
  lastDayOfMonthInYearSummary: '마지막 날 {{monthName}}',
  dayOfWeekInMonthInYear:
    '{{weekNumberOfDayInMonth}} {{dayName}} 의 매년 {{monthName}} ',
  lastDayOfWeekInMonthInYear: '지난 {{dayName}} 의 {{monthName}} 매년',
  lastDayOfMonthInYear: '매년 {{monthName}} 의 마지막 날',
  chooseAMonthsConfiguration: '구성을 선택하세요.',
  chooseADaysConfiguration: '구성을 선택하세요.',
  chooseAYearsConfiguration: '구성을 선택하세요.',
  sendCodeAgain: '코드 다시 보내기',
  didntGetCodeNewUser:
    '코드를 받지 못하셨나요?\n\n코드를 다시 요청하거나 지금은 등록을 건너뛸 수 있습니다. 나중에 등록할 수 있습니다.',
  didntGetCodeExistingUser:
    '코드를 받지 못하셨나요?\n\n코드를 다시 요청하거나 등록을 취소할 수 있습니다. 나중에 등록할 수 있습니다.',
  didntGetCodeUpdatePhoneNumber:
    '코드를 받지 못하셨나요?\n\n코드를 다시 요청하거나 나중에 다시 시도할 수 있습니다.',
  alreadySentCodeAgain:
    '코드를 다시 보내드렸습니다. 코드를 받는 데 문제가 있는 경우 지금은 등록을 건너뛰고 나중에 등록하세요.',
  theme: '테마',
  announceAlarmName: '알람 제목 발표',
  ttsHelperText:
    '알람이 울리면 알람 제목이 표시됩니다. 알람 제목 앞에 있는 재생 버튼을 탭하여 미리 듣기를 들을 수 있습니다.',
  initializingTts: '텍스트 음성 변환 초기화',
  errorSpeakingAlarmName:
    "알람 제목을 말할 수 없습니다. '설정 > 피드백'에서 지원팀에 문의하세요.",
  entityAlarm: '알람',
  entityGroup: '그룹',
  entityContact: '연락처',
  admin: '관리자',
  specifyAlarmTitleToSpeak: '말할 알람 제목 지정',
  wakeup: '일어나기',
  ttsPremiumScreenDescription:
    '알람 제목 알림은 프리미엄 기능입니다.\n\n이 기능을 활성화하면 알람이 울릴 때 알람 제목이 안내됩니다.\n\n미리 보려면 알람 제목을 입력하고 재생을 탭합니다.',
  unknownAlarmCategory: '알 수 없는 알람 카테고리',
  cantAnnounceAlarmTitle: '알람 제목이 발표되지 않습니다.',
  ringtoneDurationIsSmall:
    '알람 제목이 알려지려면 벨소리 길이가 5초 이상이어야 합니다.\n\n이 설정을 계속 사용하시겠습니까?',
  noActionItems: '작업 항목이 없습니다.',
  personNotInContacts: '이 사람은 Galarm 연락처에 없습니다.',
  light: '빛',
  dark: 'Dark',
  systemDefault: '장치 설정 사용',
  chooseTheme: '테마 선택',
  dontSeeAllYourContacts: '연락처가 모두 표시되지 않나요?',
  lastContactsSyncedAt: '마지막 동기화 시점 {{dateString}}',
  refresh: '새로 고침',
  forever: '영원히',
  autoSnoozeConfiguration: '스누즈 구성',
  autoSnoozeConfigurationSummary:
    '{{autoSnoozeDurationLabel}}, {{autoSnoozeCountLabel}}',
  uncategorizedCategoryName: '분류되지 않음',
  uncategorizedCategoryScreenTitle: '분류',
  setAlarmCategory: '알람 카테고리 설정',
  changeAlarmCategory: '알람 카테고리 변경',
  viewAlarmHistory: '알람 기록 보기',
  computingAlarmHistory: '알람 기록에 대한 컴퓨팅 요약...',
  alarmByCategories: '{{name}} 알람',
  alarmCategories: '카테고리',
  editAlarmCategory: '카테고리 편집',
  deleteAlarmCategory: '카테고리 삭제',
  newAlarmCategoryTitle: '새 카테고리',
  newAlarmCategoryNamePlaceholder: '이름 지정',
  editAlarmCategoryTitle: '카테고리 편집',
  alarmCategorySummary: {
    zero: '{{alarmsSummary}}',
    one: '{{alarmsSummary}}, {{count}} 놓친',
    other: '{{alarmsSummary}}, {{count}} 놓친'
  },
  numAlarms: {
    zero: '알람 없음',
    one: '{{count}} 알람',
    other: '{{count}} 알람'
  },
  numIncidents: {
    zero: '인시던트 없음',
    one: '{{count}} 인시던트',
    other: '{{count}} 인시던트'
  },
  openedIncidents: '열린 인시던트',
  closedIncidents: '종료된 인시던트',
  incidentChart: '인시던트 차트(지난 30일)',
  incidentCalendar: '인시던트 캘린더',
  incidentDistribution: '인시던트 배포(최근 30일)',
  noIncidentsOpenedClosedInLast30Days:
    '지난 30일 동안 인시던트가 열리거나 닫힌 적이 없습니다.',
  noIncidentsOpenedInLast30Days: '지난 30일 동안 인시던트가 열리지 않았습니다.',
  color: '색상',
  createNewAlarmCategory:
    '오른쪽 하단의 + 버튼을 탭하여 새 카테고리를 만듭니다.',
  setValidName: '유효한 이름 설정',
  specifyTimezoneForAlarmMessage:
    '알람을 만들 때 시간대를 지정할 수 있습니다. 지정된 시간대에 따라 알람이 울립니다. \n\n"장치 표준 시간대"를 선택하면 장치 표준 시간대에 따라 알람이 울립니다.',
  alarmCategoryFeatureDescription:
    '나만의 알람 카테고리를 만드세요. 카테고리를 사용하면 알람을 쉽게 정리할 수 있습니다.',
  markAlarmDoneInAdvance: '알람을 "완료"로 일찍 표시하시겠습니까?',
  skipAlarmInAdvance: "\"알람을 일찍 '건너뛰세요'?",
  markAlarmDoneInAdvanceMessageRepeatingAlarm:
    "지금 '완료'로 표시하면 {{dateTimeString}} 에서 알람이 울리지 않습니다.",
  skipAlarmInAdvanceMessageRepeatingAlarm:
    '지금 건너뛰면 {{dateTimeString}} 에서 알람이 울리지 않습니다.',
  markAlarmDoneInAdvanceMessageOneTimeAlarm:
    "지금 '완료'로 표시하면 {{dateTimeString}} 에서 알람이 울리지 않습니다.",
  skipAlarmInAdvanceMessageOneTimeAlarm:
    '지금 건너뛰면 {{dateTimeString}} 에서 알람이 울리지 않습니다.',
  alarmsByCategories: '카테고리별 알람',
  workAlarmCategory: '업무',
  homeAlarmCategory: '홈',
  birthdaysAlarmCategory: '생일',
  more: '자세히 보기',
  alarmCategory: '알람 카테고리',
  editAlarmCategoryColor: '색상 편집',
  editAlarmCategoryColorTitle: '다음에 대한 색상 편집 {{name}}',
  allUserCategoriesDeleted:
    "프리미엄 요금제가 취소되었기 때문에 내가 만든 모든 알람 카테고리가 제거되었습니다. 해당 카테고리의 알람은 '분류되지 않음'으로 표시됩니다.",
  calendar: '캘린더',
  eventExportedFromGalarm:
    '"Galarm에서 {{dateString}} 에서 {{timeString}} 으로 내보냈습니다."',
  unableToExportEventToCalendar:
    "캘린더로 이벤트를 내보낼 수 없습니다. 디바이스에서 캘린더에 액세스할 수 있는 권한을 부여했는지 확인하세요. 문제가 지속되면 '설정 > 피드백'을 통해 피드백을 보내주세요.",
  scanQrCode:
    '브라우저에서 Galarm에 액세스하려면 web.galarm.app으로 이동하여 QR 코드를 스캔하세요.',
  logOut: '로그아웃',
  useHere: '여기에서 사용',
  appRunningElsewhere:
    '이 세션에서 로그아웃되었습니다. 이 세션을 다시 시작하시겠습니까?',
  dateTime: '날짜 및 시간',
  selectAtLeastOneDayForRepetition:
    '알람이 반복될 요일을 하루 이상 선택합니다.',
  chooseNumberOfDaysForAlarmRepetition: '알람이 반복될 일수를 입력합니다.',
  chooseNumberOfWeeksForAlarmRepetition: '알람이 반복될 주 수를 입력합니다.',
  chooseNumberOfMonthsForAlarmRepetition: '알람이 반복될 개월 수를 입력합니다.',
  chooseNumberOfYearsForAlarmRepetition: '알람이 반복되는 기간을 입력합니다.',
  previouslySelectedContactsAreUnselected:
    '이전에 선택한 연락처가 선택 해제됩니다.',
  previouslySelectedGroupIsUnselected: '이전에 선택한 그룹이 선택 해제됩니다.',
  galarmWebInstructions:
    '1. 휴대폰에서 Galarm 앱을 엽니다.\n2. "설정" 탭으로 이동하여 "Galarm 웹"을 탭합니다.\n3. 옵션이 표시되지 않으면 최신 앱을 실행하고 있는지 확인하십시오.\n4. 오른쪽의 QR 코드를 스캔합니다.',
  likeToCreateAlarm: '다음 대상에 대한 알람 만들기',
  payUtilityBills: '청구서 납부',
  wakeupAlarmName: '일어나기',
  exerciseAlarmName: '운동',
  drinkWaterAlarmName: '물 마시기',
  everyNHoursRepetitionStringShortSummary: {
    one: '{{startTimeString}} {{endTimeString}} 매시간',
    other: '{{startTimeString}} {{endTimeString}} 매 {{count}} 시간마다'
  },
  firstTimeManagementQuote:
    '"나쁜 소식은 시간이 빨리 간다는 것입니다. 좋은 소식은 당신이 조종사라는 것입니다."',
  firstTimeManagementQuoteAuthor: '마이클 알트슐러',
  secondTimeManagementQuote: '"모든 시간 관리는 계획에서 시작됩니다."',
  secondTimeManagementQuoteAuthor: '톰 그리닝',
  thirdTimeManagementQuote: '"오늘 할 수 있는 일을 내일로 미루지 마라."',
  thirdTimeManagementQuoteAuthor: '벤자민 프랭클린',
  fourthTimeManagementQuote:
    '"시간은 우리가 가장 원하지만 가장 잘못 사용하는 것입니다."',
  fourthTimeManagementQuoteAuthor: '윌리엄 펜',
  fifthTimeManagementQuote:
    '"1년 뒤에는 오늘 시작했더라면 하는 아쉬움이 남을 것입니다."',
  fifthTimeManagementQuoteAuthor: '카렌 램',
  sixthTimeManagementQuote: '"늦출 수는 있지만 시간은 늦추지 않습니다."',
  sixthTimeManagementQuoteAuthor: '벤자민 프랭클린',
  tapToGetStarted: "시작하려면 '+'를 탭하세요.",
  allCategories: '모든 카테고리',
  createAlarmWithContact: '다음을 사용하여 알람을 만듭니다. {{name}}',
  createAlarmWithGroup: '다음을 사용하여 알람을 만듭니다. {{name}}',
  editGroup: '그룹 편집',
  discardChanges: '현재 변경 사항은 삭제됩니다. 계속하시겠습니까?',
  alarmWillRingEveryNDays: {
    zero: '매일 알람이 울립니다.',
    one: '알람은 번갈아 가며 울립니다.',
    other: '매일 {{dayWithOrdinal}} 알람이 울립니다.'
  },
  hoursString: { zero: '', one: '1시간', other: '{{count}} 시간' },
  minutesString: { zero: '', one: '1분', other: '{{count}} 분' },
  alarmWillRingEveryMHoursAndNMinutes:
    '{{dayString}} 을 시작으로 {{repeatString}} 마다 알람이 울립니다. 그 후 {{otherDaysString}} 등으로 이어집니다.',
  alarmWillRingEveryNWeeks: {
    zero: '매주 알람이 울립니다.',
    one: '알람은 격주로 울립니다.',
    other: '매주 {{weekWithOrdinal}} 알람이 울립니다.'
  },
  alarmWillRingEveryNMonths: {
    zero: '매월 알람이 울립니다.',
    one: '알람은 격월로 울립니다.',
    other: '매월 {{monthWithOrdinal}} 알람이 울립니다.'
  },
  alarmWillRingEveryNYears: {
    zero: '매년 알람이 울립니다.',
    one: '알람은 1년마다 울립니다.',
    other: '매년 {{yearWithOrdinal}} 알람이 울립니다.'
  },
  ringParticipantAlarmsByDefaultTooltipMessage:
    '이 환경설정을 사용 설정하면 다른 사람이 보낸 알람은 수락하지 않아도 울립니다. 그래도 알람을 수락해야만 알람에 참여할 의사가 있음을 크리에이터에게 알릴 수 있습니다.\n\n이 환경설정을 비활성화하면 다른 사람이 나를 위해 추가한 알람은 명시적으로 수락하지 않는 한 내 휴대폰에서 울리지 않습니다.',
  specifyTimezoneForAlarmDefaultTooltipMessage:
    '이 환경설정을 사용 설정하면 알람을 만들 때 시간대를 지정할 수 있습니다. 지정된 시간대에 따라 알람이 울립니다.\n\n이 환경설정을 비활성화하면 장치 시간대에 따라 알람이 울립니다.',
  ringOnEarphoneOnlyTooltipTitle: '이어폰 연결 시 알람 동작',
  ringOnEarphoneOnlyTooltipMessage:
    '이어폰이 연결된 경우 이어폰에서만 울리거나 휴대폰 스피커와 이어폰 모두에서 울리도록 알람을 구성할 수 있습니다.\n\n이어폰이 연결되지 않은 경우에는 휴대폰 스피커에서 알람이 울립니다.',
  gestureOptionsTooltipTitle: '알람을 해제하는 제스처',
  gestureOptionsTooltipMessage:
    '잠금 화면에서 알람이 울리면 버튼을 탭하거나 버튼을 밀어서 알람을 해제할 수 있습니다.\n\n이 설정에서는 알람을 해제하는 방법을 선택할 수 있습니다.',
  notificationSettingsTooltipTitle: '알림 설정',
  notificationSettingsTooltipMessage:
    '받고 싶은 알림은 활성화하고 받고 싶지 않은 알림은 비활성화합니다.',
  messagesForAlarm: '대상 메시지 {{name}}',
  systemNotificationsTooltipMessage:
    '다음과 같은 이벤트에 대해 앱에서 생성된 알림:\n  - 연락처의 시간대 변경으로 인해 알람이 영향을 받는 경우\n  - 초대받은 연락처가 Galarm에 등록한 경우\n  - 누군가가 계정을 삭제하여 알람이 영향을 받는 경우',
  galarmWebNotificationsTooltipMessage: 'Galarm 웹에서 보낸 알림입니다.',
  alarmsfromOthersNotificationsTooltipMessage:
    '알람에 참가자로 추가될 때 전송되는 알림입니다.',
  alarmChatNotifictionsTooltipMessage:
    '채팅 메시지에 대한 알림이 알람으로 전송됩니다.',
  groupNotificationsTooltipMessage:
    '내가 그룹에 추가되거나 그룹에서 삭제되거나 다른 사람이 그룹에서 추가/삭제될 때 전송되는 알림입니다.',
  alarmAcknowledgementNotificationsTooltipMessage:
    '참가자가 본인도 참가자인 알람을 확인하면 전송되는 알림입니다.',
  sharedAlarmNotificationsTooltipMessage:
    '전송되는 알림\n  - 누군가 링크를 통해 내가 공유한 알람을 구독하는 경우\n  - 내가 구독한 알람이 변경된 경우',
  sleepTimer: '수면 음악',
  sleepTimerTitle: '음악 재생 대상',
  ongoingSleepTimer: '수면 음악 진행 중',
  stopSleepTimer: '현재 음악을 중지하시겠습니까?',
  restartSleepTimer: '현재 음악을 중지하고 새 음악을 시작하시겠습니까?',
  play: '플레이',
  youAreOffline:
    '현재 오프라인 상태입니다. 작업을 수행하려면 온라인으로 전환하세요.',
  alarmLinkRelativeToTimezone:
    '모든 사람의 알람은 해당 시간대의 {{timeString}}에 울려야 합니다.',
  alarmLinkNotRelativeToTimezone:
    '모든 사람의 알람이 {{timeString}}에 울려야 합니다.',
  sleepMusicTooltip:
    '몸과 마음을 편안하게 해주는 자연스러운 사운드를 재생합니다. 지정된 시간이 지나면 음악 재생이 중지됩니다.',
  alarmLinkCopiedToClipboard: '알람 링크가 클립보드에 복사되었습니다.',
  confirmLogOut: '로그아웃하시겠습니까?',
  appNotRunningMessage:
    '휴대폰에서 Galarm이 실행되고 있지 않음을 감지했습니다. 중요한 알람을 놓치지 않도록 Galarm을 시작하려면 누르세요.',
  tapToEnterNotesForAlarm: '눌러서 노트 입력하기',
  accountAlreadyExists: '기존 계정 발견',
  accountsMerged:
    '이 휴대폰 번호와 연결된 기존 계정을 발견했습니다. 두 계정을 병합했습니다.',
  upgradeToPremiumToAssignUserDefinedCategory:
    '사용자 정의 카테고리를 선택하려면 프리미엄으로 업그레이드하세요.',
  userDefinedCategoryNotSetForAlarm:
    '사용자 정의 카테고리를 자동으로 할당하려면 프리미엄으로 업그레이드하세요. 알람에 대한 카테고리가 설정되어 있지 않습니다.',
  alarmDetails: '알람 세부 정보',
  profileDetails: '프로필',
  activeWebSession:
    '이미 활성 웹 세션이 있습니다. 해당 세션에서 로그아웃하거나 새 세션을 시작할 수 있습니다.\n\n새 세션을 시작하면 현재 활성 상태인 웹 세션에서 자동으로 로그아웃됩니다.',
  startNewSession: '새 세션 시작',
  confirmWebSessionLogout:
    '그러면 현재 웹 세션에서 로그아웃됩니다. 코드를 다시 스캔하여 새 세션을 시작할 수 있습니다.',
  defaultInstantAlarmTitleForGroup: '{{groupName}} 알림 발신자 {{creatorName}}',
  defaultInstantAlarmTitleForContact: '알림 발신자 {{creatorName}}',
  atleastOneMemberShouldBeActive: '최소 한 명의 참가자가 활동 중이어야 합니다.',
  activeParticipants: '활성 참가자',
  acceptAlarm: '알람 수락',
  rejectAlarm: '알람 거부',
  nConfirmed: '{{count}} 확인됨',
  nDeclined: '{{count}} 거부됨',
  nPending: '{{count}} 보류 중',
  everyoneConfirmed: '모두가 확인했습니다.',
  everyoneDeclined: '모두가 거절했습니다.',
  appTagline: '어떤 것도 놓치지 마세요',
  usePreviousBackup: '등록된 사용자입니다 >',
  viewAlarmsByCategories: '카테고리별 알람 보기',
  viewAllAlarms: '모든 알람 보기',
  switchToSummaryView: '요약 보기로 전환',
  switchToListView: '목록 보기로 전환',
  hideHourlyAlarms: '시간별 알람 숨기기',
  calendarView: '갈람 캘린더',
  viewRecentlyDeletedAlarms: '최근 삭제된 알람 보기',
  deletedOnDate: '삭제됨 {{alarmDate}}',
  recentlyDeletedAlarmFooterText:
    '이 알람은 다음에서 삭제되었습니다. {{alarmDate}}',
  noRecentlyDeletedAlarms: '최근에 삭제한 알람 없음',
  restoreAlarmsFromMobileApp:
    "이러한 알람은 삭제한 후 30일이 지나면 자동으로 삭제됩니다. 이러한 알람을 복원하려면 모바일 앱의 '최근 삭제한 알람' 화면으로 이동하여 복원 아이콘을 탭하세요.",
  deleteInactiveAlarms: '비활성 알람 삭제',
  defaultConfigurationUpdated: '기본 구성이 업데이트되었습니다.',
  notAlerted: '(제외)',
  alarmKeepsRingingHelp: '도와주세요! 알람이 계속 울립니다.',
  alarmKeepsRingingHelpMessage: '알람을 새로 고치고 문제 보고서를 보냈습니다.',
  alarmKeepsRingingHelpAlert:
    "알람을 새로 고치면 더 이상 이 문제가 발생하지 않을 것입니다. 또한 추가 조사를 위해 지원팀에 문제 보고서를 보내드리겠습니다.\n\n  문제가 지속되면 '설정 > 피드백'을 통해 문의해 주세요.",
  sendingFeedback: '피드백 보내기',
  sendingProblemReport: '문제 보고서 보내기',
  fixingAlarms: '알람 새로 고침하기',
  existingAccountFound: '백업 복원',
  existingAccountDataRestored: {
    zero: '축하합니다. 모든 데이터가 백업에서 복원되었습니다.',
    one: '축하합니다. 모든 데이터가 백업에서 복원되었습니다.',
    other: '축하합니다. 모든 데이터가 백업에서 복원되었습니다.'
  },
  noExistingAccountFound: '백업을 찾을 수 없음',
  createdNewAccount:
    '이 휴대폰 번호는 Galarm에 등록되지 않았습니다. 이 전화번호를 사용하여 새 계정을 만들었습니다.',
  iosCloseToAllowedNumNotificationsScheduled: {
    zero: 'iOS에서는 앱에서 최대 64개의 알림을 예약할 수 있습니다. 해당 알림을 모두 사용했습니다. 일부 알람을 놓칠 수 있습니다.',
    one: 'iOS에서는 앱에서 최대 64개의 알림을 예약할 수 있습니다. 해당 알림 중 {{numNotifications}} 을 모두 사용했습니다.'
  },
  acknowledgeAlarmToScheduleNextOccurrence:
    '이 알람에 대한 마지막 알림입니다. 이 알림을 길게 누르고 다음 알람 발생을 예약하려면 조치를 취하세요.',
  lastNotificationForOneTimeAlarm: '이 알람에 대한 마지막 알림입니다.',
  criticalAlertsMessageTitle: '휴대폰 음소거 시 알람 울리기',
  criticalAlertsMessageDescription:
    "\n휴대폰이 음소거되어 있을 때 iOS 중요 알림을 사용하여 알람을 울리도록 Galarm을 구성할 수 있습니다. 모든 알람에 대해 이 기능을 사용 설정하시겠습니까?\n\n'소리 및 진동 > 중요 알림' 설정을 편집하여 일부 알람에 대해서만 선택적으로 활성화할 수도 있습니다.",
  allowCriticalAlerts: '중요 알림 허용',
  criticalAlertsPermissionDenied:
    'Galarm에서 중요 알림을 표시하려면 휴대폰의 권한이 필요합니다. 휴대폰 설정에서 이 권한을 활성화하세요.',
  enableCriticalAlertsForAllAlarms: '예, 모든 알람에 사용 설정',
  selectivelyEnableCriticalAlerts: '아니요, 선택적으로 활성화하겠습니다.',
  firstName: '이름',
  lastName: '성',
  email: '이메일',
  password: '비밀번호',
  confirmPassword: '비밀번호 확인',
  signup: '가입하기',
  login: '로그인',
  alreadySignedUp: '이미 가입하셨나요?',
  notAUser: '사용자가 아니신가요?',
  subscribe: '구독',
  choosePlan: '요금제 구성',
  billingCycle: '청구 주기',
  needHelpToGetStarted: '시작하는 데 도움이 필요하신가요?',
  browser: '브라우저: {{name}}',
  deviceOs: '장치: {{name}}',
  lastActive: '마지막 활성 상태: {{timestamp}}',
  signUp: '가입하기',
  useGalarmOnWeb: '웹에서 Galarm 사용',
  welcomeUser: '환영 {{name}}',
  changePassword: '비밀번호 변경',
  saveAlarm: '알람 저장',
  galarmUserTip: '알고 계셨나요?',
  sendAlarm: '알람 보내기',
  saveGroup: '그룹 저장',
  webAppBestViewedOnDesktop:
    'URL(web.galarm.app)은 노트북이나 데스크톱의 웹 브라우저에서 Galarm 인터페이스를 실행하기 위한 것입니다.\n\n이 모바일 장치에서 Galarm을 사용하려면 아래 링크 중 하나를 사용하여 Google Play에서 Android 앱을 설치하거나 App Store에서 iOS 앱을 설치하세요:',
  scanQrCodeMessage: 'QR코드 스캔',
  reloadQrCode: 'QR 코드를 다시 로드하려면 클릭하세요.',
  invalidQrCodeScanned: '잘못된 QR 코드가 스캔되었습니다.',
  unableToScanCode: '코드를 스캔할 수 없습니다.',
  unableToScanCodeMessage:
    "코드를 스캔하는 동안 문제가 발생했습니다. 다시 시도해 주세요. 계속 문제가 발생하면 모바일 앱에서 '설정 > 피드백'을 통해 피드백을 보내주세요.",
  problemWithEnterpriseAccountSetup:
    'Galarm Pro 계정을 설정하는 동안 문제가 발생했습니다. 기술 지원팀에 문의하세요.',
  problemWithCreatingCheckoutSession:
    '결제 세션을 생성하는 동안 문제가 발생했습니다. 기술 지원팀에 문의하세요.',
  problemWithCreatingCustomerPortalSession:
    '고객 포털 세션을 만드는 동안 문제가 발생했습니다. 기술 지원팀에 문의하세요.',
  problemWithCreatingApiKey:
    'API 키를 생성하는 동안 문제가 발생했습니다. 기술 지원팀에 문의하세요.',
  creatingAnAccount: '계정 만들기',
  currentPlan: '현재 요금제: {{name}} ({{quantity}} 좌석)',
  myAccount: '계정 정보',
  new: '신규',
  deleteAllAlarmHistoryAlertTitle: '알람 기록 삭제',
  deleteAllAlarmHistoryAlertMessage:
    "이 알람의 기록을 삭제하려면 '계속'을 탭합니다. 이 작업은 취소할 수 없습니다.",
  ageOutInactiveAlarmsMessage:
    '60일 이상 비활성 상태인 {{count}} 알람이 있습니다. 비활성 알람이 많으면 앱 성능에 영향을 미칠 수 있습니다.\n\n이러한 알람을 삭제하시겠습니까?',
  duplicateAlarm: '중복 알람',
  graduallyIncreaseVolume: '점차적으로 볼륨 높이기',
  graduallyIncreaseVolumeTooltipMessage:
    '이 환경설정을 활성화하면 알람 볼륨이 설정된 벨소리 볼륨까지 서서히 증가합니다. 이렇게 하면 알람이 너무 커지기 전에 알람을 중지할 수 있습니다.\n\n이 기본 설정을 비활성화하면 전체 벨소리 시간 동안 구성된 벨소리 볼륨으로 알람이 울립니다.',
  teams: '팀',
  alerts: '알림',
  listeners: '청취자',
  noMembersConfiguredInEnterpriseAccount:
    '아직 멤버를 추가하지 않았습니다. 멤버는 수신자의 에스컬레이션 정책에 추가할 수 있고 수신자가 수신한 인시던트에 대해 알림을 받을 수 있는 사용자입니다. 이 사람들은 이미 Galarm에 등록되어 있어야 합니다.',
  noTeamsConfiguredInEnterpriseAccount:
    '아직 팀을 만들지 않았습니다. 팀은 수신자의 에스컬레이션 정책에 추가할 수 있는 구성원들의 그룹으로 수신자가 수신한 인시던트에 대해 알림을 받습니다.',
  noAlertsConfiguredInEnterpriseAccount:
    '아직 리스너를 만들지 않았습니다. 모니터링 도구에서 신고된 인시던트를 수신하도록 수신기를 구성할 수 있습니다. 수신기를 만들면 수신기를 다양한 모니터링 도구와 통합하는 방법에 대한 지침을 볼 수 있습니다.\n\n각 리스너에 대해 여러 에스컬레이션 수준을 구성할 수 있습니다.',
  noShiftsConfiguredInEnterpriseAccount:
    '아직 교대근무 구성을 만들지 않았습니다.',
  noRotationsConfiguredInEnterpriseAccount:
    '아직 로테이션을 생성하지 않았습니다.',
  rotations: '회전',
  addRotation: '회전 추가',
  viewRotation: '보기 회전',
  enterRotationName: '로테이션 이름 입력',
  rotationType: '회전 유형',
  numberOfHoursInRotation: '로테이션 시간 수',
  numberOfDaysInRotation: '로테이션 일수',
  numberOfWeeksInRotation: '로테이션 주 수',
  specify: '지정...',
  noIntegrationsConfiguredInEnterpriseAccount:
    '아직 연동 설정을 구성하지 않았습니다.',
  loadingUser: '사용자 정보 로드',
  addMember: '회원 추가',
  add: '추가',
  noUserFoundWithMobileNumber:
    '이 전화번호로 등록된 Galarm 사용자를 찾을 수 없습니다. 괄호, 대시, 공백 등의 특수 문자 없이 국가 코드와 함께 전화번호를 입력했는지 확인하세요.',
  memberWithPhoneNumberAlreadyAdded:
    '이 전화번호를 가진 회원이 이미 추가되었습니다.',
  memberWithEmailAlreadyAdded: '이 이메일을 받은 회원이 이미 추가되었습니다.',
  assignKeyToMember: '라이선스 키 할당',
  revokeKeyFromMember: '라이선스 키 해지',
  cantAssignKeyToMember: '회원에게 키를 할당할 수 없습니다.',
  noEnterpriseLicenseKeyAvailable:
    '사용 가능한 라이선스 키가 없습니다. 요금제를 업그레이드하세요.',
  addTeam: '팀 추가',
  enterTeamName: '팀 이름 입력',
  addAlert: '리스너 추가',
  low: '낮음',
  medium: 'Medium',
  high: '높음',
  severity: '심각도',
  enterAlertName: '리스너의 이름을 입력합니다.',
  organization: '조직',
  startTime: '시작 시간',
  endTime: '종료 시간',
  shiftName: '시프트 {{index}}',
  addShift: 'Shift 추가',
  assignees: '양수인',
  shiftStartEndTime: '{{startTime}} - {{endTime}}',
  assignee: '양수인',
  specifyAssigneeForShift: '교대근무 담당자 지정',
  selectAtLeastOneMember: '구성원 한 명 이상 선택',
  editMember: '회원 수정',
  editTeam: '편집 팀',
  cantRemoveMemberAssignedToTeamsOrAlerts:
    '멤버가 팀의 일부이거나 리스트너에게 할당된 경우. 삭제하기 전에 해당 팀/리스터에서 해당 멤버를 제거하세요.\n팀: {{teams}}\n청취자: {{alerts}}',
  cantRevokeKeyFromMemberAssignedToTeamsOrAlerts:
    '팀에 소속되어 있거나 리스트너에게 배정된 멤버입니다. 키를 해지하기 전에 해당 팀/리스터에서 해당 멤버를 제거하세요.\n팀: {{teams}}\n리스너: {{alerts}}',
  cantDeleteTeamAssignedToAlerts:
    '팀이 리스너의 일부입니다. 삭제하기 전에 수신자에서 팀을 제거하세요.\n리스너: {{alerts}}',
  cantDeleteRotationAssignedToAlerts:
    '로테이션은 리스너의 일부입니다. 삭제하기 전에 해당 팀을 리스너에서 제거하세요.\n리스너: {{alerts}}',
  editAlert: '리스너 편집',
  timezoneDisplayString: '(GMT{{offset}}) {{timezone}}',
  getCode: '코드 받기',
  apiKey: 'API 키',
  viewApiKey: 'API 키 보기',
  apiKeyWarningMessage:
    "API 키를 항상 안전하게 보관하세요. 어떤 기관과도 공유하지 마세요. API 키가 유출되었다고 생각되면 위의 '변경' 버튼을 탭하여 키를 변경하세요. 키를 변경한 후에는 REST API를 사용하여 인시던트를 트리거하는 모든 위치에서 새 키를 지정해야 합니다.",
  integrationConfigs: '통합 구성',
  addIntegrationConfig: '통합 구성 추가',
  editIntegrationConfig: '통합 구성 편집',
  integrationSource: '통합 소스',
  apiToken: 'API 토큰',
  integrations: '통합',
  selectPlaceholder: '선택...',
  zendeskSubdomain: 'Zendesk 하위 도메인',
  creatorTimeForBuddyAlarm: '{{timeString}} ({{remindMeAfter}})',
  nextFiveAlarms: '다음 5가지 알람',
  categories: '카테고리',
  viewingAlarmsDueToday: '오늘 마감되는 알람 보기',
  viewingAlarmsDueTomorrow: '내일 마감되는 알람 보기',
  expiredAlarms: '만료된 알람',
  viewingExpiredAlarms: '만료된 알람 보기',
  noAlarmsDueToday: '오늘 예정된 알람 없음',
  noAlarmsDueTomorrow: '내일 마감되는 알람 없음',
  noExpiredAlarms: '만료된 알람 없음',
  viewingAllAlarms: '모든 알람 보기',
  viewingRecentlyDeletedAlarms: '최근 삭제된 알람 보기',
  summaryView: '요약 보기를 사용하시겠습니까?',
  summaryViewDescription:
    '마감일 및 카테고리별로 정리된 알람을 확인하세요.\n\n오른쪽 상단의 ⋮ 버튼을 사용하여 목록 보기로 다시 전환할 수 있습니다.',
  disableBatteryOptimizationMessageOnStartup:
    '알람이 안정적으로 울리려면 Galarm이 백그라운드에서 실행되어야 합니다. 다음 화면에서 권한을 허용하세요.',
  disableBatteryOptimizationMessage:
    '알람이 안정적으로 울리려면 Galarm이 백그라운드에서 실행되어야 합니다.',
  allowNotificationsToDisplayAlarms:
    '알람이 울릴 때 알림을 표시하려면 Galarm에 권한이 필요합니다.',
  scheduleExactAlarmsMessage:
    'Galarm은 정확한 알람을 예약하려면 권한이 필요합니다.',
  alarmCategoryWithSameNameExists:
    '이 이름의 카테고리가 이미 존재합니다. 다른 이름을 지정해 주세요.',
  unlimitedAlarmsPremiumFeature: '무제한 그룹, 버디 및 인스턴트 알람',
  alarmRepetitionsPremiumFeature: '고급 반복 옵션',
  ringtonesPremiumFeature: '더 많은 벨소리',
  galarmWebPremiumFeature: '웹에서 Galarm 사용',
  taskListPremiumFeature: '여러 작업 목록 만들기',
  preReminderPremiumFeature: '알람 사전 알림',
  alarmCategoriesPremiumFeature: '사용자 지정 알람 카테고리',
  shareableAlarmLinksPremiumFeature: '공유 가능한 알람 링크',
  announceAlarmNamePremiumFeature: '알람 제목 발표',
  manageSubscription: '구독 관리',
  purchaseRestored: '구매가 복원되었습니다.',
  unableToRetrieveSubscriptionPlansAndroid:
    '업그레이드를 수행하기 위해 Google Play에 연결할 수 없습니다. 인터넷에 연결되어 있는지 확인한 후 다시 시도하세요. \n\n이 문제가 지속되면 지원팀에 문제를 신고해 주세요.',
  unableToRetrieveSubscriptionPlansIos:
    '업그레이드를 수행하기 위해 App Store에 연결할 수 없습니다. 인터넷에 연결되어 있는지 확인한 후 다시 시도하세요. \n\n이 문제가 지속되면 지원팀에 문제를 신고해 주세요.',
  drawOverOtherApps: '다른 앱 위에 표시',
  drawOverOtherAppsHelperText:
    'Galarm이 알람 알림을 상단에 표시하도록 허용하기',
  nTimes: { one: '한 번', other: '{{count}} 횟수' },
  learnHowToUseFocus: '집중 학습',
  enhancedNotificationsDeprecated: '향상된 알림 사용 중단',
  enhancedNotificationsDeprecatedMessage:
    '향상된 알림이 안정적이지 않고 과도한 배터리 소모를 유발한다는 사실을 발견했습니다. 휴대폰이 방해 금지 모드로 설정되어 있을 때 Galarm이 알람을 울리도록 하려면 iOS Focus를 사용하세요.',
  usingIosFocus: 'iOS Focus 사용',
  iosFocusModePara1:
    "휴대폰의 벨소리 스위치가 꺼져 있거나 벨소리 볼륨이 0인 경우 Galarm은 벨소리를 울리지 않습니다.\n\n휴대폰을 무음으로 유지하되 일부 앱에서 알림을 받을 수 있도록 하려면 '포커스'를 사용할 것을 Apple은 권장합니다. 다음 안내에 따라 휴대폰에서 Focus를 설정하세요.",
  iosFocusModePara2:
    '자세한 지침은 https://galarm.zendesk.com/hc/en-us/articles/10906890793236-Allow-alarms-to-ring-on-silent-on-iOS 에서 스크린샷과 함께 확인할 수 있습니다.',
  settingFocus: 'Focus 설정하기:',
  settingFocusFootNotes:
    "Focus는 iOS 15 이상에서 사용할 수 있습니다.\n\n질문이나 피드백이 있으면 '설정 > 피드백'을 통해 문의해 주세요.",
  enablingFocus: '포커스 활성화:',
  settingFocusInstruction1: '"휴대폰 설정 > 초점"으로 이동합니다.',
  settingFocusInstruction2: "'방해 금지' 포커스 프로필을 탭합니다.",
  settingFocusInstruction3: '"앱"을 탭하고 Galarm을 추가합니다.',
  settingFocusInstruction4:
    '설정한 다른 Focus 프로필에 대해서도 동일한 작업을 수행합니다.',
  enablingFocusInstruction1:
    '오른쪽 상단 가장자리에서 아래로 스와이프하여 제어 센터를 확인합니다.',
  enablingFocusInstruction2: '초점을 탭합니다.',
  enablingFocusInstruction3:
    '방해 금지 모드 또는 사용하려는 다른 Focus 프로필을 탭합니다.',
  enablingFocusInstruction4:
    '현재 활성화된 초점이 선택된 것으로 나타납니다. 다시 탭하여 비활성화할 수 있습니다.',
  startDateForRotation: '로테이션 시작 날짜',
  startTimeForRotation: '회전 시작 시간',
  member: '회원',
  multipleShiftsInADay: '하루에 여러 번 교대 근무',
  escalationPolicy: '에스컬레이션 정책',
  atTimeOfEvent: '이벤트 진행 시',
  afterNMinutes: '{{count}} 분 후',
  afterNHours: { one: '1시간 후', other: '{{count}} 시간 이후' },
  afterDuration: '이후 {{durationString}}',
  addTrigger: '트리거 추가',
  viewCode: '코드 보기',
  apiCodes: 'API 코드',
  viewIncidents: '인시던트 보기',
  incidentsForAlert: '리스너를 위한 인시던트 {{alertName}}',
  timestamp: '타임스탬프',
  acknowledged: '인정됨',
  noIncidentsForAlert: '아직 이 리스너에 대한 인시던트가 없습니다.',
  apiCodeCopiedToClipboard: 'API 코드가 클립보드에 복사되었습니다.',
  triggerName: '트리거 {{index}}',
  delay: '지연',
  chooseAssigneePlaceholder: '양수인 선택...',
  delayForTrigger: '트리거 지연',
  assigneeWillBeAlertedAfterDelay:
    '양수인에게 알림이 전송됩니다 {{durationString}}.',
  escalationForIncidentsForAlert:
    '수신자의 경우 {{incidentDate}} 로 인시던트 에스컬레이션하기 {{alertName}}',
  alertIncidentAcknowledged: '{{memberName}} 에서 확인 {{dateString}}',
  alertIncidentRecovered: '다음에서 복구되었습니다. {{dateString}}',
  alertIncidents: '인시던트',
  viewingAlertIncidents: '인시던트 보기',
  createOrganization: '조직 만들기',
  youAreNotPartOfAnyOrganization:
    '귀하는 어떤 조직에도 속해 있지 않습니다. 시작하려면 조직을 만드세요.',
  tellUsAboutYourOrganization: '귀사의 조직에 대해 알려주세요.',
  noActiveSubscription: '활성 구독이 없습니다.',
  chooseOrganization: '계속할 조직을 선택합니다.',
  noTriggersForIncident: '이 인시던트에 대한 트리거를 찾을 수 없습니다.',
  role: '역할',
  viewer: '뷰어',
  newOrganization: '새 조직',
  noOrganizations: '귀하는 어떤 조직에도 속해 있지 않습니다.',
  maxOrganizationsReached: '사용자는 최대 10개 조직에 소속될 수 있습니다.',
  yourOrganizations: '조직',
  urlNotFound: 'URL이 지원되는 경로와 일치하지 않습니다.',
  triggerAlert: '인시던트 트리거',
  recaptchaFailed: '재캡차 인증에 실패했습니다. {{error}}',
  loadingAccountInformation: '계정 정보 로드 중',
  loadingAuthInfo: '인증 정보 로드',
  allAlerts: '모든 청취자',
  recentlyTriggeredAlerts: '최근 트리거된 리스너',
  recentlyClosedIncidents: '최근 종료된 인시던트',
  noRecentlyTriggeredAlerts: '최근에 트리거된 리스너가 없습니다.',
  noRecentlyClosedIncidents: '최근에 종결된 인시던트는 없습니다.',
  closedAt: '마감 시간 {{dateTimeString}}',
  dashboard: '대시보드',
  loggedIntoOrganizationAccount: '(조직: {{organizationName}}, 역할: {{role}})',
  viewIncidentEscalation: '인시던트 에스컬레이션 보기',
  closeIncident: '인시던트 닫기',
  closingIncident: '인시던트 마감',
  unableToRefreshEnterpriseAccount: '계정을 새로 고칠 수 없습니다.',
  viewAuthToken: '인증 토큰 보기',
  problemWithCreatingAuthToken:
    '인증 토큰을 만드는 동안 문제가 발생했습니다. 기술 지원팀에 문의하세요.',
  authToken: '인증 토큰',
  authTokenWarningMessage:
    "이 인증 토큰은 항상 안전하게 보관하세요. 어떤 기관과도 공유하지 마세요. API 키가 유출되었다고 생각되면 위의 '변경' 버튼을 탭하여 변경하세요. 토큰을 변경한 후에는 REST API를 사용하여 인시던트를 트리거하는 모든 곳에서 새 토큰을 지정해야 합니다.",
  authTokenCopiedToClipboard: '인증 토큰이 클립보드에 복사되었습니다.',
  resendVerificationEmail: '확인 이메일 다시 보내기',
  loggedInUsingEmail: '이메일을 사용하여 로그인 {{email}}.',
  checkingIfMemberIsAppUser: '회원이 앱 사용자인지 확인합니다.',
  settingMemberDetails: '회원 세부 정보 설정하기.',
  addingTeam: '팀 추가하기.',
  editingTeam: '편집 팀.',
  addingRotation: '회전 추가하기.',
  editingRotation: '회전 편집.',
  assigningKeyToMember: '회원에게 키 할당하기.',
  revokingKeyFromMember: '회원의 키를 해지합니다.',
  deletingTeam: '팀 삭제하기.',
  deletingRotation: '회전 삭제하기.',
  addingAlert: '리스너 추가하기.',
  editingAlert: '리스너 편집하기.',
  deletingAlert: '리스너 삭제하기.',
  triggeringAlert: '인시던트 트리거.',
  creatingOrganizationAccount: '조직 계정 만들기.',
  creatingCheckoutSession: '결제 세션 시작하기.',
  creatingAnAccountForMember: '회원용 계정 만들기.',
  passwordResetEmailSent:
    '비밀번호 재설정을 위한 이메일을 보내드렸습니다. 비밀번호를 변경하고 다시 로그인하세요.',
  changeOwner: '소유자 변경',
  changeOrganizationOwner: '조직 소유자 변경',
  newOwnerEmail: '새 소유자 이메일',
  saveOnYearlySubscription: '저장 {{percent}}%',
  annual: '연간',
  resetAlarms: '알람 새로 고침',
  resetAlarmsAlert:
    "모든 알람이 새로 고쳐졌습니다. 알람에 문제가 계속 발생하면 '문제 신고' 옵션을 사용하여 티켓을 제출하세요.",
  resetAlarmsTooltipMessage:
    "삭제된 알람이 휴대폰에서 울리는 등 알람에 문제가 있는 경우 이 옵션을 사용하여 알람을 새로 고쳐주세요. 문제가 지속되면 '문제 신고' 옵션을 사용하여 티켓을 제출하세요.",
  resetTempPassword: '임시 비밀번호 재설정',
  currPassword: '현재 비밀번호',
  tempPassword: '임시 비밀번호',
  newPassword: '새 비밀번호',
  reset: '초기화',
  loggingIn: '로그인',
  resettingTempPassword: '임시 비밀번호 재설정하기',
  changingPassword: '비밀번호 변경',
  numSeats: '좌석 수',
  helpMeDecide: '결정을 도와주세요',
  numEnterpriseSeatsHelper:
    '조직에서 앱을 사용하여 인시던트를 수신할 사람의 수만큼 좌석 수를 선택해야 합니다. 수신기를 구성하는 동안 에스컬레이션 정책을 지정할 수 있습니다. 에스컬레이션 정책에는 여러 단계를 지정할 수 있습니다. 예를 들어 첫 번째 에스컬레이션 수준을 3명으로 구성된 팀으로 지정할 수 있습니다. 첫 번째 에스컬레이션 수준에서 5분 이내에 응답하지 않으면 두 번째 수준은 5명으로 구성된 팀으로 에스컬레이션됩니다. 이 경우 좌석 수를 8명으로 지정할 수 있습니다.',
  numBusinessSeatsHelper:
    '알람 및 미리 알림을 받기 위해 Galarm을 사용할 사람 수에 맞게 좌석 수를 선택합니다.',
  perSeatPrice: '{{price}} 당 {{interval}} 좌석당',
  doneForToday: '인정됨',
  getStarted: '시작하기',
  firstAlertInstructions: '첫 번째 청취자 지침',
  learnMoreAboutGalarmEnterprise: 'Galarm Pro에 대해 자세히 알아보기',
  home: '홈',
  team: '팀',
  alert: '알림',
  listener: '리스너',
  forgotPassword: '비밀번호를 잊으셨나요?',
  forgotPasswordTitle: '비밀번호 찾기',
  sendPasswordResetEmail: '비밀번호 재설정 이메일 보내기',
  sendingPasswordResetEmail: '비밀번호 재설정 이메일 보내기',
  field: '필드',
  description: '설명',
  requestIntegration: '다른 통합 요청하기',
  integrationDetails: '통합 세부 정보',
  enterIntegrationDetails: '통합 세부 정보 제공',
  galarmEnterpriseFreeTrial: '14일 무료 평가판 등록하기',
  galarmEnterpriseFreeTrialDescription: '인시던트 관리 자동화',
  bySigningUpYouAgreeToOur: '가입하면 다음 약관에 동의하는 것입니다.',
  and: '그리고',
  alarmSubscribers: '알람 팔로워',
  otherSubscribersCount: {
    one: '연락처에 없는 팔로워 1명 추가',
    other: '{{count}} 연락처에 없는 팔로워 수 늘리기'
  },
  getAccountData: '알람 데이터 내보내기',
  getAccountDataPrompt:
    '모든 알람이 포함된 텍스트 파일을 보낼 이메일 주소를 입력하세요.',
  getAccountDataConfirmation:
    '모든 알람이 포함된 텍스트 파일을 {{email}} 으로 이메일로 보내드릴 예정이니 참고하시기 바랍니다.',
  sendingAccountData: '알람 데이터 내보내기...',
  sentAccountData: '{{email}} 으로 전송된 알람 데이터 .',
  errorSendingAccountData:
    '알람 데이터를 내보내는 중 오류가 발생했습니다: {{error}}',
  sendingEmailVerificationLink: '이메일 인증 링크 보내기',
  viewLogs: '로그 보기',
  message: '메시지',
  billing: '청구',
  newIncidentForAlert: '리스너를 위한 새로운 인시던트 {{alertName}}',
  clickNextAfterAddingMembers: "멤버 추가 후 '다음'을 클릭합니다.",
  clickDoneAfterAddingAlerts:
    "'완료'를 클릭하여 설정을 완료하고 대시보드로 이동합니다.",
  alertCreatedSuccessfully:
    '리스너가 성공적으로 생성되었습니다. 이 리스너는 리스너 이름 앞의 점 3개 메뉴 아이콘에서 트리거할 수 있습니다.',
  fixSubscription: '구독 수정',
  fixSubscriptionDescription:
    '구독에 문제가 발견되었습니다. 구독에 {{numSeats}} 시트가 포함되어 있지만 {{numSeatsAssigned}} 회원에게 키를 할당했습니다. 최대 {{numSeats}} 키를 할당할 수 있습니다.',
  fixSubscriptionChooseOption:
    '아래 옵션 중 하나를 사용하여 구독을 수정하세요.',
  fixSubscriptionContactAdmin: '조직 관리자에게 문의하세요.',
  unassignKeysFromMembers:
    '구성원으로부터 최소 {{extraKeysAssigned}} 키 할당 취소',
  addMoreSeatsToSubscription:
    '구독에 최소 {{extraKeysAssigned}} 시트를 추가하세요.',
  revokeMembersKey: '회원 키 해지',
  revokeMembersKeyDescription: '키를 해지하려는 멤버를 선택합니다.',
  revoke: '취소',
  unableToRevokeMembersKey:
    '회원 키를 해지하는 동안 오류가 발생했습니다. 지원팀에 문의하세요.',
  problemWithLoadingProducts: '제품 로딩에 문제가 있습니다. {{error}}',
  verificationCompleted: '인증 완료',
  deviceAlreadyRegistered:
    '이 휴대폰 번호는 이전에 이 장치에서 확인된 적이 있습니다.',
  phoneNumberAutoFilled:
    'Galarm은 Google 계정에서 선택한 전화번호를 사용합니다.',
  unableToRegisterPreVerifiedUser: '사전 인증된 사용자를 등록할 수 없습니다.',
  allowNotifications: '알림 허용',
  allowNotificationsDescription: '알람이 울릴 때 알람을 표시합니다.',
  scheduleExactAlarms: '알람 예약',
  scheduleExactAlarmsHelperText:
    'Galarm이 정확한 시간에 알람을 울리도록 허용하세요.',
  scheduleExactAlarmsDescription: '정확한 시간에 알람을 울리려면.',
  disableBatteryOptimization: '배터리 최적화 관리',
  disableBatteryOptimizationDescription:
    'Galarm이 백그라운드에서 실행되도록 허용합니다.',
  requestRequiredPermissions:
    '알람을 울리려면 Galarm에 다음 권한이 필요합니다.',
  allowDisallowedPermissions: '탭하여 허용',
  notificationsAreEnabled: '알림 표시 권한은 이미 허용되어 있습니다.',
  connectingToStore: '스토어에 연결 중...',
  restartApp: '앱 다시 시작',
  updateAvailable: '업데이트 가능',
  english: '영어',
  russian: '러시아어',
  german: '독일어',
  french: '프랑스어',
  spanish: '스페인어',
  italian: '이탈리아어',
  portuguese: '포르투갈어',
  japanese: '일본어',
  korean: '한국어',
  indonesian: '인도네시아어',
  changeAppLanguage: '앱 언어 변경',
  appLanguage: '앱 언어',
  iosChangeAppLanguagePara1:
    'Galarm은 휴대폰 설정에서 앱 언어를 선택합니다. Galarm의 앱 언어를 변경하려면 다음 단계를 따르세요:',
  iosChangeAppLanguageInstruction1:
    '"휴대폰 설정 > 일반 > 언어 및 지역"을 엽니다. Galarm에 사용하려는 언어가 기본 설정 언어 목록에 있는지 확인합니다.',
  iosChangeAppLanguageInstruction2:
    "'휴대폰 설정'으로 돌아가서 하단의 앱 목록에서 'Galarm'을 탭합니다.",
  iosChangeAppLanguageInstruction3:
    '"언어"를 탭하고 Galarm에 설정할 언어를 선택합니다. 언어를 선택한 후 앱으로 돌아옵니다.',
  androidChangeAppLanguagePara1:
    'Galarm은 휴대폰 설정에서 앱 언어를 선택합니다. Galarm의 앱 언어를 변경하려면 다음 단계를 따르세요:',
  androidChangeAppLanguageInstruction1:
    "아래의 '앱 설정 열기' 버튼을 탭합니다.",
  androidChangeAppLanguageInstruction2:
    '"언어"를 탭하고 Galarm에 사용할 언어를 선택합니다.',
  androidChangeAppLanguageInstruction3:
    "언어를 선택한 후 여기로 돌아와 아래의 '앱 다시 시작' 버튼을 클릭합니다.",
  problemRetrievingSubscriptionData:
    '구독 정보를 검색하는 동안 문제가 발생했습니다. 계정 페이지로 연결됩니다. 구독 상태를 확인하고 문제가 계속되면 기술 지원팀에 문의하세요.',
  deletingOrganization: '조직 삭제하기...',
  deleteOrganization: '조직 삭제',
  deleteOrganizationDescription:
    '조직을 삭제하시겠습니까?\n  \n  이것은 되돌릴 수 없는 작업입니다. 조직의 모든 데이터가 삭제됩니다.\n  \n  조직을 삭제하기 전에 구독을 해지했는지 확인하세요.',
  galarmEnterpriseTrialDescription:
    '14일 무료 체험 기간이 있으며, 이 기간 동안 무료로 구독을 취소할 수 있습니다.',
  galarmEnterpriseTrialAlreadyAvailed:
    '이미 14일 무료 체험을 이용하셨습니다. 제품을 평가하는 데 시간이 더 필요한 경우 지원팀에 문의하세요.',
  signinWithGoogle: 'Google로 로그인',
  signinWithGithub: 'GitHub로 로그인',
  verifyAccount: '계정 확인',
  verifyAccountMessage:
    "인증 코드가 포함된 이메일을 받으셨을 것입니다. 아래 코드를 입력하여 계정을 인증하세요. 코드를 받지 못한 경우 '코드 다시 보내기' 버튼을 클릭하여 새 코드를 요청할 수 있습니다.",
  verificationCode: '인증 코드',
  optionalString: '{{string}}',
  quickReminderTitle: '제목(선택 사항)',
  accountVerified: '계정 확인',
  accountVerifiedMessage: '자격 증명을 사용하여 로그인하세요.',
  multipleUsersWithSameEmail:
    '동일한 이메일을 사용하는 사용자가 여러 명입니다. 지원팀에 문의하세요.',
  multipleUsersWithSameMobileNumber:
    '휴대폰 번호가 같은 사용자가 여러 명입니다. 지원팀에 문의하세요.',
  startTrial: '평가판 시작',
  nextSteps: '다음 단계',
  firstTimeSetupComplete:
    'Galarm Enterprise의 초기 설정을 완료하고 첫 번째 리스너를 만들었습니다. 다음은 여기에서 수행할 수 있는 몇 가지 다음 단계입니다.',
  testTheAlertInstruction:
    '방금 만든 리스너를 테스트하는 방법을 알아보려면 {{link}} 에서 지침을 확인하세요.',
  integrateTheAlertInstruction:
    '다양한 모니터링 도구와 리스너를 통합하려면 {{link}} 에서 지침을 확인하세요.',
  createMoreAlertsInstruction:
    "왼쪽의 '리스너' 탭으로 이동하여 더 많은 리스너를 추가할 수 있습니다.",
  addMoreMembersInstruction:
    "왼쪽의 '멤버' 탭으로 이동하여 멤버를 더 추가할 수 있습니다.",
  createTeamsInstruction:
    '인시던트가 발생하면 팀원들을 \'팀\'으로 그룹화하여 전체 팀에 알릴 수 있습니다. 왼쪽의 "팀" 탭으로 이동하여 팀을 만들고 수신자 설정에서 해당 팀을 사용하세요.',
  testTheAlert: '리스너 테스트',
  integrateTheAlert: '리스너 통합',
  createMoreAlerts: '더 많은 청취자 만들기',
  addMoreMembers: '회원 추가하기',
  createTeams: '팀 만들기',
  docs: '문서',
  contactSupport: '지원팀에 문의',
  pastDue: '기한 초과',
  canceled: '취소됨',
  unpaid: '미지급',
  incomplete: '불완전',
  incomplete_expired: '불완전 만료',
  trialing: '무료 평가판',
  paused: '일시 중지됨',
  plan: '계획',
  status: '상태',
  renewsOn: '갱신',
  yourRoles: '귀하의 역할',
  enterprisePlan: '{{quantity}} 좌석, 매번 갱신 {{interval}}',
  currentOrganization: '현재 조직',
  useThisOrganization: '이 조직 사용',
  fixPayment: '결제 수정',
  fixingPayment: '결제 수정',
  addPaymentInformation: '결제 정보 추가',
  noPaymentInformationAvailable:
    '결제 정보가 없습니다. 최근에 결제 정보를 추가한 경우 페이지를 새로고침하세요.',
  openBillingPortal: '청구 포털 열기',
  startupWizardDescription:
    '이 마법사는 첫 번째 리스너를 시작하고 생성하는 데 도움이 됩니다.',
  userNotFound: '사용자를 찾을 수 없음',
  wrongPassword: '잘못된 비밀번호',
  emailAlreadyInUse: '이미 사용 중인 이메일',
  restoreDeletedAlarms: '삭제된 알람 복원',
  deleteAlarmAlert:
    '삭제된 알람은 "최근 삭제한 알람"에서 복원할 수 있습니다. \'알람\' 탭의 오른쪽 상단에 있는 점 3개 메뉴(⋮)를 탭하면 알람을 볼 수 있습니다.\n\n삭제된 알람은 30일 동안 보관되며 그 이후에는 영구적으로 삭제됩니다.',
  slackVerificationCode: 'Slack 인증 코드는 {{verificationCode}} 입니다.',
  dndAccess: '액세스 방해 금지',
  dndHelperText:
    '휴대폰이 방해 금지 모드로 설정되어 있을 때 Galarm이 알람을 울리도록 허용하세요.',
  cantRestoreInstantAlarmMessage:
    '즉시 알람은 복구할 수 없습니다. 필요한 경우 이 알람에 대한 채팅 및 기타 정보를 볼 수 있습니다. 이 알람은 삭제 후 30일이 지나면 영구적으로 삭제됩니다.',
  upgradeToUnlockAllFeatures: '업그레이드하여 모든 기능 잠금 해제',
  otherSignupOptions: '기타 가입 옵션:',
  renewSubscription: '구독 갱신',
  subscriptionOnHold:
    '보류 중(요금제에서 {{subscriptionQty}} 좌석을 허용하지만\n을 허용하지만 {{numSubscriptionKeys}} 시트를 할당했습니다.',
  checklists: '작업',
  checklist: '작업 목록',
  typeToAddTask: '작업을 추가할 유형',
  specifyTaskName: '작업 이름 지정',
  otherChecklists: '작업 목록',
  defaultChecklistName: '제목 없음',
  addTasksToChecklist: '하단의 입력 상자를 사용하여 작업 추가',
  addTasksToDefaultChecklist:
    '하단의 입력 상자를 사용하여 작업을 추가하거나 오른쪽 상단의 + 버튼을 클릭하여 새 작업 목록을 시작합니다.',
  createNewChecklist:
    '새 작업 목록을 만들어 관련 작업을 한곳에서 추적할 수 있습니다.',
  addNewChecklist: '새 작업 목록',
  alarmPhoto: '알람 사진',
  alarmPhotoFeatureTitle: '알람 사진',
  alarmPhotoFeatureDescriptionPremiumUser:
    '이 기능을 사용하면 알람에 사진을 추가하여 약, 작업 지침, 물품 등의 사진을 빠르게 시각적 단서로 표시할 수 있습니다. 알람이 울릴 때 사진이 표시됩니다.',
  alarmPhotoFeatureDescriptionFreeUser:
    '이 기능을 사용하면 알람에 사진을 추가하여 약, 작업 지침, 물품 등의 사진을 빠르게 시각적 단서로 표시할 수 있습니다. 알람이 울릴 때 사진이 표시됩니다.\n  \n이 기능은 프리미엄 기능입니다. 이 기능을 사용하려면 프리미엄으로 업그레이드하라는 메시지가 표시됩니다.',
  alarmPhotoPremiumFeature: '알람 메모에 사진 추가',
  installPendingAppUpdate:
    '최신 앱 업데이트가 다운로드되어 설치 준비가 완료되었습니다.',
  numTasks: { zero: '작업 없음', one: '1 작업', other: '{{count}} 작업' },
  numTasksWithCompleted: {
    one: '1 작업, {{completed}} 완료',
    other: '{{count}} 작업, {{completed}} 완료'
  },
  muteAlarms: '알람 음소거',
  unmuteAlarms: '알람 음소거 해제',
  alarmsAreMuted:
    '현재 모든 알람이 음소거되어 있습니다. 음소거를 해제하려면 탭하세요.',
  alarmsHaveBeenMuted:
    '알람이 음소거되었습니다. 음소거를 해제할 때까지 알람이 울리지 않습니다.',
  alarmsHaveBeenUnmuted:
    '알람이 음소거 해제되었습니다. 구성된 대로 알람이 울립니다.',
  deleteChecklist: '작업 목록 삭제',
  confirmDeleteChecklist: '작업 목록을 삭제하시겠습니까?',
  confirmDeleteCompletedTasks: '완료된 작업을 삭제하시겠습니까?',
  confirmResetCompletedTasks: '완료된 작업을 초기화하시겠습니까?',
  confirmDeleteTask: '삭제 {{taskName}}?',
  showCompletedTasks: '완료된 작업 표시',
  hideCompletedTasks: '완료된 작업 숨기기',
  deleteCompletedTasks: '완료된 작업 삭제',
  resetCompletedTasks: '완료된 작업 초기화',
  yourGroups: '내 그룹',
  newAlarmWith: '새로운 알람 {{name}}',
  newGroupWith: '새 그룹 {{name}}',
  alertContact: '알림 {{name}}',
  alertGroup: '알림 {{name}}',
  instantAlarmMovedToFabNotice:
    "이제 이 화면의 오른쪽 하단에 있는 '+' 버튼에서 즉시 알람 기능을 사용할 수 있습니다. '확성기' 아이콘은 향후 릴리스에서 오른쪽 상단에서 제거될 예정입니다.",
  tasksIntroTitle: '작업 및 작업 목록',
  tasksIntroDescription:
    '작업은 마감 기한이 없는 할 일입니다. 여기에서 작업을 빠르게 추가하고 관리하면서 각 작업을 처리할 때마다 체크 표시할 수 있습니다.\n\n작업 목록을 만들어 비슷한 작업을 그룹화할 수도 있습니다. 예를 들어, 장보기 작업 목록, 업무 작업 목록 등을 만들 수 있습니다.',
  groupsMovedToContactsScreen:
    '"그룹"이 이 탭으로 통합되었습니다. 내 그룹은 상단의 \'내 그룹\' 아래에 표시됩니다. 오른쪽 하단의 "+" 버튼을 탭하여 새 그룹을 만들 수 있습니다.',
  loadingTasks: '작업 로드 중...',
  tryPremium: '프리미엄 체험',
  buyPremiumToUnlockFeature:
    '이 기능은 프리미엄 기능입니다. 14일 무료 체험이 제공되는 프리미엄 요금제로 이 기능을 비롯한 여러 가지 멋진 기능을 사용해 보세요.',
  newTask: '새 작업',
  newTaskList: '새 작업 목록',
  enterTaskName: '작업 이름 입력',
  enterTaskListName: '작업 목록 이름 입력',
  taskName: '작업 이름',
  taskListName: '작업 목록 이름',
  checklistTasks: '{{name}}',
  editTaskListName: '작업 목록 이름 편집',
  editTask: '작업 편집',
  releaseNotes: '릴리스 정보',
  currentRelease: '(현재 설치됨)',
  loadingReleaseNotes:
    '릴리즈 노트를 로드 중입니다. 인터넷에 연결되어 있는지 확인하세요.',
  id: 'ID',
  subscriptionData: '구독 데이터',
  organizations: '조직',
  showRotationCalendar: '회전 캘린더 표시',
  rotationCalendar: '로테이션 캘린더',
  editShift: '교대근무 편집',
  startDateForShift: '교대근무 시작 날짜',
  startTimeForShift: '교대 근무 시작 시간',
  endDateForShift: '교대근무 종료일',
  endTimeForShift: '교대근무 종료 시간',
  editingShift: '교대 근무 편집',
  addingShift: '교대근무 추가',
  products: '제품',
  prices: '요금제',
  planType: '요금제 유형',
  business: '비즈니스',
  enterprise: '엔터프라이즈',
  compare: '비교',
  apiSupport: '서비스 모니터링을 위한 API 지원',
  accessToConsoleToViewAndCloseIncidents: '인시던트 관리를 위한 대시보드',
  triggerInstantAlertsFromTheConsole: 'Enterprise 콘솔에서 인시던트 트리거',
  coreFeatures: '핵심 기능:',
  everythingInBusiness: '비즈니스 계획의 모든 것 및:',
  updatePlanInstructions:
    "오른쪽의 '청구 포털 열기' 버튼을 클릭하고 청구 포털에서 '요금제 업데이트' 버튼을 선택하면 요금제를 업데이트할 수 있습니다.",
  membersDescription:
    '{{subscriptionKeysCount}} 좌석을 구매했습니다( {{assignedSubscriptionKeysCount}} 할당됨, {{unassignedSubscriptionKeysCount}} 사용 가능). 회원의 이름 앞에 있는 체크 표시는 좌석이 배정되었음을 나타냅니다.',
  centralBilling: '중앙 청구',
  saveTwoMonths: '2개월 절약',
  owner: '소유자',
  enterpriseAccountSummary:
    '귀하는 Galarm Pro {{planType}} 플랜에 가입한 {{organizationName}} 조직의 일원입니다.',
  perYearPerUser: '사용자당/년',
  perMonthPerUser: '사용자당/월',
  creationDate: '생성 날짜',
  updatePhoneNumber: '전화 번호 변경',
  confirmCountryCodeAndEnterUpdatedPhoneNumber:
    '국가 코드를 확인하고 새 휴대폰 번호를 입력하세요. 인증이 완료되면 계정이 이 휴대폰 번호에 연결됩니다.',
  unableToUpdatePhoneNumber:
    '전화번호를 변경할 수 없습니다: {{error}}. 지원팀에 문의하세요.',
  phoneNumberUpdated: '전화번호가 성공적으로 변경되었습니다.',
  phoneNumberUpdatedMessage:
    '앱을 정상적으로 사용할 수 있습니다. 연락처에 주소록에서 전화번호를 업데이트하도록 요청합니다.',
  memberChangedPhoneNumber:
    '{{memberName}} 의 전화번호가 변경되었습니다. 계속 알람에 추가하려면 주소록을 업데이트하세요.',
  preferredSourceForIncidents: '선호하는 인시던트 소스',
  apiCodesDescription:
    '다음 안내에 따라 리스너를 소스와 통합하려면 다음과 같이 하세요.',
  rotation: '회전',
  noAlarmsForThisDay: '이 날에는 알람이 없습니다.',
  markAlarmsAsComplete: '알람을 완료로 표시',
  markAlarmsAsCompleteExplanation:
    "일회성 알람은 완료된 것으로 표시되며 '비활성' 알람 섹션에 나타납니다.\n\n반복되는 알람은 완료로 표시되고 다음 알람으로 이동합니다.",
  enterIncidentPrefix: '최대 5자',
  incidentPrefix: '인시던트 접두사',
  incidentPrefixAlreadyExists: '인시던트 접두사가 이미 존재합니다.',
  removeTask: '작업 제거?',
  incidentPrefixLengthExceeded: '인시던트 접두사가 5자를 초과하는 경우',
  criticalAlerts: '중요 알림',
  criticalAlertsConfiguration:
    '휴대폰이 음소거되어 있거나 Focus가 켜져 있어도 {{criticalAlertsVolume}}% 볼륨으로 알람이 울립니다.',
  criticalAlertsDisabledShort:
    '휴대폰이 음소거된 상태에서는 알람이 울리지 않습니다.',
  criticalAlertsConfigurationGlobal:
    '휴대폰이 음소거되어 있거나 Focus가 켜져 있어도 {{criticalAlertsVolume}}% 볼륨으로 알람이 울립니다.',
  criticalAlertsDisabledShortGlobal:
    '휴대폰이 음소거된 상태에서는 알람이 울리지 않습니다.',
  criticalAlertsEnabled:
    '중요 알림이 활성화됩니다. 휴대폰이 음소거되어 있거나 Focus가 켜져 있어도 알람이 울립니다.',
  criticalAlertsDisabled:
    '중요 알림이 비활성화됩니다. 휴대폰이 음소거된 상태에서는 알람이 울리지 않습니다.',
  enableCriticalAlerts: '중요 알림 사용',
  summer_soft: '여름 소프트',
  beeps: '삐 소리',
  bollywood_nights: '볼리우드 나이트',
  gentle_reminder: '부드러운 알림',
  himalayan_piper: '히말라야 파이퍼',
  holiday_joy: '홀리데이 조이',
  hollywood_adventure: '할리우드 어드벤처',
  melody: '멜로디',
  morning_rooster: '모닝 루스터',
  morning_sunshine: '모닝 선샤인',
  sweet_sea: '달콤한 바다',
  old_school_ring: '트링 트링',
  MyAlarmNotification: '{{alarmName}} - 이제 시간입니다.',
  ParticipantCascadingAlarmNotification:
    '{{alarmName}} - {{alarmCreatorName}} 을 상기시킬 때입니다.',
  ParticipantSimultaneousAlarmNotification:
    '{{alarmName}}. 이제 참여를 확인할 차례입니다.',
  InstantAlarmNotification: '{{alarmName}}. 즉각적인 주의가 필요합니다.',
  MyAlarmNotificationShort: '이제 시간입니다.',
  ParticipantCascadingAlarmNotificationShort:
    '{{alarmCreatorName}} 을 상기시킬 때입니다.',
  ParticipantSimultaneousAlarmNotificationShort:
    '이제 참여를 확인할 차례입니다.',
  InstantAlarmNotificationShort: '즉각적인 주의가 필요합니다.',
  MyRecipientAlarmNotification:
    '{{alarmName}} - {{alarmRecipientName}} 을 상기시킬 때입니다.',
  MyRecipientAlarmNotificationShort:
    '{{alarmRecipientName}} 을 상기시킬 때입니다.',
  RecipientAlarmNotification:
    '{{alarmName}} ( {{alarmCreatorName}}) - 시간입니다.',
  RecipientAlarmNotificationShort: '이제 시간입니다 - {{alarmCreatorName}}.',
  calendarView: '갈람 캘린더',
  noAlarmsForThisDay: '이 날의 알람이 없습니다',
  selectADate: '알람을 보려면 날짜를 선택하세요'
}
