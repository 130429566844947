import ActionTypes from '../actions/types'
import { createReducer } from 'reduxsauce'
import Utils from '../utils/Utils'

// Copied from AlarmUtils. We don't update the lastUpdated timestamp in this one. Keep in sync.
const updateAlarmParticipant = (alarm, participantId, update) => {
  if (
    alarm.backupGroup &&
    Utils.getObjectWithId(alarm.backupGroup.members, participantId)
  ) {
    const newMembers = alarm.backupGroup.members.slice()
    const member = Utils.getObjectWithId(newMembers, participantId)
    const memberIndex = Utils.getIndexOfObjectWithId(newMembers, participantId)
    const newMember = {
      ...member,
      ...update
    }
    newMembers.splice(memberIndex, 1, newMember)
    return {
      ...alarm,
      backupGroup: {
        ...alarm.backupGroup,
        members: newMembers
      }
    }
  } else if (Utils.getObjectWithId(alarm.backupContacts, participantId)) {
    const newBackupContacts = alarm.backupContacts.slice()
    const backupContact = Utils.getObjectWithId(
      newBackupContacts,
      participantId
    )
    const backupContactIndex = Utils.getIndexOfObjectWithId(
      newBackupContacts,
      participantId
    )
    const newBackupContact = {
      ...backupContact,
      ...update
    }
    newBackupContacts.splice(backupContactIndex, 1, newBackupContact)
    return {
      ...alarm,
      backupContacts: newBackupContacts
    }
  } else if (alarm.recipient && alarm.recipient.id === participantId) {
    return {
      ...alarm,
      recipient: {
        ...alarm.recipient,
        ...update
      }
    }
  } else {
    return alarm
  }
}

const INITIAL_STATE = {
  alarms: []
}

const addAlarm = (state, action) => {
  const { alarmId, alarm } = action.payload
  const alarmIndex = Utils.getIndexOfObjectWithId(state.alarms, alarmId)

  let newAlarms = state.alarms.slice()

  // New backup alarm
  if (alarmIndex === -1) {
    newAlarms.push(alarm)
  } else {
    // Check if the alarm has changed
    newAlarms.splice(alarmIndex, 1, alarm)
  }

  return {
    ...state,
    alarms: newAlarms
  }
}

const removeAlarm = (state, action) => {
  const { alarmId } = action.payload
  let alarmIndex = Utils.getIndexOfObjectWithId(state.alarms, alarmId)
  if (alarmIndex !== -1) {
    let newAlarms = state.alarms.slice()
    newAlarms.splice(alarmIndex, 1)
    return {
      ...state,
      alarms: newAlarms
    }
  } else {
    console.tron.log('Trying to delete a non-existing backup alarm')
    return state
  }
}

const removeAlarms = (state, action) => {
  const alarmIds = action.payload.alarmIds
  let newAlarms = state.alarms.slice()
  alarmIds.forEach(alarmId => {
    let alarmIndex = Utils.getIndexOfObjectWithId(newAlarms, alarmId)
    if (alarmIndex !== -1) {
      newAlarms.splice(alarmIndex, 1)
    }
  })
  return {
    ...state,
    alarms: newAlarms
  }
}

const expireAlarms = (state, action) => {
  const alarmIds = action.payload.alarmIds
  if (alarmIds.length === 0) {
    return state
  }

  // Don't update lastUpdatedAt because it will cause
  // the alarm to be synced.
  const newAlarms = state.alarms.map(alarm => {
    if (alarmIds.includes(alarm.id)) {
      return {
        ...alarm,
        lastExpiredAt: alarm.lastExpiredAt ? null : Date.now()
      }
    }
    return alarm
  })

  return {
    ...state,
    alarms: newAlarms
  }
}

const updateAlarmsLocally = (state, action) => {
  const alarmIds = action.payload.alarmIds
  if (alarmIds.length === 0) {
    return state
  }

  // This is used to move the alarms from one section to another.
  // We change a property locally that is not synced with fireabse
  const newAlarms = state.alarms.map(alarm => {
    if (alarmIds.includes(alarm.id)) {
      return {
        ...alarm,
        lastSeenAt: alarm.lastSeenAt ? null : Date.now()
      }
    }
    return alarm
  })

  return {
    ...state,
    alarms: newAlarms
  }
}

const unsnoozeAlarmsLocally = (state, action) => {
  const alarmIds = action.payload.alarmIds
  const uid = action.payload.uid
  if (alarmIds.length === 0) {
    return state
  }

  const newAlarms = state.alarms.map(alarm => {
    if (alarmIds.includes(alarm.id)) {
      return updateAlarmParticipant(alarm, uid, {
        lastSnoozeOn: null,
        lastSnoozeFor: null
      })
    }
    return alarm
  })

  return {
    ...state,
    alarms: newAlarms
  }
}

// Soft updates don't change the persistedOnline flag such that they are not
// colored grey in the app
const softUpdateLocalAlarm = (state, action) => {
  const alarm = action.payload.alarm
  const alarmIndex = Utils.getIndexOfObjectWithId(state.alarms, alarm.id)
  if (alarmIndex !== -1) {
    let newAlarms = state.alarms.slice()
    newAlarms.splice(alarmIndex, 1, alarm)
    return {
      ...state,
      alarms: newAlarms
    }
  } else {
    console.tron.log('Trying to edit a non-existent alarm')
    return state
  }
}

const ACTION_HANDLERS = {
  [ActionTypes.ADD_BACKUP_ALARM]: addAlarm,
  [ActionTypes.REMOVE_BACKUP_ALARM]: removeAlarm,
  [ActionTypes.REMOVE_PARTICIPANT_ALARMS]: removeAlarms,
  [ActionTypes.EXPIRE_PARTICIPANT_ALARMS]: expireAlarms,
  [ActionTypes.UPDATE_PARTICIPANT_ALARMS_LOCALLY]: updateAlarmsLocally,
  [ActionTypes.UNSNOOZE_PARTICIPANT_ALARMS_LOCALLY]: unsnoozeAlarmsLocally,
  [ActionTypes.SOFT_UPDATE_LOCAL_PARTICIPANT_ALARM]: softUpdateLocalAlarm
}

export default createReducer(INITIAL_STATE, ACTION_HANDLERS)
