import { Platform } from 'react-native'
import Constants from './Constants'
import GlobalConfig from './GlobalConfig'

export const colors = {
  white: 'white',
  transparent: 'transparent',
  black: 'black',
  lightBlack: '#212121',
  mediumBlack: '#151515',
  lightGrey: '#f7f7f7',
  grey: '#bdbec2',
  darkGrey: '#8e8e93',
  veryDarkGrey: '#424242',
  veryDarkBlueGrey: '#37474f',
  lightYellow: '#fffde7',
  lightRed: '#FFEBEE',
  lightBlue: '#e3f2fd',
  mediumBlue: '#BBDEFB',
  darkBlue: '#006db3',
  lightBlueGrey: '#ECEFF1',
  transluscentWhite: '#bdbec288',
  transluscentBlack: '#00000088',
  indigo: '#5e5ce6',
  lightTeal: '#d5edec',
  darkOrange: '#ff702b',
  deepPurple: '#673ab7',
  green50: '#2E7D32',
  ...Platform.select({
    ios: {
      green: '#4cd964',
      red: '#ff3b30',
      redLightMode: '#ff3b30',
      redDarkMode: '#ff3b30',
      yellow: '#ffcc00',
      orange: '#ff9500',
      blue: '#007aff',
      transluscentBlue: '#007aff44',
      transluscentRed: '#ff3b30544',
      lightGreen: '#B2DFDB'
    },
    android: {
      green: '#00897B',
      red: '#E53935',
      redLightMode: '#E53935',
      redDarkMode: '#F44336',
      yellow: '#FDD835',
      orange: '#ff9800',
      blue: '#039BE5',
      transluscentBlue: '#039BE544',
      transluscentRed: '#E5393544',
      lightGreen: '#B2DFDB'
    },
    web: {
      green: '#00897B',
      red: '#E53935',
      redLightMode: '#E53935',
      redDarkMode: '#F44336',
      yellow: '#FDD835',
      orange: '#ff9800',
      blue: '#039BE5',
      transluscentBlue: '#039BE544',
      transluscentRed: '#E5393544',
      lightGreen: '#B2DFDB'
    }
  })
}

const colorThemes = (function () {
  const themes = {
    light: {
      lightTint: colors.lightGrey,
      mediumTint: colors.grey,
      darkTint: colors.darkGrey,
      lightBlueGreyTint: colors.lightBlueGrey,
      transluscentBackground: colors.transluscentBlack,
      transluscentText: colors.transluscentWhite,
      textColor: colors.lightBlack,
      screenBackgroundColor: colors.white,
      textBackgroundColor: colors.white,
      listItemSelectedColor: colors.lightYellow,
      formFieldIcon: colors.grey,
      primary: colors.blue,
      primaryBusiness: colors.green50,
      primaryEnterprise: colors.deepPurple,
      lightPrimary: colors.lightBlue,
      tileHeader: colors.mediumBlue,
      transluscentPrimary: colors.transluscentBlue,
      tabBarBackgroundColor: colors.lightGrey,
      tabBarButtonColor: colors.darkGrey,
      tabBarSelectedButtonColor: colors.blue,
      navBarBackgroundColor: colors.blue,
      navBarTextColor: colors.white,
      navBarButtonColor: colors.white,
      navBarSubtitleTextColor: colors.lightGrey,
      darkPrimary: colors.darkBlue,
      tileShadowColor: colors.darkGrey,
      outlineButtonBackgroundColor: colors.lightGrey,
      warningTileBackgroundColor: colors.lightRed,
      warningTileTextColor: colors.red,
      warningTextColor: colors.redLightMode,
      statusBarColor: colors.darkBlue,
      fabBackground: colors.transluscentBlack,
      enabledSwitchTrackColor: colors.lightGreen,
      alarmCategoryColors: [
        { code: '#F8BBD0', id: 'pink' },
        { code: '#BBDEFB', id: 'blue' },
        { code: '#D1C4E9', id: 'deepPurple' },
        { code: '#D7CCC8', id: 'brown' },
        { code: '#C8E6C9', id: 'green' },
        { code: '#FFE0B2', id: 'orange' },
        { code: '#B2EBF2', id: 'cyan' },
        { code: '#C5CAE9', id: 'indigo' },
        { code: '#F0F4C3', id: 'lime' },
        { code: '#B2DFDB', id: 'teal' },
        { code: '#FF5349', id: 'bloodOrange' },
        { code: '#ECEFF1', id: 'uncategorized' }
      ],
      shadowColor: colors.grey,
      tilePositiveActionBackgroundColor: colors.lightTeal,
      tilePositiveActionTextColor: colors.veryDarkBlueGrey,
      priceTextColor: '#00897B'
    },

    dark: {
      lightTint: colors.veryDarkGrey,
      mediumTint: colors.darkGrey,
      darkTint: colors.grey,
      lightBlueGreyTint: colors.veryDarkBlueGrey,
      transluscentBackground: colors.transluscentBlack,
      transluscentText: colors.transluscentWhite,
      textColor: colors.lightGrey,
      screenBackgroundColor: colors.black,
      textBackgroundColor: colors.lightBlack,
      listItemSelectedColor: colors.transluscentWhite,
      formFieldIcon: colors.grey,
      primary: colors.blue,
      lightPrimary: colors.veryDarkGrey,
      tileHeader: colors.darkBlue,
      transluscentPrimary: colors.transluscentBlue,
      tabBarBackgroundColor: colors.mediumBlack,
      tabBarButtonColor: colors.darkGrey,
      tabBarSelectedButtonColor: colors.blue,
      navBarBackgroundColor: colors.mediumBlack,
      navBarTextColor: colors.lightGrey,
      navBarSubtitleTextColor: colors.grey,
      navBarButtonColor: colors.lightGrey,
      darkPrimary: colors.darkBlue,
      tileShadowColor: colors.veryDarkGrey,
      outlineButtonBackgroundColor: colors.lightGrey,
      warningTileBackgroundColor: colors.veryDarkGrey,
      warningTileTextColor: colors.red,
      warningTextColor: colors.redDarkMode,
      statusBarColor: colors.black,
      fabBackground: colors.transluscentWhite,
      enabledSwitchTrackColor: colors.lightTint,
      alarmCategoryColors: [
        { code: '#C2185B', id: 'pink' },
        { code: '#0277BD', id: 'blue' },
        { code: '#7E57C2', id: 'deepPurple' },
        { code: '#8D6E63', id: 'brown' },
        { code: '#2E7D32', id: 'green' },
        { code: '#E64A19', id: 'orange' },
        { code: '#00838F', id: 'cyan' },
        { code: '#5C6BC0', id: 'indigo' },
        { code: '#9E9D24', id: 'lime' },
        { code: '#00695C', id: 'teal' },
        { code: '#FF5349', id: 'bloodOrange' },
        { code: '#455A64', id: 'uncategorized' }
      ],
      shadowColor: colors.veryDarkGrey,
      tilePositiveActionBackgroundColor: colors.veryDarkBlueGrey,
      tilePositiveActionTextColor: colors.lightGrey,
      priceTextColor: '#B2DFDB'
    }
  }

  const getColorTheme = colorScheme => {
    // Based on platform get the color scheme
    if (!colorScheme) {
      if (Platform.OS === 'web') {
        colorScheme = GlobalConfig.webColorScheme
      } else colorScheme = GlobalConfig.colorScheme
    }
    if (colorScheme === Constants.COLOR_SCHEMES.AUTO_DARK) {
      colorScheme = Constants.COLOR_SCHEMES.DARK
    } else if (colorScheme === Constants.COLOR_SCHEMES.AUTO_LIGHT) {
      colorScheme = Constants.COLOR_SCHEMES.LIGHT
    }
    const colorTheme = themes[colorScheme]
    return colorTheme
  }

  return {
    Blue: themes.light,
    getColorTheme,
    colors
  }
})()

export default colorThemes
