import { Text } from 'grommet'
import React from 'react'

const SecondaryText = ({ children, ...props }) => {
  return (
    <Text {...props} size="small" color="darkTint">
      {children}
    </Text>
  )
}

export default SecondaryText
