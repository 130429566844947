import { colorThemes, I18n } from 'galarm-config'
import { Box, Heading, Image } from 'grommet'
import React from 'react'
import { useSelector } from 'react-redux'

const GalarmWebHeader = () => {
  const colorScheme = useSelector(state => state.userSettings.webColorScheme)

  return (
    <Box
      flex={{ shrink: 0 }}
      align="center"
      pad="small"
      height="80px"
      direction="row"
      gap="xsmall"
      background={colorThemes.getColorTheme(colorScheme).lightPrimary}>
      <Image
        src={require('galarm-res/img/web/galarm-logo.svg').default}
        height="60px"
      />
      <Heading level={3}>{I18n.t('galarmWeb')}</Heading>
    </Box>
  )
}

export default GalarmWebHeader
