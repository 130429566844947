import React from 'react'
import { Box } from 'grommet'
import SelectContacts from './SelectContacts'

const ConfigureAlarmRecipient = ({ recipient, onSelectRecipient }) => {
  const selectedContacts = recipient ? [recipient] : []
  return (
    <Box flex direction="row">
      <Box width={'500px'} margin={{ top: 'medium' }}>
        <SelectContacts
          selectedContacts={selectedContacts}
          onSelectContact={onSelectRecipient}
        />
      </Box>
    </Box>
  )
}

export default ConfigureAlarmRecipient
