import React from 'react'
import { Text, Box } from 'grommet'
import { isSameDay, isSameUser, getUserColor } from './utils'

const MessageText = props => {
  const renderUsername = () => {
    if (props.renderUsername) {
      const { ...usernameProps } = props
      return props.renderUsername(usernameProps)
    }
    return (
      <Text
        style={{
          color: getUserColor(props.currentMessage.user.name),
          marginLeft: 5,
          marginRight: 5,
          marginTop: 5,
          backgroundColor: 'transparent',
          fontSize: 16,
          fontWeight: '700'
        }}>
        {props.currentMessage.user.name}
      </Text>
    )
  }
  return (
    <Box>
      {(!isSameUser(props.currentMessage, props.previousMessage) ||
        !isSameDay(props.currentMessage, props.previousMessage)) &&
      props.renderUsername !== null &&
      props.position === 'left'
        ? renderUsername()
        : null}
      {props.renderParsedMessageText(props)}
    </Box>
  )
}
export default MessageText

MessageText.defaultProps = {
  position: 'left',
  currentMessage: {
    text: ''
  },
  messageTextContainerStyle: {},
  textStyle: {},
  linkStyle: {},
  usernameStyle: {},
  textMessageProps: {}
}
