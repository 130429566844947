import {
  ActionCreators,
  AlarmUtils,
  DateTimeUtils,
  Selectors
} from 'galarm-shared'
import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Header,
  Image,
  Layer,
  Menu,
  Text,
  Anchor
} from 'grommet'
import { Checkmark, Close, MoreVertical } from 'grommet-icons'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import LabelledText from './LabelledText'
import isEmpty from 'lodash/isEmpty'
import { Constants, GlobalConfig, I18n, colorThemes } from 'galarm-config'
import LabelText from './LabelText'
import ParticipantsListWithAvatar from './ParticipantsListWithAvatar'
import {
  FirebaseProxy,
  NavigationUtils,
  NotificationManager
} from 'galarm-ps-api'
import AppCanvas from './AppCanvas'
import AlarmType from './AlarmType'
import CategoryLabel from './CategoryLabel'
import ChatIcon from './ChatIcon'
import objGet from 'lodash/get'
import SetAlarmCategory from './SetAlarmCategory'
import ShareAlarm from './ShareAlarm'
import RichTextEditor from './RichTextEditor'
import objOmit from 'lodash/omit'
import EntityNotFound from './EntityNotFound'
import AlarmFollowers from './AlarmFollowers'
import ColoredButtonWithBorder from './ColoredButtonWithBorder'

const {
  makeAlarmCategoryForAlarmIdSelector,
  makeOwnAlarmDetailsSelector,
  makeSubscribedAlarmSystemAlertsSelector,
  upgradePurchasedSelector
} = Selectors

const AlarmDetailsHeader = ({ alarm }) => {
  const dispatch = useDispatch()
  const upgradePurchased = useSelector(upgradePurchasedSelector)
  const shareableAlarmLinksCount = useSelector(
    state => state.userInfo.shareableAlarmLinksCount
  )

  const [showAlarmCategoryPicker, setShowAlarmCategoryPicker] = useState(false)
  const [showShareAlarmDialog, setShowShareAlarmDialog] = useState(false)

  const displayAlarmCategoryPicker = () => setShowAlarmCategoryPicker(true)
  const hideAlarmCategoryPicker = () => setShowAlarmCategoryPicker(false)

  const displayShareAlarmDialog = () => {
    const totalShareableLinks = Object.values(shareableAlarmLinksCount).reduce(
      (acc, currVal) => acc + currVal,
      0
    )
    if (
      totalShareableLinks >= GlobalConfig.maxAllowedShareableAlarmLinks &&
      !upgradePurchased
    ) {
      NavigationUtils.showGalarmPremiumScreen(
        'shareableAlarmLinksPremiumFeature'
      )
      return
    }

    setShowShareAlarmDialog(true)
  }
  const hideShareAlarmDialog = () => setShowShareAlarmDialog(false)

  const alarmCategorySelector = makeAlarmCategoryForAlarmIdSelector()
  const alarmCategory = useSelector(state =>
    alarmCategorySelector(state, { alarmId: alarm.id })
  )

  if (isEmpty(alarm)) {
    return null
  }

  const deleteAlarm = () => {
    NavigationUtils.showAlert(
      I18n.t('deleteAlarm'),
      '',
      [
        {
          text: I18n.t('no')
        },
        {
          text: I18n.t('yes'),
          onPress: deleteAlarmCore
        }
      ],
      false
    )
  }

  const closeAlarmDetailsScreen = () => {
    dispatch(ActionCreators.hideAlarmDetailsScreen())
  }

  const deleteAlarmCore = () => {
    // Send a notification to the mobile device.
    const notificationInfo = {
      type: Constants.NotificationTypes.ALARM_DELETED,
      alarmId: alarm.id,
      alarmType: alarm.type,
      alarmName: alarm.name,
      alarmDate: alarm.date.toString()
    }
    NotificationManager.sendRemoteNotification(
      GlobalConfig.uid,
      Constants.NotificationKeys.AlarmDeletedFromWebNotification,
      notificationInfo
    )

    dispatch(ActionCreators.deleteAlarm(alarm.id))
    dispatch(ActionCreators.hideAlarmDetailsScreen())
  }

  const onEditAlarmCore = () => {
    dispatch(ActionCreators.showEditAlarmWizard(alarm.id))
  }

  const onEditAlarm = () => {
    // Below code is copied to AlarmUtilsWithExtras. Keep in sync
    if (
      alarm.link &&
      alarm.link.subscribers &&
      Object.keys(alarm.link.subscribers).length > 0
    ) {
      NavigationUtils.showAlert(
        I18n.t('sharedAlarm'),
        I18n.t('editingPublishedAlarm', {
          count: Object.keys(alarm.link.subscribers).length
        }),
        [
          {
            text: I18n.t('cancel')
          },
          {
            text: I18n.t('continue'),
            onPress: onEditAlarmCore
          }
        ]
      )
      return
    } else if (alarm.parentLink) {
      NavigationUtils.showAlert(
        I18n.t('sharedAlarm'),
        I18n.t('editingSubscribedAlarm', {
          creatorName: alarm.parentLink.creatorName
        }),
        [
          {
            text: I18n.t('cancel')
          },
          {
            text: I18n.t('continue'),
            onPress: onEditAlarmCore
          }
        ]
      )
      return
    } else {
      onEditAlarmCore()
    }
  }

  const onReportAProblem = () => {
    GlobalConfig.showFeedbackScreen({
      type: Constants.TICKET_TYPES.PROBLEM_REPORT,
      extras: JSON.stringify({
        alarm: objOmit(alarm, [
          'name',
          'notes',
          'backupGroup',
          'backupContacts'
        ])
      })
    })
  }

  const duplicateAlarm = () => {
    if (upgradePurchased || alarm.type === Constants.AlarmTypes.CASCADING) {
      dispatch(
        ActionCreators.showDuplicateAlarmWizard({
          baseAlarm: alarm,
          alarmCategoryId: alarmCategory.id
        })
      )
    } else {
      NavigationUtils.showGalarmPremiumScreen('duplicateAlarmPremiumFeature')
    }
    FirebaseProxy.logEvent(Constants.UserAnalyticsEvents.DUPLICATE_ALARM, {})
  }

  const onViewPreviousOccurrences = () => {
    dispatch(ActionCreators.showAlarmHistoryScreen(alarm))
  }

  const menuOptions = [
    {
      label: I18n.t('duplicateAlarm'),
      onClick: duplicateAlarm
    },
    {
      label: I18n.t('deleteAlarmQuickAction'),
      onClick: deleteAlarm
    },
    {
      label:
        alarmCategory.id === Constants.UNCATEGORIZED_ALARM_CATEGORY_ID
          ? I18n.t('setAlarmCategory')
          : I18n.t('changeAlarmCategory'),
      onClick: displayAlarmCategoryPicker
    }
  ]

  if (alarm.repeatType) {
    menuOptions.push({
      label: I18n.t('viewAlarmHistory'),
      onClick: onViewPreviousOccurrences
    })
  }

  menuOptions.push(
    ...[
      {
        label: I18n.t('shareAlarm'),
        onClick: displayShareAlarmDialog
      },
      {
        label: I18n.t('reportAProblem'),
        onClick: onReportAProblem
      }
    ]
  )

  return (
    <Header className="header" background="brand" pad="small">
      <Text color="white">{I18n.t('alarmDetails')}</Text>
      <Box direction="row">
        <Button
          plain
          margin="small"
          color="white"
          label={I18n.t('editAlarm')}
          onClick={onEditAlarm}
        />
        <Button icon={<Close />} onClick={closeAlarmDetailsScreen} />
        <Menu
          id="alarm-details-menu"
          dropBackground="lightTint"
          icon={<MoreVertical />}
          items={menuOptions}
        />
      </Box>
      {showAlarmCategoryPicker && (
        <Layer background="lightTint">
          <Box margin="medium">
            <SetAlarmCategory
              onClose={hideAlarmCategoryPicker}
              selectedAlarmCategory={alarmCategory}
              alarmId={alarm.id}
            />
          </Box>
        </Layer>
      )}
      {showShareAlarmDialog && (
        <Layer background="lightTint">
          <Box margin="medium">
            <ShareAlarm onClose={hideShareAlarmDialog} alarm={alarm} />
          </Box>
        </Layer>
      )}
    </Header>
  )
}

const AlarmDetails = ({ alarmId }) => {
  console.log('alarmId', alarmId)

  const dispatch = useDispatch()
  const colorScheme = useSelector(state => state.userSettings.webColorScheme)

  const [showAlarmFollowers, setShowAlarmFollowers] = useState(false)
  const displayAlarmFollowers = () => {
    setShowAlarmFollowers(true)

    FirebaseProxy.logEvent(
      Constants.UserAnalyticsEvents.VIEW_ALARM_FOLLOWERS,
      {}
    )
  }
  const hideAlarmFollowers = () => setShowAlarmFollowers(false)

  const alarmDetaisSelector = makeOwnAlarmDetailsSelector()
  const alarm = useSelector(state =>
    alarmDetaisSelector(state, { alarmId: alarmId })
  )

  const alarmCategorySelector = makeAlarmCategoryForAlarmIdSelector()
  const alarmCategory = useSelector(state =>
    alarmCategorySelector(state, { alarmId: alarmId })
  )

  const subscribedAlarmSystemAlertsSelector =
    makeSubscribedAlarmSystemAlertsSelector()
  const subscribedAlarmSystemAlerts = useSelector(state =>
    subscribedAlarmSystemAlertsSelector(state, { alarmId: alarm.id })
  )
  const subscribedAlarmDeletedSystemAlert = subscribedAlarmSystemAlerts.find(
    alert => alert.type === Constants.AlertTypes.SUBSCRIBED_ALARM_DELETED
  )
  const subscribedAlarmEditedSystemAlert = subscribedAlarmSystemAlerts.find(
    alert => alert.type === Constants.AlertTypes.SUBSCRIBED_ALARM_EDITED
  )

  const alarmRepetitionString = AlarmUtils.createAlarmRepetitionString(
    alarm.repeatType,
    alarm.repeat
  )

  const alarmCategoryColor = AlarmUtils.getColorForAlarmCategory(
    colorScheme,
    alarmCategory.color
  )

  const alarmConversations = useSelector(state => state.conversations.alarms)

  const unseenMessagesForAlarm = objGet(
    alarmConversations,
    `[${alarmId}].unseenMessages`,
    0
  )

  const deviceOs = useSelector(state => state.userInfo.deviceOs)

  const currAlarmDate = AlarmUtils.getCurrentDateForAlarm(alarm)
  const alarmEffectiveDate = DateTimeUtils.getMyAlarmDate(currAlarmDate)

  const alarmAcknowledgement =
    AlarmUtils.getAlarmAcknowledgementStatusForOccurrence(
      alarm.id,
      currAlarmDate
    )

  const creator = {
    id: alarm.creator,
    name: alarm.creatorName,
    mobileNumber: alarm.creatorMobileNumber,
    responseStatus: Constants.ACCEPT_ALARM,
    isCreator: true
  }

  const myAlarmDate = DateTimeUtils.getMyAlarmDate(alarmEffectiveDate)

  const participants = AlarmUtils.getAlarmParticipants(
    alarm.type,
    alarm.backupGroup,
    alarm.backupContacts,
    alarm.recipient
  )

  const numParticipants =
    participants.filter(
      participant => participant.state !== Constants.ParticipantStates.INACTIVE
    ).length || 0

  const alarmInfo = {
    alarmExpired: alarm.alarmExpired,
    alarmAcknowledgement: alarmAcknowledgement,
    alarmType: alarm.type,
    myAlarmDate: myAlarmDate,
    creator: alarm.creator,
    recipient: alarm.recipient,
    numParticipants: numParticipants,
    alarmStatus: alarm.status,
    creationMode: alarm.creationMode,
    alarmAcknowledgements: alarm.alarmAcknowledgements
  }

  const footerText = AlarmUtils.getAlarmDetailsFooterText(alarmInfo)
  const footerLinkText = AlarmUtils.getAlarmDetailsFooterLink(alarmInfo)

  const markAlarmAsAcknowledged = () => {
    dispatch(ActionCreators.markAlarmAsAcknowledged(alarmId))
    const notificationInfo = {
      type: Constants.NotificationTypes.ALARM_ACKNOWLEDGED,
      alarmId: alarm.id,
      alarmType: alarm.type,
      alarmName: alarm.name
    }
    NotificationManager.sendRemoteNotification(
      GlobalConfig.uid,
      Constants.NotificationKeys.AlarmMarkedDoneFromWebNotification,
      notificationInfo
    )
  }

  const markAlarmAsUnacknowledged = () => {
    dispatch(ActionCreators.markPersonalAlarmAsUndone(alarmId))
  }

  const skipPersonalAlarm = () => {
    dispatch(ActionCreators.skipPersonalAlarm(alarmId))
    const notificationInfo = {
      type: Constants.NotificationTypes.ALARM_ACKNOWLEDGED,
      alarmId: alarm.id,
      alarmType: alarm.type,
      alarmName: alarm.name
    }
    NotificationManager.sendRemoteNotification(
      GlobalConfig.uid,
      Constants.NotificationKeys.AlarmSkippedFromWebNotification,
      notificationInfo
    )
  }

  const markAlarmAsUnskipped = () => {
    dispatch(ActionCreators.markPersonalAlarmAsUnskipped(alarmId))
  }

  const markRecipientCreatorAlarmAsUnskipped = () => {
    dispatch(ActionCreators.markRecipientCreatorAlarmAsUnskipped(alarmId))
  }

  const skipRecipientCreatorAlarm = () => {
    dispatch(ActionCreators.skipRecipientCreatorAlarm(alarmId))
  }

  const onIAmUp = () => {
    if (
      alarmAcknowledgement &&
      alarmAcknowledgement.response === Constants.PERSONAL_ALARM_DONE
    ) {
      const durationString = DateTimeUtils.getShortDurationTillDateAsString(
        alarmAcknowledgement.timestamp
      )
      NavigationUtils.showAlert(
        I18n.t('alarmDone'),
        I18n.t('markAlarmUndone', { durationString }),
        [
          {
            text: I18n.t('no')
          },
          {
            text: I18n.t('yes'),
            onPress: () => {
              markAlarmAsUnacknowledged()
            }
          }
        ]
      )
      return
    }

    const alarmExpired = AlarmUtils.hasAlarmExpired(alarmId)
    if (!alarmExpired) {
      const dateTimeString = DateTimeUtils.getDateTimeAsString(currAlarmDate)
      NavigationUtils.showAlert(
        I18n.t('markAlarmDoneInAdvance'),
        alarm.repeatType
          ? I18n.t('markAlarmDoneInAdvanceMessageRepeatingAlarm', {
              dateTimeString
            })
          : I18n.t('markAlarmDoneInAdvanceMessageOneTimeAlarm', {
              dateTimeString
            }),
        [
          {
            text: I18n.t('cancel')
          },
          {
            text: I18n.t('ok'),
            onPress: markAlarmAsAcknowledged
          }
        ]
      )
      return
    }
    markAlarmAsAcknowledged()
  }

  const onSkipPersonalAlarm = () => {
    if (
      alarmAcknowledgement &&
      alarmAcknowledgement.response === Constants.PERSONAL_ALARM_SKIP
    ) {
      const durationString = DateTimeUtils.getShortDurationTillDateAsString(
        alarmAcknowledgement.timestamp
      )
      NavigationUtils.showAlert(
        I18n.t('alarmSkipped'),
        I18n.t('markAlarmUnskipped', { durationString }),
        [
          {
            text: I18n.t('no')
          },
          {
            text: I18n.t('yes'),
            onPress: markAlarmAsUnskipped
          }
        ]
      )
      return
    }

    const alarmExpired = AlarmUtils.hasAlarmExpired(alarmId)
    if (!alarmExpired) {
      const dateTimeString = DateTimeUtils.getDateTimeAsString(currAlarmDate)
      NavigationUtils.showAlert(
        I18n.t('skipAlarmInAdvance'),
        alarm.repeatType
          ? I18n.t('skipAlarmInAdvanceMessageRepeatingAlarm', {
              dateTimeString
            })
          : I18n.t('skipAlarmInAdvanceMessageOneTimeAlarm', { dateTimeString }),
        [
          {
            text: I18n.t('cancel')
          },
          {
            text: I18n.t('ok'),
            onPress: skipPersonalAlarm
          }
        ]
      )
      return
    }
    skipPersonalAlarm()
  }

  const onSkipRecipientCreatorAlarm = () => {
    if (
      alarmAcknowledgement &&
      alarmAcknowledgement.response === Constants.RECIPIENT_CREATOR_ALARM_SKIP
    ) {
      const durationString = DateTimeUtils.getShortDurationTillDateAsString(
        alarmAcknowledgement.timestamp
      )
      NavigationUtils.showAlert(
        I18n.t('alarmSkipped'),
        I18n.t('markAlarmUnskipped', { durationString }),
        [
          {
            text: I18n.t('no')
          },
          {
            text: I18n.t('yes'),
            onPress: markRecipientCreatorAlarmAsUnskipped
          }
        ]
      )
      return
    }

    const alarmExpired = AlarmUtils.hasAlarmExpired(alarmId)
    if (!alarmExpired) {
      const dateTimeString = DateTimeUtils.getDateTimeAsString(currAlarmDate)
      NavigationUtils.showAlert(
        I18n.t('skipAlarmInAdvance'),
        alarm.repeatType
          ? I18n.t('skipAlarmInAdvanceMessageRepeatingAlarm', {
              dateTimeString
            })
          : I18n.t('skipAlarmInAdvanceMessageOneTimeAlarm', { dateTimeString }),
        [
          {
            text: I18n.t('cancel')
          },
          {
            text: I18n.t('ok'),
            onPress: skipRecipientCreatorAlarm
          }
        ]
      )
      return
    }
    skipRecipientCreatorAlarm()
  }

  const onGroupAlarmYes = () => {
    if (
      alarmAcknowledgement &&
      alarmAcknowledgement.response === Constants.GROUP_ALARM_YES
    ) {
      NavigationUtils.showAlert(I18n.t('alarmAlreadyConfirmed'))
      return
    }

    const alarmExpired = AlarmUtils.hasAlarmExpired(alarmId)
    if (!alarmExpired && !alarmAcknowledgement) {
      NavigationUtils.showAlert(
        alarm.name,
        I18n.t('stillRingGroupAlarmAfterConfirmingInAdvance'),
        [
          {
            text: I18n.t('yes'),
            onPress: () => {
              onGroupAlarmYesCore(false)
              FirebaseProxy.logEvent(
                Constants.UserAnalyticsEvents.CONFIRM_GROUP_ALARM_IN_ADVANCE,
                {
                  [Constants.UserAnalyticsEventParameters
                    .RING_GROUP_ALARM_AFTER_CONFIRMING_ADVANCE]: 'true'
                }
              )
            }
          },
          {
            text: I18n.t('no'),
            onPress: () => {
              onGroupAlarmYesCore(true)
              FirebaseProxy.logEvent(
                Constants.UserAnalyticsEvents.CONFIRM_GROUP_ALARM_IN_ADVANCE,
                {
                  [Constants.UserAnalyticsEventParameters
                    .RING_GROUP_ALARM_AFTER_CONFIRMING_ADVANCE]: 'false'
                }
              )
            }
          }
        ]
      )
      return
    }

    onGroupAlarmYesCore(true)
  }

  const onGroupAlarmYesCore = (rescheduleAlarm = true) => {
    dispatch(
      ActionCreators.markCreatorResponseForSimultaneousAlarm(
        alarmId,
        Constants.GROUP_ALARM_YES,
        rescheduleAlarm
      )
    )
    const notificationInfo = {
      type: Constants.NotificationTypes.ALARM_ACKNOWLEDGED,
      alarmId: alarm.id,
      alarmType: alarm.type,
      alarmName: alarm.name
    }
    NotificationManager.sendRemoteNotification(
      GlobalConfig.uid,
      Constants.NotificationKeys.AlarmConfirmedFromWebNotification,
      notificationInfo
    )
  }

  const onGroupAlarmNo = () => {
    if (
      alarmAcknowledgement &&
      alarmAcknowledgement.response === Constants.GROUP_ALARM_NO
    ) {
      NavigationUtils.showAlert(I18n.t('alarmAlreadyDeclined'))
      return
    }

    onGroupAlarmNoCore()
  }

  const onGroupAlarmNoCore = () => {
    dispatch(
      ActionCreators.markCreatorResponseForSimultaneousAlarm(
        alarmId,
        Constants.GROUP_ALARM_NO,
        true
      )
    )
    const notificationInfo = {
      type: Constants.NotificationTypes.ALARM_ACKNOWLEDGED,
      alarmId: alarm.id,
      alarmType: alarm.type,
      alarmName: alarm.name
    }
    NotificationManager.sendRemoteNotification(
      GlobalConfig.uid,
      Constants.NotificationKeys.AlarmDeclinedFromWebNotification,
      notificationInfo
    )
  }

  if (isEmpty(alarm)) {
    return (
      <EntityNotFound
        entityType={I18n.t('entityAlarm')}
        onClose={() => dispatch(ActionCreators.hideAlarmDetailsScreen())}
      />
    )
  }

  const onViewConversation = () => {
    dispatch(ActionCreators.showAlarmConversationScreen(alarm.id))
  }

  const updateSubscribedAlarm = () => {
    dispatch(ActionCreators.updateSubscriberAlarm(alarm.id))
  }

  const deleteSubscribedAlarm = () => {
    dispatch(ActionCreators.deleteSubscriberAlarm(alarm.id))
  }

  const ignoreSubscribedAlarmSystemAlerts = () => {
    dispatch(ActionCreators.removeSubscribedAlarmSystemAlerts(alarm.id))
  }

  const viewConversation = (
    <Box align="center" pad="small" onClick={onViewConversation}>
      <ChatIcon unseenMessages={unseenMessagesForAlarm} />
      <LabelText color="primary">{I18n.t('chat')}</LabelText>
    </Box>
  )

  const alarmParticipants = AlarmUtils.getAlarmParticipants(
    alarm.type,
    alarm.backupGroup,
    alarm.backupContacts,
    alarm.recipient
  ).filter(
    participant => participant.state !== Constants.ParticipantStates.INACTIVE
  )

  const numAlarmParticipants = alarmParticipants.length

  const endDateString = alarm.endDate
    ? DateTimeUtils.getDateAsString(alarm.endDate)
    : I18n.t('noEndDate')

  let buddyAlarmTimeStringWithTimezone
  if (alarm.type === Constants.AlarmTypes.RECIPIENT) {
    const participantAlarmDate = DateTimeUtils.getParticipantAlarmDate({
      date: alarmEffectiveDate,
      type: alarm.type,
      cascadingAlarmInterval: alarm.cascadingAlarmInterval,
      recipientAlarmInterval: alarm.recipientAlarmInterval
    })

    buddyAlarmTimeStringWithTimezone =
      alarm.timezoneSetting === Constants.TIMEZONE_SETTINGS.EXPLICIT
        ? DateTimeUtils.getLocalizedTimeAsStringWithTimezone(
            participantAlarmDate,
            alarm.creatorTimezone
          )
        : DateTimeUtils.getLocalizedTimeAsString(participantAlarmDate)
  }

  return (
    <AppCanvas key={alarmId} overflow="auto">
      <AlarmDetailsHeader alarm={alarm} />
      <Card
        margin="small"
        background="textBackgroundColor"
        width="600px"
        flex={{ shrink: 0 }}>
        {alarm.parentLink && (
          <Box>
            <Box background="tileHeader" pad="small" align="center">
              <Text color="textColor">
                {I18n.t('youHaveSubscribedToThisAlarm', {
                  creatorName: alarm.parentLink.creatorName
                })}
              </Text>
            </Box>
            {(subscribedAlarmDeletedSystemAlert ||
              subscribedAlarmEditedSystemAlert) && (
              <Box
                justifyContent="center"
                align="center"
                background="warningTileBackgroundColor"
                pad="small">
                <Text textAlign="center" color="warningTileTextColor">
                  {subscribedAlarmDeletedSystemAlert
                    ? I18n.t('subscribedAlarmDeletedInAlarmDetails', {
                        creatorName: alarm.parentLink.creatorName
                      })
                    : I18n.t('subscribedAlarmEditedInAlarmDetails', {
                        creatorName: alarm.parentLink.creatorName
                      })}
                </Text>
                <Box direction="row" margin="small" gap="medium">
                  <Button
                    onClick={
                      subscribedAlarmDeletedSystemAlert
                        ? deleteSubscribedAlarm
                        : updateSubscribedAlarm
                    }
                    label={
                      subscribedAlarmDeletedSystemAlert
                        ? I18n.t('deleteSubscribedAlarm')
                        : I18n.t('updateSubscribedAlarm')
                    }
                  />
                  <Button
                    onClick={ignoreSubscribedAlarmSystemAlerts}
                    label={I18n.t('ignoreSubscribedAlarm')}
                  />
                </Box>
              </Box>
            )}
          </Box>
        )}
        {alarm.link &&
          alarm.link.subscribers &&
          Object.keys(alarm.link.subscribers).length > 0 && (
            <Box
              background="tileHeader"
              pad="small"
              align="center"
              onClick={displayAlarmFollowers}>
              <Text>
                {I18n.t('nPeopleFollowingAlarm', {
                  count: Object.keys(alarm.link.subscribers).length
                })}
              </Text>
            </Box>
          )}
        <CardHeader pad="small" justify="between">
          <Box flex />
          <AlarmType type={alarm.type} creationMode={alarm.creationMode} />
          <Box flex align="end">
            {alarmCategory.id !== Constants.UNCATEGORIZED_ALARM_CATEGORY_ID && (
              <CategoryLabel
                name={alarmCategory.name}
                color={alarmCategoryColor}
              />
            )}
          </Box>
        </CardHeader>
        <CardBody gap="medium" pad="medium">
          <Box direction="row" gap="medium">
            <LabelledText
              label={I18n.t('date')}
              text={DateTimeUtils.getDateAsString(alarmEffectiveDate)}
              border="bottom"
            />
            <LabelledText
              label={
                alarm.type === Constants.AlarmTypes.RECIPIENT
                  ? I18n.t('timeForBuddy')
                  : I18n.t('time')
              }
              text={
                alarm.type === Constants.AlarmTypes.RECIPIENT
                  ? buddyAlarmTimeStringWithTimezone
                  : DateTimeUtils.getLocalizedTimeAsString(alarmEffectiveDate)
              }
              border="bottom"
            />
          </Box>
          {alarm.type === Constants.AlarmTypes.RECIPIENT && (
            <LabelledText
              label={I18n.t('timeForYou')}
              text={
                alarm.recipientAlarmInterval ===
                Constants.NO_REMINDER_RECIPIENT_INTERVAL_KEY
                  ? I18n.t('dontRemindMe')
                  : I18n.t('creatorTimeForBuddyAlarm', {
                      timeString:
                        DateTimeUtils.getLocalizedTimeAsString(
                          alarmEffectiveDate
                        ),
                      remindMeAfter:
                        Constants.RecipientAlarmIntervals[
                          alarm.recipientAlarmInterval ||
                            GlobalConfig.defaultRecipientAlarmInterval
                        ].alarmDetailsDisplayString
                    })
              }
              border="bottom"
            />
          )}
          <LabelledText
            label={I18n.t('title')}
            text={alarm.name}
            border="bottom"
            flex={{ shrink: 0 }}
          />
          <LabelledText
            label={I18n.t('repeat')}
            text={alarmRepetitionString}
            border="bottom"
          />
          {alarm.repeatType !== '' && !!alarm.endDate && (
            <LabelledText
              label={I18n.t('endDate')}
              text={endDateString}
              border="bottom"
            />
          )}
          <LabelledText
            label={I18n.t('soundAndVibration')}
            text={
              deviceOs === 'ios'
                ? AlarmUtils.getIosRingerSettingsLabel(alarm.ringerSettings)
                : AlarmUtils.getAndroidRingerSettingsLabel(alarm.ringerSettings)
            }
            border="bottom"
          />
          {alarm.type !== Constants.AlarmTypes.RECIPIENT && (
            <LabelledText
              label={I18n.t('preReminder')}
              text={AlarmUtils.getPreReminderLabel(alarm.preReminderDuration)}
              border="bottom"
            />
          )}
          {!!numAlarmParticipants && (
            <Box border="bottom" direction="row" justify="between">
              <Box>
                <LabelText>{I18n.t('participants')}</LabelText>
                <ParticipantsListWithAvatar
                  type={alarm.type}
                  creator={creator}
                  backupContacts={
                    alarm.backupContacts.length > 0
                      ? [creator, ...alarm.backupContacts]
                      : []
                  }
                  backupGroup={
                    alarm.backupGroup
                      ? {
                          ...alarm.backupGroup,
                          members: [creator, ...alarm.backupGroup.members]
                        }
                      : null
                  }
                  recipient={alarm.recipient}
                  alarmId={alarm.id}
                />
              </Box>
              {viewConversation}
            </Box>
          )}
          {(!!alarm.notes || !!alarm.alarmPhotoUrl) && (
            <Box gap="medium">
              <Box>
                <LabelText color="darkTint">{I18n.t('notes')}</LabelText>
                {!!alarm.alarmPhotoUrl && (
                  <Box
                    margin={{
                      vertical: 'small',
                      left: 'large'
                    }}
                    width="80%">
                    <Image src={alarm.alarmPhotoUrl} />
                  </Box>
                )}
                {!!alarm.notes && (
                  <Box direction="row" border="bottom">
                    <Text style={{ wordBreak: 'break-all' }}>
                      <RichTextEditor
                        notes={alarm.notes}
                        readOnly="true"
                        toolbarHidden="true"
                      />
                    </Text>
                  </Box>
                )}
              </Box>
            </Box>
          )}
        </CardBody>
        {alarm.status && (
          <CardFooter pad="small" background="brand">
            <Box flex direction="column">
              <Box flex direction="row" justify="around">
                {alarm.type === Constants.AlarmTypes.CASCADING && (
                  <ColoredButtonWithBorder
                    backgroundColor={
                      alarmAcknowledgement &&
                      alarmAcknowledgement.response ===
                        Constants.PERSONAL_ALARM_DONE
                        ? colorThemes.getColorTheme(colorScheme).darkPrimary
                        : 'primary'
                    }
                    borderColor="white"
                    icon={<Checkmark />}
                    label={I18n.t(Constants.PERSONAL_ALARM_DONE)}
                    onClick={onIAmUp}
                  />
                )}
                {alarm.type === Constants.AlarmTypes.CASCADING && (
                  <ColoredButtonWithBorder
                    backgroundColor={
                      alarmAcknowledgement &&
                      alarmAcknowledgement.response ===
                        Constants.PERSONAL_ALARM_SKIP
                        ? colorThemes.getColorTheme(colorScheme).darkPrimary
                        : 'primary'
                    }
                    borderColor="white"
                    label={I18n.t(Constants.PERSONAL_ALARM_SKIP)}
                    onClick={onSkipPersonalAlarm}
                  />
                )}
                {alarm.type === Constants.AlarmTypes.SIMULTANEOUS && (
                  <ColoredButtonWithBorder
                    backgroundColor={
                      alarmAcknowledgement &&
                      alarmAcknowledgement.response ===
                        Constants.GROUP_ALARM_YES
                        ? colorThemes.getColorTheme(colorScheme).darkPrimary
                        : 'primary'
                    }
                    borderColor="white"
                    label={I18n.t(Constants.GROUP_ALARM_YES)}
                    onClick={onGroupAlarmYes}
                  />
                )}
                {alarm.type === Constants.AlarmTypes.SIMULTANEOUS && (
                  <ColoredButtonWithBorder
                    backgroundColor={
                      alarmAcknowledgement &&
                      alarmAcknowledgement.response === Constants.GROUP_ALARM_NO
                        ? colorThemes.getColorTheme(colorScheme).darkPrimary
                        : 'primary'
                    }
                    borderColor="white"
                    label={I18n.t(Constants.GROUP_ALARM_NO)}
                    onClick={onGroupAlarmNo}
                  />
                )}
                {alarm.type === Constants.AlarmTypes.RECIPIENT && (
                  <ColoredButtonWithBorder
                    backgroundColor={
                      alarmAcknowledgement &&
                      alarmAcknowledgement.response ===
                        Constants.RECIPIENT_CREATOR_ALARM_SKIP
                        ? colorThemes.getColorTheme(colorScheme).darkPrimary
                        : 'primary'
                    }
                    borderColor="white"
                    label={I18n.t(Constants.RECIPIENT_CREATOR_ALARM_SKIP)}
                    onClick={onSkipRecipientCreatorAlarm}
                  />
                )}
              </Box>
              <Box flex direction="row" justify="center">
                <Text color="white" alignSelf="center" margin="small">
                  {footerText}
                </Text>
                <Anchor
                  onClick={onIAmUp}
                  size="medium"
                  alignSelf="center"
                  color={
                    colorThemes.getColorTheme(colorScheme).textBackgroundColor
                  }>
                  {footerLinkText}
                </Anchor>
              </Box>
            </Box>
          </CardFooter>
        )}
      </Card>
      {showAlarmFollowers && (
        <Layer
          background="lightTint"
          onEsc={hideAlarmFollowers}
          onClickOutside={hideAlarmFollowers}>
          <Box margin="medium" width="medium" height={{ max: 'large' }}>
            <AlarmFollowers subscribers={alarm.link.subscribers} />
          </Box>
        </Layer>
      )}
    </AppCanvas>
  )
}

export default AlarmDetails
