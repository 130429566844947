import { LogUtils } from 'galarm-ps-api'
import localStorage from 'redux-persist/lib/storage'

export default {
  key: 'root',
  storage: localStorage,
  blacklist: ['appState', 'progress', 'pendingConversationMessages'], // Don't persist appState and progress state trees locally
  debug: __DEV__, // true -> verbose logs
  writeFailHandler: err => {
    LogUtils.logError(new Error('Unable to persist redux state'), err)
  }
}
