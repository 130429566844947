import { createStore, applyMiddleware, compose } from 'redux'
import rootReducer from '../reducers'
import thunk from 'redux-thunk'
import { persistReducer, persistStore } from 'redux-persist'
import debounce from 'lodash/debounce'
import { batchedSubscribe } from 'redux-batched-subscribe'
import { reactotronConfig, reduxPersistConfig } from 'galarm-config'
import { Platform } from 'react-native'
import { GlobalConfig } from 'galarm-config'
import {
  StorageManager,
  NotificationManager,
  AlarmManager
} from 'galarm-ps-api'

const debounceNotify = debounce(notify => notify(), 200, {
  maxWait: 500
})

export default function configureStore() {
  let storeEnhancers = []

  storeEnhancers.push(applyMiddleware(thunk), batchedSubscribe(debounceNotify))

  if (__DEV__) {
    // only bring in Reactotron in dev mode
    const reactotronEnhancer = reactotronConfig.createEnhancer()
    storeEnhancers.push(reactotronEnhancer)
  }

  const persistedReducer = persistReducer(reduxPersistConfig, rootReducer)
  const store = createStore(persistedReducer, compose(...storeEnhancers))
  const persistor = persistStore(store)
  return { store, persistor }
}

export function purgeStateIfReducerVersionChanged(persistor) {
  return StorageManager.retrieveData(['reducerVersion']).then(data => {
    const reducerVersion = data.reducerVersion
    if (reducerVersion !== GlobalConfig.reducerVersion) {
      console.log(
        'Reducer version change ' +
          reducerVersion +
          ' ' +
          GlobalConfig.reducerVersion
      )

      persistor.purge()
      StorageManager.storeData({
        reducerVersion: GlobalConfig.reducerVersion
      })
      Platform.OS === 'android' && NotificationManager.cancelAllNotifications()
      AlarmManager.cancelAllNotifications()
    }
  })
}
