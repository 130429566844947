import React, { useEffect, useState } from 'react'
import { Box, Text } from 'grommet'
import { FormNext } from 'grommet-icons'
import { I18n, Constants, GlobalConfig } from 'galarm-config'
import SelectHoursAndMinutesRepetition from './SelectHoursAndMinutesRepetition'
import HoverBox from './HoverBox'
import SelectWeeklyRepetition from './SelectWeeklyRepetition'
import SelectCustomRepetition from './SelectCustomRepetition'
import { useSelector } from 'react-redux'
import { colorThemes } from 'galarm-config'
import { Checkmark } from 'grommet-icons'
import { AlarmUtils } from 'galarm-shared'
import { Selectors } from 'galarm-shared'
import { NavigationUtils } from 'galarm-ps-api'

const { upgradePurchasedSelector } = Selectors

const ConfigureAlarmRepetition = ({
  date,
  repeatType,
  repeat,
  onRepeatChange
}) => {
  const defaultAdditionalConfigurationToShow = {
    [I18n.t(Constants.RepeatTypes.HOURS_AND_MINUTES)]: false,
    [I18n.t(Constants.RepeatTypes.DAYS_OF_WEEK)]: false,
    [I18n.t('moreOptions')]: false
  }
  const colorScheme = useSelector(state => state.userSettings.webColorScheme)
  const upgradePurchased = useSelector(upgradePurchasedSelector)

  const [additionalConfigurationToShow, setAdditionalConfigurationToShow] =
    useState(defaultAdditionalConfigurationToShow)

  const hideAdditionalConfiguration = () =>
    setAdditionalConfigurationToShow(defaultAdditionalConfigurationToShow)

  const displayHourlyAlarmConfiguration = () =>
    setAdditionalConfigurationToShow({
      ...defaultAdditionalConfigurationToShow,
      [I18n.t(Constants.RepeatTypes.HOURS_AND_MINUTES)]: true
    })

  const displayDaysOfWeekConfiguration = () =>
    setAdditionalConfigurationToShow({
      ...defaultAdditionalConfigurationToShow,
      [I18n.t(Constants.RepeatTypes.DAYS_OF_WEEK)]: true
    })

  const displayCustomRepetitions = () => {
    if (!upgradePurchased) {
      NavigationUtils.showGalarmPremiumScreen('alarmRepetitionsPremiumFeature')
      return
    }

    setAdditionalConfigurationToShow({
      ...defaultAdditionalConfigurationToShow,
      [I18n.t('moreOptions')]: true
    })
  }

  // Check if repeat is a type of more options
  const isRepeatTypeOneOfMoreOptions = () => {
    if (
      repeatType === Constants.RepeatTypes.EVERY_M_HOURS_AND_N_MINUTES ||
      repeatType === Constants.RepeatTypes.DAYS ||
      repeatType === Constants.RepeatTypes.WEEKS ||
      repeatType === Constants.RepeatTypes.YEARS ||
      repeatType === Constants.RepeatTypes.MONTHS
    ) {
      return true
    }
    return false
  }

  // Check if the current item should be selected based on repeat type
  const shouldRepeatOptionBeMarkedSelected = item => {
    if (
      isRepeatTypeOneOfMoreOptions() &&
      item.label === I18n.t('moreOptions')
    ) {
      return true
    }
    return repeatType === item.value
  }

  useEffect(() => {
    if (repeatType === Constants.RepeatTypes.EVERY_N_HOURS) {
      const { startHours, startMins, endHours, endMins, hours, selectedDays } =
        AlarmUtils.getRepeatOptionsForHourlyRepetition(repeat)
      const minutes = 60 * hours
      const selectedRepeat = `${startHours}:${startMins}:${endHours}:${endMins}:${minutes}:${selectedDays}`
      onRepeatChange(Constants.RepeatTypes.HOURS_AND_MINUTES, selectedRepeat)
    }
  })

  const repeatItems = [
    {
      label: I18n.t(Constants.RepeatTypes.EVERY_N_HOURS),
      value: Constants.RepeatTypes.HOURS_AND_MINUTES,
      onClick: () => {
        let selectedRepeat = `8:0:20:0:150:${Object.keys(
          Constants.RepeatDayToDayMapping
        ).join(',')}`
        if (repeat) {
          const {
            startHours,
            startMins,
            endHours,
            endMins,
            minutes,
            selectedDays
          } = AlarmUtils.getRepeatOptionsForHoursAndMinutesRepetition(repeat)

          selectedRepeat = `${startHours}:${startMins}:${endHours}:${endMins}:${minutes}:${selectedDays}`
        }
        onRepeatChange(Constants.RepeatTypes.HOURS_AND_MINUTES, selectedRepeat)
        displayHourlyAlarmConfiguration()
      },
      nested: true
    },
    {
      label: I18n.t(Constants.RepeatTypes.DAYS_OF_WEEK),
      value: Constants.RepeatTypes.DAYS_OF_WEEK,
      onClick: () => {
        onRepeatChange(
          Constants.RepeatTypes.DAYS_OF_WEEK,
          Object.keys(Constants.RepeatDayToDayMapping).join(',')
        )
        displayDaysOfWeekConfiguration()
      },
      nested: true
    },
    {
      label: I18n.t(Constants.RepeatTypes.MONTHLY),
      value: Constants.RepeatTypes.MONTHLY,
      onClick: () => {
        onRepeatChange(Constants.RepeatTypes.MONTHLY, '')
        hideAdditionalConfiguration()
      }
    },
    {
      label: I18n.t(Constants.RepeatTypes.YEARLY),
      value: Constants.RepeatTypes.YEARLY,
      onClick: () => {
        onRepeatChange(Constants.RepeatTypes.YEARLY, '')
        hideAdditionalConfiguration()
      }
    },
    {
      label: I18n.t('moreOptions'),
      onClick: () => {
        // Adding this so that we hide the previous view of showing repeat types
        onRepeatChange('', '')
        // This is called such that the view
        // can be moved to the right
        GlobalConfig.forceUpdate()
        displayCustomRepetitions()
      },
      nested: true
    }
  ]

  repeatType &&
    repeatItems.push({
      label: I18n.t(Constants.RepeatTypes.NO_REPEAT),
      onClick: () => {
        onRepeatChange('', '')
      }
    })

  return (
    <Box flex direction="row">
      <Box width={'350px'}>
        {repeatItems.map((item, index) => (
          <HoverBox
            key={index}
            onClick={() => item.onClick(item)}
            background={
              shouldRepeatOptionBeMarkedSelected(item)
                ? item.nested
                  ? 'lightPrimary'
                  : colorThemes.getColorTheme(colorScheme).listItemSelectedColor
                : 'textBackgroundColor'
            }>
            <Text>{item.label}</Text>
            {item.nested && <FormNext />}
            {shouldRepeatOptionBeMarkedSelected(item) && !item.nested && (
              <Checkmark />
            )}
          </HoverBox>
        ))}
      </Box>
      {(repeatType === Constants.RepeatTypes.HOURS_AND_MINUTES ||
        additionalConfigurationToShow[
          I18n.t(Constants.RepeatTypes.HOURS_AND_MINUTES)
        ]) && (
        <Box border="left">
          <SelectHoursAndMinutesRepetition
            repeat={repeat}
            onSaveAlarmRepetition={onRepeatChange}
          />
        </Box>
      )}
      {(repeatType === Constants.RepeatTypes.DAYS_OF_WEEK ||
        additionalConfigurationToShow[
          I18n.t(Constants.RepeatTypes.DAYS_OF_WEEK)
        ]) && (
        <Box border="left">
          <SelectWeeklyRepetition
            selectedRepetitions={repeat.split(',')}
            onSaveAlarmRepetition={onRepeatChange}
          />
        </Box>
      )}
      {(isRepeatTypeOneOfMoreOptions() ||
        additionalConfigurationToShow[I18n.t('moreOptions')]) && (
        <Box border="left">
          <SelectCustomRepetition
            date={date}
            repeat={repeat}
            repeatType={repeatType}
            onSaveAlarmRepetition={onRepeatChange}
          />
        </Box>
      )}
    </Box>
  )
}

export default ConfigureAlarmRepetition
