import React, { useEffect, useState, useRef } from 'react'
import { GlobalConfig, I18n } from 'galarm-config'
import PropTypes from 'prop-types'
import LabelText from './LabelText'
import { Box, TextInput, Layer } from 'grommet'
import { FormNext } from 'grommet-icons'
import HoverBox from './HoverBox'
import EditGroupHeader from './EditGroupHeader'
import MembersList from './MembersList'
import SelectContacts from './SelectContacts'
import SelectImage from './SelectImage'
import { NavigationUtils } from 'galarm-ps-api'
import EditAvatar from './EditAvatar'

const EditGroup = props => {
  const defaultAdditionalConfigurationToShow = {
    membersPicker: false
  }
  const inputFile = useRef(null)

  const [showGroupImageSelector, setShowGroupImageSelector] = useState(false)

  const options = [I18n.t('uploadImage'), I18n.t('removeImage')]

  const scrollToRightRef = useRef(null)

  const [additionalConfigurationToShow, setAdditionalConfigurationToShow] =
    useState(defaultAdditionalConfigurationToShow)

  const hideAdditionalConfiguration = () =>
    setAdditionalConfigurationToShow(defaultAdditionalConfigurationToShow)

  const displayMembersPicker = () =>
    setAdditionalConfigurationToShow({
      ...defaultAdditionalConfigurationToShow,
      membersPicker: true
    })

  const hideGroupImagePicker = () => {
    setShowGroupImageSelector(false)
  }

  const onSelectUploadImage = () => {
    inputFile.current.click()
  }

  const onRemoveImage = () => {
    props.onAvatarChange(null)
  }

  const onClickGroupAvatar = () => {
    if (!props.avatarSource) {
      inputFile.current.click()
    } else {
      setShowGroupImageSelector(true)
    }
  }

  const onSelectFile = event => {
    var file = event.target.files[0]
    if (file.size > GlobalConfig.maxProfileImageSize) {
      NavigationUtils.showAlert(
        I18n.t('cantUploadImage'),
        I18n.t('selectSmallerImage')
      )
    } else {
      props.onAvatarChange(file)
    }
  }
  useEffect(() => {
    scrollToRightRef.current.scrollIntoView({ behavior: 'smooth' })
  }, [])

  return (
    <Box flex background="textBackgroundColor">
      <EditGroupHeader
        groupData={{ name: props.name, isEdit: props.isEdit }}
        onSave={props.onSaveGroup}
      />
      <Box flex overflow={{ horizontal: 'auto' }}>
        <Box flex direction="row" width={{ min: '1200px' }}>
          <Box
            pad={{ vertical: 'small' }}
            border="right"
            fill="vertical"
            width={'500px'}
            overflow="auto">
            <Box
              pad="small"
              align="center"
              onClick={() => onClickGroupAvatar()}>
              <EditAvatar
                size="4xl"
                title={I18n.t('addGroupImage')}
                url={props.avatarSource}
                onClick={onClickGroupAvatar}
              />
            </Box>
            <HoverBox onClick={hideAdditionalConfiguration}>
              <Box flex pad={{ horizontal: '10px' }}>
                <LabelText>{I18n.t('name')}</LabelText>
                <TextInput
                  flex
                  value={props.name}
                  placeholder={I18n.t('enterNameForGroup')}
                  onChange={props.onNameChange}
                />
              </Box>
            </HoverBox>
            <HoverBox
              onClick={displayMembersPicker}
              background={
                additionalConfigurationToShow.participantsPicker
                  ? 'lightPrimary'
                  : 'textBackgroundColor'
              }>
              <Box>
                <LabelText margin={{ left: '10px' }}>
                  {I18n.t('members')}
                </LabelText>
                <MembersList members={props.members} />
              </Box>
              <FormNext />
            </HoverBox>
          </Box>
          {additionalConfigurationToShow.membersPicker && (
            <Box width="500px" border="right" pad={{ top: 'medium' }}>
              <SelectContacts
                selectedContacts={props.members}
                onSelectContact={props.onSelectMember}
              />
            </Box>
          )}
          <div ref={scrollToRightRef} />
        </Box>
      </Box>
      {showGroupImageSelector && (
        <Layer
          background="lightTint"
          onEsc={hideGroupImagePicker}
          onClickOutside={hideGroupImagePicker}>
          <Box margin="small">
            <SelectImage
              onClose={hideGroupImagePicker}
              onUpload={onSelectUploadImage}
              onRemove={onRemoveImage}
              options={options}
            />
          </Box>
        </Layer>
      )}
      {
        <input
          type="file"
          id="file"
          accept="image/png, image/jpeg"
          ref={inputFile}
          style={{ display: 'none' }}
          onChange={onSelectFile}
        />
      }
    </Box>
  )
}

EditGroup.propTypes = {
  name: PropTypes.string.isRequired,
  avatarSource: PropTypes.object,
  onSelectMember: PropTypes.func.isRequired,
  onNameChange: PropTypes.func.isRequired
}

export default EditGroup
