import React, { useState, useRef } from 'react'
import { Box, TextInput, Layer, Header, Text, Button, Image } from 'grommet'
import { FormNext } from 'grommet-icons'
import { Constants, I18n, GlobalConfig } from 'galarm-config'
import { Close } from 'grommet-icons'
import { useDispatch, useSelector } from 'react-redux'
import { AlarmUtils, Utils } from 'galarm-shared'
import ChangeGroupMembersConfig from './ChangeGroupMembersConfig'
import HoverBox from './HoverBox'
import LabelText from './LabelText'
import TintedImage from './TintedImage'
import RichTextEditor from './RichTextEditor'
import ParticipantsList from './ParticipantsList'
import { ActionCreators } from 'galarm-shared'
import SelectImage from './SelectImage'
import { NavigationUtils } from 'galarm-ps-api'

const EditAlarmHeader = ({ alarmData, onSave }) => {
  const dispatch = useDispatch()
  return (
    <Header
      className="header"
      background="brand"
      pad="small"
      direction="row"
      justify="between">
      <Text color="white" level={5}>
        {alarmData.alarmCreationMode ===
        Constants.AlarmCreationModes.INSTANT_ALARM
          ? I18n.t('newInstantAlarm')
          : AlarmUtils.getAlarmTitle(alarmData.type, alarmData.isEdit)}
      </Text>
      <Box direction="row" align="center">
        <Button
          plain
          color="white"
          label={I18n.t('sendAlarm')}
          onClick={onSave}
        />
        <Button
          icon={<Close />}
          onClick={() => dispatch(ActionCreators.hideNewInstantAlarmWizard())}
        />
      </Box>
    </Header>
  )
}

const EditInstantAlarm = props => {
  const colorScheme = useSelector(state => state.userSettings.webColorScheme)

  const [showNotes, setShowNotes] = useState(false)
  const [showAlarmPhotoSelector, setShowAlarmPhotoSelector] = useState(false)

  const displayAlarmPhotoSelector = event => {
    event.stopPropagation()
    setShowAlarmPhotoSelector(true)
  }

  const hideAlarmPhotoSelector = () => {
    setShowAlarmPhotoSelector(false)
  }

  const options = [I18n.t('uploadImage'), I18n.t('removeImage')]

  const defaultAdditionalConfigurationToShow = {
    participantsPicker: false
  }

  const scrollToRightRef = useRef(null)
  const inputFile = useRef(null)

  const onSelectFile = event => {
    var file = event.target.files[0]
    // Fix for the issue of adding the same file
    event.target.value = ''
    if (file.size > GlobalConfig.maxProfileImageSize) {
      NavigationUtils.showAlert(
        I18n.t('cantUploadImage'),
        I18n.t('selectSmallerImage')
      )
    } else {
      props.onUploadAlarmPhoto(file)
    }
  }

  const onNameChange = event => props.onNameChange(event.target.value)

  const [additionalConfigurationToShow, setAdditionalConfigurationToShow] =
    useState(defaultAdditionalConfigurationToShow)

  const hideAdditionalConfiguration = () =>
    setAdditionalConfigurationToShow(defaultAdditionalConfigurationToShow)

  const displayParticipantsPicker = () =>
    setAdditionalConfigurationToShow({
      ...defaultAdditionalConfigurationToShow,
      participantsPicker: true
    })

  const handleChangeNotes = () => {
    setShowNotes(true)
  }

  const onSelectUploadImage = () => {
    inputFile.current.click()
  }

  const onRemoveImage = () => {
    props.onDeleteAlarmPhoto()
  }

  const onShowAlarmPhoto = event => {
    event.stopPropagation()
    inputFile.current.click()
  }

  const updateMembersConfig = members => {
    let newMembers = props.backupGroup.members.map(member => {
      const newMember = Utils.getObjectWithId(members, member.id)
      return {
        ...member,
        state: newMember.state
      }
    })

    let newBackupGroup = {
      ...props.backupGroup,
      members: newMembers
    }

    props.onSaveBackups([], newBackupGroup)
  }

  const onSave = () => {
    props.onSaveAlarm()
  }

  return (
    <Box flex background="textBackgroundColor">
      <EditAlarmHeader
        alarmData={{
          name: props.name,
          type: props.type,
          isEdit: props.isEdit,
          alarmCreationMode: props.creationMode
        }}
        onSave={onSave}
      />
      <Box flex overflow={{ horizontal: 'auto' }}>
        <Box flex direction="row" width={{ min: '1200px' }}>
          <Box
            pad={{ vertical: 'small' }}
            border="right"
            fill="vertical"
            width={'400px'}
            overflow="auto">
            <Box flex>
              <HoverBox onClick={hideAdditionalConfiguration}>
                <TintedImage
                  tintColor="darkTint"
                  src={require('galarm-res/img/web/ic_title.svg').default}
                />
                <Box flex>
                  <LabelText>{I18n.t('title')}</LabelText>
                  <TextInput
                    flex
                    value={props.name}
                    placeholder={AlarmUtils.getDefaultAlarmName(props.type)}
                    onChange={onNameChange}
                  />
                </Box>
              </HoverBox>
              {props.backupGroup !== null && (
                <HoverBox
                  onClick={displayParticipantsPicker}
                  background={
                    additionalConfigurationToShow.participantsPicker
                      ? 'lightPrimary'
                      : 'textBackgroundColor'
                  }>
                  <Box direction="row" gap="medium" align="start">
                    <TintedImage
                      tintColor="darkTint"
                      src={require('galarm-res/img/web/ic_person.svg').default}
                      margin={{ vertical: '15px' }}
                    />
                    <Box>
                      <LabelText>{I18n.t('participants')}</LabelText>
                      <ParticipantsList
                        type={props.type}
                        backupGroup={props.backupGroup}
                        backupContacts={props.backupContacts}
                        recipient={props.recipient}
                      />
                    </Box>
                  </Box>
                  <FormNext />
                </HoverBox>
              )}
              {props.backupContacts.length > 0 && (
                <HoverBox
                  background={
                    additionalConfigurationToShow.participantsPicker
                      ? 'lightPrimary'
                      : 'textBackgroundColor'
                  }>
                  <Box direction="row" gap="medium" align="start">
                    <TintedImage
                      tintColor="darkTint"
                      src={require('galarm-res/img/web/ic_person.svg').default}
                      margin={{ vertical: '15px' }}
                    />
                    <Box>
                      <LabelText>{I18n.t('participants')}</LabelText>
                      <ParticipantsList
                        type={props.type}
                        backupGroup={props.backupGroup}
                        backupContacts={props.backupContacts}
                        recipient={props.recipient}
                      />
                    </Box>
                  </Box>
                </HoverBox>
              )}
              <HoverBox
                onClick={() => {
                  hideAdditionalConfiguration()
                  handleChangeNotes()
                }}>
                <Box flex>
                  <Box flex direction="row" align="center" gap="medium">
                    <TintedImage
                      tintColor="darkTint"
                      src={require('galarm-res/img/web/ic_notes.svg').default}
                      alignSelf="start"
                    />
                    <Box
                      flex
                      direction="row"
                      align="center"
                      justify="between"
                      gap="medium">
                      <Text color="darkTint">{I18n.t('notes')}</Text>
                      <Box direction="row" gap="medium">
                        <TintedImage
                          src={
                            require('galarm-res/img/web/ic_alarm_text.svg')
                              .default
                          }
                        />
                        <Box onClick={onShowAlarmPhoto}>
                          <TintedImage
                            src={
                              require('galarm-res/img/web/ic_alarm_photo.svg')
                                .default
                            }
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box>
                    {!!props.alarmPhotoUrl && (
                      <Box
                        onClick={displayAlarmPhotoSelector}
                        margin={{
                          vertical: 'small',
                          left: 'large'
                        }}
                        width="80%">
                        <Image src={props.alarmPhotoUrl} />
                      </Box>
                    )}
                    {!!props.notes && (
                      <Box flex margin={{ right: 'small', left: 'large' }}>
                        <RichTextEditor
                          notes={props.notes}
                          onNotesChange={props.onNotesChange}
                          readOnly="true"
                          linksDisabled="true"
                        />
                      </Box>
                    )}
                  </Box>
                </Box>
              </HoverBox>
            </Box>
          </Box>
          {additionalConfigurationToShow.participantsPicker && (
            <Box border="right">
              <ChangeGroupMembersConfig
                backupGroup={props.backupGroup}
                alarmType={props.alarmType}
                updateMembersConfig={updateMembersConfig}
              />
            </Box>
          )}
          {showNotes && (
            <Layer
              background="lightTint"
              onEsc={() => setShowNotes(false)}
              onClickOutside={() => setShowNotes(false)}>
              <Box margin="xsmall" direction="column">
                <Box pad={{ horizontal: 'small' }}>
                  <RichTextEditor
                    notes={props.notes}
                    onNotesChange={props.onNotesChange}
                    onDone={() => setShowNotes(false)}
                    onCancel={() => setShowNotes(false)}
                  />
                </Box>
              </Box>
            </Layer>
          )}
          <div ref={scrollToRightRef} />
        </Box>
      </Box>
      {showAlarmPhotoSelector && (
        <Layer
          background="lightTint"
          onEsc={hideAlarmPhotoSelector}
          onClickOutside={hideAlarmPhotoSelector}>
          <SelectImage
            onClose={hideAlarmPhotoSelector}
            onUpload={onSelectUploadImage}
            onRemove={onRemoveImage}
            options={options}
            colorScheme={colorScheme}
          />
        </Layer>
      )}
      {
        <input
          type="file"
          id="file"
          accept="image/png, image/jpeg"
          ref={inputFile}
          style={{ display: 'none' }}
          onChange={onSelectFile}
        />
      }
    </Box>
  )
}

export default EditInstantAlarm
