/*
 **  This component will be published in a separate package
 */
import React, { useState } from 'react'
import { Image, Text, Box } from 'grommet'
import { getUserColor, getUserInitials } from './utils'

// TODO
// 3 words name initials
// handle only alpha numeric chars

const GiftedAvatar = props => {
  const [avatarColor, setAvatarColor] = useState('')
  const renderAvatar = () => {
    if (typeof props.user.avatar === 'function') {
      return props.user.avatar()
    } else if (typeof props.user.avatar === 'string') {
      return (
        <Image
          src={{ uri: props.user.avatar }}
          style={defaultStyles.avatarStyle}
        />
      )
    } else if (typeof props.user.avatar === 'number') {
      return <Image src={props.user.avatar} style={defaultStyles.avatarStyle} />
    }
    return null
  }

  const renderInitials = () => {
    return (
      <Text style={defaultStyles.textStyle}>
        {getUserInitials(props.user.name)}
      </Text>
    )
  }

  if (!props.user.name && !props.user.avatar) {
    // render placeholder
    return <Box style={defaultStyles.avatarStyle} />
  }
  if (props.user.avatar) {
    return (
      <Box
        onClick={() => {
          const { ...other } = props
          props.onPress && props.onPress(other)
        }}>
        {renderAvatar()}
      </Box>
    )
  }

  if (!avatarColor) {
    setAvatarColor(getUserColor(props.user.name || ''))
  }

  return (
    <Box
      onPress={() => {
        const { ...other } = props
        props.onPress && props.onPress(other)
      }}
      style={defaultStyles.avatarStyle}>
      {renderInitials()}
    </Box>
  )
}
export default GiftedAvatar

const defaultStyles = {
  avatarStyle: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 40,
    height: 40,
    borderRadius: 20
  },
  textStyle: {
    color: '#fff',
    fontSize: 16,
    backgroundColor: 'transparent',
    fontWeight: '100'
  }
}

GiftedAvatar.defaultProps = {
  user: {
    name: null,
    avatar: null
  },
  onPress: null,
  avatarStyle: {},
  textStyle: {}
}
