import { combineReducers } from 'redux'
import { Reducers, ActionTypes } from 'galarm-shared'
import appState from './appState'

const appReducer = combineReducers({
  appState,
  alarms: Reducers.alarms,
  backupAlarms: Reducers.backupAlarms,
  groups: Reducers.groups,
  contacts: Reducers.contacts,
  pendingActions: Reducers.pendingActions,
  userInfo: Reducers.userInfo,
  progress: Reducers.progress,
  userSettings: Reducers.userSettings,
  conversations: Reducers.conversations,
  pendingConversationMessages: Reducers.pendingConversationMessages,
  connections: Reducers.connections,
  alarmActions: Reducers.alarmActions
})

const rootReducer = (state, action) => {
  if (action.type === ActionTypes.BATCH_ACTIONS) {
    return action.actions.reduce(appReducer, state)
  }

  if (action.type === ActionTypes.RESET_APP) {
    // Don't reset the appState as it only contains the phone
    // state which doesn't change when the app is reset.
    state = Object.assign(
      {},
      { appState: state.appState, userSettings: state.userSettings }
    )
  }

  return appReducer(state, action)
}

export default rootReducer
