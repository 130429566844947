import React from 'react'
import LabelText from './LabelText'
import { Box, List, ThemeContext } from 'grommet'

const ColorOption = ({ code, id, selected, onSelect }) => {
  return (
    <Box
      onClick={() => onSelect(id)}
      background={code}
      height="40px"
      width="40px"
      round
      border={selected ? { color: 'darkTint', size: 'medium' } : false}
    />
  )
}

const ColorPalette = ({ colors, selectedColor, onChange, label }) => {
  const colorsData = colors.map(color => {
    return color.id === selectedColor
      ? { ...color, selected: true, onSelect: onChange }
      : { ...color, selected: false, onSelect: onChange }
  })

  return (
    <Box>
      {!!label && <LabelText>{label}</LabelText>}
      <ThemeContext.Extend
        value={{
          list: {
            extend: {
              display: 'inline-flex',
              'flex-wrap': 'wrap'
            }
          }
        }}>
        <List
          border={false}
          data={colorsData}
          // eslint-disable-next-line react/no-children-prop
          children={ColorOption}
          margin="none"
          pad="small"
        />
      </ThemeContext.Extend>
    </Box>
  )
}

ColorPalette.defaultProps = {
  labelStyle: {},
  containerStyle: {}
}

export default ColorPalette
