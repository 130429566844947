import React from 'react'
import { I18n, Constants } from 'galarm-config'
import { Box, Text } from 'grommet'
import { FormNext } from 'grommet-icons'
import SelectEveryMHoursAndNMinutesRepetition from './SelectEveryMHoursNMinutesRepetition'
import SelectDaysRepetition from './SelectDaysRepetition'
import SelectWeeksRepetition from './SelectWeeksRepetition'
import SelectMonthsRepetition from './SelectMonthsRepetition'
import SelectYearsRepetition from './SelectYearsRepetition'
import HoverBox from './HoverBox'

const frequencyOptions = [
  {
    label: I18n.t(Constants.RepeatTypes.HOURS_AND_MINUTES),
    value: Constants.RepeatTypes.EVERY_M_HOURS_AND_N_MINUTES
  },
  {
    label: I18n.t(Constants.RepeatTypes.DAYS),
    value: Constants.RepeatTypes.DAYS
  },
  {
    label: I18n.t(Constants.RepeatTypes.WEEKS),
    value: Constants.RepeatTypes.WEEKS
  },
  {
    label: I18n.t(Constants.RepeatTypes.MONTHS),
    value: Constants.RepeatTypes.MONTHS
  },
  {
    label: I18n.t(Constants.RepeatTypes.YEARS),
    value: Constants.RepeatTypes.YEARS
  }
]

const SelectCustomRepetition = ({
  date,
  repeat,
  repeatType,
  onSaveAlarmRepetition
}) => {
  const onSelectFrequency = item => {
    let selectedRepeat
    switch (item.value) {
      case Constants.RepeatTypes.EVERY_M_HOURS_AND_N_MINUTES:
        selectedRepeat = '2:30'
        // If editing repetition, then set the currently set hourly repetition
        if (
          repeatType === Constants.RepeatTypes.EVERY_M_HOURS_AND_N_MINUTES &&
          repeat
        ) {
          selectedRepeat = repeat
        }

        onSaveAlarmRepetition(
          Constants.RepeatTypes.EVERY_M_HOURS_AND_N_MINUTES,
          selectedRepeat
        )
        break
      case Constants.RepeatTypes.DAYS:
        selectedRepeat = '2'
        // If editing repetition, then set the currently set hourly repetition
        if (repeatType === Constants.RepeatTypes.DAYS && repeat) {
          selectedRepeat = repeat
        }
        onSaveAlarmRepetition(Constants.RepeatTypes.DAYS, selectedRepeat)
        break
      case Constants.RepeatTypes.WEEKS:
        selectedRepeat = '2'
        // If editing repetition, then set the currently set hourly repetition
        if (repeatType === Constants.RepeatTypes.WEEKS && repeat) {
          selectedRepeat = repeat
        }
        onSaveAlarmRepetition(Constants.RepeatTypes.WEEKS, selectedRepeat)
        break
      case Constants.RepeatTypes.MONTHS:
        selectedRepeat = '2'
        // If editing repetition, then set the currently set hourly repetition
        if (repeatType === Constants.RepeatTypes.MONTHS && repeat) {
          selectedRepeat = repeat
        }
        onSaveAlarmRepetition(Constants.RepeatTypes.MONTHS, selectedRepeat)
        break
      case Constants.RepeatTypes.YEARS:
        selectedRepeat = '2'
        if (repeatType === Constants.RepeatTypes.YEARS && repeat) {
          selectedRepeat = repeat
        }
        onSaveAlarmRepetition(Constants.RepeatTypes.YEARS, selectedRepeat)
        break
      default:
        console.log('Unknown frequency ' + item.value)
    }
  }

  return (
    <Box flex direction="row">
      <Box width={'350px'}>
        {frequencyOptions.map((item, index) => (
          <HoverBox
            key={index}
            onClick={() => onSelectFrequency(item)}
            background={
              repeatType === item.value ? 'lightPrimary' : 'textBackgroundColor'
            }>
            <Text>{item.label}</Text>
            <FormNext />
          </HoverBox>
        ))}
      </Box>
      {repeatType === Constants.RepeatTypes.EVERY_M_HOURS_AND_N_MINUTES && (
        <Box border="left">
          <SelectEveryMHoursAndNMinutesRepetition
            date={date}
            repeat={repeat}
            onSaveAlarmRepetition={onSaveAlarmRepetition}
          />
        </Box>
      )}
      {repeatType === Constants.RepeatTypes.DAYS && (
        <Box border="left">
          <SelectDaysRepetition
            repeat={repeat}
            onSaveAlarmRepetition={onSaveAlarmRepetition}
          />
        </Box>
      )}
      {repeatType === Constants.RepeatTypes.WEEKS && (
        <Box border="left">
          <SelectWeeksRepetition
            repeat={repeat}
            onSaveAlarmRepetition={onSaveAlarmRepetition}
          />
        </Box>
      )}
      {repeatType === Constants.RepeatTypes.MONTHS && (
        <Box border="left">
          <SelectMonthsRepetition
            date={date}
            repeat={repeat}
            onSaveAlarmRepetition={onSaveAlarmRepetition}
          />
        </Box>
      )}
      {repeatType === Constants.RepeatTypes.YEARS && (
        <Box border="left">
          <SelectYearsRepetition
            date={date}
            repeat={repeat}
            onSaveAlarmRepetition={onSaveAlarmRepetition}
          />
        </Box>
      )}
    </Box>
  )
}

export default SelectCustomRepetition
