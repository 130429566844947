import { NewAlarmContainer } from './NewAlarmContainer'
import React from 'react'
import { connect } from 'react-redux'
import { I18n } from 'galarm-config'
import { Constants } from 'galarm-config'
import { GlobalConfig } from 'galarm-config'
import { AlarmUtils, DateTimeUtils } from 'galarm-shared'
import { FirebaseProxy } from 'galarm-ps-api'
import { ChooseDurationContainer } from 'web-components'
import * as RNLocalize from 'react-native-localize'
import { Selectors } from 'galarm-shared'

const { requiredPermissionsEnabledSelector } = Selectors

class QuickAlarmContainer extends NewAlarmContainer {
  state = {
    id: GlobalConfig.rootFirebaseRef.push().key,
    name: '',
    date: Date.now(),
    backupContacts: this.getInitialBackupContacts(),
    backupGroup: this.getInitialBackupGroup(),
    notes: '',
    alarmPhotoUrl: '',
    repeat: '',
    repeatType: '',
    creatorTimezone: RNLocalize.getTimeZone(),
    timezoneSetting: Constants.TIMEZONE_SETTINGS.DEVICE,
    type: Constants.AlarmTypes.CASCADING,
    isEdit: false,
    cascadingAlarmInterval: GlobalConfig.defaultCascadingAlarmInterval,
    recipientAlarmInterval: null,
    recipient: this.getInitialRecipient(),
    ringerSettings: AlarmUtils.getDefaultRingerSettings(),
    creationMode: Constants.AlarmCreationModes.QUICK_ALARM,
    endDate: GlobalConfig.defaultAlarmEndDate,
    alarmCategoryId:
      this.props.alarmCategoryId || Constants.UNCATEGORIZED_ALARM_CATEGORY_ID,
    source: Constants.AlarmCreationSources.CREATED_MANUALLY_FROM_WEB,
    preReminderDuration: GlobalConfig.defaultPreReminderDuration
  }

  componentDidMount = () => {
    FirebaseProxy.logEvent(Constants.UserAnalyticsEvents.TAP_QUICK_ALARM, {})
  }

  getDisplayTextForDuration = duration => {
    const newDate = DateTimeUtils.roundUpToNextMin(Date.now() + duration)
    return I18n.t('quickAlarmWillRingAt', {
      timeString: DateTimeUtils.getTimeAsString(newDate)
    })
  }

  onSaveDuration = alarmDuration => {
    const newDate = DateTimeUtils.roundUpToNextMin(Date.now() + alarmDuration)
    this.setState(
      {
        date: newDate,
        name: this.getAlarmNameFromDuration(alarmDuration)
      },
      () => {
        this.onSaveAlarm()
      }
    )
    FirebaseProxy.logEvent(Constants.UserAnalyticsEvents.SAVE_QUICK_ALARM, {})
  }

  getAlarmNameFromDuration = duration => {
    const minutes = Math.floor((duration / Constants.MSEC_IN_MINUTE) % 60)
    const hours = Math.floor((duration / Constants.MSEC_IN_HOUR) % 24)
    let count
    if (hours && minutes) {
      count = 2
    } else if (hours) {
      count = 1
    } else {
      count = 0
    }
    return I18n.t('quickAlarmName', {
      count,
      minutes,
      hours
    })
  }

  render() {
    return (
      <ChooseDurationContainer
        onSaveDuration={this.onSaveDuration}
        getDisplayTextForDuration={this.getDisplayTextForDuration}
        onClose={this.props.onClose}
        title={I18n.t('remindMeIn')}
      />
    )
  }
}

const mapStateToProps = state => ({
  creatorName: state.userInfo.name,
  creatorMobileNumber: state.userInfo.formattedMobileNumberWithCC,
  requiredPermissionsEnabled: requiredPermissionsEnabledSelector(state),
  isAuthenticated: state.appState.authenticatedWithFirebase,
  rateTheAppInfo: state.userInfo.rateTheAppInfo,
  mobileNumber: state.userInfo.mobileNumber,
  colorScheme: state.userSettings.webColorScheme
})

export default connect(mapStateToProps)(QuickAlarmContainer)
