import React from 'react'
import { Text, Box } from 'grommet'

import moment from 'moment'

import { isSameDay } from './utils'

const Day = props => {
  if (!isSameDay(props.currentMessage, props.previousMessage)) {
    return (
      <Box style={styles.container}>
        <Box style={styles.wrapper}>
          <Text style={props.textStyle[props.position]}>
            {moment(props.currentMessage.createdAt).format('ll').toUpperCase()}
          </Text>
        </Box>
      </Box>
    )
  }
  return null
}

export default Day

const styles = {
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 5,
    marginBottom: 10
  }
}
