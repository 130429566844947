import React from 'react'
import { Text, Box, Button, Menu } from 'grommet'
import { Constants, GlobalConfig, I18n } from 'galarm-config'
import { LinkPrevious, MoreVertical, Search } from 'grommet-icons'
import { useDispatch, useSelector } from 'react-redux'
import { ActionCreators, Selectors } from 'galarm-shared'
import { NavigationUtils } from 'galarm-ps-api'
import SessionManager from '../api/SessionManager'

const { defaultChecklistSelector } = Selectors

const { alarmCategoriesSelector } = Selectors

const SidebarNavbar = ({ showSearchBar, showOrHideSearchBar }) => {
  const tabIndex = useSelector(state => state.appState.tabActiveIndex)
  const categorizedAlarmView = useSelector(
    state => state.userSettings.categorizedAlarmView
  )

  const showAlertIncidents = useSelector(
    state => state.appState.showAlertIncidents
  )

  const showRecentlyDeletedAlarms = useSelector(
    state => state.appState.showRecentlyDeletedAlarms
  )

  const currentlySelectedAlarmCategoryId = useSelector(
    state => state.appState.currentlySelectedAlarmCategoryId
  )
  const alarmCategories = useSelector(alarmCategoriesSelector)
  const alarmCategory = alarmCategories[currentlySelectedAlarmCategoryId]

  const allTaskLists = useSelector(state => state.alarms.checklists)
  const defaultTaskList = useSelector(defaultChecklistSelector)
  let currentlySelectedTaskList
  let currentlySelectedTaskListId = useSelector(
    state => state.appState.currentlySelectedTaskListId
  )
  if (!currentlySelectedTaskListId) {
    currentlySelectedTaskListId = defaultTaskList?.id
    currentlySelectedTaskList = defaultTaskList
  } else {
    currentlySelectedTaskList = allTaskLists.find(
      taskList => taskList.id === currentlySelectedTaskListId
    )
  }

  console.log(
    'currentlySelectedTaskList',
    currentlySelectedTaskListId,
    currentlySelectedTaskList
  )

  const dispatch = useDispatch()

  const onReportProblem = () => {
    GlobalConfig.showFeedbackScreen({
      type: Constants.TICKET_TYPES.PROBLEM_REPORT,
      extras: ''
    })
  }

  const onLogOut = () => {
    NavigationUtils.showAlert(I18n.t('confirm'), I18n.t('confirmLogOut'), [
      { text: I18n.t('no') },
      { text: I18n.t('yes'), onPress: logOutCore }
    ])
  }

  const logOutCore = () => {
    SessionManager.logOutWebSession()
  }

  const goBackToAllCategoriesButton = (
    <LinkPrevious
      id="go-back-to-all-categories"
      onClick={() => {
        dispatch(ActionCreators.setCurrentlySelectedAlarmCategoryId(''))
        dispatch(ActionCreators.setShowAlertIncidents(false))
      }}
    />
  )

  const hideRecentlyDeletedAlarmsButton = (
    <LinkPrevious
      id="hide-recently-deleted-alarms"
      onClick={() =>
        dispatch(ActionCreators.setShowRecentlyDeletedAlarms(false))
      }
    />
  )

  const goBackToDefaultTaskListButton = (
    <LinkPrevious
      id="go-back-to-default-task-list"
      onClick={() =>
        dispatch(ActionCreators.setCurrentlySelectedTaskListId(''))
      }
    />
  )

  const showProfileAndSettingsScreen = () => {
    dispatch(ActionCreators.showProfileAndSettingsScreen())
  }

  let title = '',
    buttons = [],
    items = [],
    menu = null,
    backButton = null
  switch (tabIndex) {
    case 0:
      title = I18n.t('alarms')
      if (categorizedAlarmView && alarmCategory) {
        title = I18n.t('alarmByCategories', { name: alarmCategory.name })
      } else if (showAlertIncidents) {
        title = I18n.t('alertIncidents')
      } else if (categorizedAlarmView) {
        title = I18n.t('alarmsByCategories')
      } else if (showRecentlyDeletedAlarms) {
        title = I18n.t('recentlyDeletedAlarms')
      }
      buttons = [
        {
          icon: <Search />,
          onClick: showOrHideSearchBar
        }
      ]
      backButton = null
      if (showSearchBar) {
        backButton = null
      } else if (categorizedAlarmView && alarmCategory) {
        backButton = goBackToAllCategoriesButton
      } else if (showAlertIncidents) {
        backButton = goBackToAllCategoriesButton
      } else if (showRecentlyDeletedAlarms) {
        backButton = hideRecentlyDeletedAlarmsButton
      }

      menu = (
        <Menu
          id="sidebar-menu-button"
          dropBackground="lightTint"
          icon={<MoreVertical />}
          items={[
            {
              label: categorizedAlarmView
                ? I18n.t('viewAllAlarms')
                : I18n.t('viewAlarmsByCategories'),
              onClick: () => {
                dispatch(ActionCreators.toggleCategorizedAlarmView())
                dispatch(ActionCreators.setShowRecentlyDeletedAlarms(false))
              }
            },
            {
              label: I18n.t('viewRecentlyDeletedAlarms'),
              onClick: () => {
                dispatch(ActionCreators.setShowRecentlyDeletedAlarms(true))
                categorizedAlarmView &&
                  dispatch(ActionCreators.toggleCategorizedAlarmView())
              }
            },
            {
              label: I18n.t('deleteInactiveAlarms'),
              onClick: GlobalConfig.showDeleteInactiveAlarmsDialog
            },
            {
              label: I18n.t('reportAProblem'),
              onClick: onReportProblem
            },
            {
              label: I18n.t('profileAndSettings'),
              onClick: showProfileAndSettingsScreen
            },
            {
              label: I18n.t('logOut'),
              onClick: onLogOut
            }
          ]}
        />
      )
      break
    case 1:
      if (!currentlySelectedTaskListId || !currentlySelectedTaskList) {
        title = I18n.t('checklists')
        break
      }

      title =
        currentlySelectedTaskListId !== defaultTaskList.id
          ? I18n.t('checklistTasks', { name: currentlySelectedTaskList.name })
          : I18n.t('checklists')
      if (currentlySelectedTaskList.showCompletedItems) {
        items.push({
          label: I18n.t('hideCompletedTasks'),
          onClick: () => {
            const { id, creator, name, isDefault, items, lastUpdatedAt } =
              currentlySelectedTaskList
            dispatch(
              ActionCreators.updateChecklist({
                id,
                creator,
                name,
                isDefault,
                items,
                showCompletedItems: false,
                lastUpdatedAt
              })
            )
          }
        })
      } else {
        items.push({
          label: I18n.t('showCompletedTasks'),
          onClick: () => {
            const { id, creator, name, isDefault, items, lastUpdatedAt } =
              currentlySelectedTaskList
            dispatch(
              ActionCreators.updateChecklist({
                id,
                creator,
                name,
                isDefault,
                items,
                showCompletedItems: true,
                lastUpdatedAt
              })
            )
          }
        })
      }
      items.push({
        label: I18n.t('deleteCompletedTasks'),
        onClick: () => {
          NavigationUtils.showAlert(
            I18n.t('confirm'),
            I18n.t('confirmDeleteCompletedTasks'),
            [
              {
                text: I18n.t('no')
              },
              {
                text: I18n.t('yes'),
                onPress: () => {
                  const {
                    id,
                    creator,
                    name,
                    isDefault,
                    items,
                    showCompletedItems
                  } = currentlySelectedTaskList
                  const newItems = items.filter(item => !item.checked)
                  dispatch(
                    ActionCreators.updateChecklist({
                      id,
                      creator,
                      name,
                      isDefault,
                      items: newItems,
                      showCompletedItems,
                      lastUpdatedAt: Date.now()
                    })
                  )
                }
              }
            ]
          )
        }
      })
      if (currentlySelectedTaskListId !== defaultTaskList.id) {
        items.push({
          label: I18n.t('deleteChecklist'),
          onClick: () => {
            NavigationUtils.showAlert(
              I18n.t('confirm'),
              I18n.t('confirmDeleteChecklist'),
              [
                {
                  text: I18n.t('no')
                },
                {
                  text: I18n.t('yes'),
                  onPress: () => {
                    dispatch(
                      ActionCreators.removeChecklist(
                        currentlySelectedTaskList.id
                      )
                    )
                    dispatch(ActionCreators.setCurrentlySelectedTaskListId(''))
                  }
                }
              ]
            )
          }
        })
      }

      menu = (
        <Menu
          id="sidebar-menu-button"
          dropBackground="lightTint"
          icon={<MoreVertical />}
          items={items}
        />
      )
      backButton =
        currentlySelectedTaskListId !== defaultTaskList.id
          ? goBackToDefaultTaskListButton
          : null
      break
    case 2:
      title = I18n.t('groups')
      buttons = [
        {
          icon: <Search />,
          onClick: showOrHideSearchBar
        }
      ]
      break
    case 3:
      title = I18n.t('contacts')
      buttons = [
        {
          icon: <Search />,
          onClick: showOrHideSearchBar
        }
      ]
      break
    default:
      title = ''
      break
  }

  return (
    <Box
      flex={{ shrink: 0 }}
      className="header"
      direction="row"
      align="center"
      justify="between"
      background="brand"
      pad="small">
      <Box
        direction="row"
        align="center"
        gap="small"
        pad={{ horizontal: 'small' }}>
        {backButton}
        <Text color="white" size="large">
          {title}
        </Text>
      </Box>
      <Box direction="row" justify="around" align="center">
        {buttons.map((button, index) => (
          <Button
            key={index}
            margin="small"
            pad="medium"
            plain
            onClick={button.onClick}
            icon={button.icon}
          />
        ))}
        {menu}
      </Box>
    </Box>
  )
}

export default SidebarNavbar
