import React from 'react'
import { Box } from 'grommet'
import LabelText from './LabelText'
import ParsedText from './ParsedText'

const LabelledText = ({ label, text, ...containerProps }) => {
  return (
    <Box flex {...containerProps}>
      <LabelText color="darkTint">{label}</LabelText>
      <ParsedText style={{ overflowWrap: 'break-word' }}>{text}</ParsedText>
    </Box>
  )
}

export default LabelledText
