import React from 'react'
import { connect } from 'react-redux'
import { I18n } from 'galarm-config'
import { Constants } from 'galarm-config'
import {
  Selectors,
  AlarmUtils,
  ActionCreators,
  AbstractAlarmCategoryActionsProvider
} from 'galarm-shared'
import { Box, CardBody, Text, Card, Menu, Layer } from 'grommet'
import { MoreVertical } from 'grommet-icons'
import EditAlarmCategoryColor from './EditAlarmCategoryColor'
import EditAlarmCategory from './EditAlarmCategory'
import { colorThemes } from 'galarm-config'
import { SecondaryText } from 'web-components'
const {
  makeAlarmCategorySummaryDataSelector,
  makeAllEnabledAlarmsInCategorySelector,
  makeAllDisabledAlarmsInCategorySelector
} = Selectors

const EmptyAlarmsByCategorySummary = () => {
  return <Box pad="small" margin="small" />
}

const SectionHeaderSummary = props => {
  return (
    <Text color="darkTint" alignSelf="center">
      {props.name}
    </Text>
  )
}

class AlarmsByCategorySummary extends AbstractAlarmCategoryActionsProvider {
  state = {
    editCategoryColor: false,
    editCategory: false
  }

  onNewAlarmCore = alarmCategoryId => {
    this.props.dispatch(
      ActionCreators.showSelectAlarmTypeScreen({
        alarmCategoryId: alarmCategoryId
      })
    )
  }

  // eslint-disable-next-line no-unused-vars
  editAlarmCategoryCore = alarmCategoryId => {
    this.setState({ editCategory: true })
  }

  // eslint-disable-next-line no-unused-vars
  editAlarmCategoryColorCore = alarmCategoryId => {
    this.setState({ editCategoryColor: true })
  }

  onChooseAlarmCategory = alarmCategoryId => {
    this.props.dispatch(
      ActionCreators.setCurrentlySelectedAlarmCategoryId(alarmCategoryId)
    )
  }

  render() {
    const { id, name, color, source, disabled, empty, sectionHeader } =
      this.props.alarmCategory

    const alarmCategoryColor = AlarmUtils.getColorForAlarmCategory(
      this.props.colorScheme,
      color
    )

    if (empty) {
      return <EmptyAlarmsByCategorySummary />
    }

    if (sectionHeader) {
      return <SectionHeaderSummary name={name} />
    }

    const alarmsSummary = I18n.t('numAlarms', {
      count: this.props.alarmCategorySummaryData.numAlarms
    })

    let menuItems = []

    const numAlarms = this.props.alarmCategorySummaryData.numAlarms
    if (source === Constants.ALARM_CATEGORY_SOURCES.SYSTEM) {
      if (!disabled) {
        menuItems = [
          { label: I18n.t('newAlarm'), onClick: () => this.onNewAlarm(id) },
          {
            label: I18n.t('editAlarmCategoryColor'),
            onClick: () => this.editAlarmCategoryColor(id)
          },
          {
            label: I18n.t('hideCategory'),
            onClick: () => this.hideAlarmCategory(id, numAlarms)
          }
        ]
        // We only need to enable or disable all alarms if there are alarms in the category
        if (numAlarms > 0) {
          menuItems.push({
            label: I18n.t('enableAllAlarms'),
            onClick: () =>
              this.enableAllAlarms(this.props.allDisabledAlarmsInCategory)
          })
          menuItems.push({
            label: I18n.t('disableAllAlarms'),
            onClick: () =>
              this.disableAllAlarms(this.props.allEnabledAlarmsInCategory)
          })
          menuItems.push({
            label: I18n.t('deleteAllAlarms'),
            onClick: () =>
              this.deleteAllAlarms(id, this.props.alarmCategory, numAlarms)
          })
        }
      } else {
        menuItems = [
          {
            label: I18n.t('unhideCategory'),
            onClick: () => this.unhideAlarmCategory(id)
          }
        ]
      }
    } else {
      menuItems = [
        { label: I18n.t('newAlarm'), onClick: () => this.onNewAlarm(id) },
        {
          label: I18n.t('editAlarmCategory'),
          onClick: () => this.editAlarmCategory(id)
        },
        {
          label: I18n.t('deleteAlarmCategory'),
          onClick: () => this.deleteAlarmCategory(id, numAlarms)
        }
      ]
      // We only need to enable or disable all alarms if there are alarms in the category
      if (numAlarms > 0) {
        menuItems.push({
          label: I18n.t('enableAllAlarms'),
          onClick: () =>
            this.enableAllAlarms(this.props.allDisabledAlarmsInCategory)
        })
        menuItems.push({
          label: I18n.t('disableAllAlarms'),
          onClick: () =>
            this.disableAllAlarms(this.props.allEnabledAlarmsInCategory)
        })
        menuItems.push({
          label: I18n.t('deleteAllAlarms'),
          onClick: () =>
            this.deleteAllAlarms(id, this.props.alarmCategory, numAlarms)
        })
      }
    }

    return (
      <Box key={id}>
        <Card background={alarmCategoryColor}>
          <CardBody direction="row" pad="small">
            <Box size="small" />
            <Box
              flex
              align="center"
              gap="small"
              onClick={() => this.onChooseAlarmCategory(id)}>
              <Text
                style={{
                  color: disabled
                    ? colorThemes.getColorTheme(this.props.colorScheme).darkTint
                    : colorThemes.getColorTheme(this.props.colorScheme)
                        .textColor
                }}>
                {name}
              </Text>
              <SecondaryText
                style={{
                  color: disabled
                    ? colorThemes.getColorTheme(this.props.colorScheme).darkTint
                    : colorThemes.getColorTheme(this.props.colorScheme)
                        .textColor
                }}>
                {I18n.t('alarmCategorySummary', {
                  count: this.props.alarmCategorySummaryData.numMissedAlarms,
                  alarmsSummary: alarmsSummary
                })}
              </SecondaryText>
            </Box>
            {id !== Constants.UNCATEGORIZED_ALARM_CATEGORY_ID ? (
              <Menu
                dropBackground="lightTint"
                alignSelf="start"
                size="small"
                icon={<MoreVertical color="textColor" />}
                items={menuItems}
              />
            ) : (
              <Box background="transparent" pad="medium" />
            )}
            {id === Constants.UNCATEGORIZED_ALARM_CATEGORY_ID && <Box />}
          </CardBody>
        </Card>
        {this.state.editCategoryColor && (
          <Layer background="lightTint">
            <EditAlarmCategoryColor
              alarmCategory={this.props.alarmCategory}
              onChangeColor={({ color }) =>
                this.updateAlarmCategory(id, { color })
              }
              onClose={() => this.setState({ editCategoryColor: false })}
            />
          </Layer>
        )}
        {this.state.editCategory && (
          <Layer background="lightTint">
            <EditAlarmCategory
              isEdit={true}
              alarmCategory={this.props.alarmCategory}
              onSaveAlarmCategory={alarmCategory =>
                this.updateAlarmCategory(id, alarmCategory)
              }
              onClose={() => this.setState({ editCategory: false })}
            />
          </Layer>
        )}
      </Box>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const alarmCategorySummaryDataSelector =
    makeAlarmCategorySummaryDataSelector()
  const getAllEnabledAlarmsSelector = makeAllEnabledAlarmsInCategorySelector()
  const getAllDisabledAlarmsSelector = makeAllDisabledAlarmsInCategorySelector()
  const { id } = ownProps.alarmCategory

  return {
    colorScheme: state.userSettings.webColorScheme,
    alarmCategorySummaryData: alarmCategorySummaryDataSelector(state, {
      id: id
    }),
    allEnabledAlarmsInCategory: getAllEnabledAlarmsSelector(state, { id: id }),
    allDisabledAlarmsInCategory: getAllDisabledAlarmsSelector(state, { id: id })
  }
}

export default connect(mapStateToProps)(AlarmsByCategorySummary)
