import React, { useState } from 'react'
import { Constants, I18n } from 'galarm-config'
import { Box, Heading, Button, Text, CheckBox } from 'grommet'
import { useDispatch, useSelector } from 'react-redux'
import { ActionCreators, AlarmUtils, Selectors } from 'galarm-shared'

const { enabledAlarmCategoriesSelector } = Selectors

const AlarmCategorySummary = ({
  category,
  selectedAlarmCategoryId,
  onChooseAlarmCategory
}) => {
  const colorScheme = useSelector(state => state.userSettings.webColorScheme)

  return (
    <Box direction="row" justify="start" align="center" gap="small">
      <CheckBox
        name="category"
        checked={category.id === selectedAlarmCategoryId}
        onChange={() => {
          if (selectedAlarmCategoryId === category.id) {
            onChooseAlarmCategory(Constants.UNCATEGORIZED_ALARM_CATEGORY)
          } else {
            onChooseAlarmCategory(category)
          }
        }}
      />
      <Box
        height={'30px'}
        width={'30px'}
        round
        background={AlarmUtils.getColorForAlarmCategory(
          colorScheme,
          category.color
        )}
      />
      <Text>{category.name}</Text>
    </Box>
  )
}

const SetAlarmCategory = ({ onClose, selectedAlarmCategory, alarmId }) => {
  const previousAlarmCategoryId = selectedAlarmCategory.id
  const [selectedAlarmCategoryId, setSelectedAlarmCategoryId] = useState(
    previousAlarmCategoryId
  )

  const alarmCategories = useSelector(enabledAlarmCategoriesSelector)

  const dispatch = useDispatch()

  const onSelectAlarmCategory = alarmCategoryId => {
    dispatch(ActionCreators.addAlarmToAlarmCategory(alarmCategoryId, alarmId))
  }

  const onUnselectAlarmCategory = alarmCategoryId => {
    dispatch(
      ActionCreators.removeAlarmFromAlarmCategory(alarmCategoryId, alarmId)
    )
  }

  const onChooseAlarmCategory = category => {
    setSelectedAlarmCategoryId(category.id)
  }

  const onSave = () => {
    if (previousAlarmCategoryId !== selectedAlarmCategoryId) {
      if (
        previousAlarmCategoryId !== Constants.UNCATEGORIZED_ALARM_CATEGORY_ID
      ) {
        onUnselectAlarmCategory(previousAlarmCategoryId)
      }
      onSelectAlarmCategory(selectedAlarmCategoryId)
    }
    onClose()
  }

  return (
    <Box>
      <Heading margin="none" level={4}>
        {previousAlarmCategoryId === Constants.UNCATEGORIZED_ALARM_CATEGORY_ID
          ? I18n.t('setAlarmCategory')
          : I18n.t('changeAlarmCategory')}
      </Heading>
      <Box margin={{ vertical: 'medium' }} gap="small">
        {Object.values(alarmCategories).map((alarmCategory, index) => (
          <AlarmCategorySummary
            key={index}
            category={alarmCategory}
            selectedAlarmCategoryId={selectedAlarmCategoryId}
            onChooseAlarmCategory={onChooseAlarmCategory}
          />
        ))}
      </Box>
      <Box direction="row" justify="end" gap="small" margin={{ top: 'small' }}>
        <Button onClick={onClose} label={I18n.t('cancel')} />
        <Button onClick={onSave} label={I18n.t('save')} />
      </Box>
    </Box>
  )
}

export default SetAlarmCategory
