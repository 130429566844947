import React, { Component } from 'react'
import { I18n } from 'galarm-config'
import { Constants } from 'galarm-config'
import { colorThemes } from 'galarm-config'
import { connect } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import { Utils } from 'galarm-shared'
import { Box, Text, TextInput } from 'grommet'
import { Checkmark } from 'grommet-icons'
import { SecondaryText } from 'web-components'

class SelectWeeksRepetition extends Component {
  static defaultProps = {
    repeat: '2'
  }

  state = {
    weeks: this.props.repeat
  }

  onChangeWeeks = event => {
    const newWeeksInt = parseInt(event.target.value)
    this.setState(
      {
        weeks: newWeeksInt ? newWeeksInt.toString() : ''
      },
      () => {
        if (this.state.weeks) {
          this.props.onSaveAlarmRepetition(
            Constants.RepeatTypes.WEEKS,
            this.state.weeks
          )
        }
      }
    )
  }

  render() {
    const weeksInt = parseInt(this.state.weeks)

    return (
      <Box width="450px">
        <Box
          direction="row"
          pad="small"
          justify="between"
          align="center"
          background={
            colorThemes.getColorTheme(this.props.colorScheme)
              .listItemSelectedColor
          }>
          <Box direction="row" align="center">
            <Text>{I18n.t('repeatEvery')}</Text>
            <Box pad={{ horizontal: 'small' }} width="80px">
              <TextInput
                size="medium"
                value={this.state.weeks}
                onChange={this.onChangeWeeks}
                margin="small"
              />
            </Box>
            <Text margin={{ right: '10px' }}>{I18n.t('repeatEveryWeeks')}</Text>
          </Box>
          {!isEmpty(this.state.weeks) && <Checkmark />}
        </Box>
        <Box
          pad="small"
          background={
            colorThemes.getColorTheme(this.props.colorScheme)
              .listItemSelectedColor
          }>
          <SecondaryText>
            {isEmpty(this.state.weeks)
              ? I18n.t('numberOfWeeksShouldBeAtLeastOne')
              : I18n.t('alarmWillRingEveryNWeeks', {
                  weekWithOrdinal: Utils.getNumberWithOrdinal(weeksInt),
                  count: weeksInt - 1
                })}
          </SecondaryText>
        </Box>
      </Box>
    )
  }
}

const mapStateToProps = state => ({
  colorScheme: state.userSettings.webColorScheme
})

export default connect(mapStateToProps)(SelectWeeksRepetition)
