import { OrderedList, List } from 'grommet-icons'
import { Box, Button } from 'grommet'
import React from 'react'
import { useSelector } from 'react-redux'
import { colorThemes } from 'galarm-config'

const TextAreaToolbarListComponent = props => {
  const onClickOrderedList = () => {
    const { onChange } = props
    onChange('ordered')
  }
  const onClickUnOrderedList = () => {
    const { onChange } = props
    onChange('unordered')
  }
  const currentState = props.currentState
  const colorScheme = useSelector(state => state.userSettings.webColorScheme)

  return (
    <Box direction="row" pad={{ left: 'small' }} gap="small">
      <Box
        border={
          currentState.listType === 'ordered'
            ? { color: colorThemes.getColorTheme(colorScheme).darkTint }
            : false
        }
        round={'small'}>
        <Button
          icon={<OrderedList color="primary" />}
          onClick={onClickOrderedList}
        />
      </Box>
      <Box
        border={
          currentState.listType === 'unordered'
            ? { color: colorThemes.getColorTheme(colorScheme).darkTint }
            : false
        }
        round={'small'}>
        <Button
          icon={<List color="primary" />}
          onClick={onClickUnOrderedList}
        />
      </Box>
    </Box>
  )
}

export default TextAreaToolbarListComponent
