import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Text, Box, Button, Header } from 'grommet'
import { GiftedChat, Bubble, utils } from 'galarm-gifted-chat'
import { colorThemes, GlobalConfig } from 'galarm-config'
import {
  ActionCreators,
  DateTimeUtils,
  Selectors,
  NetworkUtils
} from 'galarm-shared'
import { FirebaseProxy, NavigationUtils } from 'galarm-ps-api'
import { I18n } from 'galarm-config'
import debounce from 'lodash/debounce'
import { Constants } from 'galarm-config'
import moment from 'moment-timezone'
import isEmpty from 'lodash/isEmpty'
import TintedImage from './TintedImage'
import AppCanvas from './AppCanvas'
import ParsedLabelledText from './ParsedLabelledText'
import { Close } from 'grommet-icons'

const { makeAlarmConversationSelector, makeAlarmSelector } = Selectors

const AlarmChatHeader = ({ alarm }) => {
  const dispatch = useDispatch()

  if (isEmpty(alarm)) {
    return null
  }

  return (
    <Header className="header" background="brand" pad="small">
      <Text color="white">
        {I18n.t('messagesForAlarm', { name: alarm.name })}
      </Text>
      <Button
        icon={<Close />}
        onClick={() => dispatch(ActionCreators.hideAlarmConversationScreen())}
      />
    </Header>
  )
}

const AlarmConversationContainer = ({ alarmId }) => {
  const alarmConversationSelector = makeAlarmConversationSelector()
  const alarmSelector = makeAlarmSelector()
  const dispatch = useDispatch()
  const alarmConversation = useSelector(state =>
    alarmConversationSelector(state, { alarmId: alarmId })
  )
  const alarm = useSelector(state => alarmSelector(state, { alarmId: alarmId }))
  const isAuthenticated = useSelector(
    state => state.appState.authenticatedWithFirebase
  )

  const isInactiveAlarm = !isEmpty(alarm) && !alarm.status
  const pendingConversationMessage = useSelector(
    state => state.pendingConversationMessages.alarms[alarmId]
  )

  const resetUserIsCurrentlyTyping = () => {
    dispatch(ActionCreators.resetUserCurrentlyTyping(alarmId))
  }

  const resetTypingCall = debounce(
    resetUserIsCurrentlyTyping,
    GlobalConfig.resetUserIsTypingInterval,
    { maxWait: GlobalConfig.maxResetUserIsTypingInterval }
  )

  const colorScheme = useSelector(state => state.userSettings.webColorScheme)

  useEffect(() => {
    dispatch(ActionCreators.loadLatestConversationMessagesForAlarm(alarmId))
    dispatch(ActionCreators.loadCurrentlyTypingUsers(alarmId))
    dispatch(ActionCreators.resetUnseenMessagesForAlarm(alarmId))
    FirebaseProxy.logEvent(Constants.UserAnalyticsEvents.OPEN_CHAT, {})

    // returned function will be called on component unmount
    return () => {
      dispatch(ActionCreators.unloadCurrentlyTypingUsers(alarmId))
      resetTypingCall.flush()
    }
  }, [])

  const renderBubble = props => {
    return (
      <Bubble
        {...props}
        wrapperStyle={{
          left: {
            backgroundColor: 'lightTint',
            borderRadius: 3,
            elevation: 1,
            shadowColor: 'tileShadowColor',
            shadowOpacity: 0.5,
            shadowOffset: {
              height: 1
            },
            shadowRadius: 1,
            marginLeft: 10,
            alignItems: 'flex-start'
          },
          right: {
            backgroundColor: 'lightBlueGreyTint',
            borderRadius: 3,
            elevation: 1,
            shadowColor: 'tileShadowColor',
            shadowOpacity: 0.5,
            shadowOffset: {
              height: 1
            },
            shadowRadius: 1,
            marginRight: 10,
            alignItems: 'flex-end'
          }
        }}
        textStyle={{
          left: {
            marginTop: 2,
            marginBottom: 0,
            marginLeft: 5,
            marginRight: 5,
            color: colorThemes.getColorTheme(colorScheme).textColor,
            justifyContent: 'flex-start',
            whiteSpace: 'pre-line'
          },
          right: {
            marginTop: 2,
            marginBottom: 0,
            marginLeft: 5,
            marginRight: 5,
            color: colorThemes.getColorTheme(colorScheme).textColor,
            justifyContent: 'flex-start',
            whiteSpace: 'pre-line'
          }
        }}
        timeTextStyle={{
          left: {
            color: 'darkTint',
            marginTop: 2,
            marginBottom: 5,
            marginLeft: 5,
            marginRight: 5,
            fontSize: 12,
            backgroundColor: 'transparent',
            textAlign: 'right'
          },
          right: {
            color: 'darkTint',
            marginTop: 2,
            marginBottom: 5,
            marginLeft: 5,
            marginRight: 5,
            fontSize: 12,
            backgroundColor: 'transparent',
            textAlign: 'right'
          }
        }}
      />
    )
  }

  const onClickSend = sendProps => {
    sendProps.onSend({ text: sendProps.text.trim() }, true)
    dispatch(
      ActionCreators.updatePendingConversationMessageForAlarm(alarmId, '')
    )
  }

  const onSend = messages => {
    const message = messages[0]
    dispatch(ActionCreators.addMessageToAlarmConversation(alarmId, message))
    resetTypingCall.flush()
  }

  const onLoadEarlier = () => {
    NetworkUtils.withConnectAndAuthentication(
      onLoadEarlierCore,
      true,
      isAuthenticated,
      I18n.t('cantLoadEarlierMessages'),
      I18n.t('deviceOffline'),
      dispatch
    )
  }

  const onLoadEarlierCore = () => {
    dispatch(ActionCreators.loadEarlierConversationMessagesForAlarm(alarmId))
  }

  const renderDay = dayProps => {
    if (!utils.isSameDay(dayProps.currentMessage, dayProps.previousMessage)) {
      return (
        <Box align="center" justify="center" margin="xsmall">
          <Text
            style={{
              color: colorThemes.getColorTheme(colorScheme).textColor,
              fontSize: 12,
              fontWeight: 'bold'
            }}>
            {DateTimeUtils.isDateToday(dayProps.currentMessage.createdAt)
              ? I18n.t('todayString')
              : DateTimeUtils.getDateAsString(
                  dayProps.currentMessage.createdAt
                )}
          </Text>
        </Box>
      )
    }
    return null
  }

  const renderParsedMessageText = textProps => {
    return (
      <ParsedLabelledText
        style={textProps.textStyle[textProps.position]}
        label={''}
        text={textProps.currentMessage.text}></ParsedLabelledText>
    )
  }

  const renderSend = sendProps => {
    if (sendProps.text.trim().length > 0) {
      return (
        <Box
          align="center"
          justify="center"
          onClick={() => {
            if (isInactiveAlarm) {
              NavigationUtils.showAlert(
                I18n.t('cantSendMessage'),
                I18n.t('sendMessageNotAllowedForPastAlarms')
              )
              return
            }
            NetworkUtils.withConnectAndAuthentication(
              onClickSend,
              true,
              isAuthenticated,
              I18n.t('cantSendMessage'),
              I18n.t('deviceOffline'),
              dispatch,
              sendProps
            )
          }}>
          <TintedImage
            src={require('galarm-res/img/web/ic_send.svg').default}
            tintColor={!isInactiveAlarm ? 'primary' : 'darkTint'}
          />
        </Box>
      )
    }
    return null
  }

  const onInputTextChanged = text => {
    dispatch(
      ActionCreators.updatePendingConversationMessageForAlarm(alarmId, text)
    )
    dispatch(ActionCreators.updateUserCurrentlyTyping(alarmId))
    resetTypingCall()
  }

  // eslint-disable-next-line no-unused-vars
  const renderFooter = footerProps => {
    if (
      alarmConversation.currentlyTypingUsers &&
      alarmConversation.currentlyTypingUsers.length > 0
    ) {
      return (
        <Text
          size="small"
          margin={{ horizontal: 'small' }}
          color="darkTint"
          style={{
            fontStyle: 'italic'
          }}>
          {I18n.t('currentlyTypingUsers', {
            currentlyTypingUsers:
              alarmConversation.currentlyTypingUsers.join(', '),
            count: alarmConversation.currentlyTypingUsers.length
          })}
        </Text>
      )
    }
    return null
  }

  const renderTime = ({ currentMessage, timeTextStyle, position }) => {
    const timeFormat = GlobalConfig.timeFormat || GlobalConfig.defaultTimeFormat
    let format =
      timeFormat === Constants.TimeFormats.TWELVE_HOUR_FORMAT
        ? 'h:mm A'
        : 'H:mm'
    return (
      <Text style={timeTextStyle[position]}>
        {moment(currentMessage.createdAt).format(format)}
      </Text>
    )
  }

  return (
    <AppCanvas key={alarmId} overflow="auto">
      <AlarmChatHeader alarm={alarm} />
      <Box style={{ flex: 1, paddingBottom: 5 }}>
        <GiftedChat
          messages={alarmConversation.messages}
          onSend={onSend}
          loadEarlier={alarmConversation.loadEarlier}
          onLoadEarlier={onLoadEarlier}
          isLoadingEarlier={alarmConversation.isLoadingEarlier}
          renderBubble={renderBubble}
          renderAvatar={null}
          renderDay={renderDay}
          renderSend={renderSend}
          renderFooter={renderFooter}
          renderParsedMessageText={renderParsedMessageText}
          onInputTextChanged={onInputTextChanged}
          initialChatText={pendingConversationMessage}
          renderTime={renderTime}
          user={{
            _id: GlobalConfig.uid
          }}
          keyboardShouldPersistTaps={'never'}
          placeholder={
            isInactiveAlarm
              ? I18n.t('cantPostMessageToPastAlarm')
              : I18n.t('typeYourMessage')
          }
          textInputProps={{
            editable: isInactiveAlarm ? false : true
          }}
        />
      </Box>
    </AppCanvas>
  )
}

export default AlarmConversationContainer
