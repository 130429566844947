import ActionTypes from '../actions/types'
import { createReducer } from 'reduxsauce'

const INITIAL_STATE = {
  alarms: {},
  feedback: '',
  problem: ''
  // groups: {} We can add groups later on if needed
}

const updatePendingConversationMessageForAlarm = (state, action) => {
  const alarmId = action.payload.alarmId
  const pendingMessage = action.payload.pendingMessage
  return {
    ...state,
    alarms: {
      ...state.alarms,
      [alarmId]: pendingMessage
    }
  }
}

const updatePendingFeedback = (state, action) => {
  return {
    ...state,
    feedback: action.payload.feedback
  }
}

const updatePendingProblem = (state, action) => {
  return {
    ...state,
    problem: action.payload.problem
  }
}

const ACTION_HANDLERS = {
  [ActionTypes.UPDATE_PENDING_CONVERSATION_MESSAGE_FOR_ALARM]:
    updatePendingConversationMessageForAlarm,
  [ActionTypes.UPDATE_PENDING_FEEDBACK]: updatePendingFeedback,
  [ActionTypes.UPDATE_PENDING_PROBLEM]: updatePendingProblem
}

export default createReducer(INITIAL_STATE, ACTION_HANDLERS)
