import { Button } from 'grommet'
import React from 'react'
import { ThemeContext } from 'styled-components'

const ColoredButton = ({ buttonTextColor = 'white', ...restProps }) => {
  return (
    <ThemeContext.Extend
      value={{
        global: {
          colors: {
            text: buttonTextColor
          }
        }
      }}>
      <Button {...restProps} />
    </ThemeContext.Extend>
  )
}

export default ColoredButton
