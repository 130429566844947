import React from 'react'
import AlarmDetails from './AlarmDetails'
import GroupDetails from './GroupDetails'
import ContactDetails from './ContactDetails'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Text } from 'grommet'
import NewAlarmContainer from './NewAlarmContainer'
import NewGroupContainer from './NewGroupContainer'
import AlarmConversationContainer from './AlarmConversationContainer'
import EditAlarm from './EditAlarm'
import SelectAlarmType from './SelectAlarmType'
import ReportAProblem from './ReportAProblem'
import EditAlarmContainer from './EditAlarmContainer'
import ProfileAndSettingsScreen from './ProfileAndSettingsScreen'
import ParticipantAlarmDetails from './ParticipantAlarmDetails'
import { ActionCreators } from 'galarm-shared'
import EditGroupContainer from './EditGroupContainer'
import NewInstantAlarmContainer from './NewInstantAlarmContainer'
import AlarmPreviousOccurrences from './AlarmPreviousOccurrences'
import CalendarView from './CalendarView'
import ChangeGroupMemberStateContainer from './ChangeGroupMemberStateContainer'
import EditInstantAlarm from './EditInstantAlarm'
import InstantAlarmDetails from './InstantAlarmDetails'
import InstantParticipantAlarmDetails from './InstantParticipantAlarmDetails'
import DuplicateAlarmContainer from './DuplicateAlarmContainer'

const InspectorPanel = () => {
  const dispatch = useDispatch()
  const inspectorPanelConfig = useSelector(
    state => state.appState.inspectorPanelConfig
  )

  if (inspectorPanelConfig.newAlarmWizard.visible) {
    return (
      <NewAlarmContainer
        targetComponent={EditAlarm}
        {...inspectorPanelConfig.newAlarmWizard}
        onClose={() => dispatch(ActionCreators.closeNewAlarmWizard())}
      />
    )
  } else if (inspectorPanelConfig.duplicateAlarmWizard.visible) {
    return (
      <DuplicateAlarmContainer
        targetComponent={EditAlarm}
        {...inspectorPanelConfig.duplicateAlarmWizard}
        onClose={() => dispatch(ActionCreators.closeDuplicateAlarmWizard())}
      />
    )
  } else if (inspectorPanelConfig.editAlarmWizard.visible) {
    return (
      <EditAlarmContainer
        targetComponent={EditAlarm}
        alarmId={inspectorPanelConfig.editAlarmWizard.alarmId}
        onClose={() => dispatch(ActionCreators.closeEditAlarmWizard())}
      />
    )
  } else if (inspectorPanelConfig.newGroupWizard.visible) {
    return <NewGroupContainer />
  } else if (inspectorPanelConfig.editGroupWizard.visible) {
    return (
      <EditGroupContainer
        groupId={inspectorPanelConfig.editGroupWizard.groupId}
      />
    )
  } else if (inspectorPanelConfig.alarmConversationScreen.visible) {
    // View conversation should be before alarm details
    return (
      <AlarmConversationContainer
        alarmId={inspectorPanelConfig.alarmConversationScreen.alarmId}
      />
    )
  } else if (inspectorPanelConfig.alarmHistoryScreen.visible) {
    // Alarm history should be before alarm details
    return (
      <AlarmPreviousOccurrences
        alarm={inspectorPanelConfig.alarmHistoryScreen.alarm}
      />
    )
  } else if (inspectorPanelConfig.alarmDetailsScreen.visible) {
    return (
      <AlarmDetails alarmId={inspectorPanelConfig.alarmDetailsScreen.alarmId} />
    )
  } else if (inspectorPanelConfig.instantAlarmDetailsScreen.visible) {
    return (
      <InstantAlarmDetails
        alarmId={inspectorPanelConfig.instantAlarmDetailsScreen.alarmId}
      />
    )
  } else if (
    inspectorPanelConfig.instantParticipantAlarmDetailsScreen.visible
  ) {
    return (
      <InstantParticipantAlarmDetails
        alarmId={
          inspectorPanelConfig.instantParticipantAlarmDetailsScreen.alarmId
        }
      />
    )
  } else if (inspectorPanelConfig.participantAlarmDetailsScreen.visible) {
    return (
      <ParticipantAlarmDetails
        alarmId={inspectorPanelConfig.participantAlarmDetailsScreen.alarmId}
      />
    )
  } else if (inspectorPanelConfig.changeMemberStateScreen.visible) {
    // Should be before GroupDetails
    return (
      <ChangeGroupMemberStateContainer
        groupId={inspectorPanelConfig.changeMemberStateScreen.groupId}
        groupName={inspectorPanelConfig.changeMemberStateScreen.groupName}
        members={inspectorPanelConfig.changeMemberStateScreen.members}
        updateMemberState={
          inspectorPanelConfig.changeMemberStateScreen.updateMemberState
        }
        memberToChange={
          inspectorPanelConfig.changeMemberStateScreen.memberToChange
        }
        onClose={() =>
          dispatch(ActionCreators.closeChangeGroupMemberStateScreen())
        }
      />
    )
  } else if (inspectorPanelConfig.groupDetailsScreen.visible) {
    return (
      <GroupDetails groupId={inspectorPanelConfig.groupDetailsScreen.groupId} />
    )
  } else if (inspectorPanelConfig.contactDetailsScreen.visible) {
    return (
      <ContactDetails
        contactId={inspectorPanelConfig.contactDetailsScreen.contactId}
      />
    )
  }
  if (inspectorPanelConfig.reportAProblemScreen.visible) {
    return <ReportAProblem />
  } else if (inspectorPanelConfig.profileAndSettingsScreen.visible) {
    return <ProfileAndSettingsScreen />
  }
  // This should always be the last one here as this is the default screen
  else if (inspectorPanelConfig.selectAlarmTypeScreen.visible) {
    return <SelectAlarmType />
  } else if (inspectorPanelConfig.newInstantAlarmWizard.visible) {
    return (
      <NewInstantAlarmContainer
        targetComponent={EditInstantAlarm}
        {...inspectorPanelConfig.newInstantAlarmWizard}
        onClose={() => dispatch(ActionCreators.closeInstantAlarmWizard())}
      />
    )
  } else if (inspectorPanelConfig.calendarScreen.visible) {
    return <CalendarView />
  } else {
    return (
      <Box flex={true} justify="center" align="center">
        <Text>
          {'Keep your phone connected to view/edit alarms on your machine'}
        </Text>
      </Box>
    )
  }
}

export default InspectorPanel
