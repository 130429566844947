import React from 'react'
import { useSelector } from 'react-redux'
import { List, Box, Text } from 'grommet'
import UnifiedAlarmSummary from './UnifiedAlarmSummary'
import { I18n, colorThemes } from 'galarm-config'

const RecentlyDeletedAlarmList = ({ alarms }) => {
  const colorScheme = useSelector(state => state.userSettings.webColorScheme)

  if (!alarms || alarms.length === 0) {
    return (
      <Box pad="medium" flex justify="center" align="center">
        <Text color={colorThemes.getColorTheme(colorScheme).textColor}>
          {I18n.t('noRecentlyDeletedAlarms')}
        </Text>
      </Box>
    )
  }

  return (
    <Box>
      <Text color="darkTint" margin={{ horizontal: 'medium', top: 'small' }}>
        {I18n.t('restoreAlarmsFromMobileApp')}
      </Text>
      <List
        border={false}
        margin={{ vertical: 'small' }}
        pad={{ vertical: 'xsmall', horizontal: 'small' }}
        data={alarms}
        // eslint-disable-next-line react/no-children-prop
        children={UnifiedAlarmSummary}
      />
    </Box>
  )
}

export default RecentlyDeletedAlarmList
