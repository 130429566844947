import React from 'react'
import { Box, Button, TextInput, Text } from 'grommet'
import { useSelector } from 'react-redux'
import { colorThemes, I18n } from 'galarm-config'
import { Close } from 'grommet-icons'

const SearchBar = props => {
  const colorScheme = useSelector(state => state.userSettings.webColorScheme)

  const resetSearchBox = () => {
    if (props.searchText) {
      props.onChangeText('')
    }
  }

  const onChangeText = newText => {
    props.onChangeText(newText)
  }

  return (
    <Box direction="row" pad={{ horizontal: 'small' }}>
      <Box
        flex
        round="small"
        direction="row"
        align="center"
        background={colorThemes.getColorTheme(colorScheme).lightTint}>
        <TextInput
          autoFocus={props.focus}
          plain
          value={props.searchText}
          placeholder={I18n.t('search')}
          onChange={event => onChangeText(event.target.value)}
          style={{ color: colorThemes.getColorTheme(colorScheme).textColor }}
        />
        {!!props.searchText && (
          <Button icon={<Close />} onClick={() => resetSearchBox()} />
        )}
      </Box>
      {props.onCloseSearchBar && (
        <Box
          onClick={props.onCloseSearchBar}
          pad={{ left: 'small' }}
          justify="center">
          <Text color={colorThemes.getColorTheme(colorScheme).primary}>
            {I18n.t('cancel')}
          </Text>
        </Box>
      )}
    </Box>
  )
}
export default SearchBar
