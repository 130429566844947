import React from 'react'
import { I18n } from 'galarm-config'
import { Text, Box, List, Avatar } from 'grommet'
import { Constants, colorThemes } from 'galarm-config'
import { NavigationUtils, LogUtils } from 'galarm-ps-api'
import { useDispatch, useSelector } from 'react-redux'
import TintedImage from './TintedImage'
import RightClickMenuProvider from './RightClickMenuProvider'
import { GlobalConfig } from 'galarm-config'
import { FirebaseProxy } from 'galarm-ps-api'
import { ActionCreators, TaskManager, Utils, Selectors } from 'galarm-shared'

const { ownAlarmsSelector, makeContactThumbnailSelector } = Selectors

const MemberSummary = ({ item, getContextMenuOptions }) => {
  const contactThumbnailSelector = makeContactThumbnailSelector()
  const avatarThumbnailUrl = useSelector(state =>
    contactThumbnailSelector(state, { id: item.id })
  )
  const colorScheme = useSelector(state => state.userSettings.webColorScheme)

  let memberName = item.name
  if (item.id === GlobalConfig.uid) {
    memberName = I18n.t('you')
  } else if (item.known === false) {
    memberName = '~' + memberName
  }

  if (avatarThumbnailUrl) {
    return (
      <RightClickMenuProvider contextMenuOptions={getContextMenuOptions(item)}>
        <Box direction="row" gap="small" align="center">
          <Avatar src={avatarThumbnailUrl.uri} />
          <Text color={item.known === false ? 'darkTint' : 'textColor'}>
            {memberName}
          </Text>
          {item.role === Constants.ADMIN_ROLE && (
            <Box flex align="end" justify="end">
              <Text color={colorThemes.getColorTheme(colorScheme).darkTint}>
                {I18n.t('admin')}
              </Text>
            </Box>
          )}
        </Box>
      </RightClickMenuProvider>
    )
  }

  return (
    <RightClickMenuProvider contextMenuOptions={getContextMenuOptions(item)}>
      <Box direction="row" gap="small" align="center">
        <Avatar background="mediumTint">
          <TintedImage
            tintColor="lightTint"
            src={require('galarm-res/img/web/ic_person.svg').default}
          />
        </Avatar>
        <Text color={item.known === false ? 'darkTint' : 'textColor'}>
          {memberName}
        </Text>
        {item.role === Constants.ADMIN_ROLE && (
          <Box flex align="end" justify="end">
            <Text color={colorThemes.getColorTheme(colorScheme).darkTint}>
              {I18n.t('admin')}
            </Text>
          </Box>
        )}
      </Box>
    </RightClickMenuProvider>
  )
}

const MembersListWithAvatar = ({ group }) => {
  const ownAlarms = useSelector(ownAlarmsSelector)
  const dispatch = useDispatch()

  const members = group.members.slice().sort((member1, member2) => {
    return member1.order - member2.order
  })

  const impactedAlarms = ownAlarms.filter(alarm => {
    return (
      alarm.status === true &&
      (alarm.date > Date.now() || alarm.repeatType !== '') &&
      !!alarm.backupGroup &&
      alarm.backupGroup.id === group.id
    )
  })

  const allowEdit = () => {
    return (
      members.filter(member => {
        return (
          member.id === GlobalConfig.uid && member.role === Constants.ADMIN_ROLE
        )
      }).length > 0
    )
  }

  const removeGroupMember = member => {
    const impactedAlarmsWithMember = impactedAlarms.filter(alarm => {
      const alarmMember = Utils.getObjectWithId(
        alarm.backupGroup.members,
        member.id
      )
      return (
        alarmMember &&
        alarmMember.state !== Constants.ParticipantStates.INACTIVE
      )
    })
    if (impactedAlarmsWithMember.length > 0) {
      const alertMessage = createAlert('Remove Member') // Don't i18n this Remove Member here
      NavigationUtils.showAlert(I18n.t('confirm'), alertMessage, [
        {
          text: I18n.t('no')
        },
        {
          text: I18n.t('yes'),
          onPress: () => {
            onRemoveMember(member)
          }
        }
      ])
    } else {
      NavigationUtils.showAlert(
        I18n.t('confirm'),
        I18n.t('removeMemberFromGroup', { name: member.name }),
        [
          {
            text: I18n.t('no')
          },
          {
            text: I18n.t('yes'),
            onPress: () => {
              onRemoveMember(member)
            }
          }
        ]
      )
    }
  }

  const onRemoveMember = member => {
    NavigationUtils.showProgress(
      Constants.ProgressStates.IN_PROGRESS,
      I18n.t('removingMember')
    )

    TaskManager.addHttpsCloudTask('removeGroupMember', {
      id: group.id,
      uid: GlobalConfig.uid,
      username: member.name,
      removedMemberId: member.id,
      removedMemberName: member.name,
      name: group.name
    })
      .then(() => {
        NavigationUtils.dismissProgress()
      })
      .catch(error => {
        dispatch(
          ActionCreators.setProgress(
            Constants.ProgressStates.ERROR,
            I18n.t('unableToRemoveMember', { error: error.error }),
            true
          )
        )
        LogUtils.logError(new Error(error.error), 'Unable to remove member')
      })

    FirebaseProxy.logEvent(
      Constants.UserAnalyticsEvents.REMOVE_MEMBER_FROM_GROUP,
      {}
    )
  }

  const updateMemberState = (memberToChangeId, membersConfiguration) => {
    let firebaseUpdateObj = {}
    membersConfiguration.forEach(member => {
      firebaseUpdateObj[
        'userInfos/' +
          member.id +
          '/groups/' +
          group.id +
          '/members/' +
          memberToChangeId +
          '/state'
      ] = member.state
      firebaseUpdateObj[
        'userInfos/' + member.id + '/groups/' + group.id + '/lastUpdatedAt'
      ] = Date.now()
    })

    GlobalConfig.rootFirebaseRef.update(firebaseUpdateObj).catch(error => {
      LogUtils.logError(error, 'Unable to update member state')
    })
  }

  const changeMemberState = memberToChange => {
    dispatch(
      ActionCreators.showChangeMemberStateScreen(
        group.id,
        group.name,
        members,
        updateMemberState,
        memberToChange
      )
    )
  }

  const getContextMenuOptions = member => {
    const items = getItemsAndInfoForContextMenuForMember(member)

    const ctxOptions = {
      items: items,
      handlers: {
        [I18n.t('removeMember', { memberName: member.name })]: () =>
          removeGroupMember(member),
        [I18n.t('changeMemberState', { memberName: member.name })]: () =>
          changeMemberState(member),
        [I18n.t('leaveGroup')]: () => {
          onLeaveGroup()
        },
        [I18n.t('viewMember', { memberName: member.name })]: () => {
          dispatch(ActionCreators.showContactDetailsScreen(member.id))
        },
        [I18n.t('changeYourGroupState')]: () => changeMemberState(member)
      }
    }
    return ctxOptions
  }

  const onLeaveGroupCore = member => {
    dispatch(
      ActionCreators.setProgress(
        Constants.ProgressStates.IN_PROGRESS,
        I18n.t('leavingGroup')
      )
    )
    TaskManager.addHttpsCloudTask('leaveGroup', {
      id: group.id,
      uid: GlobalConfig.uid, // Same as member.id
      username: member.name, // Same as member.name
      name: group.name,
      role: member.role
    })
      .then(() => {
        dispatch(ActionCreators.hideGroupDetailsScreen())
        NavigationUtils.dismissProgress()
      })
      .catch(error => {
        dispatch(
          ActionCreators.setProgress(
            Constants.ProgressStates.ERROR,
            I18n.t('unableToLeaveGroup', { error: error.error }),
            true
          )
        )
        LogUtils.logError(new Error(error.error), 'Unable to leave group')
      })

    FirebaseProxy.logEvent(Constants.UserAnalyticsEvents.LEAVE_GROUP, {})
  }

  const onLeaveGroup = () => {
    const member = Utils.getObjectWithId(members, GlobalConfig.uid)
    if (!member) {
      console.log(
        'Unable to leave group because you are not a member of the group ' +
          group.name
      )
      return
    }

    let alertMessage = I18n.t('leaveGroupConfirm')

    if (impactedAlarms.length > 0) {
      const alarmNames = impactedAlarms.map(alarm => alarm.name).join('\n')
      alertMessage = I18n.t('leaveGroupAlert', { alarmNames })
    }

    NavigationUtils.showAlert(I18n.t('confirm'), alertMessage, [
      {
        text: I18n.t('no')
      },
      {
        text: I18n.t('yes'),
        onPress: () => {
          onLeaveGroupCore(member)
        }
      }
    ])
  }

  const createAlert = command => {
    const alarmNames = impactedAlarms.map(alarm => alarm.name).join('\n')
    switch (command) {
      case 'Remove Member':
        return I18n.t('removeGroupMemberAlert', { alarmNames })

      case 'Leave Group':
        return I18n.t('leaveGroupAlert', { alarmNames })
      default:
        return I18n.t('leaveGroupAlert', { alarmNames })
    }
  }

  const getItemsAndInfoForContextMenuForMember = member => {
    let items = []

    if (member.id === GlobalConfig.uid) {
      if (allowEdit()) {
        items = [I18n.t('leaveGroup'), I18n.t('changeYourGroupState')]
      } else {
        items = [I18n.t('leaveGroup')]
      }
    } else if (allowEdit()) {
      if (member.known !== false) {
        items = [
          I18n.t('viewMember', { memberName: member.name }),
          I18n.t('removeMember', { memberName: member.name }),
          I18n.t('changeMemberState', { memberName: member.name })
        ]
      } else {
        items = [
          I18n.t('removeMember', { memberName: member.name }),
          I18n.t('changeMemberState', { memberName: member.name })
        ]
      }
    } else {
      if (member.known !== false) {
        items = [I18n.t('viewMember', { memberName: member.name })]
      }
    }
    return items
  }

  if (members.length === 0) {
    return (
      <Text margin={{ horizontal: '10px', vertical: '5px' }}>
        {I18n.t('none')}
      </Text>
    )
  }

  if (members.length > 0) {
    return (
      <List data={members} border={false} margin="xsmall" pad="xsmall">
        {item => {
          return (
            <MemberSummary
              item={item}
              getContextMenuOptions={getContextMenuOptions}
            />
          )
        }}
      </List>
    )
  }

  return <Box />
}

export default MembersListWithAvatar
