import React from 'react'
import { Box } from 'grommet'
import { useSelector } from 'react-redux'
import { Constants } from 'galarm-config'

const AppCanvas = ({ children, ...restProps }) => {
  let colorScheme = useSelector(state => state.userSettings.webColorScheme)

  if (colorScheme === Constants.COLOR_SCHEMES.AUTO_DARK) {
    colorScheme = Constants.COLOR_SCHEMES.DARK
  } else if (colorScheme === Constants.COLOR_SCHEMES.AUTO_LIGHT) {
    colorScheme = Constants.COLOR_SCHEMES.LIGHT
  }

  const imgUrl =
    colorScheme === Constants.COLOR_SCHEMES.LIGHT
      ? 'url(./img/galarm_bg.png)'
      : 'url(./img/galarm_bg_dark.png)'

  return (
    <Box
      fill
      background={{
        size: 'contain',
        image: imgUrl,
        repeat: 'repeat'
      }}
      {...restProps}>
      {children}
    </Box>
  )
}

export default AppCanvas
