import React, { useRef, useState, useEffect } from 'react'
import { I18n } from 'galarm-config'
import { Constants } from 'galarm-config'
import { colorThemes } from 'galarm-config'
import { Checkmark } from 'grommet-icons'
import { AlarmUtils } from 'galarm-shared'
import { Box, Text, TextInput } from 'grommet'
import isEmpty from 'lodash/isEmpty'
import { useSelector } from 'react-redux'
import { SecondaryText } from 'web-components'

const SelectEveryMHoursNMinutesRepetition = props => {
  const everyNHoursRef = useRef(null)
  const everyNMinutesRef = useRef(null)

  const colorScheme = useSelector(state => state.userSettings.webColorScheme)

  const [hours, setHours] = useState(
    props.repeat ? props.repeat.split(':')[0] || '0' : '2'
  )
  const [minutes, setMinutes] = useState(
    props.repeat ? props.repeat.split(':')[1] || '0' : '30'
  )

  useEffect(() => {
    onSaveRepetition()
  }, [hours, minutes])

  const onSaveRepetition = () => {
    const hoursInt = parseInt(hours) || 0
    const minutesInt = parseInt(minutes) || 0
    let repeatString = hoursInt + ':' + minutesInt
    // If we have 0 hours, 0 minutes or empty hours and minutes then we want to set the default to 2:30
    if (hoursInt + minutesInt === 0) {
      repeatString = '2:30'
    }
    props.onSaveAlarmRepetition(
      Constants.RepeatTypes.EVERY_M_HOURS_AND_N_MINUTES,
      repeatString
    )
  }

  const onChangeHours = event => {
    everyNHoursRef.current?.focus()
    const hoursInt = parseInt(event.target.value)
    // Check if hoursInt is a number
    const newHours = !isNaN(hoursInt) ? hoursInt.toString() : ''
    setHours(newHours)
  }

  const onChangeMinutes = event => {
    everyNMinutesRef.current?.focus()
    const minutesInt = parseInt(event.target.value)
    const newMinutes = !isNaN(minutesInt) ? minutesInt.toString() : ''
    setMinutes(newMinutes)
  }

  let selectedOption = 1
  let parsedHours = parseInt(hours),
    parsedMinutes = parseInt(minutes)

  const parts = []

  parts.push(I18n.t('hoursString', { count: parsedHours }))

  parts.push(I18n.t('minutesString', { count: parsedMinutes }))

  const occurences = AlarmUtils.getNextOccurrencesForHoursAndMinutesRepetition(
    props.date,
    hours,
    minutes,
    5
  )
  // Pop the very first occurence
  const otherOccurences = occurences.slice(1)
  return (
    <Box width="500px">
      <Box direction="column">
        <Box
          direction="row"
          pad="small"
          justify="between"
          align="center"
          background={
            selectedOption === 1
              ? colorThemes.getColorTheme(colorScheme).listItemSelectedColor
              : colorThemes.getColorTheme(colorScheme).screenBackgroundColor
          }>
          <Box direction="row" align="center" justify="center" gap="small">
            <Text>{I18n.t('repeatEvery')}</Text>
            <TextInput
              size="small"
              value={hours}
              onChange={onChangeHours}
              margin="small"
              ref={everyNHoursRef}
            />
            <Text margin={{ right: '10px' }}>{I18n.t('snoozeUnitHours')}</Text>
            <TextInput
              size="small"
              value={minutes}
              onChange={onChangeMinutes}
              margin="small"
              ref={everyNMinutesRef}
            />
            <Text margin={{ right: '10px' }}>
              {I18n.t('snoozeUnitMinutes')}
            </Text>
          </Box>
          {selectedOption === 1 && <Checkmark />}
        </Box>
        {selectedOption === 1 && (
          <Box
            pad="small"
            background={
              colorThemes.getColorTheme(colorScheme).listItemSelectedColor
            }>
            <SecondaryText>
              {(isEmpty(hours) || hours === '0') &&
              (isEmpty(minutes) || minutes === '0')
                ? I18n.t('selectAtLeastOneHourOrMinute')
                : I18n.t('alarmWillRingEveryMHoursAndNMinutes', {
                    repeatString: parts.join(' ').trim(),
                    dayString: occurences[0],
                    otherDaysString: otherOccurences.join('; ')
                  })}
            </SecondaryText>
          </Box>
        )}
      </Box>
    </Box>
  )
}

SelectEveryMHoursNMinutesRepetition.defaultProps = {
  hours: '2',
  minutes: '30'
}

export default SelectEveryMHoursNMinutesRepetition
