import { Box, Text } from 'grommet'
import React from 'react'

const CategoryLabel = ({ name, color }) => {
  return (
    <Box background={color} pad="small" round="small">
      <Text>{name}</Text>
    </Box>
  )
}

export default CategoryLabel
