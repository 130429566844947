import React, { Component, createRef } from 'react'
import { I18n } from 'galarm-config'
import { Constants } from 'galarm-config'
import { colorThemes } from 'galarm-config'
import { connect } from 'react-redux'
import { FirebaseProxy } from 'galarm-ps-api'
import moment from 'moment-timezone'
import { AlarmUtils, DateTimeUtils } from 'galarm-shared'
import isEmpty from 'lodash/isEmpty'
import { Utils } from 'galarm-shared'
import { Box, Text, TextInput } from 'grommet'
import { Checkmark } from 'grommet-icons'
import { SecondaryText } from 'web-components'

class SelectMonthsRepetition extends Component {
  static defaultProps = {
    repeat: '2'
  }

  constructor(props) {
    super(props)
    this.everyNMonthsRef = createRef()
  }

  state = {
    repeat: this.props.repeat
  }

  onChooseMonths = () => {
    console.log('onChooseMonths')
    this.everyNMonthsRef.current?.focus()
    this.setState(
      {
        repeat: '2'
      },
      () => {
        if (this.state.repeat) {
          this.props.onSaveAlarmRepetition(
            Constants.RepeatTypes.MONTHS,
            this.state.repeat
          )
          FirebaseProxy.logEvent(
            Constants.UserAnalyticsEvents.CHOOSE_MONTHLY_REPEAT_TYPE,
            {
              [Constants.UserAnalyticsEventParameters.REPEAT_STRING]:
                Constants.REPEAT_EVERY_N_MONTHS_REPEAT_STRING
            }
          )
        }
      }
    )
  }

  onChangeMonths = event => {
    const newMonthsInt = parseInt(event.target.value)
    this.setState(
      {
        repeat: newMonthsInt ? newMonthsInt.toString() : ''
      },
      () => {
        if (this.state.repeat) {
          this.props.onSaveAlarmRepetition(
            Constants.RepeatTypes.MONTHS,
            this.state.repeat
          )
          FirebaseProxy.logEvent(
            Constants.UserAnalyticsEvents.CHOOSE_MONTHLY_REPEAT_TYPE,
            {
              [Constants.UserAnalyticsEventParameters.REPEAT_STRING]:
                Constants.REPEAT_EVERY_N_MONTHS_REPEAT_STRING
            }
          )
        }
      }
    )
  }

  onChooseLastDayOfMonth = () => {
    this.everyNMonthsRef.current?.blur()
    this.setState(
      {
        repeat: Constants.LAST_DAY_OF_MONTH_REPEAT_STRING
      },
      () => {
        this.props.onSaveAlarmRepetition(
          Constants.RepeatTypes.MONTHS,
          this.state.repeat
        )
        FirebaseProxy.logEvent(
          Constants.UserAnalyticsEvents.CHOOSE_MONTHLY_REPEAT_TYPE,
          {
            [Constants.UserAnalyticsEventParameters.REPEAT_STRING]:
              Constants.LAST_DAY_OF_MONTH_REPEAT_STRING
          }
        )
      }
    )
  }

  onChooseDayOfWeekInMonthRepeatOption = (weekNumberOfMonth, dayName) => {
    this.everyNMonthsRef.current?.blur()
    this.setState(
      {
        repeat:
          Constants.DAY_OF_WEEK_IN_MONTH_REPEAT_STRING +
          ':' +
          weekNumberOfMonth +
          ':' +
          dayName
      },
      () => {
        this.props.onSaveAlarmRepetition(
          Constants.RepeatTypes.MONTHS,
          this.state.repeat
        )
        FirebaseProxy.logEvent(
          Constants.UserAnalyticsEvents.CHOOSE_MONTHLY_REPEAT_TYPE,
          {
            [Constants.UserAnalyticsEventParameters.REPEAT_STRING]:
              Constants.DAY_OF_WEEK_IN_MONTH_REPEAT_STRING
          }
        )
      }
    )
  }

  onChooseLastDayOfWeekInMonthRepeatOption = dayName => {
    this.everyNMonthsRef.current?.blur()
    this.setState(
      {
        repeat:
          Constants.LAST_DAY_OF_WEEK_IN_MONTH_REPEAT_STRING + ':' + dayName
      },
      () => {
        this.props.onSaveAlarmRepetition(
          Constants.RepeatTypes.MONTHS,
          this.state.repeat
        )
        FirebaseProxy.logEvent(
          Constants.UserAnalyticsEvents.CHOOSE_MONTHLY_REPEAT_TYPE,
          {
            [Constants.UserAnalyticsEventParameters.REPEAT_STRING]:
              Constants.LAST_DAY_OF_WEEK_IN_MONTH_REPEAT_STRING
          }
        )
      }
    )
  }

  render() {
    let selectedOption = 0
    let monthsValue = '',
      monthsInt = 0

    if (this.state.repeat === Constants.LAST_DAY_OF_MONTH_REPEAT_STRING) {
      selectedOption = 2
    } else if (
      this.state.repeat.startsWith(Constants.DAY_OF_WEEK_IN_MONTH_REPEAT_STRING)
    ) {
      selectedOption = 3
    } else if (
      this.state.repeat.startsWith(
        Constants.LAST_DAY_OF_WEEK_IN_MONTH_REPEAT_STRING
      )
    ) {
      selectedOption = 4
    } else {
      monthsValue = this.state.repeat
      monthsInt = parseInt(this.state.repeat)
      selectedOption = 1
    }

    const dayNumber = moment(this.props.date).day()
    let dayName = Constants.DayRepetitionOptions[dayNumber]
    let weekNumberOfDayInMonth = AlarmUtils.computeDayOfWeekInMonthForDate(
      this.props.date
    )
    const showLastDayOfMonthRepeatOption =
      AlarmUtils.checkIfDayOfMonthIsLastDayOfWeekInMonth(this.props.date)

    return (
      <Box width="450px">
        <Box
          onClick={this.onChooseMonths}
          direction="row"
          pad="small"
          justify="between"
          align="center"
          background={
            selectedOption === 1
              ? colorThemes.getColorTheme(this.props.colorScheme)
                  .listItemSelectedColor
              : colorThemes.getColorTheme(this.props.colorScheme)
                  .screenBackgroundColor
          }>
          <Box direction="row" align="center">
            <Text>{I18n.t('repeatEvery')}</Text>
            <Box pad={{ horizontal: 'small' }} width="80px">
              <TextInput
                ref={this.everyNMonthsRef}
                size="medium"
                value={monthsValue}
                onChange={this.onChangeMonths}
                margin="small"
              />
            </Box>
            <Text margin={{ right: '10px' }}>
              {I18n.t('repeatEveryMonths')}
            </Text>
          </Box>
          {selectedOption === 1 && <Checkmark />}
        </Box>
        {selectedOption === 1 && (
          <Box
            pad="small"
            background={
              colorThemes.getColorTheme(this.props.colorScheme)
                .listItemSelectedColor
            }>
            <SecondaryText>
              {isEmpty(monthsValue)
                ? I18n.t('numberOfMonthsShouldBeAtLeastOne')
                : I18n.t('alarmWillRingEveryNMonths', {
                    monthWithOrdinal: Utils.getNumberWithOrdinal(monthsInt),
                    count: monthsInt - 1
                  })}
            </SecondaryText>
          </Box>
        )}
        <Box
          direction="row"
          pad="small"
          align="center"
          justify="between"
          onClick={this.onChooseLastDayOfMonth}
          background={
            selectedOption === 2
              ? colorThemes.getColorTheme(this.props.colorScheme)
                  .listItemSelectedColor
              : colorThemes.getColorTheme(this.props.colorScheme)
                  .screenBackgroundColor
          }>
          <Text>{I18n.t('lastDayOfMonth')}</Text>
          {selectedOption === 2 && <Checkmark />}
        </Box>
        <Box
          direction="row"
          pad="small"
          align="center"
          justify="between"
          onClick={() =>
            this.onChooseDayOfWeekInMonthRepeatOption(
              weekNumberOfDayInMonth,
              dayName
            )
          }
          background={
            selectedOption === 3
              ? colorThemes.getColorTheme(this.props.colorScheme)
                  .listItemSelectedColor
              : colorThemes.getColorTheme(this.props.colorScheme)
                  .screenBackgroundColor
          }>
          <Text>
            {I18n.t('dayOfWeekInMonth', {
              weekNumberOfDayInMonth: DateTimeUtils.getDayOfWeekInMonthOrdinal(
                weekNumberOfDayInMonth
              ),
              dayName: I18n.t(dayName)
            })}
          </Text>
          {selectedOption === 3 && <Checkmark />}
        </Box>
        {showLastDayOfMonthRepeatOption && (
          <Box
            direction="row"
            pad="small"
            align="center"
            justify="between"
            onClick={() =>
              this.onChooseLastDayOfWeekInMonthRepeatOption(dayName)
            }
            background={
              selectedOption === 4
                ? colorThemes.getColorTheme(this.props.colorScheme)
                    .listItemSelectedColor
                : colorThemes.getColorTheme(this.props.colorScheme)
                    .screenBackgroundColor
            }>
            <Text>
              {I18n.t('lastDayOfWeekInMonth', {
                dayName: I18n.t(dayName)
              })}
            </Text>
            {selectedOption === 4 && <Checkmark />}
          </Box>
        )}
      </Box>
    )
  }
}

const mapStateToProps = state => ({
  colorScheme: state.userSettings.webColorScheme
})

export default connect(mapStateToProps)(SelectMonthsRepetition)
