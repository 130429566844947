import React from 'react'
import { Avatar, Box, Text } from 'grommet'
import TintedImage from './TintedImage'

const EditAvatar = ({ url, size = 'medium', title }) => {
  if (url) {
    return <Avatar size={size} src={url} />
  }

  return (
    <Avatar size={size} background="mediumTint">
      <TintedImage
        tintColor="lightTint"
        src={require('galarm-res/img/web/ic_camera.svg').default}
      />
      {title && (
        <Box pad="small">
          <Text color="lightTint">{title}</Text>
        </Box>
      )}
    </Avatar>
  )
}

export default EditAvatar
