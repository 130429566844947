import React from 'react'
import { Card, CardBody, Text, Box } from 'grommet'
import { useDispatch } from 'react-redux'
import { ActionCreators } from 'galarm-shared'
import ContactAvatar from './ContactAvatar'
import objGet from 'lodash/get'

const ContactSummary = item => {
  const dispatch = useDispatch()

  return (
    <Card
      onClick={() => {
        dispatch(ActionCreators.showContactDetailsScreen(item.id))
      }}
      background="textBackgroundColor">
      <CardBody pad="small">
        <Box direction="row" gap="small" align="center">
          <ContactAvatar
            url={objGet(item, 'images.avatarThumbnailUrl', undefined)}
            name={item.name}
          />
          <Text>{item.name}</Text>
        </Box>
      </CardBody>
    </Card>
  )
}

export default ContactSummary
