import React, { useEffect, useState } from 'react'
import { colorThemes, Constants, GlobalConfig } from 'galarm-config'
import QRCode from 'qrcode'
import { Box, Image, List, Text, Anchor, Avatar, TextInput } from 'grommet'
import { I18n } from 'galarm-config'
import { FormNextLink } from 'grommet-icons'
import GalarmWebHeader from './GalarmWebHeader'
import TintedImage from './TintedImage'
import CryptoJS from 'crypto-js'
import { FirebaseProxy } from 'galarm-ps-api'
import { useDispatch } from 'react-redux'
import { ActionCreators } from 'galarm-shared'

const LoginScreen = () => {
  // Default to light mode for the login screen
  // as many of the assets such as mobile and laptop
  // don't look good in dark mode
  let colorScheme = Constants.COLOR_SCHEMES.LIGHT

  const dispatch = useDispatch()

  const [url, setUrl] = useState('')
  const [qrCodeValid, setQrCodeValid] = useState(true)

  const [devAuthInfo, setDevAuthInfo] = useState('')

  // Create a location on firebase where we will listen for uid and phone id
  // Encrypt the firebase location and show as a QR code that the mobile app
  // will scan and write the uid and phone id in encrypted format at that location
  const encryptDataAndGenerateQrCode = () => {
    const id = GlobalConfig.rootFirebaseRef
      .child('galarmOnWebHandshake')
      .push().key
    startListeningForAuthInfo(id)

    var encryptedData = CryptoJS.AES.encrypt(
      `galarmOnWebHandshake:${id}`,
      GlobalConfig.appSecret
    ).toString()

    QRCode.toDataURL(
      encryptedData,
      {
        maskPattern: 7,
        margin: 0
      },
      (err, url) => {
        if (err) {
          console.error(err)
          return
        }
        setUrl(url)
      }
    )
  }

  useEffect(() => {
    setTimeout(() => setQrCodeValid(false), 60000)
    encryptDataAndGenerateQrCode()
    dispatch(ActionCreators.resetApp())
    window.localStorage.clear()
  }, [])

  const refreshQrCode = () => {
    encryptDataAndGenerateQrCode()
    setQrCodeValid(true)
    setTimeout(() => setQrCodeValid(false), 60000)
    FirebaseProxy.logEvent(
      Constants.UserAnalyticsEvents.RELOAD_WEB_LOGIN_QR_CODE,
      {}
    )
  }

  // Start listening for the auth info that the mobile app will
  // write. On reading the auth info, emit the information using
  // an event and delete the data on firebase
  const startListeningForAuthInfo = taskId => {
    const galarmOnWebHandshakeRef = GlobalConfig.rootFirebaseRef
      .child('galarmOnWebHandshake')
      .child(taskId)
    galarmOnWebHandshakeRef.on('value', taskSnapshot => {
      if (taskSnapshot.exists()) {
        const encryptedData = taskSnapshot.val()
        const data =
          CryptoJS.AES.decrypt(encryptedData, GlobalConfig.appSecret).toString(
            CryptoJS.enc.Utf8
          ) +
          ':' +
          taskId
        GlobalConfig.eventEmitter.emit('authInfo', data)
        galarmOnWebHandshakeRef.off('value')
        GlobalConfig.rootFirebaseRef
          .child('galarmOnWebHandshake')
          .child(taskId)
          .remove()
        setQrCodeValid(false)
      }
    })
  }

  const instructions = I18n.t('galarmWebInstructions')
  const instructionsArr = instructions
    .split('\n')
    .map(instruction => ({ text: instruction }))

  return (
    <Box fill style={{ minHeight: '1080px' }} overflow="auto">
      <GalarmWebHeader />
      <Box fill direction="row">
        <Box
          flex
          background={
            colorThemes.getColorTheme(colorScheme).textBackgroundColor
          }
          pad={{
            // There is an 80px scroll added to the page for some reason
            // because of the navbar on top. Adding margin as a workaround
            // till that is fixed
            bottom: '80px'
          }}>
          <Box pad="large">
            <Text
              size="medium"
              weight="bold"
              margin={{ bottom: 'xsmall' }}
              color={colorThemes.getColorTheme(colorScheme).textColor}>
              {I18n.t('useGalarmOnWeb')}
            </Text>
            <List
              data={instructionsArr}
              primaryKey={item => (
                <Text color={colorThemes.getColorTheme(colorScheme).textColor}>
                  {item.text}
                </Text>
              )}
              itemKey={item => 'key-' + item.text}
              pad="xsmall"
              border={false}
            />
            <Anchor
              margin={{ top: 'medium' }}
              size="small"
              color={colorThemes.getColorTheme(colorScheme).primary}
              href="https://www.galarmapp.com/galarm-web"
              label={I18n.t('needHelpToGetStarted')}
              target="_blank"
              rel="noopener noreferrer"
            />
          </Box>
          <Box
            direction="row"
            pad="medium"
            align="center"
            justify="center"
            gap="medium">
            <Image width="25%" src={require('galarm-res/img/web/mobile.png')} />
            <FormNextLink
              size="large"
              color={colorThemes.getColorTheme(colorScheme).mediumTint}
            />
            <Image width="40%" src={require('galarm-res/img/web/laptop.png')} />
          </Box>
        </Box>
        <Box
          flex
          background={colorThemes.getColorTheme(colorScheme).lightTint}
          justify="center"
          align="center"
          pad={{
            // There is an 80px scroll added to the page for some reason
            // because of the navbar on top. Adding margin as a workaround
            // till that is fixed
            bottom: '80px'
          }}>
          {!!url && (
            <Box
              style={{
                position: 'relative'
              }}
              pad="large"
              round="small"
              background="white"
              elevation="small"
              align="center">
              <Image fit="contain" src={url} height="250px" width="250px" />
              <Image
                src={
                  require('galarm-res/img/web/qr-code-galarm-logo.png').default
                }
                height="80px"
                style={{
                  position: 'absolute',
                  left: '50%',
                  top: '50%',
                  transform: 'translate(-50%, -50%)'
                }}
              />
              {!qrCodeValid && (
                <Box
                  fill
                  round="small"
                  background="transluscentBackground"
                  style={{
                    position: 'absolute',
                    bottom: 0
                  }}
                  justify="center"
                  align="center">
                  <Avatar
                    size="5xl"
                    background="primary"
                    onClick={refreshQrCode}>
                    <TintedImage
                      height="40px"
                      tintColor="white"
                      src={require('galarm-res/img/web/ic_refresh.svg').default}
                    />
                    <Box pad="small">
                      <Text color="white" textAlign="center">
                        {I18n.t('reloadQrCode')}
                      </Text>
                    </Box>
                  </Avatar>
                </Box>
              )}
              {/* {qrCodeValid && (
                <Box
                  background="primary"
                  pad="small"
                  style={{
                    position: 'absolute',
                    bottom: -20,
                    textAlign: 'center'
                  }}
                  round="xsmall">
                  <Text size="medium" weight="bold" color="white">
                    {I18n.t('scanQrCodeMessage')}
                  </Text>
                </Box>
              )} */}
            </Box>
          )}
          {
            // Allow the developer to enter the auth info manually
            __DEV__ && (
              <Box align="center" margin="small">
                <TextInput
                  width="small"
                  value={devAuthInfo}
                  onChange={event => setDevAuthInfo(event.target.value)}
                  onKeyDown={event => {
                    console.log('Handling key down')
                    if (event.key === 'Enter') {
                      GlobalConfig.eventEmitter.emit(
                        'authInfo',
                        `${devAuthInfo}:${
                          GlobalConfig.rootFirebaseRef
                            .child('galarmOnWebHandshake')
                            .push().key
                        }`
                      )
                    }
                  }}
                />
              </Box>
            )
          }
        </Box>
      </Box>
    </Box>
  )
}

export default LoginScreen
