import React from 'react'
import { Box } from 'grommet'

import MessageText from './MessageText'
import Time from './Time'

const Bubble = props => {
  const renderMessageText = () => {
    if (props.currentMessage.text) {
      const { ...messageTextProps } = props
      if (props.renderMessageText) {
        return props.renderMessageText(messageTextProps)
      }
      return <MessageText {...messageTextProps} />
    }
    return null
  }

  const renderTime = () => {
    if (props.currentMessage.createdAt) {
      const { ...timeProps } = props
      if (props.renderTime) {
        return props.renderTime(timeProps)
      }
      return <Time {...timeProps} />
    }
    return null
  }
  return (
    <Box style={props.wrapperStyle[props.position]}>
      <Box
        background={props.wrapperStyle[props.position].backgroundColor}
        round="small"
        pad="xsmall"
        width={{ min: '200px', max: '75%' }}>
        {renderMessageText()}
        <Box>{renderTime()}</Box>
      </Box>
    </Box>
  )
}

export default Bubble
