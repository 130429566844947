import React from 'react'
import { I18n } from 'galarm-config'
import { Box, List } from 'grommet'

// eslint-disable-next-line no-unused-vars
const SelectImage = ({ onClose, onUpload, onRemove, options }) => {
  const onSelectOption = ({ item }) => {
    if (item === I18n.t('uploadImage')) {
      onUpload()
      onClose()
    } else {
      onRemove()
      onClose()
    }
  }

  return (
    <Box>
      <List
        border={false}
        margin={{ vertical: 'small' }}
        pad={{ horizontal: 'medium', vertical: 'small' }}
        onClickItem={onSelectOption}
        data={options}
      />
    </Box>
  )
}

export default SelectImage
