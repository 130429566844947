import React, { useState } from 'react'
import { Box, List, Text } from 'grommet'
import ContactSummary from './ContactSummary'
import { useSelector } from 'react-redux'
import { Selectors } from 'galarm-shared'
import { colorThemes, I18n } from 'galarm-config'
import SearchBar from './SearchBar'

const ContactList = ({ showSearchBar, hideSearchBar }) => {
  const colorScheme = useSelector(state => state.userSettings.webColorScheme)
  const contacts = useSelector(state =>
    Selectors.sortedContactListSelector(state)
  )

  const isUserRegistered = useSelector(
    state => state.userInfo.mobileNumber !== ''
  )

  const [searchText, setSearchText] = useState('')

  let filteredContacts = searchText
    ? contacts.filter(contact =>
        contact.name.toLowerCase().includes(searchText.toLowerCase())
      )
    : contacts

  if (!contacts || contacts.length === 0) {
    return (
      <Box
        pad="medium"
        flex
        overflow="auto"
        justify="center"
        alignItems="center">
        <Text color={colorThemes.getColorTheme(colorScheme).textColor}>
          {I18n.t('noContactsWeb', { count: isUserRegistered ? 1 : 0 })}
        </Text>
      </Box>
    )
  }

  const onChangeText = newText => {
    setSearchText(newText)
  }

  return (
    <Box flex overflow="auto">
      {showSearchBar && (
        <Box
          flex={{ shrink: 0 }}
          margin={{ horizontal: 'small', top: 'medium' }}>
          <SearchBar
            onChangeText={onChangeText}
            searchText={searchText}
            onCloseSearchBar={hideSearchBar}
            focus
          />
        </Box>
      )}
      {filteredContacts && filteredContacts.length === 0 && (
        <Box pad="medium" flex justify="center" align="center">
          <Text color={colorThemes.getColorTheme(colorScheme).textColor}>
            {I18n.t('noSearchResultsFound', { type: 'contacts' })}
          </Text>
        </Box>
      )}
      {filteredContacts && filteredContacts.length > 0 && (
        <List
          border={false}
          margin={{ vertical: '10px' }}
          pad={{ vertical: '5px', horizontal: '20px' }}
          data={filteredContacts}
          // eslint-disable-next-line react/no-children-prop
          children={ContactSummary}
        />
      )}
    </Box>
  )
}

export default ContactList
