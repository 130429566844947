import range from 'lodash/range'

const StorageManager = (function () {
  const storeData = data => {
    const promise = new Promise(resolve => {
      Object.keys(data).forEach(key => localStorage.setItem(key, data[key]))
      resolve()
    })
    return promise
  }

  const retrieveData = keys => {
    const promise = new Promise(resolve => {
      let data = {}
      keys.forEach(key => (data[key] = localStorage.getItem(key)))
      resolve(data)
    })
    return promise
  }

  const removeData = keys => {
    const promise = new Promise(resolve => {
      keys.forEach(key => localStorage.removeItem(key))
      resolve()
    })
    return promise
  }

  const getAllDataKeys = () => {
    const promise = new Promise(resolve => {
      let keys = []
      const storageLength = localStorage.length
      range(0, storageLength).forEach(index =>
        keys.push(localStorage.key(index))
      )
      resolve(keys)
    })
    return promise
  }

  return {
    storeData,
    retrieveData,
    removeData,
    getAllDataKeys
  }
})()

export default StorageManager
