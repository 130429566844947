import ActionTypes from '../actions/types'
import { createReducer } from 'reduxsauce'
import Utils from '../utils/Utils'

const INITIAL_STATE = {
  contacts: [],
  invitedContacts: [],
  phoneContacts: [],
  totalNumberOfContacts: 0,
  contactsLastUpdatedAt: 0
}

const addContact = (state, action) => {
  const contact = action.payload.contact
  let contactIndex = Utils.getIndexOfObjectWithId(state.contacts, contact.id)

  let newContacts = state.contacts.slice()

  if (contactIndex === -1) {
    newContacts.push(contact)
  } else {
    const existingContact = state.contacts[contactIndex]
    newContacts.splice(contactIndex, 1, { ...existingContact, ...contact })
  }

  return {
    ...state,
    contacts: newContacts
  }
}

const addInvitedContact = (state, action) => {
  const contact = action.payload.contact
  let contactIndex = Utils.getIndexOfObjectWithId(
    state.invitedContacts,
    contact.id
  )

  let newInvitedContacts = state.invitedContacts.slice()

  if (contactIndex === -1) {
    newInvitedContacts.push(contact)
  } else {
    const existingContact = state.invitedContacts[contactIndex]
    newInvitedContacts.splice(contactIndex, 1, {
      ...existingContact,
      ...contact
    })
  }

  return {
    ...state,
    invitedContacts: newInvitedContacts
  }
}

const addPhoneContacts = (state, action) => {
  const phoneContacts = action.payload.phoneContacts
  return {
    ...state,
    phoneContacts: phoneContacts
  }
}

const addContacts = (state, action) => {
  const contacts = action.payload.contacts
  const newContacts = []
  contacts.forEach(contact => {
    let contactIndex = Utils.getIndexOfObjectWithId(state.contacts, contact.id)
    if (contactIndex === -1) {
      newContacts.push(contact)
    } else {
      const existingContact = state.contacts[contactIndex]
      newContacts.splice(contactIndex, 1, { ...existingContact, ...contact })
    }
  })
  return {
    ...state,
    contacts: newContacts
  }
}

const deleteContact = (state, action) => {
  const { contactId } = action.payload
  let contactIndex = Utils.getIndexOfObjectWithId(state.contacts, contactId)

  if (contactIndex !== -1) {
    let newContacts = state.contacts.slice()
    newContacts.splice(contactIndex, 1)
    return {
      ...state,
      contacts: newContacts
    }
  }

  return state
}

const deleteInvitedContact = (state, action) => {
  const { contactId } = action.payload
  let contactIndex = Utils.getIndexOfObjectWithId(
    state.invitedContacts,
    contactId
  )

  if (contactIndex !== -1) {
    let newInvitedContacts = state.invitedContacts.slice()
    newInvitedContacts.splice(contactIndex, 1)
    return {
      ...state,
      invitedContacts: newInvitedContacts
    }
  }

  return state
}

const setContactAvatarImages = (state, action) => {
  let { contactId, images } = action.payload
  let contactIndex = Utils.getIndexOfObjectWithId(state.contacts, contactId)

  if (contactIndex !== -1) {
    let newContacts = state.contacts.slice()
    const contact = state.contacts[contactIndex]
    newContacts.splice(contactIndex, 1, {
      ...contact,
      images: images
    })
    return {
      ...state,
      contacts: newContacts
    }
  }

  return state
}

const deleteContactAvatarImages = (state, action) => {
  let { contactId } = action.payload
  let images = {}

  let contactIndex = Utils.getIndexOfObjectWithId(state.contacts, contactId)

  if (contactIndex !== -1) {
    let newContacts = state.contacts.slice()
    const contact = state.contacts[contactIndex]
    newContacts.splice(contactIndex, 1, {
      ...contact,
      images: images
    })
    return {
      ...state,
      contacts: newContacts
    }
  }

  return state
}

const setTotalNumberOfContacts = (state, action) => {
  const { totalNumberOfContacts } = action.payload

  return {
    ...state,
    totalNumberOfContacts
  }
}

const setContactsLastUpdatedAt = (state, action) => {
  const { contactsLastUpdatedAt } = action.payload

  return {
    ...state,
    contactsLastUpdatedAt
  }
}

const ACTION_HANDLERS = {
  [ActionTypes.ADD_CONTACT]: addContact,
  [ActionTypes.ADD_CONTACTS]: addContacts,
  [ActionTypes.ADD_PHONE_CONTACTS]: addPhoneContacts,
  [ActionTypes.ADD_INVITED_CONTACT]: addInvitedContact,
  [ActionTypes.DELETE_CONTACT]: deleteContact,
  [ActionTypes.DELETE_INVITED_CONTACT]: deleteInvitedContact,
  [ActionTypes.SET_CONTACT_AVATAR_IMAGES]: setContactAvatarImages,
  [ActionTypes.DELETE_CONTACT_AVATAR_IMAGES]: deleteContactAvatarImages,
  [ActionTypes.SET_TOTAL_NUMBER_OF_CONTACTS]: setTotalNumberOfContacts,
  [ActionTypes.SET_CONTACTS_LAST_UPDATED_AT]: setContactsLastUpdatedAt
}

export default createReducer(INITIAL_STATE, ACTION_HANDLERS)
