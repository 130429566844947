import ActionTypes from '../actions/types'
import { createReducer } from 'reduxsauce'
import { AsYouType } from 'libphonenumber-js'
import Utils from '../utils/Utils'
import objGet from 'lodash/get'
import objSet from 'lodash/set'
import { GlobalConfig } from 'galarm-config'
import objOmit from 'lodash/omit'

const INITIAL_STATE = {
  name: '',
  mobileNumber: '',
  countryCode: '',
  cca2: '',
  images: {},
  blockedContacts: [],
  formattedMobileNumberWithCC: '',
  email: '',
  systemAlerts: [],
  alarmsCount: {},
  purchases: [],
  maxAllowedAlarms: GlobalConfig.maxAllowedAlarms,
  deviceManufacturer: '',
  joinDate: 0,
  shareableAlarmLinksCount: {},
  alarmsSavedToCalendarCount: 0,
  alarmCategories: {},
  deviceOs: '',
  instantAlarmsCount: 0,
  enterpriseSubscriptions: []
}

const setUserInfo = (state, action) => {
  const { mobileNumber = '', countryCode = '', name = '' } = action.payload
  return {
    ...state,
    name: name,
    mobileNumber: mobileNumber,
    countryCode: countryCode,
    formattedMobileNumberWithCC:
      mobileNumber && new AsYouType().input('+' + countryCode + mobileNumber),
    email: action.payload.email || state.email,
    cca2: action.payload.cca2
  }
}

const setUserMobileNumber = (state, action) => ({
  ...state,
  mobileNumber: action.payload.mobileNumber,
  countryCode: action.payload.countryCode,
  formattedMobileNumberWithCC: new AsYouType().input(
    '+' + action.payload.countryCode + action.payload.mobileNumber
  )
})

const setUserName = (state, action) => ({
  ...state,
  name: action.payload.name
})

const setUserAvatarImages = (state, action) => ({
  ...state,
  images: action.payload.images
})

// eslint-disable-next-line no-unused-vars
const deleteUserAvatarImages = (state, action) => ({
  ...state,
  images: {}
})

const addEnterpriseSubscriptions = (state, action) => ({
  ...state,
  enterpriseSubscriptions: action.payload.enterpriseSubscriptions
})

const addBlockedContacts = (state, action) => ({
  ...state,
  blockedContacts: action.payload.blockedContacts
})

const setUserEmail = (state, action) => ({
  ...state,
  email: action.payload.email
})

const setDeviceOs = (state, action) => ({
  ...state,
  deviceOs: action.payload.deviceOs
})

const setRateTheAppUserInfo = (state, action) => ({
  ...state,
  rateTheAppInfo: action.payload.rateTheAppInfo
})

const addSystemAlerts = (state, action) => {
  const alerts = action.payload.systemAlerts
  const newAlerts = state.systemAlerts.concat(alerts)
  return {
    ...state,
    systemAlerts: newAlerts
  }
}

const addSystemAlert = (state, action) => {
  const alert = action.payload.alert
  const alertId = alert.id
  const existingAlert =
    state.systemAlerts.findIndex(alert => alert.id === alertId) !== -1
  if (existingAlert) {
    return state
  }
  return {
    ...state,
    systemAlerts: state.systemAlerts.concat([alert])
  }
}

const removeSystemAlert = (state, action) => {
  const alertId = action.payload.id
  const alertIndex = Utils.getIndexOfObjectWithId(state.systemAlerts, alertId)

  if (alertIndex !== -1) {
    let newAlerts = state.systemAlerts.slice()
    newAlerts.splice(alertIndex, 1)
    return {
      ...state,
      systemAlerts: newAlerts
    }
  } else {
    return state
  }
}

const incrementOwnAlarmsCount = (state, action) => {
  const { alarmType, countType } = action.payload
  const newState = { ...state }
  const existingAlarmsCount = objGet(
    state.alarmsCount,
    `ownAlarms.${alarmType}.${countType}`,
    0
  )
  objSet(
    newState.alarmsCount,
    `ownAlarms.${alarmType}.${countType}`,
    existingAlarmsCount + 1
  )
  return newState
}

const setAlarmsCount = (state, action) => {
  const { alarmsCount } = action.payload
  return {
    ...state,
    alarmsCount
  }
}

const setShareableAlarmLinksCount = (state, action) => {
  const { shareableAlarmLinksCount } = action.payload
  return {
    ...state,
    shareableAlarmLinksCount
  }
}

const setAlarmsSavedToCalendarCount = (state, action) => {
  const { alarmsSavedToCalendarCount } = action.payload
  return {
    ...state,
    alarmsSavedToCalendarCount
  }
}

const setInstantAlarmsCount = (state, action) => {
  const { instantAlarmsCount } = action.payload
  return {
    ...state,
    instantAlarmsCount
  }
}

const setPurchases = (state, action) => {
  const { purchases } = action.payload
  return {
    ...state,
    purchases
  }
}

const addPurchase = (state, action) => {
  const { purchase } = action.payload
  const existingPurchaseIndex = state.purchases.findIndex(
    item => item.productId === purchase.productId
  )

  let newPurchases = state.purchases.slice()

  // New purchase
  if (existingPurchaseIndex === -1) {
    // Add the new purchase to front to handle the change to yearly plan
    newPurchases.unshift(purchase)
  } else {
    // Existing purchase
    newPurchases.splice(existingPurchaseIndex, 1, purchase)
  }

  return {
    ...state,
    purchases: newPurchases
  }
}

const setMaxAllowedAlarms = (state, action) => {
  const { maxAllowedAlarms } = action.payload
  return {
    ...state,
    maxAllowedAlarms
  }
}

const setDeviceManufacturer = (state, action) => {
  const { deviceManufacturer } = action.payload
  return {
    ...state,
    deviceManufacturer
  }
}
const setJoinDate = (state, action) => {
  const { joinDate } = action.payload
  return {
    ...state,
    joinDate
  }
}

const setAlarmCategories = (state, action) => {
  const { alarmCategories } = action.payload
  return {
    ...state,
    alarmCategories
  }
}

const createAlarmCategory = (state, action) => {
  const { id, alarmCategory } = action.payload
  const newAlarmCategories = {
    ...state.alarmCategories,
    [id]: alarmCategory
  }
  return {
    ...state,
    alarmCategories: newAlarmCategories
  }
}

const hideAlarmCategory = (state, action) => {
  const { id } = action.payload
  const existingAlarmCategory = state.alarmCategories[id]

  if (!existingAlarmCategory) {
    console.tron.log('Unable to find alarm category with id ' + id)
    return state
  }

  const newAlarmCategory = {
    ...existingAlarmCategory,
    disabled: true,
    alarmIds: []
  }
  const newAlarmCategories = {
    ...state.alarmCategories,
    [id]: newAlarmCategory
  }
  return {
    ...state,
    alarmCategories: newAlarmCategories
  }
}

const unhideAlarmCategory = (state, action) => {
  const { id } = action.payload
  const existingAlarmCategory = state.alarmCategories[id]

  if (!existingAlarmCategory) {
    console.tron.log('Unable to find alarm category with id ' + id)
    return state
  }

  const newAlarmCategory = objOmit(existingAlarmCategory, ['disabled'])

  const newAlarmCategories = {
    ...state.alarmCategories,
    [id]: newAlarmCategory
  }
  return {
    ...state,
    alarmCategories: newAlarmCategories
  }
}

const updateAlarmCategory = (state, action) => {
  const { id, alarmCategory } = action.payload
  const existingAlarmCategory = state.alarmCategories[id]

  if (!existingAlarmCategory) {
    console.tron.log('Unable to find alarm category with id ' + id)
    return state
  }

  const newAlarmCategory = {
    ...existingAlarmCategory,
    ...alarmCategory
  }
  const newAlarmCategories = {
    ...state.alarmCategories,
    [id]: newAlarmCategory
  }
  return {
    ...state,
    alarmCategories: newAlarmCategories
  }
}

const deleteAlarmCategory = (state, action) => {
  const { id } = action.payload

  const newAlarmCategories = objOmit(state.alarmCategories, [id])
  return {
    ...state,
    alarmCategories: newAlarmCategories
  }
}

const addAlarmToAlarmCategory = (state, action) => {
  const { id, alarmId } = action.payload
  const alarmCategory = state.alarmCategories[id]

  if (!alarmCategory) {
    console.tron.log('Unable to find alarm category with id ' + id)
    return state
  }

  const alarmIds = alarmCategory.alarmIds || []
  const newAlarmIds = alarmIds.concat(alarmId)
  const newAlarmCategory = {
    ...alarmCategory,
    alarmIds: newAlarmIds
  }
  const newAlarmCategories = {
    ...state.alarmCategories,
    [alarmCategory.id]: newAlarmCategory
  }
  return {
    ...state,
    alarmCategories: newAlarmCategories
  }
}

const removeAlarmFromAlarmCategory = (state, action) => {
  const { id, alarmId } = action.payload
  const alarmCategory = state.alarmCategories[id]

  if (!alarmCategory) {
    console.tron.log('Unable to find alarm category with id ' + id)
    return state
  }

  const alarmIds = alarmCategory.alarmIds || []
  const newAlarmIds = alarmIds.filter(itemAlarmId => itemAlarmId !== alarmId)
  const newAlarmCategory = {
    ...alarmCategory,
    alarmIds: newAlarmIds
  }
  const newAlarmCategories = {
    ...state.alarmCategories,
    [alarmCategory.id]: newAlarmCategory
  }
  return {
    ...state,
    alarmCategories: newAlarmCategories
  }
}

const ACTION_HANDLERS = {
  [ActionTypes.SET_USER_INFO]: setUserInfo,
  [ActionTypes.SET_USER_NAME]: setUserName,
  [ActionTypes.SET_USER_AVATAR_IMAGES]: setUserAvatarImages,
  [ActionTypes.DELETE_USER_AVATAR_IMAGES]: deleteUserAvatarImages,
  [ActionTypes.ADD_BLOCKED_CONTACTS]: addBlockedContacts,
  [ActionTypes.SET_USER_EMAIL]: setUserEmail,
  [ActionTypes.SET_RATE_THE_APP_USER_INFO]: setRateTheAppUserInfo,
  [ActionTypes.SET_USER_MOBILE_NUMBER]: setUserMobileNumber,
  [ActionTypes.ADD_SYSTEM_ALERTS]: addSystemAlerts,
  [ActionTypes.ADD_SYSTEM_ALERT]: addSystemAlert,
  [ActionTypes.REMOVE_SYSTEM_ALERT]: removeSystemAlert,
  [ActionTypes.INCREMENT_OWN_ALARMS_COUNT]: incrementOwnAlarmsCount,
  [ActionTypes.SET_ALARMS_COUNT]: setAlarmsCount,
  [ActionTypes.SET_PURCHASES]: setPurchases,
  [ActionTypes.ADD_PURCHASE]: addPurchase,
  [ActionTypes.SET_MAX_ALLOWED_ALARMS]: setMaxAllowedAlarms,
  [ActionTypes.SET_DEVICE_MANUFACTURER]: setDeviceManufacturer,
  [ActionTypes.SET_JOIN_DATE]: setJoinDate,
  [ActionTypes.SET_SHAREABLE_ALARM_LINKS_COUNT]: setShareableAlarmLinksCount,
  [ActionTypes.SET_ALARMS_SAVED_TO_CALENDAR_COUNT]:
    setAlarmsSavedToCalendarCount,
  [ActionTypes.SET_ALARM_CATEGORIES]: setAlarmCategories,
  [ActionTypes.CREATE_ALARM_CATEGORY]: createAlarmCategory,
  [ActionTypes.UPDATE_ALARM_CATEGORY]: updateAlarmCategory,
  [ActionTypes.DELETE_ALARM_CATEGORY]: deleteAlarmCategory,
  [ActionTypes.ADD_ALARM_TO_ALARM_CATEGORY]: addAlarmToAlarmCategory,
  [ActionTypes.REMOVE_ALARM_FROM_ALARM_CATEGORY]: removeAlarmFromAlarmCategory,
  [ActionTypes.HIDE_ALARM_CATEGORY]: hideAlarmCategory,
  [ActionTypes.UNHIDE_ALARM_CATEGORY]: unhideAlarmCategory,
  [ActionTypes.SET_DEVICE_OS]: setDeviceOs,
  [ActionTypes.SET_INSTANT_ALARMS_COUNT]: setInstantAlarmsCount,
  [ActionTypes.ADD_ENTERPRISE_SUBSCRIPTIONS]: addEnterpriseSubscriptions
}

export default createReducer(INITIAL_STATE, ACTION_HANDLERS)
