import {
  Box,
  Button,
  Card,
  CardBody,
  Header,
  Text,
  TextArea,
  TextInput
} from 'grommet'
import { Close, Send } from 'grommet-icons'
import React, { useState } from 'react'
import LabelText from './LabelText'
import { Constants, GlobalConfig, I18n } from 'galarm-config'
import { useDispatch, useSelector } from 'react-redux'
import { ActionCreators } from 'galarm-shared'
import AppCanvas from './AppCanvas'

const ReportAProblemHeader = ({ onSend }) => {
  const dispatch = useDispatch()
  return (
    <Header className="header" background="brand" pad="small">
      <Text>{I18n.t('reportAProblem')}</Text>
      <Box direction="row" align="center">
        <Button icon={<Send />} onClick={onSend} />
        <Button
          icon={<Close />}
          onClick={() => dispatch(ActionCreators.hideReportAProblemScreen())}
        />
      </Box>
    </Header>
  )
}

const ReportAProblem = () => {
  const dispatch = useDispatch()
  const email = useSelector(state => state.userInfo.email)

  const [feedback, setFeedback] = useState('')
  const [emailId, setEmailId] = useState(email)

  const onChangeFeedback = event => setFeedback(event.target.value)

  const onChangeEmail = event => setEmailId(event.target.value)

  const onSend = () => {
    dispatch(
      ActionCreators.createSupportTicket(
        Constants.TICKET_TYPES.PROBLEM_REPORT,
        emailId,
        feedback
      )
    ).then(() => {
      if (emailId !== '') {
        GlobalConfig.rootFirebaseRef
          .child('users')
          .child(GlobalConfig.uid)
          .child('email')
          .set(emailId)
      }
    })
    dispatch(ActionCreators.hideReportAProblemScreen())
  }
  return (
    <AppCanvas overflow="auto">
      <ReportAProblemHeader onSend={onSend} />
      <Card margin="small" background="textBackgroundColor" width="600px">
        <CardBody gap="medium" pad="medium">
          <TextArea
            placeholder={I18n.t('describeProblem')}
            value={feedback}
            onChange={onChangeFeedback}
            margin={{ horizontal: '10px' }}
            resize="vertical"
          />
          <Box flex>
            <LabelText>{I18n.t('emailId')}</LabelText>
            <TextInput
              flex
              value={emailId}
              placeholder={I18n.t('enterEmailIdForFeedback')}
              onChange={onChangeEmail}
            />
          </Box>
        </CardBody>
      </Card>
    </AppCanvas>
  )
}

export default ReportAProblem
