import { Box, Heading, TextInput } from 'grommet'
import React, { useState } from 'react'
import LabelText from './LabelText'
import { Constants, GlobalConfig, I18n } from 'galarm-config'
import { useDispatch, useSelector } from 'react-redux'
import { ActionCreators, StringUtils } from 'galarm-shared'
import TallTextArea from './TallTextArea'
import ColoredButton from './ColoredButton'
import { NavigationUtils } from 'galarm-ps-api'

const Feedback = props => {
  const dispatch = useDispatch()
  const email = useSelector(state => state.userInfo.email)

  const [feedback, setFeedback] = useState('')
  const [emailId, setEmailId] = useState(email)

  const onChangeFeedback = event => setFeedback(event.target.value)

  const onChangeEmail = event => setEmailId(event.target.value)

  const { type, extras } = props.feedbackConfiguration

  const validateFeedback = () => {
    let validationMessages = []
    if (feedback === '') {
      validationMessages.push(
        type === Constants.TICKET_TYPES.GENERAL_FEEDBACK
          ? I18n.t('specifyFeedback')
          : I18n.t('specifyProblem')
      )
    }

    if (emailId === '') {
      validationMessages.push(I18n.t('specifyEmailId'))
    }

    if (emailId && !StringUtils.isStringAnEmailId(emailId)) {
      validationMessages.push(I18n.t('specifyValidEmailId'))
    }

    return validationMessages
  }

  const onSend = () => {
    const validationMessages = validateFeedback()
    if (validationMessages.length > 0) {
      NavigationUtils.showAlert(
        type === Constants.TICKET_TYPES.GENERAL_FEEDBACK
          ? I18n.t('cantSendFeedback')
          : I18n.t('cantSubmitProblem'),
        validationMessages.join('\n')
      )
      return
    }

    dispatch(
      ActionCreators.createSupportTicket(type, emailId, feedback, extras)
    ).then(() => {
      if (emailId !== '') {
        GlobalConfig.rootFirebaseRef
          .child('users')
          .child(GlobalConfig.uid)
          .child('email')
          .set(emailId)
      }
    })
    props.onClose()
  }
  return (
    <Box overflow="auto" width="500px" margin="medium" gap="small">
      <Heading level={4} margin="none">
        {type === Constants.TICKET_TYPES.GENERAL_FEEDBACK
          ? I18n.t('feedback')
          : I18n.t('reportAProblem')}
      </Heading>
      <TallTextArea
        placeholder={
          type === Constants.TICKET_TYPES.GENERAL_FEEDBACK
            ? I18n.t('enterFeedback')
            : I18n.t('describeProblem')
        }
        height="200px"
        value={feedback}
        onChange={onChangeFeedback}
        margin={{ horizontal: '10px' }}
        resize="vertical"
      />
      <Box flex>
        <LabelText>{I18n.t('emailId')}</LabelText>
        <TextInput
          flex
          value={emailId}
          placeholder={I18n.t('enterEmailIdForFeedback')}
          onChange={onChangeEmail}
        />
      </Box>
      <ColoredButton
        primary
        width="100px"
        label={I18n.t('submit')}
        onClick={onSend}
        margin={{ top: 'small' }}
        buttonTextColor="white"
      />
    </Box>
  )
}

export default Feedback
