import React, { Component } from 'react'
import { Constants, I18n } from 'galarm-config'
import { Box, Menu, Text } from 'grommet'
import TimeInput from './TimeInput'
import range from 'lodash/range'
import DaysOfWeekPicker from './DaysOfWeekPicker'
import moment from 'moment-timezone'
import { AlarmUtils } from 'galarm-shared'
import { NavigationUtils } from 'galarm-ps-api'
import HoverBox from './HoverBox'
import { SecondaryText } from 'web-components'

class SelectHoursAndMinutesRepetition extends Component {
  onSaveHoursAndMinutesRepetition() {
    const startHours = moment(this.state.startTimeOfDay).hours()
    const startMins = moment(this.state.startTimeOfDay).minutes()
    const endHours = moment(this.state.endTimeOfDay).hours()
    const endMins = moment(this.state.endTimeOfDay).minutes()
    const sortedSelectedDays = []
    Object.keys(Constants.RepeatDayToDayMapping).forEach(repeatDay => {
      if (
        this.state.selectedDays.findIndex(
          selectedDay => selectedDay === repeatDay
        ) !== -1
      ) {
        sortedSelectedDays.push(repeatDay)
      }
    })
    const selectedDaysString = sortedSelectedDays.join(',')
    const minutes = this.state.hours * 60 + this.state.minutes

    const repeatString = `${startHours}:${startMins}:${endHours}:${endMins}:${minutes}:${selectedDaysString}`
    this.props.onSaveAlarmRepetition(
      Constants.RepeatTypes.HOURS_AND_MINUTES,
      repeatString
    )
  }

  getDefaultStartTimeOfDay = () => {
    const { startHours, startMins } =
      AlarmUtils.getRepeatOptionsForHoursAndMinutesRepetition(this.props.repeat)
    return moment()
      .hours(startHours)
      .minutes(startMins)
      .seconds(0)
      .milliseconds(0)
      .valueOf()
  }

  getDefaultEndTimeOfDay = () => {
    const { endHours, endMins } =
      AlarmUtils.getRepeatOptionsForHoursAndMinutesRepetition(this.props.repeat)
    return moment()
      .hours(endHours)
      .minutes(endMins)
      .seconds(0)
      .milliseconds(0)
      .valueOf()
  }

  getDefaultHours = () => {
    const { minutes: totalMinutes } =
      AlarmUtils.getRepeatOptionsForHoursAndMinutesRepetition(this.props.repeat)
    const { hours } = AlarmUtils.getHoursAndMinutesFromMinutes(totalMinutes)
    return hours
  }

  getDefaultMinutes = () => {
    const { minutes: totalMinutes } =
      AlarmUtils.getRepeatOptionsForHoursAndMinutesRepetition(this.props.repeat)
    const { minutes } = AlarmUtils.getHoursAndMinutesFromMinutes(totalMinutes)
    return minutes
  }

  getDefaultSelectedDays = () => {
    const { selectedDays } =
      AlarmUtils.getRepeatOptionsForHoursAndMinutesRepetition(this.props.repeat)
    return selectedDays
  }

  state = {
    startTimeOfDay: this.getDefaultStartTimeOfDay(),
    endTimeOfDay: this.getDefaultEndTimeOfDay(),
    hours: this.getDefaultHours(),
    minutes: this.getDefaultMinutes(),
    selectedDays: this.getDefaultSelectedDays()
  }

  onStartTimeChange = newTime => {
    if (this.state.endTimeOfDay < newTime) {
      NavigationUtils.showAlert(
        I18n.t('cantSetStartTime'),
        I18n.t('startTimeShouldBeLess')
      )
      return
    }
    this.setState({ startTimeOfDay: newTime }, () =>
      this.onSaveHoursAndMinutesRepetition()
    )
  }

  onEndTimeChange = newTime => {
    if (newTime < this.state.startTimeOfDay) {
      NavigationUtils.showAlert(
        I18n.t('cantSetEndTime'),
        I18n.t('endTimeShouldBeGreater')
      )
      return
    }
    this.setState({ endTimeOfDay: newTime }, () =>
      this.onSaveHoursAndMinutesRepetition()
    )
  }

  onHourChange = newHours =>
    this.setState({ hours: newHours }, () =>
      this.onSaveHoursAndMinutesRepetition()
    )

  onMinuteChange = newMins =>
    this.setState({ minutes: newMins }, () =>
      this.onSaveHoursAndMinutesRepetition()
    )

  onSelectDay = day => {
    let newSelectedDays = this.state.selectedDays.slice()
    if (!this.state.selectedDays.includes(day.value)) {
      newSelectedDays.push(day.value)
    } else {
      const index = this.state.selectedDays.findIndex(
        selectedDay => selectedDay === day.value
      )
      newSelectedDays.splice(index, 1)
    }

    if (newSelectedDays.length === 0) {
      NavigationUtils.showAlert('', I18n.t('selectAtLeastOneDayForRepetition'))
      return
    }
    this.setState(
      {
        selectedDays: newSelectedDays
      },
      () => this.onSaveHoursAndMinutesRepetition()
    )
  }

  onChangeStartTime = newStartTime => {
    this.onStartTimeChange(newStartTime)
  }

  onChangeEndTime = newEndTime => {
    this.onEndTimeChange(newEndTime)
  }

  render() {
    const { previewString, count, alarmTimesInDay, daysString } =
      AlarmUtils.createPreviewDataForHoursAndMinutesRepetition(
        this.state.startTimeOfDay,
        this.state.endTimeOfDay,
        this.state.hours,
        this.state.minutes,
        this.state.selectedDays
      )

    const hourItems = range(0, 13).map(item => ({
      label: item,
      onClick: () => this.onHourChange(item)
    }))

    const minutesRange = this.state.hours ? range(0, 12) : range(1, 12)
    const minuteItems = minutesRange.map(index => ({
      label: index * 5,
      onClick: () => this.onMinuteChange(index * 5)
    }))

    this.state.hours
      ? minuteItems.splice(1, 0, {
          label: 3,
          onClick: () => this.onMinuteChange(3)
        })
      : minuteItems.splice(0, 0, {
          label: 3,
          onClick: () => this.onMinuteChange(3)
        })

    return (
      <Box width="450px">
        <HoverBox>
          <Text>{I18n.t('ringEveryHours')}</Text>
          <Box direction="row">
            <Menu
              id="hours-menu"
              dropBackground="lightTint"
              label={I18n.t('hours', { count: this.state.hours })}
              items={hourItems}
            />
            <Menu
              id="minutes-menu"
              dropBackground="lightTint"
              label={I18n.t('minutes', { count: this.state.minutes })}
              items={minuteItems}
            />
          </Box>
        </HoverBox>
        <HoverBox>
          <Text>{I18n.t('startTimeOfDay')}</Text>
          <Box>
            <TimeInput
              value={this.state.startTimeOfDay}
              onChange={this.onChangeStartTime}
            />
          </Box>
        </HoverBox>
        <HoverBox>
          <Text>{I18n.t('endTimeOfDay')}</Text>
          <Box>
            <TimeInput
              value={this.state.endTimeOfDay}
              onChange={this.onChangeEndTime}
            />
          </Box>
        </HoverBox>
        <HoverBox>
          <DaysOfWeekPicker
            selectedDays={this.state.selectedDays}
            onSelectDay={this.onSelectDay}
          />
        </HoverBox>
        <Box pad="small">
          <SecondaryText>
            {I18n.t('hourlyRepetitionPreviewString', {
              previewString,
              daysString,
              count
            })}
          </SecondaryText>
          {alarmTimesInDay.map((time, index) => (
            <SecondaryText key={index}>{index + 1 + ') ' + time}</SecondaryText>
          ))}
        </Box>
      </Box>
    )
  }
}

export default SelectHoursAndMinutesRepetition
