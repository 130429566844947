import { Store } from 'react-notifications-component'
import { Constants, GlobalConfig, I18n } from 'galarm-config'
import { ActionCreators } from 'galarm-shared'

const NavigationUtils = (function () {
  const showTransientAlert = ({
    message,
    type = 'success',
    duration = Constants.AlertDurations.SHORT,
    position = Constants.AlertPositions.BOTTOM,
    onPress,
    messageStyle = {} // eslint-disable-line no-unused-vars
  }) => {
    Store.addNotification({
      message: message,
      type: type,
      insert: 'top',
      container: `${position}-right`,
      animationIn: ['animate__animated', 'animate__fadeIn'],
      animationOut: ['animate__animated', 'animate__fadeOut'],
      dismiss: {
        duration: duration * 1000
      },
      onRemoval: onPress
    })
  }

  const showAlert = async (
    title = '',
    message = '',
    buttons,
    cancelable = true
  ) => {
    let finalButtons = []

    if (!buttons || buttons.length === 0) {
      finalButtons = [
        {
          text: I18n.t('ok'),
          onClick: GlobalConfig.hideAlert
        }
      ]
    } else {
      finalButtons = buttons.map(button => ({
        text: button.text,
        onClick: () => {
          if (button.onPress) {
            button.onPress()
          }
          GlobalConfig.hideAlert()
        }
      }))
    }
    GlobalConfig.showAlert({
      title: title,
      message: message,
      buttons: finalButtons,
      cancelable
    })
  }

  const showProgress = (progressState, message, closeable = false) => {
    GlobalConfig.store?.dispatch(
      ActionCreators.setProgress(progressState, message, closeable)
    )
  }

  const dismissProgress = () => {
    GlobalConfig.store?.dispatch(ActionCreators.resetProgress())
  }

  const showGalarmPremiumScreen = featureId => {
    const premiumFeature = Constants.PREMIUM_FEATURES.find(
      feature => feature.id === featureId
    )
    const premiumFeatureDescription = premiumFeature.description
    const alertMessage = `${premiumFeatureDescription}\n\n${I18n.t(
      'upgradeToPremiumForWeb'
    )}`
    showAlert(I18n.t('upgradeRequiredForWeb'), alertMessage)
  }

  return {
    showTransientAlert,
    showAlert,
    showProgress,
    dismissProgress,
    showGalarmPremiumScreen
  }
})()

export default NavigationUtils
