import React, { useRef, useState, useEffect } from 'react'
import { I18n } from 'galarm-config'
import { Constants } from 'galarm-config'
import { colorThemes } from 'galarm-config'
import { Utils, AlarmUtils } from 'galarm-shared'
import { Box, Text, TextInput } from 'grommet'
import { Checkmark } from 'grommet-icons'
import { useSelector } from 'react-redux'
import DaysOfWeekPicker from './DaysOfWeekPicker'
import { FirebaseProxy, NavigationUtils } from 'galarm-ps-api'
import isEmpty from 'lodash/isEmpty'
import { SecondaryText } from 'web-components'

const SelectDaysRepetition = props => {
  const colorScheme = useSelector(state => state.userSettings.webColorScheme)

  const [repeat, setRepeat] = useState(props.repeat)

  const everyNDaysRef = useRef(null)

  useEffect(() => {
    onSaveRepetition()
  }, [repeat])

  const onChangeDays = event => {
    everyNDaysRef.current?.focus()
    const newDaysInt = parseInt(event.target.value)
    const newDays = newDaysInt ? newDaysInt.toString() : ''
    setRepeat(newDays)
  }

  const onSaveRepetition = () => {
    if (repeat) {
      props.onSaveAlarmRepetition(Constants.RepeatTypes.DAYS, repeat)

      if (repeat.includes(Constants.ODD_NUMBERED_DAYS_REPEAT_STRING)) {
        FirebaseProxy.logEvent(
          Constants.UserAnalyticsEvents.CHOOSE_DAY_REPEAT_TYPE,
          {
            [Constants.UserAnalyticsEventParameters.REPEAT_STRING]:
              Constants.ODD_NUMBERED_DAYS_REPEAT_STRING
          }
        )
      } else if (repeat.includes(Constants.EVEN_NUMBERED_DAYS_REPEAT_STRING)) {
        FirebaseProxy.logEvent(
          Constants.UserAnalyticsEvents.CHOOSE_DAY_REPEAT_TYPE,
          {
            [Constants.UserAnalyticsEventParameters.REPEAT_STRING]:
              Constants.EVEN_NUMBERED_DAYS_REPEAT_STRING
          }
        )
      } else {
        if (repeat && parseInt(repeat)) {
          FirebaseProxy.logEvent(
            Constants.UserAnalyticsEvents.CHOOSE_DAY_REPEAT_TYPE,
            {
              [Constants.UserAnalyticsEventParameters.REPEAT_STRING]:
                Constants.REPEAT_EVERY_N_DAYS_REPEAT_STRING
            }
          )
        }
      }
    }
  }

  const onSelectEvenDay = day => {
    onSelectOddEvenDayCore(day, false)
  }

  const onSelectOddDay = day => {
    onSelectOddEvenDayCore(day, true)
  }

  const onChooseOddNumberedDayRepeatType = () => {
    onChooseOddOrEvenRepeatTypeCore(true)
  }

  const onChooseEvenNumberedDayRepeatType = () => {
    onChooseOddOrEvenRepeatTypeCore(false)
  }

  const onChooseOddOrEvenRepeatTypeCore = isOdd => {
    let { selectedDays } =
      AlarmUtils.getRepeatOptionsForOddAndEvenNumberedDaysRepetition(
        props.repeat
      )

    selectedDays = selectedDays
      ? selectedDays.length > 0
        ? selectedDays
        : Constants.RepeatAllDays
      : Constants.RepeatAllDays

    everyNDaysRef.current?.blur()
    // Sort the days
    const selectedDaysString = getSortedSelectedDays(selectedDays)
    const repeat = isOdd
      ? Constants.ODD_NUMBERED_DAYS_REPEAT_STRING
      : Constants.EVEN_NUMBERED_DAYS_REPEAT_STRING
    const repeatString = `${repeat}:${selectedDaysString}`
    setRepeat(repeatString)
  }

  const onSelectOddEvenDayCore = (day, isOdd) => {
    let { selectedDays } =
      AlarmUtils.getRepeatOptionsForOddAndEvenNumberedDaysRepetition(
        props.repeat
      )

    selectedDays = selectedDays
      ? selectedDays.length > 0
        ? selectedDays
        : Constants.RepeatAllDays
      : Constants.RepeatAllDays

    let newSelectedDays = selectedDays.slice()
    if (!selectedDays.includes(day.value)) {
      newSelectedDays.push(day.value)
    } else {
      const index = selectedDays.findIndex(
        selectedDay => selectedDay === day.value
      )
      newSelectedDays.splice(index, 1)
    }

    if (newSelectedDays.length === 0) {
      NavigationUtils.showAlert('', I18n.t('selectAtLeastOneDayForRepetition'))
      return
    }

    // Sort the days
    const selectedDaysString = getSortedSelectedDays(newSelectedDays)

    const repeat = isOdd
      ? Constants.ODD_NUMBERED_DAYS_REPEAT_STRING
      : Constants.EVEN_NUMBERED_DAYS_REPEAT_STRING

    const repeatString = `${repeat}:${selectedDaysString}`

    setRepeat(repeatString)
  }

  const getSortedSelectedDays = newSelectedDays => {
    const sortedSelectedDays = []
    Object.keys(Constants.RepeatDayToDayMapping).forEach(repeatDay => {
      if (
        newSelectedDays.findIndex(selectedDay => selectedDay === repeatDay) !==
        -1
      ) {
        sortedSelectedDays.push(repeatDay)
      }
    })
    const selectedDaysString = sortedSelectedDays.join(',')
    return selectedDaysString
  }

  const onChooseDays = () => {
    everyNDaysRef.current?.focus()
    setRepeat('2')
  }

  const renderHelperTextComponent = (daysString, isOdd, selectedDays) => {
    return (
      <Box pad="small">
        {selectedDays.length > 0 ? (
          <SecondaryText>
            {I18n.t(
              isOdd
                ? 'oddNumberedRepetitionPreviewString'
                : 'evenNumberedRepetitionPreviewString',
              {
                daysString: daysString,
                count: selectedDays.length < 7 ? 1 : selectedDays.length
              }
            )}
          </SecondaryText>
        ) : (
          <SecondaryText>{I18n.t('selectAtleastOneDay')}</SecondaryText>
        )}
      </Box>
    )
  }

  let selectedOption = 0
  let daysValue = '',
    daysInt = 0

  let { selectedDays } =
    AlarmUtils.getRepeatOptionsForOddAndEvenNumberedDaysRepetition(props.repeat)

  selectedDays = selectedDays
    ? selectedDays.length > 0
      ? selectedDays
      : Constants.RepeatAllDays
    : Constants.RepeatAllDays

  const daysString = AlarmUtils.createSelectedDaysString(selectedDays)

  if (repeat.includes(Constants.ODD_NUMBERED_DAYS_REPEAT_STRING)) {
    selectedOption = 2
  } else if (repeat.includes(Constants.EVEN_NUMBERED_DAYS_REPEAT_STRING)) {
    selectedOption = 3
  } else {
    daysValue = repeat
    daysInt = parseInt(repeat)
    selectedOption = 1
  }

  return (
    <Box width="450px">
      <Box direction="column">
        <Box
          direction="row"
          pad="small"
          justify="between"
          align="center"
          background={
            selectedOption === 1
              ? colorThemes.getColorTheme(colorScheme).listItemSelectedColor
              : colorThemes.getColorTheme(colorScheme).screenBackgroundColor
          }>
          <Box direction="row" align="center" onClick={onChooseDays}>
            <Text>{I18n.t('repeatEvery')}</Text>
            <Box pad={{ horizontal: 'small' }} width="80px">
              <TextInput
                size="medium"
                value={daysValue}
                onChange={onChangeDays}
                margin="small"
              />
            </Box>
            <Text margin={{ right: '10px' }}>{I18n.t('repeatEveryDays')}</Text>
          </Box>
          {selectedOption === 1 && <Checkmark />}
        </Box>
        {selectedOption === 1 && (
          <Box
            pad="small"
            background={
              colorThemes.getColorTheme(colorScheme).listItemSelectedColor
            }>
            <SecondaryText>
              {isEmpty(daysValue)
                ? I18n.t('numberOfDaysShouldBeAtLeastOne')
                : I18n.t('alarmWillRingEveryNDays', {
                    dayWithOrdinal: Utils.getNumberWithOrdinal(daysInt),
                    count: daysInt - 1
                  })}
            </SecondaryText>
          </Box>
        )}
      </Box>
      <Box
        direction="row"
        pad="small"
        align="center"
        justify="between"
        onClick={onChooseOddNumberedDayRepeatType}
        background={
          selectedOption === 2
            ? colorThemes.getColorTheme(colorScheme).listItemSelectedColor
            : colorThemes.getColorTheme(colorScheme).screenBackgroundColor
        }>
        <Text> {I18n.t(Constants.RepeatTypes.ODD_NUMBERED_DAYS)}</Text>
        {selectedOption === 2 && <Checkmark />}
      </Box>
      {selectedOption === 2 && (
        <Box
          background={
            selectedOption === 2
              ? colorThemes.getColorTheme(colorScheme).listItemSelectedColor
              : colorThemes.getColorTheme(colorScheme).screenBackgroundColor
          }>
          <DaysOfWeekPicker
            selectedDays={selectedDays}
            onSelectDay={onSelectOddDay}
          />
          {renderHelperTextComponent(daysString, true, selectedDays)}
        </Box>
      )}
      <Box
        direction="row"
        pad="small"
        align="center"
        justify="between"
        onClick={onChooseEvenNumberedDayRepeatType}
        background={
          selectedOption === 3
            ? colorThemes.getColorTheme(colorScheme).listItemSelectedColor
            : colorThemes.getColorTheme(colorScheme).screenBackgroundColor
        }>
        <Text> {I18n.t(Constants.RepeatTypes.EVEN_NUMBERED_DAYS)}</Text>
        {selectedOption === 3 && <Checkmark />}
      </Box>
      {selectedOption === 3 && (
        <Box
          background={
            selectedOption === 3
              ? colorThemes.getColorTheme(colorScheme).listItemSelectedColor
              : colorThemes.getColorTheme(colorScheme).screenBackgroundColor
          }>
          <DaysOfWeekPicker
            selectedDays={selectedDays}
            onSelectDay={onSelectEvenDay}
          />
          {renderHelperTextComponent(daysString, false, selectedDays)}
        </Box>
      )}
    </Box>
  )
}

SelectDaysRepetition.defaultProps = {
  repeat: '2'
}

export default SelectDaysRepetition
