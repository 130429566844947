import { Emoji } from 'grommet-icons'
import { Button, Box } from 'grommet'
import React from 'react'
import classNames from 'classnames'
import { colorThemes } from 'galarm-config'
import { useSelector } from 'react-redux'

const TextAreaToolbarEmojiComponent = props => {
  const { expanded, onExpandEvent } = props

  const colorScheme = useSelector(state => state.userSettings.webColorScheme)

  const stopPropagation = event => {
    event.stopPropagation()
  }

  const onChange = emoji => {
    const { onChange } = props
    onChange(emoji.target.innerHTML)
  }

  const renderModal = () => {
    const {
      config: { popupClassName, emojis }
    } = props
    return (
      <div
        className={classNames('rdw-emoji-modal', popupClassName)}
        onClick={stopPropagation}>
        {emojis.map((emoji, index) => (
          <span
            key={index}
            className="rdw-emoji-icon"
            alt=""
            onClick={onChange}>
            {emoji}
          </span>
        ))}
      </div>
    )
  }
  const onEmojiClick = () => {
    onExpandEvent()
  }

  return (
    <Box
      border={
        expanded
          ? { color: colorThemes.getColorTheme(colorScheme).darkTint }
          : false
      }
      margin={{ left: 'small' }}
      round="small">
      <div
        className="rdw-emoji-wrapper"
        aria-haspopup="true"
        aria-label="rdw-emoji-control"
        aria-expanded={expanded}
        title={'emoji'}>
        <Button icon={<Emoji color="primary" />} onClick={onEmojiClick} />
        {expanded ? renderModal() : null}
      </div>
    </Box>
  )
}

export default TextAreaToolbarEmojiComponent
