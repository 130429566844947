import React from 'react'
import { Box } from 'grommet'

import Composer from './Composer'
import Send from './Send'

export default class InputToolbar extends React.Component {
  constructor(props) {
    super(props)
    this.composerRef = React.createRef()
  }

  renderSend() {
    if (this.props.renderSend) {
      return this.props.renderSend(this.props)
    }
    return <Send {...this.props} />
  }

  renderComposer() {
    if (this.props.renderComposer) {
      return this.props.renderComposer(this.props)
    }

    return <Composer {...this.props} ref={this.composerRef} />
  }

  resetValue() {
    this.composerRef.current.resetValue()
  }

  render() {
    return (
      <Box direction="row" margin="small">
        {this.renderComposer()}
        {this.renderSend()}
      </Box>
    )
  }
}

InputToolbar.defaultProps = {
  renderSend: null,
  renderComposer: null,
  inputToolbarContainerStyle: {}
}
