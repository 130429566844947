import React from 'react'
import { Constants, I18n, colors, GlobalConfig } from 'galarm-config'
import { Text, Box, List } from 'grommet'
import { useSelector } from 'react-redux'
import BadgeProvider from './BadgeProvider'
import ContactAvatar from './ContactAvatar'
import objGet from 'lodash/get'
import { Checkmark, Close, Help } from 'grommet-icons'
import TintedImage from './TintedImage'
import { AlarmUtils, Selectors } from 'galarm-shared'
import { SecondaryText } from 'web-components'

const { makeContactThumbnailSelector } = Selectors

const ParticipantSummary = ({ item, alarmType, alarmId }) => {
  const contactThumbnailSelector = makeContactThumbnailSelector()
  const avatarThumbnailUrl = useSelector(state =>
    contactThumbnailSelector(state, { id: item.id })
  )

  let badge,
    badgeBackgroundColor,
    backupStatus = null,
    acknowledgedAs = null

  if (item.responseStatus === Constants.ACCEPT_ALARM) {
    badge = <Checkmark color="white" size="small" />
    badgeBackgroundColor = colors.green
  } else if (item.responseStatus === Constants.REJECT_ALARM) {
    badge = <Close color="white" size="small" />
    badgeBackgroundColor = colors.red
  } else {
    badge = <Help color="white" size="small" />
    badgeBackgroundColor = colors.orange
  }

  if (item.seenOn) {
    backupStatus = (
      <TintedImage
        src={require('galarm-res/img/web/ic_done_all.svg').default}
        tintColor="primary"
      />
    )
  } else if (item.deliveredOn) {
    backupStatus = (
      <TintedImage
        src={require('galarm-res/img/web/ic_done_all.svg').default}
        tintColor="darkTint"
      />
    )
  } else {
    backupStatus = (
      <TintedImage
        src={require('galarm-res/img/web/ic_check.svg').default}
        tintColor="darkTint"
      />
    )
  }

  if (alarmType === Constants.AlarmTypes.SIMULTANEOUS) {
    const currAlarmDate = AlarmUtils.getCurrentDateForAlarmId(alarmId)
    const alarmAcknowledgement =
      AlarmUtils.getUserAlarmAcknowledgementStatusForOccurrence(
        alarmId,
        currAlarmDate,
        item.id
      )
    acknowledgedAs = alarmAcknowledgement ? (
      alarmAcknowledgement.response === Constants.GROUP_ALARM_YES ? (
        <TintedImage
          src={require('galarm-res/img/web/ic_thumb_up.svg').default}
          tintColor="green"
        />
      ) : (
        <TintedImage
          src={require('galarm-res/img/web/ic_thumb_down.svg').default}
          tintColor="red"
        />
      )
    ) : null
  }

  let participantName = item.name
  if (item.id === GlobalConfig.uid) {
    participantName = I18n.t('you')
  } else if (item.known === false) {
    participantName = '~' + participantName
  }

  if (item.state === Constants.ParticipantStates.INACTIVE) {
    return (
      <Box
        direction="row"
        gap="small"
        align="center"
        style={{
          opacity: 0.5
        }}>
        <ContactAvatar
          url={objGet(avatarThumbnailUrl, 'uri', undefined)}
          name={item.name}
        />
        <Text>{participantName}</Text>
        <SecondaryText>{I18n.t('notAlerted')}</SecondaryText>
      </Box>
    )
  }

  return (
    <Box direction="row" gap="small" align="center">
      <BadgeProvider badge={badge} backgroundColor={badgeBackgroundColor}>
        <ContactAvatar
          url={objGet(avatarThumbnailUrl, 'uri', undefined)}
          name={item.name}
        />
      </BadgeProvider>
      <Text color={item.known === false ? 'darkTint' : 'textColor'}>
        {participantName}
      </Text>
      {item.isCreator && <SecondaryText>{I18n.t('creator')}</SecondaryText>}
      {item.isRecipient && <SecondaryText>{I18n.t('recipient')}</SecondaryText>}
      {!item.isCreator && backupStatus}
      {alarmType === Constants.AlarmTypes.SIMULTANEOUS && acknowledgedAs}
    </Box>
  )
}

const ParticipantsListWithAvatar = ({
  backupGroup,
  backupContacts,
  recipient,
  type,
  creator,
  alarmId
}) => {
  if (type === Constants.AlarmTypes.RECIPIENT && recipient === null) {
    return <Text>{I18n.t('none')}</Text>
  } else if (
    type !== Constants.AlarmTypes.RECIPIENT &&
    backupGroup === null &&
    backupContacts.length === 0
  ) {
    return <Text>{I18n.t('none')}</Text>
  }

  let participants = []

  if (backupGroup) {
    let orderedMembers = backupGroup.members.sort((member1, member2) => {
      if (member1.state === Constants.ParticipantStates.INACTIVE) {
        return 1
      } else if (member2.state === Constants.ParticipantStates.INACTIVE) {
        return -1
      } else {
        return member1.order - member2.order
      }
    })
    participants = orderedMembers
  } else if (backupContacts.length > 0) {
    let orderedContacts = backupContacts.sort((contact1, contact2) => {
      return contact1.order - contact2.order
    })
    participants = orderedContacts
  } else if (recipient) {
    participants = [creator, { ...recipient, isRecipient: true }]
  }
  return (
    <Box>
      {backupGroup && (
        <Text>{I18n.t('groupName', { name: backupGroup.name })}</Text>
      )}
      <List data={participants} border={false} margin="xsmall" pad="xsmall">
        {participant => (
          <ParticipantSummary
            item={participant}
            alarmType={type}
            alarmId={alarmId}
          />
        )}
      </List>
    </Box>
  )
}

export default ParticipantsListWithAvatar
