import { Text } from 'grommet'
import React from 'react'

const BigText = ({ children, ...otherProps }) => {
  return (
    <Text size="large" {...otherProps}>
      {children}
    </Text>
  )
}

export default BigText
