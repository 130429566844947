import ActionTypes from '../actions/types'
import { createReducer } from 'reduxsauce'
import Utils from '../utils/Utils'
import objGet from 'lodash/get'
import { REHYDRATE } from 'redux-persist'

const INITIAL_STATE = {
  alarms: [],
  checklists: [],
  recentlyDeletedAlarms: [],
  existingAlarmsLoaded: false
}

const rehydrateState = (state, action) => {
  const persistedAlarms = objGet(action, 'payload.alarms', INITIAL_STATE)
  const newAlarms = persistedAlarms.alarms
    .filter(alarm => alarm.id)
    .map(alarm => ({
      ...alarm,
      preReminderDuration: alarm.preReminderDuration || 0
    }))
  return {
    ...state,
    ...persistedAlarms,
    alarms: newAlarms
  }
}

const updateAlarm = (state, action) => {
  const { alarmId, alarm } = action.payload
  const alarmIndex = Utils.getIndexOfObjectWithId(state.alarms, alarmId)

  let newAlarms = state.alarms.slice()

  // New alarm
  if (alarmIndex === -1) {
    newAlarms.push(alarm)
  } else {
    // Check if the alarm has changed
    newAlarms.splice(alarmIndex, 1, alarm)
  }

  return {
    ...state,
    alarms: newAlarms
  }
}

const updateAlarms = (state, action) => {
  const { alarms } = action.payload

  let newAlarms = state.alarms.slice()

  alarms.forEach(alarm => {
    const alarmIndex = Utils.getIndexOfObjectWithId(state.alarms, alarm.id)
    // New alarm
    if (alarmIndex === -1) {
      newAlarms.push(alarm)
    } else {
      // Check if the alarm has changed
      newAlarms.splice(alarmIndex, 1, alarm)
    }
  })

  return {
    ...state,
    alarms: newAlarms
  }
}

const addLocalAlarm = (state, action) => {
  const alarm = action.payload.alarm
  const localAlarm = {
    ...alarm,
    persistedOnline: false
  }

  let newAlarms = state.alarms.slice()
  const alarmIndex = Utils.getIndexOfObjectWithId(state.alarms, alarm.id)
  if (alarmIndex !== -1) {
    newAlarms.splice(alarmIndex, 1, localAlarm)
  } else {
    newAlarms.push(localAlarm)
  }

  return {
    ...state,
    alarms: newAlarms
  }
}

const addLocalChecklist = (state, action) => {
  const { checklist, persistedOnline = false } = action.payload
  const localChecklist = {
    ...checklist,
    persistedOnline: persistedOnline
  }

  let newChecklists = state.checklists.slice()
  const checklistIndex = Utils.getIndexOfObjectWithId(
    state.checklists,
    checklist.id
  )
  if (checklistIndex !== -1) {
    newChecklists.splice(checklistIndex, 1, localChecklist)
  } else {
    newChecklists.push(localChecklist)
  }

  return {
    ...state,
    checklists: newChecklists
  }
}

const updateLocalAlarm = (state, action) => {
  const alarm = action.payload.alarm
  const localAlarm = {
    ...alarm,
    persistedOnline: false
  }
  const alarmIndex = Utils.getIndexOfObjectWithId(state.alarms, alarm.id)
  if (alarmIndex !== -1) {
    let newAlarms = state.alarms.slice()
    newAlarms.splice(alarmIndex, 1, localAlarm)
    return {
      ...state,
      alarms: newAlarms
    }
  } else {
    console.tron.log('Trying to edit a non-existent alarm')
    return state
  }
}

const updateLocalChecklist = (state, action) => {
  const checklist = action.payload.checklist
  const localChecklist = {
    ...checklist,
    persistedOnline: false
  }
  const checklistIndex = Utils.getIndexOfObjectWithId(
    state.checklists,
    checklist.id
  )
  if (checklistIndex !== -1) {
    let newChecklists = state.checklists.slice()
    newChecklists.splice(checklistIndex, 1, localChecklist)
    return {
      ...state,
      checklists: newChecklists
    }
  } else {
    console.tron.log('Trying to edit a non-existent checklist')
    return state
  }
}

const removeLocalChecklist = (state, action) => {
  const checklistId = action.payload.checklistId
  const checklistIndex = Utils.getIndexOfObjectWithId(
    state.checklists,
    checklistId
  )
  if (checklistIndex !== -1) {
    let newChecklists = state.checklists.slice()
    newChecklists.splice(checklistIndex, 1)
    return {
      ...state,
      checklists: newChecklists
    }
  } else {
    return state
  }
}

// Soft updates don't change the persistedOnline flag such that they are not
// colored grey in the app
const softUpdateLocalAlarm = (state, action) => {
  const alarm = action.payload.alarm
  const alarmIndex = Utils.getIndexOfObjectWithId(state.alarms, alarm.id)
  if (alarmIndex !== -1) {
    let newAlarms = state.alarms.slice()
    newAlarms.splice(alarmIndex, 1, alarm)
    return {
      ...state,
      alarms: newAlarms
    }
  } else {
    console.tron.log('Trying to edit a non-existent alarm')
    return state
  }
}

const removeLocalAlarm = (state, action) => {
  const alarmId = action.payload.alarmId
  const alarmIndex = Utils.getIndexOfObjectWithId(state.alarms, alarmId)

  if (alarmIndex !== -1) {
    let newAlarms = state.alarms.slice()
    newAlarms.splice(alarmIndex, 1)
    return {
      ...state,
      alarms: newAlarms
    }
  } else {
    return state
  }
}

const removeLocalAlarms = (state, action) => {
  const alarmIds = action.payload.alarmIds
  let newAlarms = state.alarms.slice()
  let performUpdate = false
  alarmIds.forEach(alarmId => {
    const alarmIndex = Utils.getIndexOfObjectWithId(newAlarms, alarmId)
    if (alarmIndex !== -1) {
      performUpdate = true
      newAlarms.splice(alarmIndex, 1)
    }
  })

  if (performUpdate) {
    return {
      ...state,
      alarms: newAlarms
    }
  } else {
    return state
  }
}

const expireAlarms = (state, action) => {
  const alarmIds = action.payload.alarmIds
  if (alarmIds.length === 0) {
    return state
  }

  // Don't update lastUpdatedAt because it will cause
  // the alarm to be synced.
  const newAlarms = state.alarms.map(alarm => {
    if (alarmIds.includes(alarm.id)) {
      return {
        ...alarm,
        lastExpiredAt: alarm.lastExpiredAt ? null : Date.now()
      }
    }
    return alarm
  })

  return {
    ...state,
    alarms: newAlarms
  }
}

// This is used to move the alarms from one section to another.
// We change a property locally that is not synced with fireabse
const updateAlarmsLocally = (state, action) => {
  const alarmIds = action.payload.alarmIds
  if (alarmIds.length === 0) {
    return state
  }

  const newAlarms = state.alarms.map(alarm => {
    if (alarmIds.includes(alarm.id)) {
      return {
        ...alarm,
        lastSeenAt: alarm.lastSeenAt ? null : Date.now()
      }
    }
    return alarm
  })

  return {
    ...state,
    alarms: newAlarms
  }
}

const unsnoozeAlarmsLocally = (state, action) => {
  const alarmIds = action.payload.alarmIds
  if (alarmIds.length === 0) {
    return state
  }

  const newAlarms = state.alarms.map(alarm => {
    if (alarmIds.includes(alarm.id)) {
      return {
        ...alarm,
        lastSnoozeByCreatorOn: null,
        lastSnoozeByCreatorFor: null
      }
    }
    return alarm
  })

  return {
    ...state,
    alarms: newAlarms
  }
}

// eslint-disable-next-line no-unused-vars
const setExistingAlarmsLoaded = (state, action) => {
  if (!state.existingAlarmsLoaded) {
    return {
      ...state,
      existingAlarmsLoaded: true
    }
  }
  return state
}

const setRecentlyDeletedAlarms = (state, action) => ({
  ...state,
  recentlyDeletedAlarms: action.payload.alarms
})

const setChecklists = (state, action) => {
  const { checklists } = action.payload

  const newChecklists = state.checklists.slice()
  checklists.forEach(checklist => {
    const checklistIndex = Utils.getIndexOfObjectWithId(
      newChecklists,
      checklist.id
    )
    if (checklistIndex !== -1) {
      const existingChecklist = newChecklists[checklistIndex]
      if (existingChecklist.lastUpdated < checklist.lastUpdatedAt) {
        newChecklists.splice(checklistIndex, 1, checklist)
      }
    } else {
      newChecklists.push(checklist)
    }
  })

  return {
    ...state,
    checklists: newChecklists
  }
}

const ACTION_HANDLERS = {
  [REHYDRATE]: rehydrateState,
  [ActionTypes.UPDATE_ALARM]: updateAlarm,
  [ActionTypes.UPDATE_ALARMS]: updateAlarms,
  [ActionTypes.SOFT_UPDATE_LOCAL_ALARM]: softUpdateLocalAlarm,
  [ActionTypes.ADD_LOCAL_ALARM]: addLocalAlarm,
  [ActionTypes.REMOVE_LOCAL_ALARM]: removeLocalAlarm,
  [ActionTypes.UPDATE_LOCAL_ALARM]: updateLocalAlarm,
  [ActionTypes.EXPIRE_ALARMS]: expireAlarms,
  [ActionTypes.REMOVE_LOCAL_ALARMS]: removeLocalAlarms,
  [ActionTypes.UPDATE_ALARMS_LOCALLY]: updateAlarmsLocally,
  [ActionTypes.UNSNOOZE_ALARMS_LOCALLY]: unsnoozeAlarmsLocally,
  [ActionTypes.SET_EXISTING_ALARMS_LOADED]: setExistingAlarmsLoaded,
  [ActionTypes.SET_RECENTLY_DELETED_ALARMS]: setRecentlyDeletedAlarms,
  [ActionTypes.ADD_LOCAL_CHECKLIST]: addLocalChecklist,
  [ActionTypes.UPDATE_LOCAL_CHECKLIST]: updateLocalChecklist,
  [ActionTypes.REMOVE_LOCAL_CHECKLIST]: removeLocalChecklist,
  [ActionTypes.SET_CHECKLISTS]: setChecklists
}

export default createReducer(INITIAL_STATE, ACTION_HANDLERS)
