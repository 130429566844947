import ActionTypes from '../actions/types'
import { createReducer } from 'reduxsauce'
import { Constants } from 'galarm-config'
import uuid from 'react-native-uuid'
import Utils from '../utils/Utils'
import arrayDifferenceWith from 'lodash/differenceWith'

const INITIAL_STATE = {
  pendingActions: [],
  softPendingActions: []
}

const addAddAlarmPendingAction = (state, action) => {
  let newPendingActions = state.pendingActions.slice()
  const pendingAction = {
    name: Constants.ADD_ALARM_PENDING_ACTION_NAME,
    id: uuid.v4(),
    alarm: action.payload.alarm
  }
  newPendingActions.push(pendingAction)
  return {
    ...state,
    pendingActions: newPendingActions
  }
}

const addAddChecklistPendingAction = (state, action) => {
  let newPendingActions = state.pendingActions.slice()
  const pendingAction = {
    name: Constants.ADD_CHECKLIST_PENDING_ACTION_NAME,
    id: uuid.v4(),
    checklist: action.payload.checklist
  }
  newPendingActions.push(pendingAction)
  return {
    ...state,
    pendingActions: newPendingActions
  }
}

const addEnableDisableAlarmPendingAction = (state, action) => {
  // eslint-disable-line no-unused-vars
  let newPendingActions = state.pendingActions.slice()
  const existingEnableDisableAlarmPendingActionIndex =
    state.pendingActions.findIndex(pendingAction => {
      return (
        pendingAction.name ===
          Constants.ADD_ENABLE_DISABLE_ALARM_PENDING_ACTION_NAME &&
        pendingAction.alarm.id === action.payload.alarm.id
      )
    })
  if (existingEnableDisableAlarmPendingActionIndex !== -1) {
    newPendingActions.splice(existingEnableDisableAlarmPendingActionIndex, 1)
  }
  const pendingAction = {
    name: Constants.ADD_ENABLE_DISABLE_ALARM_PENDING_ACTION_NAME,
    id: uuid.v4(),
    alarm: action.payload.alarm,
    timestamp: action.payload.timestamp,
    alarmStatus: action.payload.alarmStatus
  }
  newPendingActions.push(pendingAction)
  return {
    ...state,
    pendingActions: newPendingActions
  }
}

const removePendingAction = (state, action) => {
  let newPendingActions = state.pendingActions.slice()
  console.tron.log('Remove pending action ' + action.payload.id)
  const actionIndex = Utils.getIndexOfObjectWithId(
    state.pendingActions,
    action.payload.id
  )
  if (actionIndex !== -1) {
    newPendingActions.splice(actionIndex, 1)
  } else {
    console.tron.log('Trying to remove a non-existent pending action ', action)
  }
  return {
    ...state,
    pendingActions: newPendingActions
  }
}

const removeSoftPendingAction = (state, action) => {
  let newSoftPendingActions = state.softPendingActions.slice()
  console.tron.log('Remove soft pending action ' + action.payload.id)
  const actionIndex = Utils.getIndexOfObjectWithId(
    state.softPendingActions,
    action.payload.id
  )
  if (actionIndex !== -1) {
    newSoftPendingActions.splice(actionIndex, 1)
  } else {
    console.tron.log('Trying to remove a non-existent pending action ', action)
  }
  return {
    ...state,
    softPendingActions: newSoftPendingActions
  }
}

const removeAllPendingActions = state => {
  return {
    ...state,
    pendingActions: [],
    softPendingActions: []
  }
}

const addDeleteAlarmPendingAction = (state, action) => {
  let newPendingActions = state.pendingActions.slice()
  let newSoftPendingActions = state.softPendingActions.slice()

  const getAlarmIdForPendingAction = pendingAction => {
    let pendingActionAlarmId = ''
    if (pendingAction.alarmId) {
      pendingActionAlarmId = pendingAction.alarmId
    } else if (pendingAction.alarm) {
      pendingActionAlarmId = pendingAction.alarm.id
    } else if (pendingAction.editedAlarm) {
      pendingActionAlarmId = pendingAction.editedAlarm.id
    }
    return pendingActionAlarmId
  }
  // If there are any existing pending actions for this alarm, remove them
  const existingPendingActionsForAlarm = state.pendingActions.filter(
    pendingAction => {
      const pendingActionAlarmId = getAlarmIdForPendingAction(pendingAction)
      return pendingActionAlarmId === action.payload.alarm.id
    }
  )

  const existingSoftPendingActionsForAlarm = state.softPendingActions.filter(
    pendingAction => {
      const pendingActionAlarmId = getAlarmIdForPendingAction(pendingAction)
      return pendingActionAlarmId === action.payload.alarm.id
    }
  )

  if (existingPendingActionsForAlarm.length > 0) {
    newPendingActions = arrayDifferenceWith(
      state.pendingActions,
      existingPendingActionsForAlarm,
      (action1, action2) => action1.id === action2.id
    )
  }

  if (existingSoftPendingActionsForAlarm.length > 0) {
    newSoftPendingActions = arrayDifferenceWith(
      state.softPendingActions,
      existingSoftPendingActionsForAlarm,
      (action1, action2) => action1.id === action2.id
    )
  }

  const pendingAction = {
    name: Constants.DELETE_ALARM_PENDING_ACTION_NAME,
    id: uuid.v4(),
    alarm: action.payload.alarm
  }

  newPendingActions.push(pendingAction)

  return {
    ...state,
    pendingActions: newPendingActions,
    softPendingActions: newSoftPendingActions
  }
}

const addRemoveChecklistPendingAction = (state, action) => {
  const { checklistId } = action.payload
  let newPendingActions = state.pendingActions.slice()

  const newChecklistPendingActionIndex = state.pendingActions.findIndex(
    pendingAction => {
      return (
        pendingAction.name === Constants.ADD_CHECKLIST_PENDING_ACTION_NAME &&
        pendingAction.checklist.id === checklistId
      )
    }
  )

  if (newChecklistPendingActionIndex !== -1) {
    newPendingActions.splice(newChecklistPendingActionIndex, 1)
    return {
      ...state,
      pendingActions: newPendingActions
    }
  }

  const editChecklistPendingActionIndex = state.pendingActions.findIndex(
    pendingAction => {
      return (
        pendingAction.name === Constants.EDIT_CHECKLIST_PENDING_ACTION_NAME &&
        pendingAction.checklist.id === checklistId
      )
    }
  )

  if (editChecklistPendingActionIndex !== -1) {
    const pendingAction = {
      name: Constants.REMOVE_CHECKLIST_PENDING_ACTION_NAME,
      id: uuid.v4(),
      checklistId
    }
    newPendingActions.splice(editChecklistPendingActionIndex, 1, pendingAction)
  } else {
    const pendingAction = {
      name: Constants.REMOVE_CHECKLIST_PENDING_ACTION_NAME,
      id: uuid.v4(),
      checklistId
    }
    newPendingActions.push(pendingAction)
  }

  return {
    ...state,
    pendingActions: newPendingActions
  }
}

const addEditAlarmPendingAction = (state, action) => {
  const { editedAlarm, prevAlarm, removeSubscription } = action.payload
  let newPendingActions = state.pendingActions.slice()

  // If there is a pending action to create new alarm with same id, then the alarm has not been
  // pushed to the cloud yet. Just update the add operation to add the edited alarm
  const newAlarmPendingActionIndex = state.pendingActions.findIndex(
    pendingAction => {
      return (
        pendingAction.name === Constants.ADD_ALARM_PENDING_ACTION_NAME &&
        pendingAction.alarm.id === action.payload.editedAlarm.id
      )
    }
  )

  if (newAlarmPendingActionIndex !== -1) {
    newPendingActions.splice(newAlarmPendingActionIndex, 1)
    const pendingAction = {
      name: Constants.ADD_ALARM_PENDING_ACTION_NAME,
      id: uuid.v4(),
      alarm: editedAlarm
    }
    newPendingActions.push(pendingAction)
    return {
      ...state,
      pendingActions: newPendingActions
    }
  }

  // Check if we already have an edit alarm pending action for the same alarm
  // If yes, then merge the two
  const editAlarmPendingActionIndex = state.pendingActions.findIndex(
    pendingAction => {
      return (
        pendingAction.name === Constants.EDIT_ALARM_PENDING_ACTION_NAME &&
        pendingAction.editedAlarm.id === action.payload.editedAlarm.id
      )
    }
  )

  let pendingAction = null
  if (editAlarmPendingActionIndex !== -1) {
    const prevPendingAction = state.pendingActions[editAlarmPendingActionIndex]
    pendingAction = {
      name: Constants.EDIT_ALARM_PENDING_ACTION_NAME,
      id: uuid.v4(),
      editedAlarm,
      prevAlarm: prevPendingAction.prevAlarm,
      removeSubscription
    }
    newPendingActions.splice(editAlarmPendingActionIndex, 1)
  } else {
    pendingAction = {
      name: Constants.EDIT_ALARM_PENDING_ACTION_NAME,
      id: uuid.v4(),
      editedAlarm,
      prevAlarm,
      removeSubscription
    }
  }
  newPendingActions.push(pendingAction)
  return {
    ...state,
    pendingActions: newPendingActions
  }
}

const addEditChecklistPendingAction = (state, action) => {
  const { checklist } = action.payload
  let newPendingActions = state.pendingActions.slice()

  // If there is a pending action to create new alarm with same id, then the alarm has not been
  // pushed to the cloud yet. Just update the add operation to add the edited alarm
  const newChecklistPendingActionIndex = state.pendingActions.findIndex(
    pendingAction => {
      return (
        pendingAction.name === Constants.ADD_CHECKLIST_PENDING_ACTION_NAME &&
        pendingAction.checklist.id === checklist.id
      )
    }
  )

  if (newChecklistPendingActionIndex !== -1) {
    const pendingAction = {
      name: Constants.ADD_CHECKLIST_PENDING_ACTION_NAME,
      id: uuid.v4(),
      checklist: checklist
    }
    newPendingActions.splice(newChecklistPendingActionIndex, 1, pendingAction)
    return {
      ...state,
      pendingActions: newPendingActions
    }
  }

  // Check if we already have an edit alarm pending action for the same alarm
  // If yes, then merge the two
  const editChecklistPendingActionIndex = state.pendingActions.findIndex(
    pendingAction => {
      return (
        pendingAction.name === Constants.EDIT_CHECKLIST_PENDING_ACTION_NAME &&
        pendingAction.checklist.id === checklist.id
      )
    }
  )

  if (editChecklistPendingActionIndex !== -1) {
    const pendingAction = {
      name: Constants.EDIT_CHECKLIST_PENDING_ACTION_NAME,
      id: uuid.v4(),
      checklist: checklist
    }
    newPendingActions.splice(editChecklistPendingActionIndex, 1, pendingAction)
  } else {
    const pendingAction = {
      name: Constants.EDIT_CHECKLIST_PENDING_ACTION_NAME,
      id: uuid.v4(),
      checklist
    }
    newPendingActions.push(pendingAction)
  }
  return {
    ...state,
    pendingActions: newPendingActions
  }
}

const addStartSnoozeForCreatorSoftPendingAction = (state, action) => {
  const { alarmId, snoozeInterval, snoozeDate } = action.payload
  let newSoftPendingActions = state.softPendingActions.slice()
  const pendingActionIndex = state.softPendingActions.findIndex(
    pendingAction => {
      return (
        pendingAction.name ===
          Constants.STOP_SNOOZE_FOR_CREATOR_SOFT_PENDING_ACTION_NAME &&
        pendingAction.alarmId === alarmId
      )
    }
  )

  if (pendingActionIndex !== -1) {
    newSoftPendingActions.splice(pendingActionIndex, 1)
  }
  const softPendingAction = {
    name: Constants.START_SNOOZE_FOR_CREATOR_SOFT_PENDING_ACTION_NAME,
    id: uuid.v4(),
    alarmId,
    snoozeInterval,
    snoozeDate
  }
  newSoftPendingActions.push(softPendingAction)
  return {
    ...state,
    softPendingActions: newSoftPendingActions
  }
}

const addStopSnoozeForCreatorSoftPendingAction = (state, action) => {
  const { alarmId } = action.payload
  let newSoftPendingActions = state.softPendingActions.slice()
  const pendingActionIndex = state.softPendingActions.findIndex(
    pendingAction => {
      return (
        pendingAction.name ===
          Constants.START_SNOOZE_FOR_CREATOR_SOFT_PENDING_ACTION_NAME &&
        pendingAction.alarmId === alarmId
      )
    }
  )

  if (pendingActionIndex !== -1) {
    newSoftPendingActions.splice(pendingActionIndex, 1)
    return {
      ...state,
      softPendingActions: newSoftPendingActions
    }
  }
  const softPendingAction = {
    name: Constants.STOP_SNOOZE_FOR_CREATOR_SOFT_PENDING_ACTION_NAME,
    id: uuid.v4(),
    alarmId
  }
  newSoftPendingActions.push(softPendingAction)
  return {
    ...state,
    softPendingActions: newSoftPendingActions
  }
}

const addStartSnoozeForParticipantSoftPendingAction = (state, action) => {
  const { alarm, snoozeInterval, snoozeDate } = action.payload
  let newSoftPendingActions = state.softPendingActions.slice()
  const pendingActionIndex = state.softPendingActions.findIndex(
    pendingAction => {
      return (
        pendingAction.name ===
          Constants.STOP_SNOOZE_FOR_PARTICIPANT_SOFT_PENDING_ACTION_NAME &&
        pendingAction.alarm.id === alarm.id
      )
    }
  )

  if (pendingActionIndex !== -1) {
    newSoftPendingActions.splice(pendingActionIndex, 1)
  }
  const softPendingAction = {
    name: Constants.START_SNOOZE_FOR_PARTICIPANT_SOFT_PENDING_ACTION_NAME,
    id: uuid.v4(),
    alarm,
    snoozeInterval,
    snoozeDate
  }
  newSoftPendingActions.push(softPendingAction)
  return {
    ...state,
    softPendingActions: newSoftPendingActions
  }
}

const addStopSnoozeForParticipantSoftPendingAction = (state, action) => {
  const { alarm } = action.payload
  let newSoftPendingActions = state.softPendingActions.slice()
  const pendingActionIndex = state.softPendingActions.findIndex(
    pendingAction => {
      return (
        pendingAction.name ===
          Constants.START_SNOOZE_FOR_PARTICIPANT_SOFT_PENDING_ACTION_NAME &&
        pendingAction.alarm.id === alarm.id
      )
    }
  )

  if (pendingActionIndex !== -1) {
    newSoftPendingActions.splice(pendingActionIndex, 1)
    return {
      ...state,
      softPendingActions: newSoftPendingActions
    }
  }
  const softPendingAction = {
    name: Constants.STOP_SNOOZE_FOR_PARTICIPANT_SOFT_PENDING_ACTION_NAME,
    id: uuid.v4(),
    alarm
  }
  newSoftPendingActions.push(softPendingAction)
  return {
    ...state,
    softPendingActions: newSoftPendingActions
  }
}

const addMarkPersonalAlarmDoneSoftPendingAction = (state, action) => {
  const { alarm, occurrenceTime, timestamp } = action.payload
  let newSoftPendingActions = state.softPendingActions.slice()
  const softPendingAction = {
    name: Constants.MARK_PERSONAL_ALARM_DONE_SOFT_PENDING_ACTION_NAME,
    id: uuid.v4(),
    alarm,
    occurrenceTime,
    timestamp
  }
  newSoftPendingActions.push(softPendingAction)
  return {
    ...state,
    softPendingActions: newSoftPendingActions
  }
}

const addSkipPersonalAlarmSoftPendingAction = (state, action) => {
  const { alarm, occurrenceTime, timestamp } = action.payload
  let newSoftPendingActions = state.softPendingActions.slice()
  const softPendingAction = {
    name: Constants.SKIP_PERSONAL_ALARM_SOFT_PENDING_ACTION_NAME,
    id: uuid.v4(),
    alarm,
    occurrenceTime,
    timestamp
  }
  newSoftPendingActions.push(softPendingAction)
  return {
    ...state,
    softPendingActions: newSoftPendingActions
  }
}

const addSkipPersonalParticipantAlarmSoftPendingAction = (state, action) => {
  const { alarm, occurrenceTime, timestamp } = action.payload
  let newSoftPendingActions = state.softPendingActions.slice()
  const softPendingAction = {
    name: Constants.SKIP_PERSONAL_PARTICIPANT_ALARM_SOFT_PENDING_ACTION_NAME,
    id: uuid.v4(),
    alarm,
    occurrenceTime,
    timestamp
  }
  newSoftPendingActions.push(softPendingAction)
  return {
    ...state,
    softPendingActions: newSoftPendingActions
  }
}

const addSkipRecipientAlarmSoftPendingAction = (state, action) => {
  const { alarm, occurrenceTime, timestamp } = action.payload
  let newSoftPendingActions = state.softPendingActions.slice()
  const softPendingAction = {
    name: Constants.SKIP_RECIPIENT_ALARM_SOFT_PENDING_ACTION_NAME,
    id: uuid.v4(),
    alarm,
    occurrenceTime,
    timestamp
  }
  newSoftPendingActions.push(softPendingAction)
  return {
    ...state,
    softPendingActions: newSoftPendingActions
  }
}

const addSkipRecipientCreatorAlarmSoftPendingAction = (state, action) => {
  const { alarm, occurrenceTime, timestamp } = action.payload
  let newSoftPendingActions = state.softPendingActions.slice()
  const softPendingAction = {
    name: Constants.SKIP_RECIPIENT_CREATOR_ALARM_SOFT_PENDING_ACTION_NAME,
    id: uuid.v4(),
    alarm,
    occurrenceTime,
    timestamp
  }
  newSoftPendingActions.push(softPendingAction)
  return {
    ...state,
    softPendingActions: newSoftPendingActions
  }
}

const addMarkRecipientAlarmDoneSoftPendingAction = (state, action) => {
  const { alarm, occurrenceTime, timestamp } = action.payload
  let newSoftPendingActions = state.softPendingActions.slice()
  const softPendingAction = {
    name: Constants.MARK_RECIPIENT_ALARM_DONE_SOFT_PENDING_ACTION_NAME,
    id: uuid.v4(),
    alarm,
    occurrenceTime,
    timestamp
  }
  newSoftPendingActions.push(softPendingAction)
  return {
    ...state,
    softPendingActions: newSoftPendingActions
  }
}

const addMarkCreatorResponseForGroupAlarmSoftPendingAction = (
  state,
  action
) => {
  const { alarm, response, rescheduleAlarm, occurrenceTime, timestamp } =
    action.payload

  let newSoftPendingActions = state.softPendingActions.slice()
  const softPendingAction = {
    name: Constants.MARK_CREATOR_RESPONSE_FOR_GROUP_ALARM_SOFT_PENDING_ACTION_NAME,
    id: uuid.v4(),
    alarm,
    response,
    rescheduleAlarm,
    occurrenceTime,
    timestamp
  }
  newSoftPendingActions.push(softPendingAction)

  return {
    ...state,
    softPendingActions: newSoftPendingActions
  }
}

const addMarkParticipantResponseForGroupAlarmSoftPendingAction = (
  state,
  action
) => {
  const {
    alarm,
    response,
    rescheduleAlarm,
    updateAlarmResponseStatus,
    occurrenceTime,
    timestamp
  } = action.payload

  let newSoftPendingActions = state.softPendingActions.slice()
  const softPendingAction = {
    name: Constants.MARK_PARTICIPANT_RESPONSE_FOR_GROUP_ALARM_SOFT_PENDING_ACTION_NAME,
    id: uuid.v4(),
    alarm,
    response,
    rescheduleAlarm,
    updateAlarmResponseStatus,
    occurrenceTime,
    timestamp
  }
  newSoftPendingActions.push(softPendingAction)

  return {
    ...state,
    softPendingActions: newSoftPendingActions
  }
}

const addSetParticipantAlarmResponseSoftPendingAction = (state, action) => {
  const { alarm, participantResponse } = action.payload
  let newSoftPendingActions = state.softPendingActions.slice()
  const pendingActionIndex = state.softPendingActions.findIndex(
    pendingAction => {
      return (
        pendingAction.name ===
          Constants.SET_PARTICIPANT_ALARM_RESPONSE_SOFT_PENDING_ACTION_NAME &&
        pendingAction.alarm.id === alarm.id
      )
    }
  )

  // Update the same action if it already exists such that the previous
  // response status is picked correctly when the action is persisted
  if (pendingActionIndex !== -1) {
    const softPendingAction = newSoftPendingActions[pendingActionIndex]
    newSoftPendingActions.splice(pendingActionIndex, 1, {
      ...softPendingAction,
      participantResponse
    })
    return {
      ...state,
      softPendingActions: newSoftPendingActions
    }
  } else {
    const softPendingAction = {
      name: Constants.SET_PARTICIPANT_ALARM_RESPONSE_SOFT_PENDING_ACTION_NAME,
      id: uuid.v4(),
      alarm,
      participantResponse
    }
    newSoftPendingActions.push(softPendingAction)
    return {
      ...state,
      softPendingActions: newSoftPendingActions
    }
  }
}

const addSetRecipientAlarmResponseSoftPendingAction = (state, action) => {
  const { alarm, participantResponse } = action.payload
  let newSoftPendingActions = state.softPendingActions.slice()
  const pendingActionIndex = state.softPendingActions.findIndex(
    pendingAction => {
      return (
        pendingAction.name ===
          Constants.SET_RECIPIENT_ALARM_RESPONSE_SOFT_PENDING_ACTION_NAME &&
        pendingAction.alarm.id === alarm.id
      )
    }
  )

  // Update the same action if it already exists such that the previous
  // response status is picked correctly when the action is persisted
  if (pendingActionIndex !== -1) {
    const softPendingAction = newSoftPendingActions[pendingActionIndex]
    newSoftPendingActions.splice(pendingActionIndex, 1, {
      ...softPendingAction,
      participantResponse
    })
    return {
      ...state,
      softPendingActions: newSoftPendingActions
    }
  } else {
    const softPendingAction = {
      name: Constants.SET_RECIPIENT_ALARM_RESPONSE_SOFT_PENDING_ACTION_NAME,
      id: uuid.v4(),
      alarm,
      participantResponse
    }
    newSoftPendingActions.push(softPendingAction)
    return {
      ...state,
      softPendingActions: newSoftPendingActions
    }
  }
}

const addSetPastAlarmOccurrenceResponseSoftPendingAction = (state, action) => {
  const { alarm, occurrenceTime, timestamp, response } = action.payload
  let newSoftPendingActions = state.softPendingActions.slice()
  const softPendingAction = {
    name: Constants.SET_PAST_ALARM_OCCURRENCE_RESPONSE_SOFT_PENDING_ACTION_NAME,
    id: uuid.v4(),
    alarm,
    occurrenceTime,
    timestamp,
    response
  }
  newSoftPendingActions.push(softPendingAction)
  return {
    ...state,
    softPendingActions: newSoftPendingActions
  }
}

const addUpdateParticipantAlarmRingerSettingsSoftPendingAction = (
  state,
  action
) => {
  let newSoftPendingActions = state.softPendingActions.slice()
  const pendingActionIndex = state.softPendingActions.findIndex(
    pendingAction => {
      return (
        pendingAction.name ===
          Constants.ADD_UPDATE_PARTICIPANT_ALARM_RINGER_SETTINGS_SOFT_PENDING_ACTION_NAME &&
        pendingAction.alarm.id === action.payload.alarm.id
      )
    }
  )
  if (pendingActionIndex !== -1) {
    newSoftPendingActions.splice(pendingActionIndex, 1)
  }
  const pendingAction = {
    name: Constants.ADD_UPDATE_PARTICIPANT_ALARM_RINGER_SETTINGS_SOFT_PENDING_ACTION_NAME,
    id: uuid.v4(),
    alarm: action.payload.alarm,
    ringerSettings: action.payload.ringerSettings
  }
  newSoftPendingActions.push(pendingAction)
  return {
    ...state,
    softPendingActions: newSoftPendingActions
  }
}

const addUpdateGestureToDismissAnAlarmSoftPendingAction = (state, action) => {
  let newSoftPendingActions = state.softPendingActions.slice()
  const pendingActionIndex = state.softPendingActions.findIndex(
    pendingAction => {
      return (
        pendingAction.name ===
        Constants.UPDATE_GESTURE_TO_DISMISS_AN_ALARM_SOFT_PENDING_ACTION_NAME
      )
    }
  )

  if (pendingActionIndex !== -1) {
    newSoftPendingActions.splice(pendingActionIndex, 1)
  }
  const pendingAction = {
    name: Constants.UPDATE_GESTURE_TO_DISMISS_AN_ALARM_SOFT_PENDING_ACTION_NAME,
    id: uuid.v4(),
    gestureToDismissAnAlarm: action.payload.gestureToDismissAnAlarm
  }
  newSoftPendingActions.push(pendingAction)

  return {
    ...state,
    softPendingActions: newSoftPendingActions
  }
}

const addUpdateNotificationSettingsSoftPendingAction = (state, action) => {
  let newSoftPendingActions = state.softPendingActions.slice()
  const pendingActionIndex = state.softPendingActions.findIndex(
    pendingAction => {
      return (
        pendingAction.name ===
        Constants.UPDATE_NOTIFICATION_SETTINGS_SOFT_PENDING_ACTION_NAME
      )
    }
  )

  if (pendingActionIndex !== -1) {
    newSoftPendingActions.splice(pendingActionIndex, 1)
  }
  const pendingAction = {
    name: Constants.UPDATE_NOTIFICATION_SETTINGS_SOFT_PENDING_ACTION_NAME,
    id: uuid.v4(),
    notificationSettings: action.payload.notificationSettings
  }
  newSoftPendingActions.push(pendingAction)

  return {
    ...state,
    softPendingActions: newSoftPendingActions
  }
}

const addUpdateGraduallyIncreaseVolumeSoftPendingAction = (state, action) => {
  let newSoftPendingActions = state.softPendingActions.slice()
  const pendingActionIndex = state.softPendingActions.findIndex(
    pendingAction => {
      return (
        pendingAction.name ===
        Constants.UPDATE_GRADUALLY_INCREASE_VOLUME_SOFT_PENDING_ACTION_NAME
      )
    }
  )
  // If there is already a pending action to update the setting, then just remove the pending action
  // as if it were previously disabled, now it must be enabled which should be the current value on
  // the database and vice versa
  if (pendingActionIndex !== -1) {
    newSoftPendingActions.splice(pendingActionIndex, 1)
  } else {
    const pendingAction = {
      name: Constants.UPDATE_GRADUALLY_INCREASE_VOLUME_SOFT_PENDING_ACTION_NAME,
      id: uuid.v4(),
      graduallyIncreaseVolume: action.payload.graduallyIncreaseVolume
    }
    newSoftPendingActions.push(pendingAction)
  }
  return {
    ...state,
    softPendingActions: newSoftPendingActions
  }
}

const addUpdateNotificationActionBehaviorSoftPendingAction = (
  state,
  action
) => {
  let newSoftPendingActions = state.softPendingActions.slice()
  const pendingActionIndex = state.softPendingActions.findIndex(
    pendingAction => {
      return (
        pendingAction.name ===
        Constants.UPDATE_NOTIFICATION_ACTION_BEHAVIOR_SOFT_PENDING_ACTION_NAME
      )
    }
  )
  // If there is already a pending action to update the setting, then just remove the pending action
  // as if it were previously disabled, now it must be enabled which should be the current value on
  // the database and vice versa
  if (pendingActionIndex !== -1) {
    newSoftPendingActions.splice(pendingActionIndex, 1)
  } else {
    const pendingAction = {
      name: Constants.UPDATE_NOTIFICATION_ACTION_BEHAVIOR_SOFT_PENDING_ACTION_NAME,
      id: uuid.v4(),
      notificationActionBehavior: action.payload.notificationActionBehavior
    }
    newSoftPendingActions.push(pendingAction)
  }
  return {
    ...state,
    softPendingActions: newSoftPendingActions
  }
}

const addUpdateDefaultTimezoneForAlarmSoftPendingAction = (state, action) => {
  let newSoftPendingActions = state.softPendingActions.slice()
  const pendingActionIndex = state.softPendingActions.findIndex(
    pendingAction => {
      return (
        pendingAction.name ===
        Constants.ADD_UPDATE_DEFAULT_TIMEZONE_FOR_ALARM_SOFT_PENDING_ACTION_NAME
      )
    }
  )
  // If there is already a pending action to update the setting, then just remove the pending action
  // as if it were previously disabled, now it must be enabled which should be the current value on
  // the database and vice versa
  if (pendingActionIndex !== -1) {
    newSoftPendingActions.splice(pendingActionIndex, 1)
  } else {
    const pendingAction = {
      name: Constants.ADD_UPDATE_DEFAULT_TIMEZONE_FOR_ALARM_SOFT_PENDING_ACTION_NAME,
      id: uuid.v4(),
      defaultTimezoneForAlarm: action.payload.defaultTimezoneForAlarm
    }
    newSoftPendingActions.push(pendingAction)
  }
  return {
    ...state,
    softPendingActions: newSoftPendingActions
  }
}

const addUpdateSpecifyTimezoneForAlarmSoftPendingAction = (state, action) => {
  let newSoftPendingActions = state.softPendingActions.slice()
  const pendingActionIndex = state.softPendingActions.findIndex(
    pendingAction => {
      return (
        pendingAction.name ===
        Constants.ADD_UPDATE_SPECIFY_TIMEZONE_FOR_ALARM_SOFT_PENDING_ACTION_NAME
      )
    }
  )
  // If there is already a pending action to update the setting, then just remove the pending action
  // as if it were previously disabled, now it must be enabled which should be the current value on
  // the database and vice versa
  if (pendingActionIndex !== -1) {
    newSoftPendingActions.splice(pendingActionIndex, 1)
  } else {
    const pendingAction = {
      name: Constants.ADD_UPDATE_SPECIFY_TIMEZONE_FOR_ALARM_SOFT_PENDING_ACTION_NAME,
      id: uuid.v4(),
      specifyTimezoneForAlarm: action.payload.specifyTimezoneForAlarm
    }
    newSoftPendingActions.push(pendingAction)
  }
  return {
    ...state,
    softPendingActions: newSoftPendingActions
  }
}

const addUpdateRingOnEarphoneOnlySoftPendingAction = (state, action) => {
  let newSoftPendingActions = state.softPendingActions.slice()
  const pendingActionIndex = state.softPendingActions.findIndex(
    pendingAction => {
      return (
        pendingAction.name ===
        Constants.UPDATE_RING_ON_EARPHONE_ONLY_SOFT_PENDING_ACTION_NAME
      )
    }
  )

  if (pendingActionIndex !== -1) {
    newSoftPendingActions.splice(pendingActionIndex, 1)
  }
  const pendingAction = {
    name: Constants.UPDATE_RING_ON_EARPHONE_ONLY_SOFT_PENDING_ACTION_NAME,
    id: uuid.v4(),
    ringOnEarphoneOnly: action.payload.ringOnEarphoneOnly
  }
  newSoftPendingActions.push(pendingAction)

  return {
    ...state,
    softPendingActions: newSoftPendingActions
  }
}

const addUpdateParticipantAlarmPreReminderDurationSoftPendingAction = (
  state,
  action
) => {
  let newSoftPendingActions = state.softPendingActions.slice()
  const pendingActionIndex = state.softPendingActions.findIndex(
    pendingAction => {
      return (
        pendingAction.name ===
          Constants.ADD_UPDATE_PARTICIPANT_ALARM_PRE_REMINDER_DURATION_SOFT_PENDING_ACTION_NAME &&
        pendingAction.alarm.id === action.payload.alarm.id
      )
    }
  )
  if (pendingActionIndex !== -1) {
    newSoftPendingActions.splice(pendingActionIndex, 1)
  }
  const pendingAction = {
    name: Constants.ADD_UPDATE_PARTICIPANT_ALARM_PRE_REMINDER_DURATION_SOFT_PENDING_ACTION_NAME,
    id: uuid.v4(),
    alarm: action.payload.alarm,
    preReminderDuration: action.payload.preReminderDuration
  }
  newSoftPendingActions.push(pendingAction)
  return {
    ...state,
    softPendingActions: newSoftPendingActions
  }
}

const addUpdateAlarmPreReminderDurationSoftPendingAction = (state, action) => {
  let newSoftPendingActions = state.softPendingActions.slice()
  const pendingActionIndex = state.softPendingActions.findIndex(
    pendingAction => {
      return (
        pendingAction.name ===
          Constants.ADD_UPDATE_ALARM_PRE_REMINDER_DURATION_SOFT_PENDING_ACTION_NAME &&
        pendingAction.alarm.id === action.payload.alarm.id
      )
    }
  )
  if (pendingActionIndex !== -1) {
    newSoftPendingActions.splice(pendingActionIndex, 1)
  }
  const pendingAction = {
    name: Constants.ADD_UPDATE_ALARM_PRE_REMINDER_DURATION_SOFT_PENDING_ACTION_NAME,
    id: uuid.v4(),
    alarm: action.payload.alarm,
    preReminderDuration: action.payload.preReminderDuration
  }
  newSoftPendingActions.push(pendingAction)
  return {
    ...state,
    softPendingActions: newSoftPendingActions
  }
}

const addUpdateAlarmRingerSettingsSoftPendingAction = (state, action) => {
  let newSoftPendingActions = state.softPendingActions.slice()
  const pendingActionIndex = state.softPendingActions.findIndex(
    pendingAction => {
      return (
        pendingAction.name ===
          Constants.ADD_UPDATE_ALARM_RINGER_SETTINGS_SOFT_PENDING_ACTION_NAME &&
        pendingAction.alarm.id === action.payload.alarm.id
      )
    }
  )
  if (pendingActionIndex !== -1) {
    newSoftPendingActions.splice(pendingActionIndex, 1)
  }
  const pendingAction = {
    name: Constants.ADD_UPDATE_ALARM_RINGER_SETTINGS_SOFT_PENDING_ACTION_NAME,
    id: uuid.v4(),
    alarm: action.payload.alarm,
    ringerSettings: action.payload.ringerSettings
  }
  newSoftPendingActions.push(pendingAction)
  return {
    ...state,
    softPendingActions: newSoftPendingActions
  }
}

const addUpdateGlobalAlarmRingerSettingsSoftPendingAction = (state, action) => {
  let newSoftPendingActions = state.softPendingActions.slice()
  const pendingActionIndex = state.softPendingActions.findIndex(
    pendingAction => {
      return (
        pendingAction.name ===
        Constants.ADD_UPDATE_GLOBAL_ALARM_RINGER_SETTINGS_SOFT_PENDING_ACTION_NAME
      )
    }
  )
  if (pendingActionIndex !== -1) {
    newSoftPendingActions.splice(pendingActionIndex, 1)
  }
  const pendingAction = {
    name: Constants.ADD_UPDATE_GLOBAL_ALARM_RINGER_SETTINGS_SOFT_PENDING_ACTION_NAME,
    id: uuid.v4(),
    ringerSettings: action.payload.ringerSettings
  }
  newSoftPendingActions.push(pendingAction)
  return {
    ...state,
    softPendingActions: newSoftPendingActions
  }
}

const addUpdateRingParticipantAlarmsByDefaultSettingSoftPendingAction = (
  state,
  action
) => {
  let newSoftPendingActions = state.softPendingActions.slice()
  const pendingActionIndex = state.softPendingActions.findIndex(
    pendingAction => {
      return (
        pendingAction.name ===
        Constants.ADD_UPDATE_RING_PARTICIPANT_ALARMS_BY_DEFAULT_SETTING_SOFT_PENDING_ACTION_NAME
      )
    }
  )

  // If there is already a pending action to update the setting, then just remove the pending action
  // as if it were previously disabled, now it must be enabled which should be the current value on
  // the database and vice versa
  if (pendingActionIndex !== -1) {
    newSoftPendingActions.splice(pendingActionIndex, 1)
  } else {
    const pendingAction = {
      name: Constants.ADD_UPDATE_RING_PARTICIPANT_ALARMS_BY_DEFAULT_SETTING_SOFT_PENDING_ACTION_NAME,
      id: uuid.v4(),
      ringParticipantAlarmsByDefault:
        action.payload.ringParticipantAlarmsByDefault
    }
    newSoftPendingActions.push(pendingAction)
  }
  return {
    ...state,
    softPendingActions: newSoftPendingActions
  }
}

const addAddAlarmToAlarmCategorySoftPendingAction = (state, action) => {
  const { alarmCategoryId, alarmId } = action.payload

  let newSoftPendingActions = state.softPendingActions.slice()
  const pendingAction = {
    name: Constants.ADD_ALARM_TO_ALARM_CATEGORY_SOFT_PENDING_ACTION_NAME,
    id: uuid.v4(),
    alarmCategoryId,
    alarmId
  }
  newSoftPendingActions.push(pendingAction)
  return {
    ...state,
    softPendingActions: newSoftPendingActions
  }
}

const addRemoveAlarmFromAlarmCategorySoftPendingAction = (state, action) => {
  const { alarmCategoryId, alarmId } = action.payload

  let newSoftPendingActions = state.softPendingActions.slice()
  const pendingAction = {
    name: Constants.REMOVE_ALARM_FROM_ALARM_CATEGORY_SOFT_PENDING_ACTION_NAME,
    id: uuid.v4(),
    alarmCategoryId,
    alarmId
  }
  newSoftPendingActions.push(pendingAction)
  return {
    ...state,
    softPendingActions: newSoftPendingActions
  }
}

const addDeleteAlarmCategorySoftPendingAction = (state, action) => {
  const { alarmCategoryId } = action.payload

  let newSoftPendingActions = state.softPendingActions.filter(
    pendingAction => pendingAction.alarmCategoryId !== alarmCategoryId
  )

  const pendingAction = {
    name: Constants.DELETE_ALARM_CATEGORY_SOFT_PENDING_ACTION_NAME,
    id: uuid.v4(),
    alarmCategoryId
  }
  newSoftPendingActions.push(pendingAction)
  return {
    ...state,
    softPendingActions: newSoftPendingActions
  }
}

const addUpdateAlarmCategorySoftPendingAction = (state, action) => {
  const { alarmCategoryId, alarmCategory } = action.payload

  let newSoftPendingActions = state.softPendingActions.slice()
  const pendingAction = {
    name: Constants.UPDATE_ALARM_CATEGORY_SOFT_PENDING_ACTION_NAME,
    id: uuid.v4(),
    alarmCategoryId,
    alarmCategory
  }

  const existingPendingActionForCategoryIndex = newSoftPendingActions.findIndex(
    pendingAction =>
      pendingAction.name ===
        Constants.UPDATE_ALARM_CATEGORY_SOFT_PENDING_ACTION_NAME &&
      pendingAction.alarmCategoryId === alarmCategoryId
  )

  if (existingPendingActionForCategoryIndex !== -1) {
    newSoftPendingActions.splice(
      existingPendingActionForCategoryIndex,
      1,
      pendingAction
    )
  } else {
    newSoftPendingActions.push(pendingAction)
  }

  return {
    ...state,
    softPendingActions: newSoftPendingActions
  }
}

const addCreateAlarmCategorySoftPendingAction = (state, action) => {
  const { alarmCategoryId, alarmCategory } = action.payload

  let newSoftPendingActions = state.softPendingActions.slice()
  const pendingAction = {
    name: Constants.CREATE_ALARM_CATEGORY_SOFT_PENDING_ACTION_NAME,
    id: uuid.v4(),
    alarmCategoryId,
    alarmCategory
  }
  newSoftPendingActions.push(pendingAction)
  return {
    ...state,
    softPendingActions: newSoftPendingActions
  }
}

const ACTION_HANDLERS = {
  [ActionTypes.ADD_ADD_ALARM_PENDING_ACTION]: addAddAlarmPendingAction,
  [ActionTypes.REMOVE_PENDING_ACTION]: removePendingAction,
  [ActionTypes.REMOVE_ALL_PENDING_ACTIONS]: removeAllPendingActions,
  [ActionTypes.ADD_DELETE_ALARM_PENDING_ACTION]: addDeleteAlarmPendingAction,
  [ActionTypes.ADD_EDIT_ALARM_PENDING_ACTION]: addEditAlarmPendingAction,
  [ActionTypes.ADD_ENABLE_DISABLE_ALARM_PENDING_ACTION]:
    addEnableDisableAlarmPendingAction,
  [ActionTypes.ADD_START_SNOOZE_FOR_CREATOR_SOFT_PENDING_ACTION]:
    addStartSnoozeForCreatorSoftPendingAction,
  [ActionTypes.ADD_STOP_SNOOZE_FOR_CREATOR_SOFT_PENDING_ACTION]:
    addStopSnoozeForCreatorSoftPendingAction,
  [ActionTypes.ADD_START_SNOOZE_FOR_PARTICIPANT_SOFT_PENDING_ACTION]:
    addStartSnoozeForParticipantSoftPendingAction,
  [ActionTypes.ADD_STOP_SNOOZE_FOR_PARTICIPANT_SOFT_PENDING_ACTION]:
    addStopSnoozeForParticipantSoftPendingAction,
  [ActionTypes.REMOVE_SOFT_PENDING_ACTION]: removeSoftPendingAction,
  [ActionTypes.ADD_MARK_PERSONAL_ALARM_DONE_SOFT_PENDING_ACTION]:
    addMarkPersonalAlarmDoneSoftPendingAction,
  [ActionTypes.ADD_SKIP_PERSONAL_ALARM_SOFT_PENDING_ACTION]:
    addSkipPersonalAlarmSoftPendingAction,
  [ActionTypes.ADD_SKIP_PERSONAL_PARTICIPANT_ALARM_SOFT_PENDING_ACTION]:
    addSkipPersonalParticipantAlarmSoftPendingAction,
  [ActionTypes.ADD_SKIP_RECIPIENT_ALARM_SOFT_PENDING_ACTION]:
    addSkipRecipientAlarmSoftPendingAction,
  [ActionTypes.ADD_SKIP_RECIPIENT_CREATOR_ALARM_SOFT_PENDING_ACTION]:
    addSkipRecipientCreatorAlarmSoftPendingAction,
  [ActionTypes.ADD_MARK_RECIPIENT_ALARM_DONE_SOFT_PENDING_ACTION]:
    addMarkRecipientAlarmDoneSoftPendingAction,
  [ActionTypes.ADD_SET_PARTICIPANT_ALARM_RESPONSE_SOFT_PENDING_ACTION]:
    addSetParticipantAlarmResponseSoftPendingAction,
  [ActionTypes.ADD_SET_RECIPIENT_ALARM_RESPONSE_SOFT_PENDING_ACTION]:
    addSetRecipientAlarmResponseSoftPendingAction,
  [ActionTypes.ADD_SET_PAST_ALARM_OCCURRENCE_RESPONSE_SOFT_PENDING_ACTION]:
    addSetPastAlarmOccurrenceResponseSoftPendingAction,
  [ActionTypes.ADD_UPDATE_PARTICIPANT_ALARM_RINGER_SETTINGS_SOFT_PENDING_ACTION]:
    addUpdateParticipantAlarmRingerSettingsSoftPendingAction,
  [ActionTypes.ADD_UPDATE_ALARM_RINGER_SETTINGS_SOFT_PENDING_ACTION]:
    addUpdateAlarmRingerSettingsSoftPendingAction,
  [ActionTypes.ADD_UPDATE_ALARM_PRE_REMINDER_SOFT_PENDING_ACTION]:
    addUpdateAlarmPreReminderDurationSoftPendingAction,
  [ActionTypes.ADD_UPDATE_GLOBAL_ALARM_RINGER_SETTINGS_SOFT_PENDING_ACTION]:
    addUpdateGlobalAlarmRingerSettingsSoftPendingAction,
  [ActionTypes.ADD_UPDATE_RING_PARTICIPANT_ALARMS_BY_DEFAULT_SETTING_SOFT_PENDING_ACTION]:
    addUpdateRingParticipantAlarmsByDefaultSettingSoftPendingAction,
  [ActionTypes.ADD_ADD_ALARM_TO_ALARM_CATEGORY_SOFT_PENDING_ACTION]:
    addAddAlarmToAlarmCategorySoftPendingAction,
  [ActionTypes.ADD_REMOVE_ALARM_FROM_ALARM_CATEGORY_SOFT_PENDING_ACTION]:
    addRemoveAlarmFromAlarmCategorySoftPendingAction,
  [ActionTypes.ADD_DELETE_ALARM_CATEGORY_SOFT_PENDING_ACTION]:
    addDeleteAlarmCategorySoftPendingAction,
  [ActionTypes.ADD_UPDATE_ALARM_CATEGORY_SOFT_PENDING_ACTION]:
    addUpdateAlarmCategorySoftPendingAction,
  [ActionTypes.ADD_CREATE_ALARM_CATEGORY_SOFT_PENDING_ACTION]:
    addCreateAlarmCategorySoftPendingAction,
  [ActionTypes.ADD_MARK_PARTICIPANT_RESPONSE_FOR_GROUP_ALARM_SOFT_PENDING_ACTION]:
    addMarkParticipantResponseForGroupAlarmSoftPendingAction,
  [ActionTypes.ADD_MARK_CREATOR_RESPONSE_FOR_GROUP_ALARM_SOFT_PENDING_ACTION]:
    addMarkCreatorResponseForGroupAlarmSoftPendingAction,
  [ActionTypes.ADD_UPDATE_RING_ON_EARPHONE_ONLY_SOFT_PENDING_ACTION]:
    addUpdateRingOnEarphoneOnlySoftPendingAction,
  [ActionTypes.ADD_UPDATE_GESTURE_TO_DISMISS_ALARM_SOFT_PENDING_ACTION]:
    addUpdateGestureToDismissAnAlarmSoftPendingAction,
  [ActionTypes.ADD_UPDATE_SPECIFY_TIMEZONE_FOR_ALARM_SOFT_PENDING_ACTION]:
    addUpdateSpecifyTimezoneForAlarmSoftPendingAction,
  [ActionTypes.ADD_UPDATE_DEFAULT_TIMEZONE_FOR_ALARM_SOFT_PENDING_ACTION]:
    addUpdateDefaultTimezoneForAlarmSoftPendingAction,
  [ActionTypes.ADD_UPDATE_GRADUALLY_INCREASE_VOLUME_SOFT_PENDING_ACTION]:
    addUpdateGraduallyIncreaseVolumeSoftPendingAction,
  [ActionTypes.ADD_UPDATE_PARTICIPANT_ALARM_PRE_REMINDER_DURATION_SOFT_PENDING_ACTION]:
    addUpdateParticipantAlarmPreReminderDurationSoftPendingAction,
  [ActionTypes.ADD_UPDATE_NOTIFICATION_SETTINGS_SOFT_PENDING_ACTION]:
    addUpdateNotificationSettingsSoftPendingAction,
  [ActionTypes.ADD_ADD_CHECKLIST_PENDING_ACTION]: addAddChecklistPendingAction,
  [ActionTypes.ADD_EDIT_CHECKLIST_PENDING_ACTION]:
    addEditChecklistPendingAction,
  [ActionTypes.ADD_REMOVE_CHECKLIST_PENDING_ACTION]:
    addRemoveChecklistPendingAction,
  [ActionTypes.ADD_UPDATE_NOTIFICATION_ACTION_BEHAVIOR_SOFT_PENDING_ACTION]:
    addUpdateNotificationActionBehaviorSoftPendingAction
}

export default createReducer(INITIAL_STATE, ACTION_HANDLERS)
