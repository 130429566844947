import React from 'react'
import { Box, Button, Header, Text } from 'grommet'
import { Close } from 'grommet-icons'
import { I18n } from 'galarm-config'
import { useDispatch } from 'react-redux'
import { ActionCreators } from 'galarm-shared'

const ChangeGroupMemberStateHeader = ({ onSave }) => {
  const dispatch = useDispatch()
  return (
    <Header
      className="header"
      background="brand"
      pad="small"
      direction="row"
      justify="between">
      <Text color="white" level={5}>
        {I18n.t('changeStatus')}
      </Text>
      <Box direction="row" align="center">
        <Box
          pad="medium"
          onClick={() => {
            onSave()
          }}>
          <Text color="white" size="large">
            {I18n.t('save')}
          </Text>
        </Box>
        <Button
          icon={<Close />}
          onClick={() =>
            dispatch(ActionCreators.hideChangeGroupMemberStateScreen())
          }
        />
      </Box>
    </Header>
  )
}

export default ChangeGroupMemberStateHeader
