import * as Sentry from '@sentry/react'

export function logError(error) {
  if (!__DEV__) {
    Sentry.captureException(error)
  } else {
    console.error(error)
  }
}
export function logWarning(error) {
  if (!__DEV__) {
    Sentry.captureException(error)
  } else {
    console.warn(error)
  }
}
export function logMessage(message) {
  if (!__DEV__) {
    Sentry.captureMessage(message)
  } else {
    console.log(message)
  }
}
