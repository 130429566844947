import React, { useState } from 'react'
import { colors, colorThemes, Constants } from 'galarm-config'
import { useSelector } from 'react-redux'
import { I18n } from 'galarm-config'
import ColorPalette from './ColorPalette'
import { Box, Button, Heading, Text, TextInput } from 'grommet'
import arrUniq from 'lodash/uniq'
import arrDifference from 'lodash/difference'
import { random } from 'lodash'
import LabelText from './LabelText'
import { Selectors } from 'galarm-shared'

const { alarmCategoriesSelector } = Selectors

const EditAlarmCategory = ({
  alarmCategory,
  onSaveAlarmCategory,
  onClose,
  isEdit
}) => {
  const alarmCategories = useSelector(alarmCategoriesSelector)

  const suggestAlarmCategoryColor = () => {
    if (alarmCategory.color) {
      return alarmCategory.color
    }

    const usedColors = arrUniq(
      Object.values(alarmCategories).map(alarmCategory => alarmCategory.color)
    )

    const unusedColors = arrDifference(
      Constants.ALARM_CATEGORY_COLORS,
      usedColors
    )

    if (unusedColors.length === 0) {
      const randomNumber = random(0, 5)
      return Constants.ALARM_CATEGORY_COLORS[randomNumber]
    } else {
      return unusedColors[0]
    }
  }

  const [name, setName] = useState(alarmCategory.name)
  const [nameValidationError, setNameValidationError] = useState('')
  const [color, setColor] = useState(suggestAlarmCategoryColor())
  const colorScheme = useSelector(state => state.userSettings.webColorScheme)

  const onChangeAlarmCategoryTitle = event => {
    setName(event.target.value)
    setNameValidationError('')
  }

  const onChangeAlarmCategoryColor = newValue => {
    setColor(newValue)
  }

  const onSave = () => {
    if (!name.trim()) {
      setNameValidationError(I18n.t('setValidName'))
      return
    }

    onSaveAlarmCategory({
      name,
      color,
      source: Constants.ALARM_CATEGORY_SOURCES.USER
    })

    onClose()
  }

  const alarmCategoryColors = colorThemes
    .getColorTheme(colorScheme)
    .alarmCategoryColors.filter(color => color.id !== 'uncategorized')

  return (
    <Box overflow="auto" width="500px" margin="medium" gap="small">
      <Heading level={4} margin="none">
        {isEdit
          ? I18n.t('editAlarmCategoryTitle')
          : I18n.t('newAlarmCategoryTitle')}
      </Heading>
      <Box margin={{ bottom: '20px' }}>
        <LabelText>{I18n.t('name')}</LabelText>
        <TextInput
          flex
          value={name}
          placeholder={I18n.t('newAlarmCategoryNamePlaceholder')}
          onChange={onChangeAlarmCategoryTitle}
        />
        {nameValidationError && (
          <Text color={colors.red}>{nameValidationError}</Text>
        )}
      </Box>
      <ColorPalette
        label={I18n.t('color')}
        onChange={onChangeAlarmCategoryColor}
        selectedColor={color}
        colors={alarmCategoryColors}
      />
      <Box direction="row" alignSelf="end" gap="small">
        <Button onClick={onClose} label={I18n.t('cancel')} />
        <Button onClick={onSave} label={I18n.t('save')} />
      </Box>
    </Box>
  )
}

EditAlarmCategory.defaultProps = {
  alarmCategory: {
    name: ''
  }
}

export default EditAlarmCategory
