import React, { Component } from 'react'
import { GlobalConfig } from 'galarm-config'
import {
  AlarmUtils,
  Utils,
  DateTimeUtils,
  NetworkUtils,
  ActionCreators
} from 'galarm-shared'
import { Constants } from 'galarm-config'
import { I18n } from 'galarm-config'
import { NavigationUtils } from 'galarm-ps-api'
import moment from 'moment-timezone'
import * as RNLocalize from 'react-native-localize'
import { FirebaseProxy } from 'galarm-ps-api'
import isObjEqual from 'fast-deep-equal'
import { getNextDateForAlarm } from '../utils/AlarmUtils'

class AbstractEditAlarmContainer extends Component {
  constructor(props) {
    super(props)
    this.animateAlarmEndDate = false
    this.animateTbpa = false
  }

  getMinimumDate = () => {
    const prevDay = moment()
      .hours(0)
      .minutes(0)
      .seconds(0)
      .milliseconds(0)
      .subtract(1, 'day')
      .valueOf()

    return new Date(prevDay)
  }

  onDateChange = date => {
    if (this.state.repeatType === Constants.RepeatTypes.EVERY_N_HOURS) {
      const { hours, selectedDays } =
        AlarmUtils.getRepeatOptionsForHourlyRepetition(this.state.repeat)
      const newStartHours = moment(date).hours()
      const newStartMins = moment(date).minutes()
      // Use the end time as 23:59 and find the appropriate end hours and mins
      let newRepeat = `${newStartHours}:${newStartMins}:23:59:${hours}:${selectedDays.join(
        ','
      )}`

      const { endHours: newEndHours, endMins: newEndMins } =
        AlarmUtils.getLastOccurrenceOfHourlyAlarm(newRepeat)
      newRepeat = `${newStartHours}:${newStartMins}:${newEndHours}:${newEndMins}:${hours}:${selectedDays.join(
        ','
      )}`

      this.setState({
        date: date.getTime(),
        repeat: newRepeat
      })
    } else if (
      this.state.repeatType === Constants.RepeatTypes.HOURS_AND_MINUTES
    ) {
      const { minutes, selectedDays } =
        AlarmUtils.getRepeatOptionsForHoursAndMinutesRepetition(
          this.state.repeat
        )
      const newStartHours = moment(date).hours()
      const newStartMins = moment(date).minutes()
      // Use the end time as 23:59 and find the appropriate end hours and mins
      let newRepeat = `${newStartHours}:${newStartMins}:23:59:${minutes}:${selectedDays.join(
        ','
      )}`
      const { endHours: newEndHours, endMins: newEndMins } =
        AlarmUtils.getLastOccurrenceOfHourAndMinutesAlarm(newRepeat)
      newRepeat = `${newStartHours}:${newStartMins}:${newEndHours}:${newEndMins}:${minutes}:${selectedDays.join(
        ','
      )}`
      this.setState({
        date: date.getTime(),
        repeat: newRepeat
      })
    } else if (
      this.state.repeatType === Constants.RepeatTypes.MONTHS &&
      this.state.repeat === Constants.LAST_DAY_OF_MONTH_REPEAT_STRING
    ) {
      const newDate = moment(date).endOf('month')
      // If the user changes to a date that's not the end of the month,
      // just change the repeat type to Monthly
      if (newDate.date() !== moment(date).date()) {
        this.setState({
          date: date.getTime(),
          repeatType: Constants.RepeatTypes.MONTHLY,
          repeat: ''
        })
        NavigationUtils.showTransientAlert({
          message: I18n.t('alarmRepetitionAdjustedBasedOnNewDate'),
          duration: Constants.AlertDurations.LONG
        })
      } else {
        const hours = moment(date).hours()
        const minutes = moment(date).minutes()
        this.setState({
          date: moment(date)
            .endOf('month')
            .hours(hours)
            .minutes(minutes)
            .seconds(0)
            .milliseconds(0)
            .valueOf()
        })
      }
    } else if (
      this.state.repeatType === Constants.RepeatTypes.MONTHS &&
      this.state.repeat.startsWith(Constants.DAY_OF_WEEK_IN_MONTH_REPEAT_STRING)
    ) {
      const repeatOptionsArr = this.state.repeat.split(':')
      const weekNumberOfDayInMonth = Number(repeatOptionsArr[1])
      const dayName = repeatOptionsArr[2]
      const newWeekNumberOfDayInMonth =
        AlarmUtils.computeDayOfWeekInMonthForDate(date)
      const newDayName = Constants.DayRepetitionOptions[moment(date).day()]
      // If repeat type has changed
      if (
        weekNumberOfDayInMonth !== newWeekNumberOfDayInMonth ||
        dayName !== newDayName
      ) {
        // Adjust the repeat type based on the date, the new repeat type will
        // always be one of 1,2,3,4,5 day of week in month
        this.setState({
          date: date.getTime(),
          repeat:
            Constants.DAY_OF_WEEK_IN_MONTH_REPEAT_STRING +
            ':' +
            newWeekNumberOfDayInMonth +
            ':' +
            newDayName
        })
        NavigationUtils.showTransientAlert({
          message: I18n.t('alarmRepetitionAdjustedBasedOnNewDate'),
          duration: Constants.AlertDurations.LONG
        })
      } else {
        this.setState({
          date: date.getTime()
        })
      }
    } else if (
      this.state.repeatType === Constants.RepeatTypes.MONTHS &&
      this.state.repeat.startsWith(
        Constants.LAST_DAY_OF_WEEK_IN_MONTH_REPEAT_STRING
      )
    ) {
      const repeatOptionsArr = this.state.repeat.split(':')
      const dayName = repeatOptionsArr[1]
      const newWeekNumberOfDayInMonth =
        AlarmUtils.computeDayOfWeekInMonthForDate(date)
      const isDateLastDayOfWeekInMonth =
        AlarmUtils.checkIfDayOfMonthIsLastDayOfWeekInMonth(date)
      const newDayName = Constants.DayRepetitionOptions[moment(date).day()]
      // If repeat type has changed
      if (!isDateLastDayOfWeekInMonth) {
        // Adjust the repeat type based on the date, the new repeat type will
        // always be one of 1,2,3,4,5 day of week in month
        this.setState({
          date: date.getTime(),
          repeat:
            Constants.DAY_OF_WEEK_IN_MONTH_REPEAT_STRING +
            ':' +
            newWeekNumberOfDayInMonth +
            ':' +
            newDayName
        })
        NavigationUtils.showTransientAlert({
          message: I18n.t('alarmRepetitionAdjustedBasedOnNewDate'),
          duration: Constants.AlertDurations.LONG
        })
      } else if (isDateLastDayOfWeekInMonth && dayName !== newDayName) {
        // Case when just the day is changed but it's still last day of week in month
        this.setState({
          date: date.getTime(),
          repeat:
            Constants.LAST_DAY_OF_WEEK_IN_MONTH_REPEAT_STRING + ':' + newDayName
        })
        NavigationUtils.showTransientAlert({
          message: I18n.t('alarmRepetitionAdjustedBasedOnNewDate')
        })
      } else {
        this.setState({
          date: date.getTime()
        })
      }
    } else if (
      this.state.repeatType === Constants.RepeatTypes.YEARS &&
      this.state.repeat.startsWith(Constants.DAY_OF_WEEK_IN_MONTH_REPEAT_STRING)
    ) {
      const repeatOptionsArr = this.state.repeat.split(':')
      const weekNumberOfDayInMonth = Number(repeatOptionsArr[1])
      const dayName = repeatOptionsArr[2]
      const monthName = repeatOptionsArr[3]
      const newWeekNumberOfDayInMonth =
        AlarmUtils.computeDayOfWeekInMonthForDate(date)
      const newDayName = Constants.DayRepetitionOptions[moment(date).day()]
      const newMonthName =
        Constants.MonthRepetitionOptions[moment(date).month()]
      // If repeat type has changed
      if (
        weekNumberOfDayInMonth !== newWeekNumberOfDayInMonth ||
        dayName !== newDayName ||
        monthName !== newMonthName
      ) {
        // Adjust the repeat type based on the date, the new repeat type will
        // always be one of 1,2,3,4,5 day of week in month
        this.setState({
          date: date.getTime(),
          repeat:
            Constants.DAY_OF_WEEK_IN_MONTH_REPEAT_STRING +
            ':' +
            newWeekNumberOfDayInMonth +
            ':' +
            newDayName +
            ':' +
            newMonthName
        })
        NavigationUtils.showTransientAlert({
          message: I18n.t('alarmRepetitionAdjustedBasedOnNewDate'),
          duration: Constants.AlertDurations.LONG
        })
      } else {
        this.setState({
          date: date.getTime()
        })
      }
    } else if (
      this.state.repeatType === Constants.RepeatTypes.YEARS &&
      this.state.repeat.startsWith(
        Constants.LAST_DAY_OF_WEEK_IN_MONTH_REPEAT_STRING
      )
    ) {
      const repeatOptionsArr = this.state.repeat.split(':')
      const dayName = repeatOptionsArr[1]
      const newWeekNumberOfDayInMonth =
        AlarmUtils.computeDayOfWeekInMonthForDate(date)
      const isDateLastDayOfWeekInMonth =
        AlarmUtils.checkIfDayOfMonthIsLastDayOfWeekInMonth(date)
      const newDayName = Constants.DayRepetitionOptions[moment(date).day()]
      const monthName = repeatOptionsArr[3]
      const newMonthName =
        Constants.MonthRepetitionOptions[moment(date).month()]
      // If repeat type has changed
      if (!isDateLastDayOfWeekInMonth) {
        // Adjust the repeat type based on the date, the new repeat type will
        // always be one of 1,2,3,4,5 day of week in month
        this.setState({
          date: date.getTime(),
          repeat:
            Constants.DAY_OF_WEEK_IN_MONTH_REPEAT_STRING +
            ':' +
            newWeekNumberOfDayInMonth +
            ':' +
            newDayName +
            ':' +
            newMonthName
        })
        NavigationUtils.showTransientAlert({
          message: I18n.t('alarmRepetitionAdjustedBasedOnNewDate'),
          duration: Constants.AlertDurations.LONG
        })
      } else if (
        isDateLastDayOfWeekInMonth &&
        (dayName !== newDayName || monthName !== newMonthName)
      ) {
        // Case when just the day or month is changed but it's still last day of week in month
        this.setState({
          date: date.getTime(),
          repeat:
            Constants.LAST_DAY_OF_WEEK_IN_MONTH_REPEAT_STRING +
            ':' +
            newDayName +
            ':' +
            newMonthName
        })
        NavigationUtils.showTransientAlert({
          message: I18n.t('alarmRepetitionAdjustedBasedOnNewDate')
        })
      } else {
        this.setState({
          date: date.getTime()
        })
      }
    } else if (
      this.state.repeatType === Constants.RepeatTypes.YEARS &&
      this.state.repeat.startsWith(Constants.LAST_DAY_OF_MONTH_REPEAT_STRING)
    ) {
      const newDate = moment(date).endOf('month')
      // If the user changes to a date that's not the end of the month,
      // just change the repeat type to Yearly
      if (newDate.date() !== moment(date).date()) {
        this.setState({
          date: date.getTime(),
          repeatType: Constants.RepeatTypes.YEARLY,
          repeat: ''
        })
        NavigationUtils.showTransientAlert({
          message: I18n.t('alarmRepetitionAdjustedBasedOnNewDate'),
          duration: Constants.AlertDurations.LONG
        })
      } else {
        const hours = moment(date).hours()
        const minutes = moment(date).minutes()
        this.setState({
          date: moment(date)
            .endOf('month')
            .hours(hours)
            .minutes(minutes)
            .seconds(0)
            .milliseconds(0)
            .valueOf()
        })
      }
    } else {
      this.setState({
        date: date.getTime()
      })
    }
  }

  // Returns a function which will return the response status for a participant
  // If the effective time for an alarm has changed for a participant, then update
  // the ACCEPTED status to NOT_RESPONDED. Else keep the REJECTED status. If the
  // effective time has not changed, return previous status or NOT_RESPONDED
  getNewResponseStatusFunc = () => {
    let statusMap = {}

    // For new alarm, backupGroup and backupContacts props will be undefined
    // For edit alarm, backupGroup will be null if no group is a backup previously
    if (this.props.backupGroup) {
      this.props.backupGroup.members.forEach(member => {
        statusMap[member.id] = member.responseStatus
      })
    } else if (this.props.backupContacts) {
      this.props.backupContacts.forEach(backupContact => {
        statusMap[backupContact.id] = backupContact.responseStatus
      })
    }

    return function (memberId, timeChanged) {
      const previousStatus = statusMap[memberId]

      if (!previousStatus) {
        return Constants.ALARM_RESPONSE_PENDING
      }

      if (timeChanged && previousStatus === Constants.ACCEPT_ALARM) {
        return Constants.ALARM_RESPONSE_PENDING
      }

      return previousStatus
    }
  }

  hasEffectiveTimeChangedForCreator = () => {
    // If new alarm, return true
    if (!this.state.isEdit) {
      return true
    }

    // Only edit alarm workflow should reach here
    // Use the default device timezone here. No need to pass in the timezone.
    if (
      AlarmUtils.getNextDateForAlarm(
        this.props.date,
        this.props.endDate,
        this.props.repeatType,
        this.props.repeat
      ) !==
      AlarmUtils.getNextDateForAlarm(
        this.state.date,
        this.state.endDate,
        this.state.repeatType,
        this.state.repeat
      )
    ) {
      return true
    }

    return false
  }

  // eslint-disable-next-line no-unused-vars
  hasEffectiveTimeChangedForParticipant = memberId => {
    // If new alarm, return true
    if (!this.state.isEdit) {
      return true
    }

    // Only edit alarm workflow should reach here
    // Use the default device timezone here. No need to pass in the timezone.
    if (
      AlarmUtils.getNextDateForAlarm(
        this.props.date,
        this.props.endDate,
        this.props.repeatType,
        this.props.repeat
      ) !==
      AlarmUtils.getNextDateForAlarm(
        this.state.date,
        this.state.endDate,
        this.state.repeatType,
        this.state.repeat
      )
    ) {
      return true
    }

    if (
      this.props.cascadingAlarmInterval &&
      this.state.cascadingAlarmInterval &&
      this.props.cascadingAlarmInterval !== this.state.cascadingAlarmInterval
    ) {
      return true
    }

    // Not using the order because that may change because of several reasons
    // When a user changes from a group to contact or vice versa, the order may be
    // different for the same participants. Also, when a user declines an alarm, the
    // order of the participants is affected. If we do need to look at order, we
    // should try to figure out a significant change in time and then use that.
    return false
  }

  hasEffectiveTimeChangedForRecipient = () => {
    // If new alarm, return true
    if (!this.state.isEdit) {
      return true
    }

    const alarmDate =
      this.state.date +
      Constants.RecipientAlarmIntervals[this.state.recipientAlarmInterval].value

    // Only edit alarm workflow should reach here
    // Use the default device timezone here. No need to pass in the timezone.
    if (
      AlarmUtils.getNextDateForAlarm(
        this.props.date,
        this.props.endDate,
        this.props.repeatType,
        this.props.repeat
      ) !==
      AlarmUtils.getNextDateForAlarm(
        alarmDate,
        this.state.endDate,
        this.state.repeatType,
        this.state.repeat
      )
    ) {
      return true
    }

    // No need to check the recipient alarm interval because
    // that doesn't affect the recipient alarm time.
    // It only affects the creator's alarm time.

    return false
  }

  updateBackupsWithOrderAndResponseStatus = () => {
    const getNewResponseStatus = this.getNewResponseStatusFunc()
    let backupGroup = null
    let backupContacts = []
    if (this.state.backupGroup && this.state.backupGroup.members) {
      let members = this.state.backupGroup.members.map(member => {
        const hasEffectiveTimeChangedForParticipant =
          this.hasEffectiveTimeChangedForParticipant(member.id)
        const responseStatus = getNewResponseStatus(
          member.id,
          hasEffectiveTimeChangedForParticipant
        )
        return {
          ...member,
          responseStatus,
          seenOn: hasEffectiveTimeChangedForParticipant
            ? null
            : member.seenOn || null,
          deliveredOn: hasEffectiveTimeChangedForParticipant
            ? null
            : member.deliveredOn || null
        }
      })
      backupGroup = {
        ...this.state.backupGroup,
        members
      }
    } else {
      backupContacts = this.state.backupContacts.map((backupContact, index) => {
        const hasEffectiveTimeChangedForParticipant =
          this.hasEffectiveTimeChangedForParticipant(backupContact.id)
        const responseStatus = getNewResponseStatus(
          backupContact.id,
          hasEffectiveTimeChangedForParticipant
        )
        return {
          ...backupContact,
          responseStatus,
          order: index + 1,
          seenOn: hasEffectiveTimeChangedForParticipant
            ? null
            : backupContact.seenOn || null,
          deliveredOn: hasEffectiveTimeChangedForParticipant
            ? null
            : backupContact.deliveredOn || null
        }
      })
    }
    return { backupGroup, backupContacts }
  }

  updateRecipientWithResponseStatus = () => {
    let updatedRecipient = null
    const recipient = this.state.recipient
    if (recipient !== null) {
      const hasEffectiveTimeChangedForRecipient =
        this.hasEffectiveTimeChangedForRecipient()
      updatedRecipient = {
        ...recipient,
        responseStatus: hasEffectiveTimeChangedForRecipient
          ? Constants.ALARM_RESPONSE_PENDING
          : recipient.responseStatus || Constants.ALARM_RESPONSE_PENDING,
        seenOn: hasEffectiveTimeChangedForRecipient
          ? null
          : recipient.seenOn || null,
        deliveredOn: hasEffectiveTimeChangedForRecipient
          ? null
          : recipient.deliveredOn || null
      }
    }
    return updatedRecipient
  }

  adjustWeeklyRepetitionStringForBuddyAlarmIfNeeded = (
    date,
    repeat,
    recipientAlarmInterval
  ) => {
    const creatorAlarmDate =
      date + Constants.RecipientAlarmIntervals[recipientAlarmInterval].value

    if (DateTimeUtils.differenceInDayForDates(date, creatorAlarmDate) === 0) {
      return repeat
    }

    return DateTimeUtils.differenceInDayForDates(date, creatorAlarmDate) === 1
      ? AlarmUtils.addDayToWeeklyRepetitionString(repeat)
      : AlarmUtils.subtractDayFromWeeklyRepetitionString(repeat)
  }

  createAlarm = alarmId => {
    const { backupGroup, backupContacts } =
      this.updateBackupsWithOrderAndResponseStatus()
    const recipient = this.updateRecipientWithResponseStatus()

    const defaultAlarmName = AlarmUtils.getDefaultAlarmName(this.state.type)

    let date = this.state.date
    let repeat = this.state.repeat
    const repeatType = this.state.repeatType

    // Store the date in the correct timezone if an explicit timezone is selected
    date =
      this.state.timezoneSetting === Constants.TIMEZONE_SETTINGS.EXPLICIT
        ? DateTimeUtils.getDateFromFormattedDateString(
            DateTimeUtils.getFormattedDateAsString(date),
            this.state.creatorTimezone
          )
        : date

    if (this.state.type === Constants.AlarmTypes.RECIPIENT) {
      date =
        date +
        Constants.RecipientAlarmIntervals[this.state.recipientAlarmInterval]
          .value
      if (repeatType === Constants.RepeatTypes.DAYS_OF_WEEK) {
        repeat = this.adjustWeeklyRepetitionStringForBuddyAlarmIfNeeded(
          date,
          repeat,
          this.state.recipientAlarmInterval
        )
      }
    }

    // If it is a repeating alarm and the date is in the past, then find the next date
    if (date < Date.now()) {
      const nextDate = getNextDateForAlarm(
        date,
        this.state.endDate,
        repeatType,
        repeat,
        this.state.creatorTimezone
      )
      console.log(
        'Updating alarm date as the set date is in past',
        DateTimeUtils.getDateTimeAsString(nextDate)
      )
      date = nextDate || date
    }

    // Store the date string also in the correct timezone if an explicit timezone is selected
    const dateString =
      this.state.timezoneSetting === Constants.TIMEZONE_SETTINGS.EXPLICIT
        ? DateTimeUtils.getFormattedDateAsStringInTimezone(
            date,
            this.state.creatorTimezone
          )
        : DateTimeUtils.getFormattedDateAsString(date)

    const currDate = Date.now()
    const historyStartDate = this.props.historyStartDate || currDate

    let firstAlarmDate = this.props.firstAlarmDate || date
    if (
      AlarmUtils.hasAlarmPreviousOccurrencesChanged(
        {
          date: this.props.date,
          repeatType: this.props.repeatType,
          repeat: this.props.repeat,
          creatorTimezone: this.props.creatorTimezone
        },
        {
          date: date,
          repeatType: this.state.repeatType,
          repeat: repeat,
          creatorTimezone: this.state.creatorTimezone
        }
      )
    ) {
      firstAlarmDate = date
    }

    const alarm = {
      id: alarmId,
      name: this.state.name.trim() || defaultAlarmName,
      date: date,
      dateString,
      status: true,
      creator: GlobalConfig.uid,
      creatorName: this.props.creatorName,
      creatorMobileNumber: this.props.creatorMobileNumber,
      creatorTimezoneOffset: DateTimeUtils.getTimezoneOffsetForTimezone(
        this.state.creatorTimezone
      ),
      creatorTimezone: this.state.creatorTimezone,
      timezoneSetting: this.state.timezoneSetting,
      notes: this.state.notes,
      alarmPhotoUrl: this.state.alarmPhotoUrl,
      repeat: repeat,
      repeatType: this.state.repeatType,
      type: this.state.type,
      cascadingAlarmInterval: this.state.cascadingAlarmInterval || null,
      recipientAlarmInterval: this.state.recipientAlarmInterval || null,
      backupGroup,
      backupContacts,
      lastUpdatedAt: currDate,
      recipient: recipient,
      // historyStartDate is the date from which the alarm history starts.
      // It takes care of the fact that if somebody sets an alarm in the past
      // and makes it repeating, which is allowed, we don't show any occurrences
      // before the alarm was created
      historyStartDate: this.state.isEdit ? historyStartDate : currDate,
      release: GlobalConfig.release,
      ringerSettings: this.state.ringerSettings,
      creationMode: this.state.creationMode,
      endDate: this.state.endDate || GlobalConfig.defaultAlarmEndDate,
      source:
        this.state.source || Constants.AlarmCreationSources.CREATED_MANUALLY,
      link: this.props.link || null,
      // firstAlarmDate is the first time this alarm would have rung. It
      // is to take care of not loosing history if things such as names, notes
      // etc. are changed
      firstAlarmDate: firstAlarmDate,
      preReminderDuration: this.state.preReminderDuration
    }
    return alarm
  }

  onSaveBackups = (backupContacts, backupGroup) => {
    // console.log('backupGroup', backupGroup, 'backupContacts', backupContacts)
    // If the group or some of the contacts are already selected,
    // then restore their seen status
    const newBackupContacts = backupContacts.map(backupContact => {
      const alreadySelectedContact = Utils.getObjectWithId(
        this.state.backupContacts,
        backupContact.id
      )
      if (alreadySelectedContact) {
        return alreadySelectedContact
      } else {
        return backupContact
      }
    })

    let newBackupGroup = backupGroup
    if (
      this.state.backupGroup &&
      this.state.backupGroup.members &&
      backupGroup &&
      backupGroup.members &&
      this.state.backupGroup.id === backupGroup.id
    ) {
      const newMembers = backupGroup.members.map(member => {
        const alreadySelectedMember = Utils.getObjectWithId(
          this.state.backupGroup.members,
          member.id
        )
        if (member.state === alreadySelectedMember.state) {
          // Update the order if it has changed
          return {
            ...alreadySelectedMember,
            ...member
          }
        } else {
          return member
        }
      })
      newBackupGroup = {
        ...newBackupGroup,
        members: newMembers
      }
    }

    this.setState({
      backupContacts: newBackupContacts,
      backupGroup: newBackupGroup
    })
  }

  onSaveRecipient = recipient => {
    if (recipient.length === 0) {
      this.setState({
        recipient: null
      })
    } else {
      this.setState({
        recipient: recipient[0]
      })
    }
  }

  onSaveTimezone = timezone => {
    // If a timezone is selected, then consider it as an explicit timezone
    if (timezone) {
      this.setState({
        creatorTimezone: timezone,
        timezoneSetting: Constants.TIMEZONE_SETTINGS.EXPLICIT
      })
    }
    // If a timezone is unselected, then change it back to default
    else {
      this.setState({
        creatorTimezone: RNLocalize.getTimeZone(),
        timezoneSetting: Constants.TIMEZONE_SETTINGS.DEVICE
      })
    }
  }

  onNameChange = newValue => {
    this.setState({
      name: newValue
    })
  }

  onNotesChange = newValue => {
    this.setState({
      notes: newValue
    })
  }

  onUploadAlarmPhoto = async photo => {
    NetworkUtils.withConnectAndAuthentication(
      this.onUploadAlarmPhotoCore,
      this.props.isConnected,
      this.props.isAuthenticated,
      I18n.t('cantUploadImage'),
      I18n.t('deviceOffline'),
      this.props.dispatch,
      photo
    )
  }

  onUploadAlarmPhotoCore = photo => {
    const metadata = {
      [Constants.FILE_METADATA_KEYS.ENTITY_TYPE]: Constants.ENTITY_TYPES.ALARM,
      [Constants.FILE_METADATA_KEYS.UID]: this.state.id
    }

    this.props
      .dispatch(
        ActionCreators.uploadImage(
          photo,
          '/images/' + this.state.id + '/alarmPhotoFullImage.jpg',
          metadata
        )
      )
      .then(result => {
        if (result.status === 'success') {
          this.setState({
            alarmPhotoUrl: result.downloadUrl
          })
          FirebaseProxy.logEvent(
            Constants.UserAnalyticsEvents.UPLOADED_ALARM_PHOTO,
            {}
          )
        }
      })
  }

  onDeleteAlarmPhoto = async () => {
    NetworkUtils.withConnectAndAuthentication(
      this.onDeleteAlarmPhotoCore,
      this.props.isConnected,
      this.props.isAuthenticated,
      I18n.t('cantDeleteImage'),
      I18n.t('deviceOffline'),
      this.props.dispatch
    )
  }

  onDeleteAlarmPhotoCore = async () => {
    this.props
      .dispatch(ActionCreators.deleteImage(this.state.alarmPhotoUrl))
      .then(result => {
        if (result.status === 'success') {
          this.setState({
            alarmPhotoUrl: null
          })
        }
      })
  }

  onEndDateChange = newValue => {
    if (newValue === GlobalConfig.defaultAlarmEndDate) {
      this.setState({
        endDate: GlobalConfig.defaultAlarmEndDate
      })
      return
    }

    const newEndDate = moment(newValue)
    newEndDate.hours(23).minutes(59).seconds(59).milliseconds(0)
    this.setState({
      endDate: newEndDate.valueOf()
    })
  }

  onSaveRingerSettings = newRingerSettings => {
    if (!isObjEqual(this.state.ringerSettings, newRingerSettings)) {
      let parameters = AlarmUtils.getChangedParametersForRingerSettings(
        this.state.ringerSettings,
        newRingerSettings
      )
      FirebaseProxy.logEvent(
        Constants.UserAnalyticsEvents.CHANGE_RINGER_SETTINGS,
        {
          ...parameters,
          [Constants.UserAnalyticsEventParameters.ALARM_CATEGORY]:
            Constants.AlarmCategories.MY_ALARM,
          [Constants.UserAnalyticsEventParameters.ENTRY_POINT]:
            Constants.RingerSettingsEntryPoints.ALARM
        }
      )
      this.setState({
        ringerSettings: newRingerSettings
      })
    }
  }

  onRepeatChange = (repeatType, repeat) => {
    let date = this.state.date
    if (repeatType === Constants.RepeatTypes.EVERY_N_HOURS) {
      const { startHours, startMins, hours, selectedDays } =
        AlarmUtils.getRepeatOptionsForHourlyRepetition(repeat)
      date = moment(date).hours(startHours).minutes(startMins).valueOf()
      const { endHours, endMins } =
        AlarmUtils.getLastOccurrenceOfHourlyAlarm(repeat)
      repeat = `${startHours}:${startMins}:${endHours}:${endMins}:${hours}:${selectedDays.join(
        ','
      )}`
    } else if (repeatType === Constants.RepeatTypes.HOURS_AND_MINUTES) {
      const { startHours, startMins, minutes, selectedDays } =
        AlarmUtils.getRepeatOptionsForHoursAndMinutesRepetition(repeat)
      date = moment(date).hours(startHours).minutes(startMins).valueOf()
      const { endHours, endMins } =
        AlarmUtils.getLastOccurrenceOfHourAndMinutesAlarm(repeat)
      repeat = `${startHours}:${startMins}:${endHours}:${endMins}:${minutes}:${selectedDays.join(
        ','
      )}`
    } else if (
      repeatType === Constants.RepeatTypes.MONTHS &&
      repeat === Constants.LAST_DAY_OF_MONTH_REPEAT_STRING
    ) {
      const hours = moment(date).hours()
      const minutes = moment(date).minutes()

      date = moment(date)
        .endOf('month')
        .hours(hours)
        .minutes(minutes)
        .seconds(0)
        .milliseconds(0)
        .valueOf()
    } else if (
      repeatType === Constants.RepeatTypes.YEARS &&
      repeat.startsWith(Constants.LAST_DAY_OF_MONTH_REPEAT_STRING)
    ) {
      const hours = moment(date).hours()
      const minutes = moment(date).minutes()

      date = moment(date)
        .endOf('month')
        .hours(hours)
        .minutes(minutes)
        .seconds(0)
        .milliseconds(0)
        .valueOf()
    }
    this.setState({
      repeatType: repeatType,
      repeat: repeat,
      date: date
    })
  }

  getRepeatForAlarm = () => {
    if (
      this.props.repeatType !== Constants.RepeatTypes.DAYS_OF_WEEK ||
      this.props.type !== Constants.AlarmTypes.RECIPIENT
    ) {
      return this.props.repeat
    }

    const recipientAlarmDate =
      this.props.date -
      Constants.RecipientAlarmIntervals[this.props.recipientAlarmInterval].value

    if (
      DateTimeUtils.differenceInDayForDates(
        this.props.date,
        recipientAlarmDate
      ) === 0
    ) {
      return this.props.repeat
    }

    return DateTimeUtils.differenceInDayForDates(
      this.props.date,
      recipientAlarmDate
    ) === 1
      ? AlarmUtils.addDayToWeeklyRepetitionString(this.props.repeat)
      : AlarmUtils.subtractDayFromWeeklyRepetitionString(this.props.repeat)
  }

  isAlarmInPast = () => {
    const dateString = DateTimeUtils.getFormattedDateAsString(this.state.date)

    // Store the date in the correct timezone if an explicit timezone is selected
    const date =
      this.state.timezoneSetting === Constants.TIMEZONE_SETTINGS.EXPLICIT
        ? DateTimeUtils.getDateFromFormattedDateString(
            dateString,
            this.state.creatorTimezone
          )
        : this.state.date
    return date < Date.now()
  }

  isPreReminderInThePast = () => {
    if (this.state.preReminderDuration === 0) {
      return false
    }

    const dateString = DateTimeUtils.getFormattedDateAsString(this.state.date)

    // Store the date in the correct timezone if an explicit timezone is selected
    const date =
      this.state.timezoneSetting === Constants.TIMEZONE_SETTINGS.EXPLICIT
        ? DateTimeUtils.getDateFromFormattedDateString(
            dateString,
            this.state.creatorTimezone
          )
        : this.state.date
    return date - this.state.preReminderDuration < Date.now()
  }

  validateAlarm = () => {
    let validationMessages = []

    if (this.isAlarmInPast() && this.state.repeatType === '') {
      validationMessages.push(I18n.t('specifyTimeInFutureForAlarm'))
    } else if (this.state.endDate !== GlobalConfig.defaultAlarmEndDate) {
      const nextAlarmDate = AlarmUtils.getNextDateForAlarm(
        this.state.date,
        this.state.endDate,
        this.state.repeatType,
        this.state.repeat,
        RNLocalize.getTimeZone()
      )

      if (!nextAlarmDate) {
        validationMessages.push(I18n.t('noOccurrenceForThisAlarmAsPerEndDate'))
      }
    }

    // Show a validation error for one-time alarms with pre-reminders if the pre-reminder
    // is in past. Allow users to edit an alarm even if pre-reminder is in past because
    // they may be editing alarm notes or something after the pre-reminder
    if (
      this.state.repeatType === '' &&
      this.props.date !== this.state.date &&
      this.isPreReminderInThePast()
    ) {
      validationMessages.push(I18n.t('specifyTimeInFutureForPreReminder'))
    }

    if (
      this.state.type === Constants.AlarmTypes.RECIPIENT &&
      this.state.recipient === null
    ) {
      validationMessages.push(I18n.t('selectRecipientForRecipientAlarm'))
    }

    if (
      this.state.type === Constants.AlarmTypes.CASCADING &&
      this.state.repeatType &&
      this.state.repeatType === Constants.RepeatTypes.EVERY_N_HOURS
    ) {
      if (
        this.state.backupGroup ||
        (this.state.backupContacts && this.state.backupContacts.length > 0)
      ) {
        const { hours } = AlarmUtils.getRepeatOptionsForHourlyRepetition(
          this.state.repeat
        )
        const timeBetweenTwoOccurrences = hours * Constants.MSEC_IN_HOUR
        const cascadingAlarmInterval =
          Constants.CascadingAlarmIntervals[this.state.cascadingAlarmInterval]
            .value
        if (timeBetweenTwoOccurrences <= cascadingAlarmInterval) {
          validationMessages.push(
            I18n.t('cascadingAlarmIntervalGreaterThanRepetitionInterval')
          )
        }
      }
    }

    if (
      this.state.type === Constants.AlarmTypes.CASCADING &&
      this.state.repeatType &&
      this.state.repeatType === Constants.RepeatTypes.HOURS_AND_MINUTES
    ) {
      if (
        this.state.backupGroup ||
        (this.state.backupContacts && this.state.backupContacts.length > 0)
      ) {
        const { minutes } =
          AlarmUtils.getRepeatOptionsForHoursAndMinutesRepetition(
            this.state.repeat
          )
        const timeBetweenTwoOccurrences = minutes * Constants.MSEC_IN_MINUTE
        const cascadingAlarmInterval =
          Constants.CascadingAlarmIntervals[this.state.cascadingAlarmInterval]
            .value
        if (timeBetweenTwoOccurrences <= cascadingAlarmInterval) {
          validationMessages.push(
            I18n.t('cascadingAlarmIntervalGreaterThanRepetitionInterval')
          )
        }
      }
    }

    if (
      this.state.type === Constants.AlarmTypes.RECIPIENT &&
      this.state.repeatType &&
      this.state.repeatType === Constants.RepeatTypes.EVERY_N_HOURS
    ) {
      const { hours } = AlarmUtils.getRepeatOptionsForHourlyRepetition(
        this.state.repeat
      )
      const timeBetweenTwoOccurrences = hours * Constants.MSEC_IN_HOUR
      const recipientAlarmInterval =
        Constants.RecipientAlarmIntervals[this.state.recipientAlarmInterval]
          .value
      if (timeBetweenTwoOccurrences <= recipientAlarmInterval) {
        validationMessages.push(
          I18n.t('recipientAlarmIntervalGreaterThanRepetitionInterval')
        )
      }
    }

    if (
      this.state.type === Constants.AlarmTypes.RECIPIENT &&
      this.state.repeatType &&
      this.state.repeatType === Constants.RepeatTypes.HOURS_AND_MINUTES
    ) {
      const { minutes } =
        AlarmUtils.getRepeatOptionsForHoursAndMinutesRepetition(
          this.state.repeat
        )
      const timeBetweenTwoOccurrences = minutes * Constants.MSEC_IN_MINUTE
      const recipientAlarmInterval =
        Constants.RecipientAlarmIntervals[this.state.recipientAlarmInterval]
          .value
      if (timeBetweenTwoOccurrences <= recipientAlarmInterval) {
        validationMessages.push(
          I18n.t('recipientAlarmIntervalGreaterThanRepetitionInterval')
        )
      }
    }

    if (
      this.state.type === Constants.AlarmTypes.SIMULTANEOUS &&
      this.state.backupContacts &&
      this.state.backupContacts.length === 0 &&
      this.state.backupGroup === null
    ) {
      validationMessages.push(
        I18n.t('selectAtLeastOneParticipantForGroupAlarm')
      )
    }

    return validationMessages
  }

  getCascadingAlarmIntervals = () => {
    return Object.keys(Constants.CascadingAlarmIntervals)
      .filter(cascadingInterval => !['2hr', '3hr'].includes(cascadingInterval))
      .map(cascadingInterval => {
        return {
          id: cascadingInterval,
          ...Constants.CascadingAlarmIntervals[cascadingInterval]
        }
      })
  }

  getRecipientAlarmIntervals = () => {
    return Object.keys(Constants.RecipientAlarmIntervals)
      .filter(
        recipientAlarmInterval =>
          !['2hr', '3hr'].includes(recipientAlarmInterval)
      )
      .map(recipientAlarmInterval => {
        return {
          id: recipientAlarmInterval,
          ...Constants.RecipientAlarmIntervals[recipientAlarmInterval]
        }
      })
  }

  onSaveCascadingAlarmInterval = newInterval => {
    this.setState({
      cascadingAlarmInterval: newInterval
    })
  }

  onSaveRecipientAlarmInterval = newInterval => {
    this.setState({
      recipientAlarmInterval: newInterval
    })
  }

  onSaveAlarm = () => {
    const validationMessages = this.validateAlarm()
    if (validationMessages.length > 0) {
      NavigationUtils.showAlert(
        I18n.t('cantSaveAlarm'),
        validationMessages.join('\n')
      )
      return
    }

    this.showAlertIfAlarmWillNotRingOnSomeDatesAndSaveAlarm()
  }

  showAlertIfAlarmWillNotRingOnSomeDatesAndSaveAlarm = () => {
    if (this.state.repeatType === Constants.RepeatTypes.MONTHLY) {
      this.showAlertIfAlarmWillNotRingOnSomeDatesAndSaveAlarmForMonthlyRepeat()
    } else if (
      this.state.repeatType === Constants.RepeatTypes.MONTHS &&
      this.state.repeat.startsWith(Constants.DAY_OF_WEEK_IN_MONTH_REPEAT_STRING)
    ) {
      const repeatOptionsArr = this.state.repeat.split(':')
      const weekNumberOfDayInMonth = repeatOptionsArr[1]
      const dayName = repeatOptionsArr[2]
      // Alert if the alarm rings on 5th day of week in month, e.g. 5th Friday
      this.showAlertIfAlarmWillNotRingOnSomeMonthsForDayOfWeekInMonthRepeat(
        weekNumberOfDayInMonth,
        dayName
      )
    } else if (
      this.state.repeatType === Constants.RepeatTypes.MONTHS &&
      this.state.repeat !== Constants.LAST_DAY_OF_MONTH_REPEAT_STRING &&
      !this.state.repeat.startsWith(
        Constants.LAST_DAY_OF_WEEK_IN_MONTH_REPEAT_STRING
      )
    ) {
      this.showAlertIfAlarmWillNotRingOnSomeDatesAndSaveAlarmForMonthlyRepeat()
    } else if (
      this.state.repeatType === Constants.RepeatTypes.YEARLY ||
      (this.state.repeatType === Constants.RepeatTypes.YEARS &&
        !this.state.repeat.startsWith(
          Constants.LAST_DAY_OF_MONTH_REPEAT_STRING
        ) &&
        !this.state.repeat.startsWith(
          Constants.LAST_DAY_OF_WEEK_IN_MONTH_REPEAT_STRING
        ) &&
        !this.state.repeat.startsWith(
          Constants.DAY_OF_WEEK_IN_MONTH_REPEAT_STRING
        ))
    ) {
      this.showAlertIfAlarmWillNotRingOnSomeDatesAndSaveAlarmForYearlyRepeat()
    } else {
      this.onSaveAlarmCore()
    }
  }

  showAlertIfAlarmWillNotRingOnSomeMonthsForDayOfWeekInMonthRepeat = (
    weekNumberOfDayInMonth,
    dayName
  ) => {
    if (weekNumberOfDayInMonth === '5') {
      NavigationUtils.showAlert(
        I18n.t('confirm'),
        I18n.t('alarmRingMessageForSpecificDayOfWeekInMonth', {
          weekNumberOfDayInMonth: weekNumberOfDayInMonth.toString(),
          dayName: I18n.t(dayName)
        }),
        [
          {
            text: I18n.t('no')
          },
          {
            text: I18n.t('yes'),
            onPress: () => {
              this.onSaveAlarmCore()
              FirebaseProxy.logEvent(
                Constants.UserAnalyticsEvents
                  .USER_SAVED_ALARM_ON_FIFTH_DAY_OF_WEEK_IN_MONTH
              )
            }
          }
        ]
      )
      FirebaseProxy.logEvent(
        Constants.UserAnalyticsEvents
          .USER_TRIED_TO_SAVE_ALARM_ON_FIFTH_DAY_OF_WEEK_IN_MONTH
      )
    } else {
      this.onSaveAlarmCore()
    }
  }

  showAlertIfAlarmWillNotRingOnSomeDatesAndSaveAlarmForMonthlyRepeat = () => {
    var date = moment(this.state.date)
    var dayOfMonth = date.date()
    var dayOfMonthString = String(dayOfMonth)
    if (dayOfMonth === 29 || dayOfMonth === 30 || dayOfMonth === 31) {
      const hasParticipants =
        AlarmUtils.getAlarmParticipants(
          this.state.type,
          this.state.backupGroup,
          this.state.backupContacts,
          this.state.recipient
        ).length > 0
      NavigationUtils.showAlert(
        I18n.t('confirm'),
        I18n.t('alarmRingMessageForSpecificDates', { dayOfMonth }),
        [
          {
            text: I18n.t('no')
          },
          {
            text: I18n.t('yes'),
            onPress: () => {
              this.onSaveAlarmCore()
              FirebaseProxy.logEvent(
                Constants.UserAnalyticsEvents
                  .USER_SAVED_ALARM_ON_END_DATES_OF_MONTH,
                {
                  [Constants.UserAnalyticsEventParameters.DAY_OF_MONTH]:
                    dayOfMonthString,
                  [Constants.UserAnalyticsEventParameters.HAS_PARTICIPANTS]:
                    hasParticipants.toString()
                }
              )
            }
          }
        ]
      )
      FirebaseProxy.logEvent(
        Constants.UserAnalyticsEvents
          .USER_TRIED_TO_SAVE_ALARM_ON_END_DATES_OF_MONTH,
        {
          [Constants.UserAnalyticsEventParameters.DAY_OF_MONTH]:
            dayOfMonthString,
          [Constants.UserAnalyticsEventParameters.HAS_PARTICIPANTS]:
            hasParticipants.toString()
        }
      )
    } else {
      this.onSaveAlarmCore()
    }
  }

  showAlertIfAlarmWillNotRingOnSomeDatesAndSaveAlarmForYearlyRepeat = () => {
    var date = moment(this.state.date)
    var dayOfMonth = date.date()
    var dayOfMonthString = String(dayOfMonth)
    var monthOfYear = date.month()
    if (dayOfMonth === 29 && monthOfYear === 1) {
      const hasParticipants =
        AlarmUtils.getAlarmParticipants(
          this.state.type,
          this.state.backupGroup,
          this.state.backupContacts,
          this.state.recipient
        ).length > 0
      FirebaseProxy.logEvent(
        Constants.UserAnalyticsEvents
          .USER_TRIED_TO_SAVE_ALARM_ON_END_DATES_OF_MONTH,
        {
          [Constants.UserAnalyticsEventParameters.DAY_OF_MONTH]:
            dayOfMonthString,
          [Constants.UserAnalyticsEventParameters.HAS_PARTICIPANTS]:
            hasParticipants.toString()
        }
      )
      NavigationUtils.showAlert(
        I18n.t('confirm'),
        I18n.t('alarmRingMessageForSpecificYear'),
        [
          {
            text: I18n.t('no')
          },
          {
            text: I18n.t('yes'),
            onPress: () => {
              FirebaseProxy.logEvent(
                Constants.UserAnalyticsEvents
                  .USER_SAVED_ALARM_ON_END_DATES_OF_MONTH,
                {
                  [Constants.UserAnalyticsEventParameters.DAY_OF_MONTH]:
                    dayOfMonthString,
                  [Constants.UserAnalyticsEventParameters.HAS_PARTICIPANTS]:
                    hasParticipants.toString()
                }
              )
              this.onSaveAlarmCore()
            }
          }
        ]
      )
    } else {
      this.onSaveAlarmCore()
    }
  }

  componentDidUpdate(nextProps, nextState) {
    this.animateAlarmEndDate =
      this.state.repeatType === '' && nextState.repeatType !== ''
    this.animateTbpa =
      this.state.backupGroup === null &&
      this.state.backupContacts.length === 0 &&
      (nextState.repeatType !== null || nextState.backupContacts.length > 0)
  }

  onSelectAlarmCategory = alarmCategoryId => {
    this.setState({
      alarmCategoryId
    })
  }

  onUnselectAlarmCategory = () => {
    this.setState({
      alarmCategoryId: Constants.UNCATEGORIZED_ALARM_CATEGORY_ID
    })
  }

  computeEndDateOptions = () => {
    let endDateOptions = []
    switch (this.state.repeatType) {
      case Constants.RepeatTypes.DAYS:
      case Constants.RepeatTypes.DAYS_OF_WEEK:
      case Constants.RepeatTypes.WEEKS:
        endDateOptions = [
          I18n.t('noEndDate'),
          I18n.t('next30Days'),
          I18n.t('next12Months'),
          I18n.t('chooseDate')
        ]
        break
      case Constants.RepeatTypes.EVERY_N_HOURS:
      case Constants.RepeatTypes.HOURS_AND_MINUTES:
      case Constants.RepeatTypes.EVERY_M_HOURS_AND_N_MINUTES:
        endDateOptions = [
          I18n.t('noEndDate'),
          I18n.t('next7Days'),
          I18n.t('next30Days'),
          I18n.t('chooseDate')
        ]
        break
      case Constants.RepeatTypes.MONTHLY:
      case Constants.RepeatTypes.MONTHS:
        endDateOptions = [
          I18n.t('noEndDate'),
          I18n.t('next12Months'),
          I18n.t('chooseDate')
        ]
        break
      case Constants.RepeatTypes.YEARS:
      case Constants.RepeatTypes.YEARLY:
        endDateOptions = [
          I18n.t('noEndDate'),
          I18n.t('next10Years'),
          I18n.t('chooseDate')
        ]
        break
    }

    return endDateOptions
  }

  onSaveDurationForPreReminder = duration => {
    this.setState({ preReminderDuration: duration })
  }

  // As of now for the pre-reminder, any duration is valid, so we are returning true
  // eslint-disable-next-line no-unused-vars
  isValidDurationForPreReminder = duration => {
    return true
  }

  render() {
    const datePickerProps = {
      date: new Date(this.state.date),
      onDateChange: this.onDateChange,
      minimumDate: this.getMinimumDate(),
      type: this.state.type
    }

    const repeatString = AlarmUtils.createAlarmRepetitionString(
      this.state.repeatType,
      this.state.repeat
    )

    const endDateString = this.state.endDate
      ? DateTimeUtils.getDateAsString(this.state.endDate)
      : I18n.t('noEndDate')

    let alarmCategory
    if (
      this.state.alarmCategoryId === Constants.UNCATEGORIZED_ALARM_CATEGORY_ID
    ) {
      alarmCategory = Constants.UNCATEGORIZED_ALARM_CATEGORY
    } else {
      alarmCategory =
        this.props.alarmCategories[this.state.alarmCategoryId] ||
        Constants.UNCATEGORIZED_ALARM_CATEGORY
    }
    const props = {
      id: this.state.id,
      name: this.state.name,
      notes: this.state.notes,
      repeat: this.state.repeat,
      repeatType: this.state.repeatType,
      creatorTimezone: this.state.creatorTimezone,
      timezoneSetting: this.state.timezoneSetting,
      onNameChange: this.onNameChange,
      onNotesChange: this.onNotesChange,
      onUploadAlarmPhoto: this.onUploadAlarmPhoto,
      onDeleteAlarmPhoto: this.onDeleteAlarmPhoto,
      onShowBackups: this.onShowBackups,
      onShowAlarmRepetition: this.onShowAlarmRepetition,
      repeatString,
      datePickerProps,
      type: this.state.type,
      creationMode: this.state.creationMode,
      preReminderDuration: this.state.preReminderDuration,
      isEdit: this.state.isEdit,
      cascadingAlarmInterval: this.state.cascadingAlarmInterval,
      onShowCascadingAlarmInterval: this.onShowCascadingAlarmInterval,
      backupGroup: this.state.backupGroup,
      backupContacts: this.state.backupContacts,
      date: this.state.date,
      recipientAlarmInterval: this.state.recipientAlarmInterval,
      onShowRecipientAlarmInterval: this.onShowRecipientAlarmInterval,
      recipient: this.state.recipient,
      onShowRecipient: this.onShowRecipient,
      mobileNumber: this.props.mobileNumber,
      ringerSettings: this.state.ringerSettings,
      onSaveRingerSettings: this.onSaveRingerSettings,
      endDate: this.state.endDate,
      endDateString: endDateString,
      onChooseAlarmEndDate: this.onChooseAlarmEndDate,
      onEndDateChange: this.onEndDateChange,
      animateAlarmEndDate: this.animateAlarmEndDate,
      animateTbpa: this.animateTbpa,
      speakAlarmName: this.speakAlarmName,
      colorScheme: this.props.colorScheme,
      onSetAlarmCategory: this.onSetAlarmCategory,
      onSelectAlarmCategory: this.onSelectAlarmCategory,
      onUnselectAlarmCategory: this.onUnselectAlarmCategory,
      alarmCategory: alarmCategory,
      onRepeatChange: this.onRepeatChange,
      computeEndDateOptions: this.computeEndDateOptions,
      onSaveAlarm: this.onSaveAlarm,
      onSaveBackups: this.onSaveBackups,
      onSaveRecipient: this.onSaveRecipient,
      onSaveCascadingAlarmInterval: this.onSaveCascadingAlarmInterval,
      onSaveRecipientAlarmInterval: this.onSaveRecipientAlarmInterval,
      onSaveTimezone: this.onSaveTimezone,
      onShowTimezones: this.onViewSpecifyTimezone,
      specifyTimezoneForAlarm: this.props.specifyTimezoneForAlarm,
      onShowPreReminderOptions: this.onShowPreReminderOptions,
      isValidDurationForPreReminder: this.isValidDurationForPreReminder,
      onSaveDurationForPreReminder: this.onSaveDurationForPreReminder,
      upgradePurchased: this.props.upgradePurchased,
      alarmPhotoUrl: this.state.alarmPhotoUrl
    }

    return <this.props.targetComponent {...props} />
  }
}

export default AbstractEditAlarmContainer
