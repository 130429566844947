import I18n from './translations/i18nTranslations'
import CommonConstants from './CommonConstants'

const Constants = (function () {
  return {
    ...CommonConstants,

    ENTERPRISE_USER_ROLES: {
      VIEWER: 'viewer',
      OWNER: 'owner',
      ADMIN: 'admin'
    },

    ENTERPRISE_PLAN_TYPES: [
      {
        label: I18n.t('business'),
        value: 'business'
      },
      {
        label: I18n.t('enterprise'),
        value: 'enterprise'
      }
    ],

    SOURCES_FOR_INCIDENTS: {
      CURL_NO_DATA_BASIC_AUTH: 'curlNoDataBasicAuth',
      CURL_NO_DATA_BEARER_TOKEN: 'curlNoDataBearerToken',
      CURL_WITH_DATA_BASIC_AUTH: 'curlWithDataBasicAuth',
      CURL_WITH_DATA_BEARER_TOKEN: 'curlWithDataBearerToken',
      WEBHOOK_NO_DATA_BASIC_AUTH: 'webhookNoDataBasicAuth',
      WEBHOOK_NO_DATA_BEARER_TOKEN: 'webhookNoDataBearerToken',
      WEBHOOK_WITH_DATA_BASIC_AUTH: 'webhookWithDataBasicAuth',
      WEBHOOK_WITH_DATA_BEARER_TOKEN: 'webhookWithDataBearerToken',
      DATA_DOG: 'dataDog',
      AWS_SNS: 'awsSns'
    }
  }
})()

export default Constants
