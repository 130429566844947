import React from 'react'
import { Box, Text } from 'grommet'
import HoverBox from './HoverBox'
import { Checkmark } from 'grommet-icons'
import { Constants } from 'galarm-config'

const ConfigureCascadingAlarmInterval = ({
  cascadingAlarmInterval,
  onSaveCascadingAlarmInterval
}) => {
  const cascadingAlarmIntervalItems = Object.keys(
    Constants.CascadingAlarmIntervals
  )
    .filter(
      cascadingAlarmInterval => !['2hr', '3hr'].includes(cascadingAlarmInterval)
    )
    .map(cascadingInterval => {
      return {
        id: cascadingInterval,
        ...Constants.CascadingAlarmIntervals[cascadingInterval]
      }
    })

  return (
    <Box width={'400px'} overflow="auto">
      {cascadingAlarmIntervalItems.map((interval, index) => (
        <HoverBox
          key={index}
          onClick={() => onSaveCascadingAlarmInterval(interval.id)}
          background={
            interval.id === cascadingAlarmInterval
              ? 'lightPrimary'
              : 'transparent'
          }>
          <Text>{interval.label}</Text>
          {interval.id === cascadingAlarmInterval && <Checkmark />}
        </HoverBox>
      ))}
    </Box>
  )
}

export default ConfigureCascadingAlarmInterval
