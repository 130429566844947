import { Component } from 'react'
import { FirebaseProxy, NavigationUtils } from 'galarm-ps-api'
import { I18n } from 'galarm-config'
import { Constants } from 'galarm-config'
import { AlarmUtils, ActionCreators } from 'galarm-shared'

class AbstractAlarmCategoryActionsProvider extends Component {
  constructor(props) {
    super(props)
  }

  deleteAlarmCategory = (alarmCategoryId, numAlarms) => {
    NavigationUtils.showAlert(
      I18n.t('deleteCategoryAlertTitle'),
      I18n.t('deleteCategoryAlertMessage', { count: numAlarms }),
      [
        {
          text: I18n.t('no')
        },
        {
          text: I18n.t('yes'),
          onPress: () => {
            this.props.dispatch(
              ActionCreators.deleteAlarmCategory(alarmCategoryId)
            )
            FirebaseProxy.logEvent(
              Constants.UserAnalyticsEvents.ALARM_CATEGORY_ACTION_CLICKED,
              {
                [Constants.UserAnalyticsEventParameters.ACTION_NAME]:
                  'deleteAlarmCategory'
              }
            )
          }
        }
      ]
    )
  }

  updateAlarmCategory = (alarmCategoryId, update) => {
    this.props.dispatch(
      ActionCreators.updateAlarmCategory(alarmCategoryId, update)
    )
  }

  editAlarmCategory = alarmCategoryId => {
    this.editAlarmCategoryCore(alarmCategoryId)
    FirebaseProxy.logEvent(
      Constants.UserAnalyticsEvents.ALARM_CATEGORY_ACTION_CLICKED,
      {
        [Constants.UserAnalyticsEventParameters.ACTION_NAME]:
          'editAlarmCategory'
      }
    )
  }

  editAlarmCategoryColor = alarmCategoryId => {
    this.editAlarmCategoryColorCore(alarmCategoryId)
    FirebaseProxy.logEvent(
      Constants.UserAnalyticsEvents.ALARM_CATEGORY_ACTION_CLICKED,
      {
        [Constants.UserAnalyticsEventParameters.ACTION_NAME]:
          'editAlarmCategoryColor'
      }
    )
  }

  unhideAlarmCategory = alarmCategoryId => {
    this.props.dispatch(ActionCreators.unhideAlarmCategory(alarmCategoryId))
    FirebaseProxy.logEvent(
      Constants.UserAnalyticsEvents.ALARM_CATEGORY_ACTION_CLICKED,
      {
        [Constants.UserAnalyticsEventParameters.ACTION_NAME]:
          'unhideAlarmCategory'
      }
    )
  }

  hideAlarmCategory = (alarmCategoryId, numAlarms) => {
    NavigationUtils.showAlert(
      I18n.t('hideCategoryAlertTitle'),
      I18n.t('hideCategoryAlertMessage', { count: numAlarms }),
      [
        {
          text: I18n.t('no')
        },
        {
          text: I18n.t('yes'),
          onPress: () => {
            this.props.dispatch(
              ActionCreators.hideAlarmCategory(alarmCategoryId)
            )
            FirebaseProxy.logEvent(
              Constants.UserAnalyticsEvents.ALARM_CATEGORY_ACTION_CLICKED,
              {
                [Constants.UserAnalyticsEventParameters.ACTION_NAME]:
                  'hideAlarmCategory'
              }
            )
          }
        }
      ]
    )
  }

  onNewAlarm = alarmCategoryId => {
    this.onNewAlarmCore(alarmCategoryId)
    FirebaseProxy.logEvent(
      Constants.UserAnalyticsEvents.ALARM_CATEGORY_ACTION_CLICKED,
      {
        [Constants.UserAnalyticsEventParameters.ACTION_NAME]: 'createNewAlarm'
      }
    )
  }

  enableAllAlarms = alarms => {
    if (!alarms.length) {
      NavigationUtils.showAlert('', I18n.t('allAlarmsAlreadyEnabled'))
      return
    }

    NavigationUtils.showAlert(I18n.t('enableAllAlarmsInCategory'), '', [
      { text: I18n.t('no') },
      {
        text: I18n.t('yes'),
        onPress: () => this.enableAllAlarmsCore(alarms)
      }
    ])
  }

  enableAllAlarmsCore = alarms => {
    let enabledAlarmsCount = 0
    let notEnabledAlarmsCount = 0

    alarms.forEach(alarm => {
      if (this.canAlarmBeEnabled(alarm)) {
        // If it's own alarm
        if (alarm.alarmCategory === Constants.AlarmCategories.MY_ALARM) {
          this.props.dispatch(ActionCreators.updateAlarmStatus(alarm.id, true))
        } // This is a participant alarm
        else {
          if (alarm.type === Constants.AlarmTypes.CASCADING) {
            this.props.dispatch(
              ActionCreators.setBackupResponseStatusForAlarm(
                alarm.id,
                Constants.ACCEPT_ALARM
              )
            )
          } else if (alarm.type === Constants.AlarmTypes.SIMULTANEOUS) {
            this.props.dispatch(
              ActionCreators.setBackupResponseStatusForAlarm(
                alarm.id,
                Constants.ACCEPT_ALARM
              )
            )
          } else if (alarm.type === Constants.AlarmTypes.RECIPIENT) {
            this.props.dispatch(
              ActionCreators.setRecipientResponseStatusForAlarm(
                alarm.id,
                Constants.ACCEPT_ALARM
              )
            )
          }
        }
        enabledAlarmsCount++
      }
      // Alarm cannot be enabled because it has no more occurences
      else {
        notEnabledAlarmsCount++
      }
    })

    const message =
      notEnabledAlarmsCount > 0
        ? I18n.t('enabledAndNotEnabledAlarmsCount', {
            count: enabledAlarmsCount
          })
        : I18n.t('enabledAlarmsCount', {
            count: enabledAlarmsCount
          })

    NavigationUtils.showAlert('', message)

    FirebaseProxy.logEvent(
      Constants.UserAnalyticsEvents.ALARM_CATEGORY_ACTION_CLICKED,
      {
        [Constants.UserAnalyticsEventParameters.ACTION_NAME]: 'enableAllAlarms'
      }
    )
  }

  disableAllAlarms = alarms => {
    if (!alarms.length) {
      NavigationUtils.showAlert('', I18n.t('allAlarmsAlreadyDisabled'))
      return
    }

    NavigationUtils.showAlert(I18n.t('disableAllAlarmsInCategory'), '', [
      { text: I18n.t('no') },
      {
        text: I18n.t('yes'),
        onPress: () => this.disableAllAlarmsCore(alarms)
      }
    ])
  }

  disableAllAlarmsCore = alarms => {
    let disabledAlarmsCount = 0

    alarms.forEach(alarm => {
      if (this.canAlarmBeDisabled(alarm)) {
        // Own alarm
        if (alarm.alarmCategory === Constants.AlarmCategories.MY_ALARM) {
          this.props.dispatch(ActionCreators.updateAlarmStatus(alarm.id, false))
        }
        //Participant alarm
        else {
          if (alarm.type === Constants.AlarmTypes.CASCADING) {
            this.props.dispatch(
              ActionCreators.setBackupResponseStatusForAlarm(
                alarm.id,
                Constants.REJECT_ALARM
              )
            )
          } else if (alarm.type === Constants.AlarmTypes.SIMULTANEOUS) {
            this.props.dispatch(
              ActionCreators.setBackupResponseStatusForAlarm(
                alarm.id,
                Constants.REJECT_ALARM
              )
            )
          } else if (alarm.type === Constants.AlarmTypes.RECIPIENT) {
            this.props.dispatch(
              ActionCreators.setRecipientResponseStatusForAlarm(
                alarm.id,
                Constants.REJECT_ALARM
              )
            )
          }
        }
        disabledAlarmsCount++
      }
    })

    const message = I18n.t('disabledAlarmsCount', {
      count: disabledAlarmsCount
    })
    NavigationUtils.showAlert('', message)

    FirebaseProxy.logEvent(
      Constants.UserAnalyticsEvents.ALARM_CATEGORY_ACTION_CLICKED,
      {
        [Constants.UserAnalyticsEventParameters.ACTION_NAME]: 'disableAllAlarms'
      }
    )
  }

  deleteAllAlarms = (alarmCategoryId, alarmCategory, numAlarms) => {
    NavigationUtils.showAlert(
      '',
      I18n.t('alarmCategoryDeleteAllAlarmsPrompt', { count: numAlarms }),
      [
        {
          text: I18n.t('no')
        },
        {
          text: I18n.t('yes'),
          onPress: () => {
            this.props.dispatch(
              ActionCreators.deleteAlarmsInCategory(
                alarmCategoryId,
                alarmCategory.alarmIds
              )
            )
            FirebaseProxy.logEvent(
              Constants.UserAnalyticsEvents.ALARM_CATEGORY_ACTION_CLICKED,
              {
                [Constants.UserAnalyticsEventParameters.ACTION_NAME]:
                  'deleteAllAlarmsInCategory'
              }
            )
          }
        }
      ]
    )
  }

  canAlarmBeEnabled = alarm => {
    // Check if there is a next occurence for the alarm
    const nextDate = AlarmUtils.getNextDateForAlarm(
      alarm.date,
      alarm.endDate,
      alarm.repeatType,
      alarm.repeat,
      alarm.creatorTimezone
    )
    return !!nextDate
  }

  // eslint-disable-next-line no-unused-vars
  canAlarmBeDisabled = alarm => {
    return true
  }
}

export default AbstractAlarmCategoryActionsProvider
