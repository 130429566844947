import React from 'react'
import { Box, Text } from 'grommet'
import { useSelector } from 'react-redux'
import { AlarmUtils, Selectors } from 'galarm-shared'
import HoverBox from './HoverBox'
import { Checkmark } from 'grommet-icons'
import { Constants } from 'galarm-config'
import { colorThemes } from 'galarm-config'

const { enabledAlarmCategoriesSelector } = Selectors

const ConfigureAlarmCategory = ({
  selectedAlarmCategory,
  onSelectAlarmCategory,
  onUnselectAlarmCategory
}) => {
  const colorScheme = useSelector(state => state.userSettings.webColorScheme)

  const selectedAlarmCategoryId = selectedAlarmCategory.id
  const onChooseAlarmCategory = category => {
    if (category.id === selectedAlarmCategoryId) {
      onUnselectAlarmCategory(category.id)
    } else {
      onSelectAlarmCategory(category.id)
    }
  }

  let alarmCategories = useSelector(state =>
    enabledAlarmCategoriesSelector(state)
  )

  const sortedAlarmCategories = Object.values(alarmCategories).sort(
    (alarmCategory1, alarmCategory2) =>
      alarmCategory1.name.localeCompare(alarmCategory2.name)
  )

  const alarmCategory =
    alarmCategories[selectedAlarmCategoryId] ||
    Constants.UNCATEGORIZED_ALARM_CATEGORY

  return (
    <Box width={'400px'} overflow="auto">
      {sortedAlarmCategories.map((category, index) => (
        <HoverBox
          key={index}
          onClick={() => onChooseAlarmCategory(category)}
          background={
            category.id === alarmCategory.id
              ? colorThemes.getColorTheme(colorScheme).listItemSelectedColor
              : 'transparent'
          }>
          <Box direction="row" justify="start" align="center" gap="small">
            <Box
              height={'30px'}
              width={'30px'}
              round
              background={AlarmUtils.getColorForAlarmCategory(
                colorScheme,
                category.color
              )}
            />
            <Text>{category.name}</Text>
          </Box>
          {category.id === alarmCategory.id && <Checkmark />}
        </HoverBox>
      ))}
    </Box>
  )
}

export default ConfigureAlarmCategory
