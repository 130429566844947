import { Constants, GlobalConfig } from 'galarm-config'
import { ActionCreators, DateTimeUtils, WebUtils } from 'galarm-shared'
import firebase from 'firebase/compat/app'
import { FirebaseProxy } from 'galarm-ps-api'

const SessionManager = (function () {
  const addSession = (uid, sessionId) => {
    window.localStorage.setItem('sessionId', sessionId)
    const session = {
      id: sessionId,
      browser: WebUtils.getBrowserName(),
      platform: WebUtils.getDeviceOsName(),
      lastActive: DateTimeUtils.getDateTimeAsString(Date.now())
    }
    let firebaseUpdate = {}
    firebaseUpdate['userInfos/' + uid + '/sessions/' + sessionId] = session
    firebaseUpdate['userInfos/' + uid + '/activeSessionId'] = sessionId

    // Update the new session data
    GlobalConfig.rootFirebaseRef.update(firebaseUpdate)

    addActiveTab()
    startListeningForActiveSessionChangedEvent(uid, sessionId)
  }

  function handleAppStartedOnAnotherTab(event) {
    if (event.key === 'TAB_OPEN') {
      showGalarmRunningElsewhereAlert()
      window.removeEventListener('storage', handleAppStartedOnAnotherTab)
    }
  }

  const addActiveTab = () => {
    // Add and remove the event. Addition would cause the event handler to
    // be called on any other tab that is listening on that location. Removing
    // it because otherwise TAB_OPEN key would always be present.
    window.localStorage.setItem('TAB_OPEN', true)
    window.localStorage.removeItem('TAB_OPEN')
    window.addEventListener('storage', handleAppStartedOnAnotherTab)
  }

  const showGalarmRunningElsewhereAlert = () => {
    GlobalConfig.store.dispatch(
      ActionCreators.setShowAppRunningElsewhereAlert(true)
    )
    FirebaseProxy.logEvent(
      Constants.UserAnalyticsEvents.APP_OPENED_ON_MULTIPLE_BROWSERS,
      {}
    )
  }

  const startListeningForActiveSessionChangedEvent = (uid, sessionId) => {
    const activeSessionRef = GlobalConfig.rootFirebaseRef
      .child('userInfos')
      .child(uid)
      .child('activeSessionId')

    activeSessionRef.on('value', activeSessionIdSnapshot => {
      if (activeSessionIdSnapshot.exists()) {
        const activeSessionId = activeSessionIdSnapshot.val()
        if (activeSessionId !== sessionId) {
          // If user logs in from a different window, then
          // remove the session from firebase to avoid orphan
          // sessions on Firebase
          GlobalConfig.rootFirebaseRef
            .child('userInfos')
            .child(uid)
            .child('sessions')
            .child(sessionId)
            .remove()
          // TODO: Do we need to unload app data here???
          showGalarmRunningElsewhereAlert()
          // Stop listening to the active session
          activeSessionRef.off('value')
        }
      } else {
        closeSession()
        // Stop listening to the active session
        activeSessionRef.off('value')
      }
    })
  }

  const logOutWebSession = () => {
    // Log first because we are logged out afterwards
    FirebaseProxy.logEvent(Constants.UserAnalyticsEvents.WEB_LOG_OUT, {})
    GlobalConfig.rootFirebaseRef
      .child('userInfos')
      .child(GlobalConfig.uid)
      .child('activeSessionId')
      .off('value')
    GlobalConfig.rootFirebaseRef
      .child('userInfos')
      .child(GlobalConfig.uid)
      .child('activeSessionId')
      .remove()
    GlobalConfig.rootFirebaseRef
      .child('userInfos')
      .child(GlobalConfig.uid)
      .child('sessions')
      .remove()
    closeSession()
  }

  const closeSession = () => {
    GlobalConfig.store.dispatch(ActionCreators.resetApp())
    GlobalConfig.store.dispatch(ActionCreators.unloadAppData())
    window.localStorage.removeItem('uid')
    window.localStorage.removeItem('phoneId')
    window.localStorage.removeItem('sessionId')
    GlobalConfig.uid = null
    GlobalConfig.phoneId = null
    firebase.auth().signOut()
  }

  return {
    addSession,
    logOutWebSession,
    addActiveTab,
    closeSession
  }
})()

export default SessionManager
