import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { I18n, Constants, colorThemes } from 'galarm-config'
import { Text, List, Box } from 'grommet'
import { Utils, Selectors } from 'galarm-shared'
import { Checkmark } from 'grommet-icons'
import ContactAvatar from './ContactAvatar'
import objGet from 'lodash/get'
import SearchBar from './SearchBar'
import { SecondaryText } from 'web-components'

const { sortedContactListSelector } = Selectors

const ContactSummary = item => {
  return (
    <Box direction="row" justify="between" align="center">
      <Box direction="row" align="center" gap="small">
        <ContactAvatar
          url={objGet(item, 'images.avatarThumbnailUrl', undefined)}
          name={item.name}
        />
        <Box>
          <Text>{item.name}</Text>
          <SecondaryText>{item.mobileNumber}</SecondaryText>
        </Box>
      </Box>
      {item.selected && <Checkmark />}
    </Box>
  )
}

const SelectContacts = ({ selectedContacts, onSelectContact }) => {
  const colorScheme = useSelector(state => state.userSettings.webColorScheme)
  const onClickContact = ({ item }) => {
    onSelectContact(item)
  }

  const contacts = useSelector(state => sortedContactListSelector(state))

  const [searchText, setSearchText] = useState('')

  if (contacts.length === 0) {
    return (
      <Text margin={{ horizontal: 'medium' }}>
        {I18n.t('noContactsWithAppInstalled')}
      </Text>
    )
  }

  let contactsData = []

  let filteredContacts = searchText
    ? contacts.filter(contact =>
        contact.name.toLowerCase().includes(searchText.toLowerCase())
      )
    : contacts

  filteredContacts.forEach(contact => {
    const selectedContact = Utils.getObjectWithId(selectedContacts, contact.id)
    if (selectedContact) {
      contactsData.push({
        ...contact,
        ...selectedContact,
        selected: true,
        participantType: Constants.ParticipantTypes.CONTACT
      })
    } else {
      contactsData.push({
        ...contact,
        selected: false,
        participantType: Constants.ParticipantTypes.CONTACT
      })
    }
  })

  const onChangeText = newText => {
    setSearchText(newText)
  }

  return (
    <Box overflow="auto">
      <Box
        flex={{ shrink: 0 }}
        margin={{ horizontal: 'small', bottom: 'medium' }}>
        <SearchBar onChangeText={onChangeText} searchText={searchText} />
      </Box>
      {filteredContacts && filteredContacts.length === 0 && (
        <Box pad="medium" flex justify="center" align="center">
          <Text color={colorThemes.getColorTheme(colorScheme).textColor}>
            {I18n.t('noSearchResultsFound', { type: 'contacts' })}
          </Text>
        </Box>
      )}
      <List
        data={contactsData}
        onClickItem={onClickContact}
        // eslint-disable-next-line react/no-children-prop
        children={ContactSummary}
      />
    </Box>
  )
}

export default SelectContacts
