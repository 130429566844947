import React from 'react'
import { Box, Text } from 'grommet'
import TintedImage from './TintedImage'
import BadgeProvider from './BadgeProvider'
import { colors } from 'galarm-config'

const ChatIcon = ({ unseenMessages = 0 }) => {
  const badge = (
    <Text size="10px" weight="bold">
      {unseenMessages}
    </Text>
  )
  return (
    <Box style={{ padding: 2 }}>
      {unseenMessages ? (
        <BadgeProvider badge={badge} backgroundColor={colors.red}>
          <TintedImage
            tintColor="primary"
            src={require('galarm-res/img/web/ic_chat.svg').default}
            height="40px"
          />
        </BadgeProvider>
      ) : (
        <TintedImage
          tintColor="primary"
          src={require('galarm-res/img/web/ic_chat.svg').default}
          height="40px"
        />
      )}
    </Box>
  )
}

export default ChatIcon
