import { Box, Text } from 'grommet'
import React from 'react'
import moment from 'moment-timezone'
import { I18n, GlobalConfig } from 'galarm-config'
import HoverBox from './HoverBox'
import { NavigationUtils } from 'galarm-ps-api'
import DateInput from './DateInput'

const DateTextInput = props => {
  return (
    <Box flex onClick={props.onClick}>
      <Text>{props.value}</Text>
    </Box>
  )
}

const ConfigureEndDate = ({
  endDateString, // eslint-disable-line no-unused-vars
  endDateOptions,
  onEndDateChange,
  minDate,
  hideEndDatePicker
}) => {
  const onChooseCustomEndDate = newEndDate => {
    if (newEndDate < minDate) {
      NavigationUtils.showAlert(
        '',
        I18n.t('alarmDateShouldBeBiggerThanAlarmStartDate')
      )
      return
    }

    onEndDateChange(newEndDate)
    hideEndDatePicker()
  }

  const onChoosePresetEndDate = newEndDate => {
    onEndDateChange(newEndDate)
    hideEndDatePicker()
  }

  const onChooseEndDate = endDateOption => {
    switch (endDateOption) {
      case I18n.t('next7Days'):
        onChoosePresetEndDate(moment().add(7, 'days').valueOf())
        break
      case I18n.t('next30Days'):
        onChoosePresetEndDate(moment().add(30, 'days').valueOf())
        break
      case I18n.t('next12Months'):
        onChoosePresetEndDate(moment().add(12, 'months').valueOf())
        break
      case I18n.t('next10Years'):
        onChoosePresetEndDate(moment().add(10, 'years').valueOf())
        break
      case I18n.t('noEndDate'):
        onChoosePresetEndDate(GlobalConfig.defaultAlarmEndDate)
        break
      default:
        return
    }
  }

  return (
    <Box width={'400px'}>
      <Box>
        {endDateOptions.map((option, index) => (
          <HoverBox key={index} onClick={() => onChooseEndDate(option)}>
            {option !== I18n.t('chooseDate') && <Text>{option}</Text>}
            {option === I18n.t('chooseDate') && (
              <DateInput
                labelFunc={() => I18n.t('chooseDate')}
                TextFieldComponent={DateTextInput}
                value={new Date()}
                onChange={onChooseCustomEndDate}
              />
            )}
          </HoverBox>
        ))}
      </Box>
    </Box>
  )
}

export default ConfigureEndDate
