import React, { useState } from 'react'
import { Box, List, Text } from 'grommet'
import GroupSummary from './GroupSummary'
import { useDispatch, useSelector } from 'react-redux'
import { ActionCreators, Selectors } from 'galarm-shared'
import { colorThemes, I18n } from 'galarm-config'
import SearchBar from './SearchBar'
import { Fab } from 'react-tiny-fab'
import { Add } from 'grommet-icons'

const NewGroupFAB = () => {
  const colorScheme = useSelector(state => state.userSettings.webColorScheme)

  const dispatch = useDispatch()

  const onNewGroup = () => {
    dispatch(ActionCreators.showNewGroupWizard())
  }

  return (
    <Fab
      mainButtonStyles={{
        backgroundColor: colorThemes.getColorTheme(colorScheme).primary
      }}
      style={{
        bottom: 0,
        left: 0
      }}
      icon={<Add color="white" />}
      event="hover"
      onClick={onNewGroup}
      text={I18n.t('newGroup')}></Fab>
  )
}

const GroupList = ({ showSearchBar, hideSearchBar }) => {
  const groups = useSelector(state => Selectors.sortedGroupListSelector(state))

  const isUserRegistered = useSelector(
    state => state.userInfo.mobileNumber !== ''
  )

  const colorScheme = useSelector(state => state.userSettings.webColorScheme)

  const [searchText, setSearchText] = useState('')

  const onChangeText = newText => {
    setSearchText(newText)
  }

  let filteredGroups = searchText
    ? groups.filter(group =>
        group.name.toLowerCase().includes(searchText.toLowerCase())
      )
    : groups

  if (!groups || groups.length === 0) {
    return (
      <Box pad="medium" flex overflow="auto" justify="center" align="center">
        <Text color={colorThemes.getColorTheme(colorScheme).textColor}>
          {I18n.t('noGroupsWeb', { count: isUserRegistered ? 1 : 0 })}
        </Text>
        {isUserRegistered && <NewGroupFAB />}
      </Box>
    )
  }

  return (
    <Box flex overflow="auto">
      {showSearchBar && (
        <Box
          flex={{ shrink: 0 }}
          margin={{ horizontal: 'small', top: 'medium' }}>
          <SearchBar
            onChangeText={onChangeText}
            searchText={searchText}
            onCloseSearchBar={hideSearchBar}
            focus
          />
        </Box>
      )}
      {filteredGroups && filteredGroups.length === 0 && (
        <Box pad="medium" flex justify="center" align="center">
          <Text color={colorThemes.getColorTheme(colorScheme).textColor}>
            {I18n.t('noSearchResultsFound', { type: 'groups' })}
          </Text>
        </Box>
      )}
      <List
        border={false}
        margin={{ vertical: '10px' }}
        pad={{ vertical: '5px', horizontal: '20px' }}
        data={filteredGroups}
        // eslint-disable-next-line react/no-children-prop
        children={GroupSummary}
      />
      <NewGroupFAB />
    </Box>
  )
}

export default GroupList
