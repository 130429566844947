import React from 'react'
import TallTextArea from './TallTextArea'
import objGet from 'lodash/get'
import { Box } from 'grommet'

export default class Composer extends React.Component {
  state = {
    value: '',
    height: '40px'
  }

  onChangeText = text => {
    if (objGet(this.props, 'textInputProps.editable', true)) {
      const numLines = text.split('\n').length || 1
      const height = Math.min(numLines * 24, 120)
      this.setState({ value: text, height: height })
      this.props.onTextChanged(text)
    }
  }

  onClickSend = text => {
    this.onChangeText(text)
    if (text && text.trim()) {
      this.props.onSend({ text: text }, true)
    }
  }

  resetValue = () => {
    this.setState({ value: '' })
  }

  render() {
    return (
      <Box
        flex
        background="textBackgroundColor"
        round="small"
        margin={{ right: 'small' }}>
        <TallTextArea
          plain
          style={{
            marginLeft: 10,
            marginRight: 10,
            marginTop: 5,
            marginBottom: 5,
            borderRadius: 5,
            paddingHorizontal: 5,
            textAlignVertical: 'top',
            borderWidth: 1,
            borderColor: 'lightPrimary',
            minHeight: '50px',
            resize: 'none'
          }}
          color="textColor"
          value={this.state.value}
          placeholder={this.props.placeholder}
          onChange={event => {
            this.onChangeText(event.target.value)
          }}
          {...this.props.textInputProps}
          height={this.state.height}
          onKeyPress={event => {
            if (
              (event.key === 13 || event.key === 'Enter') &&
              !event.shiftKey
            ) {
              this.onClickSend(event.target.value)
              event.preventDefault()
            }
          }}
        />
      </Box>
    )
  }
}

Composer.defaultProps = {
  placeholder: 'Type a message...',
  textInputProps: {},
  onTextChanged: () => {}
}
