import React from 'react'
import { Box, Button, Header, Text } from 'grommet'
import { Close } from 'grommet-icons'
import { Constants, I18n } from 'galarm-config'
import { useDispatch } from 'react-redux'
import { ActionCreators, AlarmUtils } from 'galarm-shared'

const EditAlarmHeader = ({ alarmData, onSave }) => {
  const dispatch = useDispatch()
  return (
    <Header
      className="header"
      background="brand"
      pad="small"
      direction="row"
      justify="between">
      <Text color="white" level={5}>
        {alarmData.alarmCreationMode ===
        Constants.AlarmCreationModes.INSTANT_ALARM
          ? I18n.t('newInstantAlarm')
          : AlarmUtils.getAlarmTitle(alarmData.type, alarmData.isEdit)}
      </Text>
      <Box direction="row" align="center" gap="small">
        <Button
          plain
          color="white"
          label={I18n.t('saveAlarm')}
          onClick={onSave}
        />
        <Button
          icon={<Close />}
          onClick={() =>
            alarmData.isEdit
              ? dispatch(ActionCreators.hideEditAlarmWizard())
              : dispatch(ActionCreators.hideNewAlarmWizard())
          }
        />
      </Box>
    </Header>
  )
}

export default EditAlarmHeader
