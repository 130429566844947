import React from 'react'
import { Box } from 'grommet'
import { useSelector } from 'react-redux'
import { colors, Constants, GlobalConfig, I18n } from 'galarm-config'
import BadgeProvider from './BadgeProvider'
import ContactAvatar from './ContactAvatar'
import objGet from 'lodash/get'
import { Selectors } from 'galarm-shared'
import { SecondaryText } from 'web-components'

const { makeContactThumbnailSelector } = Selectors

const ParticipantAvatar = ({ item }) => {
  const contactThumbnailSelector = makeContactThumbnailSelector()
  const avatarThumbnailUrl = useSelector(state =>
    contactThumbnailSelector(state, { id: item.id })
  )

  return (
    <ContactAvatar
      url={objGet(avatarThumbnailUrl, 'uri', undefined)}
      name={item.name}
      size="small"
    />
  )
}

const ParticipantsAvatarList = ({
  backupGroup,
  backupContacts,
  creator,
  recipient,
  type // eslint-disable-line no-unused-vars
}) => {
  const getBadgeAndBackgroundColor = member => {
    let badge, badgeBackgroundColor

    if (member.responseStatus === Constants.ACCEPT_ALARM) {
      badge = <Box />
      badgeBackgroundColor = colors.green
    } else if (member.responseStatus === Constants.REJECT_ALARM) {
      badge = <Box />
      badgeBackgroundColor = colors.red
    } else {
      badge = <Box />
      badgeBackgroundColor = colors.orange
    }

    return { badge, badgeBackgroundColor }
  }

  const maxParticipantsToShowInAlarmSummary =
    GlobalConfig.maxParticipantsToShowInAlarmSummary

  if (backupGroup) {
    // Only show backupGroup members which are active
    let filteredMembers = backupGroup.members
      ? backupGroup.members.filter(member => {
          return member.state !== Constants.ParticipantStates.INACTIVE
        })
      : []
    let orderedMembers = filteredMembers.sort((member1, member2) => {
      return member1.order - member2.order
    })
    return (
      <Box direction="row" align="center" gap="10px" margin={{ top: '5px' }}>
        {orderedMembers
          .filter((member, index) =>
            orderedMembers.length > maxParticipantsToShowInAlarmSummary
              ? index <= maxParticipantsToShowInAlarmSummary - 2
              : index <= maxParticipantsToShowInAlarmSummary - 1
          )
          .map((member, index) => {
            const { badge, badgeBackgroundColor } =
              getBadgeAndBackgroundColor(member)
            return (
              <BadgeProvider
                key={index}
                badge={badge}
                backgroundColor={badgeBackgroundColor}>
                <ParticipantAvatar item={member} />
              </BadgeProvider>
            )
          })}
        {orderedMembers.length > maxParticipantsToShowInAlarmSummary && (
          <SecondaryText
            style={{
              marginLeft: 5,
              alignSelf: 'center'
            }}>
            {I18n.t('nMore', {
              count:
                orderedMembers.length - maxParticipantsToShowInAlarmSummary + 1
            })}
          </SecondaryText>
        )}
      </Box>
    )
  } else if (backupContacts.length > 0) {
    let orderedContacts = backupContacts.sort((contact1, contact2) => {
      return contact1.order - contact2.order
    })
    return (
      <Box direction="row" align="center" gap="10px" margin={{ top: '5px' }}>
        {orderedContacts
          .filter((member, index) =>
            orderedContacts.length > maxParticipantsToShowInAlarmSummary
              ? index <= maxParticipantsToShowInAlarmSummary - 2
              : index <= maxParticipantsToShowInAlarmSummary - 1
          )
          .map((contact, index) => {
            const { badge, badgeBackgroundColor } =
              getBadgeAndBackgroundColor(contact)
            return (
              <BadgeProvider
                key={index}
                badge={badge}
                backgroundColor={badgeBackgroundColor}>
                <ParticipantAvatar item={contact} />
              </BadgeProvider>
            )
          })}
        {orderedContacts.length > maxParticipantsToShowInAlarmSummary && (
          <SecondaryText
            style={{
              marginLeft: 5,
              alignSelf: 'center'
            }}>
            {I18n.t('nMore', {
              count:
                orderedContacts.length - maxParticipantsToShowInAlarmSummary + 1
            })}
          </SecondaryText>
        )}
      </Box>
    )
  } else if (recipient) {
    const {
      badge: creatorBadge,
      badgeBackgroundColor: creatorBadgeBackgroundColor
    } = getBadgeAndBackgroundColor(creator)
    const {
      badge: recipientBadge,
      badgeBackgroundColor: recipientBadgeBackgroundColor
    } = getBadgeAndBackgroundColor(recipient)
    return (
      <Box direction="row" gap="10px" margin={{ top: '5px' }}>
        <BadgeProvider
          badge={creatorBadge}
          backgroundColor={creatorBadgeBackgroundColor}>
          <ParticipantAvatar item={creator} />
        </BadgeProvider>
        <BadgeProvider
          badge={recipientBadge}
          backgroundColor={recipientBadgeBackgroundColor}>
          <ParticipantAvatar item={recipient} />
        </BadgeProvider>
      </Box>
    )
  }
  return null
}

export default ParticipantsAvatarList
