import React from 'react'
import { Constants, GlobalConfig, I18n } from 'galarm-config'
import { Text, Box, List } from 'grommet'

const ParticipantSummary = item => {
  let memberName = item.name
  if (item.id === GlobalConfig.uid) {
    memberName = I18n.t('you')
  } else if (item.known === false) {
    memberName = '~' + memberName
  }

  return (
    <Text color={item.known === false ? 'darkTint' : 'textColor'}>
      {memberName}
    </Text>
  )
}

const ParticipantsList = ({ backupGroup, backupContacts, recipient, type }) => {
  if (type === Constants.AlarmTypes.RECIPIENT && recipient === null) {
    return <Text>{I18n.t('none')}</Text>
  } else if (
    type !== Constants.AlarmTypes.RECIPIENT &&
    backupGroup === null &&
    backupContacts.length === 0
  ) {
    return <Text>{I18n.t('none')}</Text>
  }

  if (backupGroup) {
    // Only show backupGroup members which are active
    let filteredMembers = backupGroup.members
      ? backupGroup.members.filter(member => {
          return member.state !== Constants.ParticipantStates.INACTIVE
        })
      : []
    let orderedMembers = filteredMembers.sort((member1, member2) => {
      return member1.order - member2.order
    })
    return (
      <Box>
        <Text>{I18n.t('groupName', { name: backupGroup.name })}</Text>
        <List
          data={orderedMembers}
          border={false}
          margin="none"
          pad={{ left: 'xsmall', top: 'xsmall' }}
          // eslint-disable-next-line react/no-children-prop
          children={ParticipantSummary}
        />
      </Box>
    )
  } else if (backupContacts.length > 0) {
    return (
      <List
        data={backupContacts}
        border={false}
        margin="none"
        pad={{ top: 'xsmall' }}
        // eslint-disable-next-line react/no-children-prop
        children={ParticipantSummary}
      />
    )
  } else if (recipient) {
    return <Text>{recipient.name}</Text>
  }
  return <Box />
}

export default ParticipantsList
