import React from 'react'
import { useSelector } from 'react-redux'
import { List, Box, Text } from 'grommet'
import UnifiedAlarmSummary from './UnifiedAlarmSummary'
import { AlarmUtils, DateTimeUtils } from 'galarm-shared'
import { Constants, GlobalConfig, I18n, colorThemes } from 'galarm-config'

const AlarmList = ({ alarms }) => {
  const colorScheme = useSelector(state => state.userSettings.webColorScheme)

  const currDate = Date.now()

  let alarmsObj = {}
  alarmsObj[Constants.AlarmTimeCategories.MISSED] = []
  alarmsObj[Constants.AlarmTimeCategories.UPCOMING] = []
  alarmsObj[Constants.AlarmTimeCategories.LATER] = []
  alarmsObj[Constants.AlarmTimeCategories.OLD] = []

  alarms.forEach(alarm => {
    let alarmEffectiveDate
    let alarmStatus
    if (alarm.alarmCategory === Constants.AlarmCategories.MY_ALARM) {
      const currAlarmDate = AlarmUtils.getCurrentDateForAlarm(alarm)
      alarmEffectiveDate = DateTimeUtils.getMyAlarmDate(currAlarmDate)
      alarmStatus = alarm.status
    } else {
      const currAlarmDate = AlarmUtils.getCurrentDateForAlarm(alarm)
      alarmEffectiveDate = DateTimeUtils.getParticipantAlarmDate(
        { ...alarm, date: currAlarmDate },
        alarm.order
      )
      alarmStatus =
        alarm.status && alarm.responseStatus !== Constants.REJECT_ALARM
    }

    if (
      alarmStatus &&
      alarmEffectiveDate > currDate + GlobalConfig.upcomingAlarmInterval
    ) {
      alarmsObj[Constants.AlarmTimeCategories.LATER].push(alarm)
    } else if (alarmStatus && alarmEffectiveDate > currDate) {
      alarmsObj[Constants.AlarmTimeCategories.UPCOMING].push(alarm)
    } else if (alarmStatus && alarmEffectiveDate < currDate) {
      alarmsObj[Constants.AlarmTimeCategories.MISSED].push(alarm)
    } else {
      alarmsObj[Constants.AlarmTimeCategories.OLD].push(alarm)
    }
  })

  // Reverse these two lists so that they are in ascending order
  alarmsObj[Constants.AlarmTimeCategories.UPCOMING].reverse()
  alarmsObj[Constants.AlarmTimeCategories.LATER].reverse()

  if (
    alarmsObj[Constants.AlarmTimeCategories.UPCOMING].length === 0 &&
    alarmsObj[Constants.AlarmTimeCategories.MISSED].length === 0
  ) {
    alarmsObj[Constants.AlarmTimeCategories.UPCOMING] = [
      {
        infoMessage: I18n.t('noUpcomingAlarms')
      }
    ]
  } else if (alarmsObj[Constants.AlarmTimeCategories.UPCOMING].length === 0) {
    delete alarmsObj[Constants.AlarmTimeCategories.UPCOMING]
  }

  if (alarmsObj[Constants.AlarmTimeCategories.LATER].length === 0) {
    delete alarmsObj[Constants.AlarmTimeCategories.LATER]
  }

  if (alarmsObj[Constants.AlarmTimeCategories.OLD].length === 0) {
    delete alarmsObj[Constants.AlarmTimeCategories.OLD]
  }

  if (alarmsObj[Constants.AlarmTimeCategories.MISSED].length === 0) {
    delete alarmsObj[Constants.AlarmTimeCategories.MISSED]
  }

  let alarmsData = []
  Object.keys(alarmsObj).forEach(timeCategory => {
    alarmsData.push({
      sectionName: timeCategory
    })
    alarmsData = alarmsData.concat(alarmsObj[timeCategory])
  })

  if (!alarms || alarms.length === 0) {
    return (
      <Box pad="medium" flex justify="center" align="center">
        <Text color={colorThemes.getColorTheme(colorScheme).textColor}>
          {I18n.t('noAlarms')}
        </Text>
      </Box>
    )
  }

  return (
    <List
      border={false}
      margin={{ vertical: 'small' }}
      pad={{ vertical: 'xsmall', horizontal: 'small' }}
      data={alarmsData}
      // eslint-disable-next-line react/no-children-prop
      children={UnifiedAlarmSummary}
    />
  )
}

export default AlarmList
