let reactotronConfig

if (__DEV__) {
  const Reactotron = require('reactotron-react-js').default
  const trackGlobalErrors = require('reactotron-react-js').trackGlobalErrors
  const reactotronRedux = require('reactotron-redux').reactotronRedux
  reactotronConfig = Reactotron.configure({ name: 'Galarm' })
    .use(trackGlobalErrors())
    .use(reactotronRedux())
    .connect()

  // Totally hacky, but this allows you to not both importing reactotron-react-js
  // on every file.  This is just DEV mode, so no big deal.
  console.tron = Reactotron
  console.tron.log = console.log
  console.tron.warn = console.warn
  console.tron.error = console.error
} else {
  console = {} // eslint-disable-line no-global-assign
  console.log = () => false
  console.info = () => false
  console.warn = () => false
  console.error = () => false
  console.assert = () => false

  // a mock version should you decide to leave console.tron in your codebase
  console.tron = {
    log: () => false,
    warn: () => false,
    error: () => false,
    display: () => false,
    image: () => false
  }
}

export default reactotronConfig
