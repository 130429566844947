import moment from 'moment'

export function isSameDay(currentMessage = {}, diffMessage = {}) {
  if (!diffMessage.createdAt) {
    return false
  }

  let currentCreatedAt = moment(currentMessage.createdAt)
  let diffCreatedAt = moment(diffMessage.createdAt)

  if (!currentCreatedAt.isValid() || !diffCreatedAt.isValid()) {
    return false
  }

  return currentCreatedAt.isSame(diffCreatedAt, 'day')
}

export function isSameUser(currentMessage = {}, diffMessage = {}) {
  return !!(
    diffMessage.user &&
    currentMessage.user &&
    diffMessage.user._id === currentMessage.user._id
  )
}

export function getUserColor(userName) {
  let sumChars = 0
  for (let i = 0; i < userName.length; i++) {
    sumChars += userName.charCodeAt(i)
  }

  // inspired by https://github.com/wbinnssmith/react-user-avatar
  // colors from https://flatuicolors.com/  & https://material.io/guidelines/style/color.html#color-color-palette
  const colors = [
    '#e67e22', // carrot
    '#2ecc71', // emerald
    '#3498db', // peter river
    '#8e44ad', // wisteria
    '#e91e63', // pink
    '#1abc9c', // turquoise
    '#2c3e50', // midnight blue
    '#3f51b5', // indigo
    '#00bcd4', // cyan
    '#8bc34a', // light green
    '#795548' // brown
  ]

  return colors[sumChars % colors.length]
}

export function getUserInitials(userName) {
  const name = userName
    .toUpperCase()
    .split(' ')
    .filter(word => word !== '')
  if (name.length === 1) {
    return `${name[0].charAt(0)}`
  } else if (name.length > 1) {
    return `${name[0].charAt(0)}${name[1].charAt(0)}`
  } else {
    return ''
  }
}
