import { ActionCreators, Utils } from 'galarm-shared'
import { Box, Button, Card, CardBody, Header, Text } from 'grommet'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import LabelledText from './LabelledText'
import { GlobalConfig, I18n } from 'galarm-config'
import AppCanvas from './AppCanvas'
import ColoredButton from './ColoredButton'
import { Close } from 'grommet-icons'
import TintedImage from './TintedImage'
import EntityNotFound from './EntityNotFound'
import { Selectors } from 'galarm-shared'
import { NavigationUtils } from 'galarm-ps-api'

const { upgradePurchasedSelector } = Selectors

// eslint-disable-next-line no-unused-vars
const ContactDetailsHeader = ({ contact }) => {
  const dispatch = useDispatch()

  const closeContactDetailsScreen = () => {
    dispatch(ActionCreators.hideContactDetailsScreen())
  }

  return (
    <Header className="header" background="brand" pad="small">
      <Text color="white">{I18n.t('contactDetails')}</Text>
      <Button icon={<Close />} onClick={closeContactDetailsScreen} />
    </Header>
  )
}

const ContactDetails = ({ contactId }) => {
  console.log('contactId', contactId)

  const dispatch = useDispatch()
  const upgradePurchased = useSelector(upgradePurchasedSelector)
  const instantAlarmsCount = useSelector(
    state => state.userInfo.instantAlarmsCount
  )

  const contact = useSelector(state =>
    Utils.getObjectWithId(state.contacts.contacts, contactId)
  )

  const createAlarmWithContact = () => {
    dispatch(
      ActionCreators.showSelectAlarmTypeScreen({
        selectedContacts: [contact]
      })
    )
  }

  if (!contact) {
    return (
      <EntityNotFound
        entityType={I18n.t('entityContact')}
        onClose={() => dispatch(ActionCreators.hideContactDetailsScreen())}
      />
    )
  }

  const createInstantAlarmWithContact = () => {
    if (
      instantAlarmsCount >= GlobalConfig.maxAllowedInstantAlarms &&
      !upgradePurchased
    ) {
      NavigationUtils.showGalarmPremiumScreen('instantAlarmsPremiumFeature')
      return
    }

    dispatch(
      ActionCreators.showNewInstantAlarmWizard({
        selectedContacts: [
          {
            name: contact.name,
            id: contact.id,
            mobileNumber: contact.mobileNumber
          }
        ]
      })
    )
  }

  return (
    <AppCanvas key={contactId} overflow="auto">
      <ContactDetailsHeader contact={contact} />
      <Card
        margin="small"
        background="textBackgroundColor"
        width="600px"
        flex={{ shrink: 0 }}>
        <CardBody gap="medium" pad="medium">
          <Box direction="row" align="center">
            <LabelledText
              label={I18n.t('name')}
              text={contact.name}
              border="bottom"
            />
            <Box
              pad={{ left: 'medium', right: 'small' }}
              onClick={createInstantAlarmWithContact}>
              <TintedImage
                height="24px"
                src={require('galarm-res/img/web/ic_instant_alarm.svg').default}
                tintColor="primary"
              />
            </Box>
          </Box>
          <Box>
            <LabelledText
              label={I18n.t('mobileNumber')}
              text={contact.mobileNumber}
              border="bottom"
            />
          </Box>
          <ColoredButton
            primary
            color="primary"
            margin={{ top: '5px' }}
            onClick={createAlarmWithContact}
            label={I18n.t('createAlarmWithContact', { name: contact.name })}
          />
        </CardBody>
      </Card>
    </AppCanvas>
  )
}

export default ContactDetails
