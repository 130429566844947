import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

import React, { useState, useRef, useEffect } from 'react'
import { Box, Button } from 'grommet'
import { NavigationUtils } from 'galarm-ps-api'
import { I18n, colorThemes } from 'galarm-config'
import { useSelector } from 'react-redux'
import { ContentState, EditorState } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import htmlToDraft from 'html-to-draftjs'
import { convertToRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import linkifyHtml from 'linkify-html'
import TextAreaToolbarInlineComponent from './TextAreaToolbarInlineComponent'
import TextAreaToolbarListComponent from './TextAreaToolbarListComponent'
import TextAreaToolbarEmojiComponent from './TextAreaToolbarEmojiComponent'
import { StringUtils } from 'galarm-shared'

const RichTextEditor = props => {
  const calcState = value => {
    if (value) {
      // Creating the content from existing HTML
      const blocksFromHtml = htmlToDraft(value)
      const { contentBlocks, entityMap } = blocksFromHtml
      const initialContentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap
      )
      return EditorState.createWithContent(initialContentState)
    }
    return EditorState.createEmpty()
  }
  const [editorState, setEditorState] = useState(calcState(props.notes))

  useEffect(() => {
    setEditorState(calcState(props.notes))
  }, [props.notes])

  const [isEditorStateChanged, setEditorStateChanged] = useState(false)

  const colorScheme = useSelector(state => state.userSettings.webColorScheme)

  const editorReference = useRef(null)

  const onNotesChange = newEditorState => {
    if (editorState !== newEditorState) {
      setEditorState(newEditorState)
      setEditorStateChanged(true)
    } else {
      setEditorStateChanged(false)
    }
  }

  // On Done we want to linkify to create links for html and email
  // and also add telephone numbers as links
  const onDone = () => {
    var contentState = editorState.getCurrentContent()
    if (contentState.hasText()) {
      const rawContentState = convertToRaw(editorState.getCurrentContent())
      let markup = StringUtils.telify(
        linkifyHtml(draftToHtml(rawContentState), {
          attributes: {
            rel: 'noopener noreferrer'
          },
          target: {
            url: '_blank'
          }
        })
      )
      props.onNotesChange(markup)
      props.onDone()
    } else {
      // If there is no text, same as cancel
      setEditorState(calcState(props.notes))
      props.onCancel()
    }
  }

  const onCancel = () => {
    if (isEditorStateChanged) {
      NavigationUtils.showAlert(I18n.t('confirm'), I18n.t('discardChanges'), [
        {
          text: I18n.t('yes'),
          onPress: () => {
            setEditorState(calcState(props.notes))
            props.onCancel()
          }
        },
        {
          text: I18n.t('no')
        }
      ])
      return
    } else {
      setEditorState(calcState(props.notes))
      props.onCancel()
    }
  }

  const styles = {
    editorStyle: {
      height: '300px',
      width: '500px',
      backgroundColor:
        colorThemes.getColorTheme(colorScheme).textBackgroundColor,
      padding: '10px',
      color: colorThemes.getColorTheme(colorScheme).textColor
    },
    toolbarStyle: {
      backgroundColor: colorThemes.getColorTheme(colorScheme).lightTint,
      border: 'none'
    },
    wrapperStyle: {}
  }

  // If the user changes block type before entering any text, we can
  // either style the placeholder or hide it
  let className = 'RichEditor-editor'
  var contentState = editorState.getCurrentContent()
  if (!contentState.hasText()) {
    if (contentState.getBlockMap().first().getType() !== 'unstyled') {
      className += ' RichEditor-hidePlaceholder'
    }
  }

  if (props.readOnly) {
    return (
      <Box>
        <div
          className={
            props.linksDisabled ? 'RichText-disabledLink' : 'RichEditor-editor'
          }>
          <Editor
            placeholder={I18n.t('enterNotesForAlarm')}
            editorState={editorState}
            onEditorStateChange={onNotesChange}
            readOnly="true"
            toolbarHidden="true"
            ref={editorReference}
            stripPastedStyles="true"
          />
        </div>
      </Box>
    )
  } else {
    return (
      <Box>
        <div className={className}>
          <Editor
            placeholder={I18n.t('enterNotesForAlarm')}
            editorState={editorState}
            wrapperStyle={styles.wrapperStyle}
            editorStyle={styles.editorStyle}
            toolbarStyle={styles.toolbarStyle}
            onEditorStateChange={onNotesChange}
            toolbar={{
              options: ['inline', 'list', 'emoji'],
              inline: {
                component: TextAreaToolbarInlineComponent
              },
              list: { component: TextAreaToolbarListComponent },
              emoji: { component: TextAreaToolbarEmojiComponent }
            }}
            ref={editorReference}
            stripPastedStyles="true"
          />
        </div>
        <Box
          direction="row"
          alignSelf="end"
          gap="small"
          margin={{ bottom: 'small', top: 'medium' }}>
          <Button onClick={() => onDone()} label={I18n.t('done')} />
          <Button onClick={() => onCancel()} label={I18n.t('cancel')} />
        </Box>
      </Box>
    )
  }
}

export default RichTextEditor
