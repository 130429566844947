import PropTypes from 'prop-types'
import React from 'react'
import { Box, Button, Header, Text } from 'grommet'
import { useSelector } from 'react-redux'
import { FormNext, FormPrevious } from 'grommet-icons'
import { Selectors } from 'galarm-shared'
import { I18n } from 'galarm-config'

const { alarmCategoriesSelector } = Selectors

const navigate = {
  PREVIOUS: 'PREV',
  NEXT: 'NEXT',
  TODAY: 'TODAY',
  DATE: 'DATE'
}

const CalendarToolbar = props => {
  let {
    localizer: { messages },
    label
  } = props

  // Add category name to the toolbar label if user has selected a category
  const categorizedAlarmView = useSelector(
    state => state.userSettings.categorizedAlarmView
  )
  const currentlySelectedAlarmCategoryId = useSelector(
    state => state.appState.currentlySelectedAlarmCategoryId
  )
  const alarmCategories = useSelector(alarmCategoriesSelector)

  const showAlertIncidents = useSelector(
    state => state.appState.showAlertIncidents
  )

  if (categorizedAlarmView && currentlySelectedAlarmCategoryId) {
    const currentlySelectedAlarmCategory =
      alarmCategories[currentlySelectedAlarmCategoryId]

    label = `${label} - ${currentlySelectedAlarmCategory.name}`
  } else if (showAlertIncidents) {
    label = `${label} - ${I18n.t('alertIncidents')}`
  }

  const onNavigate = action => {
    props.onNavigate(action)
  }

  const onView = view => {
    props.onView(view)
  }

  const viewNamesGroup = messages => {
    let viewNames = props.views
    const view = props.view

    if (viewNames.length > 1) {
      return viewNames.map(name => (
        <Button
          key={name}
          primary
          color={view === name ? 'lightPrimary' : 'mediumTint'}
          hoverIndicator
          onClick={onView.bind(null, name)}
          label={messages[name]}
        />
      ))
    }
  }

  return (
    <Header className="header" background="brand" pad="small" justify="between">
      <Button
        plain
        color="white"
        onClick={onNavigate.bind(null, navigate.TODAY)}
        label={messages.today}
        margin="small"
      />
      <Box direction="row" align="center">
        <Button
          onClick={onNavigate.bind(null, navigate.PREVIOUS)}
          icon={<FormPrevious color="white" />}
        />
        <Text color="white" size="medium">
          {label}
        </Text>
        <Button
          onClick={onNavigate.bind(null, navigate.NEXT)}
          icon={<FormNext color="white" />}
        />
      </Box>
      <Box direction="row" gap="xsmall" round="medium" background="mediumTint">
        {viewNamesGroup(messages)}
      </Box>
    </Header>
  )
}

CalendarToolbar.propTypes = {
  view: PropTypes.string.isRequired,
  views: PropTypes.arrayOf(PropTypes.string).isRequired,
  label: PropTypes.node.isRequired,
  localizer: PropTypes.object,
  onNavigate: PropTypes.func.isRequired,
  onView: PropTypes.func.isRequired
}

export default CalendarToolbar
