import React from 'react'
import { Box, Text } from 'grommet'
import LabelText from './LabelText'

const LabelledText = ({ label, text, ...containerProps }) => {
  return (
    <Box flex {...containerProps}>
      <LabelText color="darkTint">{label}</LabelText>
      <Text style={{ overflowWrap: 'break-word' }}>{text}</Text>
    </Box>
  )
}

export default LabelledText
