import React from 'react'
import { Box, List } from 'grommet'

// Section List will create a list with headers for each section
// Expects a sections array which has sections with title and data, and extras
// to support the components, for e.g. if we are showing a summary with a text and checkbox
// extras could be clickHandler or state change handlers
const SectionList = props => {
  const { renderItem, renderSectionHeader, sections, extras } = props
  const Component = renderItem
  return (
    <Box direction="column">
      {sections.map((section, index) => {
        return (
          <Box key={index}>
            <Box>{renderSectionHeader(section)}</Box>
            <List
              border={props.border || false}
              pad={props.pad || { horizontal: 'small', vertical: 'xsmall' }}
              margin="none"
              data={section.data}>
              {(item, index) => {
                return <Component key={index} item={item} extras={extras} />
              }}
            </List>
          </Box>
        )
      })}
    </Box>
  )
}

export default SectionList
