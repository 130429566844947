import React, { useState } from 'react'
import { Select } from 'grommet'
import { I18n } from 'galarm-config'
import { AlarmUtils } from 'galarm-shared'

const SelectTimezone = ({ selectedTimezone, onSaveTimezone }) => {
  const [searchText, setSearchText] = useState('')

  const timezones = AlarmUtils.getAllTimezones()
  const timezoneOptions = timezones
    .filter(timezone =>
      timezone.label.toLowerCase().includes(searchText.toLowerCase())
    )
    .map(timezone => {
      if (selectedTimezone === timezone.value) {
        return { ...timezone, selected: true }
      } else {
        return {
          ...timezone,
          selected: false
        }
      }
    })

  const onSearch = text => {
    setSearchText(text)
  }

  const onSelectTimezone = item => {
    onSaveTimezone(item, !item.selected)
  }

  return (
    <Select
      placeholder={I18n.t('deviceTimezone')}
      searchPlaceholder={I18n.t('searchForATimezone')}
      value={selectedTimezone || ''}
      valueKey={{ key: 'value', reduce: true }}
      labelKey="label"
      options={timezoneOptions}
      onChange={({ option }) => {
        onSelectTimezone(option)
      }}
      onClose={() => setSearchText('')}
      onSearch={text => onSearch(text)}
    />
  )
}

export default SelectTimezone
