import { colorThemes } from 'galarm-config'
import ua from 'ua-parser-js'

const WebUtils = (function () {
  const getBrowserName = () => {
    const UAParser = ua(window.navigator.userAgent)
    return UAParser.browser.name
  }

  const getBrowserVersion = () => {
    const UAParser = ua(window.navigator.userAgent)
    return UAParser.browser.version
  }

  const getDeviceOsName = () => {
    const UAParser = ua(window.navigator.userAgent)
    return UAParser.os.name
  }

  const getDeviceOsVersion = () => {
    const UAParser = ua(window.navigator.userAgent)
    return UAParser.os.version
  }

  const getGrommetTheme = colorScheme => {
    const theme = {
      global: {
        colors: {
          brand: colorThemes.getColorTheme(colorScheme).primary,
          lightTint: colorThemes.getColorTheme(colorScheme).lightTint,
          mediumTint: colorThemes.getColorTheme(colorScheme).mediumTint,
          darkTint: colorThemes.getColorTheme(colorScheme).darkTint,
          lightBlueGreyTint:
            colorThemes.getColorTheme(colorScheme).lightBlueGreyTint,
          transluscentBackground:
            colorThemes.getColorTheme(colorScheme).transluscentBackground,
          transluscentText:
            colorThemes.getColorTheme(colorScheme).transluscentText,
          textColor: colorThemes.getColorTheme(colorScheme).textColor,
          screenBackgroundColor:
            colorThemes.getColorTheme(colorScheme).screenBackgroundColor,
          textBackgroundColor:
            colorThemes.getColorTheme(colorScheme).textBackgroundColor,
          listItemSelectedColor:
            colorThemes.getColorTheme(colorScheme).lightYellow,
          formFieldIcon: colorThemes.getColorTheme(colorScheme).formFieldIcon,
          primary: colorThemes.getColorTheme(colorScheme).primary,
          primaryBusiness:
            colorThemes.getColorTheme(colorScheme).primaryBusiness,
          primaryEnterprise:
            colorThemes.getColorTheme(colorScheme).primaryEnterprise,
          lightPrimary: colorThemes.getColorTheme(colorScheme).lightPrimary,
          tileHeader: colorThemes.getColorTheme(colorScheme).tileHeader,
          transluscentPrimary:
            colorThemes.getColorTheme(colorScheme).transluscentPrimary,
          tabBarBackgroundColor:
            colorThemes.getColorTheme(colorScheme).tabBarBackgroundColor,
          tabBarButtonColor:
            colorThemes.getColorTheme(colorScheme).tabBarButtonColor,
          tabBarSelectedButtonColor:
            colorThemes.getColorTheme(colorScheme).tabBarSelectedButtonColor,
          navBarBackgroundColor:
            colorThemes.getColorTheme(colorScheme).navBarBackgroundColor,
          navBarTextColor:
            colorThemes.getColorTheme(colorScheme).navBarTextColor,
          navBarButtonColor:
            colorThemes.getColorTheme(colorScheme).navBarButtonColor,
          navBarSubtitleTextColor:
            colorThemes.getColorTheme(colorScheme).navBarSubtitleTextColor,
          darkPrimary: colorThemes.getColorTheme(colorScheme).darkPrimary,
          tileShadowColor:
            colorThemes.getColorTheme(colorScheme).tileShadowColor,
          outlineButtonBackgroundColor:
            colorThemes.getColorTheme(colorScheme).outlineButtonBackgroundColor,
          warningTileBackgroundColor:
            colorThemes.getColorTheme(colorScheme).warningTileBackgroundColor,
          warningTileTextColor:
            colorThemes.getColorTheme(colorScheme).warningTileTextColor,
          warningTextColor:
            colorThemes.getColorTheme(colorScheme).warningTextColor,
          statusBarColor: colorThemes.getColorTheme(colorScheme).statusBarColor,
          fabBackground: colorThemes.getColorTheme(colorScheme).fabBackground,
          enabledSwitchTrackColor:
            colorThemes.getColorTheme(colorScheme).enabledSwitchTrackColor,
          control: colorThemes.getColorTheme(colorScheme).primary,
          text: colorThemes.getColorTheme(colorScheme).textColor,
          border: 'mediumTint'
        },
        size: {
          medium: '500px'
        },
        font: {
          family: 'Roboto',
          size: '18px',
          height: '20px'
        },
        input: {
          extend: {
            borderRadius: '5px',
            fontWeight: 'normal',
            borderColor: 'mediumTint',
            borderWidth: '2px'
          }
        },
        focus: { outline: { size: '0px' } },
        elevation: {
          light: {
            none: 'none',
            xsmall: `1px 1px 2px ${
              colorThemes.getColorTheme(colorScheme).shadowColor
            }`,
            small: `2px 2px 4px ${
              colorThemes.getColorTheme(colorScheme).shadowColor
            }`,
            medium: `4px 4px 8px ${
              colorThemes.getColorTheme(colorScheme).shadowColor
            }`,
            large: `8px 8px 16px ${
              colorThemes.getColorTheme(colorScheme).shadowColor
            }`,
            xlarge: `12px 12px 24px ${
              colorThemes.getColorTheme(colorScheme).shadowColor
            }`
          },
          dark: {
            none: 'none',
            xsmall: `1px 1px 2px ${
              colorThemes.getColorTheme(colorScheme).shadowColor
            }`,
            small: `2px 2px 4px ${
              colorThemes.getColorTheme(colorScheme).shadowColor
            }`,
            medium: `4px 4px 8px ${
              colorThemes.getColorTheme(colorScheme).shadowColor
            }`,
            large: `8px 8px 16px ${
              colorThemes.getColorTheme(colorScheme).shadowColor
            }`,
            xlarge: `12px 12px 24px ${
              colorThemes.getColorTheme(colorScheme).shadowColor
            }`
          }
        },
        breakpoints: {
          small: {
            value: 720
          },
          medium: {
            value: 1200
          },
          large: {}
        }
      },
      textArea: {
        disabled: {
          opacity: '0.8'
        }
      },
      accordion: {
        border: {
          side: 'top'
        },
        panel: {
          border: {
            style: 'hidden',
            side: 'top'
          }
        }
      },
      formField: {
        border: {
          color: colorThemes.getColorTheme(colorScheme).darkTint,
          position: 'inner',
          side: 'bottom',
          error: {
            color: {
              dark: 'white',
              light: 'status-critical'
            }
          }
        }
      },
      radioButton: {
        hover: {
          border: {
            color: 'primary'
          }
        },
        border: {
          color: 'darkTint'
        },
        check: {
          color: 'primary'
        }
      },
      checkBox: {
        hover: {
          border: {
            color: 'primary'
          }
        },
        border: {
          color: 'mediumTint'
        },
        check: {
          color: 'primary'
        }
      },
      tip: {
        content: {
          background: 'lightTint'
        }
      },
      dataTable: {
        pinned: {
          header: { background: { opacity: 'strong' } }
        }
      }
    }

    return theme
  }

  return {
    getBrowserName,
    getDeviceOsName,
    getGrommetTheme,
    getBrowserVersion,
    getDeviceOsVersion
  }
})()
export default WebUtils
