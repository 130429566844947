import {
  AbstractEditAlarmContainer,
  ActionCreators,
  AlarmUtils,
  DateTimeUtils,
  Selectors
} from 'galarm-shared'
import moment from 'moment-timezone'
import { Constants, GlobalConfig, I18n } from 'galarm-config'
import {
  FirebaseProxy,
  NavigationUtils,
  NotificationManager
} from 'galarm-ps-api'
import { connect } from 'react-redux'
import * as RNLocalize from 'react-native-localize'

const {
  upgradePurchasedSelector,
  numGroupAlarmsSelector,
  numBuddyAlarmsSelector,
  alarmCategoriesSelector,
  requiredPermissionsEnabledSelector
} = Selectors

class NewAlarmContainer extends AbstractEditAlarmContainer {
  getDateForAlarm = () => {
    if (this.props.alarmDate) {
      return this.props.alarmDate
    }

    const currDateMoment = moment()
    const minutes = currDateMoment.minutes()
    const hours = currDateMoment.hours()
    if (minutes < 30) {
      return currDateMoment
        .hours(hours + 1)
        .minutes(0)
        .seconds(0)
        .milliseconds(0)
        .valueOf()
    } else {
      return currDateMoment
        .hours(hours + 1)
        .minutes(30)
        .seconds(0)
        .milliseconds(0)
        .valueOf()
    }
  }

  getInitialBackupContacts = () => {
    if (
      this.props.alarmType === Constants.AlarmTypes.CASCADING ||
      this.props.alarmType === Constants.AlarmTypes.SIMULTANEOUS
    ) {
      return this.props.selectedContacts || []
    }
    return []
  }

  getInitialBackupGroup = () => {
    if (
      this.props.alarmType === Constants.AlarmTypes.CASCADING ||
      this.props.alarmType === Constants.AlarmTypes.SIMULTANEOUS
    ) {
      return this.props.selectedGroup || null
    }
    return null
  }

  getInitialRecipient = () => {
    if (
      this.props.alarmType === Constants.AlarmTypes.RECIPIENT &&
      this.props.selectedContacts &&
      this.props.selectedContacts.length > 0
    ) {
      return this.props.selectedContacts[0]
    }
    return null
  }

  state = {
    id: GlobalConfig.rootFirebaseRef.push().key,
    name: '',
    date: this.getDateForAlarm(),
    backupContacts: this.getInitialBackupContacts(),
    backupGroup: this.getInitialBackupGroup(),
    notes: '',
    alarmPhotoUrl: '',
    repeat: '',
    repeatType: '',
    creatorTimezone: RNLocalize.getTimeZone(),
    timezoneSetting: Constants.TIMEZONE_SETTINGS.DEVICE,
    type: this.props.alarmType,
    isEdit: false,
    cascadingAlarmInterval:
      this.props.alarmType === Constants.AlarmTypes.CASCADING
        ? GlobalConfig.defaultCascadingAlarmInterval
        : null,
    recipientAlarmInterval:
      this.props.alarmType === Constants.AlarmTypes.RECIPIENT
        ? GlobalConfig.defaultRecipientAlarmInterval
        : null,
    recipient: this.getInitialRecipient(),
    ringerSettings: AlarmUtils.getDefaultRingerSettings(),
    creationMode: Constants.AlarmCreationModes.NORMAL_ALARM,
    endDate: GlobalConfig.defaultAlarmEndDate,
    alarmCategoryId:
      this.props.alarmCategoryId || Constants.UNCATEGORIZED_ALARM_CATEGORY_ID,
    source: Constants.AlarmCreationSources.CREATED_MANUALLY_FROM_WEB,
    preReminderDuration: GlobalConfig.defaultPreReminderDuration
  }

  // Whichever properties could be passed to the NewAlarmContainer should be checked here
  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(nextProps, nextState) {
    if (
      nextProps.alarmType !== this.props.alarmType ||
      nextProps.alarmCategoryId !== this.props.alarmCategoryId ||
      nextProps.alarmDate !== this.props.alarmDate
    ) {
      this.setState({
        type: nextProps.alarmType || this.props.alarmType,
        alarmCategoryId:
          nextProps.alarmCategoryId ||
          Constants.UNCATEGORIZED_ALARM_CATEGORY_ID,
        date: nextProps.alarmDate || this.getDateForAlarm()
      })
    }
  }

  validateAlarm() {
    const validationMessages = super.validateAlarm()

    const repeatValidationMessage = AlarmUtils.validateRepetition(
      this.state.repeatType,
      this.state.repeat
    )

    if (repeatValidationMessage) {
      validationMessages.push(repeatValidationMessage)
    }

    return validationMessages
  }

  onSaveAlarmCore = () => {
    const alarm = this.createAlarm(this.state.id)

    // Use the default device timezone here. No need to pass in the timezone.

    // To get the right duration, passing in the alarm's calculated date instead of the state's date,
    // to take into account any timezone changes, we are converting the date to the right timezone when we
    // save the alarm, the user could just choose 2.30 and save in New york time zone,
    // then it will show us that only 2 hours are left instead of 18 hours.
    const durationString = DateTimeUtils.getDurationTillDateAsString(
      AlarmUtils.getNextDateForAlarm(
        alarm.date,
        this.state.endDate,
        this.state.repeatType,
        this.state.repeat
      )
    )
    this.props.dispatch(ActionCreators.addAlarm(alarm))

    // Send a notification to the mobile device.
    const notificationInfo = {
      type: Constants.NotificationTypes.ALARM_ADDED,
      alarmId: alarm.id,
      alarmType: alarm.type,
      alarmName: alarm.name,
      alarmDate: alarm.date.toString()
    }
    NotificationManager.sendRemoteNotification(
      GlobalConfig.uid,
      Constants.NotificationKeys.AlarmAddedFromWebNotification,
      notificationInfo
    )

    if (
      this.state.alarmCategoryId &&
      this.state.alarmCategoryId !== Constants.UNCATEGORIZED_ALARM_CATEGORY_ID
    ) {
      this.props.dispatch(
        ActionCreators.addAlarmToAlarmCategory(
          this.state.alarmCategoryId,
          this.state.id
        )
      )
      FirebaseProxy.logEvent(
        Constants.UserAnalyticsEvents.SET_ALARM_CATEGORY_FROM_NEW_ALARM_SCREEN,
        {}
      )
    }

    NavigationUtils.showTransientAlert({
      message: I18n.t('alarmDurationAndroid', { durationString }),
      duration: Constants.AlertDurations.SHORT
    })

    this.props.onClose()

    FirebaseProxy.logEvent(Constants.UserAnalyticsEvents.SAVE_NEW_ALARM, {})
  }
}

const mapStateToProps = state => ({
  creatorName: state.userInfo.name,
  creatorMobileNumber: state.userInfo.formattedMobileNumberWithCC,
  requiredPermissionsEnabled: requiredPermissionsEnabledSelector(state),
  isAuthenticated: state.appState.authenticatedWithFirebase,
  rateTheAppInfo: state.userInfo.rateTheAppInfo,
  mobileNumber: state.userInfo.mobileNumber,
  upgradePurchased: upgradePurchasedSelector(state),
  numGroupAlarms: numGroupAlarmsSelector(state),
  numBuddyAlarms: numBuddyAlarmsSelector(state),
  colorScheme: state.userSettings.webColorScheme,
  alarmCategories: alarmCategoriesSelector(state),
  specifyTimezoneForAlarm: state.userSettings.specifyTimezoneForAlarm,
  seenSpecifyTimezoneAlert: state.appState.seenSpecifyTimezoneAlert
})

export default connect(mapStateToProps)(NewAlarmContainer)

export { NewAlarmContainer }
