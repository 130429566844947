import React, { useState } from 'react'
import { Box, Text } from 'grommet'
import { Constants, I18n } from 'galarm-config'
import SelectContacts from './SelectContacts'
import SelectGroup from './SelectGroup'
import TintedImage from './TintedImage'
import SelectedParticipants from './SelectedParticipants'
import ChangeGroupMembersConfig from './ChangeGroupMembersConfig'
import ReorderParticipants from './ReorderParticipants'

const ConfigureAlarmParticipants = ({
  type, // eslint-disable-line no-unused-vars
  backupGroup,
  backupContacts,
  onSelectParticipant,
  onUnselectParticipant,
  updateParticipantsOrder,
  updateMembersConfig
}) => {
  const [groupsSelected, setGroupsSelected] = useState(
    backupGroup ? true : false
  )

  const [showReorderParticipants, setShowReoderParticipants] = useState(false)

  const [showChangeGroupConfig, setShowChangeGroupConfig] = useState(false)

  const onEditParticipantConfig = () => {
    if (groupsSelected) {
      setShowChangeGroupConfig(true)
    } else if (
      type === Constants.AlarmTypes.CASCADING &&
      backupContacts.length > 0
    ) {
      setShowReoderParticipants(true)
    }
  }

  // When any participant is selected, we want to dismiss the re-order participant window or the group config window
  const onSelectParticipantCore = participant => {
    setShowChangeGroupConfig(false)
    setShowReoderParticipants(false)
    onSelectParticipant(participant)
  }

  return (
    <Box flex direction="row">
      <Box width={'500px'}>
        <Box
          flex={{ shrink: 0 }}
          pad={{ horizontal: 'small', top: 'small' }}
          direction="row"
          align="center"
          justify="between">
          <SelectedParticipants
            backupGroup={backupGroup}
            backupContacts={backupContacts}
            type={type}
            onClickCross={onUnselectParticipant}
          />
          {((groupsSelected && backupGroup && backupGroup.members.length > 0) ||
            (type === Constants.AlarmTypes.CASCADING &&
              backupContacts &&
              backupContacts.length > 1)) && (
            <Box
              pad={{ left: 'small' }}
              align="end"
              onClick={onEditParticipantConfig}>
              <TintedImage
                src={require('galarm-res/img/web/ic_swap_vert.svg').default}
                tintColor="primary"
              />
            </Box>
          )}
        </Box>
        <Box
          flex={{ shrink: 0 }}
          overflow="auto"
          direction="row"
          background="transparent"
          border={{
            color: 'primary',
            size: 'small',
            side: 'all'
          }}
          margin="medium"
          round="small">
          <Box
            flex
            pad="small"
            background={groupsSelected ? 'textBackgroundColor' : 'primary'}
            align="center"
            onClick={() => setGroupsSelected(false)}
            round={{ size: 'small', corner: 'right' }}>
            <Text color={groupsSelected ? 'textColor' : 'white'}>
              {I18n.t('contacts')}
            </Text>
          </Box>
          <Box
            flex
            pad="small"
            background={groupsSelected ? 'primary' : 'textBackgroundColor'}
            align="center"
            onClick={() => setGroupsSelected(true)}
            round={{ size: 'small', corner: 'left' }}>
            <Text color={groupsSelected ? 'white' : 'textColor'}>
              {I18n.t('groups')}
            </Text>
          </Box>
        </Box>
        {groupsSelected && (
          <SelectGroup
            selectedGroup={backupGroup}
            onSelectGroup={onSelectParticipantCore}
          />
        )}
        {!groupsSelected && (
          <SelectContacts
            selectedContacts={backupContacts}
            onSelectContact={onSelectParticipantCore}
          />
        )}
      </Box>
      {showReorderParticipants && (
        <Box border="left">
          <ReorderParticipants
            participants={backupContacts}
            updateParticipantsOrder={updateParticipantsOrder}
          />
        </Box>
      )}
      {showChangeGroupConfig && (
        <Box border="left">
          <ChangeGroupMembersConfig
            backupGroup={backupGroup}
            alarmType={type}
            updateMembersConfig={updateMembersConfig}
          />
        </Box>
      )}
    </Box>
  )
}

export default ConfigureAlarmParticipants
