import { LogUtils } from 'galarm-ps-api'

const FirebaseManager = (function () {
  let firebaseDb = {}

  const addFirebaseRefChildAdded = function (componentName, ref, callback) {
    addFirebaseRefEventHandler('child_added', componentName, ref, callback)
  }

  const addFirebaseRefChildAddedIfNotPresent = function (
    componentName,
    ref,
    callback
  ) {
    const bindKey = ref.toString()
    try {
      if (
        !firebaseDb[componentName]['firebaseListeners'][bindKey]['child_added']
      ) {
        addFirebaseRefEventHandler('child_added', componentName, ref, callback)
      }
    } catch (error) {
      addFirebaseRefEventHandler('child_added', componentName, ref, callback)
    }
  }

  const addFirebaseRefChildChanged = function (componentName, ref, callback) {
    addFirebaseRefEventHandler('child_changed', componentName, ref, callback)
  }

  const addFirebaseRefChildMoved = function (componentName, ref, callback) {
    addFirebaseRefEventHandler('child_moved', componentName, ref, callback)
  }

  const addFirebaseRefChildRemoved = function (componentName, ref, callback) {
    addFirebaseRefEventHandler('child_removed', componentName, ref, callback)
  }

  const addFirebaseRefValueChanged = function (componentName, ref, callback) {
    addFirebaseRefEventHandler('value', componentName, ref, callback)
  }

  // This will call the event handler only on the value change and not on the initial value
  const addFirebaseRefValueChangedOnly = function (
    componentName,
    ref,
    callback
  ) {
    let initialValue = true
    const unsubscribe = ref.on(
      'value',
      snapshot => {
        if (initialValue) {
          initialValue = false
        } else {
          callback(snapshot)
        }
      },
      error => {
        LogUtils.logError(
          error,
          'Unable to bind firebase value event at location ' + ref.toString()
        )
      }
    )
    addEventHandlerToMap('value', componentName, ref, unsubscribe)
  }

  const addFirebaseRefEventHandler = function (
    event,
    componentName,
    ref,
    callback
  ) {
    const unsubscribe = ref.on(event, callback, error => {
      LogUtils.logError(
        error,
        'Unable to bind firebase ' +
          event +
          ' event at location ' +
          ref.toString()
      )
    })
    addEventHandlerToMap(event, componentName, ref, unsubscribe)
  }

  const addEventHandlerToMap = function (event, componentName, ref, callback) {
    const bindKey = ref.toString()

    if (!firebaseDb[componentName]) {
      firebaseDb[componentName] = {}
      firebaseDb[componentName]['firebaseRefs'] = {}
      firebaseDb[componentName]['firebaseListeners'] = {}
    }

    let firebaseRefs = firebaseDb[componentName]['firebaseRefs']
    let firebaseListeners = firebaseDb[componentName]['firebaseListeners']

    firebaseRefs[bindKey] = ref
    if (!firebaseListeners[bindKey]) {
      firebaseListeners[bindKey] = {}
    }

    firebaseListeners[bindKey][event] = callback
  }

  const unbindFirebaseEventHandlers = function (componentName) {
    if (
      !firebaseDb[componentName] ||
      !firebaseDb[componentName]['firebaseRefs'] ||
      !firebaseDb[componentName]['firebaseListeners']
    ) {
      console.tron.log(
        'No firebase event handlers have been defined for component ' +
          componentName
      )
      return
    }

    const firebaseRefs = firebaseDb[componentName]['firebaseRefs']
    const firebaseListeners = firebaseDb[componentName]['firebaseListeners']

    for (const bindKey in firebaseRefs) {
      if (Object.prototype.hasOwnProperty.call(firebaseRefs, bindKey)) {
        _unbindForKey(bindKey, firebaseRefs, firebaseListeners)
      }
    }
  }

  const unbindFirebaseEventHandlerForChildAdded = function (
    componentName,
    ref
  ) {
    _unbindFirebaseEventHandlerForEvent(componentName, ref, 'child_added')
  }

  const unbindFirebaseEventHandlerForChildRemoved = function (
    componentName,
    ref
  ) {
    _unbindFirebaseEventHandlerForEvent(componentName, ref, 'child_removed')
  }

  const unbindFirebaseEventHandlerForValueChanged = function (
    componentName,
    ref
  ) {
    _unbindFirebaseEventHandlerForEvent(componentName, ref, 'value')
  }

  const _unbindFirebaseEventHandlerForEvent = function (
    componentName,
    ref,
    event
  ) {
    try {
      if (
        !firebaseDb[componentName] ||
        !firebaseDb[componentName]['firebaseRefs'] ||
        !firebaseDb[componentName]['firebaseListeners']
      ) {
        console.tron.log(
          'No firebase event handlers have been defined for component ' +
            componentName
        )
        return
      }
      const bindKey = ref.toString()
      const firebaseRefs = firebaseDb[componentName]['firebaseRefs']
      const firebaseListeners = firebaseDb[componentName]['firebaseListeners']

      if (
        !firebaseRefs[bindKey] ||
        !firebaseListeners[bindKey] ||
        !firebaseListeners[bindKey][event]
      ) {
        console.tron.log(
          'No matching firebase event handlers found for ' +
            componentName +
            '-' +
            bindKey +
            '-' +
            event
        )
        return
      }

      const offListener = firebaseListeners[bindKey][event]
      firebaseRefs[bindKey].off(event, offListener)
      delete firebaseListeners[bindKey][event]
    } catch (error) {
      LogUtils.logError(error, 'Error while unbinding firebase event handler', {
        componentName,
        ref: ref.toString(),
        event
      })
    }
  }

  const _unbindForKey = function (bindKey, firebaseRefs, firebaseListeners) {
    try {
      for (const event in firebaseListeners[bindKey]) {
        if (
          Object.prototype.hasOwnProperty.call(
            firebaseListeners[bindKey],
            event
          )
        ) {
          const offListener = firebaseListeners[bindKey][event]
          firebaseRefs[bindKey].off(event, offListener)
        }
      }
      delete firebaseRefs[bindKey]
      delete firebaseListeners[bindKey]
    } catch (error) {
      LogUtils.logError(error, 'Error while unbinding firebase event handlers')
    }
  }

  return {
    addFirebaseRefChildAdded,
    addFirebaseRefValueChanged,
    unbindFirebaseEventHandlers,
    addFirebaseRefChildRemoved,
    addFirebaseRefChildChanged,
    addFirebaseRefChildMoved,
    unbindFirebaseEventHandlerForChildAdded,
    unbindFirebaseEventHandlerForChildRemoved,
    unbindFirebaseEventHandlerForValueChanged,
    addFirebaseRefValueChangedOnly,
    addFirebaseRefChildAddedIfNotPresent
  }
})()

export default FirebaseManager
