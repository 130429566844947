import React, { Component } from 'react'
import { Box, Text, CheckBox } from 'grommet'
import { GlobalConfig, I18n } from 'galarm-config'
import { Constants } from 'galarm-config'
import { Utils } from 'galarm-shared'
import groupBy from 'lodash/groupBy'
import objReduce from 'lodash/reduce'
import orderBy from 'lodash/orderBy'
import SectionList from './SectionList'
import ChangeGroupMemberStateHeader from './ChangeGroupMemberStateHeader'

const ChangeMemberState = ({
  id,
  name,
  onMakeActive,
  onMakeInactive,
  state
}) => {
  return (
    <Box direction="row" justify="between" align="center" flex>
      <Text color="textColor" margin="xsmall">
        {name}
      </Text>
      <CheckBox
        checked={state !== Constants.ParticipantStates.INACTIVE}
        id={id}
        toggle
        onClick={
          state !== Constants.ParticipantStates.INACTIVE
            ? onMakeInactive
            : onMakeActive
        }
      />
    </Box>
  )
}

class ChangeMemberStateContainer extends Component {
  onMakeActive = () => {
    const extras = this.props.extras
    const item = this.props.item
    extras.onChangeState(item.id, true)
  }

  onMakeInactive = () => {
    const extras = this.props.extras
    const item = this.props.item
    extras.onChangeState(item.id, false)
  }

  render() {
    const item = this.props.item
    const props = {
      id: item.id,
      name: item.name,
      onMakeActive: this.onMakeActive,
      onMakeInactive: this.onMakeInactive,
      state: item.state
    }

    return <ChangeMemberState {...props} />
  }
}

const ChangeGroupMemberState = ({
  memberToChange,
  members,
  onChangeState,
  groupName,
  onDone
}) => {
  const SectionHeader = props => {
    const { title } = props
    return (
      <Box flex height="20px" background="lightPrimary" align="center">
        <Text>{title}</Text>
      </Box>
    )
  }

  const extras = {
    onChangeState: onChangeState
  }

  return (
    <Box flex direction="row">
      <Box background={'textBackgroundColor'} flex>
        <ChangeGroupMemberStateHeader onSave={onDone} />
        <Box flex width={'500px'} border="right">
          <Box pad="small" align="center">
            <Text color="darkTint">
              {I18n.t('changeGroupMemberStateText', {
                memberName: memberToChange.name,
                groupName,
                count: memberToChange.id === GlobalConfig.uid ? 0 : 1
              })}
            </Text>
          </Box>
          <SectionList
            border="bottom"
            sections={members}
            renderItem={ChangeMemberStateContainer}
            renderSectionHeader={SectionHeader}
            extras={{
              ...extras
            }}
          />
        </Box>
      </Box>
    </Box>
  )
}

class ChangeGroupMemberStateContainer extends Component {
  constructor(props) {
    super(props)
    this.initialMembersConfiguration = []
  }

  state = {
    membersConfiguration: []
  }

  componentDidMount() {
    let promises = []
    this.props.members
      .filter(member => member.id !== this.props.memberToChange.id)
      .forEach(member => {
        const promise = GlobalConfig.rootFirebaseRef
          .child('userInfos')
          .child(member.id)
          .child('groups')
          .child(this.props.groupId)
          .child('members')
          .child(this.props.memberToChange.id)
          .child('state')
          .once('value')
          .then(stateSnapshot => ({
            id: member.id,
            name: member.name,
            state: stateSnapshot.val() || Constants.ParticipantStates.ACTIVE
          }))
        promises.push(promise)
      })
    Promise.all(promises).then(values => {
      this.initialMembersConfiguration = values
      this.setState({ membersConfiguration: values })
    })
  }

  onDone = () => {
    let updatedMembersConfiguration = []
    this.state.membersConfiguration.forEach(memberConfiguration => {
      const initialMemberConfigration = Utils.getObjectWithId(
        this.initialMembersConfiguration,
        memberConfiguration.id
      )
      if (initialMemberConfigration.state !== memberConfiguration.state) {
        updatedMembersConfiguration.push(memberConfiguration)
      }
    })

    if (updatedMembersConfiguration.length > 0) {
      this.props.updateMemberState(
        this.props.memberToChange.id,
        updatedMembersConfiguration
      )
    }
    this.props.onClose()
  }

  onChangeState = (memberId, makeActive) => {
    let changedMember = Utils.getObjectWithId(
      this.state.membersConfiguration,
      memberId
    )

    if (
      changedMember === undefined ||
      (makeActive &&
        changedMember.state !== Constants.ParticipantStates.INACTIVE) ||
      (!makeActive &&
        changedMember.state === Constants.ParticipantStates.INACTIVE)
    ) {
      console.log('Member undefined or illegal change state operation')
      return
    }

    let newMembers = null
    if (makeActive) {
      newMembers = this.state.membersConfiguration.map(member => {
        if (member.id === changedMember.id) {
          return {
            ...member,
            state: Constants.ParticipantStates.ACTIVE
          }
        }
        return member
      })
    } else {
      newMembers = this.state.membersConfiguration.map(member => {
        if (member.id === changedMember.id) {
          return {
            ...member,
            state: Constants.ParticipantStates.INACTIVE
          }
        }
        return member
      })
    }
    this.setState({
      membersConfiguration: newMembers
    })
  }

  render() {
    const members = this.state.membersConfiguration.slice()

    const membersSections = objReduce(
      groupBy(members, member => member.state),
      (acc, next, index) => {
        acc.push({
          title:
            index !== Constants.ParticipantStates.INACTIVE
              ? I18n.t('allowed')
              : I18n.t('notAllowed'),
          data: next
        })
        return acc
      },
      []
    )

    const orderedMemberSections = orderBy(membersSections, ['title'], ['asc'])

    const props = {
      members: orderedMemberSections,
      memberToChange: this.props.memberToChange,
      onDone: this.onDone,
      onChangeState: this.onChangeState,
      groupName: this.props.groupName
    }

    return <ChangeGroupMemberState {...props} />
  }
}

export default ChangeGroupMemberStateContainer
