import React from 'react'
import { Box, Button, Header, Text } from 'grommet'
import { Close } from 'grommet-icons'
import { I18n } from 'galarm-config'
import { useDispatch } from 'react-redux'
import { ActionCreators } from 'galarm-shared'

const EditGroupHeader = ({ groupData, onSave }) => {
  const dispatch = useDispatch()
  return (
    <Header
      className="header"
      background="brand"
      pad="small"
      direction="row"
      justify="between">
      <Text color="white" level={5}>
        {groupData.isEdit ? I18n.t('editGroup') : I18n.t('newGroup')}
      </Text>
      <Box direction="row" align="center" gap="small">
        <Button
          plain
          color="white"
          label={I18n.t('saveGroup')}
          onClick={onSave}
        />
        <Button
          icon={<Close />}
          onClick={() => dispatch(ActionCreators.hideNewGroupWizard())}
        />
      </Box>
    </Header>
  )
}

export default EditGroupHeader
