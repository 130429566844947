import React, { useRef, useEffect, useState } from 'react'
import { Box, Text } from 'grommet'
import { GlobalConfig } from 'galarm-config'

// Context Menu Options will have the names of menu items and the handlers for the menu items
const RightClickComponent = ({ children, contextMenuOptions }) => {
  const [anchorPoint, setAnchorPoint] = useState({ x: 0, y: 0 })
  const [show, setShow] = useState(false)

  // The children will be placed under the wrapped ref so that they we can show
  // the right click custom menu
  const wrappedComponentRef = useRef(null)

  const handleContextMenu = event => {
    event.preventDefault()
    GlobalConfig.eventEmitter.emit('closeRightClickMenu', {})
    setTimeout(() => {
      setAnchorPoint({ x: event.pageX - 10, y: event.pageY - 10 })
      setShow(true)
    }, 100)
  }

  const onCloseRightClickMenu = () => {
    setShow(false)
  }

  useEffect(() => {
    GlobalConfig.eventEmitter.on('closeRightClickMenu', onCloseRightClickMenu)

    const wrappedComponent = wrappedComponentRef.current
    if (wrappedComponent) {
      wrappedComponent.addEventListener('contextmenu', handleContextMenu)
    }

    return () => {
      GlobalConfig.eventEmitter.off(
        'closeRightClickMenu',
        onCloseRightClickMenu
      )

      if (wrappedComponent) {
        wrappedComponent.removeEventListener('contextmenu', handleContextMenu)
      }
    }
  }, [])

  return (
    <Box>
      <Box ref={wrappedComponentRef}>{children}</Box>
      {show && (
        <Box
          style={{
            position: 'absolute',
            top: anchorPoint.y,
            left: anchorPoint.x
          }}
          margin="medium"
          height="auto"
          gap="small"
          background="lightTint"
          elevation="small"
          border="between">
          {contextMenuOptions.items.map((menuOption, index) => (
            <Box
              background="lightTint"
              pad="small"
              key={index}
              onClick={contextMenuOptions.handlers[menuOption]}>
              <Text style={{ textAlign: 'left' }}>{menuOption}</Text>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  )
}

export default RightClickComponent
