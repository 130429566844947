import ActionTypes from '../actions/types'
import { createReducer } from 'reduxsauce'
import Utils from '../utils/Utils'

const INITIAL_STATE = {
  groups: []
}

const createGroup = (groupData, isRemovedGroup) => {
  if (groupData.id === undefined || groupData.name === undefined) {
    return undefined
  }

  let members = []
  if (!isRemovedGroup) {
    const membersData = groupData.members || {}
    Object.keys(membersData).forEach(memberId => {
      const memberData = membersData[memberId]
      if (memberData.id && memberData.name) {
        members.push({
          ...memberData
        })
      }
    })
  }

  const newGroup = {
    id: groupData.id,
    name: groupData.name,
    creator: groupData.creator,
    state: groupData.state,
    members,
    lastUpdatedAt: groupData.lastUpdatedAt,
    isRemovedGroup
  }

  return newGroup
}

const updateGroup = (state, action) => {
  const { groupId, groupData, isRemovedGroup } = action.payload

  let group = createGroup(groupData, isRemovedGroup)

  // If group doesn't have required data, then skip
  if (group === undefined) {
    return state
  }

  let newGroups = state.groups.slice()
  let groupIndex = Utils.getIndexOfObjectWithId(state.groups, groupId)

  // New group
  if (groupIndex === -1) {
    newGroups.push(group)
  } else {
    // Changed group, Merge the previous and new so that avatar is not lost
    const existingGroup = state.groups[groupIndex]
    newGroups.splice(groupIndex, 1, { ...existingGroup, ...group })
  }

  return {
    ...state,
    groups: newGroups
  }
}

const updateGroupAvatarImages = (state, action) => {
  let groupId = action.payload.groupId
  let images = action.payload.images
  const groupIndex = Utils.getIndexOfObjectWithId(state.groups, groupId)

  if (groupIndex !== -1) {
    let newGroups = state.groups.slice()
    const group = state.groups[groupIndex]
    newGroups.splice(groupIndex, 1, {
      ...group,
      images: images
    })
    return {
      ...state,
      groups: newGroups
    }
  }

  return state
}

const deleteGroupAvatarImages = (state, action) => {
  let groupId = action.payload.groupId
  let images = {}

  const groupIndex = Utils.getIndexOfObjectWithId(state.groups, groupId)

  if (groupIndex !== -1) {
    let newGroups = state.groups.slice()
    const group = state.groups[groupIndex]
    newGroups.splice(groupIndex, 1, {
      ...group,
      images: images
    })
    return {
      ...state,
      groups: newGroups
    }
  }

  return state
}

const removeGroup = (state, action) => {
  const { groupId } = action.payload

  let groupIndex = Utils.getIndexOfObjectWithId(state.groups, groupId)

  if (groupIndex !== -1) {
    let newGroups = state.groups.slice()
    newGroups.splice(groupIndex, 1)
    return {
      ...state,
      groups: newGroups
    }
  }

  console.tron.log("Didn't find the group with id while removing " + groupId)
  return state
}

const ACTION_HANDLERS = {
  [ActionTypes.UPDATE_GROUP]: updateGroup,
  [ActionTypes.UPDATE_GROUP_AVATAR_IMAGES]: updateGroupAvatarImages,
  [ActionTypes.DELETE_GROUP_AVATAR_IMAGES]: deleteGroupAvatarImages,
  [ActionTypes.REMOVE_GROUP]: removeGroup
}

export default createReducer(INITIAL_STATE, ACTION_HANDLERS)
