import firebase from 'firebase/compat/app'
import CommonGlobalConfig from './CommonGlobalConfig'
import EventEmitter from 'events'
import { getFirestore } from 'firebase/firestore'

const GlobalConfig = (function () {
  let firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    projectId: process.env.REACT_APP_PROJECT_ID,
    appId: process.env.REACT_APP_APP_ID
  }

  console.tron.log('Firebase Config', firebaseConfig)

  // Initialize Firebase
  const firebaseApp = firebase.initializeApp(firebaseConfig)

  const ref = firebase.database().ref()

  const firestoreDb = getFirestore(firebaseApp)

  // Use the firebase functions running locally during development
  // Commnent if you want to run the hosted version
  __DEV__ && firebase.functions().useEmulator('localhost', 5001)

  const eventEmitter = new EventEmitter()

  // To create a test release, set test to !__DEV__ and testUser to 'vg'
  return {
    ...CommonGlobalConfig,
    maxParticipantsToShowInAlarmSummary: 8,
    rootFirebaseRef: ref,
    dynamicLinkDomain: process.env.REACT_APP_DYNAMIC_LINK_DOMAIN,
    eventEmitter: eventEmitter,
    reducerVersion: '1.0',
    firebaseApiKey: firebaseConfig.apiKey,
    eventsThresholdForCalendarView: {
      month: 3,
      week: 8,
      day: 8
    },
    firestoreDb,
    webColorScheme: CommonGlobalConfig.defaultColorScheme
  }
})()

export default GlobalConfig
