import React from 'react'
import { Avatar } from 'grommet'
import AvatarWithInitials from './AvatarWithInitials'

const ImageAvatar = ({ url, name, size = 'medium' }) => {
  if (url) {
    return <Avatar src={url} size={size} />
  }

  return <AvatarWithInitials name={name} size={size} />
}

export default ImageAvatar
