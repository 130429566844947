import { Constants } from 'galarm-config'
import { Image } from 'grommet'
import React from 'react'
import { useSelector } from 'react-redux'
import { ThemeContext } from 'styled-components'

const ColorFilters = {
  white:
    'invert(100%) sepia(100%) saturate(0%) hue-rotate(201deg) brightness(102%) contrast(103%)',
  black:
    'invert(0%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(96%) contrast(101%)',
  green:
    'invert(27%) sepia(43%) saturate(5114%) hue-rotate(161deg) brightness(94%) contrast(103%)',
  red: 'invert(31%) sepia(83%) saturate(4350%) hue-rotate(345deg) brightness(96%) contrast(87%)',
  yellow:
    'invert(96%) sepia(98%) saturate(4757%) hue-rotate(326deg) brightness(103%) contrast(98%)',
  orange:
    'invert(56%) sepia(59%) saturate(1405%) hue-rotate(360deg) brightness(102%) contrast(105%)',
  blue: 'invert(67%) sepia(67%) saturate(6443%) hue-rotate(172deg) brightness(97%) contrast(98%)',
  grey: 'invert(83%) sepia(12%) saturate(64%) hue-rotate(191deg) brightness(92%) contrast(88%)',
  lightBlack:
    'invert(0%) sepia(38%) saturate(5%) hue-rotate(318deg) brightness(102%) contrast(74%)',
  lightGrey:
    'invert(100%) sepia(99%) saturate(187%) hue-rotate(219deg) brightness(112%) contrast(94%)',
  darkGrey:
    'invert(62%) sepia(5%) saturate(210%) hue-rotate(201deg) brightness(89%) contrast(94%)',
  veryDarkGrey:
    'invert(21%) sepia(0%) saturate(7%) hue-rotate(240deg) brightness(100%) contrast(85%)',
  darkBlue:
    'invert(27%) sepia(82%) saturate(1695%) hue-rotate(182deg) brightness(94%) contrast(101%)'
}

const TintedImage = ({ src, tintColor, ...restProps }) => {
  let colorScheme = useSelector(state => state.userSettings.webColorScheme)
  if (colorScheme === Constants.COLOR_SCHEMES.AUTO_DARK) {
    colorScheme = Constants.COLOR_SCHEMES.DARK
  } else if (colorScheme === Constants.COLOR_SCHEMES.AUTO_LIGHT) {
    colorScheme = Constants.COLOR_SCHEMES.LIGHT
  }

  let filter

  switch (tintColor) {
    case 'textColor':
      filter =
        colorScheme === Constants.COLOR_SCHEMES.LIGHT
          ? ColorFilters.lightBlack
          : ColorFilters.lightGrey
      break
    case 'darkTint':
      filter =
        colorScheme === Constants.COLOR_SCHEMES.LIGHT
          ? ColorFilters.darkGrey
          : ColorFilters.grey
      break
    case 'mediumTint':
      filter =
        colorScheme === Constants.COLOR_SCHEMES.LIGHT
          ? ColorFilters.grey
          : ColorFilters.grey
      break
    case 'lightTint':
      filter =
        colorScheme === Constants.COLOR_SCHEMES.LIGHT
          ? ColorFilters.lightGrey
          : ColorFilters.veryDarkGrey
      break
    case 'white':
      filter = ColorFilters.white
      break
    case 'primary':
      filter = ColorFilters.blue
      break
    case 'darkPrimary':
      filter = ColorFilters.darkBlue
      break
    case 'green':
      filter = ColorFilters.green
      break
    case 'red':
      filter = ColorFilters.red
      break
    case 'orange':
      filter = ColorFilters.orange
      break
    default:
      filter = ''
  }

  return (
    <ThemeContext.Extend
      value={{
        image: {
          extend: {
            filter: filter
          }
        }
      }}>
      <Image src={src} {...restProps} />
    </ThemeContext.Extend>
  )
}

export default TintedImage
