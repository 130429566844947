import { Text } from 'grommet'
import React from 'react'

const LabelText = ({ children, ...otherProps }) => {
  return (
    <Text size="small" margin={{ bottom: '5px' }} {...otherProps}>
      {children}
    </Text>
  )
}

export default LabelText
