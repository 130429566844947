import { ActionCreators, AlarmUtils, DateTimeUtils } from 'galarm-shared'
import { FirebaseProxy, NavigationUtils } from 'galarm-ps-api'
import { Constants, GlobalConfig, I18n } from 'galarm-config'
import * as RNLocalize from 'react-native-localize'

const AlarmUtilsWithExtras = (function () {
  const setBackupResponseStatusForAlarm = function (
    alarm,
    previousResponseStatus,
    responseStatus,
    backupOrder,
    persistOnFirebase
  ) {
    const backupAlarmDate = DateTimeUtils.getParticipantAlarmDate(
      alarm,
      backupOrder
    )
    if (Date.now() > backupAlarmDate && alarm.repeatType === '') {
      NavigationUtils.showTransientAlert({
        message: I18n.t('noNotificationsScheduledAlarmInPast'),
        duration: Constants.AlertDurations.SHORT
      })
    }
    return AlarmUtils.setBackupResponseStatusForAlarmCore(
      alarm,
      previousResponseStatus,
      responseStatus,
      backupOrder,
      persistOnFirebase
    )
  }

  const setRecipientResponseStatusForAlarm = function (
    alarm,
    responseStatus,
    persistOnFirebase
  ) {
    const recipientAlarmDate = DateTimeUtils.getParticipantAlarmDate(
      alarm,
      alarm.order
    )
    if (Date.now() > recipientAlarmDate && alarm.repeatType === '') {
      NavigationUtils.showTransientAlert({
        message: I18n.t('noNotificationsScheduledAlarmInPast'),
        duration: Constants.AlertDurations.SHORT
      })
    }
    return AlarmUtils.setRecipientResponseStatusForAlarmCore(
      alarm,
      responseStatus,
      persistOnFirebase
    )
  }

  const getQuickActionsForAlarmOccurrence = (alarm, occurrence, dispatch) => {
    if (!GlobalConfig.store) {
      NavigationUtils.showTransientAlert({
        message: I18n.t('unableToShowQuickActions')
      })
      return []
    }

    let quickActions = []

    const markPersonalAlarmDoneQuickAction = {
      text: I18n.t('markAlarmDone'),
      onPress: () => {
        dispatch(
          ActionCreators.setPastAlarmOccurrenceResponse(
            alarm,
            occurrence.occurrenceTime,
            Date.now(),
            Constants.PERSONAL_ALARM_DONE
          )
        )
        FirebaseProxy.logEvent(
          Constants.UserAnalyticsEvents.SET_ALARM_PREVIOUS_OCCURRENCE_RESPONSE,
          {
            [Constants.UserAnalyticsEventParameters
              .ALARM_PREVIOUS_OCCURRENCE_RESPONSE]:
              Constants.PERSONAL_ALARM_DONE
          }
        )
      }
    }

    const skipPersonalAlarmQuickAction = {
      text: I18n.t('skipAlarm'),
      onPress: () => {
        dispatch(
          ActionCreators.setPastAlarmOccurrenceResponse(
            alarm,
            occurrence.occurrenceTime,
            Date.now(),
            Constants.PERSONAL_ALARM_SKIP
          )
        )
        FirebaseProxy.logEvent(
          Constants.UserAnalyticsEvents.SET_ALARM_PREVIOUS_OCCURRENCE_RESPONSE,
          {
            [Constants.UserAnalyticsEventParameters
              .ALARM_PREVIOUS_OCCURRENCE_RESPONSE]:
              Constants.PERSONAL_ALARM_SKIP
          }
        )
      }
    }

    const confirmGroupAlarmQuickAction = {
      text: I18n.t('confirmGroupAlarm'),
      onPress: () => {
        dispatch(
          ActionCreators.setPastAlarmOccurrenceResponse(
            alarm,
            occurrence.occurrenceTime,
            Date.now(),
            Constants.GROUP_ALARM_YES
          )
        )
        FirebaseProxy.logEvent(
          Constants.UserAnalyticsEvents.SET_ALARM_PREVIOUS_OCCURRENCE_RESPONSE,
          {
            [Constants.UserAnalyticsEventParameters
              .ALARM_PREVIOUS_OCCURRENCE_RESPONSE]: Constants.GROUP_ALARM_YES
          }
        )
      }
    }

    const declineGroupAlarmQuickAction = {
      text: I18n.t('declineGroupAlarm'),
      onPress: () => {
        dispatch(
          ActionCreators.setPastAlarmOccurrenceResponse(
            alarm,
            occurrence.occurrenceTime,
            Date.now(),
            Constants.GROUP_ALARM_NO
          )
        )
        FirebaseProxy.logEvent(
          Constants.UserAnalyticsEvents.SET_ALARM_PREVIOUS_OCCURRENCE_RESPONSE,
          {
            [Constants.UserAnalyticsEventParameters
              .ALARM_PREVIOUS_OCCURRENCE_RESPONSE]: Constants.GROUP_ALARM_NO
          }
        )
      }
    }

    const occurrenceStatus = occurrence.occurrenceStatus
    switch (alarm.type) {
      case Constants.AlarmTypes.CASCADING:
        if (typeof occurrenceStatus === 'boolean') {
          quickActions.push(markPersonalAlarmDoneQuickAction)
          quickActions.push(skipPersonalAlarmQuickAction)
        } else {
          const response = occurrenceStatus.response
          if (response === Constants.PERSONAL_ALARM_DONE) {
            quickActions.push(skipPersonalAlarmQuickAction)
          } else if (response === Constants.PERSONAL_ALARM_SKIP) {
            quickActions.push(markPersonalAlarmDoneQuickAction)
          } else if (response === Constants.PERSONAL_ALARM_UNDONE) {
            quickActions.push(markPersonalAlarmDoneQuickAction)
            quickActions.push(skipPersonalAlarmQuickAction)
          }
        }
        break
      case Constants.AlarmTypes.SIMULTANEOUS:
        if (typeof occurrenceStatus === 'boolean') {
          quickActions.push(confirmGroupAlarmQuickAction)
          quickActions.push(declineGroupAlarmQuickAction)
        } else {
          const response = occurrenceStatus.response
          if (response === Constants.GROUP_ALARM_YES) {
            quickActions.push(declineGroupAlarmQuickAction)
          } else if (response === Constants.GROUP_ALARM_NO) {
            quickActions.push(confirmGroupAlarmQuickAction)
          }
        }
        break
    }

    return quickActions
  }

  const getQuickActionsForParticipantAlarmOccurrence = (
    alarm,
    occurrence,
    dispatch
  ) => {
    if (!GlobalConfig.store) {
      return []
    }

    let quickActions = []

    const markRecipientAlarmDoneQuickAction = {
      text: I18n.t('markAlarmDone'),
      onPress: () => {
        dispatch(
          ActionCreators.setPastAlarmOccurrenceResponse(
            alarm,
            occurrence.occurrenceTime,
            Date.now(),
            Constants.RECIPIENT_ALARM_DONE
          )
        )
        FirebaseProxy.logEvent(
          Constants.UserAnalyticsEvents.SET_ALARM_PREVIOUS_OCCURRENCE_RESPONSE,
          {
            [Constants.UserAnalyticsEventParameters
              .ALARM_PREVIOUS_OCCURRENCE_RESPONSE]:
              Constants.RECIPIENT_ALARM_DONE
          }
        )
      }
    }

    const skipRecipientAlarmQuickAction = {
      text: I18n.t('skipAlarm'),
      onPress: () => {
        dispatch(
          ActionCreators.setPastAlarmOccurrenceResponse(
            alarm,
            occurrence.occurrenceTime,
            Date.now(),
            Constants.RECIPIENT_ALARM_SKIP
          )
        )
        FirebaseProxy.logEvent(
          Constants.UserAnalyticsEvents.SET_ALARM_PREVIOUS_OCCURRENCE_RESPONSE,
          {
            [Constants.UserAnalyticsEventParameters
              .ALARM_PREVIOUS_OCCURRENCE_RESPONSE]:
              Constants.RECIPIENT_ALARM_SKIP
          }
        )
      }
    }

    const confirmGroupAlarmQuickAction = {
      text: I18n.t('confirmGroupAlarm'),
      onPress: () => {
        dispatch(
          ActionCreators.setPastAlarmOccurrenceResponse(
            alarm,
            occurrence.occurrenceTime,
            Date.now(),
            Constants.GROUP_ALARM_YES
          )
        )
        FirebaseProxy.logEvent(
          Constants.UserAnalyticsEvents.SET_ALARM_PREVIOUS_OCCURRENCE_RESPONSE,
          {
            [Constants.UserAnalyticsEventParameters
              .ALARM_PREVIOUS_OCCURRENCE_RESPONSE]: Constants.GROUP_ALARM_YES
          }
        )
      }
    }

    const declineGroupAlarmQuickAction = {
      text: I18n.t('declineGroupAlarm'),
      onPress: () => {
        dispatch(
          ActionCreators.setPastAlarmOccurrenceResponse(
            alarm,
            occurrence.occurrenceTime,
            Date.now(),
            Constants.GROUP_ALARM_NO
          )
        )
        FirebaseProxy.logEvent(
          Constants.UserAnalyticsEvents.SET_ALARM_PREVIOUS_OCCURRENCE_RESPONSE,
          {
            [Constants.UserAnalyticsEventParameters
              .ALARM_PREVIOUS_OCCURRENCE_RESPONSE]: Constants.GROUP_ALARM_NO
          }
        )
      }
    }

    const occurrenceStatus = occurrence.occurrenceStatus
    switch (alarm.type) {
      case Constants.AlarmTypes.SIMULTANEOUS:
        if (typeof occurrenceStatus === 'boolean') {
          quickActions.push(confirmGroupAlarmQuickAction)
          quickActions.push(declineGroupAlarmQuickAction)
        } else {
          const response = occurrenceStatus.response
          if (response === Constants.GROUP_ALARM_YES) {
            quickActions.push(declineGroupAlarmQuickAction)
          } else if (response === Constants.GROUP_ALARM_NO) {
            quickActions.push(confirmGroupAlarmQuickAction)
          }
        }
        break
      case Constants.AlarmTypes.RECIPIENT:
        if (typeof occurrenceStatus === 'boolean') {
          quickActions.push(markRecipientAlarmDoneQuickAction)
          quickActions.push(skipRecipientAlarmQuickAction)
        } else {
          const response = occurrenceStatus.response
          if (response === Constants.RECIPIENT_ALARM_DONE) {
            quickActions.push(skipRecipientAlarmQuickAction)
          } else if (response === Constants.RECIPIENT_ALARM_SKIP) {
            quickActions.push(markRecipientAlarmDoneQuickAction)
          } else if (response === Constants.RECIPIENT_ALARM_UNDONE) {
            quickActions.push(markRecipientAlarmDoneQuickAction)
            quickActions.push(skipRecipientAlarmQuickAction)
          }
        }
        break
    }

    return quickActions
  }

  const canCreateNewAlarmAndShowAlertsIfNeeded = data => {
    let message
    let title
    let actionButtons = []
    let showAlert = false

    if (data.numAlarms > GlobalConfig.maxAllowedAlarms) {
      if (data.disabledAlarmsCount) {
        actionButtons.push({
          text: I18n.t('deletePastAlarms'),
          onPress: GlobalConfig.showDeleteInactiveAlarmsDialog
        })
      }

      title = I18n.t('alarmLimitReached')
      message = I18n.t('alarmOverAllowedLimit', {
        limit: GlobalConfig.maxAllowedAlarms,
        count: data.disabledAlarmsCount
      })
      showAlert = true
    }

    if (showAlert) {
      actionButtons.push({
        text: I18n.t('ok')
      })

      NavigationUtils.showAlert(title, message, actionButtons, true)

      FirebaseProxy.logEvent(Constants.UserAnalyticsEvents.ALARMS_LIMIT_HIT, {
        [Constants.UserAnalyticsEventParameters.ALARMS_LIMIT]:
          data.maxAllowedAlarms,
        [Constants.UserAnalyticsEventParameters.INACTIVE_ALARMS_COUNT]:
          data.disabledAlarmsCount
      })

      return false
    }

    return true
  }

  const getTimezone = () => {
    return Promise.resolve(RNLocalize.getTimeZone())
  }

  return {
    setBackupResponseStatusForAlarm,
    setRecipientResponseStatusForAlarm,
    getQuickActionsForAlarmOccurrence,
    getQuickActionsForParticipantAlarmOccurrence,
    canCreateNewAlarmAndShowAlertsIfNeeded,
    getTimezone
  }
})()
export default AlarmUtilsWithExtras
