import React from 'react'
import { Text, Box } from 'grommet'

import moment from 'moment'

const Time = props => {
  return (
    <Box>
      <Text style={props.timeTextStyle[props.position]}>
        {moment(props.currentMessage.createdAt).format('LT')}
      </Text>
    </Box>
  )
}
export default Time

Time.defaultProps = {
  position: 'left',
  currentMessage: {
    createdAt: null
  },
  timeContainerStyle: {},
  timeTextStyle: {}
}
