import { GlobalConfig } from 'galarm-config'
import { connect } from 'react-redux'
import {
  ActionCreators,
  AlarmUtils,
  Selectors,
  AbstractEditAlarmContainer
} from 'galarm-shared'
import { Constants, I18n } from 'galarm-config'
import moment from 'moment-timezone'
import { FirebaseProxy } from 'galarm-ps-api'
import * as RNLocalize from 'react-native-localize'

const {
  upgradePurchasedSelector,
  numGroupAlarmsSelector,
  numBuddyAlarmsSelector,
  enabledAlarmCategoriesSelector,
  requiredPermissionsEnabledSelector
} = Selectors

class NewInstantAlarmContainer extends AbstractEditAlarmContainer {
  componentDidMount = () => {
    FirebaseProxy.logEvent(Constants.UserAnalyticsEvents.CHOOSE_ALARM_TYPE, {
      [Constants.UserAnalyticsEventParameters.ALARM_TYPE]: this.props.alarmType
    })
  }

  getDateForAlarm = () => {
    return moment().seconds(0).milliseconds(0).valueOf()
  }

  getTitleForAlarm = () => {
    if (this.props.selectedGroup) {
      return I18n.t('defaultInstantAlarmTitleForGroup', {
        groupName: this.props.selectedGroup.name,
        creatorName: this.props.creatorName
      })
    } else {
      return I18n.t('defaultInstantAlarmTitleForContact', {
        creatorName: this.props.creatorName
      })
    }
  }

  getInitialBackupContacts = () => {
    return this.props.selectedContacts || []
  }

  getInitialBackupGroup = () => {
    return this.props.selectedGroup || null
  }

  isAlarmInPast = () => {
    return false
  }

  state = {
    id: GlobalConfig.rootFirebaseRef.push().key,
    name: this.getTitleForAlarm(),
    date: this.getDateForAlarm(),
    backupContacts: this.getInitialBackupContacts(),
    backupGroup: this.getInitialBackupGroup(),
    notes: '',
    alarmPhotoUrl: '',
    repeat: '',
    repeatType: '',
    creatorTimezone: RNLocalize.getTimeZone(),
    timezoneSetting: Constants.TIMEZONE_SETTINGS.DEVICE,
    type: Constants.AlarmTypes.SIMULTANEOUS,
    isEdit: false,
    cascadingAlarmInterval: null,
    recipientAlarmInterval: null,
    recipient: null,
    ringerSettings: AlarmUtils.getDefaultRingerSettings(),
    creationMode: Constants.AlarmCreationModes.INSTANT_ALARM,
    endDate: GlobalConfig.defaultAlarmEndDate,
    alarmCategoryId: Constants.UNCATEGORIZED_ALARM_CATEGORY_ID,
    source: Constants.AlarmCreationSources.CREATED_MANUALLY_FROM_WEB,
    preReminderDuration: GlobalConfig.defaultPreReminderDuration
  }

  onSaveAlarmCore = () => {
    const alarm = this.createAlarm(this.state.id)
    // If the user is on the screen for a long time, we want the date to be the recent date
    if (alarm.date < Date.now()) {
      alarm.date = this.getDateForAlarm()
    }
    this.props.dispatch(ActionCreators.addAlarm(alarm))

    if (
      this.state.alarmCategoryId &&
      this.state.alarmCategoryId !== Constants.UNCATEGORIZED_ALARM_CATEGORY_ID
    ) {
      this.props.dispatch(
        ActionCreators.addAlarmToAlarmCategory(
          this.state.alarmCategoryId,
          this.state.id
        )
      )
      FirebaseProxy.logEvent(
        Constants.UserAnalyticsEvents.SET_ALARM_CATEGORY_FROM_NEW_ALARM_SCREEN,
        {}
      )
    }
    this.props.onClose()

    FirebaseProxy.logEvent(Constants.UserAnalyticsEvents.SAVE_NEW_ALARM, {})
  }
}

const mapStateToProps = state => ({
  creatorName: state.userInfo.name,
  creatorMobileNumber: state.userInfo.formattedMobileNumberWithCC,
  requiredPermissionsEnabled: requiredPermissionsEnabledSelector(state),
  isConnected: state.appState.isConnected,
  isAuthenticated: state.appState.authenticatedWithFirebase,
  rateTheAppInfo: state.userInfo.rateTheAppInfo,
  mobileNumber: state.userInfo.mobileNumber,
  upgradePurchased: upgradePurchasedSelector(state),
  numGroupAlarms: numGroupAlarmsSelector(state),
  numBuddyAlarms: numBuddyAlarmsSelector(state),
  colorScheme: state.userSettings.webColorScheme,
  alarmCategories: enabledAlarmCategoriesSelector(state)
})

export default connect(mapStateToProps)(NewInstantAlarmContainer)
