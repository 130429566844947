module.exports = {
  alarms: 'Alarmes',
  contacts: 'Contatos',
  groups: 'Grupos',
  settings: 'Configurações',
  notificationsTab: 'Notificações',
  specifyTimeInFutureForAlarm: 'O alarme não pode estar no passado.',
  specifyTimeInFutureForPreReminder:
    'O pré-lembrete para alarme é coisa do passado.',
  specifyNameForGroup: 'O nome do grupo é obrigatório.',
  updatingGroupName: 'Atualização das informações do grupo.',
  unableToEditGroupName:
    'Não foi possível atualizar as informações do grupo. Tente novamente após algum tempo.',
  selectSmallerImage: 'A imagem é maior que 10 MB. Selecione uma imagem menor.',
  sendingVerificationCode:
    'Envio do código de verificação para {{formattedMobileNumber}}.',
  verifyingCode: 'Verificação do código.',
  creatingUserProfile: 'Configurando...',
  unabeToCreateUserProfile:
    'Não é possível configurar uma conta no momento. Tente novamente em alguns instantes.',
  unableToSendVerificationCode:
    'Não foi possível enviar o código de verificação. {{error}}',
  unableToVerifyCode: 'Não foi possível verificar o código. {{error}}',
  unableToRegisterUserWithoutMobileNumber:
    'Não foi possível realizar a configuração. {{error}}',
  permissionDeniedToAccessCameraIos:
    'Galarm não tem permissão para acessar a câmera. \n\nVá para Configurações > Galarm e permita o acesso à câmera.',
  permissionDeniedToAccessCameraAndroid:
    'Galarm não tem permissão para acessar a câmera.\n\nVá para Configurações > Aplicativos > Galarm > Permissões e permita o acesso à câmera.',
  permissionDeniedToAccessPhotosIos:
    'Galarm não tem permissão para acessar fotos.\n\nVá para Configurações > Galarm e permita o acesso às fotos.',
  permissionDeniedToAccessPhotosAndroid:
    'Galarm não tem permissão para acessar fotos.\n\nVá para Configurações > Aplicativos > Galarm > Permissões e permita o acesso ao armazenamento.',
  permissionDeniedToAccessCalendarIos:
    'O Galarm não tem permissão para acessar o calendário.\nVá para Configurações > Calendários e permita o acesso ao Galarm.',
  permissionDeniedToAccessCalendarAndroid:
    'O Galarm não tem permissão para acessar o calendário.\nVá para Configurações > Aplicativos > Galarm > Permissões e permita o acesso ao Calendário.',
  verificationCodeSent:
    'Código de verificação enviado para {{formattedMobileNumber}}.',
  rateTheApp:
    'Esperamos que o Galarm seja útil para você. Reserve um momento para nos avaliar na Play Store. Obrigado por seu apoio.',
  backgroundAppRefreshDisabledPermission:
    'Os alarmes podem não tocar porque o Galarm não tem permissão para executar a atualização do aplicativo em segundo plano.\n\nToque em "Enable" (Ativar) e ative "Background App Refresh" (Atualização de aplicativo em segundo plano).',
  selectedGroupUnselected:
    'Você pode selecionar um grupo ou um conjunto de contatos como participantes. Seu grupo selecionado anteriormente não será mais selecionado. Continuar?',
  selectedContactsUnselected:
    'Você pode selecionar um grupo ou um conjunto de contatos como participantes. Os contatos selecionados anteriormente não serão mais selecionados. Continuar?',
  notificationsDisabledIos:
    'Os alarmes não tocarão porque o Galarm não tem permissão para exibir notificações.\n\nVá para Settings > Galarm > Notifications e ative "Alerts", "Sounds" e "Banners".',
  notificationsDisabledAndroid:
    'As notificações de alarme não serão exibidas na tela.\n\nToque em "Enable" (Ativar), selecione "Notifications" (Notificações) e permita as notificações.',
  alarmNotificationChannelDisabled:
    'As notificações de alarme não aparecerão na tela. \n\nToque em "Enable" (Ativar) e permita que as notificações de alarme apareçam na tela.',
  alarmLockScreenNotificationlDisabled:
    'As notificações de alarme não serão exibidas na tela de bloqueio. \n\nToque em "Enable" (Ativar) e permita que as notificações de alarme sejam exibidas na tela de bloqueio.',
  alarmWillNotRingDueToNotificationsDisabledIos:
    'Os alarmes não tocarão porque as notificações estão desativadas.\n\nVá para Configurações > Galarm > Notificações e ative "Alertas", "Sons" e "Banners".',
  alarmWillNotRingDueToCriticalAlertsDisabledIos:
    'Os alarmes não tocarão quando o telefone estiver mudo porque os alertas críticos estão desativados.\n\nVá para Configurações > Galarm > Notificações e ative "Alertas críticos".',
  alarmWillNotRingDueToNotificationsDisabledAndroid:
    'O alarme não aparecerá na tela porque as notificações estão desativadas.\n\nToque em "Enable" (Ativar), selecione "Notifications" (Notificações) e permita as notificações.',
  noNotificationsScheduledAlarmInPast:
    'O alarme está no passado. Nenhuma notificação está programada.',
  alarmOverAllowedLimit: {
    zero: '\nVocê atingiu seu limite de alarmes de {{limit}} alarmes.\n\nExclua ou reutilize um alarme existente.',
    one: 'Você atingiu seu limite de alarmes de {{limit}} alarmes.\n\nVocê tem 1 alarme inativo que pode ser excluído ou reutilizado.',
    other:
      'Você atingiu seu limite de alarmes de {{limit}} alarmes.\n\nVocê tem {{count}} alarmes inativos que podem ser excluídos ou reutilizados.'
  },
  alarmAlreadyConfirmed: 'Você já confirmou.',
  alarmAlreadyDeclined: 'Você já recusou.',
  participantMovedToPosition: '{{name}} mudou para a posição {{count}}.',
  memberIsFirstParticpant: '{{name}} é o primeiro participante.',
  memberIsLastParticpant: '{{name}} é o último participante.',
  cantContinueWithRegistrationWhenOffline:
    'Não é possível continuar com o registro porque o dispositivo está off-line.',
  cantContinueWithRegistrationWhenOfflineWithTryAgain:
    'Não é possível continuar com o registro porque o dispositivo está off-line. Verifique se você está conectado à Internet.',
  confirmBlockContact:
    'Você não receberá mais alarmes do site {{name}}. Continuar?',
  confirmUnblockContact:
    'O desbloqueio do site {{name}} também restaurará os alarmes bloqueados. Como proceder?',
  unblockingContact: 'Desbloqueio do site {{name}} e restauração de alarmes.',
  unableToUnblockContact: 'Não é possível desbloquear o contato. {{error}}',
  blockingContact: 'Bloqueio {{name}}.',
  unableToBlockContact: 'Não é possível bloquear o contato. {{error}}',
  cantAddContactToAlarmBecauseBlocked:
    'Você bloqueou {{name}}. Desbloqueie-o para adicioná-lo como participante do alarme.',
  cantAddContactToGroupBecauseBlocked:
    'Você bloqueou {{name}}. Desbloqueie-o para adicioná-lo como membro do grupo.',
  sendMessageNotAllowedForPastAlarms:
    'Não é possível enviar mensagens para alarmes inativos.',
  nameIsRequired: 'O nome é obrigatório.',
  confirmDisableGroupAlarm:
    'A desativação do alarme de grupo também desativará o alarme para outros participantes. Continuar?',
  removeMemberFromGroup: 'Deseja remover {{name}} desse grupo?',
  leaveGroupConfirm: 'Você quer sair deste grupo?',
  deviceOffline:
    'O dispositivo está off-line. Verifique se você está conectado à Internet e tente novamente.',
  cantUpdateGroupDefaulConfigWhenOffline:
    'A configuração de grupo modificada não será aplicada a alarmes futuros porque o dispositivo está off-line.',
  specifyFeedback: 'O feedback é necessário.',
  specifyEmailId: 'O endereço de e-mail é obrigatório.',
  specifyValidEmailId: 'O endereço de e-mail não é válido.',
  specifyProblem: 'A descrição do problema é necessária.',
  appInstalledUsingSameNumberOnDifferentPhone:
    'Você instalou o Galarm em um dispositivo diferente usando o mesmo número. O aplicativo nesse dispositivo é redefinido.',
  invalidUid:
    'Essa conta de usuário não existe mais. O aplicativo Galarm neste dispositivo foi redefinido.',
  accountDeleted: 'Sua conta foi excluída.',
  deleteAccount: 'Excluir minha conta',
  deletingAccount: 'Exclusão de sua conta',
  howCanWeImprove: 'Lamentamos sua saída. Como podemos melhorar?',
  noMusicAlert: 'Nenhum arquivo de música foi encontrado.',
  noLongerAvailableMusic:
    '{{fileName}} não está mais disponível. O toque foi alterado para {{defaultRingtoneName}}',
  userInactiveTitle: 'Bem-vindo de volta!',
  userInactiveMessage: 'Crie alarmes para coisas importantes e nunca os perca.',
  createAlarm: 'Criar alarme',
  updatedAppAvailable: 'Uma nova versão do aplicativo está disponível.',
  alarmOccurrenceDisabled: 'Desativado para esta ocorrência',
  ownAlarmOccurrenceDone: {
    zero: 'Marcado feito em {{timeString}}',
    one: 'Marcado como concluído em {{timeString}} em {{dateString}}'
  },
  ownAlarmOccurrenceSkipped: {
    zero: 'Ignorado em {{timeString}}',
    one: 'Ignorado em {{timeString}} em {{dateString}}'
  },
  enabledAlarmsCount: {
    one: '1 alarme ativado.',
    other: '{{count}} alarmes ativados.'
  },
  enabledAndNotEnabledAlarmsCount: {
    one: '1 alarme ativado. Os alarmes sem ocorrências futuras permanecem desativados.',
    other:
      '{{count}} alarmes ativados. Os alarmes sem ocorrências futuras permanecem desativados.'
  },
  disabledAlarmsCount: {
    one: '1 alarme desativado.',
    other: '{{count}} alarmes desativados.'
  },
  ownAlarmOccurrenceUnacknowledged: 'Não respondeu',
  participantAlarmOccurrenceDone: {
    zero: 'Marcação feita por {{participantName}} em {{timeString}}',
    one: 'Marcado como concluído por {{participantName}} em {{timeString}} em {{dateString}}'
  },
  participantAlarmOccurrenceSkipped: {
    zero: 'Ignorado por {{participantName}} em {{timeString}}',
    one: 'Ignorado por {{participantName}} em {{timeString}} em {{dateString}}'
  },
  participantAlarmOccurrenceUnacknowledged: '{{participantName}} não respondeu',
  groupAlarmOccurrenceConfirmed: {
    zero: 'Confirmado em {{timeString}}',
    one: 'Confirmado em {{timeString}} em {{dateString}}'
  },
  groupAlarmOccurrenceDeclined: {
    zero: 'Recusado em {{timeString}}',
    one: 'Recusado em {{timeString}} em {{dateString}}'
  },
  warning: 'Advertência',
  info: 'Informações',
  confirm: 'Confirmar',
  error: 'Erro',
  cantUploadImage: 'Não é possível fazer upload de imagens',
  cantDeleteImage: 'Não é possível excluir a imagem',
  cantEditGroup: 'Não é possível editar o grupo',
  cantAddNewContact: 'Não é possível adicionar um novo contato',
  cantDeleteContact: 'Não é possível excluir um contato',
  cantEditContact: 'Não é possível editar o contato',
  cantSaveAlarm: 'Não é possível salvar o alarme',
  cantSaveGroup: 'Não é possível salvar o grupo',
  cantReloadContacts: 'Não é possível recarregar contatos',
  cantTakePhoto: 'Não é possível tirar fotos',
  cantExportToCalendar: 'Não é possível exportar para o Calendar',
  cantOpenPhotoLibrary: 'Não é possível abrir a biblioteca de fotos',
  cantDeleteAlarmHistory: 'Não é possível excluir o histórico de alarmes',
  cannotDuplicateAlarm: 'Não é possível duplicar o alarme',
  upgradeToPremiumToDuplicateAlarm:
    'Faça upgrade para o Premium para criar mais alarmes de grupo e de amigos.',
  confirmAcceptAlarm: 'Aceitar o alarme?',
  confirmRejectAlarm: 'Alarme de declínio?',
  deleteAlarm: 'Excluir o alarme?',
  restoreAlarmConfirmation: 'Restaurar o alarme?',
  respondToRepeatingAlarm: {
    zero: 'Você foi adicionado como destinatário do alarme {{alarmName}} por {{alarmCreatorName}}. Você será alertado em {{alarmTimeString}} {{alarmRepetitionString}} a partir de {{alarmDateString}}',
    one: 'Você foi adicionado como participante do alarme {{alarmName}} por {{alarmCreatorName}}. Você será alertado em {{alarmTimeString}} {{alarmRepetitionString}} a partir de {{alarmDateString}}'
  },
  respondToOneTimeAlarm: {
    zero: 'Você foi adicionado como destinatário do alarme {{alarmName}} por {{alarmCreatorName}}. Você será alertado em {{alarmTimeString}} {{alarmDateString}}',
    one: 'Você foi adicionado como participante do alarme {{alarmName}} por {{alarmCreatorName}}. Você será alertado em {{alarmTimeString}} {{alarmDateString}}'
  },
  cantMoveMemberUp: "Can't Move {{name}} Up",
  cantMoveMemberDown: "Can't Move {{name}} Down",
  decline: 'Declínio',
  cantEditProfile: 'Não é possível editar o perfil',
  cantSelectContact: 'Não é possível selecionar o contato',
  cantSendMessage: 'Não é possível enviar mensagem',
  cantSendVerificationCode: 'Não é possível enviar o código de verificação',
  confirmBlockContactTitle: 'Confirmar contato de bloqueio',
  confirmUnblockContactTitle: 'Confirmar o desbloqueio do contato',
  cantSaveProfile: 'Não é possível salvar o perfil',
  cantBlockContact: 'Não é possível bloquear o contato',
  cantUnblockContact: 'Não é possível desbloquear o contato',
  cantLoadEarlierMessages: 'Não é possível carregar mensagens anteriores',
  cantRestoreAlarm: 'Não é possível restaurar o alarme',
  cantSendFeedback: 'Não é possível enviar comentários',
  cantSubmitProblem: 'Não é possível enviar o problema',
  cantDeletePastAlarmsInBulk:
    'Não é possível excluir alarmes inativos em massa',
  cantAddNewGroup: 'Não é possível adicionar um novo grupo',
  alreadySnoozing: 'Já está cochilando',
  markAlarmUndone:
    "Você marcou este alarme como 'Concluído' {{durationString}} ago. Desfazer?",
  markAlarmUnskipped:
    'Você ignorou esse alarme {{durationString}} ago. Desfazer?',
  cantUndo: 'Não é possível desfazer',
  fetchMusicFromPhone: 'Encontrar músicas disponíveis.',
  enableAllAlarmsInCategory: 'Ativar todos os alarmes dessa categoria?',
  disableAllAlarmsInCategory: 'Desativar todos os alarmes dessa categoria?',
  allAlarmsAlreadyEnabled:
    'Todos os alarmes dessa categoria já estão ativados.',
  allAlarmsAlreadyDisabled:
    'Todos os alarmes dessa categoria já estão desativados.',
  time: 'Tempo',
  timeForBuddy: 'Tempo para o Buddy',
  timeForYou: 'Lembre-me em',
  name: 'Nome',
  title: 'Título',
  notes: 'Notas',
  repeat: 'Repetir',
  preReminder: 'Pré-lembrete',
  remove: 'Remover',
  preReminderShortAlert: '{{duration}} Pré-lembrete',
  preReminderTitle: '{{alarmName}} em {{date}}',
  preReminderGroupHelperText:
    'Esse pré-lembrete é apenas para você. Outros participantes do alarme podem definir seus próprios pré-lembretes assim que receberem o alarme.',
  addPreReminder: 'Adicionar pré-lembrança',
  helperTextForPreReminder:
    'Toque no botão de reinicialização na parte superior para remover o pré-lembrete.',
  edit: 'Editar',
  delete: 'Excluir',
  creator: 'Criador',
  useAsDefaultGroupConfiguration: 'Aplicar a alarmes futuros',
  done: 'Feito',
  skipped: 'Ignorado',
  save: 'Salvar',
  members: 'Membros',
  mobileNumber: 'Número de telefone',
  enter: 'Entrar',
  addMembers: 'Adicionar membros',
  respond: 'Responder',
  yes: 'Sim',
  no: 'Não',
  cancel: 'Cancelar',
  participants: 'Participantes',
  takePhoto: 'Tirar foto',
  chooseFromLibrary: 'Escolha na biblioteca',
  resendCode: 'Reenviar código',
  register: 'Registro',
  inviteFriends: 'Compartilhe este aplicativo',
  invite: 'Convite',
  incorrectNumber: 'Número incorreto?',
  date: 'Data',
  cascadingAlarmInterval: 'Tempo entre alertas de participantes',
  ringParticipantAlarmsByDefault: 'Toque os alarmes sem aceitar',
  alarmRingtone: 'Toque de alarme',
  pickASong: 'Escolha músicas do celular',
  privacyPolicy: 'Política de privacidade',
  sendFeedback: 'Feedback',
  galarmWeb: 'Galarm Web',
  galarmEnterprise: 'Galarm Pro',
  galarmAdmin: 'Administrador Galarm',
  troubleshooting: 'Solução de problemas',
  alarmDidntRingHelp: 'Ajuda! Meu alarme não tocou',
  autoStartSettingHelperText:
    'Se o seu telefone tiver a configuração de início automático, certifique-se de que o início automático esteja ativado para o Galarm.',
  batteryOptimizationHelperText:
    'Desativar a otimização da bateria para o Galarm',
  troubleshootingHelperText:
    'As configurações a seguir podem estar impedindo que os alarmes toquem como esperado. Revise essas configurações.',
  getMoreHelpText: 'Ainda está tendo problemas?',
  autoStartSetting: 'Início automático',
  batteryOptimization: 'Otimização da bateria',
  battery: 'Bateria',
  rateTheAppSettings: 'Avalie este aplicativo',
  about: 'Sobre e ajuda',
  leaveGroup: 'Grupo de licença',
  removeGroup: 'Remover grupo',
  viewMember: 'Ver {{memberName}}',
  removeMember: 'Remover {{memberName}}',
  changeMemberState: 'Alterar quem pode alertar {{memberName}}',
  changeYourGroupState: 'Alterar quem pode alertá-lo',
  numMembersInGroup: 'Membros: {{count}}',
  notifications: 'As notificações do aplicativo estão desativadas',
  backgroundAppRefresh:
    'A atualização de aplicativos em segundo plano está desativada',
  groupsInCommon: 'Grupos em comum',
  alarmsInCommon: 'Alarmes em comum',
  close: 'Fechar',
  responsePending: 'Sua resposta?',
  later: 'Mais tarde',
  install: 'Instalar',
  all: 'Todos',
  numYears: { one: '1 ano', other: '{{count}} anos' },
  numMonths: { one: '1 mês', other: '{{count}} meses' },
  numDays: { one: '1 dia', other: '{{count}} dias' },
  hours: { one: '1 hora', other: '{{count}} horas' },
  minutes: { one: '1 minuto', other: '{{count}} minutos' },
  seconds: { one: '1 segundo', other: '{{count}} segundos' },
  minutesSmall: '{{count}} min',
  secondsSmall: '{{count}} sec',
  hoursAfter: { one: '1 hora depois', other: '{{count}} horas depois' },
  minutesAfter: { one: '1 minuto depois', other: '{{count}} minutos depois' },
  hoursAfterBuddy: {
    one: '1 hora após o amigo',
    other: '{{count}} horas depois do amigo'
  },
  minutesAfterBuddy: {
    one: '1 minuto após o companheiro',
    other: '{{count}} minutos após o amigo'
  },
  longerThanAnHour: '> 1 hora',
  licenses: 'Licenciamento de terceiros',
  version: 'Versão',
  termsOfService: 'Termos de Serviço',
  showPrivacyPolicy: '> Política de privacidade',
  showTermsOfService: '> Termos de Serviço',
  showLicenses: '> Licenciamento de terceiros',
  change: 'Mudança',
  snooze: 'Snooze',
  ringOnVibrate: 'Toque no silencioso',
  vibrate: 'Vibrar',
  ringOnEarphoneOnly: 'Alarme de toque somente nos fones de ouvido',
  ringOnSpeakerAndEarphone:
    'Alarme de toque no alto-falante e nos fones de ouvido',
  whenEarphoneIsConnected: 'Quando os fones de ouvido estão conectados',
  tapGesture: 'Torneira',
  slideGesture: 'Deslizamento',
  silent: 'Silencioso',
  volume: 'Volume',
  soundAndVibration: 'Som e vibração',
  advancedSettings: 'Configurações avançadas',
  gestureToDismissAnAlarm: 'Gesto para ignorar um alarme',
  someNotificationsAreMuted: 'Algumas notificações são silenciadas',
  allNotificationsEnabled: 'Todas as notificações estão ativadas',
  notificationSettings: 'Configurações de notificação',
  galarmWebNotifications: 'Galarm Web Notifications',
  alarmsfromOthersNotifications: 'Notificações de alarmes para participantes',
  sharedAlarmNotifications: 'Notificações de alarme compartilhadas',
  alarmChatNotifictions: 'Notificações de alarme de bate-papo',
  groupNotifications: 'Notificações de grupo',
  alarmAcknowledgementNotifications: 'Notificações de reconhecimento de alarme',
  send: 'Enviar',
  prev: 'Anterior',
  snoozing: '{{timeString}}',
  specifySnoozeDuartion: 'Especificar a duração da soneca',
  chooseDifferentDuration: 'Escolha uma duração diferente',
  noCalendarsFound: 'Nenhum calendário foi encontrado no dispositivo.',
  eventWillEndAt: 'O evento terminará em {{timeString}}.',
  selectDurationWithinTheSameDay:
    'O evento não pode ultrapassar o limite do dia.',
  cannotMapRepeatTypeInTheDeviceCalendar:
    'Não é possível "Exportar para o calendário" porque a configuração "Repetir" do alarme não é compatível com o calendário.',
  alarmSavedToCalendar: 'O alarme foi salvo com êxito no calendário.',
  faq: 'Perguntas frequentes',
  openSettings: 'Abrir configurações',
  openAppSettings: 'Abrir as configurações do aplicativo',
  alreadyDone: 'Feito',
  enableAutoStart: 'Ativar o início automático',
  restrictedApps: 'Aplicativos restritos',
  unmonitoredApps: 'Aplicativos não monitorados',
  enableProtected: 'Aplicativos protegidos',
  manageAppLaunch: 'Gerenciar o lançamento do aplicativo',
  unblockContact: 'Desbloquear contato',
  blockContact: 'Contato do bloco',
  call: 'Chamada {{name}}',
  text: 'Texto {{name}}',
  chat: 'Bate-papo',
  ok: 'OK',
  stopSnooze: 'Parar a soneca',
  emailId: 'Seu endereço de e-mail',
  feedback: 'Feedback',
  submit: 'Enviar',
  reportAProblem: 'Relatar um problema',
  enableAlarm: 'Ativar alarme',
  restoreAlarm: 'Restaurar o alarme',
  deletePastAlarms: 'Excluir alarmes inativos',
  accept: 'Aceitar',
  defaultPersonalAlarmName: 'Lembrete pessoal',
  defaultGroupAlarmName: 'Evento em grupo',
  defaultRecipientAlarmName: 'Alarme Buddy',
  search: 'Pesquisa',
  recent: 'Recentes',
  upcoming: 'Próximos',
  missed: 'Perdido',
  expired: 'Expirado',
  past: 'Passado',
  active: 'Ativo',
  inactive: 'Inativo',
  allAlarms: 'Todos os alarmes',
  myAlarms: 'Meus alarmes',
  participantAlarms: 'Alarmes de participantes',
  pendingParticipantAlarms: 'Novos alarmes de terceiros',
  alarmsWithUnreadChatMessages: 'Alarmes com novo chat',
  systemNotifications: 'Mensagens do sistema',
  missedAlarms: 'Alarmes perdidos',
  recentlyDeletedAlarms: 'Alarmes excluídos recentemente',
  searchAlarms: 'Alarmes de busca',
  participant: 'Participante',
  you: 'Você',
  none: 'Nenhum',
  onceOnly: 'Apenas uma vez',
  weekdays: 'Dias úteis',
  weekends: 'Finais de semana',
  everyDayString: 'Diariamente',
  everySunday: 'Todos os domingos',
  everyMonday: 'Toda segunda-feira',
  everyTuesday: 'Toda terça-feira',
  everyWednesday: 'Toda quarta-feira',
  everyThursday: 'Toda quinta-feira',
  everyFriday: 'Toda sexta-feira',
  everySaturday: 'Todos os sábados',
  sunday: 'Domingo',
  monday: 'Segunda-feira',
  tuesday: 'Terça-feira',
  wednesday: 'Quarta-feira',
  thursday: 'Quinta-feira',
  friday: 'Sexta-feira',
  saturday: 'Sábado',
  january: 'Janeiro',
  february: 'Fevereiro',
  march: 'Março',
  april: 'Abril',
  may: 'Maio',
  june: 'Junho',
  july: 'Julho',
  august: 'Agosto',
  september: 'Setembro',
  october: 'Outubro',
  november: 'Novembro',
  december: 'Dezembro',
  jan: 'Jan',
  feb: 'Fev',
  mar: 'Mar',
  apr: 'Abr',
  shortMay: 'Mai',
  jun: 'Jun',
  jul: 'Jul',
  aug: 'Ago',
  sep: 'Set',
  oct: 'Out',
  nov: 'Nov',
  dec: 'Dez',
  sun: 'Dom',
  mon: 'Seg',
  tue: 'Ter',
  wed: 'Qua',
  thu: 'Qui',
  fri: 'Sex',
  sat: 'Sáb',
  first: '1o',
  second: '2o',
  third: '3o',
  fourth: '4o',
  fifth: '5o',
  every: 'Cada',
  oddNumberedDays: 'Datas com números ímpares',
  evenNumberedDays: 'Datas com números pares',
  noParticipants: 'Nenhum participante',
  typeYourMessage: 'Digite sua mensagem',
  cantPostMessageToPastAlarm:
    'Não é possível postar mensagem em um alarme inativo',
  groupName: 'Grupo: {{name}}',
  alarm: 'Alarme',
  confirmed: 'confirmada',
  declined: 'recusado',
  Done: 'Feito',
  Skip: 'Pular',
  Confirm: 'Confirmar',
  Decline: 'Declínio',
  Accept: 'Aceitar',
  today: 'hoje',
  on: 'em',
  recipientAlarm: 'Alarme Buddy',
  recipient: 'Beneficiário',
  remindMeAfter: 'Lembre-me',
  remindMeIn: 'Lembre-me em',
  newRecipientAlarm: 'Novo Alarme Buddy',
  editRecipientAlarm: 'Editar alarme de amigo',
  newInstantAlarm: 'Novo alarme instantâneo',
  instantAlarms: 'Alarmes instantâneos',
  alarmDone: 'Alarme marcado como concluído',
  alarmSkipped: 'Alarme ignorado',
  undo: 'Desfazer',
  enterVerificationCode: 'Digite o código de 4 dígitos abaixo:',
  initializing: 'Inicialização',
  tryAgain: 'Tente novamente',
  nMore: '+ {{count}} mais',
  enableNotification: 'Ativar',
  enableAlarmNotificationChannel: 'Ativar',
  enableBackgroundAppRefresh: 'Ativar',
  viewImpactedAlarms: 'Alarmes',
  viewImpactedGroups: 'Grupos',
  dismiss: 'Dispensar',
  minsInterval: '+ {{count}}m',
  hoursInterval: '+ {{count}}h',
  daysInterval: '+ {{count}}d',
  snoozeUnitHours: 'Horas',
  snoozeUnitMinutes: 'Minutos',
  snoozeUnitDays: 'Dias',
  selectMinutes: 'Selecionar minutos',
  selectHours: 'Selecionar horas',
  selectDays: 'Selecionar dias',
  selectHoursAndMinutes: 'Selecione horas e minutos',
  enterDuration: 'Duração',
  snoozeUnit: 'Unidade',
  specifySnoozeUnit: 'Especificar a unidade de soneca',
  specifySnoozeDuration: 'Especificar a duração da soneca',
  duration: 'Duração',
  quickAlarmName: {
    zero: '{{minutes}} lembrete mínimo',
    one: '{{hours}} lembrete de horas',
    other: '{{hours}} Hora {{minutes}} lembrete de minutos'
  },
  alarmTitleForGoogleVoiceTimerCommand: '{{durationString}} lembrete',
  addedAlarmFromVoiceCommand:
    'Adicionado um alarme para {{alarmName}} em {{dateString}}',
  addedTimerFromVoiceCommand: 'Adicionado um cronômetro para {{duration}}',
  clear: 'Limpo',
  ringtoneDuration: 'Duração do toque',
  chooseDuration: 'Toque em uma duração. Você pode tocar várias vezes.',
  newAlarm: 'Novo alarme',
  quickAlarm: 'Lembrete rápido',
  quickAlarmWillRingAt: 'O alarme tocará em {{timeString}}.',
  preReminderWillRingAt:
    'Um lembrete prévio para esse alarme tocará \nem {{timeString}}.',
  random: 'Aleatório',
  enableAllAlarms: 'Ativar todos os alarmes',
  disableAllAlarms: 'Desativar todos os alarmes',
  deleteAllAlarms: 'Excluir todos os alarmes',
  hideCategory: 'Ocultar categoria',
  unhideCategory: 'Reexibir categoria',
  dontShowHiddenCategories: 'Não mostrar categorias ocultas',
  showHiddenCategories: 'Mostrar categorias ocultas',
  welcomeAlarmsTitle: 'Vamos começar!',
  welcomeAlarmsMessage: 'Aqui estão alguns alarmes comumente usados:',
  onboardingAlarms: 'Alarmes de integração',
  hiddenAlarmCategories: 'Categorias ocultas',
  restorePurchases: 'Restaurar compras',
  noAvailablePurchasesFound:
    'Não foram encontradas compras disponíveis para sua conta.\n\nEnvie-nos um feedback usando "Configurações > Feedback" se você tiver uma compra válida que não possa ser restaurada.',
  restoringPurchases: 'Restauração de compras',
  fetchingContacts: 'Verificação de quais contatos estão usando o Galarm',
  enterNameAndUploadPhotoForUser:
    'Digite seu nome abaixo e, opcionalmente, carregue sua foto de perfil',
  enterNameAndUploadPhotoForGroup:
    'Digite um nome abaixo e, opcionalmente, carregue uma foto do grupo',
  noContactsWithAppInstalled: 'Ninguém nos seus contatos está usando o Galarm.',
  galarmContacts: {
    one: '1 contato Galarm',
    other: '{{count}} Contatos da Galarm'
  },
  contactsPendingInvitation: {
    one: '1 convite pendente',
    other: '{{count}} convites pendentes'
  },
  otherContacts: { one: '1 outro contato', other: '{{count}} outros contatos' },
  over100Contacts: 'Mais de 100 outros contatos',
  noAlarms:
    'Você não tem nenhum alarme. Toque em + para adicionar um novo alarme.',
  noContactsWeb: {
    one: 'Não encontramos nenhum contato usando o Galarm. Ou não temos permissão para acessar seus contatos ou ninguém em seus contatos está usando o Galarm. Você pode sincronizar seus contatos no aplicativo móvel.\n\nVocê pode adicionar contatos que usam o Galarm aos seus alarmes de diferentes maneiras para se beneficiar do aplicativo',
    other:
      'Registre-se no aplicativo móvel para começar a adicionar contatos aos seus alarmes.'
  },
  viewingAlarmsInCommon: 'Alarmes em comum com {{name}}',
  viewingGroupsInCommon: 'Grupos em comum com {{contactName}}',
  changeGroupMemberConfigTextForCascadingAlarm:
    'Os participantes ativos serão alertados na ordem exibida.',
  changeGroupMemberConfigTextForSimultaneousAlarm:
    'Somente os participantes ativos serão alertados.',
  changeGroupMemberStateText: {
    zero: 'Altere os membros que podem alertá-lo quando um alarme for criado para o grupo "{{groupName}}".\n\nVocê será alertado somente se o alarme for criado por membros permitidos.',
    one: 'Alterar os membros que podem alertar {{memberName}} quando um alarme é criado para o grupo "{{groupName}}".\n\n{{memberName}} será alertado somente se o alarme for criado por membros permitidos.'
  },
  allowed: 'Permitido',
  notAllowed: 'Não permitido',
  changeStatus: 'Membros permitidos',
  noUpcomingAlarms: 'Sem alarmes futuros',
  showMore: 'Mostrar mais',
  nMorePastAlarms: {
    one: 'Mais 1 alarme inativo',
    other: '{{count}} mais alarmes inativos'
  },
  shareAppMessage:
    'Faço todas as minhas coisas em tempo hábil, criando lembretes para elas usando o aplicativo Galarm. Por meio desse aplicativo, também podemos incluir uns aos outros em nossos lembretes. Recomendo o download desse inovador aplicativo de alarme e lembrete. {{downloadLink}}',
  addingMembers: 'Adição de membros.',
  unableToAddMembers: 'Não é possível adicionar membros. {{error}}',
  removingMember: 'Remoção de membro.',
  unableToRemoveMember: 'Não foi possível remover o membro. {{error}}',
  leavingGroup: 'Grupo de saída.',
  unableToLeaveGroup: 'Não é possível sair do grupo. {{error}}',
  expiredPersonalAlarmForCreator: {
    zero: 'Esse alarme expirou {{durationString}} ago. Toque em "Done" (Concluído) para concluir o alarme.',
    one: 'Esse alarme expirou {{durationString}} ago. Toque em "Done" (Concluído) para concluir o alarme e notificar os participantes.',
    other:
      'Esse alarme expirou {{durationString}} ago. Toque em "Done" (Concluído) para concluir o alarme e notificar os participantes.'
  },
  donePersonalAlarmForCreator:
    'Esse alarme expirou {{durationString}} ago. O alarme está marcado como concluído.',
  skippedPersonalAlarmForCreator:
    'Esse alarme expirou {{durationString}} ago. Você ignorou o alarme.',
  expiredGroupAlarm:
    'Esse alarme expirou {{durationString}} ago. Por favor, confirme sua participação.',
  expiredPersonalAlarmForParticipant:
    'Esse alarme expirou {{durationString}} ago. Favor lembrar {{creatorName}}.',
  alarmDurationAndroid:
    'Alarme programado para {{durationString}} a partir de agora.',
  alarmDurationIos:
    'Alarme programado para {{durationString}} a partir de agora.',
  alarmFeedbackHeader:
    'Você enviará parte das informações desse alarme para fins de depuração. Quaisquer dados pessoais, como nome do alarme, anotações ou participantes, não serão compartilhados.',
  privacyPolicyHeader:
    'O respeito à sua privacidade está sempre presente em nossos relógios. Assim como você, valorizamos a privacidade, portanto, queremos que saiba que não compartilhamos suas informações pessoais com ninguém, a menos que seja necessário para a instalação do aplicativo ou quando exigido por lei. Em todos os casos, nunca vendemos suas informações. É tão simples quanto isso!\n \nOs alarmes que você cria podem ser visualizados somente no seu dispositivo e, se um alarme incluir outros participantes, nos dispositivos desses participantes.\n \nAcessamos o catálogo de endereços/lista de contatos do seu telefone somente para preencher a guia de contatos no Galarm. Não armazenamos essa lista para nenhuma outra finalidade. Honramos o fato de ser seu catálogo de endereços!\n \nAcessamos sua câmera e suas fotos somente para permitir que você defina uma boa foto de perfil. Essa imagem aparece como seu avatar ou como imagem de perfil de um grupo que você edita. Outros usuários do Galarm que são membros dos alarmes que você definiu poderão ver essa imagem.\n\nEnviamos o IP público do seu dispositivo e seu ID exclusivo com todas as solicitações, por exemplo, ao solicitar e verificar o código durante o processo de registro. Essas informações não estão vinculadas à sua conta e são usadas apenas para determinar qualquer atividade maliciosa em potencial.\n \nConsulte a política de privacidade completa para obter mais detalhes sobre as informações que coletamos e como as usamos.',
  termsOfServiceHeader:
    'Ao usar o aplicativo Galarm, você concorda com nossos Termos de Serviço, que regem seu acesso e uso desse aplicativo móvel e dos recursos e serviços que ele oferece. \n\nO lema do Galarm é simples: ajudá-lo a nunca perder nenhum evento!  Seja configurando Alarmes Pessoais para alertas de despertar, chegando a tempo para compromissos e definindo lembretes para atividades como tomar remédios na hora certa, ou criando Alarmes de Grupo para organizar atividades em grupo, como saídas para o cinema, eventos esportivos e festas, o Galarm permite que você tenha um despertador social para lembrar uns aos outros, acompanhar o status dos outros e se comunicar.\n\nUse o Galarm somente se você concordar com estes termos. Abaixo está um link para ele.',
  acknowledgedPersonalAlarm:
    'Você marcou o alarme como "Concluído" {{durationString}} atrás.',
  skippedPersonalAlarm: 'Você pulou o alarme {{durationString}} atrás.',
  authenticatingWithServer: 'Conectando...',
  selectParticipantsForPersonalAlarm:
    'Selecione um grupo ou um conjunto de pessoas que atuem como backups para seu alarme pessoal.',
  selectParticipantsForGroupAlarm:
    'Selecione um grupo ou um conjunto de pessoas para sua atividade em grupo.',
  acknowledgedGroupAlarm:
    'Você {{acknowledgedAsString}} esse alarme {{durationString}} atrás.',
  acknowledgedExpiredGroupAlarm:
    'Esse alarme expirou {{durationString}} ago. Você {{acknowledgedAsString}} esse alarme.',
  acknowledgedPersonalAlarmForParticipant:
    '{{creatorName}} marcou o alarme como "Concluído" {{durationString}} ago.',
  acknowledgedExpiredPersonalAlarmForParticipant:
    'Esse alarme expirou {{durationString}} ago. {{creatorName}} marcou o alarme como "Done" (Concluído).',
  entityNotFound:
    'Ops! Não foi possível encontrar o site {{entityType}} selecionado. É possível que ainda estejamos recuperando-o ou que ele tenha sido removido.',
  goBack: 'Voltar',
  uploadingImageToServer: 'Carregamento de imagem para o servidor.',
  deletingImageFromServer: 'Exclusão de imagem do servidor',
  errorUploadingImageToServer:
    'Erro ao carregar a imagem no servidor. Tente novamente mais tarde.',
  unableToRemoveGroup: 'Não foi possível remover o grupo. {{error}}',
  addingGroup: 'Adicionando grupo.',
  editingGroup: 'Grupo de edição.',
  unableToAddGroup: 'Não foi possível adicionar o grupo. {{error}}',
  unableToEditGroup: 'Não é possível editar o grupo. {{error}}',
  thanksForFeedback: 'Obrigado por nos ajudar a melhorar.',
  licensesHeader:
    'O Galarm inclui software licenciado por terceiros. Consulte nossa página de licenciamento de terceiros para obter as informações de atribuição e licenciamento correspondentes.',
  alarmAlreadyInSnooze: 'Você será lembrado em {{durationString}}.',
  carrierChargesMayApply: 'Podem ser cobradas taxas da transportadora.',
  verficationCodeInfo:
    'Você receberá uma mensagem de texto com um código de 4 dígitos.',
  selectCascadingInterval:
    'O primeiro participante será alertado {{firstParticipantDurationString}} após o horário do alarme, o segundo participante será alertado {{secondParticipantDurationString}} após o horário do alarme e assim por diante se você não marcar o alarme como concluído.',
  reorderParticipantsScreenDescription:
    'Os participantes serão alertados na ordem exibida.',
  noMembers: 'Nenhum membro',
  selectedGroupDescription: {
    zero: '{{numActiveMembers}} participantes',
    one: '{{numActiveMembers}} participante ativo e {{numInactiveMembers}} participante inativo',
    other:
      '{{numActiveMembers}} participantes ativos e {{numInactiveMembers}} participantes inativos'
  },
  groupAlarmWillRingAtSameTime:
    'Todos os participantes serão alertados em {{timeString}}.',
  welcomeToGalarm: 'Bem-vindo à Galarm',
  checkingConnection: 'Verificação da conexão',
  deletePastAlarmsTitle: 'Excluir alarmes inativos mais antigos que',
  deletePastAlarmsOption: {
    zero: '{{option}} (0 alarmes)',
    one: '{{option}} (1 alarme)',
    other: '{{option}} ({{count}} alarmes)'
  },
  deletePastAlarmsNotificationMessage:
    'Exclua os alarmes inativos para reutilizá-los.',
  chooseSnoozeInterval: 'Lembre-me novamente em',
  personalAlarmDescription:
    'Use um alarme pessoal para acordar, tomar remédios ou qualquer outra tarefa para si mesmo. Opcionalmente, você pode adicionar participantes que serão alertados se você perder um alarme, para que eles possam lembrá-lo. Os participantes também são notificados quando você marca o alarme como concluído.',
  groupAlarmDescription:
    'Use um alarme de grupo para atividades em grupo, como passeios, festas ou qualquer outro evento. O alarme dispara ao mesmo tempo para todos os participantes e eles podem confirmar sua participação.',
  recipientAlarmDescription:
    'Crie alarmes para outra pessoa ("amigo") para lembrá-la de coisas que ela precisa fazer. Você é alertado para lembrar o colega, caso ele perca o alarme. Você também será notificado quando o colega marcar o alarme como concluído.\n  \nObserve que os alarmes são criados em seu fuso horário.',
  whatsThis: 'O que é isso?',
  alarmCurrentlyDisabled: 'Esse alarme está desativado no momento',
  alarmCurrentlyRejected: 'Esse alarme está sendo rejeitado no momento',
  currentlyTypingUsers: {
    one: '{{currentlyTypingUsers}} está digitando...',
    other: '{{currentlyTypingUsers}} estão digitando...'
  },
  noSearchResultsFound: 'Nenhum {{type}} encontrado',
  noGroupsCreated: 'Você não criou nenhum grupo.',
  noGroupsWeb: {
    one: 'Você não criou nenhum grupo.',
    other:
      'Registre-se no aplicativo móvel para começar a criar grupos e adicioná-los aos alarmes.'
  },
  internalErrorDuringRegistration:
    'Ocorreu um erro interno durante o registro do usuário. Tente novamente após alguns minutos',
  selectRecipientAlarmInterval: {
    zero: 'Você será alertado ao mesmo tempo que o destinatário',
    one: "Você será alertado {{durationString}} após o horário do alarme se o destinatário não marcar o alarme como 'Concluído'",
    other:
      "Você será alertado {{durationString}} após o horário do alarme se o destinatário não marcar o alarme como 'Concluído'"
  },
  selectRecipient: 'Selecionar destinatário',
  acknowledgedExpiredRecipientAlarm:
    'Este alarme expirou {{durationString}} ago. Você marcou esse alarme como "Concluído".',
  skippedExpiredRecipientAlarm:
    'Esse alarme expirou {{durationString}} ago. Você ignorou esse alarme.',
  expiredRecipientAlarm:
    'Esse alarme expirou {{durationString}} ago. Toque em "Done" (Concluído) para concluir o alarme e notificar {{creatorName}}.',
  acknowledgedRecipientAlarm:
    'Você marcou esse alarme como "Concluído" {{durationString}} ago.',
  skippedRecipientAlarm: 'Você pulou esse alarme {{durationString}} atrás.',
  acknowledgedExpiredRecipientAlarmForCreator:
    'Esse alarme expirou {{durationString}} ago. {{recipientName}} marcou o alarme como "Done" (Concluído).',
  skippedExpiredRecipientAlarmForCreator:
    'Esse alarme expirou {{durationString}} ago. {{recipientName}} ignorou o alarme.',
  expiredRecipientAlarmForCreator:
    'Esse alarme expirou {{durationString}} ago. Favor lembrar {{recipientName}}.',
  acknowledgedRecipientAlarmForCreator:
    '{{recipientName}} marcou o alarme como "Concluído" {{durationString}} ago.',
  selectRecipientForRecipientAlarm: 'Selecione um destinatário para o alarme',
  selectAtLeastOneParticipantForGroupAlarm:
    'Adicionar pelo menos um participante ao alarme',
  addAtLeastOneMemberToGroup: 'Adicionar pelo menos um membro ao grupo',
  atTheSameTime: 'Ao mesmo tempo',
  myself: 'Eu mesmo',
  group: 'Grupo',
  someoneElse: 'Outra pessoa',
  participantsAreNotified: 'Os participantes são notificados.',
  creatorIsNotified: '{{creatorName}} é notificado.',
  membersAddedToGroup: {
    one: '{{memberNames}} é adicionado ao grupo {{groupName}}.',
    other: '{{memberNames}} são adicionados ao grupo {{groupName}}.'
  },
  memberRemovedFromGroup:
    '{{memberName}} foi removido do grupo {{groupName}}, o que pode ter afetado alguns de seus alarmes.',
  youRemovedFromGroup:
    'Você foi removido do grupo {{groupName}}, o que pode ter afetado alguns de seus alarmes.',
  memberLeftGroup:
    '{{memberName}} deixou o grupo {{groupName}}, o que pode ter afetado alguns de seus alarmes.',
  youLeftGroup:
    'Você deixou o grupo {{groupName}}, o que pode ter afetado alguns de seus alarmes.',
  groupLeft: 'Você saiu do grupo {{groupName}}.',
  memberMovedToADifferentTimezone:
    '{{memberName}} mudou-se para um fuso horário diferente, o que pode ter afetado alguns de seus alarmes.',
  memberDstChanged: {
    zero: '{{memberName}} parou de observar o horário de verão. Os alarmes relevantes foram atualizados.',
    one: '{{memberName}} começou a observar o horário de verão. Os alarmes relevantes foram atualizados.'
  },
  impactedAlarms: 'Alarmes afetados:',
  alwaysRingParticipantAlarmsOn:
    'Os alarmes de outras pessoas tocarão, a menos que você os recuse.',
  alwaysRingParticipantAlarmsOff:
    'Os alarmes de outras pessoas não tocarão a menos que você os aceite.',
  agreeToTermsOfServiceAndPrivacyPolicy:
    'Ao continuar, você aceita os Termos de Serviço e a Política de Privacidade do Galarm.',
  deleteAccountWarningHeader:
    'Aviso: Esta ação é irreversível. A exclusão de sua conta irá:',
  deleteAccountDeleteAlarms: '- Excluir todos os alarmes que você criou',
  deleteAccountDeleteRecipientAlarms:
    '- Excluir todos os alarmes de amigos nos quais você é o destinatário',
  deleteAccountRemoveFromGroups: '- Remover você de todos os grupos',
  deleteAccountWarningFooter: {
    zero: 'Se você estiver trocando de telefone, basta instalar o aplicativo no novo telefone e registrar-se usando o mesmo número. Você pode remover o aplicativo nesse telefone.\n\nDepois de excluir sua conta, você não poderá restaurá-la.',
    one: 'Se você estiver trocando de telefone, basta instalar o aplicativo no novo telefone e registrar-se usando o mesmo número. Você pode remover o aplicativo nesse telefone.\n\nDepois de excluir sua conta, você não poderá restaurá-la. Você pode gerenciar sua assinatura ativa do Galarm na Play Store.',
    other:
      'Se você estiver trocando de telefone, basta instalar o aplicativo no novo telefone e excluir o aplicativo deste telefone.\n\nDepois de excluir sua conta, você não poderá restaurá-la. Você pode gerenciar sua assinatura ativa do Galarm na App Store.'
  },
  typeConfirmationStringToDeleteAccount:
    'Digite {{confirmationString}} para excluir sua conta',
  unableToDeleteAccount: 'Não é possível excluir a conta. {{error}}',
  impactedGroups: 'Grupos afetados:',
  memberDeletedAccount:
    '{{memberName}} excluiu sua conta, o que pode ter afetado alguns de seus alarmes e grupos.',
  confirmCountryCodeAndEnterNumber:
    'Confirme o código do seu país e digite seu número de telefone.',
  confirmCountryCodeAndEnterNumberForExistingUser:
    'Confirme o código do seu país e digite o número de telefone usado no registro anterior.',
  requiredPermissionsDisabledWarningIos:
    'As notificações são desativadas. Os alarmes não tocarão. Toque para corrigir.',
  requiredPermissionsDisabledWarningAndroid:
    'O Galarm não tem as permissões necessárias para tocar alarmes. Toque para corrigir.',
  notificationChannelPopupDisabledWarningAndroid:
    'As notificações de alarme não aparecerão na tela. Toque para corrigir.',
  alarmLockScreenNotificationDisabledWarningAndroid:
    'As notificações de alarme não aparecerão na tela de bloqueio. Toque para corrigir.',
  why: 'Por que',
  how: 'Como',
  registrationRequired: 'Registro obrigatório',
  phoneNumberRequired: 'Número de telefone necessário',
  fewSeconds: 'alguns segundos',
  lessThanMinute: 'menos de um minuto',
  updateUnsupportedVersion:
    'É necessário ter uma versão atualizada do aplicativo para continuar usando o Galarm.',
  update: 'Atualização',
  back: 'Voltar',
  unableToMarkAlarmDone:
    'Não é possível marcar o alarme como concluído no momento. Tente novamente em alguns instantes.',
  unableToMarkAlarmUndone:
    'Não é possível marcar o alarme como desfeito no momento. Tente novamente em alguns instantes.',
  unableToMarkAlarmUnskipped:
    'Não é possível marcar o alarme como não pulado no momento. Tente novamente em alguns instantes.',
  unableToSkipAlarm:
    'Não é possível ignorar o alarme no momento. Tente novamente em alguns instantes.',
  unableToSetGroupAlarmResponse:
    'Não é possível acessar {{response}} o alarme de grupo no momento. Tente novamente em alguns instantes.',
  unableToDeleteAlarm:
    'Não é possível excluir o alarme no momento. Tente novamente em alguns instantes.',
  unableToSetAlarmResponse:
    'Não foi possível definir sua resposta no momento. Tente novamente em alguns instantes.',
  unableToSnoozeAlarm: 'Não é possível adiar o alarme.',
  unableToStopSnoozeForAlarm: 'Não é possível interromper a soneca do alarme.',
  unableToUpdateParticipantAlarmRingerSettings:
    'Não é possível atualizar as configurações de campainha no momento. Tente novamente em alguns instantes.',
  updateParticipantPreReminderDuration:
    'Não é possível atualizar a duração do pré-lembrete no momento. Tente novamente em alguns instantes.',
  unableToUpdateAlarmRingerSettings:
    'Não é possível atualizar as configurações de campainha no momento. Tente novamente em alguns instantes.',
  unableToUpdateAlarmPreReminderDuration:
    'Não é possível atualizar a duração do pré-lembrança no momento. Tente novamente em alguns instantes.',
  retrievingAlarmsFromServer: 'Recuperação de alarmes do servidor...',
  startApp: 'Iniciar >',
  phoneNumberRequiredGroupListHeaderText:
    'O Group é uma coleção de contatos do Galarm que lhe permite criar rapidamente alarmes com eles.\n\nRegistre-se usando o seu número de telefone para encontrar os contatos que estão usando o Galarm. Você pode adicioná-los aos grupos.',
  phoneNumberRequiredBuddyAlarmHeaderText:
    'O Buddy Alarm pode ser usado para definir alarmes para amigos e familiares para lembrá-los de coisas que precisam fazer.\n\nPara usar esse e muitos outros recursos interessantes do aplicativo, registre-se usando seu número de telefone.',
  phoneNumberRequiredContactListHeaderText:
    'Contatos são pessoas que podem ser adicionadas como participantes de alarmes.\n\nRegistre-se usando o seu número de telefone para visualizar os contatos que estão usando o Galarm. Você pode adicioná-los como participantes de seus alarmes.',
  phoneNumberRequiredGroupAlarmHeaderText:
    'O alarme de grupo pode ser usado para definir um lembrete para um grupo de pessoas.\n\nPara usar esse e muitos outros recursos interessantes do aplicativo, registre-se usando seu número de telefone.',
  phoneNumberRequiredParticipantsHeaderText:
    'Os participantes atuam como backups para esse alarme. Eles serão lembrados se você perder o alarme.\n\nPara usar esse e muitos outros recursos interessantes do aplicativo, registre-se usando seu número de telefone.',
  youHaveNotRegistered:
    'O backup de seus alarmes não está sendo feito no momento.\n\nRegistre-se usando seu número de telefone. Após o registro, o backup dos alarmes será feito na nuvem. Isso permite restaurar os alarmes e outras configurações quando você trocar de telefone.\n',
  registerNow: 'Registre-se agora',
  hoursAndMinutes: 'Horas e minutos',
  days: 'Dias',
  weeks: 'Semanas',
  months: 'Meses',
  years: 'Anos',
  daysOfWeek: 'Semanalmente (dias selecionados)',
  monthly: 'Mensal',
  yearly: 'Anual',
  everyDay: 'Diariamente',
  selectDaysOfWeek: 'Selecione os dias da semana',
  alarmRingMessageForSpecificDates:
    'Esse alarme só tocará em meses que tenham pelo menos {{dayOfMonth}} dias. Deseja continuar?',
  alarmRingMessageForSpecificYear:
    'Esse alarme só tocará em anos bissextos. Deseja continuar?',
  alarmRingMessageForSpecificDayOfWeekInMonth:
    'Esse alarme só tocará nos meses que tiverem {{weekNumberOfDayInMonth}} {{dayName}} s. Deseja continuar? ',
  alarmRepetitionAdjustedBasedOnNewDate:
    'A repetição do alarme foi atualizada com base na nova data.',
  inviteFriendsToInstallGalarm:
    'Contatos são pessoas que podem ser adicionadas como participantes de seus alarmes. Você pode convidar contatos:\n\n1. Do seu catálogo de endereços\n\n2. Fornecendo manualmente o nome e o número da pessoa.\n\nToque em Refresh (Atualizar) no canto superior esquerdo para recarregar os contatos do Galarm após o registro.',
  whyCreateNewGroup:
    'Grupos são coleções de contatos que podem ser adicionados como participantes aos seus alarmes.\n  \nVocê não criou nenhum grupo.',
  noNotifications: 'Você não tem novas notificações.',
  createGroup: 'Criar grupo',
  galarmRequiresAccessToContactsPermission:
    'O Galarm solicita permissão para acessar seus contatos. Essas informações são enviadas aos servidores para identificar seus contatos que usam o Galarm. Esses usuários podem ser adicionados como participantes aos seus alarmes.',
  galarmRequiresAccessToAudioFilesPermission:
    'Alguns alarmes estão usando a música do telefone como toque. Forneça permissão para acessar arquivos de áudio em seu dispositivo para tocar esses alarmes com o toque escolhido.',
  galarmRequiresAccessToContactsSelectScreen:
    'Galarm não tem permissão para acessar os contatos. Permita o acesso aos contatos se quiser usar esse recurso.',
  allow: 'Permitir',
  deny: 'Negar',
  galarmRequiresAccessToContacts:
    'Contatos são pessoas que podem ser adicionadas como participantes de seus alarmes. Você pode adicionar contatos por:\n\n1. Importando automaticamente dos contatos do telefone. Toque em "Permitir acesso" e ative "Contatos".\n\n2. Adicionar manualmente o nome e o número do contato. Toque em "Add Contact" (Adicionar contato) para começar.',
  galarmRequiresAccessToAlarmAndRemindersPermission:
    'O Galarm requer a permissão "Alarms & reminders" (Alarmes e lembretes) para que possa tocar os alarmes exatamente nos horários especificados. Ative-a na próxima tela.',
  accessToContactsPermissionRequiredAndroid:
    'O Galarm não tem permissão para acessar os contatos do seu telefone.\n\nQuando você permitir o acesso, as pessoas dos contatos do telefone que estiverem usando o Galarm aparecerão como contatos do Galarm. Essas pessoas podem ser adicionadas como participantes do alarme. Toque em "Allow Access" (Permitir acesso), selecione "Permission" (Permissão) e ative "Contacts" (Contatos).\n\nVocê ainda pode adicionar contatos manualmente ao Galarm.',
  accessToContactsPermissionRequiredIos:
    'O Galarm não tem permissão para acessar os contatos do seu telefone.\n\nQuando você permitir o acesso, as pessoas dos contatos do telefone que estiverem usando o Galarm aparecerão como contatos do Galarm. Essas pessoas podem ser adicionadas como participantes do alarme. Toque em "Allow Access" (Permitir acesso) e ative "Contacts" (Contatos).\n\nVocê ainda pode adicionar contatos manualmente ao Galarm.',
  giveAccess: 'Permitir acesso',
  enterValidMobileNumber:
    'Digite um número de telefone válido para se registrar.',
  proceedAnyway: 'Continue assim mesmo',
  checkInvalidPhoneNumber:
    'Acreditamos que você digitou um número inválido como {{mobileNumber}}.',
  checkInvalidCountryCode:
    'Código de país inválido especificado como {{countryCode}}. Se mais de um código de país estiver presente, edite o campo para manter o código de país aplicável.',
  editNumber: 'Editar número',
  editCountryCode: 'Editar código do país',
  sendingCodeOnCall: 'Ligando para você com o código.',
  sendCodeOnCall: 'Ligue-me com o código',
  registrationLandingScreenRegisterText:
    'Toque em Continuar para se registrar usando o seu número de telefone.\n\nAo se registrar:\n1) Você poderá adicionar participantes aos seus alarmes e conversar com eles.\n2) Será feito um backup dos seus alarmes na nuvem, de modo que, quando você trocar de telefone, os alarmes aparecerão no novo telefone.',
  continue: 'Continuar',
  registerLater: 'Ignorar registro',
  cancelRegistration: 'Cancelar registro',
  welcome: 'Bem-vindo',
  haventReceivedCodeYet: {
    one: 'Ainda não recebeu o código? Você pode solicitar o código novamente em 1 segundo.',
    other:
      'Ainda não recebeu o código? Você pode solicitar o código novamente em {{count}} segundos.'
  },
  storagePermissionDenied:
    'Não é possível acessar arquivos de música porque a permissão de armazenamento foi negada.',
  havingTroubleRegisteringMessage:
    'O Galarm não consegue verificar o código após várias tentativas.\n\nDeseja pular o registro e começar a usar o aplicativo?',
  autoDetectVerificationCode:
    'Para simplificar o registro, o Galarm pode detectar automaticamente o código de verificação se você permitir que o Galarm visualize mensagens SMS.',
  notNow: 'Agora não',
  verifyNumberText:
    'Enviaremos um SMS com o código de verificação para:\n\n{{formattedMobileNumber}}',
  addToContacts: 'Adicionar aos contatos',
  todayString: 'Hoje',
  tomorrowString: 'Amanhã',
  backups: 'Backups',
  acintyoTrademark:
    '© 2024 Acintyo, Inc. Todos os direitos reservados.\nGalarm é uma marca registrada da Acintyo, Inc.\nProtegida por patentes dos EUA 10382613, 10867286.\nOutras patentes pendentes.',
  acintyoTrademarkWeb:
    '© 2024 Acintyo, Inc. Todos os direitos reservados. Galarm é uma marca registrada da Acintyo, Inc.\nProtegida por patentes dos EUA 10382613, 10867286. Outras patentes pendentes.',
  acintyoTrademarkEnterprise:
    '© 2024 Acintyo, Inc.\nTodos os direitos reservados.',
  markAlarmDone: 'Marcar o alarme como concluído',
  skipAlarm: 'Ignorar alarme',
  confirmGroupAlarm: 'Confirmar participação',
  declineGroupAlarm: 'Diminuição da participação',
  snoozeAlarm: 'Alarme soneca',
  deleteAlarmQuickAction: 'Excluir alarme',
  disableAlarm: 'Desativar alarme',
  confirmDisableAlarm: 'Desativar o alarme?',
  saveToCalendar: 'Exportar para o calendário',
  stillRingGroupAlarmAfterConfirmingInAdvance:
    'Você está confirmando o alarme de grupo com antecedência.  Você ainda quer o lembrete na hora do alarme?',
  updateNow: 'Atualizar agora',
  nParticipants: {
    one: '1 outro participante',
    other: '{{count}} outros participantes'
  },
  details: 'Detalhes',
  changeParanthesis: '(mudar)',
  loadMoreHistory: 'Ver mais',
  alarmSummaryForHistory:
    'Respostas anteriores para {{alarmName}}.\nRepetições {{alarmRepetitionString}}\n{{previousOccurrencesString}}',
  alarmSummaryForHistoryWeb: 'Respostas anteriores para {{alarmName}}',
  alarmSummaryForHistoryRepetitionWeb: 'Repetições {{alarmRepetitionString}}',
  dontSeeCompleteHistory: 'Não está vendo o histórico completo?',
  dontSeeCompleteHistoryExplanation:
    'O histórico de alarmes é registrado a partir do momento em que o alarme é criado. Mantemos apenas um histórico limitado para cada alarme, dependendo da repetição do alarme. Por exemplo, se o alarme se repetir todos os dias, manteremos um histórico de 30 dias.\n\nAlterar a hora ou a repetição do alarme redefine o histórico do alarme.',
  noActionAvailableForAlarmOccurrence:
    'Não há ações disponíveis para essa ocorrência.',
  enterEmailIdForFeedback: 'Usado para responder e compartilhar atualizações',
  noPreviousOccurrences: 'Ainda não há histórico de alarmes',
  nMissedOccurrencesOfAlarmInLastNDays: {
    zero: 'Ainda não há histórico de alarmes',
    one: '{{missedAlarmOccurrences}} ficou de fora da última {{count}}',
    other: '{{missedAlarmOccurrences}} ficou de fora da última {{count}}'
  },
  soundAndVibrationSummaryString: {
    zero: '{{volume}}% de volume, {{ringtoneName}}',
    one: '{{volume}}% de volume, {{ringtoneName}}, Título do anúncio'
  },
  soundAndVibrationSummaryStringIos: {
    zero: '{{ringtoneName}}',
    one: 'Crítico, {{volume}}% volume, {{ringtoneName}}'
  },
  previousOccurrences: 'Histórico de alarmes',
  retrieving: 'Recuperação...',
  startTimeOfDay: 'Começa em',
  endTimeOfDay: 'Termina em',
  ringEveryHours: 'Anéis a cada',
  everyNHours: 'Por hora',
  everyMHoursNMinutes: 'Horas e minutos',
  selectHourlyRepetition: 'Selecione a repetição por hora',
  everyNHoursRepetitionString: {
    one: 'A cada hora, de {{startTimeString}} a {{endTimeString}} a cada {{daysString}}',
    other:
      'A cada {{count}} horas de {{startTimeString}} a {{endTimeString}} a cada {{daysString}}'
  },
  everyNHoursAndMinutesRepetitionString:
    'Todo {{hoursAndMinutes}} de {{startTimeString}} a {{endTimeString}} todo {{daysString}}',
  everyMHoursAndNMinutesRepetitionString: 'Cada {{repeatString}}',
  everyNHoursRepetitionStringForCalendar: {
    one: 'A cada hora, de {{startTimeString}} a {{endTimeString}}',
    other: 'A cada {{count}} horas, de {{startTimeString}} a {{endTimeString}}'
  },
  everyNHoursAndMinutesRepetitionStringForCalendar:
    'Todo {{hoursAndMinutes}} de {{startTimeString}} a {{endTimeString}}',
  everyNHoursAndMinutesRepetitionStringForAlarmSummary:
    'Todo {{hoursAndMinutes}} até {{endTimeString}}',
  everyNHoursRepetitionStringForAlarmSummary: {
    one: 'A cada hora até {{endTimeString}}',
    other: 'A cada {{count}} horas até {{endTimeString}}'
  },
  daysRepetitionOddString: {
    other: 'Todas as datas ímpares',
    one: 'Datas com números ímpares ({{daysString}})'
  },
  daysRepetitionEvenString: {
    other: 'Todas as datas de número par',
    one: 'Datas com números pares ({{daysString}})'
  },
  oddNumberedRepetitionPreviewString: {
    one: 'O alarme tocará nas datas ímpares que caírem em {{daysString}} no horário especificado.',
    other: 'O alarme tocará em todas as datas ímpares no horário especificado.'
  },
  evenNumberedRepetitionPreviewString: {
    one: 'O alarme tocará nas datas pares que caírem em {{daysString}} no horário especificado.',
    other: 'O alarme tocará em todas as datas pares no horário especificado.'
  },
  hourlyRepetitionPreviewString: {
    one: 'Esse alarme tocará uma vez a cada {{daysString}} em:',
    other: 'Esse alarme tocará {{count}} vezes a cada {{daysString}} hora:'
  },
  daysRepetitionPreviewString: {
    one: 'Esse alarme tocará todos os dias.',
    other: 'Esse alarme tocará a cada {{count}} dias.'
  },
  weeksRepetitionPreviewString: {
    one: 'Esse alarme tocará toda semana.',
    other: 'Esse alarme tocará a cada {{count}} semanas.'
  },
  everyNDays: { one: 'Todos os dias', other: 'A cada {{count}} dias' },
  everyNWeeks: { one: 'Toda semana', other: 'A cada {{count}} semanas' },
  everyNMonths: { one: 'Todo mês', other: 'A cada {{count}} meses' },
  everyNYears: { one: 'Todo ano', other: 'A cada {{count}} anos' },
  cantSetEndTime: 'Não é possível definir o horário de término',
  endTimeShouldBeGreater: 'A hora final deve ser maior que a hora inicial.',
  cantSetStartTime: 'Não é possível definir a hora de início',
  startTimeShouldBeLess:
    'O horário de início deve ser menor que o horário de término.',
  everyDaySmall: 'dia',
  everyGivenDay: '{{day}}',
  everyGivenDays: '{{days}}',
  adjustingAlarmsAfterTimezoneChange:
    'Detectamos que você mudou para um fuso horário diferente. Estamos ajustando seus alarmes.',
  ringOnDays: 'Anéis em',
  yourAlarmsMayNotRing: 'Seus alarmes podem não tocar',
  yourAlarmsMayNotRingTapToFix:
    'Seus alarmes podem não tocar. Toque para corrigir.',
  xiaomiSetupInstructions:
    'Siga estas instruções para que seus alarmes sempre toquem:\n\n - Toque em "Enable Autostart" (Ativar início automático) e permita "Galarm" para que seus alarmes sempre toquem. \n\nPara saber mais, visite www.galarmapp.com/setup-mi',
  oppoSetupInstructions:
    'Siga estas instruções para que seus alarmes sempre toquem:\n\n - Toque em "Enable Autostart" (Ativar inicialização automática) e ative "Allow Auto Startup" (Permitir inicialização automática).\n\n - Toque no botão "Recent Apps" (Aplicativos recentes) em seu telefone, deslize o ícone do aplicativo Galarm para baixo e toque em "Lock" (Bloquear).  \n\nPara saber mais, visite www.galarmapp.com/setup-oppo',
  oppo8AndLowerSetupInstructions:
    'Siga estas instruções para que seus alarmes sempre toquem:\n\n - Toque em "Enable Autostart" (Ativar inicialização automática) e ative "Allow Auto Startup" (Permitir inicialização automática).\n\n - Toque no botão "Recent Apps" (Aplicativos recentes) em seu telefone, deslize o ícone do aplicativo Galarm para baixo e toque em "Lock" (Bloquear).\n\nPara saber mais, visite www.galarmapp.com/setup-oppo-8-and-lower',
  vivo9And10SetupInstructions:
    'Siga estas instruções para que seus alarmes sempre toquem:\n\n - Toque em "Enable Autostart" (Ativar início automático) e permita "Galarm".\n\n - Toque em "Battery Usage" (Uso da bateria), selecione "High background power consumption" (Alto consumo de energia em segundo plano) e permita o "Galarm".\n\nPara saber mais, visite www.galarmapp.com/setup-vivo',
  vivo11SetupInstructions:
    'Siga estas instruções para que seus alarmes sempre toquem:\n\n- Toque em "Enable Autostart" (Ativar início automático) e permita "Galarm".\n\n- Toque em "Battery Usage" (Uso da bateria), selecione "High background power consumption" (Alto consumo de energia em segundo plano) e permita o "Galarm".\n\nPara saber mais, visite www.galarmapp.com/setup-vivo-11',
  vivoSetupInstructions:
    'Siga estas instruções para que seus alarmes sempre toquem:\n\n- Abra "Configurações do telefone > Aplicativos > Acesso a aplicativos especiais > Início automático" e ative o início automático para o Galarm.\n\n- Abra "Configurações do telefone > Bateria > Gerenciamento do consumo de energia em segundo plano > Galarm" e escolha "Não restringir o uso de energia em segundo plano".\n\nPara saber mais, acesse www.galarmapp.com/setup-vivo-12-and-above',
  vivo8AndLowerSetupInstructions:
    'Siga estas instruções para que seus alarmes sempre toquem:\n\n - Toque em "Enable Autostart" (Ativar início automático) e permita "Galarm".\n\n - Toque em "Battery Usage" (Uso da bateria), selecione "High background power consumption" (Alto consumo de energia em segundo plano) e permita o "Galarm".\n\nPara saber mais, visite www.galarmapp.com/setup-vivo-8-and-lower',
  huawei7AndLowerAdditionalSetupInstructions:
    'Siga estas instruções para que seus alarmes sempre toquem:\n\n - Toque em "Aplicativos protegidos" e permita o "Galarm".\n\nPara saber mais, visite www.galarmapp.com/setup-huawei-7-and-lower',
  huaweiAdditionalSetupInstructions:
    'Siga estas instruções para que seus alarmes sempre toquem:\n\n - Toque em "Gerenciar inicialização de aplicativos" e desative "Galarm" para "Gerenciar manualmente". No prompt a seguir, certifique-se de que "Auto Launch" e "Run in background" estejam ativados.\n\nPara saber mais, visite www.galarmapp.com/setup-huawei',
  meizuSetupInstructions:
    'Siga as instruções no link abaixo para que seus alarmes sempre toquem: https://dontkillmyapp.com/meizu',
  asusSetupInstructions:
    'Siga estas instruções para que seus alarmes sempre toquem:\n\n - Toque em "Enable Autostart" (Ativar início automático) e clique em "Auto-start manager" (Gerenciador de início automático). Ative o "Galarm" no "Auto-start manager".\n\nPara saber mais, visite www.galarmapp.com/setup-asus',
  nokia8AndLowerAdditionalSetupInstructions:
    'Siga estas instruções para que seus alarmes sempre toquem:\n\n - Toque em "Enable Autostart" (Ativar início automático) e permita "Galarm".\n\nPara saber mais, visite www.galarmapp.com/setup-nokia-8-and-lower',
  nokiaAdditionalSetupInstructions:
    'Siga estas instruções para que seus alarmes sempre toquem:\n\n - Toque em "Aplicativos restritos" e desative o "Galarm".\n\nPara saber mais, visite www.galarmapp.com/setup-nokia',
  lenovoSetupInstructions:
    'Siga estas instruções para que seus alarmes sempre toquem:\n\n - Toque no botão "Recent Apps" (Aplicativos recentes) em seu telefone, localize o aplicativo Galarm e toque no ícone "Lock" (Bloqueio) no canto superior direito.\n  \nPara saber mais, visite https://dontkillmyapp.com/lenovo',
  samsungAdditionalSetupInstructions:
    'Siga estas instruções para que seus alarmes sempre toquem:\n\n - Toque em "Aplicativos não monitorados" e permita o "Galarm".\n\nPara saber mais, visite www.galarmapp.com/setup-samsung',
  tecnoSetupInstructions:
    'Siga as instruções no link abaixo para que seus alarmes sempre toquem: https://www.galarmapp.com/setup-tecno',
  infinixSetupInstructions:
    'Siga as instruções no link abaixo para que seus alarmes sempre toquem: https://www.galarmapp.com/setup-infinix',
  samsungAdditionalSetupInstructionsAndroid11AndAbove:
    'Por padrão, a Samsung mostra notificações "Breves" que desaparecem rapidamente. Siga estas instruções para visualizar as notificações de alarme "Detalhadas".\n\n - Abra as configurações do telefone e escolha "Notifications" (Notificações)\n - Escolha o estilo de notificações "Detailed" (Detalhadas)\n  \nPara saber mais, visite www.galarmapp.com/setup-samsung-android11-and-above',
  iosSetupInstructions:
    'Por padrão, o estilo de banner do iOS para notificações é definido como "Temporário", o que faz com que as notificações desapareçam rapidamente. Siga estas instruções para definir o estilo do banner como "Persistent".\n\n- Abra as configurações do telefone e role até Galarm\n- Escolha Notifications (Notificações) e defina Banner Style (Estilo de banner) como Persistent (Persistente)\n\nPara saber mais, visite www.galarmapp.com/setup-ios',
  iosSetupInstructions15AndBelow:
    'Por padrão, o estilo de banner do iOS para notificações é definido como "Temporário", o que faz com que as notificações desapareçam rapidamente. Siga estas instruções para definir o estilo do banner como "Persistent".\n\n- Abra as configurações do telefone e role até Galarm\n- Escolha Notifications (Notificações) e defina Banner Style (Estilo de banner) como Persistent (Persistente)\n\nPara saber mais, visite www.galarmapp.com/setup-ios-15-below',
  setNotificationsStyle: 'Definir estilo das notificações',
  setNotificationsStyleToDetailed:
    'Definir o estilo das notificações como "Detailed" (Detalhado)',
  setBannerStyle: 'Definir estilo do banner',
  setBannerStyleToPersistent:
    'Definir o estilo do banner como "Persistent" (Persistente)',
  selectAtleastOneDay: 'Selecione os dias em que o alarme deve tocar.',
  selectAtLeastOneHourOrMinute:
    'As horas ou os minutos devem ser um valor diferente de zero',
  hourlyAlarmDefaultStartTimeSetAlert:
    'O horário de início do alarme foi definido como 8 AM.',
  batteryUsage: 'Uso da bateria',
  dontShowAgain: 'Não apareça novamente',
  alarmWillRingEveryHourTillSeen:
    'O alarme será adiado a cada hora até que você tome alguma providência.',
  unknown: 'Desconhecido',
  alarmMarkedDone: '{{name}} marcou o alarme como "Concluído".',
  alarmMarkedSkipped: '{{name}} não deu o alarme.',
  alarmIsMarkedDone: '{{alarmName}} é marcado como concluído.',
  alarmIsSkipped: '{{alarmName}} é ignorado.',
  alarmIsConfirmed: '{{alarmName}} é confirmada.',
  alarmIsDeclined: '{{alarmName}} é recusado.',
  alarmIsSnoozed: '{{alarmName}} é adiada.',
  alarmIsAccepted: '{{alarmName}} é aceito.',
  alarmIsRejected: '{{alarmName}} é rejeitado.',
  newContactInfo:
    'Forneça o nome e o número de telefone do contato.\n\nSomente usuários registrados podem ser adicionados como contatos. Se o usuário não for um usuário registrado do Galarm, você poderá convidá-lo usando "Settings > Share This App" (Configurações > Compartilhar este aplicativo). Você pode adicioná-lo como contato depois que ele se registrar usando o número de telefone dele. ',
  cantEditContactMobileNumber:
    'Não é possível editar o número de telefone. Exclua o contato e adicione-o novamente se quiser alterar o número.',
  phoneContactsNotSyncedPermissionDenied:
    'O Galarm não tem permissão para acessar os contatos do telefone. Os contatos telefônicos não serão sincronizados.',
  inviteContactMessage:
    'Olá {{contactName}}, quero incluí-lo em meus alarmes e lembretes usando o Galarm. Faça o download do aplicativo em {{downloadLink}}. Registre-se usando esse número e comece a compartilhar alarmes.',
  noRepeat: 'Não se repete',
  deepLinkNoLongerValidTitle: 'Ops!',
  deepLinkNoLongerValidMessage:
    'O alarme não foi criado. Pode ser um link antigo ou inválido.',
  inviteContact: 'Convidar contato',
  fromPhoneBook: 'Do catálogo de endereços',
  addFromPhoneBook: 'Convidar do catálogo de endereços',
  manually: 'Manualmente',
  addManualContact: 'Convidar manualmente',
  noOtherContactsFound:
    'Todos os contatos do seu catálogo de endereços estão usando o Galarm.',
  cantAddPhoneContactPermissionDenied:
    'O Galarm não tem permissão para acessar os contatos do telefone. Permita a permissão para acessar os contatos.',
  alarmWillRingWithRandomRingtone:
    'Um toque aleatório será escolhido para o alarme.',
  alarmWillRingWithRandomRingtoneGlobal:
    'Um toque aleatório será escolhido para os alarmes.',
  createdAlarmFromDeepLinkTitle: 'Está tudo pronto!',
  createdAlarmFromDeepLinkMessage:
    'Você será lembrado de "{{alarmName}}" em {{alarmTime}} em {{alarmDate}}',
  createdRepeatingAlarmFromDeepLinkMessage: {
    zero: 'Você se lembrará de "{{alarmName}}" {{repetitionString}}',
    one: 'Você será lembrado de "{{alarmName}}" {{repetitionString}} em {{alarmTime}} a partir de {{alarmDate}}'
  },
  deleteCategoryAlertTitle: 'Excluir categoria?',
  deleteCategoryAlertMessage: {
    zero: '',
    one: '1 alarme pertence a essa categoria. A exclusão dessa categoria redefinirá a categoria desse alarme.',
    other:
      '{{count}} Os alarmes pertencem a essa categoria. A exclusão dessa categoria redefinirá a categoria desses alarmes.'
  },
  updateReadyToInstallAlertTitle: 'Instalar a atualização?',
  updateReadyToInstallAlertMessage:
    'As atualizações estão prontas para serem instaladas. Deseja instalar a atualização agora? ',
  hideCategoryAlertTitle: 'Ocultar categoria?',
  hideCategoryAlertMessage: {
    zero: 'Você não poderá usar essa categoria no futuro.',
    one: '1 alarme pertence a essa categoria. A ocultação dessa categoria redefinirá a categoria desse alarme.\n  \nVocê não poderá usar essa categoria no futuro.',
    other:
      '{{count}} Os alarmes pertencem a essa categoria. A ocultação dessa categoria redefinirá a categoria desses alarmes.\n  \nVocê não poderá usar essa categoria no futuro.'
  },
  alarmCategoryDeleteAllAlarmsPrompt: {
    one: '1 alarme pertence a essa categoria. Deseja excluí-lo?\n\nObserve que os alarmes dos participantes não são excluídos.',
    other:
      '{{count}} Os alarmes pertencem a essa categoria. Deseja excluí-los?\n\nObserve que os alarmes de participantes não são excluídos.'
  },
  enterNameForGroup: 'Digite um nome para o grupo',
  enterMobileNumber: 'Digite seu número de telefone',
  enterNotesForAlarm: 'Inserir notas para o alarme',
  enterUserName: 'Digite seu nome',
  enterEmailId: 'Opcional',
  enterFeedback: 'Como podemos melhorar?',
  describeProblem: 'Descreva seu problema',
  invited: 'Convidado',
  resend: 'Reenviar',
  addContact: 'Adicionar contato',
  inviteAgain: 'Convidar novamente',
  contactNotRegistered:
    '{{contactName}} ainda não instalou o Galarm. Você poderá adicioná-lo aos alarmes assim que ele instalar e se registrar usando seu número de telefone.',
  registration: 'Registro',
  verify: 'Verificação',
  profile: 'Perfil',
  profileImage: 'Imagem do perfil',
  addProfileImage: 'Adicionar imagem de perfil',
  groupDetails: 'Detalhes do grupo',
  newGroup: 'Novo grupo',
  uploadImage: 'Alterar imagem',
  removeImage: 'Remover imagem',
  selectParticipants: 'Selecionar participantes',
  selectMembers: 'Selecionar membros',
  selectTimezone: 'Selecione o fuso horário',
  searchForATimezone: 'Pesquisar um fuso horário',
  timezone: 'Fuso horário',
  deviceTimezone: 'Fuso horário do dispositivo',
  specifyTimezoneForAlarm: 'Especificar o fuso horário para alarmes',
  editName: 'Editar nome',
  groupImage: 'Imagem de grupo',
  addGroupImage: 'Adicionar imagem do grupo',
  selectAlarmType: 'Criar um alarme para',
  contactDetails: 'Detalhes de contato',
  commonAlarms: 'Alarmes',
  commonGroups: 'Grupos',
  groupAlarmsTitle: 'Alarmes',
  selectRingtone: 'Selecione Ringtone',
  selectMusic: 'Selecionar música',
  select: 'Selecione',
  personalAlarm: 'Alarme pessoal',
  groupAlarm: 'Alarme de grupo',
  newPersonalAlarm: 'Novo alarme pessoal',
  newGroupAlarm: 'Novo alarme de grupo',
  editPersonalAlarm: 'Editar alarme pessoal',
  editGroupAlarm: 'Editar alarme de grupo',
  rearrangeParticipants: 'Reorganizar os participantes',
  alarmConversation: 'Chat de alarme',
  timezoneMismatchDetected:
    'Algo nos diz que você está em um fuso horário diferente daquele da última vez que usou o Galarm.\n\nReinicie o Galarm para que possamos nos adaptar ao novo fuso horário.',
  deviceRestartRequired: 'Necessidade de reiniciar o aplicativo',
  disabledAlarm: 'Alarme desativado {{name}}',
  newContact: 'Novo contato',
  contactName: 'Nome do contato',
  contactMobileNumber: 'Número de telefone para contato',
  enterNameForContact: 'Digite o nome do contato',
  pickCountryForContact: 'País do contato',
  enterMobileNumberForContact: 'Insira o número de telefone do contato',
  specifyNameForContact: 'O nome do contato é obrigatório',
  specifyValidPhoneNumberForContact:
    'É necessário um número de telefone válido',
  addingContact: 'Adicionar contato',
  deletingContact: 'Remoção de contato',
  editingContact: 'Contato de edição',
  pending: 'Pendente',
  deleteContact: 'Remover contato',
  galarmContact: 'Adicionado manualmente',
  phoneContact: 'Adicionado dos contatos do telefone',
  confirmDeleteContactTitle: 'Remover {{name}}?',
  confirmDeleteContact: 'Você não poderá adicioná-los aos seus alarmes.',
  contactAdded:
    'Excelente! Agora você pode adicionar {{name}} como participante de seus alarmes.',
  contactInvited:
    'Uma mensagem de texto foi enviada para {{name}} convidando-os a usar o Galarm.',
  changeSnoozeDuration: 'Mudança Duração',
  durationOfEvent: 'Duração do evento',
  upgradeToPremium: 'Upgrade para Premium',
  premium: 'Premium',
  alarmLimitReached: 'Limite de alarme atingido',
  unableToConnectToGooglePlay:
    'Não é possível conectar-se ao Google Play. Verifique se você está conectado à sua conta do Google.',
  cantLoadVideo: 'Não é possível carregar o vídeo',
  cantUpgradeToPremium: 'Não é possível fazer upgrade para o Premium',
  unableToConnectToIapAndroid:
    'Não foi possível conectar-se à Play Store. Abra o aplicativo da Play Store e verifique se você está conectado.',
  unableToConnectToIapIos:
    'Não foi possível conectar-se à App Store. Abra o aplicativo da App Store e verifique se você está conectado.',
  premiumFeaturesIos:
    'Como usuário premium, você obtém um limite maior de {{maxLimit}} alarmes simultâneos.\n\nSua compra também nos ajuda a desenvolver mais recursos.',
  premiumFeaturesAndroid:
    'Crie alarmes ilimitados fazendo o upgrade para a versão premium.\n\nSua compra também nos ajuda a desenvolver mais recursos.',
  upgrade: 'Atualização',
  rvInternalError:
    'Não foi possível carregar o vídeo devido a um erro interno. No entanto, aumentamos seu limite de alarmes em 1.',
  rvNetworkError:
    'Verifique se você tem uma boa conexão com a Internet e tente novamente.',
  rvNotFoundError:
    'Não foi possível encontrar um vídeo adequado no momento. No entanto, aumentamos seu limite de alarmes em 1.',
  dontRemindMe: 'Não me lembre disso',
  sameTimeAsBuddy: 'Na mesma época que o amigo',
  noAlertForBuddyAlarm: 'Você optou por não ser alertado sobre esse alarme.',
  noAlertRecipientIntervalDescription: 'Você não será alertado.',
  chooseHours: 'Escolha as horas',
  chooseMins: 'Escolha os minutos',
  changeHours: 'Alterar horas',
  moreOptions: 'Mais opções...',
  set: 'Conjunto',
  chooseNumberOfDays: 'Insira o número de dias',
  chooseNumberOfWeeks: 'Insira o número de semanas',
  numberOfDaysShouldBeAtLeastOne: 'O número de dias deve ser de pelo menos 1',
  numberOfWeeksShouldBeAtLeastOne:
    'O número de semanas deve ser de pelo menos 1',
  numberOfMonthsShouldBeAtLeastOne:
    'O número de meses deve ser de pelo menos 1',
  numberOfYearsShouldBeAtLeastOne: 'O número de anos deve ser de pelo menos 1',
  frequency: 'Frequência',
  configuration: 'Configuração',
  endDate: 'Data de término do alarme',
  noEndDate: 'Sem data de término',
  alarmEndDateSetOnAlarm:
    'A data final do alarme está definida como {{endDateString}}.',
  noAlarmDateSetForAlarm: 'Nenhuma data final é definida para esse alarme.',
  noOccurrenceForThisAlarmAsPerEndDate:
    'A data final não permite nenhuma ocorrência para o alarme.',
  alarmDateShouldBeBiggerThanAlarmStartDate:
    'A data de término do alarme deve ser posterior à data de início do alarme.',
  aWeek: 'Uma semana',
  aMonth: 'Um mês',
  allInactiveAlarms: 'Todos os alarmes inativos',
  chooseAlarmEndDate: 'Selecionar a data final do alarme',
  next: 'Próximo',
  next7Days: '7 dias a partir de hoje',
  next30Days: '30 dias a partir de hoje',
  next12Months: '12 meses a partir de hoje',
  next10Years: '10 anos a partir de hoje',
  chooseDate: 'Escolha uma data...',
  learnMore: 'Saiba mais',
  repeatEvery: 'Repete a cada',
  repeatEveryDays: 'dia(s)',
  repeatEveryWeeks: 'semana(s)',
  repeatEveryMonths: 'mês(es)',
  repeatEveryYears: 'ano(s)',
  autoSnooze: 'Alarmes de soneca automaticamente',
  autoSnoozePerAlarm: 'Alarme soneca automaticamente',
  unableToUploadGroupAvatar:
    'Não é possível carregar a imagem do grupo agora. Tente novamente mais tarde',
  addNewMembersToExistingAlarms:
    'Você tem os seguintes alarmes com esse grupo:\n\n{{alarmNames}}\n    \nDeseja adicionar os novos membros a esses alarmes?',
  removeGroupMemberAlert:
    'A remoção do membro afetará os alarmes a seguir:\n\n{{alarmNames}}\n\nVocê ainda deseja remover o membro?',
  leaveGroupAlert:
    'A saída do grupo afetará os alarmes a seguir:\n\n{{alarmNames}}\n\nVocê ainda deseja sair do grupo?',
  updateAppAfterFeedback:
    'Percebemos que você está executando uma versão mais antiga do aplicativo. Atualize-a para ter uma experiência melhor.',
  changesAreLost:
    'Você tem alterações não salvas.\n\nDeseja salvar antes de sair?',
  recipientAlarmIntervalGreaterThanRepetitionInterval:
    'O intervalo de "lembre-me" deve ser menor do que o intervalo de repetição.',
  cascadingAlarmIntervalGreaterThanRepetitionInterval:
    'O intervalo "Tempo entre alertas de participantes" deve ser menor do que o intervalo de repetição.',
  subscriptionCanceled:
    'Sua assinatura premium foi cancelada. Os recursos premium não estarão disponíveis até que você renove a assinatura.',
  unableToRenewSubscription:
    'Houve um problema ao renovar sua assinatura premium. Certifique-se de que suas informações de pagamento estejam atualizadas.',
  notice: 'Aviso',
  ringerSettingsWillApplyToNewAlarms:
    'As alterações feitas nas configurações de som e vibração serão aplicadas somente aos novos alarmes.\n\nPara alarmes existentes, você pode editar o alarme para alterar suas configurações de som e vibração.',
  renew: 'Renovar',
  galarmPremium: 'Galarm Premium',
  payMonthly: 'Pagamento mensal em {{price}} por mês',
  payYearly: 'Pagamento anual em {{price}} por ano',
  free: 'Grátis',
  unlimited: 'Ilimitado',
  personalAlarms: 'Alarmes pessoais',
  groupAlarms: 'Alarmes de grupo',
  buddyAlarms: 'Alarmes Buddy',
  alarmRepetitions: 'Repetições',
  ringtones: 'Toques de celular',
  alarmHistory: 'Histórico de alarmes',
  base: 'Básico',
  advanced: 'Avançado',
  complete: 'Completo',
  alarmHistoryFree: 'Limitada',
  phoneMusicAsRingtone: 'Música do telefone como toque',
  startFreeTrial: 'Iniciar teste gratuito de 14 dias',
  chargedAtEndOfFreeTrial:
    'Você será cobrado após o término da avaliação gratuita de 14 dias, a menos que cancele sua assinatura.',
  monthlySubscription: 'Mensal',
  yearlySubscription: 'Anual',
  premiumRingtoneRequiresUpgrade:
    'Esse toque de celular requer assinatura Premium.',
  defaultPremiumFeaturesDescription:
    'Faça upgrade para Premium para ativar esses recursos interessantes.',
  groupAlarmsPremiumFeaturesDescription:
    'Você precisa de uma assinatura premium para criar mais de um alarme de grupo.',
  buddyAlarmsPremiumFeaturesDescription:
    'Você precisa de uma assinatura premium para criar mais de um alarme para outra pessoa ("amigo").',
  shareableAlarmLinksPremiumFeaturesDescription:
    'Você precisa de uma assinatura premium para criar mais links de alarme compartilháveis.',
  viewPremiumFeaturesDescription:
    'Obrigado por ser um usuário premium. Você pode aproveitar esses recursos interessantes como assinante premium.',
  accessToPremiumFeatures:
    'Você terá acesso a esses recursos como parte da assinatura:',
  alarmCategoryPremiumFeaturesDescription:
    'Você precisa de uma assinatura premium para criar novas categorias.',
  galarmWebPremiumFeaturesDescription:
    'É necessário ter uma assinatura premium para usar o Galarm em um navegador da Web.',
  instantAlarmPremiumFeaturesDescription:
    'Você precisa de uma assinatura premium para criar mais alarmes instantâneos.',
  personalAlarmsFeatureDescription:
    'Use um alarme pessoal para acordar, tomar remédios ou qualquer outra tarefa para si mesmo. Opcionalmente, você pode adicionar participantes que serão alertados se você perder um alarme, para que eles possam lembrá-lo. Os participantes também são notificados quando você marca o alarme como concluído.',
  groupAlarmsFeatureDescription:
    'Use um alarme de grupo para atividades em grupo, como passeios, festas ou qualquer outro evento. O alarme dispara ao mesmo tempo para todos os participantes e eles podem confirmar sua participação.',
  buddyAlarmsFeatureDescription:
    'Crie alarmes para outra pessoa ("amigo") para lembrá-la de coisas que ela precisa fazer. Você é alertado para lembrar o colega, caso ele perca o alarme. Você também será notificado quando o colega marcar o alarme como concluído.',
  unlimitedAlarmsFeatureDescription:
    'Crie mais alarmes de grupo, de amigos e instantâneos.',
  alarmRepetitionsFeatureDescription:
    'Crie alarmes com repetições avançadas, como a cada 30 minutos, a cada 2 horas e 45 minutos, a cada dois dias, quinzenalmente e muito mais.',
  ringtonesFeatureDescription:
    'Escolha entre uma ampla variedade de toques premium para seus alarmes. Estamos continuamente adicionando mais toques à oferta premium.',
  phoneMusicAsRingtoneFeatureDescription:
    'Escolha qualquer canção/música em seu telefone como toque de alarme.',
  shareableAlarmLinksFeatureDescription:
    'Você pode compartilhar alarmes com outras pessoas usando um link.\n\nPor exemplo, um professor pode compartilhar o link do alarme da turma com os alunos usando e-mail, WhatsApp ou qualquer aplicativo de mensagens. Quando os alunos tocarem no link, uma cópia do alarme da turma será criada em seus telefones. Todos os alunos serão alertados no horário especificado para participar da aula.',
  instantAlarmFeatureDescription:
    'Alerte instantaneamente um grupo de pessoas com o clique de um botão.\n\nUse esse alarme para alertá-las sobre um exercício de emergência, o início de um jogo, uma tarefa/reunião urgente e outros eventos que exijam atenção imediata.',
  alarmHistoryFeatureDescription:
    'Visualizar todas as respostas anteriores para cada alarme repetitivo. Isso permite que você descubra facilmente quando perdeu a aula de ginástica, quando sua mãe perdeu a medicação, etc.',
  announceAlarmTitleFeatureDescription:
    'Anuncie o título do alarme quando o alarme tocar.',
  exportToCalendarPremiumFeaturesDescription:
    'Você precisa de uma assinatura premium para exportar mais alarmes para o calendário.',
  exportToCalendarFeatureDescription:
    'Você pode exportar alarmes para o seu calendário. Isso permite que você visualize facilmente os alarmes no calendário do dispositivo e reflita melhor sua disponibilidade.',
  galarmWebFeatureDescription:
    'Use o Galarm em um navegador da Web para criar, editar e excluir alarmes.',
  preReminderFeatureDescription:
    'Defina pré-lembretes para ser alertado sobre os próximos alarmes. Você pode configurar a duração relativa para ser alertado antes da hora real do alarme, como 30 minutos antes, 1 dia antes, etc.',
  calendarViewFeatureDescription:
    'Visualize os alarmes em um calendário para ver facilmente os alarmes de qualquer dia.',
  updatePhoneNumberFeatureDescription:
    'Você pode alterar o número de telefone usado para essa conta. Todos os seus alarmes, contatos, grupos e outras configurações serão migrados para o novo número de telefone.',
  alarmPhotoFeatureDescription:
    'Adicione uma foto a um alarme para obter dicas visuais rápidas, como a foto de: um medicamento, instruções para uma tarefa, um item etc. A foto será exibida quando o alarme tocar.',
  taskListFeatureDescription:
    'Crie listas de tarefas para manter as tarefas relacionadas juntas.',
  calendarViewPremiumFeature: 'Visualização do calendário',
  updatePhoneNumberPremiumFeature: 'Alterar número de telefone',
  alarmNotFound: 'Alarme não encontrado',
  alarmNotFoundMessage:
    'Esse alarme pode ter sido excluído recentemente. Toque em "Remover" para desativar outras notificações para esse alarme.',
  alarmExistsWithDeepLinkTitle: 'Alarme duplicado',
  alarmExistsWithDeepLinkMessage:
    'Um alarme criado usando esse link já existe em seu telefone.',
  alarmDoesntHaveNextDate: 'O alarme não tem uma próxima data válida.',
  sharePersonalAlarmDeepLinkDescription:
    'Toque neste link para criar um alarme para esse evento em {{dateString}}.',
  joinGroupAlarmDeepLinkDescription:
    'Toque neste link para participar desse evento em grupo em {{dateString}}',
  subscribedAlarmEdited:
    '{{alarmCreatorName}} foi atualizado {{alarmName}}. Deseja atualizar seu alarme?',
  subscribedAlarmDeleted:
    '{{alarmCreatorName}} foi excluído {{alarmName}}. Deseja excluir seu alarme?',
  responseReceivedForTicket:
    'Respondemos ao seu tíquete nº{{ticketId}} em: {{email}}. Se ele não estiver em sua caixa de entrada, verifique se há um e-mail de support@galarm.zendesk.com em suas pastas de spam.',
  newTicketReceived:
    'Obrigado por escrever para nós. Criamos o tíquete nº{{ticketId}} para acompanhar sua solicitação.\n\nResponderemos no endereço de e-mail que você forneceu: {{email}}. Se isso não estiver correto, envie seu feedback novamente com o endereço de e-mail correto.\n\nAdicione também support@galarm.zendesk.com ao seu catálogo de endereços de e-mail para garantir a entrega adequada de nossas respostas. Também enviaremos uma notificação aqui quando tivermos respondido à sua solicitação.',
  sentAccountBackup:
    'Enviamos seus dados de alarme para {{email}}. Se não estiverem em sua caixa de entrada, verifique se há um e-mail de noreply@acintyo.com em suas pastas de spam.',
  alarmDoesntExist: 'O alarme não existe.',
  shareAlarm: 'Compartilhar link de alarme',
  shareAlarmDescription:
    'Você pode compartilhar este alarme com qualquer pessoa usando um link, por exemplo, um professor pode compartilhar um link para lembrar os alunos de ingressar em uma aula. Quando alguém toca no link, uma cópia do alarme é criada no telefone.',
  shareLink: 'Compartilhar link',
  joinedGroupAlarmFromDeepLinkMessage:
    'Você será lembrado de "{{alarmName}}" em {{alarmTime}} em {{alarmDate}}',
  joinedRepeatingGroupAlarmFromDeepLinkMessage: {
    zero: 'Você se lembrará de "{{alarmName}}" {{repetitionString}}',
    one: 'Você será lembrado de "{{alarmName}}" {{repetitionString}} em {{alarmTime}} a partir de {{alarmDate}}'
  },
  youHaveSubscribedToThisAlarm:
    'Você está seguindo este alarme de {{creatorName}}.',
  alreadySharedAlarmDescription: {
    zero: 'Você não tem nenhum seguidor para esse alarme.\n\nVocê pode continuar a compartilhar esse alarme com mais pessoas usando um link. Quando elas tocarem no link, uma cópia desse alarme será criada no telefone delas.',
    one: '{{count}} pessoa está seguindo esse alarme.\n\nVocê pode continuar a compartilhar esse alarme com mais pessoas usando um link. Quando elas tocarem no link, uma cópia desse alarme será criada no telefone delas.',
    other:
      '{{count}} pessoas estão seguindo esse alarme.\n\nVocê pode continuar a compartilhar esse alarme com mais pessoas usando um link. Quando elas tocarem no link, uma cópia desse alarme será criada no telefone delas.'
  },
  shareParentAlarmDescription:
    'Você está seguindo este alarme de {{creatorName}}.\n\nVocê também pode compartilhar esse alarme com outras pessoas usando um link. Quando elas tocam no link, uma cópia desse alarme é criada em seus telefones e elas também podem começar a segui-lo.',
  shareableAlarmLinks: 'Links compartilháveis',
  nPeopleFollowingAlarm: {
    zero: 'Você não tem nenhum seguidor para esse alarme.',
    one: '{{count}} pessoa está seguindo esse alarme.',
    other: '{{count}} pessoas estão seguindo esse alarme.'
  },
  likeUsOnFacebook: 'Facebook',
  followUsOnTwitter: 'Twitter',
  followUsOnInstagram: 'Instagram',
  youtube: 'YouTube',
  mediumBlog: 'Blogs no Medium',
  howtoVideos: 'Vídeos de instruções',
  followGalarmOnSocialMedia: 'Siga a Galarm nas mídias sociais',
  galarmOnSocialMedia: 'Galarm nas mídias sociais',
  followGalarmOnSocialMediaDescription:
    'Veja notícias interessantes sobre atualizações de aplicativos, uso de recursos, recursos futuros, bem como as principais análises de aplicativos. Você também pode interagir com a equipe do Galarm.',
  sharedAlarm: 'Editar alarme compartilhado',
  editingPublishedAlarm: {
    one: 'Você está editando um alarme que compartilhou anteriormente usando um link. {{count}} pessoa está seguindo esse alarme no momento.\n\nQuando você fizer qualquer edição nesse alarme, ela será notificada sobre as alterações.',
    other:
      'Você está editando um alarme que compartilhou anteriormente usando um link. {{count}} pessoas estão seguindo esse alarme no momento.\n\nQuando você fizer alguma edição nesse alarme, elas serão notificadas sobre as alterações.'
  },
  editingSubscribedAlarm:
    'Você está acompanhando esse alarme em {{creatorName}}. Você não receberá nenhuma atualização para esse alarme depois de editá-lo.',
  viewAlarm: 'Exibir alarme',
  view: 'Ver',
  editAlarm: 'Editar alarme',
  thanksForTryingPremiumTitle: 'Está tudo pronto!',
  thanksForTryingPremiumMessage:
    'Esperamos que você aproveite os recursos premium.\n\nEntre em contato com o suporte usando "Settings > Feedback" ou envie-nos um e-mail para galarm@acintyo.com se tiver alguma dúvida.',
  subscribedAlarmEditedInAlarmDetails:
    '{{creatorName}} alterou seu alarme.\nAtualizou seu alarme?',
  subscribedAlarmDeletedInAlarmDetails:
    '{{creatorName}} excluiu seu alarme.\nExcluir seu alarme?',
  updateSubscribedAlarm: 'Sim, atualizar',
  deleteSubscribedAlarm: 'Sim, excluir',
  ignoreSubscribedAlarm: 'Não, ignorar',
  customRepetitionPremiumScreenDescription:
    'A repetição personalizada é um recurso premium.\n\nVocê pode usar esse recurso para criar alarmes com repetições avançadas. Os exemplos incluem:\n',
  repeatAlternateDate: 'Repetir em dias alternados',
  repeatEveryNDay: 'Repetir a cada 3 dias',
  repeatEveryNHoursAndMMinutes: 'Repetir a cada 2 horas e 30 minutos',
  repeatEveryNMinutes: 'Repetir a cada 45 minutos',
  repeatBiweekly: 'Repetir a cada 2 semanas',
  repeatEveryNWeeks: 'Repetir a cada 6 semanas',
  repeatEveryNMonths: 'Repetir a cada 3 meses (trimestralmente)',
  repeatEveryLastDayOfMonth: 'Repetir o último dia de cada mês',
  repeatDayOfWeekOfMonth: 'Repetição no segundo sábado de cada mês',
  repeatLastDayOfWeekOfMonth: 'Repetir na última sexta-feira de cada mês',
  customRepetition: 'Repetição personalizada',
  lastDayOfMonth: 'Último dia de cada mês',
  dayOfWeekInMonth: '{{weekNumberOfDayInMonth}} {{dayName}} de cada mês',
  lastDayOfWeekInMonth: 'Último {{dayName}} de cada mês',
  dayOfWeekInMonthInYearSummary:
    '{{weekNumberOfDayInMonth}} {{dayName}} de {{monthName}}',
  lastDayOfWeekInMonthInYearSummary: 'Último {{dayName}} de {{monthName}}',
  lastDayOfMonthInYearSummary: 'Último dia de {{monthName}}',
  dayOfWeekInMonthInYear:
    '{{weekNumberOfDayInMonth}} {{dayName}} de {{monthName}} todo ano',
  lastDayOfWeekInMonthInYear:
    'Último {{dayName}} de {{monthName}} todos os anos',
  lastDayOfMonthInYear: 'Último dia do {{monthName}} todos os anos',
  chooseAMonthsConfiguration: 'Escolha uma configuração.',
  chooseADaysConfiguration: 'Escolha uma configuração',
  chooseAYearsConfiguration: 'Escolha uma configuração.',
  sendCodeAgain: 'Enviar código novamente',
  didntGetCodeNewUser:
    'Não recebeu o código?\n\nVocê pode solicitar o código novamente ou ignorar o registro por enquanto. Você pode se registrar mais tarde.',
  didntGetCodeExistingUser:
    'Não recebeu o código?\n\nVocê pode solicitar o código novamente ou cancelar o registro. Você pode se registrar mais tarde.',
  didntGetCodeUpdatePhoneNumber:
    'Não recebeu o código?\n\nVocê pode solicitar o código novamente ou tentar mais tarde.',
  alreadySentCodeAgain:
    'Enviamos novamente o código para você. Se estiver tendo dificuldades para obter o código, ignore o registro por enquanto e registre-se mais tarde.',
  theme: 'Tema',
  announceAlarmName: 'Anúncio do título do alarme',
  ttsHelperText:
    'O título do alarme será anunciado quando o alarme tocar. Você pode tocar no botão de reprodução na frente do título do alarme para ouvir uma prévia.',
  initializingTts: 'Inicialização da conversão de texto em fala',
  errorSpeakingAlarmName:
    'Não é possível falar o título do alarme. Entre em contato com o suporte em "Configurações > Feedback".',
  entityAlarm: 'alarme',
  entityGroup: 'grupo',
  entityContact: 'contato',
  admin: 'Administrador',
  specifyAlarmTitleToSpeak: 'Especificar o título do alarme para falar',
  wakeup: 'Acorde',
  ttsPremiumScreenDescription:
    'O anúncio do título do alarme é um recurso premium.\n\nQuando ativado, o título do alarme será anunciado quando o alarme tocar.\n\nPara visualizar, forneça um título de alarme e toque em reproduzir.',
  unknownAlarmCategory: 'Categoria de alarme desconhecida',
  cantAnnounceAlarmTitle: 'O título do alarme não será anunciado',
  ringtoneDurationIsSmall:
    'A duração do toque deve ser de pelo menos 5 segundos para que o título do alarme seja anunciado.\n\nDeseja continuar com essas configurações?',
  noActionItems: 'Não há itens de ação.',
  personNotInContacts: 'Essa pessoa não está nos seus contatos do Galarm.',
  light: 'Luz',
  dark: 'Escuro',
  systemDefault: 'Usar a configuração do dispositivo',
  chooseTheme: 'Escolha o tema',
  dontSeeAllYourContacts: 'Não está vendo todos os seus contatos?',
  lastContactsSyncedAt: 'Última sincronização em {{dateString}}',
  refresh: 'Atualizar',
  forever: 'Para sempre',
  autoSnoozeConfiguration: 'Configurar o Snooze',
  autoSnoozeConfigurationSummary:
    '{{autoSnoozeDurationLabel}}, {{autoSnoozeCountLabel}}',
  uncategorizedCategoryName: 'Não categorizado',
  uncategorizedCategoryScreenTitle: 'Sem categoria',
  setAlarmCategory: 'Definir categoria do alarme',
  changeAlarmCategory: 'Alterar a categoria do alarme',
  viewAlarmHistory: 'Exibir histórico de alarmes',
  computingAlarmHistory: 'Resumo de computação do histórico de alarmes...',
  alarmByCategories: '{{name}} Alarmes',
  alarmCategories: 'Categorias',
  editAlarmCategory: 'Editar categoria',
  deleteAlarmCategory: 'Excluir categoria',
  newAlarmCategoryTitle: 'Nova categoria',
  newAlarmCategoryNamePlaceholder: 'Especificar um nome',
  editAlarmCategoryTitle: 'Editar categoria',
  alarmCategorySummary: {
    zero: '{{alarmsSummary}}',
    one: '{{alarmsSummary}}, {{count}} não foi atendido',
    other: '{{alarmsSummary}}, {{count}} não foi atendido'
  },
  numAlarms: {
    zero: 'Sem alarmes',
    one: '{{count}} alarme',
    other: '{{count}} alarmes'
  },
  numIncidents: {
    zero: 'Nenhum incidente',
    one: '{{count}} incidente',
    other: '{{count}} incidentes'
  },
  openedIncidents: 'Incidentes abertos',
  closedIncidents: 'Incidentes encerrados',
  incidentChart: 'Gráfico de incidentes (últimos 30 dias)',
  incidentCalendar: 'Calendário de incidentes',
  incidentDistribution: 'Distribuição de incidentes (últimos 30 dias)',
  noIncidentsOpenedClosedInLast30Days:
    'Nenhum incidente aberto ou fechado nos últimos 30 dias',
  noIncidentsOpenedInLast30Days: 'Nenhum incidente aberto nos últimos 30 dias',
  color: 'Cor',
  createNewAlarmCategory:
    'Crie uma nova categoria tocando no botão + no canto inferior direito',
  setValidName: 'Definir um nome válido',
  specifyTimezoneForAlarmMessage:
    'Você pode especificar um fuso horário ao criar alarmes. Os alarmes tocarão de acordo com o fuso horário especificado. \n\nSe você escolher "Device time zone" (Fuso horário do dispositivo), os alarmes tocarão de acordo com o fuso horário do dispositivo.',
  alarmCategoryFeatureDescription:
    'Crie suas próprias categorias para alarmes. As categorias permitem que você organize facilmente seus alarmes.',
  markAlarmDoneInAdvance: 'Marcar o alarme "Concluído" antecipadamente?',
  skipAlarmInAdvance: '"Pular" o alarme mais cedo?',
  markAlarmDoneInAdvanceMessageRepeatingAlarm:
    'O alarme não tocará em {{dateTimeString}} se você marcar "Done" agora.',
  skipAlarmInAdvanceMessageRepeatingAlarm:
    'O alarme não tocará em {{dateTimeString}} se você o ignorar agora.',
  markAlarmDoneInAdvanceMessageOneTimeAlarm:
    'O alarme não tocará em {{dateTimeString}} se você marcar "Done" agora.',
  skipAlarmInAdvanceMessageOneTimeAlarm:
    'O alarme não tocará em {{dateTimeString}} se você o ignorar agora.',
  alarmsByCategories: 'Alarmes por categorias',
  workAlarmCategory: 'Trabalho',
  homeAlarmCategory: 'Início',
  birthdaysAlarmCategory: 'Aniversários',
  more: 'Mais informações',
  alarmCategory: 'Categoria de alarme',
  editAlarmCategoryColor: 'Editar cor',
  editAlarmCategoryColorTitle: 'Editar cor para {{name}}',
  allUserCategoriesDeleted:
    'Todas as categorias de alarme criadas por você foram removidas porque a assinatura premium foi cancelada. Os alarmes nessas categorias aparecerão em "Not Categorized" (Não categorizado).',
  calendar: 'Calendário',
  eventExportedFromGalarm:
    '"Exportado da Galarm em {{dateString}} em {{timeString}}."',
  unableToExportEventToCalendar:
    'Não é possível exportar o evento para o calendário. Certifique-se de que tenha dado permissão para acessar o calendário no dispositivo. Envie-nos um feedback usando "Configurações > Feedback" se o problema persistir.',
  scanQrCode:
    'Para acessar o Galarm em um navegador, vá para web.galarm.app e escaneie o código QR.',
  logOut: 'Sair do sistema',
  useHere: 'Use aqui',
  appRunningElsewhere:
    'Você foi desconectado desta sessão. Gostaria de retomar esta sessão?',
  dateTime: 'Data e hora',
  selectAtLeastOneDayForRepetition:
    'Escolha pelo menos um dia em que o alarme deve se repetir.',
  chooseNumberOfDaysForAlarmRepetition:
    'Insira o número de dias após os quais o alarme deve se repetir.',
  chooseNumberOfWeeksForAlarmRepetition:
    'Insira o número de semanas após as quais o alarme deve se repetir.',
  chooseNumberOfMonthsForAlarmRepetition:
    'Insira o número de meses após os quais o alarme deve se repetir.',
  chooseNumberOfYearsForAlarmRepetition:
    'Insira o número de anos após os quais o alarme deve se repetir.',
  previouslySelectedContactsAreUnselected:
    'Os contatos selecionados anteriormente são desmarcados.',
  previouslySelectedGroupIsUnselected:
    'O grupo selecionado anteriormente é desmarcado.',
  galarmWebInstructions:
    '1. Abra o aplicativo Galarm em seu telefone\n2. Vá para a guia "Settings" (Configurações) e toque em "Galarm Web"\n3. Verifique se você está executando o aplicativo mais recente se não vir a opção\n4. Leia o código QR à direita',
  likeToCreateAlarm: 'Crie um alarme para...',
  payUtilityBills: 'Pagar contas',
  wakeupAlarmName: 'Acorde',
  exerciseAlarmName: 'Exercício',
  drinkWaterAlarmName: 'Beba água',
  everyNHoursRepetitionStringShortSummary: {
    one: '{{startTimeString}} para {{endTimeString}} A cada hora',
    other: '{{startTimeString}} para {{endTimeString}} A cada {{count}} horas'
  },
  firstTimeManagementQuote:
    '"A má notícia é que o tempo voa. A boa notícia é que você é o piloto."',
  firstTimeManagementQuoteAuthor: 'Michael Altshuler',
  secondTimeManagementQuote:
    '"Todo gerenciamento de tempo começa com o planejamento."',
  secondTimeManagementQuoteAuthor: 'Tom Greening',
  thirdTimeManagementQuote:
    '"Nunca deixe para amanhã o que você pode fazer hoje."',
  thirdTimeManagementQuoteAuthor: 'Benjamin Franklin',
  fourthTimeManagementQuote:
    '"Tempo é o que mais queremos, mas o que mais usamos."',
  fourthTimeManagementQuoteAuthor: 'William Penn',
  fifthTimeManagementQuote:
    '"Daqui a um ano, você pode desejar ter começado hoje."',
  fifthTimeManagementQuoteAuthor: 'Karen Lamb',
  sixthTimeManagementQuote: '"Você pode atrasar, mas o tempo não o fará."',
  sixthTimeManagementQuoteAuthor: 'Benjamin Franklin',
  tapToGetStarted: 'Toque em "+" para começar.',
  allCategories: 'Todas as categorias',
  createAlarmWithContact: 'Criar um alarme com {{name}}',
  createAlarmWithGroup: 'Criar um alarme com {{name}}',
  editGroup: 'Editar grupo',
  discardChanges: 'As alterações atuais serão descartadas. Continuar?',
  alarmWillRingEveryNDays: {
    zero: 'O alarme tocará todos os dias.',
    one: 'O alarme tocará em dias alternados.',
    other: 'O alarme tocará a cada {{dayWithOrdinal}} dia.'
  },
  hoursString: { zero: '', one: '1 hora', other: '{{count}} horas' },
  minutesString: { zero: '', one: '1 minuto', other: '{{count}} minutos' },
  alarmWillRingEveryMHoursAndNMinutes:
    'O alarme tocará após cada {{repeatString}}, começando por {{dayString}}. Depois disso, {{otherDaysString}} e assim por diante.',
  alarmWillRingEveryNWeeks: {
    zero: 'O alarme tocará toda semana.',
    one: 'O alarme tocará a cada duas semanas.',
    other: 'O alarme tocará a cada {{weekWithOrdinal}} semana.'
  },
  alarmWillRingEveryNMonths: {
    zero: 'O alarme tocará todo mês.',
    one: 'O alarme tocará a cada dois meses.',
    other: 'O alarme tocará a cada {{monthWithOrdinal}} mês.'
  },
  alarmWillRingEveryNYears: {
    zero: 'O alarme tocará todos os anos.',
    one: 'O alarme tocará a cada dois anos.',
    other: 'O alarme tocará a cada {{yearWithOrdinal}} ano.'
  },
  ringParticipantAlarmsByDefaultTooltipMessage:
    'Quando essa preferência estiver ativada, os alarmes de outras pessoas tocarão sem que você os aceite. Mesmo assim, você deve aceitar o alarme para que o criador saiba que você pretende participar do alarme.\n\nQuando essa preferência estiver desativada, os alarmes adicionados para você por outras pessoas não tocarão no seu telefone, a menos que você os aceite explicitamente.',
  specifyTimezoneForAlarmDefaultTooltipMessage:
    'Quando essa preferência está ativada, você pode especificar um fuso horário ao criar alarmes. Os alarmes tocarão de acordo com o fuso horário especificado.\n\nQuando essa preferência estiver desativada, os alarmes tocarão de acordo com o fuso horário do dispositivo.',
  ringOnEarphoneOnlyTooltipTitle:
    'Comportamento do alarme quando os fones de ouvido estão conectados',
  ringOnEarphoneOnlyTooltipMessage:
    'Quando os fones de ouvido estão conectados, é possível configurar os alarmes para tocar apenas nos fones de ouvido ou no alto-falante do telefone e nos fones de ouvido.\n\nQuando os fones de ouvido não estiverem conectados, os alarmes tocarão no alto-falante do telefone.',
  gestureOptionsTooltipTitle: 'Gesto para ignorar um alarme',
  gestureOptionsTooltipMessage:
    'Quando um alarme toca na tela de bloqueio, você pode desativá-lo tocando em um botão ou deslizando um botão.\n\nEssa configuração permite que você escolha como deseja ignorar um alarme.',
  notificationSettingsTooltipTitle: 'Configurações de notificação',
  notificationSettingsTooltipMessage:
    'Ative as notificações que deseja receber e desative aquelas sobre as quais não deseja ser notificado.',
  messagesForAlarm: 'Mensagens para {{name}}',
  systemNotificationsTooltipMessage:
    'Notificações geradas pelo aplicativo para eventos como:\n  - quando os alarmes são afetados devido à mudança de fuso horário do contato\n  - quando um contato convidado se registra no Galarm\n  - quando os alarmes são afetados pelo fato de alguém ter excluído sua conta',
  galarmWebNotificationsTooltipMessage:
    'Notificações enviadas a você pelo Galarm Web.',
  alarmsfromOthersNotificationsTooltipMessage:
    'Notificações que são enviadas quando você é adicionado a um alarme como participante.',
  alarmChatNotifictionsTooltipMessage:
    'Notificações enviadas para mensagens de bate-papo em um alarme.',
  groupNotificationsTooltipMessage:
    'Notificações que são enviadas quando você é adicionado a um grupo, removido de um grupo ou quando outra pessoa é adicionada/removida de um grupo.',
  alarmAcknowledgementNotificationsTooltipMessage:
    'Notificações que são enviadas quando um participante reconhece um alarme do qual você também participa.',
  sharedAlarmNotificationsTooltipMessage:
    'Notificações que são enviadas:\n  - quando alguém se inscreve em um alarme compartilhado por você por meio de um link\n  - quando um alarme no qual você se inscreveu tiver sido alterado',
  sleepTimer: 'Música para dormir',
  sleepTimerTitle: 'Tocar música para',
  ongoingSleepTimer: 'Música para dormir em andamento',
  stopSleepTimer: 'Deseja interromper a música atual?',
  restartSleepTimer: 'Deseja interromper a música atual e iniciar uma nova?',
  play: 'Jogar',
  youAreOffline:
    'No momento, você está off-line. Fique on-line para realizar qualquer operação.',
  alarmLinkRelativeToTimezone:
    'O alarme de todos deve tocar às {{timeString}} em seu fuso horário.',
  alarmLinkNotRelativeToTimezone:
    'O alarme de todos deve tocar às {{timeString}}',
  sleepMusicTooltip:
    'Reproduza sons naturais para relaxar o corpo e a mente. A música para de tocar após a duração especificada.',
  alarmLinkCopiedToClipboard:
    'Link do alarme copiado para a área de transferência.',
  confirmLogOut: 'Deseja fazer o logout?',
  appNotRunningMessage:
    'Detectamos que o Galarm não está em execução no seu telefone. Toque para iniciar o Galarm de modo que você não perca nenhum alarme importante.',
  tapToEnterNotesForAlarm: 'Toque para inserir notas',
  accountAlreadyExists: 'Conta existente encontrada',
  accountsMerged:
    'Encontramos uma conta existente associada a esse número de celular. Unimos as duas contas.',
  upgradeToPremiumToAssignUserDefinedCategory:
    'Faça upgrade para Premium para selecionar a categoria definida pelo usuário.',
  userDefinedCategoryNotSetForAlarm:
    'Faça upgrade para Premium para atribuir automaticamente a categoria definida pelo usuário. A categoria não está definida para o alarme.',
  alarmDetails: 'Detalhes do alarme',
  profileDetails: 'Perfil',
  activeWebSession:
    'Você já tem uma sessão ativa na Web. Você pode sair dela ou iniciar uma nova sessão.\n\nAo iniciar uma nova sessão, você será automaticamente desconectado da sessão da Web ativa no momento.',
  startNewSession: 'Iniciar uma nova sessão',
  confirmWebSessionLogout:
    'Isso fará com que você saia da sessão atual da Web. Você pode iniciar uma nova sessão digitalizando o código novamente.',
  defaultInstantAlarmTitleForGroup: '{{groupName}} Alerta de {{creatorName}}',
  defaultInstantAlarmTitleForContact: 'Alerta de {{creatorName}}',
  atleastOneMemberShouldBeActive:
    'Pelo menos um participante deve estar ativo.',
  activeParticipants: 'Participantes ativos',
  acceptAlarm: 'Aceitar alarme',
  rejectAlarm: 'Alarme de rejeição',
  nConfirmed: '{{count}} confirmada',
  nDeclined: '{{count}} recusado',
  nPending: '{{count}} pendente',
  everyoneConfirmed: 'Todos confirmaram',
  everyoneDeclined: 'Todos recusaram',
  appTagline: 'Nunca perca nada',
  usePreviousBackup: 'Sou um usuário registrado >',
  viewAlarmsByCategories: 'Exibir alarmes por categorias',
  viewAllAlarms: 'Exibir todos os alarmes',
  switchToSummaryView: 'Alternar para a visualização de resumo',
  switchToListView: 'Alternar para a visualização de lista',
  hideHourlyAlarms: 'Ocultar alarmes de hora em hora',
  calendarView: 'Calendário Galarm',
  viewRecentlyDeletedAlarms: 'Exibir alarmes excluídos recentemente',
  deletedOnDate: 'Eliminado em {{alarmDate}}',
  recentlyDeletedAlarmFooterText: 'Esse alarme foi excluído em {{alarmDate}}',
  noRecentlyDeletedAlarms: 'Nenhum alarme excluído recentemente',
  restoreAlarmsFromMobileApp:
    'Esses alarmes serão excluídos automaticamente após 30 dias de sua exclusão. Se quiser restaurar algum deles, acesse a tela "Alarmes excluídos recentemente" do seu aplicativo móvel e toque no ícone de restauração.',
  deleteInactiveAlarms: 'Excluir alarmes inativos',
  defaultConfigurationUpdated: 'A configuração padrão foi atualizada.',
  notAlerted: '(excluído)',
  alarmKeepsRingingHelp: 'Ajuda! Meu alarme continua tocando',
  alarmKeepsRingingHelpMessage:
    'Atualizamos seus alarmes e enviamos o relatório de problemas.',
  alarmKeepsRingingHelpAlert:
    'Atualizaremos seus alarmes e você não deverá mais observar esse problema. Também enviaremos um relatório de problema à nossa equipe de suporte para investigação adicional.\n\n  Se o problema persistir, entre em contato conosco usando "Configurações > Feedback".',
  sendingFeedback: 'Envio de feedback',
  sendingProblemReport: 'Envio de relatório de problemas',
  fixingAlarms: 'Atualização de seus alarmes',
  existingAccountFound: 'Backup restaurado',
  existingAccountDataRestored: {
    zero: 'Parabéns, todos os dados foram restaurados do backup.',
    one: 'Parabéns, todos os dados foram restaurados do backup.',
    other: 'Parabéns, todos os dados foram restaurados do backup.'
  },
  noExistingAccountFound: 'Nenhum backup encontrado',
  createdNewAccount:
    'Esse número de telefone não foi registrado no Galarm. Criamos uma nova conta usando esse número de telefone.',
  iosCloseToAllowedNumNotificationsScheduled: {
    zero: 'O iOS permite que um aplicativo agende até 64 notificações. Você já usou todas essas notificações. É possível que você perca alguns alarmes.',
    one: 'O iOS permite que um aplicativo agende até 64 notificações. Você utilizou {{numNotifications}} dessas notificações.'
  },
  acknowledgeAlarmToScheduleNextOccurrence:
    'Esta é a última notificação para esse alarme. Pressione longamente essa notificação e tome uma ação para programar a próxima ocorrência desse alarme.',
  lastNotificationForOneTimeAlarm:
    'Essa é a última notificação para esse alarme.',
  criticalAlertsMessageTitle: 'Toque os alarmes quando o telefone estiver mudo',
  criticalAlertsMessageDescription:
    '\nVocê pode configurar o Galarm para usar os Alertas críticos do iOS para tocar os alarmes quando o telefone estiver mudo. Gostaria de ativá-lo para todos os alarmes?\n\nVocê também pode ativá-lo seletivamente para alguns alarmes, editando a configuração "Sound and Vibration > Critical Alerts" (Som e vibração > Alertas críticos).',
  allowCriticalAlerts: 'Permitir alertas críticos',
  criticalAlertsPermissionDenied:
    'O Galarm requer permissão do seu telefone para exibir alertas críticos. Ative essa permissão nas configurações do seu telefone.',
  enableCriticalAlertsForAllAlarms: 'Sim, ativar para todos os alarmes',
  selectivelyEnableCriticalAlerts: 'Não, eu habilitarei seletivamente',
  firstName: 'Primeiro nome',
  lastName: 'Sobrenome',
  email: 'E-mail',
  password: 'Senha',
  confirmPassword: 'Confirmar senha',
  signup: 'Registrar-se',
  login: 'Login',
  alreadySignedUp: 'Já se inscreveu?',
  notAUser: 'Não é um usuário?',
  subscribe: 'Assine',
  choosePlan: 'Configurar plano',
  billingCycle: 'Ciclo de faturamento',
  needHelpToGetStarted: 'Precisa de ajuda para começar?',
  browser: 'Navegador: {{name}}',
  deviceOs: 'Dispositivo: {{name}}',
  lastActive: 'Último ativo: {{timestamp}}',
  signUp: 'Registrar-se',
  useGalarmOnWeb: 'Use o Galarm na Web',
  welcomeUser: 'Bem-vindo {{name}}',
  changePassword: 'Alterar senha',
  saveAlarm: 'Salvar alarme',
  galarmUserTip: 'Você sabia?',
  sendAlarm: 'Enviar alarme',
  saveGroup: 'Salvar grupo',
  webAppBestViewedOnDesktop:
    'O URL (web.galarm.app) serve para iniciar uma interface do Galarm em um navegador da Web em seu laptop ou desktop.\n\nPara usar o Galarm em seu dispositivo móvel, instale o aplicativo Android do Google Play ou o aplicativo iOS da App Store usando um dos links abaixo:',
  scanQrCodeMessage: 'Escanear código QR',
  reloadQrCode: 'Clique para recarregar o código QR',
  invalidQrCodeScanned: 'Código QR inválido escaneado.',
  unableToScanCode: 'Não é possível digitalizar o código',
  unableToScanCodeMessage:
    'Houve um problema na leitura do código. Tente novamente. Se continuar a ter problemas, envie-nos um feedback do aplicativo móvel usando "Configurações > Feedback".',
  problemWithEnterpriseAccountSetup:
    'Houve um problema ao configurar sua conta do Galarm Pro. Entre em contato com o suporte técnico.',
  problemWithCreatingCheckoutSession:
    'Houve um problema ao criar uma sessão de checkout. Entre em contato com o suporte técnico.',
  problemWithCreatingCustomerPortalSession:
    'Houve um problema ao criar uma sessão do portal do cliente. Entre em contato com o suporte técnico.',
  problemWithCreatingApiKey:
    'Houve um problema ao criar uma chave de API. Entre em contato com o suporte técnico.',
  creatingAnAccount: 'Criar uma conta',
  currentPlan: 'Plano atual: {{name}} ({{quantity}} assentos)',
  myAccount: 'Informações sobre a conta',
  new: 'Novo',
  deleteAllAlarmHistoryAlertTitle: 'Excluir histórico de alarmes',
  deleteAllAlarmHistoryAlertMessage:
    'Toque em "Continue" (Continuar) para excluir o histórico desse alarme. Essa ação não pode ser desfeita.',
  ageOutInactiveAlarmsMessage:
    'Você tem {{count}} alarmes que estão inativos há mais de 60 dias. Ter muitos alarmes inativos pode afetar o desempenho do aplicativo.\n\nDeseja excluir esses alarmes?',
  duplicateAlarm: 'Alarme duplicado',
  graduallyIncreaseVolume: 'Aumentar gradualmente o volume',
  graduallyIncreaseVolumeTooltipMessage:
    'Quando essa preferência está ativada, o volume do alarme aumenta gradualmente até o volume do toque configurado. Isso permite que você interrompa o alarme antes que ele fique muito alto.\n\nQuando essa preferência está desativada, os alarmes tocam no volume do toque configurado durante toda a duração do toque.',
  teams: 'Equipes',
  alerts: 'Alertas',
  listeners: 'Ouvintes',
  noMembersConfiguredInEnterpriseAccount:
    'Você ainda não adicionou nenhum membro. Os membros são usuários que podem ser adicionados à política de escalonamento de um ouvinte e são alertados sobre incidentes recebidos pelo ouvinte. Essas pessoas já devem estar registradas no Galarm.',
  noTeamsConfiguredInEnterpriseAccount:
    'Você ainda não criou nenhuma equipe. As equipes são grupos de membros que podem ser adicionados à política de escalonamento de um ouvinte e são alertados sobre incidentes recebidos pelo ouvinte.',
  noAlertsConfiguredInEnterpriseAccount:
    'Você ainda não criou nenhum ouvinte. Você pode configurar os ouvintes para ouvir os incidentes relatados pelas ferramentas de monitoramento. Depois de criar um ouvinte, você poderá ver instruções sobre como integrar o ouvinte a várias ferramentas de monitoramento.\n\nVocê pode configurar vários níveis de escalonamento para cada ouvinte.',
  noShiftsConfiguredInEnterpriseAccount:
    'Você ainda não criou nenhuma configuração de turno.',
  noRotationsConfiguredInEnterpriseAccount:
    'Você ainda não criou nenhuma rotação.',
  rotations: 'Rotações',
  addRotation: 'Adicionar rotação',
  viewRotation: 'Exibir rotação',
  enterRotationName: 'Digite o nome da rotação',
  rotationType: 'Tipo de rotação',
  numberOfHoursInRotation: 'Número de horas em rotação',
  numberOfDaysInRotation: 'Número de dias em rotação',
  numberOfWeeksInRotation: 'Número de semanas em rotação',
  specify: 'Especifique...',
  noIntegrationsConfiguredInEnterpriseAccount:
    'Você ainda não configurou nenhuma configuração de integração.',
  loadingUser: 'Carregando informações do usuário',
  addMember: 'Adicionar membro',
  add: 'Adicionar',
  noUserFoundWithMobileNumber:
    'Nenhum usuário registrado do Galarm foi encontrado com esse número de telefone. Certifique-se de inserir o número de telefone com o código do país sem nenhum caractere especial, como parênteses, traços, espaços em branco etc.',
  memberWithPhoneNumberAlreadyAdded:
    'O membro com esse número de telefone já foi adicionado.',
  memberWithEmailAlreadyAdded: 'O membro com esse e-mail já foi adicionado.',
  assignKeyToMember: 'Atribuir chave de licença',
  revokeKeyFromMember: 'Revogação da chave de licença',
  cantAssignKeyToMember: 'Não é possível atribuir uma chave a um membro',
  noEnterpriseLicenseKeyAvailable:
    'Não há chaves de licença disponíveis. Atualize seu plano.',
  addTeam: 'Adicionar equipe',
  enterTeamName: 'Digite um nome de equipe',
  addAlert: 'Adicionar ouvinte',
  low: 'Baixa',
  medium: 'Médio',
  high: 'Alta',
  severity: 'Gravidade',
  enterAlertName: 'Digite o nome do ouvinte',
  organization: 'Organização',
  startTime: 'Hora de início',
  endTime: 'Tempo final',
  shiftName: 'Mudança {{index}}',
  addShift: 'Adicionar turno',
  assignees: 'Cessionários',
  shiftStartEndTime: '{{startTime}} - {{endTime}}',
  assignee: 'Cessionário',
  specifyAssigneeForShift: 'Especifique o responsável pelo turno',
  selectAtLeastOneMember: 'Selecione pelo menos um membro',
  editMember: 'Editar membro',
  editTeam: 'Equipe de edição',
  cantRemoveMemberAssignedToTeamsOrAlerts:
    'O membro faz parte de uma equipe ou está atribuído a um ouvinte. Remova o membro dessas equipes/ouvintes antes de excluí-lo.\nEquipes: {{teams}}\nOuvintes: {{alerts}}',
  cantRevokeKeyFromMemberAssignedToTeamsOrAlerts:
    'O membro faz parte de uma equipe ou está atribuído a um ouvinte. Remova o membro dessas equipes/ouvintes antes de revogar a chave.\nEquipes: {{teams}}\nOuvintes: {{alerts}}',
  cantDeleteTeamAssignedToAlerts:
    'A equipe faz parte de um ouvinte. Remova a equipe do ouvinte antes de excluí-la.\nOuvintes: {{alerts}}',
  cantDeleteRotationAssignedToAlerts:
    'A rotação faz parte de um ouvinte. Remova a equipe do ouvinte antes de excluir.\nOuvintes: {{alerts}}',
  editAlert: 'Editar ouvinte',
  timezoneDisplayString: '(GMT{{offset}}) {{timezone}}',
  getCode: 'Obter código',
  apiKey: 'Chave da API',
  viewApiKey: 'Exibir chave de API',
  apiKeyWarningMessage:
    'Mantenha sua chave de API segura o tempo todo. Não a compartilhe com ninguém. Se você acha que sua chave de API foi comprometida, toque no botão "Change" (Alterar) acima para alterá-la. Depois de alterar a chave, você precisará especificar a nova chave em todos os locais em que estiver acionando incidentes usando a API REST',
  integrationConfigs: 'Configurações de integração',
  addIntegrationConfig: 'Adicionar configuração de integração',
  editIntegrationConfig: 'Editar configuração de integração',
  integrationSource: 'Fonte de integração',
  apiToken: 'Token de API',
  integrations: 'Integrações',
  selectPlaceholder: 'Selecione...',
  zendeskSubdomain: 'Subdomínio do Zendesk',
  creatorTimeForBuddyAlarm: '{{timeString}} ({{remindMeAfter}})',
  nextFiveAlarms: 'Próximos 5 alarmes',
  categories: 'Categorias',
  viewingAlarmsDueToday: 'Os alarmes de visualização devem ser entregues hoje',
  viewingAlarmsDueTomorrow:
    'Os alarmes de visualização devem ser entregues amanhã',
  expiredAlarms: 'Alarmes expirados',
  viewingExpiredAlarms: 'Visualização de alarmes expirados',
  noAlarmsDueToday: 'Nenhum alarme deve ser entregue hoje',
  noAlarmsDueTomorrow: 'Nenhum alarme deve ser entregue amanhã',
  noExpiredAlarms: 'Sem alarmes expirados',
  viewingAllAlarms: 'Visualização de todos os alarmes',
  viewingRecentlyDeletedAlarms:
    'Visualização de alarmes excluídos recentemente',
  summaryView: 'Tentar a visualização de resumo?',
  summaryViewDescription:
    'Veja os alarmes organizados por datas de vencimento e categorias.\n\nVocê pode voltar à exibição de lista usando o botão ⋮ no canto superior direito.',
  disableBatteryOptimizationMessageOnStartup:
    'O Galarm precisa ser executado em segundo plano para que os alarmes toquem de forma confiável. Permita a permissão na próxima tela.',
  disableBatteryOptimizationMessage:
    'O Galarm precisa ser executado em segundo plano para que os alarmes toquem de forma confiável.',
  allowNotificationsToDisplayAlarms:
    'O Galarm precisa de permissão para mostrar notificações quando os alarmes tocam.',
  scheduleExactAlarmsMessage:
    'O Galarm precisa de permissão para programar alarmes exatos.',
  alarmCategoryWithSameNameExists:
    'Já existe uma categoria com esse nome. Especifique um nome diferente.',
  unlimitedAlarmsPremiumFeature:
    'Alarmes ilimitados para grupos, amigos e instantâneos',
  alarmRepetitionsPremiumFeature: 'Opções avançadas de repetição',
  ringtonesPremiumFeature: 'Mais toques de celular',
  galarmWebPremiumFeature: 'Use o Galarm na Web',
  taskListPremiumFeature: 'Criar várias listas de tarefas',
  preReminderPremiumFeature: 'Pré-lembrete para alarmes',
  alarmCategoriesPremiumFeature: 'Categorias de alarmes personalizados',
  shareableAlarmLinksPremiumFeature: 'Links de alarmes compartilháveis',
  announceAlarmNamePremiumFeature: 'Anúncio do título do alarme',
  manageSubscription: 'Gerenciar assinatura',
  purchaseRestored: 'Compra restaurada.',
  unableToRetrieveSubscriptionPlansAndroid:
    'Não foi possível conectar-se ao Google Play para realizar a atualização. Verifique se você está conectado à Internet e tente novamente. \n\nRelate um problema à nossa equipe de suporte se o problema persistir.',
  unableToRetrieveSubscriptionPlansIos:
    'Não é possível conectar-se à App Store para realizar a atualização. Verifique se você está conectado à Internet e tente novamente. \n\nRelate um problema à nossa equipe de suporte se o problema persistir.',
  drawOverOtherApps: 'Exibição sobre outros aplicativos',
  drawOverOtherAppsHelperText:
    'Permitir que o Galarm mostre alertas de alarme na parte superior',
  nTimes: { one: 'Uma vez', other: '{{count}} horários' },
  learnHowToUseFocus: 'Foco no aprendizado',
  enhancedNotificationsDeprecated: 'Notificações aprimoradas obsoletas',
  enhancedNotificationsDeprecatedMessage:
    'Descobrimos que as notificações aprimoradas não são confiáveis e causam um consumo excessivo de bateria. Use o iOS Focus para permitir que o Galarm toque os alarmes quando seu telefone estiver no modo Não perturbe.',
  usingIosFocus: 'Usando o iOS Focus',
  iosFocusModePara1:
    'O Galarm não tocará quando o botão de campainha do telefone estiver desligado ou quando o volume da campainha estiver em 0.\n\nA Apple recomenda o uso do "Focus" quando você quiser manter o telefone silencioso, mas ainda assim permitir que alguns aplicativos o notifiquem. Use as instruções a seguir para configurar o Focus em seu telefone.',
  iosFocusModePara2:
    'Você pode ver instruções mais detalhadas com capturas de tela em https://galarm.zendesk.com/hc/en-us/articles/10906890793236-Allow-alarms-to-ring-on-silent-on-iOS',
  settingFocus: 'Configuração do Focus:',
  settingFocusFootNotes:
    'Observe que o Focus está disponível no iOS 15 e superior.\n\nEm caso de dúvidas ou comentários, entre em contato conosco usando "Configurações > Comentários".',
  enablingFocus: 'Habilitando o foco:',
  settingFocusInstruction1: 'Vá para "Configurações do telefone > Foco".',
  settingFocusInstruction2: 'Toque no perfil de foco "Não perturbe".',
  settingFocusInstruction3: 'Toque em "Apps" e adicione o Galarm.',
  settingFocusInstruction4:
    'Faça o mesmo com os outros perfis do Focus que você configurou.',
  enablingFocusInstruction1:
    'Deslize para baixo a partir da borda superior direita para visualizar a Central de controle.',
  enablingFocusInstruction2: 'Toque em Foco.',
  enablingFocusInstruction3:
    'Toque em Não perturbe ou em qualquer outro perfil do Focus que você queira ativar.',
  enablingFocusInstruction4:
    'O Focus ativado no momento parece estar selecionado. Você pode tocar nele novamente para desativá-lo.',
  startDateForRotation: 'Data de início do rodízio',
  startTimeForRotation: 'Hora de início da rotação',
  member: 'Membro',
  multipleShiftsInADay: 'Vários turnos em um dia',
  escalationPolicy: 'Política de escalonamento',
  atTimeOfEvent: 'No momento do evento',
  afterNMinutes: 'Após {{count}} minutos',
  afterNHours: { one: 'Após 1 hora', other: 'Após {{count}} horas' },
  afterDuration: 'Depois de {{durationString}}',
  addTrigger: 'Adicionar acionador',
  viewCode: 'Exibir código',
  apiCodes: 'Códigos API',
  viewIncidents: 'Exibir incidentes',
  incidentsForAlert: 'Incidentes para ouvintes {{alertName}}',
  timestamp: 'Carimbo de data/hora',
  acknowledged: 'Reconhecido',
  noIncidentsForAlert: 'Ainda não há incidentes para esse ouvinte',
  apiCodeCopiedToClipboard:
    'O código da API foi copiado para a área de transferência.',
  triggerName: 'Gatilho {{index}}',
  delay: 'Atraso',
  chooseAssigneePlaceholder: 'Selecione Assignee...',
  delayForTrigger: 'Atraso para acionamento',
  assigneeWillBeAlertedAfterDelay:
    'O cessionário será alertado após {{durationString}}.',
  escalationForIncidentsForAlert:
    'Escalonamento para incidentes em {{incidentDate}} para ouvinte {{alertName}}',
  alertIncidentAcknowledged: 'Reconhecido por {{memberName}} em {{dateString}}',
  alertIncidentRecovered: 'Recuperado em {{dateString}}',
  alertIncidents: 'Incidentes',
  viewingAlertIncidents: 'Visualização de incidentes',
  createOrganization: 'Criar organização',
  youAreNotPartOfAnyOrganization:
    'Você não faz parte de nenhuma organização. Crie uma organização para começar.',
  tellUsAboutYourOrganization: 'Fale-nos sobre sua organização',
  noActiveSubscription: 'Você não tem uma assinatura ativa.',
  chooseOrganization: 'Escolha uma organização para continuar.',
  noTriggersForIncident: 'Não foram encontrados gatilhos para esse incidente.',
  role: 'Função',
  viewer: 'Visualizador',
  newOrganization: 'Nova organização',
  noOrganizations: 'Você não faz parte de nenhuma organização.',
  maxOrganizationsReached:
    'O usuário pode fazer parte de, no máximo, 10 organizações.',
  yourOrganizations: 'Organizações',
  urlNotFound: 'O URL não corresponde a nenhuma rota suportada.',
  triggerAlert: 'Incidente de gatilho',
  recaptchaFailed: 'Falha na verificação do reCAPTCHA. {{error}}',
  loadingAccountInformation: 'Carregando informações da conta',
  loadingAuthInfo: 'Carregando informações de autenticação',
  allAlerts: 'Todos os ouvintes',
  recentlyTriggeredAlerts: 'Ouvintes acionados recentemente',
  recentlyClosedIncidents: 'Incidentes encerrados recentemente',
  noRecentlyTriggeredAlerts: 'Nenhum ouvinte foi acionado recentemente.',
  noRecentlyClosedIncidents: 'Nenhum incidente foi encerrado recentemente.',
  closedAt: 'Fechado em {{dateTimeString}}',
  dashboard: 'Painel de controle',
  loggedIntoOrganizationAccount:
    '(organização: {{organizationName}}, função: {{role}})',
  viewIncidentEscalation: 'Exibir escalonamento de incidentes',
  closeIncident: 'Incidente próximo',
  closingIncident: 'Incidente de fechamento',
  unableToRefreshEnterpriseAccount: 'Não foi possível atualizar sua conta.',
  viewAuthToken: 'Exibir token de autenticação',
  problemWithCreatingAuthToken:
    'Houve um problema ao criar um token de autenticação. Entre em contato com o suporte técnico.',
  authToken: 'Token de autenticação',
  authTokenWarningMessage:
    'Mantenha esse token de autenticação sempre seguro. Não o compartilhe com ninguém. Se você acha que sua chave de API foi comprometida, toque no botão "Change" (Alterar) acima para alterá-la. Depois de alterar o token, você precisará especificar o novo token em todos os locais em que estiver acionando incidentes usando a API REST',
  authTokenCopiedToClipboard:
    'O token de autenticação foi copiado para a área de transferência.',
  resendVerificationEmail: 'Reenviar e-mail de verificação',
  loggedInUsingEmail: 'Efetuou login usando o e-mail {{email}}.',
  checkingIfMemberIsAppUser:
    'Verificar se o membro é um usuário do aplicativo.',
  settingMemberDetails: 'Definir detalhes do membro.',
  addingTeam: 'Adicionando equipe.',
  editingTeam: 'Equipe de edição.',
  addingRotation: 'Adição de rotação.',
  editingRotation: 'Rotação de edição.',
  assigningKeyToMember: 'Atribuição de chave ao membro.',
  revokingKeyFromMember: 'Revogação da chave do membro.',
  deletingTeam: 'Exclusão da equipe.',
  deletingRotation: 'Exclusão da rotação.',
  addingAlert: 'Adicionando ouvinte.',
  editingAlert: 'Ouvinte de edição.',
  deletingAlert: 'Excluindo o ouvinte.',
  triggeringAlert: 'Incidente desencadeador.',
  creatingOrganizationAccount: 'Criação de conta de organização.',
  creatingCheckoutSession: 'Iniciar sua sessão de checkout.',
  creatingAnAccountForMember: 'Criar uma conta para o membro.',
  passwordResetEmailSent:
    'Enviamos a você um e-mail para redefinir sua senha. Altere sua senha e faça login novamente.',
  changeOwner: 'Alterar proprietário',
  changeOrganizationOwner: 'Mudar o proprietário da organização',
  newOwnerEmail: 'E-mail do novo proprietário',
  saveOnYearlySubscription: 'Economize {{percent}}%',
  annual: 'Anual',
  resetAlarms: 'Atualizar alarmes',
  resetAlarmsAlert:
    'Todos os alarmes foram atualizados. Se você continuar observando problemas com os alarmes, envie um tíquete usando a opção "Report a Problem".',
  resetAlarmsTooltipMessage:
    'Se estiver tendo problemas com alarmes, como alarmes excluídos que tocam no telefone, atualize os alarmes usando essa opção. Se o problema persistir, envie um tíquete usando a opção "Relatar um problema".',
  resetTempPassword: 'Redefinir senha temporária',
  currPassword: 'Senha atual',
  tempPassword: 'Senha temporária',
  newPassword: 'Nova senha',
  reset: 'Redefinir',
  loggingIn: 'Login',
  resettingTempPassword: 'Redefinição da senha temporária',
  changingPassword: 'Alteração de senha',
  numSeats: 'Número de assentos',
  helpMeDecide: 'Ajude-me a decidir',
  numEnterpriseSeatsHelper:
    'Você deve escolher o número de assentos como o número de pessoas em sua organização que usarão o aplicativo para receber incidentes. Ao configurar um ouvinte, você pode especificar sua política de escalonamento. Você pode ter vários níveis em uma política de escalonamento. Por exemplo, você pode especificar que o primeiro nível de escalonamento é para uma equipe de 3 pessoas. Se o primeiro nível de escalonamento não responder em 5 minutos, o segundo nível de escalonamento será para uma equipe de 5 pessoas. Nesse caso, você pode especificar o número de assentos como 8.',
  numBusinessSeatsHelper:
    'Escolha o número de assentos de acordo com o número de pessoas que usarão o Galarm para receber alarmes e lembretes.',
  perSeatPrice: '{{price}} por {{interval}} por assento',
  doneForToday: 'Reconhecido',
  getStarted: 'Começar a usar',
  firstAlertInstructions: 'Instruções para o primeiro ouvinte',
  learnMoreAboutGalarmEnterprise: 'Saiba mais sobre o Galarm Pro',
  home: 'Início',
  team: 'Equipe',
  alert: 'Alerta',
  listener: 'Ouvinte',
  forgotPassword: 'Esqueceu sua senha?',
  forgotPasswordTitle: 'Esqueci minha senha',
  sendPasswordResetEmail: 'Enviar e-mail de redefinição de senha',
  sendingPasswordResetEmail: 'Envio de e-mail de redefinição de senha',
  field: 'Campo',
  description: 'Descrição',
  requestIntegration: 'Solicitar outra integração',
  integrationDetails: 'Detalhes da integração',
  enterIntegrationDetails: 'Fornecer detalhes da integração',
  galarmEnterpriseFreeTrial:
    'Inscreva-se para uma avaliação gratuita de 14 dias',
  galarmEnterpriseFreeTrialDescription:
    'Automatize o gerenciamento de incidentes',
  bySigningUpYouAgreeToOur: 'Ao se inscrever, você concorda com nossos',
  and: 'e',
  alarmSubscribers: 'Seguidores do alarme',
  otherSubscribersCount: {
    one: 'Mais 1 seguidor que não esteja em seus contatos',
    other: '{{count}} mais seguidores que não estão em seus contatos'
  },
  getAccountData: 'Exportar dados de alarme',
  getAccountDataPrompt:
    'Forneça um endereço de e-mail para o qual enviaremos um arquivo de texto contendo todos os seus alarmes.',
  getAccountDataConfirmation:
    'Observe que enviaremos por e-mail um arquivo de texto contendo todos os seus alarmes em {{email}}.',
  sendingAccountData: 'Exportação de dados de alarme...',
  sentAccountData: 'Dados de alarme enviados para {{email}}.',
  errorSendingAccountData: 'Erro ao exportar dados de alarme: {{error}}',
  sendingEmailVerificationLink: 'Envio de link de verificação de e-mail',
  viewLogs: 'Exibir registros',
  message: 'Mensagem',
  billing: 'Faturamento',
  newIncidentForAlert: 'Novo incidente para o ouvinte {{alertName}}',
  clickNextAfterAddingMembers:
    'Clique em "Next" (Avançar) depois de adicionar membros',
  clickDoneAfterAddingAlerts:
    'Clique em "Done" (Concluído) para concluir a configuração e ir para o Dashboard.',
  alertCreatedSuccessfully:
    'O ouvinte foi criado com êxito. Você pode acionar esse ouvinte no ícone do menu de três pontos na frente do nome do ouvinte.',
  fixSubscription: 'Corrigir assinatura',
  fixSubscriptionDescription:
    'Detectamos um problema com sua assinatura. Sua assinatura contém {{numSeats}} assento(s), mas você atribuiu chaves a {{numSeatsAssigned}} membro(s). Você pode atribuir no máximo {{numSeats}} chave(s).',
  fixSubscriptionChooseOption:
    'Corrija sua assinatura usando uma das opções abaixo.',
  fixSubscriptionContactAdmin:
    'Entre em contato com o administrador da sua organização.',
  unassignKeysFromMembers:
    'Retirar a atribuição de pelo menos {{extraKeysAssigned}} chave(s) dos membros',
  addMoreSeatsToSubscription:
    'Adicione pelo menos {{extraKeysAssigned}} assento(s) à sua assinatura',
  revokeMembersKey: 'Revogação da chave de membros',
  revokeMembersKeyDescription:
    'Escolha os membros cujas chaves você gostaria de revogar.',
  revoke: 'Revogar',
  unableToRevokeMembersKey:
    'Ocorreu um erro ao revogar a chave dos membros. Entre em contato com o suporte.',
  problemWithLoadingProducts:
    'Problema com o carregamento de produtos. {{error}}',
  verificationCompleted: 'Verificação concluída',
  deviceAlreadyRegistered:
    'Este número de telefone já foi verificado anteriormente neste dispositivo.',
  phoneNumberAutoFilled:
    'O Galarm usará o número de telefone selecionado de sua conta do Google.',
  unableToRegisterPreVerifiedUser:
    'Não foi possível registrar o usuário pré-verificado.',
  allowNotifications: 'Permitir notificações',
  allowNotificationsDescription: 'Para exibir os alarmes quando eles tocam.',
  scheduleExactAlarms: 'Programar alarmes',
  scheduleExactAlarmsHelperText:
    'Permitir que o Galarm toque os alarmes na hora exata.',
  scheduleExactAlarmsDescription: 'Para tocar os alarmes na hora exata.',
  disableBatteryOptimization: 'Gerenciar a otimização da bateria',
  disableBatteryOptimizationDescription:
    'Para permitir que o Galarm seja executado em segundo plano.',
  requestRequiredPermissions:
    'O Galarm requer as seguintes permissões para tocar alarmes',
  allowDisallowedPermissions: 'Toque para permitir',
  notificationsAreEnabled:
    'A permissão para mostrar notificações já é permitida.',
  connectingToStore: 'Conexão com a loja...',
  restartApp: 'Reiniciar o aplicativo',
  updateAvailable: 'Atualização disponível',
  english: 'Inglês',
  russian: 'Russo',
  german: 'Alemão',
  french: 'Francês',
  spanish: 'Espanhol',
  italian: 'Italiano',
  portuguese: 'Português',
  japanese: 'Japonês',
  korean: 'Coreano',
  indonesian: 'Indonésio',
  changeAppLanguage: 'Alterar o idioma do aplicativo',
  appLanguage: 'Idioma do aplicativo',
  iosChangeAppLanguagePara1:
    'O Galarm escolhe o idioma do aplicativo nas configurações do seu telefone. Siga estas etapas para alterar o idioma do aplicativo para o Galarm:',
  iosChangeAppLanguageInstruction1:
    'Abra "Phone Settings > General > Language & Region" (Configurações do telefone > Geral > Idioma e região). Certifique-se de que o idioma que você deseja usar no Galarm esteja presente na lista de idiomas preferidos.',
  iosChangeAppLanguageInstruction2:
    'Volte para "Phone Settings" (Configurações do telefone) e toque em "Galarm" na lista de aplicativos na parte inferior.',
  iosChangeAppLanguageInstruction3:
    'Toque em "Language" (Idioma) e selecione o idioma que você deseja definir para o Galarm. Volte ao aplicativo depois de escolher o idioma',
  androidChangeAppLanguagePara1:
    'O Galarm escolhe o idioma do aplicativo nas configurações do seu telefone. Siga estas etapas para alterar o idioma do aplicativo para o Galarm:',
  androidChangeAppLanguageInstruction1:
    'Toque no botão "Open App Settings" (Abrir configurações do aplicativo) abaixo.',
  androidChangeAppLanguageInstruction2:
    'Toque em "Language" (Idioma) e selecione o idioma que você deseja usar no Galarm.',
  androidChangeAppLanguageInstruction3:
    'Depois de escolher o idioma, volte aqui e clique no botão "Restart App" (Reiniciar aplicativo) abaixo.',
  problemRetrievingSubscriptionData:
    'Houve um problema ao recuperar suas informações de assinatura. Estamos encaminhando você para a página da conta. Verifique o status da sua assinatura e entre em contato com o suporte técnico se continuar a ter problemas.',
  deletingOrganization: 'Excluindo a organização...',
  deleteOrganization: 'Excluir organização',
  deleteOrganizationDescription:
    'Tem certeza de que deseja excluir sua organização?\n  \n  Essa é uma operação irreversível. Todos os dados de sua organização serão excluídos.\n  \n  Certifique-se de ter cancelado sua assinatura antes de excluir a organização.',
  galarmEnterpriseTrialDescription:
    'Há uma avaliação gratuita de 14 dias, durante a qual você pode cancelar a assinatura sem nenhum custo.',
  galarmEnterpriseTrialAlreadyAvailed:
    'Você já utilizou a avaliação gratuita de 14 dias. Entre em contato com o suporte se precisar de mais tempo para avaliar o produto.',
  signinWithGoogle: 'Faça login com o Google',
  signinWithGithub: 'Faça login com o GitHub',
  verifyAccount: 'Verificar conta',
  verifyAccountMessage:
    'Você deve ter recebido um e-mail com um código de verificação. Digite o código abaixo para verificar sua conta. Se não tiver recebido o código, você poderá solicitar um novo código clicando no botão "Resend Code" (Reenviar código).',
  verificationCode: 'Código de verificação',
  optionalString: '{{string}}',
  quickReminderTitle: 'Título (opcional)',
  accountVerified: 'Conta verificada',
  accountVerifiedMessage: 'Faça login usando suas credenciais.',
  multipleUsersWithSameEmail:
    'Há vários usuários com o mesmo e-mail. Entre em contato com o suporte.',
  multipleUsersWithSameMobileNumber:
    'Há vários usuários com o mesmo número de celular. Entre em contato com o suporte.',
  startTrial: 'Iniciar teste',
  nextSteps: 'Próximas etapas',
  firstTimeSetupComplete:
    'Você concluiu a configuração inicial do Galarm Enterprise e criou o seu primeiro ouvinte. Veja a seguir algumas das próximas etapas que você pode seguir a partir daqui.',
  testTheAlertInstruction:
    'Consulte as instruções em {{link}} para saber como testar o ouvinte que você acabou de criar.',
  integrateTheAlertInstruction:
    'Consulte as instruções em {{link}} para integrar o listener a várias ferramentas de monitoramento.',
  createMoreAlertsInstruction:
    'Você pode adicionar mais ouvintes acessando a guia "Listeners" (Ouvintes) à esquerda.',
  addMoreMembersInstruction:
    'Você pode adicionar mais membros acessando a guia "Members" (Membros) à esquerda.',
  createTeamsInstruction:
    'Você pode agrupar os membros em "Equipes" e alertar todas as equipes quando ocorrer um incidente. Crie equipes acessando a guia "Teams" (Equipes) à esquerda e use-as na configuração do ouvinte.',
  testTheAlert: 'Teste o ouvinte',
  integrateTheAlert: 'Integrar o ouvinte',
  createMoreAlerts: 'Criar mais ouvintes',
  addMoreMembers: 'Adicionar mais membros',
  createTeams: 'Criar equipes',
  docs: 'Documentos',
  contactSupport: 'Suporte de contato',
  pastDue: 'Vencido',
  canceled: 'Cancelado',
  unpaid: 'Não pago',
  incomplete: 'Incompleto',
  incomplete_expired: 'Incompleto Expirado',
  trialing: 'Avaliação gratuita',
  paused: 'Em pausa',
  plan: 'Plano',
  status: 'Status',
  renewsOn: 'Renova em',
  yourRoles: 'Suas funções',
  enterprisePlan: '{{quantity}} assentos, renova a cada {{interval}}',
  currentOrganization: 'Organização atual',
  useThisOrganization: 'Use esta organização',
  fixPayment: 'Pagamento fixo',
  fixingPayment: 'Fixação de pagamento',
  addPaymentInformation: 'Adicionar informações de pagamento',
  noPaymentInformationAvailable:
    'Não há informações de pagamento disponíveis. Atualize a página se você adicionou informações de pagamento recentemente.',
  openBillingPortal: 'Portal de faturamento aberto',
  startupWizardDescription:
    'Esse assistente o ajudará a começar e a criar seu primeiro ouvinte.',
  userNotFound: 'Usuário não encontrado',
  wrongPassword: 'Senha incorreta',
  emailAlreadyInUse: 'E-mail já em uso',
  restoreDeletedAlarms: 'Restaurar alarmes excluídos',
  deleteAlarmAlert:
    'Os alarmes excluídos podem ser restaurados em "Recently Deleted Alarms" (Alarmes excluídos recentemente). Você pode visualizá-los tocando no menu de três pontos (⋮) no canto superior direito da guia "Alarms" (Alarmes).\n\nOs alarmes excluídos permanecem ali por 30 dias, após os quais são permanentemente excluídos.',
  slackVerificationCode:
    'Seu código de verificação do Slack é {{verificationCode}}.',
  dndAccess: 'Acesso ao Não Perturbe',
  dndHelperText:
    'Permitir que o Galarm toque os alarmes quando o telefone estiver no modo Não perturbe.',
  cantRestoreInstantAlarmMessage:
    'Os alarmes instantâneos não podem ser restaurados. Você pode visualizar o bate-papo e outras informações desse alarme, se necessário. Esse alarme será removido permanentemente após 30 dias da exclusão.',
  upgradeToUnlockAllFeatures: 'Faça upgrade para desbloquear todos os recursos',
  otherSignupOptions: 'Outras opções de registro:',
  renewSubscription: 'Renovar a assinatura',
  subscriptionOnHold:
    'Em espera (Seu plano permite {{subscriptionQty}} assentos\nmas você atribuiu {{numSubscriptionKeys}} assentos.',
  checklists: 'Tarefas',
  checklist: 'Lista de tarefas',
  typeToAddTask: 'Tipo para adicionar tarefa',
  specifyTaskName: 'Especificar o nome da tarefa',
  otherChecklists: 'Listas de tarefas',
  defaultChecklistName: 'sem título',
  addTasksToChecklist:
    'Adicione tarefas usando a caixa de entrada na parte inferior',
  addTasksToDefaultChecklist:
    'Adicione tarefas usando a caixa de entrada na parte inferior... ou inicie uma nova lista de tarefas clicando no botão + no canto superior direito',
  createNewChecklist:
    'Você pode criar novas listas de tarefas para manter o controle de tarefas relacionadas em um único local.',
  addNewChecklist: 'Nova lista de tarefas',
  alarmPhoto: 'Foto do alarme',
  alarmPhotoFeatureTitle: 'Foto do alarme',
  alarmPhotoFeatureDescriptionPremiumUser:
    'Com esse recurso, você pode adicionar uma foto a um alarme para obter dicas visuais rápidas, como a foto de: um medicamento, instruções para uma tarefa, um item etc. A foto será exibida quando o alarme tocar.',
  alarmPhotoFeatureDescriptionFreeUser:
    'Com esse recurso, você pode adicionar uma foto a um alarme para obter dicas visuais rápidas, como a foto de: um medicamento, instruções para uma tarefa, um item etc. A foto será exibida quando o alarme tocar.\n  \nEsse é um recurso premium. Será solicitado que você faça upgrade para a versão premium para usar esse recurso.',
  alarmPhotoPremiumFeature: 'Adicionar foto às anotações do alarme',
  installPendingAppUpdate:
    'A atualização mais recente do aplicativo foi baixada e está pronta para ser instalada.',
  numTasks: {
    zero: 'Nenhuma tarefa',
    one: '1 tarefa',
    other: '{{count}} tarefas'
  },
  numTasksWithCompleted: {
    one: '1 tarefa, {{completed}} concluída',
    other: '{{count}} tarefas, {{completed}} concluídas'
  },
  muteAlarms: 'Silenciar alarmes',
  unmuteAlarms: 'Ativar alarmes',
  alarmsAreMuted:
    'Todos os seus alarmes estão silenciados no momento. Toque para ativar o som.',
  alarmsHaveBeenMuted:
    'Seus alarmes foram silenciados. Eles não tocarão até que você os tire do mudo.',
  alarmsHaveBeenUnmuted:
    'Seus alarmes foram desativados. Eles devem tocar conforme configurado.',
  deleteChecklist: 'Excluir lista de tarefas',
  confirmDeleteChecklist: 'Excluir lista de tarefas?',
  confirmDeleteCompletedTasks: 'Excluir tarefas concluídas?',
  confirmResetCompletedTasks: 'Redefinir tarefas concluídas?',
  confirmDeleteTask: 'Excluir {{taskName}}?',
  showCompletedTasks: 'Mostrar tarefas concluídas',
  hideCompletedTasks: 'Ocultar tarefas concluídas',
  deleteCompletedTasks: 'Excluir tarefas concluídas',
  resetCompletedTasks: 'Redefinir tarefas concluídas',
  yourGroups: 'Seus grupos',
  newAlarmWith: 'Novo alarme com {{name}}',
  newGroupWith: 'Novo grupo com {{name}}',
  alertContact: 'Alerta {{name}}',
  alertGroup: 'Alerta {{name}}',
  instantAlarmMovedToFabNotice:
    'O recurso Instant Alarm agora está disponível no botão "+" no canto inferior direito da tela. O ícone de "alto-falante" será removido do canto superior direito em uma versão futura.',
  tasksIntroTitle: 'Tarefas e listas de tarefas',
  tasksIntroDescription:
    'As tarefas são seus afazeres que não têm prazo. Você pode adicioná-las e gerenciá-las rapidamente aqui, marcando-as à medida que executa cada tarefa.\n\nVocê também pode criar listas de tarefas para agrupar tarefas semelhantes. Por exemplo, você pode criar uma lista de tarefas para suas compras de supermercado, outra para suas tarefas de trabalho e assim por diante.',
  groupsMovedToContactsScreen:
    'Os "Grupos" foram mesclados nessa guia. Seus grupos aparecem na parte superior, em "Your Groups" (Seus grupos). Você pode criar novos grupos tocando no botão "+" no canto inferior direito.',
  loadingTasks: 'Carregando tarefas...',
  tryPremium: 'Experimente o Premium',
  buyPremiumToUnlockFeature:
    'Esse é um recurso premium. Desbloqueie esse e muitos outros recursos interessantes com nosso plano premium que vem com uma avaliação gratuita de 14 dias.',
  newTask: 'Nova tarefa',
  newTaskList: 'Nova lista de tarefas',
  enterTaskName: 'Digite o nome da tarefa',
  enterTaskListName: 'Digite o nome da lista de tarefas',
  taskName: 'Nome da tarefa',
  taskListName: 'Nome da lista de tarefas',
  checklistTasks: '{{name}}',
  editTaskListName: 'Editar o nome da lista de tarefas',
  editTask: 'Editar tarefa',
  releaseNotes: 'Notas de lançamento',
  currentRelease: '(atualmente instalado)',
  loadingReleaseNotes:
    'Carregando notas de versão. Verifique se você está conectado à Internet.',
  id: 'ID',
  subscriptionData: 'Dados de assinatura',
  organizations: 'Organizações',
  showRotationCalendar: 'Mostrar calendário de rotação',
  rotationCalendar: 'Calendário de rotação',
  editShift: 'Mudança de edição',
  startDateForShift: 'Data de início do turno',
  startTimeForShift: 'Hora de início do turno',
  endDateForShift: 'Data de término do turno',
  endTimeForShift: 'Horário de término do turno',
  editingShift: 'Turno de edição',
  addingShift: 'Adicionar turno',
  products: 'Produtos',
  prices: 'Planos',
  planType: 'Tipo de plano',
  business: 'Negócios',
  enterprise: 'Empresa',
  compare: 'Comparar',
  apiSupport: 'Suporte de API para monitorar serviços',
  accessToConsoleToViewAndCloseIncidents:
    'Painel de controle para gerenciar incidentes',
  triggerInstantAlertsFromTheConsole:
    'Acionar incidentes a partir do Console corporativo',
  coreFeatures: 'Principais recursos:',
  everythingInBusiness: 'Tudo no plano de negócios e:',
  updatePlanInstructions:
    'Você pode atualizar seu plano clicando no botão "Open Billing Portal" (Abrir portal de faturamento) à direita e escolhendo o botão "Update Plan" (Atualizar plano) no portal de faturamento.',
  membersDescription:
    'Você adquiriu {{subscriptionKeysCount}} assentos - {{assignedSubscriptionKeysCount}} atribuídos e {{unassignedSubscriptionKeysCount}} disponíveis. Uma marca de seleção na frente do nome de um associado indica um assento designado.',
  centralBilling: 'Faturamento central',
  saveTwoMonths: 'Economize 2 meses',
  owner: 'Proprietário',
  enterpriseAccountSummary:
    'Você faz parte da organização {{organizationName}} inscrita no plano Galarm Pro {{planType}}.',
  perYearPerUser: 'por usuário/ano',
  perMonthPerUser: 'por usuário/mês',
  creationDate: 'Data de criação',
  updatePhoneNumber: 'Alterar número de telefone',
  confirmCountryCodeAndEnterUpdatedPhoneNumber:
    'Confirme o código do país e digite seu novo número de telefone. Sua conta será vinculada a esse número de telefone após a conclusão da verificação.',
  unableToUpdatePhoneNumber:
    'Não é possível alterar o número de telefone: {{error}}. Entre em contato com o suporte.',
  phoneNumberUpdated: 'Número de telefone alterado com sucesso.',
  phoneNumberUpdatedMessage:
    'Você pode começar a usar o aplicativo normalmente. Peça aos seus contatos que atualizem seu número de telefone no catálogo de endereços deles.',
  memberChangedPhoneNumber:
    '{{memberName}} mudou seu número de telefone. Atualize seu catálogo de endereços para continuar adicionando-os aos seus alarmes.',
  preferredSourceForIncidents: 'Fonte preferencial para incidentes',
  apiCodesDescription:
    'Use as instruções a seguir para integrar o ouvinte a uma fonte',
  rotation: 'Rotação',
  noAlarmsForThisDay: 'Não há alarmes para esse dia',
  markAlarmsAsComplete: 'Marcar alarmes como concluídos',
  markAlarmsAsCompleteExplanation:
    'Os alarmes únicos serão marcados como concluídos e aparecerão na seção de alarmes "Inativos".\n\nOs alarmes repetidos serão marcados como concluídos e serão movidos para a próxima ocorrência.',
  enterIncidentPrefix: 'Máximo de 5 caracteres',
  incidentPrefix: 'Prefixo do incidente',
  incidentPrefixAlreadyExists: 'O prefixo do incidente já existe',
  removeTask: 'Remover tarefa?',
  incidentPrefixLengthExceeded:
    'O prefixo do incidente tem mais de 5 caracteres',
  criticalAlerts: 'Alertas críticos',
  criticalAlertsConfiguration:
    'O alarme tocará em {{criticalAlertsVolume}}% do volume, mesmo quando o telefone estiver mudo ou o Focus estiver ligado.',
  criticalAlertsDisabledShort:
    'O alarme não tocará quando o telefone estiver mudo.',
  criticalAlertsConfigurationGlobal:
    'Os alarmes tocarão em {{criticalAlertsVolume}}% do volume, mesmo quando o telefone estiver mudo ou o Focus estiver ligado.',
  criticalAlertsDisabledShortGlobal:
    'Os alarmes não tocarão quando o telefone estiver mudo.',
  criticalAlertsEnabled:
    'Os alertas críticos estão ativados. Os alarmes tocarão mesmo quando o telefone estiver mudo ou o Focus estiver ligado.',
  criticalAlertsDisabled:
    'Os alertas críticos estão desativados. Os alarmes não tocarão quando o telefone estiver mudo.',
  enableCriticalAlerts: 'Ativar alertas críticos',
  summer_soft: 'Verão suave',
  beeps: 'Bipes',
  bollywood_nights: 'Noites de Bollywood',
  gentle_reminder: 'Lembrete gentil',
  himalayan_piper: 'Piper do Himalaia',
  holiday_joy: 'Alegria do feriado',
  hollywood_adventure: 'Aventura em Hollywood',
  melody: 'Melodia',
  morning_rooster: 'Galo da manhã',
  morning_sunshine: 'Sol da manhã',
  sweet_sea: 'Mar Doce',
  old_school_ring: 'Tring Tring',
  MyAlarmNotification: '{{alarmName}} - Chegou a hora.',
  ParticipantCascadingAlarmNotification:
    '{{alarmName}} - É hora de lembrar {{alarmCreatorName}}.',
  ParticipantSimultaneousAlarmNotification:
    '{{alarmName}}. É hora de confirmar sua participação.',
  InstantAlarmNotification: '{{alarmName}}. Sua atenção imediata é solicitada.',
  MyAlarmNotificationShort: 'Chegou a hora.',
  ParticipantCascadingAlarmNotificationShort:
    'É hora de lembrar {{alarmCreatorName}}.',
  ParticipantSimultaneousAlarmNotificationShort:
    'É hora de confirmar sua participação.',
  InstantAlarmNotificationShort: 'Sua atenção imediata é solicitada.',
  MyRecipientAlarmNotification:
    '{{alarmName}} - É hora de lembrar {{alarmRecipientName}}.',
  MyRecipientAlarmNotificationShort:
    'É hora de lembrar {{alarmRecipientName}}.',
  RecipientAlarmNotification:
    '{{alarmName}} (por {{alarmCreatorName}}) - Chegou a hora.',
  RecipientAlarmNotificationShort: 'Chegou a hora - por {{alarmCreatorName}}.',
  calendarView: 'Calendário Galarm',
  noAlarmsForThisDay: 'Sem alarmes para este dia',
  selectADate: 'Por favor, escolha uma data para ver os alarmes'
}
