import React from 'react'
import { I18n } from 'galarm-config'
import { Box, Heading, RadioButtonGroup, Button } from 'grommet'
import { Constants } from 'galarm-config'
import { Appearance } from 'react-native'

const SelectTheme = ({ onClose, onSave, colorScheme }) => {
  const onSelectColorScheme = label => {
    if (
      label ===
      Constants.COLOR_SCHEME_DISPLAY_NAMES[Constants.COLOR_SCHEMES.LIGHT]
    ) {
      if (colorScheme !== Constants.COLOR_SCHEMES.LIGHT) {
        onSave(Constants.COLOR_SCHEMES.LIGHT)
      }
    } else if (
      label ===
      Constants.COLOR_SCHEME_DISPLAY_NAMES[Constants.COLOR_SCHEMES.DARK]
    ) {
      if (colorScheme !== Constants.COLOR_SCHEMES.DARK) {
        onSave(Constants.COLOR_SCHEMES.DARK)
      }
    } else {
      const systemColorScheme = Appearance.getColorScheme()
      if (
        systemColorScheme === 'dark' &&
        colorScheme !== Constants.COLOR_SCHEMES.AUTO_DARK
      ) {
        onSave(Constants.COLOR_SCHEMES.AUTO_DARK)
      } else if (colorScheme !== Constants.COLOR_SCHEMES.AUTO_LIGHT) {
        onSave(Constants.COLOR_SCHEMES.AUTO_LIGHT)
      }
    }
  }

  const themeLabel = Constants.COLOR_SCHEME_DISPLAY_NAMES[colorScheme]

  return (
    <Box>
      <Heading margin="none" level={4}>
        {I18n.t('chooseTheme')}
      </Heading>
      <Box margin={{ vertical: 'medium' }}>
        <RadioButtonGroup
          name="theme"
          options={[I18n.t('light'), I18n.t('dark'), I18n.t('systemDefault')]}
          value={themeLabel}
          onChange={event => onSelectColorScheme(event.target.value)}
        />
      </Box>
      <Box
        direction="row"
        justify="center"
        gap="small"
        margin={{ top: 'small' }}>
        <Button onClick={onClose} label={I18n.t('ok')} />
      </Box>
    </Box>
  )
}

export default SelectTheme
