import I18n from 'i18n-js'
import * as RNLocalize from 'react-native-localize'

import i18nEnglishTranslations from './i18nEnglishTranslations'
import i18nSpanishTranslations from './i18nSpanishTranslations'
import i18nJapaneseTranslations from './i18nJapaneseTranslations'
import i18nFrenchTranslations from './i18nFrenchTranslations'
import i18nGermanTranslations from './i18nGermanTranslations'
import i18nPortuguesePTTranslations from './i18nPortuguesePTTranslations'
import i18nPortugueseBRTranslations from './i18nPortugueseBRTranslations'
import i18nIndonesianTranslations from './i18nIndonesianTranslations'
import i18nItalianTranslations from './i18nItalianTranslations'
import i18nRussianTranslations from './i18nRussianTranslations'
import i18nKoreanTranslations from './i18nKoreanTranslations'

const locales = RNLocalize.getLocales()

if (Array.isArray(locales)) {
  I18n.locale =
    window.localStorage.getItem('deviceLocale') || locales[0].languageTag
}

I18n.fallbacks = true
I18n.translations = {
  en: i18nEnglishTranslations,
  es: i18nSpanishTranslations,
  fr: i18nFrenchTranslations,
  de: i18nGermanTranslations,
  ja: i18nJapaneseTranslations,
  'pt-PT': i18nPortuguesePTTranslations,
  'pt-BR': i18nPortugueseBRTranslations,
  id: i18nIndonesianTranslations,
  it: i18nItalianTranslations,
  ru: i18nRussianTranslations,
  ko: i18nKoreanTranslations
}

export default I18n
