import { Button } from 'grommet'
import React from 'react'
import { ThemeContext } from 'styled-components'

const ColoredButtonWithBorder = ({
  buttonTextColor = 'white',
  backgroundColor = 'primary',
  borderColor = 'white',
  ...restProps
}) => {
  return (
    <ThemeContext.Extend
      value={{
        global: {
          colors: {
            text: buttonTextColor,
            control: borderColor
          }
        },
        button: {
          extend: {
            background: backgroundColor
          }
        }
      }}>
      <Button {...restProps} />
    </ThemeContext.Extend>
  )
}

export default ColoredButtonWithBorder
