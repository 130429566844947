import {
  ActionCreators,
  AlarmUtils,
  DateTimeUtils,
  Selectors
} from 'galarm-shared'
import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Header,
  Text,
  CardFooter,
  Menu,
  Button,
  Image
} from 'grommet'
import { Close, MoreVertical } from 'grommet-icons'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import LabelledText from './LabelledText'
import isEmpty from 'lodash/isEmpty'
import { Constants, GlobalConfig, I18n } from 'galarm-config'
import LabelText from './LabelText'
import { NavigationUtils } from 'galarm-ps-api'
import AppCanvas from './AppCanvas'
import AlarmType from './AlarmType'
import CategoryLabel from './CategoryLabel'
import ChatIcon from './ChatIcon'
import objGet from 'lodash/get'
import RichTextEditor from './RichTextEditor'
import ParticipantsListWithAvatar from './ParticipantsListWithAvatar'
import objOmit from 'lodash/omit'
import EntityNotFound from './EntityNotFound'

const { makeAlarmCategoryForAlarmIdSelector, makeOwnAlarmDetailsSelector } =
  Selectors

const InstantAlarmDetailsHeader = ({ alarm }) => {
  const dispatch = useDispatch()

  if (isEmpty(alarm)) {
    return null
  }

  const deleteAlarm = () => {
    NavigationUtils.showAlert(
      I18n.t('deleteAlarm'),
      '',
      [
        {
          text: I18n.t('no')
        },
        {
          text: I18n.t('yes'),
          onPress: deleteAlarmCore
        }
      ],
      false
    )
  }

  const deleteAlarmCore = () => {
    dispatch(ActionCreators.deleteAlarm(alarm.id))
    dispatch(ActionCreators.hideInstantAlarmDetailsScreen())
  }

  const onReportProblem = () => {
    GlobalConfig.showFeedbackScreen({
      type: Constants.TICKET_TYPES.PROBLEM_REPORT,
      extras: JSON.stringify({
        alarm: objOmit(alarm, [
          'name',
          'notes',
          'backupGroup',
          'backupContacts'
        ])
      })
    })
  }

  const closeInstantAlarmDetailsScreen = () => {
    dispatch(ActionCreators.hideInstantAlarmDetailsScreen())
  }

  return (
    <Header className="header" background="brand" pad="small">
      <Text color="white">{I18n.t('alarmDetails')}</Text>
      <Box direction="row">
        <Button icon={<Close />} onClick={closeInstantAlarmDetailsScreen} />
        <Menu
          dropBackground="lightTint"
          icon={<MoreVertical />}
          items={[
            {
              label: I18n.t('deleteAlarmQuickAction'),
              onClick: deleteAlarm
            },
            {
              label: I18n.t('reportAProblem'),
              onClick: onReportProblem
            }
          ]}
        />
      </Box>
    </Header>
  )
}

const InstantAlarmDetails = ({ alarmId }) => {
  console.log('alarmId', alarmId)

  const dispatch = useDispatch()
  const colorScheme = useSelector(state => state.userSettings.webColorScheme)

  const alarmDetaisSelector = makeOwnAlarmDetailsSelector()
  const alarm = useSelector(state =>
    alarmDetaisSelector(state, { alarmId: alarmId })
  )

  const alarmCategorySelector = makeAlarmCategoryForAlarmIdSelector()
  const alarmCategory = useSelector(state =>
    alarmCategorySelector(state, { alarmId: alarmId })
  )

  const alarmCategoryColor = AlarmUtils.getColorForAlarmCategory(
    colorScheme,
    alarmCategory.color
  )

  const alarmConversations = useSelector(state => state.conversations.alarms)

  const unseenMessagesForAlarm = objGet(
    alarmConversations,
    `[${alarmId}].unseenMessages`,
    0
  )

  const currAlarmDate = AlarmUtils.getCurrentDateForAlarm(alarm)
  const alarmEffectiveDate = DateTimeUtils.getMyAlarmDate(currAlarmDate)

  const alarmAcknowledgements =
    AlarmUtils.getAlarmAcknowledgementsForOccurrenceForAlarm(
      alarm,
      currAlarmDate
    )

  const participants = AlarmUtils.getAlarmParticipants(
    alarm.type,
    alarm.backupGroup,
    alarm.backupContacts,
    alarm.recipient
  )

  const numAlarmParticipants = participants.filter(
    participant => participant.state !== Constants.ParticipantStates.INACTIVE
  ).length

  const footerText = AlarmUtils.getCreatorSummaryForInstantAlarm(
    alarmAcknowledgements,
    numAlarmParticipants
  )

  let footer = null

  if (footerText) {
    footer = (
      <Box>
        <Text color="white">{footerText}</Text>
      </Box>
    )
  }

  const creator = {
    id: alarm.creator,
    name: alarm.creatorName,
    mobileNumber: alarm.creatorMobileNumber,
    responseStatus: Constants.ACCEPT_ALARM,
    isCreator: true
  }

  // If the alarm has been deleted and user is in the alarm details screen, then return an empty object.
  // The component is not updated in that case and we will pop out soon.
  if (isEmpty(alarm)) {
    return {}
  }

  const onViewConversation = () => {
    dispatch(ActionCreators.showAlarmConversationScreen(alarm.id))
  }

  const viewConversation = (
    <Box align="center" pad="small" onClick={onViewConversation}>
      <ChatIcon unseenMessages={unseenMessagesForAlarm} />
      <LabelText color="primary">{I18n.t('chat')}</LabelText>
    </Box>
  )

  if (isEmpty(alarm)) {
    return (
      <EntityNotFound
        entityType={I18n.t('entityAlarm')}
        onClose={() => dispatch(ActionCreators.hideInstantAlarmDetailsScreen())}
      />
    )
  }

  return (
    <AppCanvas key={alarmId} overflow="auto">
      <InstantAlarmDetailsHeader alarm={alarm} />
      <Card
        margin="small"
        background="textBackgroundColor"
        width="600px"
        flex={{ shrink: 0 }}>
        <CardHeader pad="small" justify="between">
          <Box flex />
          <AlarmType type={alarm.type} creationMode={alarm.creationMode} />
          <Box flex align="end">
            {alarmCategory.id !== Constants.UNCATEGORIZED_ALARM_CATEGORY_ID && (
              <CategoryLabel
                name={alarmCategory.name}
                color={alarmCategoryColor}
              />
            )}
          </Box>
        </CardHeader>
        <CardBody gap="medium" pad="medium">
          <Box direction="row" gap="medium">
            <LabelledText
              label={I18n.t('date')}
              text={DateTimeUtils.getDateAsString(alarmEffectiveDate)}
              border="bottom"
            />
            <LabelledText
              label={I18n.t('time')}
              text={DateTimeUtils.getTimeAsString(alarmEffectiveDate)}
              border="bottom"
            />
          </Box>
          <LabelledText
            label={I18n.t('title')}
            text={alarm.name}
            border="bottom"
            flex={{ shrink: 0 }}
          />
          <Box border="bottom" direction="row" justify="between">
            <Box>
              <LabelText>{I18n.t('participants')}</LabelText>
              <ParticipantsListWithAvatar
                type={alarm.type}
                creator={creator}
                backupContacts={
                  alarm.backupContacts.length > 0
                    ? [creator, ...alarm.backupContacts]
                    : []
                }
                backupGroup={
                  alarm.backupGroup
                    ? {
                        ...alarm.backupGroup,
                        members: [creator, ...alarm.backupGroup.members]
                      }
                    : null
                }
                recipient={alarm.recipient}
                alarmId={alarm.id}
              />
            </Box>
            {viewConversation}
          </Box>
          {(!!alarm.notes || !!alarm.alarmPhotoUrl) && (
            <Box gap="medium">
              <Box>
                <LabelText color="darkTint">{I18n.t('notes')}</LabelText>
                {alarm.alarmPhotoUrl && (
                  <Box
                    margin={{
                      vertical: 'small',
                      left: 'large'
                    }}
                    width="80%">
                    <Image src={alarm.alarmPhotoUrl} />
                  </Box>
                )}
                {!!alarm.notes && (
                  <Box direction="row" border="bottom">
                    <Text style={{ wordBreak: 'break-all' }}>
                      <RichTextEditor
                        notes={alarm.notes}
                        readOnly="true"
                        toolbarHidden="true"
                      />
                    </Text>
                  </Box>
                )}
              </Box>
            </Box>
          )}
        </CardBody>
        <CardFooter pad="small" background="brand">
          {footer}
        </CardFooter>
      </Card>
    </AppCanvas>
  )
}

export default InstantAlarmDetails
