import { NavigationUtils } from 'galarm-ps-api'
import ActionCreators from '../actions/creators'
import TaskManager from '../api/TaskManager'
import { GlobalConfig } from 'galarm-config'
import { Constants } from 'galarm-config'
import { I18n } from 'galarm-config'
import NetInfo from '@react-native-community/netinfo'
import { FirebaseProxy } from 'galarm-ps-api'

const NetworkUtils = (function () {
  const isNetworkConnected = () => {
    return NetInfo.fetch().then(state => state.isConnected)
  }

  // To be used pre-registration
  const withConnect = (fn, noConnectionHandler, ...args) => {
    return isNetworkConnected().then(isConnected => {
      if (isConnected) {
        fn(...args)
      } else {
        noConnectionHandler()
      }
    })
  }

  const withConnectAndAuthentication = (
    fn,
    isConnected,
    isAuthenticated,
    offlineAlertTitle,
    offlineAlertMessage,
    dispatch,
    ...args
  ) => {
    if (isConnected && isAuthenticated) {
      fn(...args)
    } else if (!isConnected && !isAuthenticated) {
      NavigationUtils.showProgress(
        Constants.ProgressStates.IN_PROGRESS,
        I18n.t('checkingConnection')
      )

      NetInfo.refresh()
        .then(state => {
          if (state.isConnected === true) {
            dispatch(ActionCreators.setConnectionStatus(true))
            dispatch(
              ActionCreators.setProgress(
                Constants.ProgressStates.IN_PROGRESS,
                I18n.t('authenticatingWithServer')
              )
            )
            return TaskManager.addHttpsCloudTask(
              'generateAuthenticationToken',
              {
                uid: GlobalConfig.uid,
                phoneId: GlobalConfig.phoneId || null,
                appSecret: GlobalConfig.appSecret
              }
            )
          }
        })
        .then(taskResult => {
          const authToken = taskResult.result.authToken
          return FirebaseProxy.auth().signInWithCustomToken(authToken)
        })
        // eslint-disable-next-line no-unused-vars
        .then(user => {
          NavigationUtils.dismissProgress()
          dispatch(ActionCreators.setAuthenticatedWithFirebase(true))
          fn(...args)
        })
        // eslint-disable-next-line no-unused-vars
        .catch(error => {
          NavigationUtils.dismissProgress()
          NavigationUtils.showAlert(offlineAlertTitle, offlineAlertMessage)
        })
    } else if (!isConnected) {
      NavigationUtils.showProgress(
        Constants.ProgressStates.IN_PROGRESS,
        I18n.t('checkingConnection')
      )

      NetInfo.refresh().then(state => {
        if (state.isConnected === true) {
          NavigationUtils.dismissProgress()
          dispatch(ActionCreators.setConnectionStatus(true))
          fn(...args)
        } else {
          NavigationUtils.dismissProgress()
          NavigationUtils.showAlert(offlineAlertTitle, offlineAlertMessage)
        }
      })
    } else if (!isAuthenticated) {
      NavigationUtils.showProgress(
        Constants.ProgressStates.IN_PROGRESS,
        I18n.t('authenticatingWithServer')
      )

      TaskManager.addHttpsCloudTask('generateAuthenticationToken', {
        uid: GlobalConfig.uid,
        phoneId: GlobalConfig.phoneId || null,
        appSecret: GlobalConfig.appSecret
      })
        .then(taskResult => {
          const authToken = taskResult.result.authToken
          return FirebaseProxy.auth().signInWithCustomToken(authToken)
        })
        // eslint-disable-next-line no-unused-vars
        .then(user => {
          NavigationUtils.dismissProgress()
          dispatch(ActionCreators.setAuthenticatedWithFirebase(true))
          fn(...args)
        })
        // eslint-disable-next-line no-unused-vars
        .catch(error => {
          NavigationUtils.dismissProgress()
          NavigationUtils.showAlert(offlineAlertTitle, offlineAlertMessage)
        })
    }
  }

  const withConnectAndAuthenticationOptional = (
    fn,
    isConnected,
    isAuthenticated,
    dispatch,
    ...args
  ) => {
    if (!isConnected && !isAuthenticated) {
      NetInfo.refresh()
        .then(state => {
          if (state.isConnected === true) {
            dispatch(ActionCreators.setConnectionStatus(true))
            return TaskManager.addHttpsCloudTask(
              'generateAuthenticationToken',
              {
                uid: GlobalConfig.uid,
                phoneId: GlobalConfig.phoneId || null,
                appSecret: GlobalConfig.appSecret
              }
            )
          }
        })
        .then(taskResult => {
          const authToken = taskResult.result.authToken
          return FirebaseProxy.auth().signInWithCustomToken(authToken)
        })
        // eslint-disable-next-line no-unused-vars
        .then(user => {
          dispatch(ActionCreators.setAuthenticatedWithFirebase(true))
          dispatch(ActionCreators.handlePendingActions())
        })
        .catch(error => {
          console.tron.error(error)
        })
    } else if (!isConnected) {
      NetInfo.refresh().then(state => {
        if (state.isConnected === true) {
          dispatch(ActionCreators.setConnectionStatus(true))
          dispatch(ActionCreators.handlePendingActions())
        }
      })
    } else if (!isAuthenticated) {
      TaskManager.addHttpsCloudTask('generateAuthenticationToken', {
        uid: GlobalConfig.uid,
        phoneId: GlobalConfig.phoneId || null,
        appSecret: GlobalConfig.appSecret
      })
        .then(taskResult => {
          const authToken = taskResult.result.authToken
          return FirebaseProxy.auth().signInWithCustomToken(authToken)
        })
        // eslint-disable-next-line no-unused-vars
        .then(user => {
          dispatch(ActionCreators.setAuthenticatedWithFirebase(true))
          dispatch(ActionCreators.handlePendingActions())
        })
        .catch(error => {
          console.tron.error(error)
        })
    }

    fn(...args)
  }

  const isConnectedAndAuthenticated = (
    isConnected,
    isAuthenticated,
    dispatch
  ) => {
    const promise = new Promise(resolve => {
      if (isConnected && isAuthenticated) {
        resolve(true)
      } else if (!isConnected && !isAuthenticated) {
        NetInfo.refresh()
          .then(state => {
            if (state.isConnected === true) {
              dispatch(ActionCreators.setConnectionStatus(true))
              return TaskManager.addHttpsCloudTask(
                'generateAuthenticationToken',
                {
                  uid: GlobalConfig.uid,
                  phoneId: GlobalConfig.phoneId || null,
                  appSecret: GlobalConfig.appSecret
                }
              )
            }
          })
          .then(taskResult => {
            const authToken = taskResult.result.authToken
            return FirebaseProxy.auth().signInWithCustomToken(authToken)
          })
          // eslint-disable-next-line no-unused-vars
          .then(user => {
            dispatch(ActionCreators.setAuthenticatedWithFirebase(true))
            resolve(true)
          })
          // eslint-disable-next-line no-unused-vars
          .catch(error => {
            resolve(false)
          })
      } else if (!isConnected) {
        NetInfo.refresh().then(state => {
          if (state.isConnected === true) {
            dispatch(ActionCreators.setConnectionStatus(true))
            resolve(true)
          } else {
            resolve(false)
          }
        })
      } else if (!isAuthenticated) {
        TaskManager.addHttpsCloudTask('generateAuthenticationToken', {
          uid: GlobalConfig.uid,
          phoneId: GlobalConfig.phoneId || null,
          appSecret: GlobalConfig.appSecret
        })
          .then(taskResult => {
            const authToken = taskResult.result.authToken
            return FirebaseProxy.auth().signInWithCustomToken(authToken)
          })
          // eslint-disable-next-line no-unused-vars
          .then(user => {
            dispatch(ActionCreators.setAuthenticatedWithFirebase(true))
            resolve(true)
          })
          // eslint-disable-next-line no-unused-vars
          .catch(error => {
            resolve(false)
          })
      }
    })
    return promise
  }

  return {
    withConnect,
    withConnectAndAuthentication,
    withConnectAndAuthenticationOptional,
    isConnectedAndAuthenticated
  }
})()

export default NetworkUtils
