/* eslint-disable */
import * as RNLocalize from 'react-native-localize'

const AlarmManager = (function () {
  const cancelAllNotifications = () => {
    // No op
  }

  const cancelNotifications = alarmId => {
    // No op
  }

  const cancelAlarmNotification = alarmId => {
    // No op
  }

  const cancelSnoozeNotification = alarmId => {
    // No op
  }

  const scheduleSnoozeNotification = (time, alertBody, info) => {
    // No op
  }

  const scheduleNotification = (
    time,
    endDate,
    repeatType,
    repeat,
    creatorTimezone = RNLocalize.getTimeZone(),
    alertBody,
    notificationInfo,
    cancelSnoozeAlarm = true,
    isForSnooze = false
  ) => {
    // No op
  }

  // eslint-disable-next-line no-unused-vars
  const updateRingtonePreferences = alarmRingtone => {
    // No op
  }

  // eslint-disable-next-line no-unused-vars
  const updateVolumePreferences = volume => {
    // No op
  }

  // eslint-disable-next-line no-unused-vars
  const updateVibratePreferences = vibrate => {
    // No op
  }

  // eslint-disable-next-line no-unused-vars
  const updateRingOnVibratePreferences = ringOnVibrate => {
    // No op
  }

  // eslint-disable-next-line no-unused-vars
  const updateTimeFormatPreferences = timeFormat => {
    // No op
  }

  // eslint-disable-next-line no-unused-vars
  const updateRingtoneDurationPreferences = ringtoneDuration => {
    // No op
  }

  // eslint-disable-next-line no-unused-vars
  const updateAutoSnoozePreferences = (enabled, duration, count) => {
    // No op
  }

  const areExistingAlarmsScheduled = () => {
    // No op
  }

  const scheduleExistingAlarms = async () => {
    // No op
  }

  const updateAnnounceAlarmNamePreferences = async () => {
    // No op
  }

  const removeMetadataForAlarm = async () => {
    // No op
  }

  const ringInstantAlarm = () => {
    // No op
  }

  const updateRingOnEarphoneOnlyPreferences = () => {
    // No op
  }

  const updateGestureToDismissAnAlarmPreferences = () => {
    // No op
  }

  const updateGraduallyIncreaseVolumePreferences = () => {
    // No op
  }

  const cancelAlarmPreReminderNotification = () => {
    // No op
  }

  const updateAlarmListWidget = () => {
    // No op
  }

  const muteAlarms = () => {
    // No op
  }

  const unmuteAlarms = () => {
    // No op
  }

  return {
    scheduleNotification,
    updateRingOnVibratePreferences,
    updateRingtonePreferences,
    scheduleSnoozeNotification,
    cancelNotifications,
    cancelAllNotifications,
    cancelAlarmNotification,
    cancelSnoozeNotification,
    areExistingAlarmsScheduled,
    scheduleExistingAlarms,
    updateTimeFormatPreferences,
    updateVolumePreferences,
    updateVibratePreferences,
    updateRingtoneDurationPreferences,
    updateAutoSnoozePreferences,
    updateAnnounceAlarmNamePreferences,
    removeMetadataForAlarm,
    ringInstantAlarm,
    updateRingOnEarphoneOnlyPreferences,
    updateGestureToDismissAnAlarmPreferences,
    updateGraduallyIncreaseVolumePreferences,
    cancelAlarmPreReminderNotification,
    updateAlarmListWidget,
    muteAlarms,
    unmuteAlarms
  }
})()

export default AlarmManager
