import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n } from 'galarm-config'
import { Constants } from 'galarm-config'
import { ActionCreators, Selectors } from 'galarm-shared'
import objGet from 'lodash/get'
import sortBy from 'lodash/sortBy'
import { Box, List, Button, Card, CardBody, Text } from 'grommet'
import AlarmsByCategorySummary from './AlarmsByCategorySummary'
import findIndex from 'lodash/findIndex'
import { SecondaryText } from 'web-components'

const {
  makeAlarmsInCategorySelector,
  alarmCategoriesSelector,
  alertIncidentsSelector
} = Selectors

const EmptyAlarmsByCategorySummary = () => {
  return <Box pad="small" margin="small" />
}

const AlertIncidentsCategorySummary = ({
  alertIncidents,
  onChooseAlarmCategory
}) => {
  return (
    <Card background="red">
      <CardBody direction="row" pad="small">
        <Box flex align="center" gap="small" onClick={onChooseAlarmCategory}>
          <Text color="white">{I18n.t('alertIncidents')}</Text>
          <SecondaryText color="white">
            {I18n.t('numIncidents', {
              count: alertIncidents.length
            })}
          </SecondaryText>
        </Box>
      </CardBody>
    </Card>
  )
}

const AlarmsByCategoriesList = () => {
  const dispatch = useDispatch()

  const alarmsInCategorySelector = makeAlarmsInCategorySelector()

  const [showHiddenCategories, setShowHiddenCategories] = useState(false)

  const alarmCategories = useSelector(alarmCategoriesSelector)
  const numUncategorizedAlarms = useSelector(
    state =>
      alarmsInCategorySelector(state, {
        id: Constants.UNCATEGORIZED_ALARM_CATEGORY_ID
      }).length
  )

  const alertIncidents = useSelector(alertIncidentsSelector)

  const onChooseAlertIncidentsCategory = () => {
    console.log('onChooseAlertIncidentsCategory')
    dispatch(ActionCreators.setShowAlertIncidents(true))
  }

  let sortedAlarmCategories = sortBy(Object.values(alarmCategories), [
    'name'
  ]).sort((alarmCategory1, alarmCategory2) => {
    if (alarmCategory1.disabled && !alarmCategory2.disabled) {
      return 1
    }
    if (alarmCategory2.disabled && !alarmCategory1.disabled) {
      return -1
    }
    const numAlarmsAlarmCategory1 = objGet(
      alarmCategory1,
      'alarmIds',
      []
    ).length
    const numAlarmsAlarmCategory2 = objGet(
      alarmCategory2,
      'alarmIds',
      []
    ).length
    return numAlarmsAlarmCategory2 - numAlarmsAlarmCategory1
  })

  if (numUncategorizedAlarms === 0) {
    const uncategorizedAlarmCategoryIndex = sortedAlarmCategories.findIndex(
      alarmCategory =>
        alarmCategory.id === Constants.UNCATEGORIZED_ALARM_CATEGORY_ID
    )
    sortedAlarmCategories.splice(uncategorizedAlarmCategoryIndex, 1)
  }

  const numDisabledCategories = sortedAlarmCategories.filter(
    sortedAlarmCategory => sortedAlarmCategory.disabled
  ).length

  if (!showHiddenCategories) {
    sortedAlarmCategories = sortedAlarmCategories.filter(
      sortedAlarmCategory => !sortedAlarmCategory.disabled
    )
  } else {
    const indexOfFirstHiddenCategory = findIndex(
      sortedAlarmCategories,
      sortedAlarmCategory => sortedAlarmCategory.disabled
    )
    if (indexOfFirstHiddenCategory !== -1) {
      // Add a section header Hidden alarm categories before starting to show the hidden categories
      sortedAlarmCategories.splice(indexOfFirstHiddenCategory, 0, {
        sectionHeader: true,
        name: I18n.t('hiddenAlarmCategories')
      })
    }
  }

  if (alertIncidents.length > 0) {
    sortedAlarmCategories.unshift({
      type: 'alertIncidents',
      alertIncidents: alertIncidents
    })
  }

  return (
    <Box overflow="auto">
      <List
        border={false}
        margin={{ bottom: '10px' }}
        pad={{ vertical: '5px', horizontal: '20px' }}
        data={Object.values(sortedAlarmCategories)}>
        {alarmCategory => {
          if (alarmCategory.type === 'alertIncidents') {
            return (
              <AlertIncidentsCategorySummary
                alertIncidents={alarmCategory.alertIncidents}
                onChooseAlarmCategory={onChooseAlertIncidentsCategory}
              />
            )
          } else if (alarmCategory.empty) {
            return <EmptyAlarmsByCategorySummary />
          }

          return <AlarmsByCategorySummary alarmCategory={alarmCategory} />
        }}
      </List>
      {numDisabledCategories > 0 && (
        <Button
          plain
          alignSelf="center"
          margin={{ horizontal: '20px', bottom: '20px' }}
          color="primary"
          label={
            showHiddenCategories
              ? I18n.t('dontShowHiddenCategories')
              : I18n.t('showHiddenCategories')
          }
          onClick={() => setShowHiddenCategories(!showHiddenCategories)}
        />
      )}
    </Box>
  )
}

export default AlarmsByCategoriesList
