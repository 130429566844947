import ActionTypes from '../actions/types'
import { createReducer } from 'reduxsauce'
import Utils from '../utils/Utils'

const INITIAL_STATE = {
  connections: []
}

const addConnection = (state, action) => {
  const { id, name, mobileNumber, countryCode } = action.payload
  let connectionIndex = Utils.getIndexOfObjectWithId(state.connections, id)
  let connection = {
    id,
    name,
    mobileNumber,
    countryCode
  }
  let newConnections = state.connections.slice()

  if (connectionIndex === -1) {
    newConnections.push(connection)
  } else {
    const existingConnection = state.connections[connectionIndex]
    newConnections.splice(connectionIndex, 1, {
      ...existingConnection,
      ...connection
    })
  }

  return {
    ...state,
    connections: newConnections
  }
}

const addConnections = (state, action) => {
  const connections = action.payload.connections
  let newConnections = state.connections.slice()
  connections.forEach(connectionToAdd => {
    const { id, name, mobileNumber, countryCode } = connectionToAdd
    let connectionIndex = Utils.getIndexOfObjectWithId(state.connections, id)
    let connection = {
      id,
      name,
      mobileNumber,
      countryCode
    }

    if (connectionIndex === -1) {
      newConnections.push(connection)
    } else {
      const existingConnection = state.connections[connectionIndex]
      newConnections.splice(connectionIndex, 1, {
        ...existingConnection,
        ...connection
      })
    }
  })

  return {
    ...state,
    connections: newConnections
  }
}

const setConnectionAvatarImages = (state, action) => {
  let { id, images } = action.payload
  let index = Utils.getIndexOfObjectWithId(state.connections, id)

  if (index !== -1) {
    let newConnections = state.connections.slice()
    const connection = state.connections[index]
    newConnections.splice(index, 1, {
      ...connection,
      images: images
    })
    return {
      ...state,
      connections: newConnections
    }
  }

  return state
}

const deleteConnectionAvatarImages = (state, action) => {
  let { id } = action.payload
  let images = {}
  let index = Utils.getIndexOfObjectWithId(state.connections, id)

  if (index !== -1) {
    let newConnections = state.connections.slice()
    const connection = state.connections[index]
    newConnections.splice(index, 1, {
      ...connection,
      images: images
    })
    return {
      ...state,
      connections: newConnections
    }
  }

  return state
}

const ACTION_HANDLERS = {
  [ActionTypes.ADD_CONNECTION]: addConnection,
  [ActionTypes.ADD_CONNECTIONS]: addConnections,
  [ActionTypes.SET_CONNECTION_AVATAR_IMAGES]: setConnectionAvatarImages,
  [ActionTypes.DELETE_CONNECTION_AVATAR_IMAGES]: deleteConnectionAvatarImages
}

export default createReducer(INITIAL_STATE, ACTION_HANDLERS)
