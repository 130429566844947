import React from 'react'
import { Spinner, Box, Button } from 'grommet'

const LoadEarlier = props => {
  if (props.isLoadingEarlier === false) {
    return (
      <Box style={{ minHeight: '50px' }}>
        <Button
          alignSelf="center"
          margin="small"
          onClick={props.onLoadEarlier}
          label={props.label}
        />
      </Box>
    )
  }
  return (
    <Box style={{ minHeight: '50px' }}>
      <Button label={props.label} />
      <Spinner color="white" size="small" />
    </Box>
  )
}
export default LoadEarlier

LoadEarlier.defaultProps = {
  onLoadEarlier: () => {},
  isLoadingEarlier: false,
  label: 'Load earlier messages',
  containerStyle: {},
  wrapperStyle: {},
  textStyle: {},
  activityIndicatorStyle: {}
}
