import React from 'react'
import { I18n } from 'galarm-config'
import { Text, Box, List } from 'grommet'

const MemberSummary = item => {
  return <Text>{item.name}</Text>
}

const MembersList = ({ members }) => {
  if (members.length === 0) {
    return (
      <Text margin={{ top: 'xsmall', left: 'small' }}>{I18n.t('none')}</Text>
    )
  }

  if (members.length > 0) {
    return (
      <List
        data={members}
        border={false}
        margin="none"
        pad={{ top: 'xsmall', left: 'small' }}
        // eslint-disable-next-line react/no-children-prop
        children={MemberSummary}
      />
    )
  }

  return <Box />
}

export default MembersList
