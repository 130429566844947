import React, { useState } from 'react'
import { Box, Text, RangeInput, Menu, CheckBox } from 'grommet'
import { useSelector } from 'react-redux'
import { Constants, GlobalConfig, I18n } from 'galarm-config'
import HoverBox from './HoverBox'
import ConfigureAutoSnooze from './ConfigureAutoSnooze'
import { SecondaryText } from 'web-components'
import { Selectors } from 'galarm-shared'
import { NavigationUtils } from 'galarm-ps-api'

const { upgradePurchasedSelector } = Selectors

const ConfigureSoundAndVibration = ({
  ringerSettings,
  onSaveRingerSettings,
  isGlobal
}) => {
  const upgradePurchased = useSelector(upgradePurchasedSelector)

  const defaultAdditionalConfigurationToShow = {
    autoSnoozeConfigurationPicker: false
  }

  const [additionalConfigurationToShow, setAdditionalConfigurationToShow] =
    useState(defaultAdditionalConfigurationToShow)

  const hideAdditionalConfiguration = () =>
    setAdditionalConfigurationToShow(defaultAdditionalConfigurationToShow)

  const displayAutoSnoozeConfiguration = () =>
    setAdditionalConfigurationToShow({
      ...defaultAdditionalConfigurationToShow,
      autoSnoozeConfigurationPicker: true
    })

  const deviceOs = useSelector(state => state.userInfo.deviceOs)

  const onRingtoneChange = ringtone => {
    onSaveRingerSettings({
      ...ringerSettings,
      alarmRingtone: ringtone
    })
  }

  const onRingtoneDurationChange = newValue => {
    onSaveRingerSettings({
      ...ringerSettings,
      ringtoneDuration: newValue
    })
  }

  const onVolumeChange = event => {
    onSaveRingerSettings({
      ...ringerSettings,
      volume: parseInt(event.target.value)
    })
  }

  const onVibrateChange = event => {
    onSaveRingerSettings({
      ...ringerSettings,
      vibrate: event.target.checked
    })
  }

  const onAutoSnoozeChange = event => {
    onSaveRingerSettings({
      ...ringerSettings,
      autoSnooze: event.target.checked
    })
  }

  const onRingOnVibrateChange = event => {
    onSaveRingerSettings({
      ...ringerSettings,
      ringOnVibrate: event.target.checked
    })
  }

  const onAnnounceAlarmNameChange = event => {
    if (!upgradePurchased && event.target.checked) {
      NavigationUtils.showGalarmPremiumScreen('announceAlarmNamePremiumFeature')
      return
    }

    onSaveRingerSettings({
      ...ringerSettings,
      announceAlarmName: event.target.checked
    })
  }

  const ringtoneItems = GlobalConfig.ringtones.map(ringtone => ({
    label: ringtone.label,
    onClick: () => onRingtoneChange(ringtone)
  }))

  const ringtoneDurations = Constants.RingtoneDurations.map(item => ({
    label: item.label,
    onClick: () => onRingtoneDurationChange(item.value)
  }))

  const ringtoneDurationLabel = Constants.RingtoneDurations.find(
    item => item.value === ringerSettings.ringtoneDuration
  ).label

  // Auto-snooze count is set to 2 for iOS
  const autoSnoozeCountLabel =
    deviceOs === 'ios'
      ? I18n.t('nTimes', { count: 2 })
      : Constants.AutoSnoozeCounts.find(obj => {
          return obj.value === ringerSettings.autoSnoozeCount
        }).label

  const autoSnoozeDurationLabel = Constants.AutoSnoozeDurations.find(obj => {
    return obj.value === ringerSettings.autoSnoozeDuration
  }).label

  return (
    <Box flex direction="row">
      <Box width={'450px'}>
        <HoverBox onClick={hideAdditionalConfiguration}>
          <Text>{I18n.t('alarmRingtone')}</Text>
          <Menu
            dropBackground="lightTint"
            label={ringerSettings.alarmRingtone.label}
            items={ringtoneItems}
          />
        </HoverBox>
        {deviceOs === 'android' && (
          <>
            <HoverBox onClick={hideAdditionalConfiguration}>
              <Text>{I18n.t('ringtoneDuration')}</Text>
              <Menu
                dropBackground="lightTint"
                label={ringtoneDurationLabel}
                items={ringtoneDurations}
              />
            </HoverBox>
            <HoverBox onClick={hideAdditionalConfiguration}>
              <Text>{I18n.t('volume')}</Text>
              <Box basis="1/2">
                <RangeInput
                  basis="1/2"
                  min={0}
                  max={100}
                  step={20}
                  value={ringerSettings.volume}
                  onChange={onVolumeChange}
                />
              </Box>
            </HoverBox>
            <HoverBox onClick={hideAdditionalConfiguration}>
              <Text>{I18n.t('vibrate')}</Text>
              <Box>
                <CheckBox
                  toggle={true}
                  checked={ringerSettings.vibrate}
                  onChange={onVibrateChange}
                />
              </Box>
            </HoverBox>
          </>
        )}
        <HoverBox
          onClick={() => {
            GlobalConfig.forceUpdate()
            displayAutoSnoozeConfiguration()
          }}>
          <Box>
            <Text>
              {isGlobal ? I18n.t('autoSnooze') : I18n.t('autoSnoozePerAlarm')}
            </Text>
            <SecondaryText>
              {I18n.t('autoSnoozeConfigurationSummary', {
                autoSnoozeCountLabel,
                autoSnoozeDurationLabel
              })}
            </SecondaryText>
          </Box>
          <Box>
            <CheckBox
              toggle={true}
              checked={ringerSettings.autoSnooze}
              onChange={onAutoSnoozeChange}
            />
          </Box>
        </HoverBox>
        {deviceOs === 'android' && (
          <>
            <HoverBox onClick={hideAdditionalConfiguration}>
              <Text>{I18n.t('ringOnVibrate')}</Text>
              <Box>
                <CheckBox
                  toggle={true}
                  checked={ringerSettings.ringOnVibrate}
                  onChange={onRingOnVibrateChange}
                />
              </Box>
            </HoverBox>
            <HoverBox onClick={hideAdditionalConfiguration}>
              <Text>{I18n.t('announceAlarmName')}</Text>
              <Box>
                <CheckBox
                  toggle={true}
                  checked={ringerSettings.announceAlarmName}
                  onChange={onAnnounceAlarmNameChange}
                />
              </Box>
            </HoverBox>
          </>
        )}
      </Box>
      {additionalConfigurationToShow.autoSnoozeConfigurationPicker && (
        <Box border="left">
          <ConfigureAutoSnooze
            ringerSettings={ringerSettings}
            onSaveRingerSettings={onSaveRingerSettings}
          />
        </Box>
      )}
    </Box>
  )
}

export default ConfigureSoundAndVibration
