import React, { useState } from 'react'
import { GlobalConfig, I18n } from 'galarm-config'
import { Box, Heading, RadioButtonGroup, Button } from 'grommet'
import moment from 'moment-timezone'
import { useDispatch, useSelector } from 'react-redux'
import { ActionCreators, Selectors } from 'galarm-shared'

const DeleteInactiveAlarms = ({ onClose }) => {
  const alarms = useSelector(state => Selectors.allAlarmsSelector(state))

  const [option, setOption] = useState('')
  const dispatch = useDispatch()

  const weekOldDate = moment().subtract(7, 'days').valueOf()
  const monthOldDate = moment().subtract(1, 'months').valueOf()
  const weekOldAlarmIds = alarms
    .filter(alarm => !alarm.status)
    .filter(alarm => alarm.creator === GlobalConfig.uid)
    .filter(alarm => {
      if (alarm.repeatType === '') {
        return alarm.date < weekOldDate
      } else {
        return alarm.lastUpdatedAt < weekOldDate
      }
    })
    .map(alarm => alarm.id)
  const monthOldAlarmIds = alarms
    .filter(alarm => !alarm.status)
    .filter(alarm => alarm.creator === GlobalConfig.uid)
    .filter(alarm => {
      if (alarm.repeatType === '') {
        return alarm.date < monthOldDate
      } else {
        return alarm.lastUpdatedAt < monthOldDate
      }
    })
    .map(alarm => alarm.id)
  const allPastAlarmIds = alarms
    .filter(alarm => !alarm.status)
    .filter(alarm => alarm.creator === GlobalConfig.uid)
    .map(alarm => alarm.id)

  const options = [
    I18n.t('deletePastAlarmsOption', {
      option: I18n.t('aWeek'),
      count: weekOldAlarmIds.length
    }),
    I18n.t('deletePastAlarmsOption', {
      option: I18n.t('aMonth'),
      count: monthOldAlarmIds.length
    }),
    I18n.t('deletePastAlarmsOption', {
      option: I18n.t('allInactiveAlarms'),
      count: allPastAlarmIds.length
    })
  ]

  const deleteInactiveAlarms = () => {
    if (option === options[0]) {
      dispatch(
        ActionCreators.deleteAllAlarms(weekOldAlarmIds, 'deletePastAlarms')
      )
    } else if (option === options[1]) {
      dispatch(
        ActionCreators.deleteAllAlarms(monthOldAlarmIds, 'deletePastAlarms')
      )
    } else if (option === options[2]) {
      dispatch(
        ActionCreators.deleteAllAlarms(allPastAlarmIds, 'deletePastAlarms')
      )
    }

    onClose()
  }

  return (
    <Box>
      <Heading margin="none" level={4}>
        {I18n.t('deletePastAlarmsTitle')}
      </Heading>
      <Box margin={{ vertical: 'medium' }}>
        <RadioButtonGroup
          name="deletealarms"
          options={options}
          value={option}
          onChange={event => setOption(event.target.value)}
        />
      </Box>
      <Box
        direction="row"
        justify="center"
        gap="small"
        margin={{ top: 'small' }}>
        <Button onClick={onClose} label={I18n.t('cancel')} />
        <Button onClick={deleteInactiveAlarms} label={I18n.t('delete')} />
      </Box>
    </Box>
  )
}

export default DeleteInactiveAlarms
