import React, { useEffect, useState, useRef } from 'react'
import { Box, TextInput, Layer, Text, Image } from 'grommet'
import TimeInput from './TimeInput'
import DateInput from './DateInput'
import { FormNext } from 'grommet-icons'
import { Constants, I18n, GlobalConfig } from 'galarm-config'
import { AlarmUtils, Utils } from 'galarm-shared'
import ConfigureAlarmRepetition from './ConfigureAlarmRepetition'
import ConfigureSoundAndVibration from './ConfigureSoundAndVibration'
import ConfigureAlarmCategory from './ConfigureAlarmCategory'
import ConfigureAlarmParticipants from './ConfigureAlarmParticipants'
import ConfigureEndDate from './ConfigureEndDate'
import EditAlarmHeader from './EditAlarmHeader'
import HoverBox from './HoverBox'
import LabelledText from './LabelledText'
import LabelText from './LabelText'
import { NavigationUtils } from 'galarm-ps-api'
import objPick from 'lodash/pick'
import ParticipantsList from './ParticipantsList'
import ConfigureAlarmRecipient from './ConfigureAlarmRecipient'
import { useSelector } from 'react-redux'
import TintedImage from './TintedImage'
import RichTextEditor from './RichTextEditor'
import ConfigureCascadingAlarmInterval from './ConfigureCascadingAlarmInterval'
import ConfigureRecipientAlarmInterval from './ConfigureRecipientAlarmInterval'
import SelectTimezone from './SelectTimezone'
import { ChooseDurationContainer } from 'web-components'
import SelectImage from './SelectImage'
import { Selectors } from 'galarm-shared'

const { upgradePurchasedSelector } = Selectors

const EditAlarm = props => {
  const defaultAdditionalConfigurationToShow = {
    repetitionPicker: false,
    endDatePicker: false,
    soundAndVibrationPicker: false,
    alarmCategoryPicker: false,
    participantsPicker: false,
    recipientPicker: false,
    cascadingAlarmIntervalPicker: false,
    recipientAlarmIntervalPicker: false,
    preReminderPicker: false
  }

  const colorScheme = useSelector(state => state.userSettings.webColorScheme)
  const upgradePurchased = useSelector(upgradePurchasedSelector)

  const [showNotes, setShowNotes] = useState(false)
  const [showAlarmPhotoSelector, setShowAlarmPhotoSelector] = useState(false)

  const displayAlarmPhotoSelector = event => {
    event.stopPropagation()

    if (!upgradePurchased) {
      NavigationUtils.showGalarmPremiumScreen('alarmPhotoPremiumFeature')
      return
    }

    setShowAlarmPhotoSelector(true)
  }

  const hideAlarmPhotoSelector = () => {
    setShowAlarmPhotoSelector(false)
  }

  const options = [I18n.t('uploadImage'), I18n.t('removeImage')]

  const scrollToRightRef = useRef(null)
  const inputFile = useRef(null)

  const onSelectFile = event => {
    var file = event.target.files[0]
    // Fix for the issue of adding the same file
    event.target.value = ''
    if (file.size > GlobalConfig.maxProfileImageSize) {
      NavigationUtils.showAlert(
        I18n.t('cantUploadImage'),
        I18n.t('selectSmallerImage')
      )
    } else {
      props.onUploadAlarmPhoto(file)
    }
  }

  const [additionalConfigurationToShow, setAdditionalConfigurationToShow] =
    useState(defaultAdditionalConfigurationToShow)

  const hideAdditionalConfiguration = () =>
    setAdditionalConfigurationToShow(defaultAdditionalConfigurationToShow)

  const displayRepetitionPicker = () =>
    setAdditionalConfigurationToShow({
      ...defaultAdditionalConfigurationToShow,
      repetitionPicker: true
    })

  const displayPreReminderPicker = () => {
    if (!upgradePurchased) {
      NavigationUtils.showGalarmPremiumScreen('preReminderPremiumFeature')
      return
    }

    setAdditionalConfigurationToShow({
      ...defaultAdditionalConfigurationToShow,
      preReminderPicker: true
    })
  }

  const hidePreReminderPicker = () =>
    setAdditionalConfigurationToShow({
      ...defaultAdditionalConfigurationToShow,
      preReminderPicker: false
    })

  const displaySoundAndVibrationPicker = () =>
    setAdditionalConfigurationToShow({
      ...defaultAdditionalConfigurationToShow,
      soundAndVibrationPicker: true
    })

  const displayAlarmCategoryPicker = () =>
    setAdditionalConfigurationToShow({
      ...defaultAdditionalConfigurationToShow,
      alarmCategoryPicker: true
    })

  const displayEndDatePicker = () =>
    setAdditionalConfigurationToShow({
      ...defaultAdditionalConfigurationToShow,
      endDatePicker: true
    })

  const hideEndDatePicker = () =>
    setAdditionalConfigurationToShow(defaultAdditionalConfigurationToShow)

  const displayParticipantsPicker = () =>
    setAdditionalConfigurationToShow({
      ...defaultAdditionalConfigurationToShow,
      participantsPicker: true
    })

  const displayRecipientPicker = () =>
    setAdditionalConfigurationToShow({
      ...defaultAdditionalConfigurationToShow,
      recipientPicker: true
    })

  const displayCascadingAlarmIntervalPicker = () =>
    setAdditionalConfigurationToShow({
      ...defaultAdditionalConfigurationToShow,
      cascadingAlarmIntervalPicker: true
    })

  const displayRecipientAlarmIntervalPicker = () =>
    setAdditionalConfigurationToShow({
      ...defaultAdditionalConfigurationToShow,
      recipientAlarmIntervalPicker: true
    })

  const onNameChange = event => props.onNameChange(event.target.value)
  const onDateChange = newDate =>
    props.datePickerProps.onDateChange(new Date(newDate))
  const onTimeChange = newTime => {
    props.datePickerProps.onDateChange(newTime)
  }

  const nextAlarmDate = AlarmUtils.getNextDateForAlarm(
    props.date,
    props.endDate,
    props.repeatType,
    props.repeat,
    props.creatorTimezone
  )

  const onSelectRecipient = recipient => {
    if (props.recipient && props.recipient.id === recipient.id) {
      props.onSaveRecipient([])
    } else {
      props.onSaveRecipient([
        objPick(recipient, ['id', 'name', 'mobileNumber'])
      ])
    }
  }

  // API to be invoked when a participant is un selected e.g. clicking on the avatar cross
  const onUnselectParticipant = contactId => {
    let newSelectedContacts = props.backupContacts.slice()
    newSelectedContacts.splice(
      Utils.getIndexOfObjectWithId(props.backupContacts, contactId),
      1
    )
    props.onSaveBackups(newSelectedContacts, null)
  }

  const onSaveTimezone = (selectedTimezone, isSelected) => {
    if (isSelected) {
      props.onSaveTimezone(selectedTimezone.value)
    } else {
      props.onSaveTimezone(null)
    }
  }

  // This API is to update the order of participants in the re order participants screen
  const updateParticipantsOrder = participants => {
    props.onSaveBackups(participants, null)
  }

  const onSelectParticipant = participant => {
    if (participant.participantType === Constants.ParticipantTypes.CONTACT) {
      if (props.backupGroup) {
        NavigationUtils.showAlert(
          '',
          I18n.t('previouslySelectedGroupIsUnselected')
        )
      }
      let newBackupContacts
      if (participant.selected) {
        newBackupContacts = props.backupContacts.filter(
          item => item.id !== participant.id
        )
      } else {
        newBackupContacts = props.backupContacts.concat([
          objPick(participant, ['id', 'name', 'mobileNumber'])
        ])
      }
      props.onSaveBackups(newBackupContacts, null)
    } else if (
      participant.participantType === Constants.ParticipantTypes.GROUP
    ) {
      if (props.backupContacts.length > 0) {
        NavigationUtils.showAlert(
          '',
          I18n.t('previouslySelectedContactsAreUnselected')
        )
      }

      if (props.backupGroup && props.backupGroup.id === participant.id) {
        props.onSaveBackups([], null)
      } else {
        props.onSaveBackups([], objPick(participant, ['id', 'name', 'members']))
      }
    }
  }

  const deviceOs = useSelector(state => state.userInfo.deviceOs)

  useEffect(() => {
    scrollToRightRef.current.scrollIntoView({ behavior: 'smooth' })
  }, [])

  // eslint-disable-next-line no-unused-vars
  const handleChangeNotes = event => {
    setShowNotes(true)
  }

  const updateMembersConfig = members => {
    let newMembers = props.backupGroup.members.map(member => {
      const newMember = Utils.getObjectWithId(members, member.id)
      return {
        ...member,
        state: newMember.state,
        order: newMember.order
      }
    })

    let newBackupGroup = {
      ...props.backupGroup,
      members: newMembers
    }

    props.onSaveBackups([], newBackupGroup)
  }

  const onSelectUploadImage = () => {
    inputFile.current.click()
  }

  const onRemoveImage = () => {
    props.onDeleteAlarmPhoto()
  }

  const onShowAlarmPhoto = event => {
    event.stopPropagation()

    if (!upgradePurchased) {
      NavigationUtils.showGalarmPremiumScreen('alarmPhotoPremiumFeature')
      return
    }

    inputFile.current.click()
  }

  return (
    <Box flex background="textBackgroundColor">
      <EditAlarmHeader
        alarmData={{ name: props.name, type: props.type, isEdit: props.isEdit }}
        onSave={props.onSaveAlarm}
      />
      <Box flex overflow={{ horizontal: 'auto' }}>
        <Box flex direction="row" width={{ min: '1200px' }}>
          <Box
            pad={{ vertical: 'small' }}
            border="right"
            fill="vertical"
            width={'450px'}
            overflow="auto">
            <Box flex>
              <HoverBox onClick={hideAdditionalConfiguration}>
                <TintedImage
                  src={require('galarm-res/img/web/ic_date.svg').default}
                  tintColor="darkTint"
                />
                <Box flex>
                  <LabelText>{I18n.t('date')}</LabelText>
                  <DateInput
                    value={props.datePickerProps.date}
                    onChange={onDateChange}
                  />
                </Box>
              </HoverBox>
              <HoverBox>
                <TintedImage
                  src={require('galarm-res/img/web/ic_time.svg').default}
                  tintColor="darkTint"
                />
                <Box flex>
                  <LabelText>
                    {props.type === Constants.AlarmTypes.RECIPIENT
                      ? I18n.t('timeForBuddy')
                      : I18n.t('time')}
                  </LabelText>
                  <TimeInput
                    value={props.datePickerProps.date.getTime()}
                    onChange={onTimeChange}
                  />
                </Box>
              </HoverBox>
              {(props.specifyTimezoneForAlarm ||
                props.timezoneSetting ===
                  Constants.TIMEZONE_SETTINGS.EXPLICIT) && (
                <HoverBox>
                  <TintedImage
                    src={require('galarm-res/img/web/ic_timezone.svg').default}
                    tintColor="darkTint"
                  />
                  <Box flex>
                    <LabelText>{I18n.t('timezone')}</LabelText>
                    <SelectTimezone
                      selectedTimezone={
                        props.timezoneSetting ===
                        Constants.TIMEZONE_SETTINGS.EXPLICIT
                          ? props.creatorTimezone
                          : null
                      }
                      onSaveTimezone={onSaveTimezone}
                    />
                  </Box>
                </HoverBox>
              )}
              <HoverBox onClick={hideAdditionalConfiguration}>
                <TintedImage
                  tintColor="darkTint"
                  src={require('galarm-res/img/web/ic_title.svg').default}
                />
                <Box flex>
                  <LabelText>{I18n.t('title')}</LabelText>
                  <TextInput
                    flex
                    value={props.name}
                    placeholder={AlarmUtils.getDefaultAlarmName(props.type)}
                    onChange={onNameChange}
                  />
                </Box>
              </HoverBox>
              <HoverBox
                id="repetition-picker"
                onClick={displayRepetitionPicker}
                background={
                  additionalConfigurationToShow.repetitionPicker
                    ? 'lightPrimary'
                    : 'textBackgroundColor'
                }>
                <TintedImage
                  tintColor="darkTint"
                  src={require('galarm-res/img/web/ic_repeat.svg').default}
                />
                <LabelledText
                  label={I18n.t('repeat')}
                  text={props.repeatString}
                />
                <FormNext />
              </HoverBox>
              {props.repeatType !== '' && (
                <HoverBox
                  onClick={displayEndDatePicker}
                  background={
                    additionalConfigurationToShow.endDatePicker
                      ? 'lightPrimary'
                      : 'textBackgroundColor'
                  }>
                  <TintedImage
                    tintColor="darkTint"
                    src={require('galarm-res/img/web/ic_pause.svg').default}
                  />
                  <LabelledText
                    label={I18n.t('endDate')}
                    text={props.endDateString}
                  />
                  <FormNext />
                </HoverBox>
              )}
              <HoverBox
                onClick={displaySoundAndVibrationPicker}
                background={
                  additionalConfigurationToShow.soundAndVibrationPicker
                    ? 'lightPrimary'
                    : 'textBackgroundColor'
                }>
                <TintedImage
                  tintColor="darkTint"
                  src={require('galarm-res/img/web/ic_music.svg').default}
                />
                <LabelledText
                  label={I18n.t('soundAndVibration')}
                  text={
                    deviceOs === 'ios'
                      ? AlarmUtils.getIosRingerSettingsLabel(
                          props.ringerSettings
                        )
                      : AlarmUtils.getAndroidRingerSettingsLabel(
                          props.ringerSettings
                        )
                  }
                />
                <FormNext />
              </HoverBox>
              {props.type !== Constants.AlarmTypes.RECIPIENT && (
                <HoverBox
                  onClick={displayPreReminderPicker}
                  background={
                    additionalConfigurationToShow.preReminderPicker
                      ? 'lightPrimary'
                      : 'textBackgroundColor'
                  }>
                  <TintedImage
                    tintColor="darkTint"
                    src={
                      require('galarm-res/img/web/ic_pre_reminder.svg').default
                    }
                  />
                  <LabelledText
                    label={I18n.t('preReminder')}
                    text={AlarmUtils.getPreReminderLabel(
                      props.preReminderDuration
                    )}
                  />
                  <FormNext />
                </HoverBox>
              )}
              <HoverBox
                onClick={displayAlarmCategoryPicker}
                background={
                  additionalConfigurationToShow.alarmCategoryPicker
                    ? 'lightPrimary'
                    : 'textBackgroundColor'
                }>
                <TintedImage
                  tintColor="darkTint"
                  src={
                    require('galarm-res/img/web/ic_category_view.svg').default
                  }
                />
                <LabelledText
                  label={I18n.t('alarmCategory')}
                  text={props.alarmCategory.name}
                />
                <FormNext />
              </HoverBox>
              {props.type !== Constants.AlarmTypes.RECIPIENT && (
                <>
                  <HoverBox
                    onClick={displayParticipantsPicker}
                    background={
                      additionalConfigurationToShow.participantsPicker
                        ? 'lightPrimary'
                        : 'textBackgroundColor'
                    }>
                    <Box direction="row" gap="medium" align="start">
                      <TintedImage
                        tintColor="darkTint"
                        src={
                          require('galarm-res/img/web/ic_person.svg').default
                        }
                        margin={{ vertical: '15px' }}
                      />
                      <Box>
                        <LabelText>{I18n.t('participants')}</LabelText>
                        <ParticipantsList
                          type={props.type}
                          backupGroup={props.backupGroup}
                          backupContacts={props.backupContacts}
                          recipient={props.recipient}
                        />
                      </Box>
                    </Box>
                    <FormNext />
                  </HoverBox>
                  {(props.backupGroup || props.backupContacts.length > 0) &&
                    props.type === Constants.AlarmTypes.CASCADING && (
                      <HoverBox
                        justify="start"
                        onClick={displayCascadingAlarmIntervalPicker}>
                        <TintedImage
                          tintColor="darkTint"
                          src={
                            require('galarm-res/img/web/ic_timer.svg').default
                          }
                          margin={{ vertical: '15px' }}
                        />
                        <LabelledText
                          label={I18n.t('cascadingAlarmInterval')}
                          text={
                            Constants.CascadingAlarmIntervals[
                              props.cascadingAlarmInterval
                            ].label
                          }
                        />
                        <FormNext />
                      </HoverBox>
                    )}
                </>
              )}
              {props.type === Constants.AlarmTypes.RECIPIENT && (
                <>
                  <HoverBox
                    onClick={displayRecipientPicker}
                    background={
                      additionalConfigurationToShow.recipientPicker
                        ? 'lightPrimary'
                        : 'textBackgroundColor'
                    }>
                    <Box direction="row" gap="medium">
                      <TintedImage
                        tintColor="darkTint"
                        src={
                          require('galarm-res/img/web/ic_person.svg').default
                        }
                      />
                      <Box>
                        <LabelText>{I18n.t('recipient')}</LabelText>
                        <ParticipantsList
                          type={props.type}
                          backupGroup={props.backupGroup}
                          backupContacts={props.backupContacts}
                          recipient={props.recipient}
                        />
                      </Box>
                    </Box>
                    <FormNext />
                  </HoverBox>
                  {props.recipient && (
                    <HoverBox
                      justify="start"
                      onClick={displayRecipientAlarmIntervalPicker}>
                      <TintedImage
                        tintColor="darkTint"
                        src={require('galarm-res/img/web/ic_timer.svg').default}
                        margin={{ vertical: '15px' }}
                      />
                      <LabelledText
                        label={I18n.t('remindMeAfter')}
                        text={
                          Constants.RecipientAlarmIntervals[
                            props.recipientAlarmInterval
                          ].label
                        }
                      />
                      <FormNext />
                    </HoverBox>
                  )}
                </>
              )}
              <HoverBox
                onClick={() => {
                  hideAdditionalConfiguration()
                  handleChangeNotes()
                }}>
                <Box flex>
                  <Box flex direction="row" align="center" gap="medium">
                    <TintedImage
                      tintColor="darkTint"
                      src={require('galarm-res/img/web/ic_notes.svg').default}
                      alignSelf="start"
                    />
                    <Box
                      flex
                      direction="row"
                      align="center"
                      justify="between"
                      gap="medium">
                      <Text color="darkTint">{I18n.t('notes')}</Text>
                      <Box direction="row" gap="medium">
                        <TintedImage
                          src={
                            require('galarm-res/img/web/ic_alarm_text.svg')
                              .default
                          }
                        />
                        <Box onClick={onShowAlarmPhoto}>
                          <TintedImage
                            src={
                              require('galarm-res/img/web/ic_alarm_photo.svg')
                                .default
                            }
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box>
                    {!!props.alarmPhotoUrl && (
                      <Box
                        onClick={displayAlarmPhotoSelector}
                        margin={{
                          vertical: 'small',
                          left: 'large'
                        }}
                        width="80%">
                        <Image src={props.alarmPhotoUrl} />
                      </Box>
                    )}
                    {!!props.notes && (
                      <Box flex margin={{ right: 'small', left: 'large' }}>
                        <RichTextEditor
                          notes={props.notes}
                          onNotesChange={props.onNotesChange}
                          readOnly="true"
                          linksDisabled="true"
                        />
                      </Box>
                    )}
                  </Box>
                </Box>
              </HoverBox>
            </Box>
          </Box>
          {additionalConfigurationToShow.repetitionPicker && (
            <Box border="right">
              <ConfigureAlarmRepetition
                date={props.date}
                repeatType={props.repeatType}
                repeat={props.repeat}
                onRepeatChange={props.onRepeatChange}
              />
            </Box>
          )}
          {additionalConfigurationToShow.endDatePicker && (
            <Box border="right">
              <ConfigureEndDate
                endDateString={props.endDateString}
                endDateOptions={props.computeEndDateOptions()}
                onEndDateChange={props.onEndDateChange}
                minDate={props.date}
                hideEndDatePicker={hideEndDatePicker}
              />
            </Box>
          )}
          {additionalConfigurationToShow.soundAndVibrationPicker && (
            <Box border="right">
              <ConfigureSoundAndVibration
                ringerSettings={props.ringerSettings}
                onSaveRingerSettings={props.onSaveRingerSettings}
              />
            </Box>
          )}
          {additionalConfigurationToShow.alarmCategoryPicker && (
            <Box border="right">
              <ConfigureAlarmCategory
                selectedAlarmCategory={props.alarmCategory}
                onSelectAlarmCategory={props.onSelectAlarmCategory}
                onUnselectAlarmCategory={props.onUnselectAlarmCategory}
              />
            </Box>
          )}
          {additionalConfigurationToShow.participantsPicker && (
            <Box border="right">
              <ConfigureAlarmParticipants
                type={props.type}
                backupContacts={props.backupContacts}
                backupGroup={props.backupGroup}
                onSelectParticipant={onSelectParticipant}
                onUnselectParticipant={onUnselectParticipant}
                updateParticipantsOrder={updateParticipantsOrder}
                updateMembersConfig={updateMembersConfig}
              />
            </Box>
          )}
          {additionalConfigurationToShow.recipientPicker && (
            <Box border="right">
              <ConfigureAlarmRecipient
                recipient={props.recipient}
                onSelectRecipient={onSelectRecipient}
              />
            </Box>
          )}
          {additionalConfigurationToShow.cascadingAlarmIntervalPicker && (
            <Box border="right">
              <ConfigureCascadingAlarmInterval
                cascadingAlarmInterval={props.cascadingAlarmInterval}
                onSaveCascadingAlarmInterval={
                  props.onSaveCascadingAlarmInterval
                }
              />
            </Box>
          )}
          {additionalConfigurationToShow.recipientAlarmIntervalPicker && (
            <Box border="right">
              <ConfigureRecipientAlarmInterval
                recipientAlarmInterval={props.recipientAlarmInterval}
                onSaveRecipientAlarmInterval={
                  props.onSaveRecipientAlarmInterval
                }
              />
            </Box>
          )}
          {additionalConfigurationToShow.preReminderPicker && (
            <Layer background="lightTint">
              <Box margin="medium">
                <ChooseDurationContainer
                  onSaveDuration={duration => {
                    hidePreReminderPicker()
                    props.onSaveDurationForPreReminder(duration)
                  }}
                  getDisplayTextForDuration={duration =>
                    AlarmUtils.getDisplayTextForPreReminderDuration(
                      nextAlarmDate,
                      duration
                    )
                  }
                  getDurationAsString={duration =>
                    AlarmUtils.getPreReminderDurationAsString(duration)
                  }
                  title={I18n.t('preReminder')}
                  intervals={Constants.PRE_REMINDER_INTERVALS}
                  initialDuration={props.preReminderDuration}
                  isValidDuration={duration =>
                    props.isValidDurationForPreReminder(duration)
                  }
                  onClose={() => hidePreReminderPicker()}
                  additionalHelperText={duration =>
                    AlarmUtils.getPreReminderHelperText(duration)
                  }
                />
              </Box>
            </Layer>
          )}
          {showNotes && (
            <Layer
              background="lightTint"
              onEsc={() => setShowNotes(false)}
              onClickOutside={() => setShowNotes(false)}>
              <Box margin="xsmall" direction="column">
                <Box pad={{ horizontal: 'small' }}>
                  <RichTextEditor
                    notes={props.notes}
                    onNotesChange={props.onNotesChange}
                    onDone={() => setShowNotes(false)}
                    onCancel={() => setShowNotes(false)}
                  />
                </Box>
              </Box>
            </Layer>
          )}
          <div ref={scrollToRightRef} />
        </Box>
      </Box>
      {showAlarmPhotoSelector && (
        <Layer
          background="lightTint"
          onEsc={hideAlarmPhotoSelector}
          onClickOutside={hideAlarmPhotoSelector}>
          <SelectImage
            onClose={hideAlarmPhotoSelector}
            onUpload={onSelectUploadImage}
            onRemove={onRemoveImage}
            options={options}
            colorScheme={colorScheme}
          />
        </Layer>
      )}
      {
        <input
          type="file"
          id="file"
          accept="image/png, image/jpeg"
          ref={inputFile}
          style={{ display: 'none' }}
          onChange={onSelectFile}
        />
      }
    </Box>
  )
}

export default EditAlarm
