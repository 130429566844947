import React from 'react'
import { Box, Card, CardBody, Text } from 'grommet'
import { useDispatch } from 'react-redux'
import { ActionCreators } from 'galarm-shared'
import GroupAvatar from './GroupAvatar'
import objGet from 'lodash/get'

const GroupSummary = item => {
  const dispatch = useDispatch()

  return (
    <Card
      onClick={() => {
        dispatch(ActionCreators.showGroupDetailsScreen(item.id))
      }}
      background="textBackgroundColor">
      <CardBody pad="small">
        <Box direction="row" gap="small" align="center">
          <GroupAvatar
            url={objGet(item, 'images.avatarThumbnailUrl', undefined)}
            name={item.name}
          />
          <Text>{item.name}</Text>
        </Box>
      </CardBody>
    </Card>
  )
}

export default GroupSummary
