import { TextArea } from 'grommet'
import React from 'react'
import { ThemeContext } from 'styled-components'

const TallTextArea = ({ height, ...restProps }) => {
  return (
    <ThemeContext.Extend
      value={{
        textArea: {
          extend: {
            height: height,
            backgroundColor: 'textBackgroundColor'
          }
        }
      }}>
      <TextArea {...restProps} />
    </ThemeContext.Extend>
  )
}

export default TallTextArea
