import firebase from 'firebase/compat/app'

const FirebaseProxy = (function () {
  const logEvent = (event, parameters) =>
    firebase.analytics().logEvent(event, parameters)
  const storage = () => firebase.storage()
  const auth = () => firebase.auth()
  const functions = () => firebase.functions()

  const putFile = (imageRef, file, metadata) => {
    const imageUploadPromise = imageRef.put(file, metadata)
    return imageUploadPromise
  }

  return {
    logEvent,
    storage,
    auth,
    putFile,
    functions
  }
})()

export default FirebaseProxy
