import React from 'react'
import { Card, CardBody, Text, Box } from 'grommet'
import {
  ActionCreators,
  AlarmUtils,
  DateTimeUtils,
  Selectors
} from 'galarm-shared'
import { colorThemes } from 'galarm-config'
import { Constants } from 'galarm-config'
import PropTypes from 'prop-types'
import BigText from './BigText'
import { useSelector, useDispatch } from 'react-redux'
import ChatIconSmall from './ChatIconSmall'
import objGet from 'lodash/get'
import { SecondaryText } from 'web-components'

const { makeAlarmCategoryForAlarmIdSelector } = Selectors

const InstantAlarmSummary = ({ item }) => {
  const dispatch = useDispatch()
  const colorScheme = useSelector(state => state.userSettings.webColorScheme)

  const { id, name, type, backupGroup, backupContacts, recipient } = item

  const textColor = colorThemes.getColorTheme(colorScheme).textColor

  const alarmCategorySelector = makeAlarmCategoryForAlarmIdSelector()
  const alarmCategory = useSelector(state =>
    alarmCategorySelector(state, { alarmId: id })
  )

  const alarmCategoryColor = AlarmUtils.getColorForAlarmCategory(
    colorScheme,
    alarmCategory.color
  )

  const participants = AlarmUtils.getAlarmParticipants(
    type,
    backupGroup,
    backupContacts,
    recipient
  )

  const numParticipants = participants.filter(
    participant => participant.state !== Constants.ParticipantStates.INACTIVE
  ).length

  const currAlarmDate = AlarmUtils.getCurrentDateForAlarmId(id)
  const alarmAcknowledgements =
    AlarmUtils.getAlarmAcknowledgementsForOccurrenceForAlarm(
      item,
      currAlarmDate
    )

  const acknowledgedString = AlarmUtils.getCreatorSummaryForInstantAlarm(
    alarmAcknowledgements,
    numParticipants
  )

  const alarmConversations = useSelector(state => state.conversations.alarms)
  const unseenMessagesForAlarm = objGet(
    alarmConversations,
    `[${item.id}].unseenMessages`,
    0
  )

  const onViewAlarm = event => {
    if (event.target.type !== 'checkbox') {
      dispatch(ActionCreators.showInstantAlarmDetailsScreen(id))
    }
  }

  const onViewConversation = event => {
    event.stopPropagation()
    dispatch(ActionCreators.showAlarmConversationScreen(item.id))
  }

  const timeString = DateTimeUtils.getTimeAsString(currAlarmDate)
  const dateString =
    DateTimeUtils.getLocalizedDayDateWithYearForLaterDatesAsString(
      currAlarmDate
    )

  console.log('Rendering AlarmSummary ' + name + ' ' + dateString)

  return (
    <Card onClick={onViewAlarm} background="textBackgroundColor">
      <CardBody direction="row" justify="between">
        <Box direction="row">
          {alarmCategory.id !== Constants.UNCATEGORIZED_ALARM_CATEGORY_ID && (
            <Box width={'10px'} background={alarmCategoryColor} />
          )}
          <Box pad="small">
            <Text color={textColor} truncate>
              {name}
            </Text>
            <Box direction="row" gap="xsmall">
              <BigText color={textColor}>{timeString}</BigText>
              <Text alignSelf="end" color={textColor}>
                {' ' + dateString}
              </Text>
            </Box>
            <Box>
              <SecondaryText>{acknowledgedString}</SecondaryText>
            </Box>
          </Box>
        </Box>
        <Box align="center" pad="small" onClick={onViewConversation}>
          <ChatIconSmall unseenMessages={unseenMessagesForAlarm} />
        </Box>
      </CardBody>
    </Card>
  )
}

InstantAlarmSummary.propsTypes = {
  name: PropTypes.string.isRequired,
  date: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  status: PropTypes.bool.isRequired,
  notes: PropTypes.string
}

export default InstantAlarmSummary
