import React, { useEffect, useState } from 'react'
import { Box, Button, Text, List } from 'grommet'
import { FormUp, FormDown } from 'grommet-icons'
import { NavigationUtils, LogUtils } from 'galarm-ps-api'
import { I18n } from 'galarm-config'
import { colorThemes } from 'galarm-config'
import { Utils } from 'galarm-shared'
import Separator from './Separator'
import { useSelector } from 'react-redux'

const ReorderParticipant = ({
  name,
  allowUp,
  allowDown,
  onMoveContactUp,
  onMoveContactDown
}) => {
  const colorScheme = useSelector(state => state.userSettings.webColorScheme)

  return (
    <Box direction="row" align="center">
      <Button
        margin="none"
        icon={
          <FormUp
            size="medium"
            color={
              allowUp
                ? colorThemes.getColorTheme(colorScheme).primary
                : colorThemes.getColorTheme(colorScheme).darkTint
            }
          />
        }
        onClick={onMoveContactUp}
      />
      <Button
        icon={
          <FormDown
            size="medium"
            color={
              allowDown
                ? colorThemes.getColorTheme(colorScheme).primary
                : colorThemes.getColorTheme(colorScheme).darkTint
            }
          />
        }
        onClick={onMoveContactDown}
      />
      <Text color="textColor">{name}</Text>
    </Box>
  )
}

const ReorderParticipantContainer = props => {
  const item = props.item
  const onMoveContactUp = () => {
    if (item.index > 0) {
      props.onMoveContactUp(item.id)
      NavigationUtils.showTransientAlert({
        message: I18n.t('participantMovedToPosition', {
          count: item.index,
          name: item.name
        })
      })
    } else {
      NavigationUtils.showAlert(
        I18n.t('cantMoveMemberUp', { name: item.name }),
        I18n.t('memberIsFirstParticpant', { name: item.name })
      )
    }
  }

  const onMoveContactDown = () => {
    if (item.index < props.numParticipants - 1) {
      props.onMoveContactDown(item.id)
      NavigationUtils.showTransientAlert({
        message: I18n.t('participantMovedToPosition', {
          count: item.index + 2,
          name: item.name
        })
      })
    } else {
      NavigationUtils.showAlert(
        I18n.t('cantMoveMemberDown', { name: item.name }),
        I18n.t('memberIsLastParticpant', { name: item.name })
      )
    }
  }

  const passProps = {
    name: item.name,
    onMoveContactUp: onMoveContactUp,
    onMoveContactDown: onMoveContactDown,
    allowUp: item.index > 0,
    allowDown: item.index < props.numParticipants - 1
  }

  return <ReorderParticipant {...passProps} />
}

const ReorderParticipants = props => {
  const [participants, setParticipants] = useState(props.participants)

  useEffect(() => {
    onDone()
  }, [participants])

  const onDone = () => {
    props.updateParticipantsOrder(participants)
  }

  const onMoveContactUp = contactId => {
    const contactIndex = Utils.getIndexOfObjectWithId(participants, contactId)
    if (contactIndex < 1) {
      LogUtils.logError(
        new Error('Contact index less than 1 during onMoveContactUp')
      )
      return
    }
    const contactToMoveUp = participants[contactIndex]
    const contactToMoveDown = participants[contactIndex - 1]
    const newParticipants = participants.slice()
    newParticipants[contactIndex - 1] = contactToMoveUp
    newParticipants[contactIndex] = contactToMoveDown
    setParticipants(newParticipants)
  }

  const onMoveContactDown = contactId => {
    const contactIndex = Utils.getIndexOfObjectWithId(participants, contactId)
    if (contactIndex >= participants.length - 1) {
      LogUtils.logError(
        new Error(
          'Participant index more than length of participants during onMoveContactDown'
        )
      )
      return
    }
    const contactToMoveDown = participants[contactIndex]
    const contactToMoveUp = participants[contactIndex + 1]
    const newParticipants = participants.slice()
    newParticipants[contactIndex + 1] = contactToMoveDown
    newParticipants[contactIndex] = contactToMoveUp
    setParticipants(newParticipants)
  }

  const participantData = participants.map((participant, index) => ({
    ...participant,
    index
  }))

  return (
    <Box flex direction="row">
      <Box width={'500px'}>
        <Text color="darkTint" margin="small">
          {I18n.t('reorderParticipantsScreenDescription')}
        </Text>
        <Separator />
        <List data={participantData} border="bottom" pad="xsmall">
          {(item, index) => (
            <ReorderParticipantContainer
              key={index}
              item={item}
              numParticipants={participants.length}
              onMoveContactDown={onMoveContactDown}
              onMoveContactUp={onMoveContactUp}
            />
          )}
        </List>
      </Box>
    </Box>
  )
}

export default ReorderParticipants
