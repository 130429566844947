import React from 'react'
import { Constants } from 'galarm-config'
import TintedImage from './TintedImage'
import { Box } from 'grommet'

const AlarmType = ({ type, creationMode }) => {
  let iconSource
  if (creationMode === Constants.AlarmCreationModes.INSTANT_ALARM) {
    iconSource = require('galarm-res/img/web/ic_instant_alarm.svg').default
  } else {
    switch (type) {
      case Constants.AlarmTypes.CASCADING:
        iconSource = require('galarm-res/img/web/ic_personal_alarm.svg').default
        break
      case Constants.AlarmTypes.SIMULTANEOUS:
        iconSource = require('galarm-res/img/web/ic_group_alarm.svg').default
        break
      case Constants.AlarmTypes.RECIPIENT:
        iconSource = require('galarm-res/img/web/ic_buddy_alarm.svg').default
        break
      default:
        iconSource = require('galarm-res/img/web/ic_personal_alarm.svg').default
    }
  }

  return (
    <Box justify="center" align="center">
      <TintedImage tintColor="primary" src={iconSource} height="40px" />
    </Box>
  )
}

export default AlarmType
