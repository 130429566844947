import { Bold, Italic, Underline } from 'grommet-icons'
import { Box, Button } from 'grommet'
import React from 'react'
import { useSelector } from 'react-redux'
import { colorThemes } from 'galarm-config'

const TextAreaToolbarInlineComponent = props => {
  const currentState = props.currentState
  const onChange = name => {
    const { onChange } = props
    onChange(name)
  }
  const colorScheme = useSelector(state => state.userSettings.webColorScheme)
  return (
    <Box direction="row" gap="small">
      <Box
        border={
          currentState.bold
            ? { color: colorThemes.getColorTheme(colorScheme).darkTint }
            : false
        }
        round={'small'}>
        <Button
          icon={<Bold color="primary" />}
          onClick={() => onChange('BOLD')}
        />
      </Box>
      <Box
        border={
          currentState.italic
            ? { color: colorThemes.getColorTheme(colorScheme).darkTint }
            : false
        }
        round={'small'}>
        <Button
          icon={<Italic color="primary" />}
          onClick={() => onChange('ITALIC')}
        />
      </Box>
      <Box
        border={
          currentState.underline
            ? { color: colorThemes.getColorTheme(colorScheme).darkTint }
            : false
        }
        round="small">
        <Button
          icon={<Underline color="primary" />}
          onClick={() => onChange('UNDERLINE')}
        />
      </Box>
    </Box>
  )
}

export default TextAreaToolbarInlineComponent
