import { Constants } from 'galarm-config'
import { ActionTypes } from 'galarm-shared'
import { createReducer } from 'reduxsauce'

const defaultInspectorPanelConfig = {
  newAlarmWizard: {
    visible: false,
    alarmType: '',
    alarmCategoryId: Constants.UNCATEGORIZED_ALARM_CATEGORY_ID
  },
  duplicateAlarmWizard: {
    visible: false,
    baseAlarm: null
  },
  newInstantAlarmWizard: {
    visible: false,
    alarmCategoryId: Constants.UNCATEGORIZED_ALARM_CATEGORY_ID
  },
  editAlarmWizard: {
    visible: false,
    alarmId: ''
  },
  alarmConversationScreen: {
    visible: false,
    alarmId: ''
  },
  newGroupWizard: {
    visible: false
  },
  editGroupWizard: { visible: false, groupId: '' },
  selectAlarmTypeScreen: {
    visible: false
  },
  alarmDetailsScreen: {
    visible: false,
    alarmId: ''
  },
  instantAlarmDetailsScreen: {
    visible: false,
    alarmId: ''
  },
  participantAlarmDetailsScreen: {
    visible: false,
    alarmId: ''
  },
  instantParticipantAlarmDetailsScreen: {
    visible: false,
    alarmId: ''
  },
  groupDetailsScreen: {
    visible: false,
    groupId: ''
  },
  contactDetailsScreen: {
    visible: false,
    contactId: ''
  },
  changeMemberStateScreen: {
    visible: false,
    groupId: '',
    groupName: '',
    members: []
  },
  reportAProblemScreen: {
    visible: false
  },
  profileAndSettingsScreen: {
    visible: false
  },
  alarmHistoryScreen: {
    visible: false,
    alarmId: ''
  },
  calendarScreen: {
    visible: true
  }
}

const INITIAL_STATE = {
  isConnected: true,
  authenticatedWithFirebase: false,
  notificationsEnabled: true,
  currentScreen: undefined,
  deviceToken: undefined,
  latestRelease: undefined,
  showAppRunningElsewhereAlert: false,
  mobileAndWebTimezoneMismatchDetected: false,
  tabActiveIndex: 0,
  inspectorPanelConfig: defaultInspectorPanelConfig,
  currentlySelectedAlarmCategoryId: '',
  showSelectAlarmTypeScreen: false,
  showQuickReminder: false,
  showSidebar: true,
  currentlySelectedTaskListId: '',
  showAlertIncidents: false,
  showRecentlyDeletedAlarms: false
}

const setConnectionStatus = (state, action) => ({
  ...state,
  isConnected: action.payload.isConnected
})

const setAuthenticatedWithFirebase = (state, action) => ({
  ...state,
  authenticatedWithFirebase: action.payload.authenticatedWithFirebase
})

const setNotificationsEnabled = (state, action) => ({
  ...state,
  notificationsEnabled: action.payload.notificationsEnabled
})

const setCurrentScreen = (state, action) => ({
  ...state,
  currentScreen: action.payload.currentScreen
})

const setDeviceToken = (state, action) => ({
  ...state,
  deviceToken: action.payload.deviceToken
})

const setLatestRelease = (state, action) => ({
  ...state,
  latestRelease: action.payload.latestRelease
})

const setShowAppRunningElsewhereAlert = (state, action) => ({
  ...state,
  showAppRunningElsewhereAlert: action.payload.showAppRunningElsewhereAlert
})

const setMobileAndWebTimezoneMismatchDetected = (state, action) => ({
  ...state,
  mobileAndWebTimezoneMismatchDetected:
    action.payload.mobileAndWebTimezoneMismatchDetected
})

const showSelectAlarmTypeScreen = (state, action) => ({
  ...state,
  inspectorPanelConfig: defaultInspectorPanelConfig,
  showSelectAlarmTypeScreen: {
    props: action.payload
  }
})

const hideSelectAlarmTypeScreen = state => ({
  ...state,
  showSelectAlarmTypeScreen: false
})

const setShowQuickReminder = (state, action) => ({
  ...state,
  showQuickReminder: action.payload.showQuickReminder
})

const setTabActiveIndex = (state, action) => ({
  ...state,
  tabActiveIndex: action.payload.tabActiveIndex
})

const showNewAlarmWizard = (state, action) => ({
  ...state,
  inspectorPanelConfig: {
    ...defaultInspectorPanelConfig,
    newAlarmWizard: {
      visible: true,
      ...action.payload
    }
  }
})

const showDuplicateAlarmWizard = (state, action) => ({
  ...state,
  inspectorPanelConfig: {
    ...defaultInspectorPanelConfig,
    duplicateAlarmWizard: {
      visible: true,
      ...action.payload
    }
  }
})

const showNewInstantAlarmWizard = (state, action) => ({
  ...state,
  inspectorPanelConfig: {
    ...defaultInspectorPanelConfig,
    newInstantAlarmWizard: {
      visible: true,
      ...action.payload
    }
  }
})

const showEditAlarmWizard = (state, action) => ({
  ...state,
  inspectorPanelConfig: {
    ...defaultInspectorPanelConfig,
    editAlarmWizard: {
      visible: true,
      alarmId: action.payload.alarmId
    }
  }
})

const showAlarmConversationScreen = (state, action) => ({
  ...state,
  inspectorPanelConfig: {
    ...state.inspectorPanelConfig,
    alarmConversationScreen: {
      visible: true,
      alarmId: action.payload.alarmId
    }
  }
})

const hideAlarmConversationScreen = state => ({
  ...state,
  inspectorPanelConfig: {
    ...state.inspectorPanelConfig,
    alarmConversationScreen: {
      visible: false,
      alarmId: ''
    }
  }
})

const hideNewAlarmWizard = state => ({
  ...state,
  inspectorPanelConfig: {
    ...defaultInspectorPanelConfig,
    newAlarmWizard: {
      visible: false,
      alarmType: ''
    }
  }
})

const hideDuplicateAlarmWizard = state => ({
  ...state,
  inspectorPanelConfig: {
    ...defaultInspectorPanelConfig,
    duplicateAlarmWizard: {
      visible: false
    }
  }
})

const hideNewInstantAlarmWizard = state => ({
  ...state,
  inspectorPanelConfig: {
    ...defaultInspectorPanelConfig,
    newInstantAlarmWizard: {
      visible: false
    }
  }
})

const hideEditAlarmWizard = state => ({
  ...state,
  inspectorPanelConfig: {
    ...defaultInspectorPanelConfig,
    editAlarmWizard: {
      visible: false,
      alarmId: ''
    }
  }
})

const showNewGroupWizard = state => ({
  ...state,
  inspectorPanelConfig: {
    ...defaultInspectorPanelConfig,
    newGroupWizard: {
      visible: true
    }
  }
})

const hideNewGroupWizard = state => ({
  ...state,
  inspectorPanelConfig: {
    ...defaultInspectorPanelConfig,
    newGroupWizard: {
      visible: false
    }
  }
})

const showEditGroupWizard = (state, action) => ({
  ...state,
  inspectorPanelConfig: {
    ...defaultInspectorPanelConfig,
    editGroupWizard: {
      visible: true,
      groupId: action.payload.groupId
    }
  }
})

const hideEditGroupWizard = state => ({
  ...state,
  inspectorPanelConfig: {
    ...defaultInspectorPanelConfig,
    editGroupWizard: {
      visible: false,
      groupId: ''
    }
  }
})

const showAlarmDetailsScreen = (state, action) => ({
  ...state,
  inspectorPanelConfig: {
    ...defaultInspectorPanelConfig,
    alarmDetailsScreen: {
      visible: true,
      alarmId: action.payload.alarmId
    }
  }
})

const showInstantAlarmDetailsScreen = (state, action) => ({
  ...state,
  inspectorPanelConfig: {
    ...defaultInspectorPanelConfig,
    instantAlarmDetailsScreen: {
      visible: true,
      alarmId: action.payload.alarmId
    }
  }
})

const hideAlarmDetailsScreen = state => ({
  ...state,
  inspectorPanelConfig: {
    ...defaultInspectorPanelConfig,
    alarmDetailsScreen: {
      visible: false,
      alarmId: ''
    }
  }
})

const hideInstantAlarmDetailsScreen = state => ({
  ...state,
  inspectorPanelConfig: {
    ...defaultInspectorPanelConfig,
    instantAlarmDetailsScreen: {
      visible: false,
      alarmId: ''
    }
  }
})

const showParticipantAlarmDetailsScreen = (state, action) => ({
  ...state,
  inspectorPanelConfig: {
    ...defaultInspectorPanelConfig,
    participantAlarmDetailsScreen: {
      visible: true,
      alarmId: action.payload.alarmId
    }
  }
})

const showInstantParticipantAlarmDetailsScreen = (state, action) => ({
  ...state,
  inspectorPanelConfig: {
    ...defaultInspectorPanelConfig,
    instantParticipantAlarmDetailsScreen: {
      visible: true,
      alarmId: action.payload.alarmId
    }
  }
})

const hideParticipantAlarmDetailsScreen = state => ({
  ...state,
  inspectorPanelConfig: {
    ...defaultInspectorPanelConfig,
    participantAlarmDetailsScreen: {
      visible: false,
      alarmId: ''
    }
  }
})

const showGroupDetailsScreen = (state, action) => ({
  ...state,
  inspectorPanelConfig: {
    ...defaultInspectorPanelConfig,
    groupDetailsScreen: {
      visible: true,
      groupId: action.payload.groupId
    }
  }
})

const hideGroupDetailsScreen = state => ({
  ...state,
  inspectorPanelConfig: {
    ...defaultInspectorPanelConfig,
    groupDetailsScreen: {
      visible: false,
      groupId: ''
    }
  }
})

const showChangeMemberStateScreen = (state, action) => ({
  ...state,
  inspectorPanelConfig: {
    ...state.inspectorPanelConfig,
    changeMemberStateScreen: {
      visible: true,
      groupId: action.payload.groupId,
      groupName: action.payload.groupName,
      members: action.payload.members,
      updateMemberState: action.payload.updateMemberState,
      memberToChange: action.payload.memberToChange
    }
  }
})

const showContactDetailsScreen = (state, action) => ({
  ...state,
  inspectorPanelConfig: {
    ...defaultInspectorPanelConfig,
    contactDetailsScreen: {
      visible: true,
      contactId: action.payload.contactId
    }
  }
})

const hideContactDetailsScreen = state => ({
  ...state,
  inspectorPanelConfig: {
    ...defaultInspectorPanelConfig,
    contactDetailsScreen: {
      visible: false,
      contactId: ''
    }
  }
})

const hideChangeMemberStateScreen = state => ({
  ...state,
  inspectorPanelConfig: {
    ...state.inspectorPanelConfig,
    changeMemberStateScreen: {
      visible: false,
      groupId: '',
      groupName: '',
      members: []
    }
  }
})

const showReportAProblemScreen = state => ({
  ...state,
  inspectorPanelConfig: {
    ...defaultInspectorPanelConfig,
    reportAProblemScreen: {
      visible: true
    }
  }
})

const hideReportAProblemScreen = state => ({
  ...state,
  inspectorPanelConfig: {
    ...defaultInspectorPanelConfig,
    reportAProblemScreen: {
      visible: false
    }
  }
})

const showProfileAndSettingsScreen = state => ({
  ...state,
  inspectorPanelConfig: {
    ...defaultInspectorPanelConfig,
    profileAndSettingsScreen: {
      visible: true
    }
  }
})

const hideProfileAndSettingsScreen = state => ({
  ...state,
  inspectorPanelConfig: {
    ...defaultInspectorPanelConfig,
    profileAndSettingsScreen: {
      visible: false
    }
  }
})

const showAlarmHistoryScreen = (state, action) => ({
  ...state,
  inspectorPanelConfig: {
    ...state.inspectorPanelConfig,
    alarmHistoryScreen: {
      visible: true,
      alarm: action.payload.alarm
    }
  }
})

const hideAlarmHistoryScreen = state => ({
  ...state,
  inspectorPanelConfig: {
    ...state.inspectorPanelConfig,
    alarmHistoryScreen: {
      visible: false,
      alarmId: ''
    }
  }
})

const setCurrentlySelectedAlarmCategoryId = (state, action) => ({
  ...state,
  currentlySelectedAlarmCategoryId: action.payload.id
})

const setCurrentlySelectedTaskListId = (state, action) => ({
  ...state,
  currentlySelectedTaskListId: action.payload.id
})

const toggleCategorizedAlarmView = state => {
  return {
    ...state,
    currentlySelectedAlarmCategoryId: ''
  }
}

const resetInspectorPanel = state => {
  return {
    ...state,
    inspectorPanelConfig: defaultInspectorPanelConfig
  }
}

const setShowSidebar = (state, action) => ({
  ...state,
  showSidebar: action.payload.showSidebar
})

const setShowAlertIncidents = (state, action) => ({
  ...state,
  showAlertIncidents: action.payload.showAlertIncidents
})

const setShowRecentlyDeletedAlarms = (state, action) => ({
  ...state,
  showRecentlyDeletedAlarms: action.payload.showRecentlyDeletedAlarms
})

const ACTION_HANDLERS = {
  [ActionTypes.SET_CONNECTION_STATUS]: setConnectionStatus,
  [ActionTypes.SET_AUTHENTICATED_WITH_FIREBASE]: setAuthenticatedWithFirebase,
  [ActionTypes.SET_NOTIFICATIONS_ENABLED]: setNotificationsEnabled,
  [ActionTypes.SET_CURRENT_SCREEN]: setCurrentScreen,
  [ActionTypes.SET_DEVICE_TOKEN]: setDeviceToken,
  [ActionTypes.SET_LATEST_RELEASE]: setLatestRelease,
  [ActionTypes.SET_SHOW_APP_RUNNING_ELSEWHERE_ALERT]:
    setShowAppRunningElsewhereAlert,
  [ActionTypes.SET_TAB_ACTIVE_INDEX]: setTabActiveIndex,
  [ActionTypes.SHOW_NEW_ALARM_WIZARD]: showNewAlarmWizard,
  [ActionTypes.SHOW_DUPLICATE_ALARM_WIZARD]: showDuplicateAlarmWizard,
  [ActionTypes.SHOW_NEW_INSTANT_ALARM_WIZARD]: showNewInstantAlarmWizard,
  [ActionTypes.SHOW_EDIT_ALARM_WIZARD]: showEditAlarmWizard,
  [ActionTypes.SHOW_ALARM_CONVERSATION_SCREEN]: showAlarmConversationScreen,
  [ActionTypes.HIDE_ALARM_CONVERSATION_SCREEN]: hideAlarmConversationScreen,
  [ActionTypes.SHOW_NEW_GROUP_WIZARD]: showNewGroupWizard,
  [ActionTypes.SHOW_EDIT_GROUP_WIZARD]: showEditGroupWizard,
  [ActionTypes.HIDE_NEW_ALARM_WIZARD]: hideNewAlarmWizard,
  [ActionTypes.HIDE_DUPLICATE_ALARM_WIZARD]: hideDuplicateAlarmWizard,
  [ActionTypes.HIDE_NEW_INSTANT_ALARM_WIZARD]: hideNewInstantAlarmWizard,
  [ActionTypes.HIDE_EDIT_ALARM_WIZARD]: hideEditAlarmWizard,
  [ActionTypes.HIDE_NEW_GROUP_WIZARD]: hideNewGroupWizard,
  [ActionTypes.HIDE_EDIT_GROUP_WIZARD]: hideEditGroupWizard,
  [ActionTypes.SHOW_ALARM_DETAILS_SCREEN]: showAlarmDetailsScreen,
  [ActionTypes.SHOW_INSTANT_ALARM_DETAILS_SCREEN]:
    showInstantAlarmDetailsScreen,
  [ActionTypes.HIDE_ALARM_DETAILS_SCREEN]: hideAlarmDetailsScreen,
  [ActionTypes.HIDE_INSTANT_ALARM_DETAILS_SCREEN]:
    hideInstantAlarmDetailsScreen,
  [ActionTypes.SHOW_GROUP_DETAILS_SCREEN]: showGroupDetailsScreen,
  [ActionTypes.HIDE_GROUP_DETAILS_SCREEN]: hideGroupDetailsScreen,
  [ActionTypes.SHOW_CONTACT_DETAILS_SCREEN]: showContactDetailsScreen,
  [ActionTypes.SHOW_CHANGE_MEMBER_STATE_SCREEN]: showChangeMemberStateScreen,
  [ActionTypes.HIDE_CONTACT_DETAILS_SCREEN]: hideContactDetailsScreen,
  [ActionTypes.HIDE_CHANGE_MEMBER_STATE_SCREEN]: hideChangeMemberStateScreen,
  [ActionTypes.SHOW_REPORT_A_PROBLEM_SCREEN]: showReportAProblemScreen,
  [ActionTypes.HIDE_REPORT_A_PROBLEM_SCREEN]: hideReportAProblemScreen,
  [ActionTypes.SHOW_PROFILE_AND_SETTINGS_SCREEN]: showProfileAndSettingsScreen,
  [ActionTypes.HIDE_PROFILE_AND_SETTINGS_SCREEN]: hideProfileAndSettingsScreen,
  [ActionTypes.SHOW_PARTICIPANT_ALARM_DETAILS_SCREEN]:
    showParticipantAlarmDetailsScreen,
  [ActionTypes.SHOW_INSTANT_PARTICIPANT_ALARM_DETAILS_SCREEN]:
    showInstantParticipantAlarmDetailsScreen,
  [ActionTypes.HIDE_PARTICIPANT_ALARM_DETAILS_SCREEN]:
    hideParticipantAlarmDetailsScreen,
  [ActionTypes.SHOW_ALARM_HISTORY_SCREEN]: showAlarmHistoryScreen,
  [ActionTypes.HIDE_ALARM_HISTORY_SCREEN]: hideAlarmHistoryScreen,
  [ActionTypes.SET_CURRENTLY_SELECTED_ALARM_CATEGORY_ID]:
    setCurrentlySelectedAlarmCategoryId,
  [ActionTypes.SHOW_SELECT_ALARM_TYPE_SCREEN]: showSelectAlarmTypeScreen,
  [ActionTypes.HIDE_SELECT_ALARM_TYPE_SCREEN]: hideSelectAlarmTypeScreen,
  [ActionTypes.SET_SHOW_QUICK_REMINDER]: setShowQuickReminder,
  [ActionTypes.TOGGLE_CATEGORIZED_ALARM_VIEW]: toggleCategorizedAlarmView,
  [ActionTypes.RESET_INSPECTOR_PANEL]: resetInspectorPanel,
  [ActionTypes.SET_SHOW_SIDEBAR]: setShowSidebar,
  [ActionTypes.SET_MOBILE_AND_WEB_TIMEZONE_MISMATCH_DETECTED]:
    setMobileAndWebTimezoneMismatchDetected,
  [ActionTypes.SET_CURRENTLY_SELECTED_TASK_LIST_ID]:
    setCurrentlySelectedTaskListId,
  [ActionTypes.SET_SHOW_ALERT_INCIDENTS]: setShowAlertIncidents,
  [ActionTypes.SET_SHOW_RECENTLY_DELETED_ALARMS]: setShowRecentlyDeletedAlarms
}

export default createReducer(INITIAL_STATE, ACTION_HANDLERS)
