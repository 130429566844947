import React, { useState } from 'react'
import { colorThemes } from 'galarm-config'
import { useSelector } from 'react-redux'
import { I18n } from 'galarm-config'
import ColorPalette from '../components/ColorPalette'
import { Box, Button, Heading } from 'grommet'

const EditAlarmCategoryColor = ({ alarmCategory, onChangeColor, onClose }) => {
  const [color, setColor] = useState(alarmCategory.color)
  const colorScheme = useSelector(state => state.userSettings.webColorScheme)

  const onChangeAlarmCategoryColor = newValue => {
    setColor(newValue)
  }

  const onSave = () => {
    onChangeColor({
      color: color
    })
    onClose()
  }

  const alarmCategoryColors = colorThemes
    .getColorTheme(colorScheme)
    .alarmCategoryColors.filter(color => color.id !== 'uncategorized')

  return (
    <Box overflow="auto" width="500px" margin="medium" gap="small">
      <Heading level={4} margin="none">
        {I18n.t('editAlarmCategoryColorTitle', {
          name: alarmCategory.name
        })}
      </Heading>
      <ColorPalette
        onChange={onChangeAlarmCategoryColor}
        selectedColor={color}
        colors={alarmCategoryColors}
      />
      <Box direction="row" alignSelf="end" gap="small">
        <Button onClick={onClose} label={I18n.t('cancel')} />
        <Button onClick={onSave} label={I18n.t('save')} />
      </Box>
    </Box>
  )
}

export default EditAlarmCategoryColor
