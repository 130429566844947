import {
  AbstractEditAlarmContainer,
  ActionCreators,
  AlarmUtils,
  DateTimeUtils,
  Selectors
} from 'galarm-shared'
import moment from 'moment-timezone'
import { Constants, GlobalConfig, I18n } from 'galarm-config'
import {
  FirebaseProxy,
  NavigationUtils,
  NotificationManager
} from 'galarm-ps-api'
import { connect } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import * as RNLocalize from 'react-native-localize'

const {
  upgradePurchasedSelector,
  numGroupAlarmsSelector,
  numBuddyAlarmsSelector,
  alarmCategoriesSelector,
  makeAlarmCategoryForAlarmIdSelector,
  makeOwnAlarmDetailsSelector,
  requiredPermissionsEnabledSelector
} = Selectors

class EditAlarmContainer extends AbstractEditAlarmContainer {
  getDateForAlarm = () => {
    let date = this.props.date
    if (this.props.type === Constants.AlarmTypes.RECIPIENT) {
      date =
        this.props.date -
        Constants.RecipientAlarmIntervals[this.props.recipientAlarmInterval]
          .value
    }
    if (this.props.repeatType !== '') {
      return date
    } else if (
      date + GlobalConfig.alarmPreviousOccurrenceThreshold <
      Date.now()
    ) {
      return DateTimeUtils.getNextOccurrenceOfTime(date)
    } else {
      return date
    }
  }

  computeBackupContactsForAlarm = () => {
    if (this.props.backupContacts.length > 0) {
      return this.props.backupContacts.sort((contact1, contact2) => {
        return contact1.order - contact2.order
      })
    }

    return this.props.backupContacts
  }

  state = {
    id: this.props.id,
    name: this.props.name,
    date: this.getDateForAlarm(),
    backupContacts: this.computeBackupContactsForAlarm(),
    backupGroup: this.props.backupGroup,
    notes: this.props.notes,
    alarmPhotoUrl: this.props.alarmPhotoUrl || '',
    repeat: this.getRepeatForAlarm() || '',
    repeatType: this.props.repeatType || '',
    type: this.props.type,
    creatorTimezone: this.props.creatorTimezone || RNLocalize.getTimeZone(),
    timezoneSetting:
      this.props.timezoneSetting || Constants.TIMEZONE_SETTINGS.DEVICE,
    isEdit: true,
    cascadingAlarmInterval:
      this.props.cascadingAlarmInterval ||
      (this.props.type === Constants.AlarmTypes.CASCADING
        ? GlobalConfig.defaultCascadingAlarmInterval
        : null),
    recipientAlarmInterval:
      this.props.recipientAlarmInterval ||
      (this.props.type === Constants.AlarmTypes.RECIPIENT
        ? GlobalConfig.defaultRecipientAlarmInterval
        : null),
    recipient: this.props.recipient || null,
    ringerSettings: AlarmUtils.computeRingerSettings(this.props.ringerSettings),
    creationMode: Constants.AlarmCreationModes.NORMAL_ALARM,
    endDate: this.props.endDate || GlobalConfig.defaultAlarmEndDate,
    alarmCategoryId: this.props.alarmCategoryId,
    source: this.props.source,
    preReminderDuration:
      this.props.preReminderDuration || GlobalConfig.defaultPreReminderDuration
  }

  validateAlarm() {
    const validationMessages = super.validateAlarm()

    const repeatValidationMessage = AlarmUtils.validateRepetition(
      this.state.repeatType,
      this.state.repeat
    )

    if (repeatValidationMessage) {
      validationMessages.push(repeatValidationMessage)
    }

    return validationMessages
  }

  onSaveAlarmCore = () => {
    const alarm = this.createAlarm(this.props.id)

    // Use the default device timezone here. No need to pass in the timezone.

    // To get the right duration, passing in the alarm's calculated date instead of the state's date,
    // to take into account any timezone changes, we are converting the date to the right timezone when we
    // save the alarm, the user could just choose 2.30 and save in New york time zone,
    // then it will show us that only 2 hours are left instead of 18 hours.

    const durationString = DateTimeUtils.getDurationTillDateAsString(
      AlarmUtils.getNextDateForAlarm(
        alarm.date,
        this.state.endDate,
        this.state.repeatType,
        this.state.repeat
      )
    )

    this.props.dispatch(ActionCreators.editAlarm(alarm))

    // Send a notification to the mobile device.
    const notificationInfo = {
      type: Constants.NotificationTypes.ALARM_ADDED,
      alarmId: alarm.id,
      alarmType: alarm.type,
      alarmName: alarm.name,
      alarmDate: alarm.date.toString()
    }
    NotificationManager.sendRemoteNotification(
      GlobalConfig.uid,
      Constants.NotificationKeys.AlarmEditedFromWebNotification,
      notificationInfo
    )

    if (this.props.alarmCategoryId !== this.state.alarmCategoryId) {
      // If the category was not previously set or is unset now, then
      // remove alarm from the previously set category
      if (
        this.props.alarmCategoryId !==
          Constants.UNCATEGORIZED_ALARM_CATEGORY_ID ||
        this.state.alarmCategoryId === Constants.UNCATEGORIZED_ALARM_CATEGORY_ID
      ) {
        this.props.dispatch(
          ActionCreators.removeAlarmFromAlarmCategory(
            this.props.alarmCategoryId,
            this.props.id
          )
        )
      }

      // If the category is set now, add alarm to that category
      if (
        this.state.alarmCategoryId !== Constants.UNCATEGORIZED_ALARM_CATEGORY_ID
      ) {
        this.props.dispatch(
          ActionCreators.addAlarmToAlarmCategory(
            this.state.alarmCategoryId,
            this.props.id
          )
        )
      }

      FirebaseProxy.logEvent(
        Constants.UserAnalyticsEvents
          .CHANGE_ALARM_CATEGORY_FROM_EDIT_ALARM_SCREEN,
        {}
      )
    }

    NavigationUtils.showTransientAlert({
      message: I18n.t('alarmDurationAndroid', { durationString }),
      duration: Constants.AlertDurations.SHORT
    })

    this.props.onClose()
  }
}

const makeMapStateToProps = () => {
  const ownAlarmSelector = makeOwnAlarmDetailsSelector()
  const alarmCategorySelector = makeAlarmCategoryForAlarmIdSelector()

  const mapStateToProps = (state, ownProps) => {
    const alarm = ownAlarmSelector(state, ownProps)

    // We have observed that sometimes the alarm is not found in production
    // Although, we have never been able to reproduce, adding this sanity
    // check here to avoid any aborts in the app.
    if (isEmpty(alarm)) {
      FirebaseProxy.logEvent(Constants.UserAnalyticsEvents.ALARM_NOT_FOUND, {
        [Constants.UserAnalyticsEventParameters.SOURCE]: 'EditAlarmContainer'
      })
      return {}
    }

    let nextDate =
      AlarmUtils.getNextDateForAlarm(
        alarm.date,
        alarm.endDate,
        alarm.repeatType,
        alarm.repeat
      ) || alarm.date

    // Set the hours to original date such that hourly alarms
    // are shown to start the right time while editing
    const hours = moment(alarm.date).hours()
    nextDate = moment(nextDate).hours(hours).valueOf()

    if (alarm.timezoneSetting === Constants.TIMEZONE_SETTINGS.EXPLICIT) {
      // The date time is stored in the local timezone, so get the date string for the timezone and then
      // display that time in the local time zone, e.g. the alarm could be stored for 4.00 AM EST
      // which is equivalent to 7.30 New York, so first get the string "7.30" and display it locally
      nextDate = DateTimeUtils.getDateFromFormattedDateString(
        DateTimeUtils.getFormattedDateAsStringInTimezone(
          nextDate,
          alarm.creatorTimezone
        ),
        RNLocalize.getTimeZone()
      )
    }

    const alarmCategory = alarmCategorySelector(state, ownProps)

    return {
      ...alarm,
      date: nextDate,
      creatorName: state.userInfo.name,
      creatorMobileNumber: state.userInfo.formattedMobileNumberWithCC,
      requiredPermissionsEnabled: requiredPermissionsEnabledSelector(state),
      isAuthenticated: state.appState.authenticatedWithFirebase,
      rateTheAppInfo: state.userInfo.rateTheAppInfo,
      mobileNumber: state.userInfo.mobileNumber,
      upgradePurchased: upgradePurchasedSelector(state),
      numGroupAlarms: numGroupAlarmsSelector(state),
      numBuddyAlarms: numBuddyAlarmsSelector(state),
      joinDate: state.userInfo.joinDate,
      colorScheme: state.userSettings.webColorScheme,
      alarmCategories: alarmCategoriesSelector(state),
      alarmCategoryId: alarmCategory.id,
      specifyTimezoneForAlarm: state.userSettings.specifyTimezoneForAlarm,
      seenSpecifyTimezoneAlert: state.appState.seenSpecifyTimezoneAlert
    }
  }
  return mapStateToProps
}

export default connect(makeMapStateToProps)(EditAlarmContainer)
