import { ActionCreators } from 'galarm-shared'
import {
  Box,
  Heading,
  Text,
  Layer,
  Header,
  Button,
  Anchor,
  Image
} from 'grommet'
import { Close, FormNext } from 'grommet-icons'
import React, { useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import HorizontalLine from './HorizontalLine'
import { I18n } from 'galarm-config'
import { GlobalConfig } from 'galarm-config'
import AppCanvas from './AppCanvas'
import objGet from 'lodash/get'
import Link from './Link'
import SelectTheme from './SelectTheme'
import SelectImage from './SelectImage'
import { Constants } from 'galarm-config'
import { FirebaseProxy, NavigationUtils } from 'galarm-ps-api'
import EditAvatar from './EditAvatar'
import SessionManager from '../api/SessionManager'
import TintedImage from './TintedImage'
import { SecondaryText } from 'web-components'

const ProfileAndSettingsScreenHeader = () => {
  const dispatch = useDispatch()

  const closeProfileAndSettingsScreen = () => {
    dispatch(ActionCreators.hideProfileAndSettingsScreen())
  }

  return (
    <Header className="header" background="brand" pad="small">
      <Text color="white">{I18n.t('profileDetails')}</Text>
      <Box direction="row">
        <Button icon={<Close />} onClick={closeProfileAndSettingsScreen} />
      </Box>
    </Header>
  )
}

const ProfileAndSettingsScreen = () => {
  const dispatch = useDispatch()
  const userInfo = useSelector(state => state.userInfo)
  const [showThemePicker, setShowThemePicker] = useState(false)
  const [showProfileImageSelector, setShowProfileImageSelector] =
    useState(false)

  const colorScheme = useSelector(state => state.userSettings.webColorScheme)

  const options = [I18n.t('uploadImage'), I18n.t('removeImage')]

  const inputFile = useRef(null)

  const onSelectFile = event => {
    var file = event.target.files[0]
    // Fix for the issue of adding the same file
    event.target.value = ''
    if (file.size > GlobalConfig.maxProfileImageSize) {
      NavigationUtils.showAlert(
        I18n.t('cantUploadImage'),
        I18n.t('selectSmallerImage')
      )
    } else {
      dispatch(ActionCreators.uploadUserAvatar(GlobalConfig.uid, file))
    }
  }

  const displayFeedbackScreen = () => {
    GlobalConfig.showFeedbackScreen({
      type: Constants.TICKET_TYPES.GENERAL_FEEDBACK,
      extras: ''
    })
  }

  const displayThemePicker = () => {
    setShowThemePicker(true)
  }

  const hideThemePicker = () => {
    setShowThemePicker(false)
  }

  const hideProfileImagePicker = () => {
    setShowProfileImageSelector(false)
  }

  const onSelectUploadImage = () => {
    inputFile.current.click()
  }

  const onRemoveImage = () => {
    dispatch(ActionCreators.deleteUserAvatar(GlobalConfig.uid))
  }

  const onUpdateColorTheme = newColorScheme => {
    dispatch(ActionCreators.updateWebColorScheme(newColorScheme))
    FirebaseProxy.logEvent(
      Constants.UserAnalyticsEvents.CHANGE_WEB_COLOR_SCHEME,
      {
        webColorScheme: newColorScheme
      }
    )
  }

  const onClickUserAvatar = () => {
    if (!objGet(userInfo, 'images.avatarThumbnailUrl', undefined)) {
      inputFile.current.click()
    } else {
      setShowProfileImageSelector(true)
    }
  }

  const logOut = () => {
    NavigationUtils.showAlert(I18n.t('confirm'), I18n.t('confirmLogOut'), [
      { text: I18n.t('no') },
      { text: I18n.t('yes'), onPress: logOutCore }
    ])
  }

  const logOutCore = () => {
    SessionManager.logOutWebSession()
  }

  return (
    <AppCanvas overflow="auto">
      <Box flex={{ shrink: 0, grow: 1 }}>
        <ProfileAndSettingsScreenHeader />
        <Box flex align="center" pad="medium">
          <Box margin={{ top: 'medium' }} onClick={() => onClickUserAvatar()}>
            <EditAvatar
              size="4xl"
              title={I18n.t('addProfileImage')}
              url={objGet(userInfo, 'images.avatarFullImageUrl', undefined)}
            />
          </Box>
          <Image
            src={require('galarm-res/img/crown.png').default}
            height="50px"
            margin={{ top: 'small' }}
          />
          <Heading level={4} color="textColor" margin="none">
            {userInfo.name}
          </Heading>
          <Text size="small" color="darkTint" margin="xsmall">
            {userInfo.formattedMobileNumberWithCC}
          </Text>
          <Box margin={{ top: '40px' }} width="300px" gap="small">
            <Box direction="row" justify="between" onClick={displayThemePicker}>
              <Text color="textColor">{I18n.t('theme')}</Text>
              <FormNext color="primary" />
            </Box>
            <HorizontalLine color="mediumTint" height="2px" />
            <Box
              direction="row"
              justify="between"
              onClick={displayFeedbackScreen}>
              <Text color="textColor">{I18n.t('feedback')}</Text>
              <FormNext color="primary" />
            </Box>
            <HorizontalLine color="mediumTint" height="2px" />
            <Box direction="row" justify="between" onClick={logOut}>
              <Text color="warningTextColor">{I18n.t('logOut')}</Text>
            </Box>
            <HorizontalLine color="mediumTint" height="2px" />
          </Box>
        </Box>
        <Box align="center" gap="small">
          <Box align="center">
            <Anchor
              href={'https://www.galarmapp.com'}
              target="_blank"
              rel="noopener noreferrer"
              color="primary">
              <TintedImage
                src={require('galarm-res/img/galarm.png').default}
                height="80px"
                tintColor="darkPrimary"
              />
            </Anchor>
            <SecondaryText>
              {`${I18n.t('version')}: ${GlobalConfig.release}`}
            </SecondaryText>
          </Box>
          <Text
            size="small"
            style={{ whiteSpace: 'pre-line' }}
            textAlign="center"
            color="darkTint">
            {I18n.t('acintyoTrademarkWeb')}
          </Text>
          <Box direction="row" margin={{ bottom: 'small' }}>
            <Box
              pad={{ horizontal: 'small' }}
              border={{ color: 'darkTint', side: 'right', size: 'small' }}>
              <Link
                size="small"
                label={I18n.t('termsOfService')}
                href={Constants.TERMS_OF_SERVICE_LINK}
              />
            </Box>
            <Box
              pad={{ horizontal: 'small' }}
              border={{ color: 'darkTint', side: 'right', size: 'small' }}>
              <Link
                size="small"
                label={I18n.t('privacyPolicy')}
                href={Constants.PRIVACY_POLICY_LINK}
              />
            </Box>
            <Box pad={{ horizontal: 'small' }}>
              <Link
                size="small"
                label={I18n.t('licenses')}
                href={Constants.LICENSES_LINK}
              />
            </Box>
          </Box>
        </Box>
        {showThemePicker && (
          <Layer background="lightTint">
            <Box margin="medium">
              <SelectTheme
                onClose={hideThemePicker}
                onSave={theme => onUpdateColorTheme(theme)}
                colorScheme={colorScheme}
              />
            </Box>
          </Layer>
        )}
        {showProfileImageSelector && (
          <Layer
            background="lightTint"
            onEsc={hideProfileImagePicker}
            onClickOutside={hideProfileImagePicker}>
            <SelectImage
              onClose={hideProfileImagePicker}
              onUpload={onSelectUploadImage}
              onRemove={onRemoveImage}
              options={options}
              colorScheme={colorScheme}
            />
          </Layer>
        )}
        {
          <input
            type="file"
            id="file"
            accept="image/png, image/jpeg"
            ref={inputFile}
            style={{ display: 'none' }}
            onChange={onSelectFile}
          />
        }
      </Box>
    </AppCanvas>
  )
}

export default ProfileAndSettingsScreen
