import { Anchor } from 'grommet'
import React from 'react'

const Link = props => {
  if (typeof props === 'string') {
    return (
      <Anchor
        key={props}
        href={props}
        target="_blank"
        rel="noopener noreferrer"
        color="primary"
        size={props.size || 'small'}
        weight="normal">
        {props}
      </Anchor>
    )
  }
  return (
    <Anchor
      key={props.label}
      href={props.href}
      target="_blank"
      rel="noopener noreferrer"
      color="primary"
      size={props.size || 'small'}
      weight="normal">
      {props.label}
    </Anchor>
  )
}

export default Link
