import { GlobalConfig } from 'galarm-config'
import { LogUtils } from 'galarm-ps-api'
import TaskManager from '../api/TaskManager'

const GroupUtils = (function () {
  const updateMembersConfig = function (groupId, members) {
    let firebaseUpdateObj = {}
    members.forEach(member => {
      firebaseUpdateObj[
        'userInfos/' +
          GlobalConfig.uid +
          '/groups/' +
          groupId +
          '/members/' +
          member.id +
          '/order'
      ] = member.order
      firebaseUpdateObj[
        'userInfos/' +
          GlobalConfig.uid +
          '/groups/' +
          groupId +
          '/members/' +
          member.id +
          '/state'
      ] = member.state
    })
    firebaseUpdateObj[
      'userInfos/' + GlobalConfig.uid + '/groups/' + groupId + '/lastUpdatedAt'
    ] = Date.now()
    GlobalConfig.rootFirebaseRef.update(firebaseUpdateObj).catch(error => {
      LogUtils.logError(error, 'Unable to update default member order')
    })
  }

  const addConnectionsForGroupMembers = members => {
    if (members && members.length > 0) {
      const uids = members.map(member => member.id)
      uids.push(GlobalConfig.uid)
      return TaskManager.addHttpsCloudTask('addConnections', {
        uids: uids.join(',')
      })
    }
    return Promise.resolve()
  }

  return {
    updateMembersConfig,
    addConnectionsForGroupMembers
  }
})()

export default GroupUtils
