import { Box, Button, Text } from 'grommet'
import React from 'react'
import { I18n } from 'galarm-config'

const EntityNotFound = ({ entityType, onClose }) => {
  return (
    <Box
      flex={true}
      justify="center"
      align="center"
      gap="medium"
      background="textBackgroundColor">
      <Text color="textColor">{I18n.t('entityNotFound', { entityType })}</Text>
      <Button label={I18n.t('close')} onClick={onClose} />
    </Box>
  )
}

export default EntityNotFound
