import React from 'react'
import { Box } from 'grommet'
import GiftedAvatar from './GiftedAvatar'
import { isSameUser, isSameDay } from './utils'

const Avatar = props => {
  const renderAvatar = () => {
    if (props.renderAvatar) {
      const { ...avatarProps } = props
      return props.renderAvatar(avatarProps)
    }
    return (
      <GiftedAvatar
        avatarStyle={props.imageStyle[props.position]}
        user={props.currentMessage.user}
        onPress={() =>
          props.onPressAvatar && props.onPressAvatar(props.currentMessage.user)
        }
      />
    )
  }
  const renderAvatarOnTop = props.renderAvatarOnTop
  const messageToCompare = renderAvatarOnTop
    ? props.previousMessage
    : props.nextMessage

  if (props.renderAvatar === null) {
    return null
  }

  if (
    isSameUser(props.currentMessage, messageToCompare) &&
    isSameDay(props.currentMessage, messageToCompare)
  ) {
    return (
      <Box style={props.avatarContainerStyle[props.position]}>
        <GiftedAvatar avatarStyle={props.imageStyle[props.position]} />
      </Box>
    )
  }

  return (
    <Box style={props.avatarContainerStyle[props.position]}>
      {renderAvatar()}
    </Box>
  )
}

Avatar.defaultProps = {
  renderAvatarOnTop: false,
  position: 'left',
  currentMessage: {
    user: null
  },
  nextMessage: {},
  avatarContainerStyle: {},
  imageStyle: {}
}

export default Avatar
