import React from 'react'
import { GlobalConfig } from 'galarm-config'
import {
  TaskManager,
  ActionCreators,
  GroupUtils,
  Utils,
  Selectors
} from 'galarm-shared'
import { useDispatch, useSelector } from 'react-redux'
import { Constants, I18n } from 'galarm-config'
import { NavigationUtils } from 'galarm-ps-api'
import { FirebaseProxy } from 'galarm-ps-api'
import { useState } from 'react'
import EditGroup from './EditGroup'
import arrDifferenceBy from 'lodash/differenceBy'
import objGet from 'lodash/get'

const { makeGroupSelector } = Selectors

const EditGroupContainer = ({ groupId }) => {
  const groupSelector = makeGroupSelector()
  const group = useSelector(state => groupSelector(state, { groupId }))
  const [name, setName] = useState(group.name)
  const [members, setMembers] = useState(group.members)
  const [avatar, setAvatar] = useState(undefined)
  const avatarSource = objGet(group, 'images.avatarFullImageUrl', undefined)
  const username = useSelector(state => state.userInfo.name)

  const id = group.id

  const dispatch = useDispatch()

  const onSaveGroup = () => {
    const validationMessages = validateGroup()
    if (validationMessages.length > 0) {
      NavigationUtils.showAlert(
        I18n.t('cantSaveGroup'),
        validationMessages.join('\n')
      )
    } else {
      dispatch(
        ActionCreators.setProgress(
          Constants.ProgressStates.IN_PROGRESS,
          I18n.t('editingGroup')
        )
      )
      TaskManager.addHttpsCloudTask('editGroup', {
        groupId: group.id,
        groupName: group.name,
        uid: GlobalConfig.uid,
        username: username,
        newName: group.name !== name ? name : '',
        addedMembers: arrDifferenceBy(
          members,
          group.members,
          member => member.id
        ),
        removedMembers: arrDifferenceBy(
          group.members,
          members,
          member => member.id
        )
      })
        .then(() => {
          NavigationUtils.dismissProgress()
          dispatch(ActionCreators.closeNewGroupWizard())
        })
        .catch(error => {
          dispatch(
            ActionCreators.setProgress(
              Constants.ProgressStates.ERROR,
              I18n.t('unableToEditGroup', { error: error.error }),
              true
            )
          )
        })

      GroupUtils.addConnectionsForGroupMembers(members)
      FirebaseProxy.logEvent(Constants.UserAnalyticsEvents.NEW_GROUP, {
        [Constants.UserAnalyticsEventParameters.NUM_MEMBERS]: members.length
      })
    }
  }

  const unblockContact = (contactId, contactName) => {
    dispatch(ActionCreators.unblockContact(contactId, contactName))
  }

  const onSelectMember = member => {
    if (member.blocked) {
      NavigationUtils.showAlert(
        I18n.t('cantSelectContact'),
        I18n.t('cantAddContactToGroupBecauseBlocked', {
          name: member.name
        }),
        [
          {
            text: I18n.t('unblockContact'),
            onClick: () => {
              unblockContact(member.id, member.name)
            }
          },
          {
            text: I18n.t('ok')
          }
        ]
      )
      return
    }

    if (!member.selected) {
      let newMembers = members.slice()
      newMembers.push(member)
      setMembers(newMembers)
    } else {
      let newMembers = members.slice()
      newMembers.splice(Utils.getIndexOfObjectWithId(members, member.id), 1)
      setMembers(newMembers)
    }
  }

  const onAvatarChange = newAvatar => {
    setAvatar(newAvatar)
    if (newAvatar) {
      dispatch(ActionCreators.uploadGroupAvatar(props.id, newAvatar))
    } else {
      dispatch(ActionCreators.deleteGroupAvatar(props.id))
    }
  }

  const onNameChange = event => setName(event.target.value)

  const validateGroup = () => {
    let validationMessages = []
    if (name === '') {
      validationMessages.push(I18n.t('specifyNameForGroup'))
    }
    if (members.length === 0) {
      validationMessages.push(I18n.t('addAtLeastOneMemberToGroup'))
    }
    return validationMessages
  }

  const props = {
    id,
    name,
    onNameChange,
    avatar,
    avatarSource,
    onAvatarChange,
    members: members.filter(member => member.id !== GlobalConfig.uid),
    onSelectMember,
    onSaveGroup,
    isEdit: true
  }

  return <EditGroup {...props} />
}

export default EditGroupContainer
