import React, { useState, useEffect, useRef } from 'react'
import { Box } from 'grommet'
import { v4 as uuidv4 } from 'uuid'
import * as utils from './utils'
import Avatar from './Avatar'
import Bubble from './Bubble'
import MessageText from './MessageText'
import Composer from './Composer'
import Day from './Day'
import InputToolbar from './InputToolbar'
import LoadEarlier from './LoadEarlier'
import Message from './Message'
import MessageContainer from './MessageContainer'
import Send from './Send'
import Time from './Time'
import GiftedAvatar from './GiftedAvatar'

const GiftedChat = props => {
  const [messages, setMessages] = useState([])

  const inputToolbarRef = useRef(null)

  const [text, setText] = useState('')

  useEffect(() => {
    initMessages(props.messages)
  }, [props.messages])

  const initMessages = (messages = []) => {
    setMessages(messages)
  }

  const renderMessages = () => {
    return (
      <Box style={{ flex: 1 }}>
        <MessageContainer {...props} messages={messages} />
        {renderChatFooter()}
      </Box>
    )
  }

  const onSend = (messages = [], shouldResetInputToolbar = false) => {
    if (!Array.isArray(messages)) {
      messages = [messages]
    }

    messages = messages.map(message => {
      return {
        ...message,
        user: props.user,
        createdAt: new Date(),
        _id: props.messageIdGenerator()
      }
    })

    if (shouldResetInputToolbar === true) {
      resetInputToolbar()
    }
    props.onSend(messages)
  }

  const resetInputToolbar = () => {
    inputToolbarRef.current.resetValue()
    setText('')
  }

  const onInputTextChanged = text => {
    if (props.onInputTextChanged) {
      props.onInputTextChanged(text)
    }
    setText(text)
  }

  const renderInputToolbar = () => {
    const inputToolbarProps = {
      ...props,
      text: text,
      onSend: onSend,
      onTextChanged: onInputTextChanged,
      textInputProps: {
        ...props.textInputProps,
        maxLength: props.maxInputLength,
        initialChatText: props.initialChatText
      }
    }
    if (props.renderInputToolbar) {
      return props.renderInputToolbar(inputToolbarProps)
    }
    return <InputToolbar {...inputToolbarProps} ref={inputToolbarRef} />
  }

  const renderChatFooter = () => {
    if (props.renderChatFooter) {
      const footerProps = {
        ...props
      }
      return props.renderChatFooter(footerProps)
    }
    return null
  }

  return (
    <Box style={styles.container}>
      {renderMessages()}
      {renderInputToolbar()}
    </Box>
  )
}

const styles = {
  container: {
    flex: 1
  }
}

GiftedChat.defaultProps = {
  messages: [],
  onSend: () => {},
  loadEarlier: false,
  onLoadEarlier: () => {},
  locale: null,
  keyboardShouldPersistTaps: 'always',
  renderAvatar: null,
  renderBubble: null,
  renderFooter: null,
  renderChatFooter: null,
  renderMessageText: null,
  renderMessageImage: null,
  renderComposer: null,
  renderCustomView: null,
  renderDay: null,
  renderInputToolbar: null,
  renderLoadEarlier: null,
  renderLoading: null,
  renderMessage: null,
  renderSend: null,
  renderTime: null,
  user: {},
  bottomOffset: 0,
  isLoadingEarlier: false,
  messageIdGenerator: () => uuidv4(),
  maxInputLength: null
}

export {
  GiftedChat,
  Avatar,
  Bubble,
  MessageText,
  Composer,
  Day,
  InputToolbar,
  LoadEarlier,
  Message,
  MessageContainer,
  Send,
  Time,
  GiftedAvatar,
  utils
}
