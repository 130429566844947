import React from 'react'
import { Box } from 'grommet'
import { useSelector } from 'react-redux'
import { colorThemes } from 'galarm-config'

// Reusable component to create an avatar with a badge in the right hand corner
// If avatar source is not present, uses avatar with initials
// Badge component must be passed as the props
// Accept item (for the avatar data), badge (for the badge component), avatarSource and size

const BadgeProvider = props => {
  const colorScheme = useSelector(state => state.userSettings.webColorScheme)

  let location = {}
  switch (props.location || 'topRight') {
    case 'topRight':
      location = {
        top: -5,
        right: -10
      }
      break
    case 'bottomRight':
      location = {
        bottom: -5,
        right: -10
      }
      break
  }
  const badge = (
    <Box
      border={{
        color: colorThemes.getColorTheme(colorScheme).textBackgroundColor,
        size: 'small'
      }}
      pad="xsmall"
      style={{
        backgroundColor:
          props.backgroundColor ||
          colorThemes.getColorTheme(colorScheme).mediumTint,
        position: 'absolute',
        ...location
      }}
      round
      onClick={props.onClick}>
      {props.badge}
    </Box>
  )

  return (
    <Box direction="row" style={{ position: 'relative' }}>
      {props.children}
      {badge}
    </Box>
  )
}

export default BadgeProvider
