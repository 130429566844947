import React from 'react'
import { GlobalConfig } from 'galarm-config'
import { TaskManager, ActionCreators, GroupUtils, Utils } from 'galarm-shared'
import { useDispatch, useSelector } from 'react-redux'
import { Constants, I18n } from 'galarm-config'
import { NavigationUtils } from 'galarm-ps-api'
import { FirebaseProxy } from 'galarm-ps-api'
import { useState } from 'react'
import EditGroup from './EditGroup'

const NewGroupContainer = () => {
  const [name, setName] = useState('')
  const [members, setMembers] = useState([])
  const [avatar, setAvatar] = useState(undefined)
  const [avatarSource, setAvatarSource] = useState(undefined)

  const username = useSelector(state => state.userInfo.name)
  const mobileNumber = useSelector(state => state.userInfo.mobileNumber)

  const id = GlobalConfig.rootFirebaseRef.push().key

  const dispatch = useDispatch()

  const onSaveGroup = () => {
    const validationMessages = validateGroup()
    if (validationMessages.length > 0) {
      NavigationUtils.showAlert(
        I18n.t('cantSaveGroup'),
        validationMessages.join('\n')
      )
    } else {
      dispatch(
        ActionCreators.setProgress(
          Constants.ProgressStates.IN_PROGRESS,
          I18n.t('addingGroup')
        )
      )
      TaskManager.addHttpsCloudTask('addGroup', {
        groupId: id,
        name: name.trim(),
        uid: GlobalConfig.uid,
        username: username,
        mobileNumber: mobileNumber,
        members: members
      })
        .then(() => {
          NavigationUtils.dismissProgress()
          dispatch(ActionCreators.closeNewGroupWizard())
          avatar && dispatch(ActionCreators.uploadGroupAvatar(id, avatar))
        })
        .catch(error => {
          dispatch(
            ActionCreators.setProgress(
              Constants.ProgressStates.ERROR,
              I18n.t('unableToAddGroup', { error: error.error }),
              true
            )
          )
        })

      GroupUtils.addConnectionsForGroupMembers(members)
      FirebaseProxy.logEvent(Constants.UserAnalyticsEvents.NEW_GROUP, {
        [Constants.UserAnalyticsEventParameters.NUM_MEMBERS]: members.length
      })
    }
  }

  const unblockContact = (contactId, contactName) => {
    dispatch(ActionCreators.unblockContact(contactId, contactName))
  }

  const onSelectMember = member => {
    if (member.blocked) {
      NavigationUtils.showAlert(
        I18n.t('cantSelectContact'),
        I18n.t('cantAddContactToGroupBecauseBlocked', {
          name: member.name
        }),
        [
          {
            text: I18n.t('unblockContact'),
            onPress: () => {
              unblockContact(member.id, member.name)
            }
          },
          {
            text: I18n.t('ok')
          }
        ]
      )
      return
    }

    if (!member.selected) {
      let newMembers = members.slice()
      newMembers.push(member)
      setMembers(newMembers)
    } else {
      let newMembers = members.slice()
      newMembers.splice(Utils.getIndexOfObjectWithId(members, member.id), 1)
      setMembers(newMembers)
    }
  }

  const onNameChange = event => setName(event.target.value)

  const onAvatarChange = newAvatar => {
    setAvatar(newAvatar)
    setAvatarSource(newAvatar ? URL.createObjectURL(newAvatar) : null)
  }

  const validateGroup = () => {
    let validationMessages = []
    if (name === '') {
      validationMessages.push(I18n.t('specifyNameForGroup'))
    }
    if (members.length === 0) {
      validationMessages.push(I18n.t('addAtLeastOneMemberToGroup'))
    }
    return validationMessages
  }

  const props = {
    id,
    name,
    onNameChange,
    avatar,
    avatarSource,
    onAvatarChange,
    members,
    onSelectMember,
    onSaveGroup,
    isEdit: false
  }

  return <EditGroup {...props} />
}

export default NewGroupContainer
