import ActionTypes from '../actions/types'
import { createReducer } from 'reduxsauce'

const INITIAL_STATE = {
  state: '',
  message: '',
  closeable: false
}

const setProgress = (state, action) => ({
  ...state,
  state: action.payload.state,
  message: action.payload.message,
  closeable: action.payload.closeable
})

const resetProgress = () => ({ INITIAL_STATE })

const ACTION_HANDLERS = {
  [ActionTypes.SET_PROGRESS]: setProgress,
  [ActionTypes.RESET_PROGRESS]: resetProgress
}

export default createReducer(INITIAL_STATE, ACTION_HANDLERS)
