import React from 'react'
import Separator from './Separator'
import { Constants } from 'galarm-config'
import { I18n } from 'galarm-config'
import { Box, List, Text } from 'grommet'
import { Checkmark } from 'grommet-icons'
import HoverBox from './HoverBox'
import { NavigationUtils } from 'galarm-ps-api'
import { colorThemes } from 'galarm-config'
import { useSelector } from 'react-redux'

const WeeklyRepetitionItemSummary = ({
  label,
  selected,
  onSelectRepetition
}) => {
  const colorScheme = useSelector(state => state.userSettings.webColorScheme)
  return (
    <HoverBox
      onClick={onSelectRepetition}
      background={
        selected
          ? colorThemes.getColorTheme(colorScheme).listItemSelectedColor
          : 'textBackgroundColor'
      }>
      <Text>{label}</Text>
      {selected && <Checkmark />}
    </HoverBox>
  )
}

const DaySummary = item => {
  const colorScheme = useSelector(state => state.userSettings.webColorScheme)
  return (
    <Box
      direction="row"
      justify="between"
      pad="small"
      background={
        item.selected
          ? colorThemes.getColorTheme(colorScheme).listItemSelectedColor
          : 'textBackgroundColor'
      }>
      <Text>{item.label}</Text>
      {item.selected && <Checkmark />}
    </Box>
  )
}

const SelectWeeklyRepetition = props => {
  const repetitionItems = [
    {
      label: I18n.t('everySunday'),
      value: 'every_sun'
    },
    {
      label: I18n.t('everyMonday'),
      value: 'every_mon'
    },
    {
      label: I18n.t('everyTuesday'),
      value: 'every_tue'
    },
    {
      label: I18n.t('everyWednesday'),
      value: 'every_wed'
    },
    {
      label: I18n.t('everyThursday'),
      value: 'every_thu'
    },
    {
      label: I18n.t('everyFriday'),
      value: 'every_fri'
    },
    {
      label: I18n.t('everySaturday'),
      value: 'every_sat'
    }
  ]

  const everyDayRepetition = [
    'every_sun',
    'every_mon',
    'every_tue',
    'every_wed',
    'every_thu',
    'every_fri',
    'every_sat'
  ]

  const weekdaysRepetition = [
    'every_mon',
    'every_tue',
    'every_wed',
    'every_thu',
    'every_fri'
  ]

  const weekendsRepetiton = ['every_sat', 'every_sun']

  const onSelectDay = ({ item }) => {
    const { value, selected } = item
    let newSelectedRepetitions = props.selectedRepetitions.slice()
    if (!selected) {
      newSelectedRepetitions.push(value)
    } else {
      const index = props.selectedRepetitions.findIndex(
        selectedRepetition => selectedRepetition === value
      )
      newSelectedRepetitions.splice(index, 1)
    }

    let sortedSelectedRepetitions = []
    repetitionItems.forEach(item => {
      if (
        newSelectedRepetitions.findIndex(
          repetition => repetition === item.value
        ) !== -1
      ) {
        sortedSelectedRepetitions.push(item.value)
      }
    })

    if (sortedSelectedRepetitions && sortedSelectedRepetitions.length > 0) {
      props.onSaveAlarmRepetition(
        Constants.RepeatTypes.DAYS_OF_WEEK,
        sortedSelectedRepetitions.join(',')
      )
    } else {
      NavigationUtils.showAlert('', I18n.t('selectAtLeastOneDayForRepetition'))
    }
  }

  const isEveryDaySelected = () => {
    return (
      everyDayRepetition.every(repetition =>
        props.selectedRepetitions.includes(repetition)
      ) && props.selectedRepetitions.length === 7
    )
  }

  const isWeekdaysSelected = () => {
    return (
      weekdaysRepetition.every(repetition =>
        props.selectedRepetitions.includes(repetition)
      ) && props.selectedRepetitions.length === 5
    )
  }

  const isWeekendsSelected = () => {
    return (
      weekendsRepetiton.every(repetition =>
        props.selectedRepetitions.includes(repetition)
      ) && props.selectedRepetitions.length === 2
    )
  }

  const onSelectEveryDay = () => {
    props.onSaveAlarmRepetition(
      Constants.RepeatTypes.DAYS_OF_WEEK,
      everyDayRepetition.join(',')
    )
  }

  const onSelectWeekdays = () => {
    props.onSaveAlarmRepetition(
      Constants.RepeatTypes.DAYS_OF_WEEK,
      weekdaysRepetition.join(',')
    )
  }

  const onSelectWeekends = () => {
    props.onSaveAlarmRepetition(
      Constants.RepeatTypes.DAYS_OF_WEEK,
      weekendsRepetiton.join(',')
    )
  }

  const repetitionData = repetitionItems.map(repetitionItem => {
    if (props.selectedRepetitions.includes(repetitionItem.value)) {
      return {
        ...repetitionItem,
        selected: true
      }
    } else {
      return {
        ...repetitionItem,
        selected: false
      }
    }
  })

  const everydaySelected = isEveryDaySelected()
  const weekdaysSelected = isWeekdaysSelected()
  const weekendsSelected = isWeekendsSelected()

  return (
    <Box width="300px">
      <List
        pad="none"
        data={repetitionData}
        onClickItem={onSelectDay}
        border={false}
        // eslint-disable-next-line react/no-children-prop
        children={DaySummary}
      />
      <Separator />
      <WeeklyRepetitionItemSummary
        label={Constants.EVERY_DAY_STRING}
        selected={everydaySelected}
        onSelectRepetition={onSelectEveryDay}
      />
      <WeeklyRepetitionItemSummary
        label={Constants.WEEKDAYS_STRING}
        selected={weekdaysSelected}
        onSelectRepetition={onSelectWeekdays}
      />
      <WeeklyRepetitionItemSummary
        label={Constants.WEEKENDS_STRING}
        selected={weekendsSelected}
        onSelectRepetition={onSelectWeekends}
      />
    </Box>
  )
}

export default SelectWeeklyRepetition
