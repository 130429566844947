import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import {
  ActionCreators,
  AlarmUtils,
  DateTimeUtils,
  Utils,
  Selectors
} from 'galarm-shared'
import { Box } from 'grommet'
import { colorThemes, Constants } from 'galarm-config'
import isArray from 'lodash/isArray'
import isPlainObject from 'lodash/isPlainObject'
import CalendarToolbar from './CalendarToolbar'
import debounce from 'lodash/debounce'
import isObjEqual from 'fast-deep-equal'

import 'react-big-calendar/lib/css/react-big-calendar.css'

const {
  alarmCategoriesSelector,
  allAlarmsSelector,
  makeAlarmsInCategorySelector
} = Selectors

// To make the calendar start on Monday
// moment.updateLocale('en', {
//   week: {
//     dow: 1,
//     doy: 1
//   }
// })
const localizer = momentLocalizer(moment)

const CalendarView = () => {
  const dispatch = useDispatch()

  const lastUsedCalendarView =
    window.localStorage.getItem('calendarView') || 'month'
  const [calendarView, setCalendarView] = useState(lastUsedCalendarView)
  const [monthForCalendarView, setMonthForCalendarView] = useState(
    moment().month()
  )

  const colorScheme = useSelector(state => state.userSettings.webColorScheme)

  const allAlarms = useSelector(allAlarmsSelector)

  // Filter alarms by chosen alarm category
  const categorizedAlarmView = useSelector(
    state => state.userSettings.categorizedAlarmView
  )
  const currentlySelectedAlarmCategoryId = useSelector(
    state => state.appState.currentlySelectedAlarmCategoryId
  )
  const alarmsInCategorySelector = makeAlarmsInCategorySelector()
  const alarmsInCategory = useSelector(state =>
    alarmsInCategorySelector(state, {
      id: currentlySelectedAlarmCategoryId
    })
  )

  const alarmsToConsider = useMemo(() => {
    let alarmsToConsider
    if (categorizedAlarmView && currentlySelectedAlarmCategoryId) {
      alarmsToConsider = alarmsInCategory
    } else {
      alarmsToConsider = allAlarms
    }

    alarmsToConsider = alarmsToConsider.filter(alarm => alarm.status)
    return alarmsToConsider
  }, [
    categorizedAlarmView,
    currentlySelectedAlarmCategoryId,
    alarmsInCategory,
    allAlarms
  ])

  const [dateRange, setDateRange] = useState({
    start: Date.now(),
    end: Date.now()
  })

  const alarmCategories = useSelector(alarmCategoriesSelector)

  const [events, setEvents] = useState([])

  useEffect(() => {
    const currDateRange = DateTimeUtils.getCalendarMonthRangeForADate(
      Date.now()
    )
    setDateRange(currDateRange)
  }, [])

  const calculateEventsForCalendar = () => {
    let events = AlarmUtils.getEventsForAlarmsInRange(
      alarmsToConsider,
      dateRange.start,
      dateRange.end,
      calendarView
    )
    events.forEach(event => {
      const alarmCategory = AlarmUtils.getAlarmCategoryForAlarm(
        alarmCategories,
        event.id
      )
      event.category = alarmCategory
    })
    setEvents(events)
  }

  const debouncedCalculateEventsForCalendar = useCallback(
    debounce(calculateEventsForCalendar, 500, {
      leading: true,
      maxWait: 2000
    }),
    [alarmsToConsider, dateRange, calendarView, alarmCategories]
  )

  useEffect(() => {
    debouncedCalculateEventsForCalendar()
    return () => debouncedCalculateEventsForCalendar.cancel()
  }, [
    dateRange,
    allAlarms,
    alarmCategories,
    categorizedAlarmView,
    currentlySelectedAlarmCategoryId
  ])

  // const events = allAlarms.map(alarm => {
  //   const alarmDate = AlarmUtils.getCurrentDateForAlarm(alarm)
  //   return {
  //     id: alarm.id,
  //     title: alarm.name,
  //     allDay: false,
  //     start: new Date(alarmDate),
  //     end: new Date(alarmDate)
  //   }
  // })

  const onChangeCalendarView = view => {
    window.localStorage.setItem('calendarView', view)
    setCalendarView(view)
  }

  const onSelectSlot = ({ start }) => {
    const alarmDate = moment(start).valueOf()
    dispatch(ActionCreators.showSelectAlarmTypeScreen({ alarmDate }))
  }

  // eslint-disable-next-line no-unused-vars
  const onRangeChange = (range, rangeType) => {
    // In case of month range, it is a plain object with start and end
    // Add 1 day to get to the end of the range
    if (isPlainObject(range)) {
      const start = moment(range.start).valueOf()
      const end = moment(range.end).add(1, 'day').valueOf()
      const newDateRange = { start, end }
      if (!isObjEqual(newDateRange, dateRange)) {
        setDateRange(newDateRange)
      }

      if (moment(start).date() === 1) {
        setMonthForCalendarView(moment(start).month())
      } else {
        setMonthForCalendarView(moment(start).month() + 1)
      }
    }
    // In case of week and day, it is an array containing the days
    // Add 1 day to get to the end of the range
    else if (isArray(range)) {
      const start = moment(range[0]).valueOf()
      const end = moment(range[range.length - 1])
        .add(1, 'day')
        .valueOf()
      const newDateRange = { start, end }
      if (!isObjEqual(newDateRange, dateRange)) {
        setDateRange(newDateRange)
      }
    }
  }

  const onSelectEvent = ({ id }) => {
    const alarm = Utils.getObjectWithId(allAlarms, id)
    if (alarm.alarmCategory === Constants.AlarmCategories.PARTICIPANT_ALARM) {
      dispatch(ActionCreators.showParticipantAlarmDetailsScreen(id))
    } else {
      dispatch(ActionCreators.showAlarmDetailsScreen(id))
    }
  }

  const eventTimeRangeFormat = ({ start, end }) => {
    const startDate = moment(start)
    const endDate = moment(end)
    if (startDate.isSame(endDate)) {
      // Return undefined such that : is not shown in tooltip and
      // the event on week and day views. Returning '' shows : in
      // tooltip
      return undefined
    } else {
      return `${DateTimeUtils.getTimeAsString(
        start
      )} - ${DateTimeUtils.getTimeAsString(end)}`
    }
  }

  const eventPropGetter = event => {
    const eventBackgroundColor = AlarmUtils.getColorForAlarmCategory(
      colorScheme,
      event.category.color
    )

    const isEventOnPastDate = DateTimeUtils.isDateBeforeToday(event.start)
    const textColor = isEventOnPastDate
      ? colorThemes.getColorTheme(colorScheme).darkTint
      : colorThemes.getColorTheme(colorScheme).textColor

    return {
      style: {
        backgroundColor: eventBackgroundColor,
        borderColor: 'transparent',
        color: textColor,
        padding: '2px',
        minHeight: '24px',
        fontSize: 16,
        ...(calendarView === 'week'
          ? {
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis'
            }
          : {})
      }
    }
  }

  const dayPropGetter = day => {
    if (DateTimeUtils.isDateToday(day)) {
      return {
        style: {
          backgroundColor: colorThemes.getColorTheme(colorScheme).tileHeader
        }
      }
    } else if (DateTimeUtils.isDateBeforeToday(day)) {
      return {
        style: {
          backgroundColor: colorThemes.getColorTheme(colorScheme).lightTint
        }
      }
    } else if (
      calendarView === 'month' &&
      moment(day).month() !== monthForCalendarView
    ) {
      return {
        style: {
          backgroundColor:
            colorThemes.getColorTheme(colorScheme).screenBackgroundColor
        }
      }
    }
    return {}
  }

  // This applies each half hour slot in week and day view
  // eslint-disable-next-line no-unused-vars
  const slotPropGetter = (...props) => {
    return {
      style: {
        borderColor: 'transparent'
      }
    }
  }

  return (
    <Box flex background="screenBackgroundColor">
      <Calendar
        views={['month', 'week', 'day']} // Remove agenda
        view={calendarView}
        localizer={localizer}
        events={events}
        style={{
          backgroundColor:
            colorThemes.getColorTheme(colorScheme).screenBackgroundColor
        }}
        selectable // Needed to make the cells clickable
        onView={onChangeCalendarView}
        onRangeChange={onRangeChange}
        onSelectSlot={onSelectSlot}
        onSelectEvent={onSelectEvent}
        components={{
          toolbar: CalendarToolbar
        }}
        formats={{ eventTimeRangeFormat: eventTimeRangeFormat }}
        eventPropGetter={eventPropGetter}
        dayPropGetter={dayPropGetter}
        slotPropGetter={slotPropGetter}
        scrollToTime={new Date()}
        tooltipAccessor="tooltip"
      />
    </Box>
  )
}

export default CalendarView
