import React, { useEffect, useReducer, useState } from 'react'
import { Button, Layer, Text, ThemeContext, Tip } from 'grommet'
import { useDispatch, useSelector } from 'react-redux'
import { Constants, GlobalConfig } from 'galarm-config'
import { Box, Grid } from 'grommet'
import UnifiedAlarmList from './UnifiedAlarmList'
import InspectorPanel from './InspectorPanel'
import SidebarNavbar from './SidebarNavbar'
import { I18n } from 'galarm-config'
import GroupList from './GroupList'
import ContactList from './ContactList'
import { ActionCreators } from 'galarm-shared'
import AppCanvas from './AppCanvas'
import AlertDialog from './AlertDialog'
import ProgressDialog from './ProgressDialog'
import { FirebaseProxy, NavigationUtils } from 'galarm-ps-api'
import { Appearance } from 'react-native'
import SelectAlarmType from './SelectAlarmType'
import QuickAlarmContainer from './QuickAlarmContainer'
import Feedback from './Feedback'
import DeleteInactiveAlarms from './DeleteInactiveAlarms'
import { FormNext, FormPrevious } from 'grommet-icons'
import * as RNLocalize from 'react-native-localize'
import moment from 'moment-timezone'
import TaskList from './TaskList'

const MainApp = () => {
  const tabActiveIndex = useSelector(state => state.appState.tabActiveIndex)
  const [isOnline, setIsOnline] = useState(navigator.onLine)

  const showAppRunningElsewhereAlert = useSelector(
    state => state.appState.showAppRunningElsewhereAlert
  )
  const progress = useSelector(state => state.progress)
  const mobileAndWebTimezoneMismatchDetected = useSelector(
    state => state.appState.mobileAndWebTimezoneMismatchDetected
  )
  const showProgress = progress.state

  const dispatch = useDispatch()

  const emitSessionResumedEvent = () => {
    dispatch(ActionCreators.setShowAppRunningElsewhereAlert(false))
    GlobalConfig.eventEmitter.emit('sessionResumed', {})
    FirebaseProxy.logEvent(
      Constants.UserAnalyticsEvents.APP_SESSION_RESUMED,
      {}
    )
  }

  const emitSessionEndedEvent = () => {
    dispatch(ActionCreators.setShowAppRunningElsewhereAlert(false))
    GlobalConfig.eventEmitter.emit('sessionEnded', {})
    FirebaseProxy.logEvent(
      Constants.UserAnalyticsEvents.APP_SESSION_DISMISSED,
      {}
    )
  }

  const onUnload = event => {
    event.preventDefault()
    event.returnValue = ''
  }

  const checkIfMobileAndWebTimezonesAreSame = () => {
    var now = moment.utc()
    // Get the zone offsets for this time, in minutes

    const mobileTimezone =
      window.localStorage.getItem('mobileTimezone') || RNLocalize.getTimeZone()

    var mobileTimezoneOffset = moment.tz.zone(mobileTimezone).utcOffset(now)
    var webTimezoneOffset = moment.tz
      .zone(RNLocalize.getTimeZone())
      .utcOffset(now)

    // If the offsets are different, this means that both device and web are at different timezones
    if (mobileTimezoneOffset !== webTimezoneOffset) {
      GlobalConfig.store.dispatch(
        ActionCreators.setMobileAndWebTimezoneMismatchDetected(true)
      )
      FirebaseProxy.logEvent(
        Constants.UserAnalyticsEvents.MOBILE_AND_WEB_TIMEZONE_MISMATCH_DETECTED,
        {}
      )
    } else {
      GlobalConfig.store.dispatch(
        ActionCreators.setMobileAndWebTimezoneMismatchDetected(false)
      )
    }
  }

  // eslint-disable-next-line no-unused-vars
  const onVisiblityChange = event => {
    if (document.visibilityState === 'visible') {
      forceUpdate()
      checkIfMobileAndWebTimezonesAreSame()
    }
  }

  useEffect(() => {
    window.addEventListener('beforeunload', onUnload)
    const appearanceSub = Appearance.addChangeListener(onThemeChange)

    FirebaseProxy.logEvent(Constants.UserAnalyticsEvents.OPEN_APP, {
      [Constants.UserAnalyticsEventParameters.UID]: GlobalConfig.uid,
      [Constants.UserAnalyticsEventParameters.LOCALE]: I18n.currentLocale()
    })

    document.addEventListener('visibilitychange', onVisiblityChange)

    // Listen to changes in the device timezone, if there is a change in timezone, we don't want the web app to be operational
    const unsubscribeFromTimezoneChanges = GlobalConfig.rootFirebaseRef
      .child('userInfos')
      .child(GlobalConfig.uid)
      .child('timezone')
      .on('value', taskSnapshot => {
        if (taskSnapshot && taskSnapshot.exists()) {
          const deviceTimezone = taskSnapshot.val()
          window.localStorage.setItem('deviceTimezone', deviceTimezone)
          checkIfMobileAndWebTimezonesAreSame(deviceTimezone)
        }
      })

    // Listen to changes in the device locale, if there is a change in locale, we want to update the locale on the web as well
    const unsubscribeFromDeviceLocaleChanges = GlobalConfig.rootFirebaseRef
      .child('userInfos')
      .child(GlobalConfig.uid)
      .child('deviceLocale')
      .on('value', taskSnapshot => {
        if (taskSnapshot && taskSnapshot.exists()) {
          const deviceLocale = taskSnapshot.val()
          const oldDeviceLocale = window.localStorage.getItem('deviceLocale')
          if (oldDeviceLocale) {
            if (oldDeviceLocale !== deviceLocale) {
              window.localStorage.setItem('deviceLocale', deviceLocale)
              window.location.reload(false)
            }
          } else {
            window.localStorage.setItem('deviceLocale', deviceLocale)
          }
        }
      })

    // Update network status
    const handleOnline = () => {
      setIsOnline(true)
    }

    const handleOffline = () => {
      setIsOnline(false)
    }

    // Listen to the online status
    window.addEventListener('online', handleOnline)

    // Listen to the offline status
    window.addEventListener('offline', handleOffline)

    return () => {
      unsubscribeFromTimezoneChanges()
      unsubscribeFromDeviceLocaleChanges()
      window.removeEventListener('beforeunload', onUnload)
      document.removeEventListener('visibilitychange', onVisiblityChange)
      appearanceSub.remove()
      window.removeEventListener('online', handleOnline)
      window.removeEventListener('offline', handleOffline)
    }
  }, [])

  const onThemeChange = ({ colorScheme }) => {
    dispatch(ActionCreators.updateWebColorScheme(colorScheme))
  }

  const defaultAlertConfiguration = {
    title: '',
    message: '',
    buttons: [],
    cancelable: false
  }

  const defaultFeedbackConfiguration = {
    type: '',
    extras: ''
  }

  const [alertConfiguration, setAlertConfiguration] = useState(
    defaultAlertConfiguration
  )

  const [feedbackConfiguration, setFeedbackCofiguration] = useState(
    defaultFeedbackConfiguration
  )

  // eslint-disable-next-line no-unused-vars
  const [ignored, forceUpdate] = useReducer(x => x + 1, 0)
  GlobalConfig.forceUpdate = forceUpdate

  GlobalConfig.showAlert = setAlertConfiguration
  GlobalConfig.hideAlert = () =>
    setAlertConfiguration(defaultAlertConfiguration)

  GlobalConfig.showFeedbackScreen = setFeedbackCofiguration
  GlobalConfig.hideFeedbackScreen = () =>
    setFeedbackCofiguration(defaultFeedbackConfiguration)

  const showAlert = alertConfiguration.title || alertConfiguration.message
  const showFeedbackScreen = feedbackConfiguration.type

  const [showSearchBar, setShowSearchBar] = useState(false)

  const [showDeleteInactiveAlarmsDialog, setShowDeleteInactiveAlarmsDialog] =
    useState(false)

  GlobalConfig.showDeleteInactiveAlarmsDialog = () =>
    setShowDeleteInactiveAlarmsDialog(true)
  GlobalConfig.hideDeleteInactiveAlarmsDialog = () =>
    setShowDeleteInactiveAlarmsDialog(false)

  const showOrHideSearchBar = () => {
    if (showSearchBar) {
      setShowSearchBar(false)
    } else {
      setShowSearchBar(true)
    }
  }

  const hideSearchBar = () => {
    setShowSearchBar(false)
  }

  const showSelectAlarmTypeScreen = useSelector(
    state => state.appState.showSelectAlarmTypeScreen
  )
  const hideSelectAlarmTypeScreen = () =>
    dispatch(ActionCreators.hideSelectAlarmTypeScreen())

  const showQuickReminder = useSelector(
    state => state.appState.showQuickReminder
  )
  const hideQuickReminder = () =>
    dispatch(ActionCreators.setShowQuickReminder(false))

  const showSidebar = useSelector(state => state.appState.showSidebar)

  const toggleSidebar = () => {
    dispatch(ActionCreators.setShowSidebar(!showSidebar))
  }

  let gridColums, gridAreas
  if (showSidebar) {
    gridColums = [['400px', '25%'], '15px', ['large', 'flex']]
    gridAreas = [
      { name: 'sidebar', start: [0, 0], end: [0, 0] },
      { name: 'divider', start: [1, 0], end: [1, 0] },
      { name: 'inspectorPanel', start: [2, 0], end: [2, 0] }
    ]
  } else {
    gridColums = ['15px', 'flex']
    gridAreas = [
      { name: 'divider', start: [0, 0], end: [0, 0] },
      { name: 'inspectorPanel', start: [1, 0], end: [1, 0] }
    ]
  }

  return (
    <>
      <Grid
        fill
        columns={gridColums}
        rows={['flex']}
        //[column,row] coordinates for start and end of areas
        areas={gridAreas}>
        {showSidebar && (
          <AppCanvas gridArea="sidebar">
            <SidebarNavbar
              showSearchBar={showSearchBar}
              showOrHideSearchBar={showOrHideSearchBar}
            />
            <Box flex={{ shrink: 0 }} direction="row" justify="around">
              <Box
                flex
                pad="small"
                align="center"
                onClick={() => dispatch(ActionCreators.setTabActiveIndex(0))}
                background={
                  tabActiveIndex === 0 ? 'darkPrimary' : 'tileHeader'
                }>
                <Text
                  weight={tabActiveIndex === 0 ? 'bold' : 'normal'}
                  color={tabActiveIndex === 0 ? 'white' : 'textColor'}>
                  {I18n.t('alarms')}
                </Text>
              </Box>
              <Box
                flex
                pad="small"
                align="center"
                onClick={() => dispatch(ActionCreators.setTabActiveIndex(1))}
                background={
                  tabActiveIndex === 1 ? 'darkPrimary' : 'tileHeader'
                }>
                <Text
                  weight={tabActiveIndex === 1 ? 'bold' : 'normal'}
                  color={tabActiveIndex === 1 ? 'white' : 'textColor'}>
                  {I18n.t('checklists')}
                </Text>
              </Box>
              <Box
                flex
                pad="small"
                align="center"
                onClick={() => dispatch(ActionCreators.setTabActiveIndex(2))}
                background={
                  tabActiveIndex === 2 ? 'darkPrimary' : 'tileHeader'
                }>
                <Text
                  weight={tabActiveIndex === 2 ? 'bold' : 'normal'}
                  color={tabActiveIndex === 2 ? 'white' : 'textColor'}>
                  {I18n.t('groups')}
                </Text>
              </Box>
              <Box
                flex
                pad="small"
                align="center"
                onClick={() => dispatch(ActionCreators.setTabActiveIndex(3))}
                background={
                  tabActiveIndex === 3 ? 'darkPrimary' : 'tileHeader'
                }>
                <Text
                  weight={tabActiveIndex === 3 ? 'bold' : 'normal'}
                  color={tabActiveIndex === 3 ? 'white' : 'textColor'}>
                  {I18n.t('contacts')}
                </Text>
              </Box>
            </Box>
            {tabActiveIndex === 0 && (
              <UnifiedAlarmList
                showSearchBar={showSearchBar}
                hideSearchBar={hideSearchBar}
              />
            )}
            {tabActiveIndex === 1 && (
              <TaskList
                showSearchBar={showSearchBar}
                hideSearchBar={hideSearchBar}
              />
            )}
            {tabActiveIndex === 2 && (
              <GroupList
                showSearchBar={showSearchBar}
                hideSearchBar={hideSearchBar}
              />
            )}
            {tabActiveIndex === 3 && (
              <ContactList
                showSearchBar={showSearchBar}
                hideSearchBar={hideSearchBar}
              />
            )}
          </AppCanvas>
        )}
        <Box
          gridArea="divider"
          background="light-5"
          justify="center"
          align="center"
          onClick={toggleSidebar}>
          {showSidebar ? (
            <Tip content="Collapse">
              <FormPrevious />
            </Tip>
          ) : (
            <FormNext />
          )}
        </Box>
        <Box gridArea="inspectorPanel" background="light-2">
          <InspectorPanel />
        </Box>
      </Grid>
      {showSelectAlarmTypeScreen && (
        <Layer
          background="lightTint"
          onEsc={hideSelectAlarmTypeScreen}
          onClickOutside={hideSelectAlarmTypeScreen}>
          <SelectAlarmType
            {...showSelectAlarmTypeScreen.props}
            onClose={hideSelectAlarmTypeScreen}
          />
        </Layer>
      )}
      {showQuickReminder && (
        <Layer background="lightTint">
          <QuickAlarmContainer onClose={hideQuickReminder} />
        </Layer>
      )}
      {showAppRunningElsewhereAlert && (
        <ThemeContext.Extend
          value={{
            layer: {
              overlay: {
                background: 'black'
              }
            }
          }}>
          <Layer background="lightTint">
            <Box margin="medium">
              <Text margin={{ bottom: '20px' }}>
                {I18n.t('appRunningElsewhere')}
              </Text>
              <Box direction="row" gap="small" justify="end">
                <Button onClick={emitSessionEndedEvent} label={I18n.t('no')} />
                <Button
                  onClick={emitSessionResumedEvent}
                  label={I18n.t('useHere')}
                />
              </Box>
            </Box>
          </Layer>
        </ThemeContext.Extend>
      )}
      {mobileAndWebTimezoneMismatchDetected && (
        <ThemeContext.Extend
          value={{
            layer: {
              overlay: {
                background: 'black'
              }
            }
          }}>
          <Layer background="lightTint">
            <Box margin="medium">
              <Text margin={{ bottom: '20px' }}>
                {I18n.t('deviceAndWebTimezoneDifferentAlert', {
                  deviceTimezone: window.localStorage.getItem('deviceTimezone'),
                  webTimezone: RNLocalize.getTimeZone()
                })}
              </Text>
            </Box>
          </Layer>
        </ThemeContext.Extend>
      )}
      {showProgress && (
        <Layer
          background="lightTint"
          onEsc={
            progress.closeable
              ? () => NavigationUtils.dismissProgress()
              : undefined
          }
          onClickOutside={
            progress.closeable
              ? () => NavigationUtils.dismissProgress()
              : undefined
          }>
          <ProgressDialog progressConfiguration={progress} />
        </Layer>
      )}
      {showAlert && (
        <Layer
          background="lightTint"
          onEsc={
            alertConfiguration.cancelable ? GlobalConfig.hideAlert : undefined
          }
          onClickOutside={
            alertConfiguration.cancelable ? GlobalConfig.hideAlert : undefined
          }>
          <AlertDialog alertConfiguration={alertConfiguration} />
        </Layer>
      )}
      {showDeleteInactiveAlarmsDialog && (
        <Layer
          background="lightTint"
          onEsc={GlobalConfig.hideDeleteInactiveAlarmsDialog}
          onClickOutside={GlobalConfig.hideDeleteInactiveAlarmsDialog}>
          <Box margin="medium">
            <DeleteInactiveAlarms
              onClose={GlobalConfig.hideDeleteInactiveAlarmsDialog}
            />
          </Box>
        </Layer>
      )}
      {showFeedbackScreen && (
        <Layer
          background="lightTint"
          onEsc={GlobalConfig.hideFeedbackScreen}
          onClickOutside={GlobalConfig.hideFeedbackScreen}>
          <Feedback
            feedbackConfiguration={feedbackConfiguration}
            onClose={GlobalConfig.hideFeedbackScreen}
          />
        </Layer>
      )}
      {!isOnline && (
        <Layer background="lightTint">
          <Text margin="medium">{I18n.t('youAreOffline')}</Text>
        </Layer>
      )}
    </>
  )
}

export default MainApp
