import React from 'react'
import Linkify from 'react-linkify'
import Link from './Link'

const ParsedText = ({ children }) => {
  return (
    <Linkify componentDecorator={Link} properties={{ color: 'primary' }}>
      {children}
    </Linkify>
  )
}

export default ParsedText
