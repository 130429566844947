import ActionTypes from '../actions/types'
import { createReducer } from 'reduxsauce'
import arrayIntersectionBy from 'lodash/intersectionBy'
import arrayUnionBy from 'lodash/unionBy'
import Utils from '../utils/Utils'
import { GlobalConfig } from 'galarm-config'

const INITIAL_STATE = {
  alarms: {}
  // groups: {} We can add groups later on if needed
}

// Store id, text, createdAt (epoch time), userId and username

const addMessagesToAlarmConversation = (state, action) => {
  const alarmId = action.payload.alarmId
  const alarmConversation = state.alarms[alarmId]
  const receivedMessages = action.payload.messages
  const numMessages = receivedMessages.length
  let newMessages, loadEarlier
  if (
    numMessages ===
    GlobalConfig.numConversationMessagesToLoadInOneRequest + 1
  ) {
    newMessages = action.payload.messages
      .sort((message1, message2) => message2.createdAt - message1.createdAt)
      .slice(0, -1)
    loadEarlier = true
  } else {
    newMessages = action.payload.messages.sort(
      (message1, message2) => message2.createdAt - message1.createdAt
    )
    loadEarlier = false
  }
  let finalAlarmConversation
  if (!alarmConversation) {
    finalAlarmConversation = {
      loadEarlier: loadEarlier,
      isLoadingEarlier: false,
      messages: newMessages
    }
  } else {
    const commonMessages = arrayIntersectionBy(
      alarmConversation.messages,
      newMessages,
      message => message.id
    )
    if (commonMessages && commonMessages.length === newMessages.length) {
      finalAlarmConversation = {
        ...alarmConversation,
        loadEarlier: loadEarlier
      }
    } else if (commonMessages && commonMessages.length > 0) {
      finalAlarmConversation = {
        ...alarmConversation,
        loadEarlier: loadEarlier,
        messages: arrayUnionBy(
          alarmConversation.messages,
          newMessages,
          message => message.id
        ).sort((message1, message2) => message2.createdAt - message1.createdAt)
      }
    } else {
      // This will be true only for the first time that we get the conversation messages
      // For subsequent turns, there will be atleast one common message which is the last
      // message in the received messages
      finalAlarmConversation = {
        ...alarmConversation,
        loadEarlier: loadEarlier,
        messages: newMessages
      }
    }
  }

  const newAlarms = {
    ...state.alarms,
    [alarmId]: finalAlarmConversation
  }

  return {
    ...state,
    alarms: newAlarms
  }
}

const addNewMessageToAlarmConversation = (state, action) => {
  const alarmId = action.payload.alarmId
  const message = action.payload.message
  const alarmConversation = state.alarms[alarmId] || {}
  const messages = alarmConversation.messages || []

  if (Utils.getIndexOfObjectWithId(messages, message.id) !== -1) {
    return state
  }

  const newMessages = messages.slice()
  newMessages.unshift(message)
  return {
    ...state,
    alarms: {
      ...state.alarms,
      [alarmId]: {
        ...alarmConversation,
        messages: newMessages
      }
    }
  }
}

const setIsLoadingEarlierMessagesForAlarm = (state, action) => {
  const alarmId = action.payload.alarmId
  const isLoadingEarlier = action.payload.isLoadingEarlier
  const alarmConversation = state.alarms[alarmId]

  if (!alarmConversation) {
    console.tron.log(
      'Trying to set isLoadingEarlier flag for non existent alarm conversation'
    )
    return state
  }

  return {
    ...state,
    alarms: {
      ...state.alarms,
      [alarmId]: {
        ...alarmConversation,
        isLoadingEarlier: isLoadingEarlier
      }
    }
  }
}

const setUnseenMesssagesForAlarm = (state, action) => {
  const alarmId = action.payload.alarmId
  const unseenMessages = action.payload.unseenMessages
  const alarmConversation = state.alarms[alarmId] || {}
  return {
    ...state,
    alarms: {
      ...state.alarms,
      [alarmId]: {
        ...alarmConversation,
        unseenMessages
      }
    }
  }
}

const updateCurrentlyTypingUsersForAlarm = (state, action) => {
  const alarmId = action.payload.alarmId
  const currentlyTypingUsers = action.payload.currentlyTypingUsers
  const alarmConversation = state.alarms[alarmId] || {}
  return {
    ...state,
    alarms: {
      ...state.alarms,
      [alarmId]: {
        ...alarmConversation,
        currentlyTypingUsers
      }
    }
  }
}

const processFirstMessageKeyForAlarmConversation = (state, action) => {
  const alarmId = action.payload.alarmId
  const firstMessageKey = action.payload.firstMessageKey
  const alarmConversation = state.alarms[alarmId]

  if (!alarmConversation) {
    return state
  }

  if (!Utils.getObjectWithId(alarmConversation.messages, firstMessageKey)) {
    return state
  }

  return {
    ...state,
    alarms: {
      ...state.alarms,
      [alarmId]: {
        ...alarmConversation,
        loadEarlier: false
      }
    }
  }
}

const ACTION_HANDLERS = {
  [ActionTypes.ADD_MESSAGES_TO_ALARM_CONVERSATION]:
    addMessagesToAlarmConversation,
  [ActionTypes.ADD_NEW_MESSAGE_TO_ALARM_CONVERSATION]:
    addNewMessageToAlarmConversation,
  [ActionTypes.SET_IS_LOADING_EARLIER_MESSAGES_FOR_ALARM]:
    setIsLoadingEarlierMessagesForAlarm,
  [ActionTypes.PROCESS_FIRST_MESSAGE_KEY_FOR_ALARM_CONVERSATION]:
    processFirstMessageKeyForAlarmConversation,
  [ActionTypes.SET_UNSEEN_MESSAGES_FOR_ALARM]: setUnseenMesssagesForAlarm,
  [ActionTypes.UPDATE_CURRENTLY_TYPING_USERS_FOR_ALARM]:
    updateCurrentlyTypingUsersForAlarm
}

export default createReducer(INITIAL_STATE, ACTION_HANDLERS)
