import React from 'react'
import { Box, CheckBox, Text } from 'grommet'
import { Constants } from 'galarm-config'
import { I18n } from 'galarm-config'
import TintedImage from './TintedImage'

const ParticipantResponse = ({ iconSource, color, onChange }) => {
  return (
    <Box onClick={onChange} pad={'xsmall'}>
      <TintedImage src={iconSource} tintColor={color} />
    </Box>
  )
}

const ParticipantResponseSwitch = ({
  responseStatus: currentStatus,
  onResponseStatusChange: onChange,
  alarmStatus
}) => {
  if (currentStatus === Constants.ALARM_RESPONSE_PENDING) {
    return (
      <Box>
        <Box direction="row" justify="between">
          <ParticipantResponse
            color={alarmStatus === true ? 'green' : 'lightTint'}
            iconSource={require('galarm-res/img/web/ic_alarm_on.svg').default}
            onChange={() => {
              onChange(Constants.ACCEPT_ALARM)
            }}
          />
          <ParticipantResponse
            color={alarmStatus === true ? 'red' : 'lightTint'}
            iconSource={require('galarm-res/img/web/ic_alarm_off.svg').default}
            onChange={() => {
              onChange(Constants.REJECT_ALARM)
            }}
          />
        </Box>
        <Box pad={{ horizontal: 'xsmall' }}>
          <Text size="xsmall" color="darkTint">
            {I18n.t('responsePending')}
          </Text>
        </Box>
      </Box>
    )
  } else {
    return (
      <CheckBox
        checked={currentStatus === Constants.ACCEPT_ALARM}
        toggle
        onChange={event =>
          onChange(
            event.target.checked
              ? Constants.ACCEPT_ALARM
              : Constants.REJECT_ALARM
          )
        }
      />
    )
  }
}

export default ParticipantResponseSwitch
