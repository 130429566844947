import { Box, Image, Meter, Text } from 'grommet'
import React, { useEffect, useState } from 'react'
import { I18n } from 'galarm-config'

const AuthenticatingWithServer = () => {
  const [connectingProgress, setConnectingProgress] = useState(0)

  useEffect(() => {
    const intervalId = setInterval(() => {
      setConnectingProgress(prevProgress => {
        if (prevProgress < 100) {
          return prevProgress + 10
        }
        return prevProgress
      })
    }, 200)

    return () => clearInterval(intervalId)
  }, [])

  return (
    <Box fill justify="center" align="center" gap="small">
      <Image
        src={require('galarm-res/img/web/galarm-logo.svg').default}
        height="60px"
      />
      <Meter
        color="primary"
        round
        thickness="small"
        value={connectingProgress}
        aria-label="meter"
      />
      <Text>{I18n.t('authenticatingWithServer')}</Text>
    </Box>
  )
}

export default AuthenticatingWithServer
