import React from 'react'
import { Box, Text } from 'grommet'
import HoverBox from './HoverBox'
import { Checkmark } from 'grommet-icons'
import { Constants } from 'galarm-config'

const ConfigureRecipientAlarmInterval = ({
  recipientAlarmInterval,
  onSaveRecipientAlarmInterval
}) => {
  const recipientAlarmIntervalItems = Object.keys(
    Constants.RecipientAlarmIntervals
  )
    .filter(
      recipientAlarmInterval => !['2hr', '3hr'].includes(recipientAlarmInterval)
    )
    .map(recipientInterval => {
      return {
        id: recipientInterval,
        ...Constants.RecipientAlarmIntervals[recipientInterval]
      }
    })

  return (
    <Box width={'400px'} overflow="auto">
      {recipientAlarmIntervalItems.map((interval, index) => (
        <HoverBox
          key={index}
          onClick={() => onSaveRecipientAlarmInterval(interval.id)}
          background={
            interval.id === recipientAlarmInterval
              ? 'lightPrimary'
              : 'transparent'
          }>
          <Text>{interval.label}</Text>
          {interval.id === recipientAlarmInterval && <Checkmark />}
        </HoverBox>
      ))}
    </Box>
  )
}

export default ConfigureRecipientAlarmInterval
