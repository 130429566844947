import './App.css'
import React, { useEffect, useState } from 'react'
import { Box, Grommet, Text } from 'grommet'
import { Constants, GlobalConfig, I18n } from 'galarm-config'
import MainApp from './components/MainApp'
import firebase from 'firebase/compat/app'
import { useDispatch, useSelector } from 'react-redux'
import { TaskManager, ActionCreators, Utils, WebUtils } from 'galarm-shared'
import { LogUtils } from 'galarm-ps-api'
import LoginScreen from './components/LoginScreen'
import SessionManager from './api/SessionManager'
import DownloadAppOnMobileScreen from './components/DownloadAppOnMobileScreen'
import * as Sentry from '@sentry/react'
import AuthenticatingWithServer from './components/AuthenticatingWithServer'

const App = () => {
  const [loadedAuthInfo, setLoadedAuthInfo] = useState(false)
  const [authenticating, setAuthenticating] = useState(false)
  const [showQrCode, setShowQrCode] = useState(false)

  const dispatch = useDispatch()

  let colorScheme = useSelector(state => state.userSettings.webColorScheme)
  // Below lines are needed because colorScheme is provided to Grommet which
  // only accepts light or dark values
  if (colorScheme === Constants.COLOR_SCHEMES.AUTO_DARK) {
    colorScheme = Constants.COLOR_SCHEMES.DARK
  } else if (colorScheme === Constants.COLOR_SCHEMES.AUTO_LIGHT) {
    colorScheme = Constants.COLOR_SCHEMES.LIGHT
  }
  const theme = WebUtils.getGrommetTheme(colorScheme)

  const username = useSelector(state => state.userInfo.name)

  const emitCloseRightClickMenuEvent = () => {
    GlobalConfig.eventEmitter.emit('closeRightClickMenu', {})
  }

  const authenticateUser = sessionId => {
    TaskManager.addHttpsCloudTask('generateAuthenticationToken', {
      uid: GlobalConfig.uid,
      // Commenting it for now because for some users, the phoneId shared
      // on the QR code is not the same as the one on the database and
      // hence the user is not able to authenticate
      // phoneId: GlobalConfig.phoneId || null,
      appSecret: GlobalConfig.appSecret
    })
      .then(taskResult => {
        const authToken = taskResult.result.authToken
        return firebase.auth().signInWithCustomToken(authToken)
      })
      // eslint-disable-next-line no-unused-vars
      .then(user => {
        const existingSessionId = window.localStorage.getItem('sessionId')
        if (existingSessionId) {
          return GlobalConfig.rootFirebaseRef
            .child('userInfos')
            .child(GlobalConfig.uid)
            .child('activeSessionId')
            .once('value')
            .then(activeSessionIdSnapshot => {
              const activeSessionId = activeSessionIdSnapshot.val()
              if (existingSessionId === activeSessionId) {
                return Promise.resolve(true)
              } else {
                return Promise.resolve(false)
              }
            })
        } else {
          return Promise.resolve(true)
        }
      })
      .then(webSessionAuthenticated => {
        if (webSessionAuthenticated) {
          setAuthenticating(false)
          dispatch(ActionCreators.setAuthenticatedWithFirebase(true))
          dispatch(ActionCreators.loadAppData())
          dispatch(ActionCreators.syncAlarmsStatus(true))
          dispatch(ActionCreators.updateLastActiveTime())
          // Once user is authenticated, add a session in firebase
          SessionManager.addSession(GlobalConfig.uid, sessionId)

          // User on web will always be authenticated using this API and not using any of the NetworkUtils API
          Sentry.setUser({
            id: GlobalConfig.uid,
            username
          })
        } else {
          SessionManager.logOutWebSession()
        }
      })
      .catch(error => {
        LogUtils.logError(
          `unable to authenticate user: ${JSON.stringify({
            uid: GlobalConfig.uid,
            phoneId: GlobalConfig.phoneId || null,
            error: error
          })}`
        )
        setAuthenticating(false)
        setShowQrCode(true)
        SessionManager.logOutWebSession()
      })
  }

  const onAuthInfo = authInfo => {
    const tokens = authInfo.split(':')
    const uid = tokens[0]
    const phoneId = tokens[1]
    const sessionId = tokens[2]

    if (!uid || !sessionId) {
      alert(I18n.t('unableToScanCodeMessage'))
      return
    }

    window.localStorage.setItem('uid', uid)
    window.localStorage.setItem('phoneId', phoneId)
    GlobalConfig.uid = uid
    GlobalConfig.phoneId = phoneId
    setShowQrCode(false)
    setAuthenticating(true)
    authenticateUser(sessionId)
  }

  const onSessionResumed = () => {
    const uid = window.localStorage.getItem('uid')
    const sessionId = window.localStorage.getItem('sessionId')

    // If we have a user already authenticated and have a session id, then add a session
    if (uid && sessionId) {
      SessionManager.addSession(uid, sessionId)
    } else {
      // In case, user has cleared cache on the browser
      setShowQrCode(true)
    }
  }

  const onSessionEnded = () => {
    SessionManager.closeSession()
    window.open('https://galarmapp.com', '_self')
  }

  useEffect(() => {
    const unsubscribeFromAuthChanges = firebase
      .auth()
      .onAuthStateChanged(async user => {
        if (!user) {
          dispatch(ActionCreators.setAuthenticatedWithFirebase(false))
          setShowQrCode(true)
        }
      })

    // authInfo event is emitted by the LoginScreen once user successfully scans
    // a barcode, upload the auth info to firebase and it is read by the web app
    GlobalConfig.eventEmitter.on('authInfo', onAuthInfo)

    // sessionResumed event is thrown when user chooses to restart the session
    // on a window where it was stopped because a session was started on another window
    GlobalConfig.eventEmitter.on('sessionResumed', onSessionResumed)

    // Session ended event is when the user decides to end the session when multiple windows are open
    GlobalConfig.eventEmitter.on('sessionEnded', onSessionEnded)

    const uid = window.localStorage.getItem('uid')
    const phoneId = window.localStorage.getItem('phoneId')
    const sessionId = window.localStorage.getItem('sessionId')

    console.log('uid', uid, 'phoneId', phoneId, 'sessionId', sessionId)

    // if auth info is present on the browser, make sure that the session
    // has not be logged out from the mobile and then authenticate the client
    if (sessionId && uid) {
      GlobalConfig.uid = uid
      GlobalConfig.phoneId = phoneId
      authenticateUser(sessionId)
      setLoadedAuthInfo(true)
      setAuthenticating(true)
    } else {
      setLoadedAuthInfo(true)
      setShowQrCode(true)
      SessionManager.closeSession()
    }

    fetch('https://api64.ipify.org?format=json')
      .then(ipResponse => ipResponse.json())
      .then(ipResponseJson => {
        const { ip } = ipResponseJson
        if (ip) {
          console.log('Device ip', ip)
          GlobalConfig.ip = ip
        }
      })
      .catch(error => console.log('Unable to fetch public ip', error))

    document.addEventListener('click', emitCloseRightClickMenuEvent)
    document.addEventListener('contextmenu', emitCloseRightClickMenuEvent)

    return () => {
      unsubscribeFromAuthChanges()
      dispatch(ActionCreators.unloadAppData())
      GlobalConfig.eventEmitter.off('authInfo', onAuthInfo)
      GlobalConfig.eventEmitter.off('sessionResumed', onSessionResumed)
      GlobalConfig.eventEmitter.off('sessionEnded', onSessionEnded)
      document.removeEventListener('click', emitCloseRightClickMenuEvent)
      document.removeEventListener('contextmenu', emitCloseRightClickMenuEvent)
    }
  }, [])

  const isMobileDevice = Utils.isMobileDevice()
  if (isMobileDevice) {
    return (
      <Grommet full theme={theme}>
        <DownloadAppOnMobileScreen />
      </Grommet>
    )
  }

  if (!loadedAuthInfo) {
    return (
      <Grommet full theme={theme}>
        <Box fill justify="center" align="center">
          <Text>{I18n.t('initializing')}</Text>
        </Box>
      </Grommet>
    )
  }

  if (showQrCode) {
    return (
      <Grommet full theme={theme}>
        <LoginScreen />
      </Grommet>
    )
  }

  if (authenticating) {
    return (
      <Grommet full theme={theme}>
        <AuthenticatingWithServer />
      </Grommet>
    )
  }

  return (
    <Grommet full theme={theme}>
      <MainApp />
    </Grommet>
  )
}

export default App
