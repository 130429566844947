import React from 'react'
import { Box } from 'grommet'

import Avatar from './Avatar'
import Bubble from './Bubble'
import Day from './Day'

import { isSameUser, isSameDay } from './utils'

const Message = props => {
  const getInnerComponentProps = () => {
    return {
      ...props,
      isSameUser,
      isSameDay
    }
  }
  const renderDay = () => {
    if (props.currentMessage.createdAt) {
      const dayProps = getInnerComponentProps()
      if (props.renderDay) {
        return props.renderDay(dayProps)
      }
      return <Day {...dayProps} />
    }
    return null
  }

  const renderBubble = () => {
    const bubbleProps = getInnerComponentProps()
    if (props.renderBubble) {
      return props.renderBubble(bubbleProps)
    }

    return <Bubble {...bubbleProps} />
  }

  const renderAvatar = () => {
    if (props.user._id !== props.currentMessage.user._id) {
      const avatarProps = getInnerComponentProps()
      return <Avatar {...avatarProps} />
    }
    return null
  }

  return (
    <Box>
      {renderDay()}
      <Box
        style={{
          marginBottom: 2
        }}>
        {props.position === 'left' ? renderAvatar() : null}
        {renderBubble()}
        {props.position === 'right' ? renderAvatar() : null}
      </Box>
    </Box>
  )
}

export default Message

Message.defaultProps = {
  renderAvatar: null,
  renderBubble: null,
  renderDay: null,
  position: 'left',
  currentMessage: {},
  nextMessage: {},
  previousMessage: {},
  user: {},
  containerStyle: {}
}
