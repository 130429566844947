import React from 'react'
import { Box, Text } from 'grommet'

const DaySummary = ({ item, isSelected = false, onSelectDay }) => {
  const backgroundColor = isSelected ? 'primary' : 'lightTint'
  const fontWeight = isSelected ? 'bold' : 'normal'
  const textColor = isSelected ? 'white' : 'textColor'

  return (
    <Box
      round="20px"
      width={'40px'}
      height={'40px'}
      onClick={onSelectDay}
      background={backgroundColor}
      justify="center"
      align="center">
      <Text color={textColor} weight={fontWeight}>
        {item.label}
      </Text>
    </Box>
  )
}

const DaysOfWeekPicker = ({ selectedDays, onSelectDay }) => {
  const repetitionItems = [
    {
      label: 'S',
      value: 'every_sun'
    },
    {
      label: 'M',
      value: 'every_mon'
    },
    {
      label: 'T',
      value: 'every_tue'
    },
    {
      label: 'W',
      value: 'every_wed'
    },
    {
      label: 'T',
      value: 'every_thu'
    },
    {
      label: 'F',
      value: 'every_fri'
    },
    {
      label: 'S',
      value: 'every_sat'
    }
  ]

  return (
    <Box direction="row" justify="between" pad="small" gap="small">
      {repetitionItems.map(item => (
        <DaySummary
          key={item.value}
          item={item}
          onSelectDay={() => onSelectDay(item)}
          isSelected={selectedDays.includes(item.value)}
        />
      ))}
    </Box>
  )
}

export default DaysOfWeekPicker
