import {
  ActionCreators,
  AlarmUtils,
  DateTimeUtils,
  Selectors
} from 'galarm-shared'
import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Header,
  Text,
  Menu,
  Image
} from 'grommet'
import { Close, MoreVertical } from 'grommet-icons'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import LabelledText from './LabelledText'
import isEmpty from 'lodash/isEmpty'
import { Constants, GlobalConfig, I18n } from 'galarm-config'
import LabelText from './LabelText'
import ParticipantsListWithAvatar from './ParticipantsListWithAvatar'
import {
  FirebaseProxy,
  NavigationUtils,
  NotificationManager
} from 'galarm-ps-api'
import AppCanvas from './AppCanvas'
import AlarmType from './AlarmType'
import CategoryLabel from './CategoryLabel'
import ChatIcon from './ChatIcon'
import objGet from 'lodash/get'
import ColoredButton from './ColoredButton'
import objOmit from 'lodash/omit'
import EntityNotFound from './EntityNotFound'
import RichTextEditor from './RichTextEditor'

const {
  makeAlarmCategoryForAlarmIdSelector,
  makeParticipantAlarmDetailsSelector
} = Selectors

const InstantParticipantAlarmDetailsHeader = ({ alarm }) => {
  const dispatch = useDispatch()

  const closeInstantParticipantAlarmDetailsScreen = () => {
    dispatch(ActionCreators.hideInstantParticipantAlarmDetailsScreen())
  }

  const onReportProblem = () => {
    GlobalConfig.showFeedbackScreen({
      type: Constants.TICKET_TYPES.PROBLEM_REPORT,
      extras: JSON.stringify({
        alarm: objOmit(alarm, [
          'name',
          'notes',
          'backupGroup',
          'backupContacts'
        ])
      })
    })
  }

  return (
    <Header className="header" background="brand" pad="small">
      <Text color="white">{I18n.t('alarmDetails')}</Text>
      <Box direction="row">
        <Button
          icon={<Close />}
          onClick={closeInstantParticipantAlarmDetailsScreen}
        />
        <Menu
          dropBackground="lightTint"
          icon={<MoreVertical />}
          items={[
            {
              label: I18n.t('reportAProblem'),
              onClick: onReportProblem
            }
          ]}
        />
      </Box>
    </Header>
  )
}

const InstantParticipantAlarmDetails = ({ alarmId }) => {
  console.log('alarmId', alarmId)

  const dispatch = useDispatch()
  const colorScheme = useSelector(state => state.userSettings.webColorScheme)

  const participantAlarmDetaisSelector = makeParticipantAlarmDetailsSelector()
  const alarm = useSelector(state =>
    participantAlarmDetaisSelector(state, { alarmId: alarmId })
  )

  const alarmCategorySelector = makeAlarmCategoryForAlarmIdSelector()
  const alarmCategory = useSelector(state =>
    alarmCategorySelector(state, { alarmId: alarmId })
  )

  const alarmCategoryColor = AlarmUtils.getColorForAlarmCategory(
    colorScheme,
    alarmCategory.color
  )

  const alarmConversations = useSelector(state => state.conversations.alarms)

  const unseenMessagesForAlarm = objGet(
    alarmConversations,
    `[${alarmId}].unseenMessages`,
    0
  )

  const currAlarmDate = AlarmUtils.getCurrentDateForAlarm(alarm)
  const participantAlarmEffectiveDate = DateTimeUtils.getParticipantAlarmDate(
    {
      date: currAlarmDate,
      type: alarm.type,
      cascadingAlarmInterval: alarm.cascadingAlarmInterval,
      recipientAlarmInterval: alarm.recipientAlarmInterval
    },
    alarm.order || 0
  )

  const alarmAcknowledgement =
    AlarmUtils.getAlarmAcknowledgementStatusForOccurrence(
      alarm.id,
      currAlarmDate
    )

  const creator = {
    id: alarm.creator,
    name: alarm.creatorName,
    mobileNumber: alarm.creatorMobileNumber,
    responseStatus: Constants.ACCEPT_ALARM,
    isCreator: true
  }

  const onGroupAlarmYes = () => {
    if (
      alarmAcknowledgement &&
      alarmAcknowledgement.response === Constants.GROUP_ALARM_YES
    ) {
      NavigationUtils.showAlert(I18n.t('alarmAlreadyConfirmed'))
      return
    }

    const alarmExpired = AlarmUtils.hasParticipantAlarmExpired(alarmId)
    if (!alarmExpired) {
      NavigationUtils.showAlert(
        alarm.name,
        I18n.t('stillRingGroupAlarmAfterConfirmingInAdvance'),
        [
          {
            text: I18n.t('yes'),
            onPress: () => {
              onGroupAlarmYesCore(false)
              FirebaseProxy.logEvent(
                Constants.UserAnalyticsEvents.CONFIRM_GROUP_ALARM_IN_ADVANCE,
                {
                  [Constants.UserAnalyticsEventParameters
                    .RING_GROUP_ALARM_AFTER_CONFIRMING_ADVANCE]: 'true'
                }
              )
            }
          },
          {
            text: I18n.t('no'),
            onPress: () => {
              onGroupAlarmYesCore(true)
              FirebaseProxy.logEvent(
                Constants.UserAnalyticsEvents.CONFIRM_GROUP_ALARM_IN_ADVANCE,
                {
                  [Constants.UserAnalyticsEventParameters
                    .RING_GROUP_ALARM_AFTER_CONFIRMING_ADVANCE]: 'false'
                }
              )
            }
          }
        ]
      )
      return
    }

    onGroupAlarmYesCore(true)
  }

  const onGroupAlarmYesCore = (rescheduleAlarm = true) => {
    dispatch(
      ActionCreators.markParticipantResponseForSimultaneousAlarm(
        alarmId,
        Constants.GROUP_ALARM_YES,
        rescheduleAlarm
      )
    )
    const notificationInfo = {
      type: Constants.NotificationTypes.ALARM_ACKNOWLEDGED,
      alarmId: alarm.id,
      alarmType: alarm.type,
      alarmName: alarm.name
    }
    NotificationManager.sendRemoteNotification(
      GlobalConfig.uid,
      Constants.NotificationKeys.AlarmConfirmedFromWebNotification,
      notificationInfo
    )
  }

  const onGroupAlarmNo = () => {
    if (
      alarmAcknowledgement &&
      alarmAcknowledgement.response === Constants.GROUP_ALARM_NO
    ) {
      NavigationUtils.showAlert(I18n.t('alarmAlreadyDeclined'))
      return
    }

    onGroupAlarmNoCore()
  }

  const onGroupAlarmNoCore = () => {
    dispatch(
      ActionCreators.markParticipantResponseForSimultaneousAlarm(
        alarmId,
        Constants.GROUP_ALARM_NO,
        true
      )
    )
    const notificationInfo = {
      type: Constants.NotificationTypes.ALARM_ACKNOWLEDGED,
      alarmId: alarm.id,
      alarmType: alarm.type,
      alarmName: alarm.name
    }
    NotificationManager.sendRemoteNotification(
      GlobalConfig.uid,
      Constants.NotificationKeys.AlarmDeclinedFromWebNotification,
      notificationInfo
    )
  }

  const onViewConversation = () => {
    dispatch(ActionCreators.showAlarmConversationScreen(alarm.id))
  }

  const viewConversation = (
    <Box align="center" pad="small" onClick={onViewConversation}>
      <ChatIcon unseenMessages={unseenMessagesForAlarm} />
      <LabelText color="primary">{I18n.t('chat')}</LabelText>
    </Box>
  )

  if (isEmpty(alarm)) {
    return (
      <EntityNotFound
        entityType={I18n.t('entityAlarm')}
        onClose={() =>
          dispatch(ActionCreators.hideInstantParticipantAlarmDetailsScreen())
        }
      />
    )
  }

  return (
    <AppCanvas key={alarmId} overflow="auto">
      <InstantParticipantAlarmDetailsHeader />
      <Card
        margin="small"
        background="textBackgroundColor"
        width="600px"
        flex={{ shrink: 0 }}>
        <CardHeader pad="small" justify="between">
          <Box flex />
          <AlarmType type={alarm.type} creationMode={alarm.creationMode} />
          <Box flex align="end">
            {alarmCategory.id !== Constants.UNCATEGORIZED_ALARM_CATEGORY_ID && (
              <CategoryLabel
                name={alarmCategory.name}
                color={alarmCategoryColor}
              />
            )}
          </Box>
        </CardHeader>
        <CardBody gap="medium" pad="medium">
          <Box direction="row" gap="medium">
            <LabelledText
              label={I18n.t('date')}
              text={DateTimeUtils.getDateAsString(
                participantAlarmEffectiveDate
              )}
              border="bottom"
            />
            <LabelledText
              label={I18n.t('time')}
              text={DateTimeUtils.getTimeAsString(
                participantAlarmEffectiveDate
              )}
              border="bottom"
            />
          </Box>
          <LabelledText
            label={I18n.t('title')}
            text={alarm.name}
            border="bottom"
            flex={{ shrink: 0 }}
          />
          <Box border="bottom" direction="row" justify="between">
            <Box>
              <LabelText>{I18n.t('participants')}</LabelText>
              <ParticipantsListWithAvatar
                type={alarm.type}
                creator={creator}
                backupContacts={
                  alarm.backupContacts.length > 0
                    ? [creator, ...alarm.backupContacts]
                    : []
                }
                backupGroup={
                  alarm.backupGroup
                    ? {
                        ...alarm.backupGroup,
                        members: [creator, ...alarm.backupGroup.members]
                      }
                    : null
                }
                recipient={alarm.recipient}
                alarmId={alarm.id}
              />
            </Box>
            {viewConversation}
          </Box>
          {(!!alarm.notes || !!alarm.alarmPhotoUrl) && (
            <Box gap="medium">
              <Box>
                <LabelText color="darkTint">{I18n.t('notes')}</LabelText>
                {alarm.alarmPhotoUrl && (
                  <Box
                    margin={{
                      vertical: 'small',
                      left: 'large'
                    }}
                    width="80%">
                    <Image src={alarm.alarmPhotoUrl} />
                  </Box>
                )}
                {!!alarm.notes && (
                  <Box direction="row" border="bottom">
                    <Text style={{ wordBreak: 'break-all' }}>
                      <RichTextEditor
                        notes={alarm.notes}
                        readOnly="true"
                        toolbarHidden="true"
                      />
                    </Text>
                  </Box>
                )}
              </Box>
            </Box>
          )}
        </CardBody>
        <CardFooter pad="small" background="brand">
          <Box flex direction="row" justify="around">
            <ColoredButton
              color="white"
              label={I18n.t(Constants.GROUP_ALARM_YES)}
              onClick={onGroupAlarmYes}
            />
            {alarm.source !==
              Constants.AlarmCreationSources
                .CREATED_BY_ENTERPRISE_ALERT_API && (
              <ColoredButton
                color="white"
                label={I18n.t(Constants.GROUP_ALARM_NO)}
                onClick={onGroupAlarmNo}
              />
            )}
          </Box>
        </CardFooter>
      </Card>
    </AppCanvas>
  )
}

export default InstantParticipantAlarmDetails
