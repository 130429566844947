import React from 'react'
import { Box } from 'grommet'

const HoverBox = props => {
  const noop = () => {}
  const {
    children,
    background = 'transparent',
    onClick = noop,
    justify = 'between',
    align = 'center',
    ...containerProps
  } = props
  const pad = props.pad || 'small'
  return (
    <Box
      flex={{ shrink: 0 }}
      background={background}
      hoverIndicator="background"
      focusIndicator={false}
      onClick={onClick}>
      <Box
        direction="row"
        gap="medium"
        pad={pad}
        justify={justify}
        align={align}
        {...containerProps}>
        {children}
      </Box>
    </Box>
  )
}

export default HoverBox
