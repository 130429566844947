import React from 'react'
import { Text, Box } from 'grommet'

const Send = props => {
  if (props.text.trim().length > 0) {
    return (
      <Box
        onClick={() => {
          props.onSend({ text: props.text.trim() }, true)
        }}>
        <Text style={styles.text}>{props.label}</Text>
      </Box>
    )
  }
  return <Box />
}

export default Send

const styles = {
  container: {
    height: 44,
    justifyContent: 'flex-end',
    backgroundColor: 'white'
  },
  text: {
    color: '#0084ff',
    fontWeight: '600',
    fontSize: 17,
    backgroundColor: 'transparent',
    marginBottom: 12,
    marginLeft: 10,
    marginRight: 10
  }
}

Send.defaultProps = {
  text: '',
  onSend: () => {},
  label: 'Send',
  containerStyle: {},
  textStyle: {}
}
