import React, { useState, useEffect } from 'react'
import { Box, Button, Text, CheckBox } from 'grommet'
import { FormUp, FormDown } from 'grommet-icons'
import { useSelector } from 'react-redux'
import { I18n } from 'galarm-config'
import { Constants } from 'galarm-config'
import { colorThemes } from 'galarm-config'
import { NavigationUtils } from 'galarm-ps-api'
import { GroupUtils, Utils } from 'galarm-shared'
import SectionList from './SectionList'
import Separator from './Separator'
import groupBy from 'lodash/groupBy'
import objReduce from 'lodash/reduce'
import orderBy from 'lodash/orderBy'

const ChangeMemberConfig = ({
  name,
  allowUp,
  allowDown,
  onMoveUp,
  onMoveDown,
  onMakeActive,
  onMakeInactive,
  state,
  alarmType
}) => {
  const colorScheme = useSelector(state => state.userSettings.webColorScheme)

  return (
    <Box justify="between" align="center" direction="row">
      <Box direction="row" align="center">
        {state !== Constants.ParticipantStates.INACTIVE &&
        alarmType === Constants.AlarmTypes.CASCADING ? (
          <Box direction="row" align="center">
            <Button
              margin="none"
              icon={
                <FormUp
                  size="medium"
                  color={
                    allowUp
                      ? colorThemes.getColorTheme(colorScheme).primary
                      : colorThemes.getColorTheme(colorScheme).darkTint
                  }
                />
              }
              onClick={onMoveUp}
            />
            <Button
              icon={
                <FormDown
                  size="medium"
                  color={
                    allowDown
                      ? colorThemes.getColorTheme(colorScheme).primary
                      : colorThemes.getColorTheme(colorScheme).darkTint
                  }
                />
              }
              onClick={onMoveDown}
            />
          </Box>
        ) : null}
        <Text margin="xsmall" color="textColor">
          {name}
        </Text>
      </Box>
      {state !== Constants.ParticipantStates.INACTIVE ? (
        <CheckBox
          checked={true}
          toggle
          pad={{ horizontal: 'small' }}
          onClick={onMakeInactive}
        />
      ) : (
        <CheckBox
          checked={false}
          toggle
          pad={{ horizontal: 'small' }}
          onClick={onMakeActive}
        />
      )}
    </Box>
  )
}

const SectionHeader = props => {
  const { title } = props
  return (
    <Box flex height="20px" background="lightPrimary" align="center">
      <Text>{title}</Text>
    </Box>
  )
}

const ChangeMemberConfigContainer = ({ item, extras }) => {
  const onMoveUp = () => {
    if (item.order > 1) {
      extras.onChangeOrder(item.id, true)
      NavigationUtils.showTransientAlert({
        message: I18n.t('participantMovedToPosition', {
          count: item.order - 1,
          name: item.name
        })
      })
    } else {
      NavigationUtils.showAlert(
        I18n.t('cantMoveMemberUp', { name: item.name }),
        I18n.t('memberIsFirstParticpant', { name: item.name })
      )
    }
  }

  const onMoveDown = () => {
    if (item.order < extras.numActiveMembers) {
      extras.onChangeOrder(item.id, false)
      NavigationUtils.showTransientAlert({
        message: I18n.t('participantMovedToPosition', {
          count: item.order + 1,
          name: item.name
        })
      })
    } else {
      NavigationUtils.showAlert(
        I18n.t('cantMoveMemberDown', { name: item.name }),
        I18n.t('memberIsLastParticpant', { name: item.name })
      )
    }
  }

  const onMakeActive = () => {
    extras.onChangeState(item.id, true)
  }

  const onMakeInactive = () => {
    extras.onChangeState(item.id, false)
  }

  const memberConfigProps = {
    name: item.name,
    allowUp: item.order > 1,
    allowDown: item.order < extras.numActiveMembers,
    onMoveUp: onMoveUp,
    onMoveDown: onMoveDown,
    onMakeActive: onMakeActive,
    onMakeInactive: onMakeInactive,
    state: item.state,
    alarmType: extras.alarmType
  }

  return <ChangeMemberConfig {...memberConfigProps} />
}

const ChangeGroupMembersConfig = props => {
  const [members, setMembers] = useState(props.backupGroup.members)

  useEffect(() => {
    updateMembersConfig()
  }, [members])

  const updateMembersConfig = () => {
    let membersConfigChanged = false
    members.forEach(member => {
      const propMember = Utils.getObjectWithId(
        props.backupGroup.members,
        member.id
      )
      if (
        propMember.order !== member.order ||
        propMember.state !== member.state
      ) {
        membersConfigChanged = true
      }
    })

    if (membersConfigChanged) {
      props.updateMembersConfig(members)
    }
  }

  const onChangeUseAsDefault = () => {
    GroupUtils.updateMembersConfig(props.backupGroup.id, members)
    NavigationUtils.showTransientAlert({
      message: I18n.t('defaultConfigurationUpdated')
    })
  }

  const onChangeState = (memberId, makeActive) => {
    let changedMember = Utils.getObjectWithId(members, memberId)

    if (
      changedMember === undefined ||
      (makeActive &&
        changedMember.state !== Constants.ParticipantStates.INACTIVE) ||
      (!makeActive &&
        changedMember.state === Constants.ParticipantStates.INACTIVE)
    ) {
      console.log('Member undefined or illegal change state operation')
      return
    }

    const numActiveMembers = members.filter(member => {
      return member.state !== Constants.ParticipantStates.INACTIVE
    }).length
    const numMembers = members.length
    const memberOrder = changedMember.order
    let newMembers = null
    // If the member is made active, then change the state and
    // make the member order as the next active backup. Also
    // push down each inactive member before this member by one
    if (makeActive) {
      newMembers = members.map(member => {
        if (member.id === changedMember.id) {
          return {
            ...member,
            order: numActiveMembers + 1,
            state: Constants.ParticipantStates.ACTIVE
          }
        } else if (
          member.order > numActiveMembers &&
          member.order < memberOrder
        ) {
          return {
            ...member,
            order: member.order + 1
          }
        }
        return member
      })
    }
    // If the member is made inactive, then change the state and
    // make the member order as the last inactive member. Also,
    // bump up the order of each member with higher order by one
    else {
      newMembers = members.map(member => {
        if (member.id === changedMember.id) {
          return {
            ...member,
            order: numMembers,
            state: Constants.ParticipantStates.INACTIVE
          }
        } else if (member.order > memberOrder) {
          return {
            ...member,
            order: member.order - 1
          }
        }
        return member
      })
    }
    if (
      newMembers.filter(
        member => member.state !== Constants.ParticipantStates.INACTIVE
      ).length < 1
    ) {
      NavigationUtils.showAlert('', I18n.t('atleastOneMemberShouldBeActive'))
      return
    }
    setMembers(newMembers)
  }

  const onChangeOrder = (memberId, moveUp) => {
    let changedMember = Utils.getObjectWithId(members, memberId)

    if (
      changedMember === undefined ||
      (moveUp && changedMember.order === 1) ||
      (!moveUp && changedMember.order === members.length)
    ) {
      console.log('Member undefined or illegal change order operation')
      return
    }

    let newMembers = null
    // If the member is moved up, find the member with one order less and exchange order
    // Else, find the member with one order more and exchange
    if (moveUp) {
      newMembers = members.map(member => {
        if (member.id === changedMember.id) {
          return {
            ...member,
            order: member.order - 1
          }
        } else if (member.order === changedMember.order - 1) {
          return {
            ...member,
            order: member.order + 1
          }
        }
        return member
      })
    } else {
      newMembers = members.map(member => {
        if (member.id === changedMember.id) {
          return {
            ...member,
            order: member.order + 1
          }
        } else if (member.order === changedMember.order + 1) {
          return {
            ...member,
            order: member.order - 1
          }
        }
        return member
      })
    }

    setMembers(newMembers)
  }

  const orderedMembers = members.slice().sort((member1, member2) => {
    return member1.order - member2.order
  })

  const membersSections = objReduce(
    groupBy(orderedMembers, member => member.state),
    (acc, next, index) => {
      acc.push({
        title: I18n.t(index),
        data: next
      })
      return acc
    },
    []
  )

  const orderedMemberSections = orderBy(membersSections, ['title'], ['asc'])

  const numActiveMembers = orderedMembers.filter(member => {
    return member.state !== Constants.ParticipantStates.INACTIVE
  }).length

  const extras = {
    onChangeState: onChangeState,
    onChangeOrder: onChangeOrder,
    numActiveMembers: numActiveMembers,
    alarmType: props.alarmType
  }
  return (
    <Box width={'500px'}>
      <Box pad="small">
        <Text color="darkTint">
          {props.alarmType === Constants.AlarmTypes.CASCADING
            ? I18n.t('changeGroupMemberConfigTextForCascadingAlarm')
            : I18n.t('changeGroupMemberConfigTextForSimultaneousAlarm')}
        </Text>
      </Box>
      <SectionList
        border="bottom"
        sections={orderedMemberSections}
        renderItem={ChangeMemberConfigContainer}
        renderSectionHeader={SectionHeader}
        extras={{
          ...extras
        }}
      />
      <Separator />
      <Button
        margin="medium"
        alignSelf="center"
        onClick={onChangeUseAsDefault}
        label={I18n.t('useAsDefaultGroupConfiguration')}
      />
    </Box>
  )
}

export default ChangeGroupMembersConfig
