import arrayCompact from 'lodash/compact'
import Utils from './Utils'

const StringUtils = (function () {
  function getHashCode(str) {
    let hash = 0,
      i,
      chr,
      len
    if (str.length === 0) return hash
    for (i = 0, len = str.length; i < len; i++) {
      chr = str.charCodeAt(i)
      hash = (hash << 5) - hash + chr
      hash |= 0 // Convert to 32bit integer
    }
    return hash
  }

  function getFirstName(name) {
    return name ? name.trim().split(' ')[0] : name
  }

  function createDisplayName(firstName, lastName) {
    return [firstName, lastName].join(' ')
  }

  function getNameInitials(fullName) {
    const unicodeStringPattern = /[^\u0000-\u00ff]/
    if (unicodeStringPattern.test(fullName)) {
      return fullName
    }

    const name = Utils.sanitizeName(fullName)
    let initials = ''
    if (name.indexOf(' ') > 0) {
      const nameParts = arrayCompact(name.split(' '))
      if (nameParts.length > 1) {
        initials = nameParts[0].charAt(0) + nameParts[1].charAt(0)
      } else {
        initials = name.charAt(0)
      }
    } else {
      initials = name.charAt(0)
    }

    return initials.toUpperCase()
  }

  function getCharCode(str) {
    if (!str) {
      return 0
    }
    return str
      .split('')
      .reduce((acc, currValue, currIndex) => acc + str.charCodeAt(currIndex), 0)
  }

  function isStringAnEmailId(email) {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(email.trim())
  }

  function telify(string) {
    //const telMatchString = /(?!\/?a(?=>|\s.*>))([+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,7})/g
    const telMatchString =
      /([+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,7})(?![^<]*?<\/a>)/gi
    string = string.replace(telMatchString, function (match) {
      return `<a href="tel:${match}")>${match}</a>`
    })
    return string
  }

  return {
    getHashCode,
    getFirstName,
    getNameInitials,
    getCharCode,
    isStringAnEmailId,
    telify,
    createDisplayName
  }
})()

export default StringUtils
