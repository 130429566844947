import React from 'react'
import { Box, Text, Avatar } from 'grommet'
import { StringUtils } from 'galarm-shared'
import { colors } from 'galarm-config'

const AvatarWithInitials = props => {
  const initials = StringUtils.getNameInitials(props.name)

  return (
    <Avatar size={props.size} background="mediumTint">
      <Box justify="center" align="center" background="mediumTint">
        <Text color={colors.white} size="xsmall" weight="bold">
          {initials}
        </Text>
      </Box>
    </Avatar>
  )
}

export default AvatarWithInitials
