import React, { useEffect, useState } from 'react'
import {
  AlarmUtils,
  DateTimeUtils,
  ActionCreators,
  AlarmUtilsWithExtras,
  Utils
} from 'galarm-shared'
import { GlobalConfig, I18n } from 'galarm-config'
import { useDispatch, useSelector } from 'react-redux'
import { NavigationUtils } from 'galarm-ps-api'
import { Constants } from 'galarm-config'
import lowerFirst from 'lodash/lowerFirst'
import { FirebaseProxy } from 'galarm-ps-api'
import { Box, Button, Card, CardBody, List, Text, Header, Menu } from 'grommet'
import Spinner from './Spinner'
import { Close, MoreVertical, Trash } from 'grommet-icons'
import TintedImage from './TintedImage'
import objGet from 'lodash/get'
import AppCanvas from './AppCanvas'
import { SecondaryText } from 'web-components'

const AlarmPreviousOccurrencesHeader = ({
  alarm,
  title,
  repetitionString,
  previousOccurrencesString,
  onClose,
  onDeleteAlarmHistory
}) => {
  return (
    <Header className="header" background="brand" pad="small">
      <Box>
        <Text color="white">{title}</Text>
        <Text size="small" color="white">
          {repetitionString}
        </Text>
        <Text size="small" color="white">
          {previousOccurrencesString}
        </Text>
      </Box>
      <Box direction="row">
        {alarm.creator === GlobalConfig.uid && (
          <Button icon={<Trash />} onClick={onDeleteAlarmHistory} />
        )}
        <Button icon={<Close />} onClick={onClose} />
      </Box>
    </Header>
  )
}

const AlarmPreviousOccurrenceSummary = item => {
  const getOccurrenceStatusSummaryString = () => {
    return AlarmUtils.getOccurrenceStatusSummaryString(item)
  }

  const dispatch = useDispatch()

  const getOccurrenceStatusIcon = () => {
    const AlarmOccurrenceStatusesIcon = {
      Done: require('galarm-res/img/web/ic_check_circle.svg').default,
      Undone: require('galarm-res/img/web/ic_question_circle.svg').default,
      Confirm: require('galarm-res/img/web/ic_check_circle.svg').default,
      Decline: require('galarm-res/img/web/ic_cross_circle.svg').default,
      Skip: require('galarm-res/img/web/ic_cross_circle.svg').default,
      true: require('galarm-res/img/web/ic_question_circle.svg').default,
      false: require('galarm-res/img/web/ic_question_circle.svg').default
    }

    const AlarmOccurrenceStatusTintColor = {
      Done: 'green',
      Undone: 'orange',
      Confirm: 'green',
      Decline: 'red',
      Skip: 'red',
      true: 'orange',
      false: 'mediumTint'
    }

    const occurrenceStatus = item.occurrenceStatus
    let iconSource, tintColor
    if (typeof occurrenceStatus === 'object') {
      const response = occurrenceStatus.response
      iconSource = AlarmOccurrenceStatusesIcon[response]
      tintColor = AlarmOccurrenceStatusTintColor[response]
    } else if (typeof occurrenceStatus === 'boolean') {
      iconSource = AlarmOccurrenceStatusesIcon[occurrenceStatus.toString()]
      tintColor = AlarmOccurrenceStatusTintColor[occurrenceStatus.toString()]
    } else {
      iconSource = AlarmOccurrenceStatusesIcon.true
      tintColor = AlarmOccurrenceStatusTintColor.true
    }

    return { iconSource, tintColor }
  }

  const quickActionsForOccurrence =
    item.alarm.creator === GlobalConfig.uid
      ? AlarmUtilsWithExtras.getQuickActionsForAlarmOccurrence(
          item.alarm,
          item,
          dispatch
        )
      : AlarmUtilsWithExtras.getQuickActionsForParticipantAlarmOccurrence(
          item.alarm,
          item,
          dispatch
        )

  return (
    <Card
      background="textBackgroundColor"
      width="500px"
      pad="none"
      margin="none">
      <CardBody direction="row" justify="between" align="center">
        <Box direction="row">
          <Box pad="small">
            <Text>{item.occurrenceDateString}</Text>
            <SecondaryText>{getOccurrenceStatusSummaryString()}</SecondaryText>
          </Box>
        </Box>
        <Box direction="row" gap="small">
          <TintedImage
            tintColor={getOccurrenceStatusIcon().tintColor}
            src={getOccurrenceStatusIcon().iconSource}
          />
          {quickActionsForOccurrence.length > 0 ? (
            <Menu
              dropBackground="lightTint"
              icon={<MoreVertical />}
              items={quickActionsForOccurrence.map(item => ({
                label: item.text,
                onClick: item.onPress
              }))}
              disabled={quickActionsForOccurrence.length === 0}
            />
          ) : (
            <Button
              icon={<MoreVertical />}
              onClick={() =>
                NavigationUtils.showTransientAlert({
                  message: I18n.t('noActionAvailableForAlarmOccurrence')
                })
              }
            />
          )}
        </Box>
      </CardBody>
    </Card>
  )
}

const AlarmPreviousOccurrences = props => {
  const alarm = props.alarm

  const currAlarmDate = AlarmUtils.getCurrentDateForAlarm(alarm)

  const [loaded, setLoaded] = useState(false)
  const [loadedTill, setLoadedTill] = useState(currAlarmDate)
  const [occurrences, setOccurrences] = useState([])
  const [moreOccurrencesExists, setMoreOccurrencesExists] = useState(false)
  const [previousOccurrencesString, setPreviousOccurrencesString] = useState(
    I18n.t('computingAlarmHistory')
  )

  // Only added such that we can update the screen when user changes a response
  // from this screen
  // eslint-disable-next-line no-unused-vars
  const alarmAcknowledgements = useSelector(state =>
    objGet(state, `alarmActions.alarmAcknowledgements[${alarm.id}]`)
  )

  const dispatch = useDispatch()

  const closeAlarmPreviousOccurrences = () => {
    dispatch(ActionCreators.hideAlarmHistoryScreen())
  }

  let computePreviousOccurrencesStringPromise = null
  const computePreviousOccurrencesString = () => {
    computePreviousOccurrencesStringPromise = Utils.makeCancelablePromise(
      AlarmUtils.getPreviousOccurrencesString(alarm.id)
    )
    computePreviousOccurrencesStringPromise.promise
      .then(computedPreviousOccurrencesString => {
        setPreviousOccurrencesString(computedPreviousOccurrencesString)
      })
      .catch(() => {}) // No op rejection handling to avoid the error
  }

  useEffect(() => {
    computePreviousOccurrencesString()
    loadMoreOccurrences(() => {
      setLoaded(true)
    })
    return () => {
      computePreviousOccurrencesStringPromise &&
        computePreviousOccurrencesStringPromise.cancel()
    }
  }, [alarmAcknowledgements])

  const loadAlarmPreviousOccurrencesBucketDuration =
    AlarmUtils.computeLoadAlarmPreviousOccurrencesBucketDurationFromRepetition(
      alarm
    )

  const onLoadMoreOccurrences = () => {
    const cb = () => {}
    loadMoreOccurrences(cb)
    FirebaseProxy.logEvent(
      Constants.UserAnalyticsEvents.VIEW_MORE_ALARM_HISTORY,
      {}
    )
  }

  const loadMoreOccurrences = cb => {
    let startDate = loadedTill - loadAlarmPreviousOccurrencesBucketDuration

    if (startDate < alarm.historyStartDate) {
      startDate = alarm.historyStartDate
    }

    const endDate = loadedTill
    let previousOccurrences = AlarmUtils.getPreviousOccurrencesBetweenTime(
      alarm.id,
      startDate,
      endDate
    )

    if (previousOccurrences && previousOccurrences.length > 0) {
      const { prevDate } = AlarmUtils.getPrevAndNextDatesForAnAlarmWrtDate(
        previousOccurrences[0] - Constants.MSEC_IN_MINUTE,
        alarm.date,
        alarm.endDate,
        alarm.repeatType,
        alarm.repeat,
        alarm.creatorTimezone
      )
      const moreOccurrencesExists =
        prevDate && prevDate > alarm.historyStartDate
      const newOccurrences = previousOccurrences.concat(occurrences)
      setOccurrences(newOccurrences)
      setLoadedTill(startDate - Constants.MSEC_IN_MINUTE)
      setMoreOccurrencesExists(moreOccurrencesExists)
      cb()
    } else {
      setMoreOccurrencesExists(false)
      cb()
    }
  }

  const showDontSeeCompleteHistoryExplanation = () => {
    NavigationUtils.showAlert('', I18n.t('dontSeeCompleteHistoryExplanation'))
    FirebaseProxy.logEvent(
      Constants.UserAnalyticsEvents.TAP_DONT_SEE_COMPLETE_ALARM_HISTORY,
      {}
    )
  }

  const deleteAlarmHistory = () => {
    dispatch(ActionCreators.deleteAlarmHistory(alarm.id))
    closeAlarmPreviousOccurrences()
  }

  const onDeleteAlarmHistory = () => {
    NavigationUtils.showAlert(
      I18n.t('deleteAllAlarmHistoryAlertTitle'),
      I18n.t('deleteAllAlarmHistoryAlertMessage'),
      [
        {
          text: I18n.t('cancel')
        },
        {
          text: I18n.t('continue'),
          onPress: () => deleteAlarmHistory()
        }
      ]
    )
  }

  console.log('Rendering AlarmPreviousOccurrences')

  if (!loaded) {
    return (
      <Box
        flex
        justify="center"
        align="center"
        background="textBackgroundColor">
        <Text color="textColor">{I18n.t('initializing')}</Text>
        <Spinner />
      </Box>
    )
  }

  if (occurrences.length === 0) {
    return (
      <Box
        flex
        justify="center"
        align="center"
        background="textBackgroundColor">
        <Text color="textColor">{I18n.t('noPreviousOccurrences')}</Text>
      </Box>
    )
  }

  const occurrencesData = occurrences.map(occurrence => {
    const occurrenceAcknowledgement =
      AlarmUtils.getAlarmAcknowledgementStatusForOccurrenceForAlarm(
        alarm,
        occurrence
      )
    const occurrenceStatus = AlarmUtils.getAlarmStatusForOccurrence(
      alarm.id,
      occurrence
    )
    const myAlarmOccurrenceTime = DateTimeUtils.getMyAlarmDate(occurrence)
    return {
      occurrenceTime: occurrence,
      occurrenceDateString: DateTimeUtils.getDateTimeWoYearAsString(
        myAlarmOccurrenceTime
      ),
      occurrenceStatus: occurrenceAcknowledgement || occurrenceStatus,
      alarm: alarm
    }
  })

  const alarmName = alarm.name
  const alarmRepetitionString = AlarmUtils.createAlarmRepetitionString(
    alarm.repeatType,
    alarm.repeat
  )

  return (
    <AppCanvas key={alarm.id} overflow="auto">
      <AlarmPreviousOccurrencesHeader
        alarm={alarm}
        title={I18n.t('alarmSummaryForHistoryWeb', {
          alarmName
        })}
        repetitionString={I18n.t('alarmSummaryForHistoryRepetitionWeb', {
          alarmRepetitionString: lowerFirst(alarmRepetitionString)
        })}
        previousOccurrencesString={previousOccurrencesString}
        onClose={closeAlarmPreviousOccurrences}
        onDeleteAlarmHistory={onDeleteAlarmHistory}
      />
      <Box overflow="auto">
        <List
          data={occurrencesData.reverse()}
          border={false}
          margin={{ top: 'xsmall' }}
          pad={{ horizontal: 'small', vertical: 'xsmall' }}
          // eslint-disable-next-line react/no-children-prop
          children={AlarmPreviousOccurrenceSummary}
        />
        <Box flex={{ shrink: 0 }} width="500px" pad="small">
          {moreOccurrencesExists ? (
            <Button
              color="primary"
              alignSelf="center"
              label={I18n.t('loadMoreHistory')}
              onClick={onLoadMoreOccurrences}
            />
          ) : (
            <Button
              plain
              color="primary"
              alignSelf="center"
              label={I18n.t('dontSeeCompleteHistory')}
              onClick={showDontSeeCompleteHistoryExplanation}
            />
          )}
        </Box>
      </Box>
    </AppCanvas>
  )
}

export default AlarmPreviousOccurrences
