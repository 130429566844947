import ActionTypes from '../actions/types'
import { createReducer } from 'reduxsauce'
import objGet from 'lodash/get'
import objSet from 'lodash/set'

const INITIAL_STATE = {
  alarmAcknowledgements: {},
  alarmStatusChanges: {}
}

const addAlarmAcknowledgement = (state, action) => {
  const { alarmId, occurrenceTimeString, uid, timestamp, response } =
    action.payload
  let rescheduleAlarm = action.payload.rescheduleAlarm
  if (rescheduleAlarm === undefined) {
    rescheduleAlarm = true
  }

  const alarmAcknowledgementsForUser = objGet(
    state,
    `alarmAcknowledgements[${alarmId}][${occurrenceTimeString}][${uid}]`,
    []
  ).slice()
  const userResponseForTimestamp = alarmAcknowledgementsForUser.find(
    userResponse => userResponse.timestamp === timestamp
  )

  if (userResponseForTimestamp) {
    return state
  }

  alarmAcknowledgementsForUser.push({
    timestamp,
    response,
    rescheduleAlarm
  })

  return {
    ...state,
    alarmAcknowledgements: {
      ...state.alarmAcknowledgements,
      [alarmId]: {
        ...state.alarmAcknowledgements[alarmId],
        [occurrenceTimeString]: {
          ...objGet(
            state,
            `alarmAcknowledgements[${alarmId}][${occurrenceTimeString}]`,
            {}
          ),
          [uid]: alarmAcknowledgementsForUser
        }
      }
    }
  }
}

const addAlarmAcknowledgements = (state, action) => {
  const { alarmId, alarmAcknowledgements } = action.payload
  let alarmAcknowledgementsToAdd = {}
  alarmAcknowledgements.forEach(alarmAcknowledgement => {
    const { occurrenceTimeString, uid, timestamp, response } =
      alarmAcknowledgement
    let rescheduleAlarm = alarmAcknowledgement.rescheduleAlarm
    if (rescheduleAlarm === undefined) {
      rescheduleAlarm = true
    }

    const alarmAcknowledgementsForUser = objGet(
      alarmAcknowledgementsToAdd,
      `[${occurrenceTimeString}][${uid}]`,
      []
    )
    alarmAcknowledgementsForUser.push({ response, timestamp, rescheduleAlarm })
    objSet(
      alarmAcknowledgementsToAdd,
      `[${occurrenceTimeString}][${uid}]`,
      alarmAcknowledgementsForUser
    )
  })

  return {
    ...state,
    alarmAcknowledgements: {
      ...state.alarmAcknowledgements,
      [alarmId]: alarmAcknowledgementsToAdd
    }
  }
}

const updateAlarmsAcknowledgements = (state, action) => {
  const { alarmsAcknowledgements } = action.payload
  let alarmsAcknowledgementsToAdd = {}
  Object.keys(alarmsAcknowledgements).forEach(alarmId => {
    const alarmAcknowledgements = alarmsAcknowledgements[alarmId]
    let alarmAcknowledgementsToAdd = {}
    alarmAcknowledgements.forEach(alarmAcknowledgement => {
      const { occurrenceTimeString, uid, timestamp, response } =
        alarmAcknowledgement
      let rescheduleAlarm = alarmAcknowledgement.rescheduleAlarm
      if (rescheduleAlarm === undefined) {
        rescheduleAlarm = true
      }

      const alarmAcknowledgementsForUser = objGet(
        alarmAcknowledgementsToAdd,
        `[${occurrenceTimeString}][${uid}]`,
        []
      )
      alarmAcknowledgementsForUser.push({
        response,
        timestamp,
        rescheduleAlarm
      })
      objSet(
        alarmAcknowledgementsToAdd,
        `[${occurrenceTimeString}][${uid}]`,
        alarmAcknowledgementsForUser
      )
    })
    alarmsAcknowledgementsToAdd[alarmId] = alarmAcknowledgementsToAdd
  })

  return {
    ...state,
    alarmAcknowledgements: {
      ...state.alarmAcknowledgements,
      ...alarmsAcknowledgementsToAdd
    }
  }
}

const removeAlarmAcknowledgements = (state, action) => {
  const { alarmId } = action.payload
  const alarmAcknowledgements = objGet(
    state,
    `alarmAcknowledgements[${alarmId}]`
  )
  if (alarmAcknowledgements) {
    return {
      ...state,
      alarmAcknowledgements: {
        ...state.alarmAcknowledgements,
        [alarmId]: undefined
      }
    }
  } else {
    return state
  }
}

const addAlarmStatusChange = (state, action) => {
  const { alarmId, timestamp, status } = action.payload
  let alarmStatusChanges = objGet(
    state,
    `alarmStatusChanges[${alarmId}]`,
    []
  ).slice()

  const alarmStatusChangeForTimestamp = alarmStatusChanges.find(
    alarmStatusChange => alarmStatusChange.timestamp === timestamp
  )

  if (alarmStatusChangeForTimestamp) {
    return state
  }

  alarmStatusChanges.push({
    timestamp,
    status
  })

  return {
    ...state,
    alarmStatusChanges: {
      ...state.alarmStatusChanges,
      [alarmId]: alarmStatusChanges
    }
  }
}

const addAlarmStatusChanges = (state, action) => {
  const { alarmId, alarmStatusChanges } = action.payload

  return {
    ...state,
    alarmStatusChanges: {
      ...state.alarmStatusChanges,
      [alarmId]: alarmStatusChanges
    }
  }
}

const updateAlarmsStatusChanges = (state, action) => {
  const { alarmsStatusChanges } = action.payload

  let alarmsStatusChangesToAdd = {}
  Object.keys(alarmsStatusChanges).forEach(alarmId => {
    const alarmStatusChanges = alarmsStatusChanges[alarmId]
    alarmsStatusChangesToAdd[alarmId] = alarmStatusChanges
  })

  return {
    ...state,
    alarmStatusChanges: {
      ...state.alarmStatusChanges,
      ...alarmsStatusChangesToAdd
    }
  }
}

const removeAlarmStatusChanges = (state, action) => {
  const { alarmId } = action.payload
  const alarmStatusChanges = objGet(state, `alarmStatusChanges[${alarmId}]`)
  if (alarmStatusChanges) {
    return {
      ...state,
      alarmStatusChanges: {
        ...state.alarmStatusChanges,
        [alarmId]: undefined
      }
    }
  } else {
    return state
  }
}

const ACTION_HANDLERS = {
  [ActionTypes.ADD_ALARM_ACKNOWLEDGEMENT]: addAlarmAcknowledgement,
  [ActionTypes.ADD_ALARM_ACKNOWLEDGEMENTS]: addAlarmAcknowledgements,
  [ActionTypes.UPDATE_ALARMS_ACKNOWLEDGEMENTS]: updateAlarmsAcknowledgements,
  [ActionTypes.REMOVE_ALARM_ACKNOWLEDGEMENTS]: removeAlarmAcknowledgements,
  [ActionTypes.ADD_ALARM_STATUS_CHANGE]: addAlarmStatusChange,
  [ActionTypes.REMOVE_ALARM_STATUS_CHANGES]: removeAlarmStatusChanges,
  [ActionTypes.ADD_ALARM_STATUS_CHANGES]: addAlarmStatusChanges,
  [ActionTypes.UPDATE_ALARMS_STATUS_CHANGES]: updateAlarmsStatusChanges
}

export default createReducer(INITIAL_STATE, ACTION_HANDLERS)
