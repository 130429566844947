const ConfigManager = (function () {
  const getAppEnvironment = () => {
    return process.env.REACT_APP_CONFIG
  }

  return {
    getAppEnvironment
  }
})()

export default ConfigManager
