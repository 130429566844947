import { colorThemes, I18n } from 'galarm-config'
import { Anchor, Box, Image, Text } from 'grommet'
import React from 'react'
import { useSelector } from 'react-redux'
import GalarmWebHeader from './GalarmWebHeader'

const DownloadAppOnMobileScreen = () => {
  const colorScheme = useSelector(state => state.userSettings.webColorScheme)

  return (
    <>
      <GalarmWebHeader />
      <Box
        flex
        align="center"
        pad={{
          // There is an 80px scroll added to the page for some reason
          // because of the navbar on top. Adding margin as a workaround
          // till that is fixed
          bottom: '80px'
        }}>
        <Box pad="large">
          <Text
            size="medium"
            margin={{ bottom: 'xsmall' }}
            style={{ whiteSpace: 'pre-line' }}>
            {I18n.t('webAppBestViewedOnDesktop')}
          </Text>
        </Box>
        <Anchor
          alignSelf="start"
          margin={{ left: 'medium' }}
          color={colorThemes.getColorTheme(colorScheme).primary}
          href="https://www.galarmapp.com/galarm-web"
          label={I18n.t('learnMore')}
        />
        <Box
          direction="row"
          pad="medium"
          align="center"
          justify="around"
          gap="large">
          <Box
            onClick={() =>
              window.open(
                'http://play.google.com/store/apps/details?id=com.galarmapp',
                '_self'
              )
            }>
            <Image
              height="120px"
              src={require('galarm-res/img/web/google-play.png').default}
            />
          </Box>
          <Box
            onClick={() =>
              window.open(
                'https://itunes.apple.com/us/app/galarm-alarms-reminders/id1187849174?mt=8',
                '_self'
              )
            }>
            <Image
              height="120px"
              src={require('galarm-res/img/web/app-store.png').default}
            />
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default DownloadAppOnMobileScreen
