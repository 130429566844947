import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { I18n, Constants, colorThemes } from 'galarm-config'
import { Box, List, Text } from 'grommet'
import { Checkmark } from 'grommet-icons'
import GroupAvatar from './GroupAvatar'
import objGet from 'lodash/get'
import SearchBar from './SearchBar'
import { Selectors } from 'galarm-shared'

const { sortedGroupListSelectorForAlarmParticipant } = Selectors

const GroupSummary = item => {
  return (
    <Box direction="row" justify="between" align="center">
      <Box direction="row" align="center" gap="small">
        <GroupAvatar
          url={objGet(item, 'images.avatarThumbnailUrl', undefined)}
          name={item.name}
        />
        <Text>{item.name}</Text>
      </Box>
      {item.selected && <Checkmark />}
    </Box>
  )
}

const SelectGroup = ({ selectedGroup, onSelectGroup }) => {
  const colorScheme = useSelector(state => state.userSettings.webColorScheme)
  const [searchText, setSearchText] = useState('')

  const onClickGroup = ({ item }) => {
    onSelectGroup(item)
  }

  const groups = useSelector(state =>
    sortedGroupListSelectorForAlarmParticipant(state)
  )

  if (groups.length === 0) {
    return (
      <Box flex justify="center" align="center">
        <Text>{I18n.t('noGroupsCreated')}</Text>
      </Box>
    )
  }

  let groupsData = []
  let filteredGroups = searchText
    ? groups.filter(group =>
        group.name.toLowerCase().includes(searchText.toLowerCase())
      )
    : groups
  filteredGroups.forEach(group => {
    if (selectedGroup !== null && selectedGroup.id === group.id) {
      groupsData.push({
        ...selectedGroup,
        selected: true,
        participantType: Constants.ParticipantTypes.GROUP
      })
    } else {
      groupsData.push({
        ...group,
        selected: false,
        participantType: Constants.ParticipantTypes.GROUP
      })
    }
  })

  const onChangeText = newText => {
    setSearchText(newText)
  }

  return (
    <Box overflow="auto">
      <Box
        flex={{ shrink: 0 }}
        margin={{ horizontal: 'small', bottom: 'medium' }}>
        <SearchBar onChangeText={onChangeText} searchText={searchText} />
      </Box>
      {filteredGroups && filteredGroups.length === 0 && (
        <Box pad="medium" flex justify="center" align="center">
          <Text color={colorThemes.getColorTheme(colorScheme).textColor}>
            {I18n.t('noSearchResultsFound', { type: 'groups' })}
          </Text>
        </Box>
      )}
      <List
        data={groupsData}
        onClickItem={onClickGroup}
        // eslint-disable-next-line react/no-children-prop
        children={GroupSummary}
      />
    </Box>
  )
}

export default SelectGroup
