import React from 'react'
import { TextInput } from 'grommet'
import { TimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import moment from 'moment'
import MomentUtils from '@date-io/moment'

const TimeInput = ({ value, onChange, ...timeInputProps }) => {
  const format = 'h:mm a'
  const timeValue = moment(value)

  const onTimeChange = date => {
    onChange(date.toDate())
  }
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <TimePicker
        value={timeValue}
        onChange={onTimeChange}
        format={format}
        TextFieldComponent={TextInput}
        {...timeInputProps}
      />
    </MuiPickersUtilsProvider>
  )
}

export default TimeInput
